import { forwardRef, useCallback, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import { Validator } from 'cat-ecommerce-alloy';
import { SimpleField } from './SimpleField';
import { CheckboxField } from './CheckboxField';
import { SwitchField } from './SwitchField';
import { RadioField } from './RadioField';
import { DropdownField } from './DropdownField';
import { InputDropdownField } from './InputDropdownField';
import { DateField } from './DateField';
import { DateRangeField } from './DateRangeField';
import declarations from './Field.proptypes';
import { stickyScroll } from '@app/utils/stickyScroll';

const Field = forwardRef(
  (
    {
      constrainBytes,
      errorAfterSubmit,
      getErrorText,
      handleOnChange, // deprecated
      label,
      name,
      onChange,
      onBlur,
      skipFormikOnChange,
      skipFormikOnBlur,
      type = '',
      validator,
      ...props
    },
    ref
  ) => {
    const ariaLabel = props['aria-label'] ?? label;

    // backwards compatibility for 'handleOnChange' prop that was mistakenly added
    onChange = onChange || handleOnChange;

    const [
      { onChange: formikOnChange, onBlur: formikOnBlur, ...field },
      { error, touched, initialError },
      { setValue, setError, setTouched }
    ] = useField({
      validate: validator,
      name,
      type,
      ...props
    });

    const formikCtx = useFormikContext();
    const prevSubmitCountRef = useRef(formikCtx.submitCount);

    /**
     * It might happen that the field name is deeply nested in an object
     * inside the formik form, this function will get the most nested field
     * that is not an object
     */
    const getFirstNestedErrorKey = errors => {
      let nestedKey = '';
      for (const key in errors) {
        if (typeof errors[key] === 'object' && !Array.isArray(errors[key])) {
          const innerKey = getFirstNestedErrorKey(errors[key]);
          if (innerKey !== null) {
            nestedKey = `${key}.${innerKey}`;
          }
        } else if (
          typeof errors[key] === 'object' &&
          Array.isArray(errors[key])
        ) {
          const firstObject = errors[key].find(
            item => typeof item === 'object'
          );
          const firstIndex = errors[key].findIndex(
            item => typeof item === 'object'
          );
          const innerKey = getFirstNestedErrorKey(firstObject);
          if (innerKey !== null) {
            nestedKey = `${key}.${firstIndex}.${innerKey}`;
          }
        } else {
          nestedKey = key;
        }
      }

      return nestedKey;
    };

    /* This makes error field visible below the sticky header
     * first scroll to error field
     * finding scrollY
     * adjusting scroll top position
     */
    const scrollBelowHeader = useCallback(currentRef => {
      stickyScroll(currentRef);
    }, []);

    // This method borrows heavily (if not entirely) from https://github.com/jaredpalmer/formik/issues/146#issuecomment-474775723
    // Credit https://github.com/danielberndt
    const scrollToFirstError = (name, fieldRef) => {
      const errors = formikCtx.errors;
      const firstErrorKey = Object.keys(errors)[0];
      const firstErrorIsObject = typeof errors[firstErrorKey] === 'object';
      if (
        prevSubmitCountRef.current !== formikCtx.submitCount &&
        !formikCtx.isValid
      ) {
        // this first 'if' is for the datepiker. fieldRef.current will be an object. We are interested in the input in the object
        if (fieldRef?.current?.input && firstErrorKey === name) {
          scrollBelowHeader(fieldRef);
        } else if (fieldRef?.current && firstErrorKey === name) {
          scrollBelowHeader(fieldRef);
        } else if (firstErrorIsObject) {
          // this  will try to find the given name inside the errors using recursion
          const firstNestedErrorKey = getFirstNestedErrorKey(errors);
          if (
            (fieldRef?.current?.input || fieldRef?.current) &&
            firstNestedErrorKey === name
          ) {
            scrollBelowHeader(fieldRef);
          }
        }
      }
      prevSubmitCountRef.current = formikCtx.submitCount;
    };

    const truncateBytes = (val, maxBytes) =>
      new Validator(val).maxBytes('err', maxBytes).getError()
        ? truncateBytes(val.substring(0, val.length - 1), maxBytes)
        : val;

    const handleChange = e => {
      if (constrainBytes) {
        const truncatedVal = truncateBytes(e.target.value, constrainBytes);
        e.target.value = truncatedVal;
      }
      if (onChange) {
        onChange(e);
      }
      if (formikOnChange && !skipFormikOnChange) {
        formikOnChange(e);
      }
    };

    const handleBlur = e => {
      if (constrainBytes) {
        const truncatedVal = truncateBytes(e.target.value, constrainBytes);
        e.target.value = truncatedVal;
      }
      if (onBlur) {
        onBlur(e);
      }
      if (formikOnBlur && !skipFormikOnBlur) {
        formikOnBlur(e);
      }
    };

    const isDateRange = type.toLowerCase() === 'daterange';

    let errorText = '';
    if (getErrorText) {
      errorText = getErrorText(formikCtx);
      // TODO: Find a better way to handle this
    } else if (errorAfterSubmit) {
      errorText = formikCtx.submitCount >= 1 ? error : '';
    } else if (
      (typeof error === 'string' && touched) ||
      (error && isDateRange)
    ) {
      errorText = error;
    } else if (initialError && !touched) {
      errorText = initialError;
    }
    if (
      props.ceserialattr === 'ceSerialNumber' &&
      !field.value &&
      ariaLabel &&
      ariaLabel === 'Serial Number'
    ) {
      errorText = '';
    }
    const commonProps = {
      ariaLabel,
      error: errorText,
      label,
      onError: scrollToFirstError,
      setError,
      setValue,
      ...field,
      ...props
    };

    switch (type.toLowerCase()) {
      case 'daterange':
        return (
          <DateRangeField
            onChange={onChange}
            onBlur={handleBlur}
            setTouched={setTouched}
            {...commonProps}
          />
        );
      case 'dropdown':
        return (
          <DropdownField
            onChange={onChange}
            onBlur={handleBlur}
            setTouched={setTouched}
            {...commonProps}
          />
        );
      case 'inputdropdown':
        return (
          <InputDropdownField
            onChange={onChange}
            onBlur={handleBlur}
            setTouched={setTouched}
            {...commonProps}
          />
        );
      case 'checkbox':
        return (
          <CheckboxField
            onChange={handleChange}
            onBlur={handleBlur}
            {...commonProps}
          />
        );
      case 'date':
        return (
          <DateField onChange={onChange} onBlur={handleBlur} {...commonProps} />
        );
      case 'radio':
        return (
          <RadioField
            onChange={onChange}
            onBlur={handleBlur}
            {...commonProps}
          />
        );
      case 'switch':
        return (
          <SwitchField
            onChange={handleChange}
            onBlur={handleBlur}
            {...commonProps}
          />
        );
      default:
        return (
          <SimpleField
            onChange={handleChange}
            onBlur={handleBlur}
            type={type}
            ref={ref}
            {...commonProps}
          />
        );
    }
  }
);

Field.displayName = 'Field';

Field.propTypes = declarations.fieldTypes;

export default Field;
