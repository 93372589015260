import { CatHeading } from 'blocks-react/bedrock/components/Heading';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import links from '@app/constants/links';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { replaceTokensInUrl } from '@app/utils';
import PropTypes from 'prop-types';
import { MyEquipmentView } from '@cat-ecom/pcc-components';
import { BUTTON_VARIANTS } from '@app/constants/commonConstants';
import WrapperContainer from '@app/components/common/WrapperContainer';

const MyEquipmentViewModal = ({ isModalActive, setIsActive, classname }) => {
  const storeId = useSelector(state => state.common.storeId);
  const langId = useSelector(state => state.common.langId);
  const catalogId = useSelector(state => state.common.catalogId);
  const { CONTACT_DEALER_LINK } = links;
  const [t] = useTranslation();
  const contactDealerLink = replaceTokensInUrl(
    CONTACT_DEALER_LINK,
    catalogId,
    langId,
    storeId
  );
  const modalClose = e => {
    e.stopPropagation();
    setIsActive(false);
  };

  const handleContactDealerClick = () => {
    window.location.href = contactDealerLink;
  };

  return (
    <WrapperContainer>
      <MyEquipmentView
        onBlModalClose={modalClose}
        isActive={isModalActive}
        size="sm"
        classname={classname}
      >
        <CatHeading slot="header" variant="title-sm">
          {t('MEQ_EDIT_EQUIP')}
        </CatHeading>
        <CatHeading variant="body">{t('MEQ_DEALER_MANAGED')}</CatHeading>
        <div slot="footer" className="d-flex">
          <CatButton onClick={handleContactDealerClick}>
            {t('CONTACT_YOUR_DEALER')}
          </CatButton>
          <CatButton variant={BUTTON_VARIANTS.GHOST} onClick={modalClose}>
            {t('CANCEL')}
          </CatButton>
        </div>
      </MyEquipmentView>
    </WrapperContainer>
  );
};
MyEquipmentViewModal.propTypes = {
  isModalActive: PropTypes.bool,
  setIsActive: PropTypes.func,
  classname: PropTypes.string
};
export default MyEquipmentViewModal;
