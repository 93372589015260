import { CatButton } from '@blocks-react/components/Button';
import { isEmpty, replaceTokens, replaceTokensInUrl } from '@app/utils';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CatIconWarningCircle } from '@blocks-react/components/Icons/WarningCircle';
import { CatInlineNotification } from '@blocks-react/components/InlineNotification';
import links from '@app/constants/links';

const ErrorWarning = ({
  errorMsg = 'UNKNOWN_ERROR_MESSAGE',
  errorTitle,
  showContactUsButton = true,
  dismissible = false,
  errorCode,
  errorMsgParams
}) => {
  const [t] = useTranslation();
  const catalogId = useSelector(state => state.common.catalogId);
  const storeId = useSelector(state => state.common.storeId);
  const langId = useSelector(state => state.common.langId);
  const handleContactUsClick = () => {
    if (catalogId && storeId && langId) {
      window.location.href = replaceTokensInUrl(
        links.CONTACT_DEALER_LINK,
        catalogId,
        langId,
        storeId
      );
    }
  };

  const formatErrorMessage = () => {
    const messageWithParams = errorMsgParams
      ? replaceTokens(t(errorMsg), errorMsgParams)
      : t(errorMsg);
    return errorCode
      ? `${messageWithParams} (${errorCode})`
      : messageWithParams;
  };

  const notificationHeading = isEmpty(errorTitle)
    ? formatErrorMessage()
    : errorTitle;
  const setNotificationDescription = () =>
    !isEmpty(errorTitle) && !isEmpty(errorMsg) ? formatErrorMessage() : '';

  return (
    <CatInlineNotification
      heading={notificationHeading}
      variant="error"
      dismissible={dismissible}
      data-testid="errorWarning"
    >
      <CatIconWarningCircle slot="before" />
      {setNotificationDescription()}
      {showContactUsButton && (
        <CatButton
          slot="after"
          onClick={handleContactUsClick}
          className="text-nowrap"
        >
          {t('FOOTER_CONTACT_US')}
        </CatButton>
      )}
    </CatInlineNotification>
  );
};

ErrorWarning.propTypes = {
  errorMsg: PropTypes.string,
  errorTitle: PropTypes.string,
  showContactUsButton: PropTypes.bool,
  dismissible: PropTypes.bool,
  errorCode: PropTypes.string,
  errorMsgParams: PropTypes.arrayOf(PropTypes.string)
};

export default ErrorWarning;
