import { STATUS } from '../../constants/commonConstants';
import * as types from './constants';
import { ordersToApproveInitialStore } from './initialStore';

const ordersToApproveReducer = (
  state = ordersToApproveInitialStore,
  action = {}
) => {
  switch (action.type) {
    case types.ORDERS_TO_APPROVE_GET_BEGIN: {
      return {
        ...state,
        searchStatus: STATUS.PENDING
      };
    }
    case types.ORDERS_TO_APPROVE_GET_SUCCESS: {
      return {
        ...state,
        searchStatus: STATUS.RESOLVED,
        deleteStatus: STATUS.RESOLVED,
        approveRejectStatus: STATUS.RESOLVED,
        orders: action.payload.orders,
        recordSetCount: action.payload.recordSetCount,
        pendingOrderCount: action.payload.pendingOrderCount,
        expiringSoonOrderCount: action.payload.expiringSoonOrderCount,
        ordersToApproveDcn: action.payload.ordersToApproveDcn
      };
    }
    case types.ORDERS_TO_APPROVE_GET_FAIL: {
      return {
        ...state,
        searchStatus: STATUS.REJECTED,
        orders: {}
      };
    }

    case types.ORDERS_TO_APPROVE_STATUS_CHANGE_BEGIN: {
      const orderPayloadId = action.payload.orderId;
      const orderToUpdate = state.orders[orderPayloadId];
      const orderUpdated = {
        [orderPayloadId]: {
          ...orderToUpdate,
          orderLineItemLoading: STATUS.PENDING
        }
      };
      const ordersUpdated = { ...state.orders, ...orderUpdated };

      return {
        ...state,
        orders: ordersUpdated,
        changeOrderActionStatus: STATUS.PENDING,
        changeOrderActionId: action.payload?.changeOrderActionId
      };
    }
    case types.ORDERS_TO_APPROVE_STATUS_CHANGE_SUCCESS: {
      const orderPayloadId = action.payload.orderId;
      const orderToUpdate = state.orders[orderPayloadId];
      const orderUpdated = {
        [orderPayloadId]: {
          ...orderToUpdate,
          statusName: action.payload.statusName,
          statusId: action.payload.statusId,
          orderLineItemLoading: STATUS.RESOLVED
        }
      };
      const ordersUpdated = { ...state.orders, ...orderUpdated };
      return {
        ...state,
        orders: ordersUpdated,
        changeOrderActionStatus: STATUS.RESOLVED
      };
    }
    case types.ORDERS_TO_APPROVE_STATUS_CHANGE_FAIL: {
      const orderPayloadId = action.payload.orderId;
      const orderToUpdate = state.orders[orderPayloadId];
      const orderUpdated = {
        [orderPayloadId]: {
          ...orderToUpdate,
          orderLineItemLoading: STATUS.REJECTED
        }
      };
      const ordersUpdated = { ...state.orders, ...orderUpdated };
      return {
        ...state,
        orders: ordersUpdated,
        changeOrderActionStatus: STATUS.REJECTED
      };
    }
    case types.ORDERS_TO_APPROVE_SEARCH_FORM_SET: {
      return {
        ...state,
        searchFormValues: {
          ...ordersToApproveInitialStore.searchFormValues,
          ...action.payload
        }
      };
    }
    case types.ORDERS_PENDING_TO_APPROVE_NUMBER_BEGIN: {
      return {
        ...state,
        ordersProperties: {
          ordersPendingToApproveNumberStatus: STATUS.PENDING
        }
      };
    }
    case types.ORDERS_PENDING_TO_APPROVE_NUMBER_SUCCESS: {
      return {
        ...state,
        ordersProperties: {
          ordersPendingToApproveNumberStatus: STATUS.RESOLVED,
          ordersPendingToApproveNumber: action.payload?.recordSetCount
        },
        pendingOrderCount: action.payload?.pendingOrderCount,
        expiringOrderCount: action.payload?.expiringSoonOrderCount
      };
    }
    case types.AC_ORDERS_TO_APPROVE_SYSTEMFEEDBACK:
      return {
        ...state,
        ordersToApproveErrorDismiss: action.payload
      };
    case types.ORDERS_PENDING_TO_APPROVE_NUMBER_FAIL: {
      return {
        ...state,
        ordersProperties: {
          ordersPendingToApproveNumberStatus: STATUS.REJECTED,
          ordersPendingToApproveNumber: undefined
        }
      };
    }
    case types.APPROVE_REJECT_ORDER_TO_APPROVE_BEGIN: {
      return {
        ...state,
        approveRejectStatus: STATUS.PENDING
      };
    }

    case types.APPROVE_REJECT_ORDER_TO_APPROVE_SUCCESS: {
      return {
        ...state,
        approveRejectStatus: STATUS.RESOLVED
      };
    }

    case types.APPROVE_REJECT_ORDER_TO_APPROVE_FAIL: {
      return {
        ...state,
        approveRejectStatus: STATUS.REJECTED
      };
    }

    case types.PLACE_PENDING_ORDER_BEGIN: {
      return {
        ...state,
        approveRejectStatus: STATUS.PENDING
      };
    }

    case types.PLACE_PENDING_ORDER_SUCCESS: {
      return {
        ...state,
        approveRejectStatus: STATUS.RESOLVED
      };
    }

    case types.PLACE_PENDING_ORDER_FAIL: {
      return {
        ...state,
        approveRejectStatus: STATUS.REJECTED
      };
    }

    case types.DELETE_PENDING_ORDER_BEGIN: {
      return {
        ...state,
        deleteStatus: STATUS.PENDING
      };
    }

    case types.DELETE_PENDING_ORDER_SUCCESS: {
      return {
        ...state,
        deleteStatus: STATUS.RESOLVED
      };
    }

    case types.DELETE_PENDING_ORDER_FAIL: {
      return {
        ...state,
        deleteStatus: STATUS.REJECTED
      };
    }

    case types.ABLE_TO_EDIT_ORDER_BEGIN: {
      return {
        ...state,
        editStatus: STATUS.PENDING
      };
    }

    case types.ABLE_TO_EDIT_ORDER_SUCCESS: {
      return {
        ...state,
        editStatus: STATUS.RESOLVED
      };
    }

    case types.ABLE_TO_EDIT_ORDER_FAILURE: {
      return {
        ...state,
        editStatus: STATUS.REJECTED
      };
    }

    case types.SET_EDIT_STATUS_TO_IDLE: {
      return {
        ...state,
        editStatus: STATUS.IDLE
      };
    }

    case types.SAVE_PONUMBER_SERIAL_AND_RBD_BEGIN: {
      return {
        ...state,
        saveInputFieldsStatus: STATUS.IDLE
      };
    }

    case types.SAVE_PONUMBER_SERIAL_AND_RBD_SUCCESS: {
      return {
        ...state,
        saveInputFieldsStatus: STATUS.APPROVED
      };
    }

    case types.SAVE_PONUMBER_SERIAL_AND_RBD_FAIL: {
      return {
        ...state,
        saveInputFieldsStatus: STATUS.REJECTED
      };
    }

    default:
      return state;
  }
};
export { ordersToApproveInitialStore };
export default ordersToApproveReducer;
