import * as types from './constants';

export const invalidNickNames = new RegExp(
  `${types.INVALID_ADDRESSES.BTO}|${types.INVALID_ADDRESSES.DBSSHIPTO}|${types.INVALID_ADDRESSES.JOBSITE}|${types.INVALID_ADDRESSES.DROPBOX}`
);

export const validAddresses = new RegExp(`${types.S}|${types.B}|${types.SB}`);

export const testValidAddress = address => {
  return (
    !invalidNickNames.test(address.nickName) &&
    validAddresses.test(address.addressType)
  );
};

export const filterValidUserAddresses = inputAddresses => {
  return inputAddresses.filter(testValidAddress);
};
