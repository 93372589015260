export const ACTIONS = {
  FIRST_STORE_ID: 'FIRST_STORE_ID',
  KEEP_STORE_SELECTION: 'KEEP_STORE_SELECTION',
  SCROLL_TO_STORE: 'SCROLL_TO_STORE'
};
export const DEFAULT_NUM_RESULTS = 6;
export const DEFAULT_NUM_MAP_RESULTS = 16;
export const ACTIVE_MARKER_ICON =
  'https://caterpillar.scene7.com/is/image/Caterpillar/CM20210322-f8bd6-dc033?wid=32&hei=32&fmt=png-alpha';
export const INACTIVE_MARKER_ICON =
  'https://caterpillar.scene7.com/is/image/Caterpillar/CM20210322-d4c55-06b16?wid=32&hei=32&fmt=png-alpha';
export const DISTANCE = 'distance';
export const DEALER_NAME = 'dealerName';
export const MILLISECONDS_DEBOUNCE = 300;
export const DEALER_LOCATOR_FORM_ID = 'dealer-locator-form';
