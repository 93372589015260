import * as types from './constants';
import { STATUS } from '@app/constants/commonConstants';

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case types.USER_GET_BEGIN:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.userId]: {
            loadingStatus: STATUS.PENDING
          }
        },
        current: action.payload.userId
      };
    case types.USER_GET_FAIL:
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.current]: {
            loadingStatus: STATUS.REJECTED
          }
        }
      };
    case types.USER_GET_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.current]: {
            loadingStatus: STATUS.RESOLVED,
            ...action.payload
          }
        }
      };
    case types.USER_UPDATE_BEGIN:
      return {
        ...state,
        byId: {
          ...state?.byId,
          [action.payload.userId]: {
            ...state.byId?.[action.payload.userId],
            updateLoadingStatus: STATUS.PENDING
          }
        },
        current: action.payload.userId
      };
    case types.USER_UPDATE_FAIL:
      return {
        ...state,
        byId: {
          ...state?.byId,
          [action.payload.userId]: {
            ...state.byId?.[action.payload.userId],
            updateLoadingStatus: STATUS.REJECTED
          }
        }
      };
    case types.USER_UPDATE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state?.byId,
          [action.payload.userId]: {
            ...state?.byId?.[action.payload.userId],
            updateLoadingStatus: STATUS.RESOLVED
          }
        }
      };
    default: {
      return state;
    }
  }
};

export default userReducer;
