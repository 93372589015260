import {
  CLEAR_ALL_ERRORS,
  CLEAR_DOMAIN,
  CLEAR_ERROR,
  SET_ERROR
} from './constants';

export const clearAllErrors = () => ({
  type: CLEAR_ALL_ERRORS
});

export const clearError = (domain, component) => ({
  type: component ? CLEAR_ERROR : CLEAR_DOMAIN,
  payload: { domain, component }
});

export const setError = (domain, component, error) => ({
  type: SET_ERROR,
  payload: { domain, component, error }
});

export default {
  clearAllErrors,
  clearError,
  setError
};
