import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  NotificationFooterContent,
  NotificationContentWrapper
} from '@cat-ecom/pcc-components';
import { NOTIFICATION_INDICATOR } from '@app/constants/commonConstants';

const CardContent = ({
  dcn,
  formattedDate,
  formattedTime,
  isOrderRow = false,
  purchaseOrderNumber,
  isRead,
  statusKey
}) => {
  const { t } = useTranslation();
  return (
    <NotificationContentWrapper
      purchaseOrderNumber={purchaseOrderNumber}
      PENDING_QUOTE7={t('PENDING_QUOTE7')}
      isRead={isRead}
      NOTIFICATION_INDICATOR={!!NOTIFICATION_INDICATOR[statusKey]}
    >
      <NotificationFooterContent
        isOrderRow={isOrderRow}
        dcn={dcn}
        formattedDate={formattedDate}
        formattedTime={formattedTime}
      />
    </NotificationContentWrapper>
  );
};

export default CardContent;

CardContent.propTypes = {
  dcn: PropTypes.string,
  formattedDate: PropTypes.string,
  formattedTime: PropTypes.string,
  isOrderRow: PropTypes.bool,
  purchaseOrderNumber: PropTypes.number,
  isRead: PropTypes.bool,
  statusKey: PropTypes.string
};
