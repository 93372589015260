import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import { useBreakpoint } from '@app/hooks';
import PropTypes from 'prop-types';
import CommonBreadCrumb from '../BreadCrumb';
import styles from './Page.module.scss';

const Header = ({ className = '', children }) => {
  return <header className={`mb-2 mb-lg-5 ${className}`}>{children}</header>;
};

const BreadCrumb = ({
  breadCrumbId,
  breadCrumbs,
  backText,
  showBackButton
}) => {
  return (
    <CommonBreadCrumb
      links={breadCrumbs}
      id={breadCrumbId}
      showBackButton={showBackButton}
      backButtonText={backText}
    />
  );
};

const SideBar = ({ className = '', children }) => {
  const isLG = useBreakpoint(PAGE_BREAKPOINTS.LG);
  return (
    <div
      className={`col-md-2 p-1 d-print-none ${isLG ? 'pe-0' : ''} ${className}`}
    >
      {children}
    </div>
  );
};

const Content = ({ className = '', children }) => {
  return <div className={`col ${className}`}>{children}</div>;
};

const Page = ({
  className = '',
  children,
  dataTestId = 'page-component',
  isBlocksContainer = false
}) => {
  const isBlocksLG = useBreakpoint(PAGE_BREAKPOINTS.BLOCKS_LG);
  const isLG = useBreakpoint(PAGE_BREAKPOINTS.LG);
  const hasBlocksContainer = isBlocksContainer && isBlocksLG && !isLG;
  return (
    <main
      className={`container ${
        hasBlocksContainer && styles['blocks-container']
      } ${className}`}
      data-testid={dataTestId}
    >
      {children}
    </main>
  );
};

const Layout = ({ className = '', children }) => {
  return <div className={`row ${className}`}>{children}</div>;
};

Page.Header = Header;
Page.Layout = Layout;
Page.BreadCrumb = BreadCrumb;

Layout.SideBar = SideBar;
Layout.Content = Content;

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

Header.propTypes = propTypes;
SideBar.propTypes = propTypes;
Content.propTypes = propTypes;
Page.propTypes = { ...propTypes, dataTestId: PropTypes.string };
Layout.propTypes = propTypes;
BreadCrumb.propTypes = {
  breadCrumbId: PropTypes.string,
  breadCrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  backText: PropTypes.string,
  showBackButton: PropTypes.bool
};

export default Page;
