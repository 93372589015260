import { STATUS } from '@app/constants/commonConstants';
import * as types from './constants';
import { partsFilterInitialStore } from './initialStore';

const partsFilterReducer = (state = partsFilterInitialStore, action) => {
  switch (action.type) {
    case types.PF_UPDATE_SEARCH_STRING: {
      const s = action.payload || '';
      return {
        ...state,
        filter: {
          ...state.filter,
          searchString: s
        }
      };
    }
    case types.PF_CLEAR_SUGGESTIONS:
      return {
        ...state,
        error: '',
        isSearchSuggestionsOpen: false,
        suggestionsStatus: STATUS.IDLE,
        filter: {
          ...state.filter,
          searchSuggestions: {}
        }
      };
    case types.PF_GET_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        isSearchSuggestionsOpen: true,
        suggestionsStatus: STATUS.RESOLVED,
        error: '',
        filter: {
          ...state.filter,
          searchSuggestions: action.payload
        }
      };
    case types.PF_GET_SUGGESTIONS_ERROR:
      return {
        ...state,
        isSearchSuggestionsOpen: false,
        suggestionsStatus: STATUS.REJECTED,
        error: action.payload,
        filter: {
          ...state.filter,
          searchSuggestions: {}
        }
      };
    case types.PF_SET_SEARCH_SUGGESTIONS_OPEN:
      return {
        ...state,
        isSearchSuggestionsOpen: action.payload
      };
    default:
      return state;
  }
};

export { partsFilterInitialStore };
export default partsFilterReducer;
