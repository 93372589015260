import { useEffect, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import endpoints from '@app/constants/endpoints';
import { isHomepage, replaceTokensInString, isEmpty } from '@app/utils';
import { getPageRedirectURL } from '@app/services/windowService';
import { useHttp } from '@app/hooks';
import { ModalContext } from 'cat-ecommerce-alloy';
import { SHOW_LOGIN_EVENT } from '@app/constants/events';
import { createFragmentCookie } from '../../utils';
const { LOGIN_B2C_URL } = endpoints;

const useLogin = () => {
  const { storeId, langId } = useSelector(s => s.common);
  const { invoke, loading } = useHttp();
  const ctx = useContext(ModalContext);
  const encodeRedirectUrl = url => {
    const redirectUrl = !!url ? encodeURIComponent(url) : '';
    return redirectUrl;
  };
  /* when called, we would punchout to b2c*/
  const login = useCallback(
    detailEvent => {
      /*Added pageRedirectURL for AZURE-203120 : This will have a value when the user 
      navigates using the email links and then tries to log in. */
      const pageRedirectURL = encodeRedirectUrl(getPageRedirectURL());
      const defaultRedirectUrl = encodeRedirectUrl(
        detailEvent?.detail?.redirectUrl
      );
      const endpointURL = replaceTokensInString(
        LOGIN_B2C_URL,
        storeId,
        langId,
        !isEmpty(defaultRedirectUrl) ? defaultRedirectUrl : pageRedirectURL,
        isHomepage()
      );
      createFragmentCookie();
      invoke({ method: 'get', rethrowError: true, url: endpointURL })
        .then(data => {
          window.location = data.url;
        })
        .finally(() => ctx.closeAllModals());
    },
    [storeId, langId, invoke, ctx]
  );
  useEffect(() => {
    document.addEventListener(SHOW_LOGIN_EVENT, login);
    return () => document.removeEventListener(SHOW_LOGIN_EVENT, login);
  }, [login]);

  return { login, loading };
};
export default useLogin;
