import { createPortal } from 'react-dom';
import { APP_BANNERS, APP_BANNER_ROOT } from '../../constants/targets';
import AppBannerWrapper from './AppBannerWrapper';

const Container = () => {
  const globalBannersRootElement = document.getElementById(APP_BANNERS);
  const appBannerRootElement = document.getElementById(APP_BANNER_ROOT);
  const target = appBannerRootElement ?? globalBannersRootElement;

  return target ? createPortal(<AppBannerWrapper />, target) : null;
};

export default Container;
