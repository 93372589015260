import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useReplaceTokenWithElement } from '@app/hooks';
import { AssociatedDealerSearchResultsForm } from '../AssociatedDealerSearchResultsForm';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import {
  Conditional,
  Modal,
  DrawerBackHeader,
  ModalBody,
  ModalFooter,
  AssociatedDealerBanner
} from '@cat-ecom/pcc-components';
import styles from '../../styles.module.scss';
import { CatLoadingIndicator } from 'blocks-react/bedrock/components/LoadingIndicator';
import { ERRORS } from '../../constants';
import { useEffect, useRef } from 'react';

export const AssociatedDealerSearchResultsModal = ({
  modal,
  formMethods,
  onSubmit,
  searchResults,
  loading,
  error
}) => {
  const [t] = useTranslation();
  const { replaceTokenWithElement } = useReplaceTokenWithElement();
  const modalRef = useRef();
  const title = replaceTokenWithElement(
    t('GENERAL_BACK_TO'),
    'm-0',
    t('SEARCH_RESULTS_TEXT1')
  );

  const errorMessage = () => {
    if (error === ERRORS.NO_VALID_DCN_NO_STORES) {
      const noStores = t('VALID_DCN_NO_STORES');
      const noStoreSysAdmin = t('VALID_DCN_NO_STORES_SYS_ADMIN');
      return noStores + ' ' + noStoreSysAdmin;
    }
  };

  // * invoking scrollIntoView will shift the content upwards instead of scrolling to the error
  // * instead we change the scrollTop of the body to mimick that behaviour
  useEffect(() => {
    const shadowBody =
      modalRef.current.shadowRoot.querySelector('.cat-c-modal__body');
    if (error && shadowBody) {
      shadowBody.scrollTop = 0;
    }
  }, [error, modalRef]);

  return (
    <Modal className={styles['modal']} ref={modalRef} {...modal.modalProps}>
      <Conditional test={modal.modalProps.isActive}>
        <DrawerBackHeader handleClose={modal.modalProps.onBlModalClose}>
          <h1 className="cat-u-theme-typography-title m-0">{title}</h1>
        </DrawerBackHeader>
        <ModalBody className={styles['drawer-body']}>
          <Conditional test={error}>
            <AssociatedDealerBanner variant="error" ignoreScroll={true}>
              {errorMessage()}
            </AssociatedDealerBanner>
          </Conditional>
          <AssociatedDealerSearchResultsForm
            formMethods={formMethods}
            searchResults={searchResults}
            loading={loading}
            error={error}
          />
        </ModalBody>
        <ModalFooter className={styles['drawer-footer']}>
          <CatButton
            variant="primary"
            onClick={formMethods.handleSubmit(onSubmit)}
            disabled={loading}
          >
            {t('SELECT_CUST_ACCT')}
            <Conditional test={loading}>
              <CatLoadingIndicator slot={'after'} small={true} />
            </Conditional>
          </CatButton>
        </ModalFooter>
      </Conditional>
    </Modal>
  );
};

AssociatedDealerSearchResultsModal.propTypes = {
  drawer: PropTypes.any,
  formMethods: PropTypes.any,
  onSubmit: PropTypes.any,
  searchResults: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  hideCloseButton: PropTypes.bool
};
