import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AlloyButton, ModalContext } from 'cat-ecommerce-alloy';
import { cancelCartImport } from '../../../store/common/actions';
import { USER_TYPE_GUEST } from '../../../constants/commonConstants';
import LogoutButton from '../../NavigationBarUtilitySection/LogoutButton';

const CancelImportButton = () => {
  const [t] = useTranslation();
  const ctx = useContext(ModalContext);
  const cancelImportMessage = t('CANCEL_IMPORT');
  const storeId = useSelector(state => state?.common?.storeId);
  const userType = useSelector(state => state?.common?.userType);
  const dispatch = useDispatch();
  const isLoggedIn = !!userType && userType !== USER_TYPE_GUEST;
  const cancelImport = () => {
    dispatch(cancelCartImport(storeId));
    ctx.closeAllModals();
  };

  return isLoggedIn ? (
    <LogoutButton
      buttonType="ghost"
      name="welcome-modal__cancel-import-button"
      size="large"
    >
      {cancelImportMessage}
    </LogoutButton>
  ) : (
    <AlloyButton
      buttonType="ghost"
      name="welcome-modal__cancel-import-button"
      onClick={cancelImport}
      size="large"
    >
      {cancelImportMessage}
    </AlloyButton>
  );
};

export default CancelImportButton;
