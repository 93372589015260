import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { replaceTokensInUrl } from '../../../../utils';
import endpoints from '../../../../constants/endpoints';
import { debounce } from '@app/utils';
import { MILLISECONDS_DEBOUNCE } from '@app/constants/commonConstants';

const DealerLocatorFormAutosuggestEffect = ({
  invoke,
  openPopup,
  closePopup,
  storeId
}) => {
  const { values } = useFormikContext();
  const location = values.dealerLocation;

  const debouncedInvoke = useCallback(
    debounce(url => {
      invoke({ method: 'get', url });
      openPopup();
    }, MILLISECONDS_DEBOUNCE),
    [invoke]
  );

  useEffect(() => {
    if (location) {
      const url = replaceTokensInUrl(
        endpoints.LOCATION_AUTOSUGGEST,
        storeId,
        location
      );
      closePopup();
      debouncedInvoke(url);
    }
  }, [location, openPopup, storeId, debouncedInvoke]);
  return null;
};

DealerLocatorFormAutosuggestEffect.propTypes = {
  invoke: PropTypes.func,
  openPopup: PropTypes.func,
  storeId: PropTypes.string
};

export default DealerLocatorFormAutosuggestEffect;
