export const RO_GET_INFO_BEGIN = 'RO_GET_INFO_BEGIN';
export const RO_GET_INFO_SUCCESS = 'RO_GET_INFO_SUCCESS';
export const RO_GET_INFO_FAIL = 'RO_GET_INFO_FAIL';
export const RO_PRICE_LOAD_PARTS_LIST_BEGIN = 'RO_PRICE_LOAD_PARTS_LIST_BEGIN';
export const RO_PRICE_LOAD_PARTS_LIST_SUCCESS =
  'RO_PRICE_LOAD_PARTS_LIST_SUCCESS';
export const RO_PRICE_LOAD_PARTS_LIST_CACHED =
  'RO_PRICE_LOAD_PARTS_LIST_CACHED';
export const RO_PRICE_LOAD_PARTS_LIST_FAIL = 'RO_PRICE_LOAD_PARTS_LIST_FAIL';
export const RO_ADD_TO_CART_BEGIN = 'RO_ADD_TO_CART_BEGIN';
export const RO_ADD_TO_CART_FAIL = 'RO_ADD_TO_CART_FAIL';
export const RO_GET_CART_ITEMS_BEGIN = 'RO_GET_CART_ITEMS_BEGIN';
export const RO_GET_CART_ITEMS_SUCCESS = 'RO_GET_CART_ITEMS_SUCCESS';
export const RO_GET_CART_ITEMS_FAIL = 'RO_GET_CART_ITEMS_FAIL';
export const RO_SET_PART_QUANTITY = 'RO_SET_PART_QUANTITY';
export const RO_INCREMENT_PART_QUANTITY = 'RO_INCREMENT_PART_QUANTITY';
export const RO_DECREMENT_PART_QUANTITY = 'RO_DECREMENT_PART_QUANTITY';
export const RO_RESET_PART_QUANTITIES = 'RO_RESET_PART_QUANTITIES';
export const RO_SELECT_PART_HEADER = 'RO_SELECT_PART_HEADER';
export const RO_WRITE_READY_EVENTS = 'RO_WRITE_READY_EVENTS';
export const RO_GET_SOS_CODE_PARTS_LIST_BEGIN =
  'RO_GET_SOS_CODE_PARTS_LIST_BEGIN';
export const RO_GET_SOS_CODE_PARTS_LIST_SUCCESS =
  'RO_GET_SOS_CODE_PARTS_LIST_SUCCESS';
export const RO_GET_SOS_CODE_PARTS_LIST_FAIL =
  'RO_GET_SOS_CODE_PARTS_LIST_FAIL';
export const RO_SELECTED_DEALER_SUCCESS = 'RO_SELECTED_DEALER_SUCCESS';
export const RO_SELECTED_CONFIGURATION_SUCCESS =
  'RO_SELECTED_CONFIGURATION_SUCCESS';
export const RO_UPDATE_LINE_ITEM_PRICE_LOAD_BEGIN =
  'RO_UPDATE_LINE_ITEM_PRICE_LOAD_BEGIN';
export const RO_UPDATE_LINE_ITEM_PRICE_LOAD_SUCCESS =
  'RO_UPDATE_LINE_ITEM_PRICE_LOAD_SUCCESS';
export const RO_UPDATE_LINE_ITEM_PRICE_LOAD_CACHED =
  'RO_UPDATE_LINE_ITEM_PRICE_LOAD_CACHED';
export const RO_UPDATE_LINE_ITEM_PRICE_LOAD_FAIL =
  'RO_UPDATE_LINE_ITEM_PRICE_LOAD_FAIL';
export const RO_GET_SOS_CODE_RELATED_BEGIN = 'RO_GET_SOS_CODE_RELATED_BEGIN';
export const RO_GET_SOS_CODE_RELATED_SUCCESS =
  'RO_GET_SOS_CODE_RELATED_SUCCESS';
export const RO_GET_SOS_CODE_RELATED_FAIL = 'RO_GET_SOS_CODE_RELATED_FAIL';
export const RO_PRICE_LOAD_RELATED_CACHED = 'RO_PRICE_LOAD_RELATED_CACHED';
export const RO_PRICE_LOAD_RELATED_BEGIN = 'RO_PRICE_LOAD_RELATED_BEGIN';
export const RO_PRICE_LOAD_RELATED_SUCCESS = 'RO_PRICE_LOAD_RELATED_SUCCESS';
export const RO_PRICE_LOAD_RELATED_FAIL = 'RO_PRICE_LOAD_RELATED_FAIL';
export const RO_PRICE_LOAD_RECOMMENDED_CACHED =
  'RO_PRICE_LOAD_RECOMMENDED_CACHED';
export const RO_GET_SOS_CODE_RECOMMENDED_BEGIN =
  'RO_GET_SOS_CODE_RECOMMENDED_BEGIN';
export const RO_GET_SOS_CODE_RECOMMENDED_SUCCESS =
  'RO_GET_SOS_CODE_RECOMMENDED_SUCCESS';
export const RO_GET_SOS_CODE_RECOMMENDED_FAIL =
  'RO_GET_SOS_CODE_RECOMMENDED_FAIL';
export const RO_PRICE_LOAD_RECOMMENDED_BEGIN =
  'RO_PRICE_LOAD_RECOMMENDED_BEGIN';
export const RO_PRICE_LOAD_RECOMMENDED_SUCCESS =
  'RO_PRICE_LOAD_RECOMMENDED_SUCCESS';
export const RO_PRICE_LOAD_RECOMMENDED_FAIL = 'RO_PRICE_LOAD_RECOMMENDED_FAIL';
export const RO_CLEAR_PRICE_LOAD = 'RO_CLEAR_PRICE_LOAD';
export const RO_UPDATE_TOTAL_PRICE_LOAD_BEGIN =
  'RO_UPDATE_TOTAL_PRICE_LOAD_BEGIN';
export const RO_UPDATE_TOTAL_PRICE_LOAD_SUCCESS =
  'RO_UPDATE_TOTAL_PRICE_LOAD_SUCCESS';
export const RO_UPDATE_TOTAL_PRICE_LOAD_FAIL =
  'RO_UPDATE_TOTAL_PRICE_LOAD_FAIL';
export const RO_CLEAR_TOTAL_PRICE = 'RO_CLEAR_TOTAL_PRICE';
export const RO_UPDATE_OPTIONAL_PARTS = 'RO_UPDATE_OPTIONAL_PARTS';
export const LOADING_RECOMMENDED_TOOLS_PRICE_BEGIN =
  'LOADING_RECOMMENDED_TOOLS_PRICE_BEGIN';
export const LOADING_RECOMMENDED_TOOLS_PRICE_SUCCESS =
  'LOADING_RECOMMENDED_TOOLS_PRICE_SUCCESS';
export const LOADING_RECOMMENDED_TOOLS_PRICE_FAIL =
  'LOADING_RECOMMENDED_TOOLS_PRICE_FAIL';

export const LOADING_PARTS_PRICE_BEGIN = 'LOADING_PARTS_PRICE_BEGIN';
export const LOADING_PARTS_PRICE_SUCCESS = 'LOADING_PARTS_PRICE_SUCCESS';
export const LOADING_PARTS_PRICE_FAIL = 'LOADING_PARTS_PRICE_FAIL';

export const LOADING_RELATED_PARTS_PRICE_BEGIN =
  'LOADING_RELATED_PARTS_PRICE_BEGIN';
export const LOADING_RELATED_PARTS_PRICE_SUCCESS =
  'LOADING_RELATED_PARTS_PRICE_SUCCESS';
export const LOADING_RELATED_PARTS_PRICE_FAIL =
  'LOADING_RELATED_PARTS_PRICE_FAIL';
