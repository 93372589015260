import { useTranslation } from 'react-i18next';
import { AlloyButton } from 'cat-ecommerce-alloy';
import useLogin from '@app/components/login/hooks/useLogin';

const CheckPriceDescription = () => {
  const [t] = useTranslation();
  const { login } = useLogin();
  return (
    <>
      <AlloyButton
        buttonType="link"
        size="large"
        onClick={login}
        className="p-0 w-auto"
        style={{ verticalAlign: 'inherit' }}
      >
        {t('SIGN_IN_2')}
      </AlloyButton>{' '}
      {t('CHECK_PRICE_SIGN_IN_OR_ENTER_LOCATION')}
    </>
  );
};

export { CheckPriceDescription };
