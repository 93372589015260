import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AlloyButton,
  ContactPhoneIcon,
  ContactEmailIcon,
  LocationPinIcon,
  ModalContext
} from 'cat-ecommerce-alloy';
import { replaceTokensInUrl } from '../../../utils';
import { getfullfillmentDomain } from './../OrderDetails/utils';
import { PICK_UP_TYPE } from '@app/constants/commonConstants';
import links from '../../../constants/links';
import styles from './ContactUsOptions.module.scss';
import { useContext } from 'react';
import useHelpCenterLink from '@app/components/pages/csr-landing/hooks/useHelpCenterLink';

const ContactUsOptions = ({
  error,
  className,
  orderId = '',
  isCSR = false,
  openDealerLocator
}) => {
  const { t } = useTranslation();
  const ctx = useContext(ModalContext);

  const { storeId, isCatCorp, langId, catalogId, selectedStore } = useSelector(
    state => state.common
  );
  const phone = useSelector(s => s.store?.phone);
  const orderType =
    useSelector(s => s.orders?.byId[orderId]?.fullfillment?.type) ||
    PICK_UP_TYPE;
  const fullfillmentDomain = getfullfillmentDomain(orderType);
  const pickupDeliveryInformation = useSelector(
    s => s.orders?.byId[orderId]?.fullfillment[fullfillmentDomain]
  );
  const helpCenterEnabled = useSelector(
    state => state.featureFlag?.PCCCustomerSupportHelpCenter
  );
  const { getHelpCenterContactSupportURL } = useHelpCenterLink();
  const showHelpCenter = isCatCorp || helpCenterEnabled;

  const finalPhone = isCSR
    ? pickupDeliveryInformation?.storeAddress?.phone
    : phone;

  const { CONTACT_DEALER_LINK } = links;

  const emailFormLocation = showHelpCenter
    ? getHelpCenterContactSupportURL()
    : replaceTokensInUrl(CONTACT_DEALER_LINK, catalogId, langId, storeId);

  const handleDealerLocator = () => {
    if (!!openDealerLocator) {
      ctx.removeCurrentModal();
      openDealerLocator();
    }
  };
  const emailButtonLink = () => {
    if (showHelpCenter) {
      window.open(emailFormLocation, '_blank');
    } else {
      window.location.href = emailFormLocation;
    }
  };

  const getPhoneButton = () => {
    if (!finalPhone) {
      return null;
    }
    return (
      <a
        href={`tel:${finalPhone}`}
        className="btn btn-ghost btn-sm d-flex align-items-center justify-content-center flex-column flex-lg-row"
        data-testid="link__ContactUsOptions--phone"
      >
        <ContactPhoneIcon
          className={`me-lg-2 mb-2 mb-lg-0 ${styles['contact-us-options__icon']}`}
        />
        {finalPhone}
      </a>
    );
  };

  const getPhoneOrFindYourDealer = () =>
    (!isCatCorp && !!selectedStore) || isCSR ? (
      getPhoneButton()
    ) : (
      <AlloyButton
        buttonType="ghost"
        className="d-flex align-items-center flex-column flex-lg-row"
        onClick={handleDealerLocator}
        data-testid="button__ContactUsOptions--find-dealer"
      >
        <LocationPinIcon
          className={`me-lg-2 mb-2 mb-lg-0 ${styles['contact-us-options__icon']}`}
        />
        {t('FIND_DEALER')}
      </AlloyButton>
    );

  return (
    <div
      className={`${styles['contact-us-options']}`}
      data-testid="component__ContactUsOptions"
    >
      <h2 className="h2 display-2">{t('HELP_CTR_FAQ_CONTACT_US')}</h2>
      <div
        className={cx('pb-2', styles['contact-us-options__links'], className)}
      >
        {error ? (
          <AlloyButton
            buttonType="secondary"
            onClick={emailButtonLink}
            data-testid="button__ContactUsOptions--email-error"
          >
            {t('FOOTER_CONTACT_US')}
          </AlloyButton>
        ) : (
          <>
            {getPhoneOrFindYourDealer()}
            <a
              href={emailFormLocation}
              target={showHelpCenter ? '_blank' : '_self'}
              className="btn btn-ghost btn-sm d-flex align-items-center justify-content-center flex-column flex-lg-row"
              data-testid="link__ContactUsOptions--email"
              rel="noreferrer"
            >
              <ContactEmailIcon
                className={`me-lg-2 mb-2 mb-lg-0 ${styles['contact-us-options__icon']}`}
              />
              {t('MA_EMAIL')}
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactUsOptions;

ContactUsOptions.propTypes = {
  error: PropTypes.bool,
  className: PropTypes.string,
  orderId: PropTypes.string,
  isCSR: PropTypes.bool,
  openDealerLocator: PropTypes.func
};
