import { STATUS } from '../../constants/commonConstants';

export const orderHistoryInitialStore = {
  searchStatus: STATUS.IDLE,
  orders: [],
  failsReason: '',
  listSize: 0,
  dealerAccountsLoading: true,
  equipmentLoading: false,
  storeLocationsLoading: false,
  storeLocations: null,
  sortBy: 'orderDate',
  orderBy: 'desc',
  filter: {}
};
