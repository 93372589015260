import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DismissAllConfirmationBanner } from '@cat-ecom/pcc-components';
import { dismissNotification } from '@app/store/notificationHeader/action';
import { useCallback } from 'react';

const NotificationDismissAllConfirmationBanner = ({
  isDismissConfirmBannnerOpen,
  setIsDismissConfirmBannerOpen,
  notificationData
}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const handleConfirmationBannerCancel = useCallback(() => {
    setIsDismissConfirmBannerOpen(false);
  }, [setIsDismissConfirmBannerOpen]);

  const handleConfirmationBannerContinue = useCallback(() => {
    if (isDismissConfirmBannnerOpen) {
      const request = [];
      setIsDismissConfirmBannerOpen(false);
      if (notificationData?.length > 0) {
        notificationData.forEach(ele => {
          request.push({
            notificationId: ele.notificationId,
            isDismissed: true,
            isRead: true
          });
        });
        dispatch(dismissNotification({ notifications: request }, false));
      }
    }
  }, [
    dispatch,
    isDismissConfirmBannnerOpen,
    notificationData,
    setIsDismissConfirmBannerOpen
  ]);

  return (
    <DismissAllConfirmationBanner
      NOTIFICATIONS_DISMISS_ALL_PAGE={t('NOTIFICATIONS_DISMISS_ALL_PAGE')}
      CANCEL={t('CANCEL')}
      DISMISS_ALL={t('DISMISS_ALL')}
      handleConfirmationBannerCancel={handleConfirmationBannerCancel}
      handleConfirmationBannerContinue={handleConfirmationBannerContinue}
    />
  );
};

export default NotificationDismissAllConfirmationBanner;

NotificationDismissAllConfirmationBanner.propTypes = {
  isDismissConfirmBannnerOpen: PropTypes.bool,
  setIsDismissConfirmBannerOpen: PropTypes.func,
  notificationData: PropTypes.arrayOf(
    PropTypes.shape({
      isDismissed: PropTypes.bool,
      reviewQuoteLink: PropTypes.string,
      orderNumber: PropTypes.number,
      orderQuoteDetailLink: PropTypes.string,
      isPinned: PropTypes.bool,
      orderId: PropTypes.number,
      isRead: PropTypes.bool,
      orderStatus: PropTypes.string,
      formattedTime: PropTypes.string,
      dcnName: PropTypes.string,
      dcn: PropTypes.string,
      formattedTotalOrderAmount: PropTypes.string,
      orderAmount: PropTypes.number,
      orderShipmentDetailsLink: PropTypes.string,
      formattedDate: PropTypes.string,
      currency: PropTypes.string,
      notificationId: PropTypes.number,
      quoteExpireDays: PropTypes.string,
      reviewOrderLink: PropTypes.string
    })
  )
};
