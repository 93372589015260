import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { ModalContext, Suggestions, Suggestion } from 'cat-ecommerce-alloy';

const DealerLocationSearchSuggestions = ({
  closePopup,
  selectedSuggestion,
  showSuggestions,
  suggestions = []
}) => {
  const { setFieldValue, submitForm, values } = useFormikContext();
  const { showOverflow, hideOverflow } = useContext(ModalContext);
  const onClick = selected => {
    closePopup();
    setFieldValue('dealerLocation', selected);
    setFieldValue('isSuggestion', true);
    submitForm();
  };

  useEffect(() => {
    if (showSuggestions && !selectedSuggestion) {
      showOverflow();
    } else {
      hideOverflow();
    }
  }, [showOverflow, hideOverflow, selectedSuggestion, showSuggestions]);

  return showSuggestions ? (
    <Suggestions>
      {suggestions.map(suggestion => (
        <Suggestion
          key={suggestion}
          highlight={values.dealerLocation}
          onClick={() => onClick(suggestion)}
          text={suggestion}
        />
      ))}
    </Suggestions>
  ) : null;
};

DealerLocationSearchSuggestions.propTypes = {
  closePopup: PropTypes.func,
  selectedSuggestion: PropTypes.string,
  showSuggestions: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.string)
};

export default DealerLocationSearchSuggestions;
