import { AssociatedDealerCheckbox } from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useReplaceTokenWithElement } from '@app/hooks';
import { useFormContext } from 'react-hook-form';
import {
  DEALER_DROPDOWN_NAME,
  TERMS_AND_CONDITIONS_CHECKBOX_NAME
} from '../../constants';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import styles from '../../styles.module.scss';
import { DEALER } from '@app/constants/affiliations';

export const AssociatedDealerTermsAndConditionsCheckbox = ({
  termsAndConditionsDrawer,
  termsAndConditionsModal,
  isModal = true,
  setScrollToPrivacyNotice,
  setShowTermsAndConditions
}) => {
  const { watch } = useFormContext();
  const [t] = useTranslation();
  const dealerName = watch(DEALER_DROPDOWN_NAME);
  const userAffiliation = useSelector(s => s.common?.userAffiliation);
  const isDealerUser = userAffiliation === DEALER;
  const dealerAssociation = useSelector(
    state => state?.dealerAssociation?.dealerAssociations
  );

  const openTermAndConditions = (
    scrollToPrivacyNotice = false,
    whichTermsAndConditions
  ) => {
    setScrollToPrivacyNotice(scrollToPrivacyNotice);
    setShowTermsAndConditions(whichTermsAndConditions);
    isModal
      ? termsAndConditionsModal.modalControl.open()
      : termsAndConditionsDrawer.handleOpen();
  };

  const { replaceTokenWithElement } = useReplaceTokenWithElement();
  const defaultDealersTermsOfUse = replaceTokenWithElement(
    t('DEALERS_TERMS_OF_USE'),
    'text-xs',
    <span>{dealerName}</span>
  );
  const defaultDealersPrivacyNotice = replaceTokenWithElement(
    t('UR_CAT_DEALER_PRIVACY_NOTICE_HREF'),
    'text-xs',
    <span>{dealerName}</span>
  );
  // TO DO: Update to use AssociatedDealerTerms component
  const defaultTermsAndConditions = replaceTokenWithElement(
    t('CHECKOUT_PRIVACY_TC_STATEMENT'),
    'text-xs',
    <CatButton
      variant="ghost"
      className={styles['terms-link']}
      onClick={() => openTermAndConditions(false, '')}
    >
      {t('UR_PARTS_TERMS_OF_USE_HREF')}
    </CatButton>,
    <CatButton
      variant="ghost"
      className={styles['terms-link']}
      onClick={() => openTermAndConditions(true, '')}
    >
      {t('UR_CAT_PRIVACY_NOTICE_HREF')}
    </CatButton>,
    <CatButton
      variant="ghost"
      className={styles['terms-link']}
      onClick={() => openTermAndConditions(false, 'dealerTermsOfUse')}
    >
      {defaultDealersTermsOfUse}
    </CatButton>,
    <CatButton
      variant="ghost"
      className={styles['terms-link']}
      onClick={() => openTermAndConditions(false, 'dealerPrivacyPolicy')}
    >
      {defaultDealersPrivacyNotice}
    </CatButton>
  );

  const dealerAssociationObject = dealerAssociation?.find(dealerObject => {
    return dealerObject?.label?.includes(dealerName);
  });

  const isTermsConditionAccepted = isDealerUser
    ? false
    : dealerAssociationObject?.isTermsConditionAccepted;
  const showTerms = isDealerUser
    ? true
    : dealerName !== undefined && !isTermsConditionAccepted;

  return (
    <AssociatedDealerCheckbox
      name={TERMS_AND_CONDITIONS_CHECKBOX_NAME}
      label={t('YES_I_ACCEPT_TEXT')}
      fieldNote={defaultTermsAndConditions}
      checked={isTermsConditionAccepted}
      showCheckbox={showTerms}
      required={true}
      className="mt-2 mb-4"
    />
  );
};
