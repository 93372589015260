import PropTypes from 'prop-types';
import { getIconSizeNumber } from '../utils';

const TaskCompleteIcon = ({ size }) => {
  const sizeNumber = getIconSizeNumber(size);

  return (
    <svg
      width={sizeNumber}
      height={sizeNumber}
      viewBox={`0 0 ${sizeNumber} ${sizeNumber}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.65919 2.60449C9.2114 2.60449 8.84839 2.9675 8.84839 3.41529V4.19569C8.84839 4.64348 9.2114 5.00649 9.65919 5.00649H14.3416C14.7894 5.00649 15.1524 4.64348 15.1524 4.19569V3.42765L15.1523 3.41504L15.1523 3.40607C15.1474 2.96252 14.7863 2.60449 14.3416 2.60449H9.65919ZM16.6524 4.19569V4.16504H18.2435C18.4585 4.16504 18.6647 4.25046 18.8168 4.40252C18.9689 4.55457 19.0543 4.7608 19.0543 4.97584V20.5838C19.0543 20.7989 18.9689 21.0051 18.8168 21.1571C18.6647 21.3092 18.4585 21.3946 18.2435 21.3946H5.75709C5.54205 21.3946 5.33582 21.3092 5.18377 21.1571C5.03171 21.0051 4.94629 20.7989 4.94629 20.5838V4.97584C4.94629 4.7608 5.03171 4.55457 5.18377 4.40252C5.33582 4.25046 5.54205 4.16504 5.75709 4.16504H7.34839V4.19569C7.34839 5.47191 8.38297 6.50649 9.65919 6.50649H14.3416C15.6178 6.50649 16.6524 5.47191 16.6524 4.19569ZM9.65919 1.10449C8.64559 1.10449 7.78441 1.75709 7.47291 2.66504H5.75709C5.14423 2.66504 4.55646 2.9085 4.12311 3.34186C3.68975 3.77522 3.44629 4.36298 3.44629 4.97584V20.5838C3.44629 21.1967 3.68975 21.7844 4.12311 22.2178C4.55646 22.6512 5.14423 22.8946 5.75709 22.8946H18.2435C18.8563 22.8946 19.4441 22.6512 19.8775 22.2178C20.3108 21.7844 20.5543 21.1967 20.5543 20.5838V4.97584C20.5543 4.36298 20.3108 3.77521 19.8775 3.34186C19.4441 2.9085 18.8563 2.66504 18.2435 2.66504H16.5279C16.2164 1.75709 15.3552 1.10449 14.3416 1.10449H9.65919ZM15.7578 10.8371C15.9773 10.4858 15.8705 10.0231 15.5193 9.80355C15.168 9.58402 14.7053 9.6908 14.4858 10.0421L11.0166 15.5928L8.54819 13.7416C8.21682 13.493 7.74672 13.5602 7.49819 13.8916C7.24966 14.2229 7.31682 14.693 7.64819 14.9416L10.7698 17.2827C10.9378 17.4087 11.1509 17.4585 11.3573 17.42C11.5637 17.3816 11.7445 17.2583 11.8558 17.0802L15.7578 10.8371Z"
        fill="black"
      />
    </svg>
  );
};

TaskCompleteIcon.propTypes = {
  size: PropTypes.string
};

export default TaskCompleteIcon;
