export const REQUEST_SERVICE_DRAFT_IDLE = 'REQUEST_SERVICE_DRAFT_IDLE';
export const REQUEST_SERVICE_DRAFT_BEGIN = 'REQUEST_SERVICE_DRAFT_BEGIN';
export const REQUEST_SERVICE_DRAFT_SUCCESS = 'REQUEST_SERVICE_DRAFT_SUCCESS';
export const REQUEST_SERVICE_DRAFT_FAIL = 'REQUEST_SERVICE_DRAFT_FAIL';
export const REQUEST_SERVICE_SUBMIT_IDLE = 'REQUEST_SERVICE_SUBMIT_IDLE';
export const REQUEST_SERVICE_SUBMIT_BEGIN = 'REQUEST_SERVICE_SUBMIT_BEGIN';
export const REQUEST_SERVICE_SUBMIT_SUCCESS = 'REQUEST_SERVICE_SUBMIT_SUCCESS';
export const REQUEST_SERVICE_SUBMIT_FAIL = 'REQUEST_SERVICE_SUBMIT_FAIL';
export const EXISTING_SERVICE_STATUS_BEGIN = 'EXISTING_SERVICE_STATUS_BEGIN';
export const EXISTING_SERVICE_STATUS_SUCCESS =
  'EXISTING_SERVICE_STATUS_SUCCESS';
export const EXISTING_SERVICE_STATUS_FAIL = 'EXISTING_SERVICE_STATUS_FAIL';
export const EXISTING_SERVICE_STATUS_IDLE = 'EXISTING_SERVICE_STATUS_IDLE';
export const GENERAL = 'GENERAL';
export const COMMERCIAL_OFFER = 'COMMERCIAL_OFFER';
