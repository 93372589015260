import { getHttpInstance } from '@app/services/defaultHttpService';
import resolvedEndpoints from '@app/constants/endpoints';
import { replaceTokensInUrl } from '@app/utils';
import * as types from './constants';
import { TIMEOUT_DOUBLED } from '@app/constants/commonConstants';
import { getContextAttributes, getPreferedNotification } from './utils';

const { GET_USER_NOTIFICATIONS, PATCH_NOTIFICATIONS, USER_GET } =
  resolvedEndpoints;

export const getNotificationHeaderList =
  (isDismissed = false) =>
  async (dispatch, getState) => {
    const storeId = getState().common.storeId;
    const langId = getState().common.langId;
    const timeZone = -new Date().getTimezoneOffset() / 60;
    const timeOffset = `GMT${timeZone > 0 ? '+' : '-'}${Math.abs(timeZone)}`;
    const http = getHttpInstance();
    const httpTimeoutDoubled = getHttpInstance(TIMEOUT_DOUBLED);
    let url = replaceTokensInUrl(
      isDismissed
        ? GET_USER_NOTIFICATIONS
        : GET_USER_NOTIFICATIONS + '&isDismissed={3}',
      storeId,
      langId,
      timeOffset,
      isDismissed
    );
    const urlUser = replaceTokensInUrl(USER_GET, storeId);

    dispatch({
      type: types.INCLUDE_DISMISSED_NOTIFICATIONS,
      payload: isDismissed
    });

    dispatch({
      type: types.GET_HEADER_NOTIFICATION_BEGIN
    });

    try {
      const { data: preferencesData } = await http.get(urlUser);
      const { data } = await httpTimeoutDoubled.get(url);
      const notificationPreferenceStatus = getContextAttributes(
        preferencesData,
        storeId
      );
      dispatch({
        type: types.GET_HEADER_NOTIFICATION_SUCCESS,
        payload: getPreferedNotification(data, notificationPreferenceStatus)
      });

      dispatch({
        type: types.NOTIFICATION_GET_DEALER_ACCOUNTS_SUCCESS,
        payload: {
          preferencesData: notificationPreferenceStatus
        }
      });
    } catch (error) {
      dispatch({
        type: types.GET_HEADER_NOTIFICATION_FAIL,
        payload: error
      });
    }
  };

export const updateNotification =
  (notificationData, isRefresh) => async (dispatch, getState) => {
    const storeId = getState().common.storeId;
    const http = getHttpInstance();
    dispatch({
      type: types.READ_NOTIFICATION_BEGIN
    });
    try {
      const { status } = await http.patch(
        replaceTokensInUrl(PATCH_NOTIFICATIONS, storeId),
        notificationData
      );
      if (status === 204) {
        dispatch({
          type: types.READ_NOTIFICATION_SUCCESS,
          payload: notificationData
        });
        if (isRefresh) {
          dispatch(getNotificationHeaderList());
        }
      } else {
        dispatch({
          type: types.READ_NOTIFICATION_FAIL,
          payload: notificationData
        });
      }
    } catch (error) {
      dispatch({
        type: types.READ_NOTIFICATION_FAIL,
        payload: notificationData
      });
    }
  };

export const dismissNotification =
  (dismissedData, isSingleDismiss) => async (dispatch, getState) => {
    const storeId = getState().common.storeId;
    const http = getHttpInstance();
    dispatch({
      type: types.DISMISS_NOTIFICATION_BEGIN
    });
    try {
      const { status } = await http.patch(
        replaceTokensInUrl(PATCH_NOTIFICATIONS, storeId),
        dismissedData
      );
      if (status === 204) {
        dispatch({
          type: types.DISMISS_NOTIFICATION_SUCCESS,
          payload: dismissedData
        });
        if (isSingleDismiss === false) {
          dispatch({
            type: types.DISMISS_NOTIFICATION_SUCCESS_BANNER,
            payload: true
          });
        }
      } else {
        dispatch({
          type: types.DISMISS_NOTIFICATION_FAIL
        });
      }
    } catch (error) {
      dispatch({
        type: types.DISMISS_NOTIFICATION_FAIL
      });
    }
  };

export const handleDismissedSuccessInfo =
  dismissAllBannerStatus => dispatch => {
    dispatch({
      type: types.DISMISS_NOTIFICATION_SUCCESS_BANNER,
      payload: dismissAllBannerStatus
    });
  };

export const notificationFilterChanged = filter => dispatch => {
  dispatch({
    type: types.NOTIFICATION_FILTER,
    payload: filter
  });
};
