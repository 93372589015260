import { STATUS } from '@app/constants/commonConstants';

export const catVantageInitialStore = {
  catVantageRewardsBalance: {
    isRewardsContact: false,
    currency: 'USD',
    rewardsAppliedToOrder: '0.00',
    totalPointsBalance: '0.00',
    totalCatCreditsBalance: '0.00',
    totalCombinedBalance: '0.00',
    catCreditsLimitAmount: '0.00'
  },
  catRewardsBalanceStatus: STATUS.IDLE,
  cvrEnrollStatus: STATUS.IDLE,
  cvrUserAlreadyEnrolled: false,
  isCatVantageEnrolled: false,
  isEligible: false,
  status: STATUS.IDLE,
  displayToast: false,
  isCatVantageCoveringOrderTotal: false,
  appliedRewards: ''
};
