import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from '@app/hooks';
import { ContactDealer, useDrawer } from '@cat-ecom/pcc-components';
import { CatDrawer } from 'blocks-react/bedrock/components/Drawer';
import {
  NOTIFICATION_ORDER_LINK_TEXT,
  PAGE_BREAKPOINTS
} from '@app/constants/commonConstants';
import CardHeader from './CardHeader';
import styles from './NotificationItem.module.scss';

const ContactDealerDrawer = ({
  isContactDealerClicked,
  setContactDealerClicked,
  orderStatus,
  orderId,
  type,
  statusKey,
  dismissNotification
}) => {
  const [t] = useTranslation();
  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);
  const { phone, email, address, zipcode, name, state } = useSelector(
    s => s?.store
  );
  const { dealerName } = useSelector(s => s?.dealer);
  const data = useSelector(s => s.notificationHeader?.data?.notifications);
  const contactDealerDrawer = useDrawer();
  const drawerRef = useRef(null);

  const link = useMemo(() => {
    return data?.find(element => {
      return element.orderId === orderId;
    });
  }, [data, orderId]);

  const formateMobileNumber = useMemo(() => {
    if (!!phone && !isNaN(phone)) {
      return `${phone?.slice(0, 1)}.${phone?.slice(1, 4)}.${phone?.slice(
        4,
        7
      )}.${phone?.slice(7)}`;
    } else {
      return phone;
    }
  }, [phone]);

  useEffect(() => {
    if (isContactDealerClicked) {
      drawerRef.current?.toggle();
    }
  }, [isContactDealerClicked]);

  const handleDrawerClose = event => {
    event?.preventDefault();
    event?.stopPropagation();
    setContactDealerClicked(false);
    contactDealerDrawer.closeDrawer();
  };
  const handleClickClose = () => {
    drawerRef.current?.toggle();
  };

  return (
    <CatDrawer
      {...contactDealerDrawer.drawerProps}
      ref={drawerRef}
      position="right"
      className={`${styles['notification_drawer']} ${styles['drawer-show-padding']}`}
      onBlDrawerClose={handleDrawerClose}
      width="464px"
      data-testid="cat-contact-dealer-drawer"
    >
      <ContactDealer
        {...{
          orderStatus,
          type,
          dealerName,
          name,
          address,
          state,
          zipcode,
          email,
          isMD,
          handleClickClose,
          phone,
          formateMobileNumber
        }}
        SOS_CONTACT_DEALER={t('SOS_CONTACT_DEALER')}
        NOTIFICATION_CONTACT_DEALER_MESSAGE={t(
          'NOTIFICATION_CONTACT_DEALER_MESSAGE'
        )}
        EMAIL_US={t('EMAIL_US')}
        CANCEL={t('CANCEL')}
        backButtonAriaLable={t('BACK')}
        closeDrawer={handleDrawerClose}
      >
        <CardHeader
          orderId={orderId}
          quoteExpireDate={null}
          title={t(NOTIFICATION_ORDER_LINK_TEXT[statusKey])}
          orderQuoteDetailLink={link?.orderQuoteDetailLink}
          className="ms-2"
          dismissNotification={false}
        />
      </ContactDealer>
    </CatDrawer>
  );
};

export default ContactDealerDrawer;

ContactDealerDrawer.propTypes = {
  isContactDealerClicked: PropTypes.bool,
  setContactDealerClicked: PropTypes.func,
  orderStatus: PropTypes.string,
  orderId: PropTypes.number,
  type: PropTypes.string,
  statusKey: PropTypes.string,
  dismissNotification: PropTypes.bool
};
