import { deletePendingQuote } from '@app/store/account/actions';
import { replaceTokensInString } from '@app/utils';
import { ToastContext } from 'cat-ecommerce-alloy';
import { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const useDeletePendingQuote = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toastContext = useContext(ToastContext);
  const deleteQuote = useCallback(
    (item = {}) =>
      dispatch(deletePendingQuote(item))
        .then(data => {
          if (data?.success === 'true') {
            const toastMessage = replaceTokensInString(
              t('PENDING_QUOTE_DELETE_SUCCESS'),
              item.quoteDocumentNumber
            );
            toastContext.createSuccessToast(toastMessage);
          }
        })
        .catch(e => {
          throw e;
        }),
    [dispatch, t, toastContext]
  );
  return { deleteQuote };
};

export default useDeletePendingQuote;
