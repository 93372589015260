import * as types from './constants';
import { dealerInitialStore } from './initialStore';

const dealerReducer = (state = {}, action) => {
  switch (action.type) {
    case types.DEALER_GET_INFO_BEGIN: {
      return {
        ...state,
        getDealerInfoLoading: true,
        getDealerInfoSuccess: false
      };
    }
    case types.DEALER_GET_INFO_SUCCESS: {
      return {
        ...state,
        getDealerInfoLoading: false,
        getDealerInfoSuccess: true,
        ...action.payload
      };
    }
    case types.DEALER_GET_INFO_FAIL: {
      return {
        ...state,
        getDealerInfoLoading: false,
        getDealerInfoSuccess: false
      };
    }
    default: {
      return state;
    }
  }
};

export { dealerInitialStore };
export default dealerReducer;
