import PropTypes from 'prop-types';

const { shape, string, bool, number, arrayOf, oneOf, element, func } =
  PropTypes;

export const singleEquipmentPropType = shape({
  assetId: string,
  equipmentFamily: string,
  imageURL: string,
  model: string,
  multiple: bool,
  productId: string,
  serialNumber: string,
  uniqueIndex: string,
  unValidatedEquipmentId: string,
  unValidatedItemListId: string,
  validatedAsset: bool,
  validSerialAndModel: bool
});

export const equipmentTableElementPropType = shape({
  ...singleEquipmentPropType,
  reactKey: string,
  showRowNotes: bool,
  rowNotes: element,
  rowProperties: shape({
    onClick: func,
    className: string
  }),
  isRequired: false
});

export const equipmentPropType = shape({
  manageMyEquipmentURL: string,
  myEquipmentList: arrayOf(singleEquipmentPropType),
  selectedEquipment: singleEquipmentPropType,
  selectedEquipmentIndex: number
});

export const orderPropType = oneOf(['asc', 'desc']);

export const sortByPropTypes = oneOf([
  'serialNumber',
  'assetId',
  'modelequipmentFamily'
]);

export const filtersPropType = shape({
  text: PropTypes.string,
  sortBy: PropTypes.string,
  order: orderPropType,
  itemsPerPage: PropTypes.number,
  total: PropTypes.number,
  currentPage: PropTypes.number
});

export const equipmentListPropType = PropTypes.arrayOf(
  PropTypes.shape({
    dealerName: PropTypes.string,
    serialNumber: PropTypes.string,
    productId: PropTypes.string,
    dealerCode: PropTypes.string,
    equipmentFamily: PropTypes.string,
    multiple: PropTypes.bool,
    validatedAsset: PropTypes.bool,
    unValidatedEquipmentId: PropTypes.string,
    validSerialAndModel: PropTypes.bool,
    dcnName: PropTypes.string,
    dcn: PropTypes.string,
    equipmentUniqueId: PropTypes.string,
    isQRCodeTempEquipment: PropTypes.bool,
    ucidName: PropTypes.string,
    seoUrl: PropTypes.string,
    uniqueIndex: PropTypes.string,
    assetId: PropTypes.string,
    imageURL: PropTypes.string,
    model: PropTypes.string,
    dealerMakeCode: PropTypes.string,
    unValidatedItemListId: PropTypes.string,
    ucid: PropTypes.number
  })
);

export const equipmentsPropType = PropTypes.shape({
  serialNumber: PropTypes.string,
  productId: PropTypes.string,
  equipmentFamily: PropTypes.string,
  multiple: PropTypes.bool,
  validatedAsset: PropTypes.bool,
  unValidatedEquipmentId: PropTypes.string,
  validSerialAndModel: PropTypes.bool,
  equipmentUniqueId: PropTypes.string,
  isQRCodeTempEquipment: PropTypes.bool,
  seoUrl: PropTypes.string,
  isCEModel: PropTypes.bool,
  uniqueIndex: PropTypes.string,
  assetId: PropTypes.string,
  imageURL: PropTypes.string,
  model: PropTypes.string,
  dealerMakeCode: PropTypes.string,
  unValidatedItemListId: PropTypes.string
});
