import { isEmpty } from '@app/utils';

const getDefault = arr => (arr || []).find(i => i.default) || {};

export const getDefaultDisplayName = arr => getDefault(arr).displayName;

export const getNonDefaultObject = arr =>
  (arr || []).find(i => !i.default) || {};

export const getNonDefaultDisplayName = arr =>
  getNonDefaultObject(arr).displayName;

export const getDefaultDisplayNameEx = arr => {
  // When there is only one name available, use that name, even if it is not marked as the default.
  // This is necessary when using a drop down where the selection is required.
  const name = getDefaultDisplayName(arr);
  return isEmpty(name) && (arr || []).length === 1 ? arr[0].displayName : name;
};

export const getDefaultValue = arr => getDefault(arr).value;

export const getDefaultValueEx = arr => {
  // When there is only one value available, use that value, even if it is not marked as the default.
  const value = getDefaultValue(arr);
  return isEmpty(value) && (arr || []).length === 1 ? arr[0].value : value;
};

export const objectFromDisplayName = (name, arr) =>
  (arr || []).find(s => s.displayName === name) || {};

export const valueFromName = (name, arr) =>
  ((arr || []).find(s => s.displayName === name) || {}).value;

export const defaultFromName = (name, arr) =>
  ((arr || []).find(s => s.displayName === name) || {}).default;

export const nameFromValue = (value, arr) =>
  ((arr || []).find(s => s.value === value) || {}).displayName;

export const getOnOffByBool = (b1, b2) => {
  if (b1 === b2 || b1 === undefined) {
    return undefined;
  }
  return b1 ? 'on' : 'off';
};

export const getBoolByBool = (b1, b2) => {
  if (b1 === b2 || b1 === undefined) {
    return undefined;
  }
  return b1 ? true : false;
};

export const getValueByName = (formName, arr) => {
  const newValue = valueFromName(formName, arr);
  const oldValue = getDefaultValue(arr);
  return newValue === oldValue || isEmpty(newValue) ? undefined : newValue;
};

export const getValueByValue = (formValue, arr) => {
  const oldValue = getDefaultValue(arr);
  return formValue === oldValue || isEmpty(formValue) ? undefined : formValue;
};

export const getNameByValue = (formValue, arr) => {
  const oldValue = getDefaultValue(arr);
  const formName = nameFromValue(formValue, arr);
  return formValue === oldValue || isEmpty(formValue) ? undefined : formName;
};

export const fmtDropBoxAddress = a => (isEmpty(a) ? '' : a.dropBoxAddressLine1);

export const getSectionMessages = (arr, name) =>
  (arr || []).find(i => i.pageSection === name) || {};

/**
 * Returns the displayName of the selected language if it exist from a languages list options
 * @param {Array<Record<string, string>>} languageOptions List of available languages
 * @param {string} defaultLanguage Selected language
 * @returns {string}
 */
export const getSelectedLanguage = (
  languageOptions = [],
  defaultLanguage = ''
) => {
  const selectedLanguage = languageOptions.find(
    language => language.value === defaultLanguage
  );
  return selectedLanguage?.displayName;
};
