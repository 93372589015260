import { Expander, NavMenu, SkeletonLoader } from 'cat-ecommerce-alloy';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CategoriesNavItem from './CategoriesNavItem';
import styles from './CategoriesNav.module.scss';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import uniqueId from 'lodash/uniqueId';
import { DEFAULT_CATEGORIES_SIZE } from '@app/components/pages/top-category/constants';

const CategoriesNav = ({
  categories,
  isNameWithCount = false,
  isLoading = false,
  skeletonSize = DEFAULT_CATEGORIES_SIZE
}) => {
  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);
  const [t] = useTranslation();
  const [isExpanded, setIsExpanded] = useState(isMD);

  useEffect(() => {
    setIsExpanded(isMD);
  }, [isMD]);

  const skeletonItems = () => {
    return Array.from(Array(skeletonSize)).map(() => {
      return (
        <li
          key={`skeletonLoaderNav-${uniqueId()}`}
          className="mb-1 height-30px"
        >
          <SkeletonLoader className="w-100 h-100" />
        </li>
      );
    });
  };

  return (
    <Expander
      buttonClassName="u-fill--ExtraDarkGray"
      className={styles['sidenav-expander']}
      title={
        <p className="h3 u-fs--24 u-fs-md--20 u-color--ExtraDarkGray m-0 p-0">
          {t('1294_CATEGORY')}
        </p>
      }
      expanded={isExpanded}
      setExpanded={setIsExpanded}
    >
      <NavMenu>
        {isLoading
          ? skeletonItems()
          : categories &&
            categories.map(({ name, count, redirectUrl }) => {
              const itemName = isNameWithCount ? `${name} (${count})` : name;
              return (
                <CategoriesNavItem
                  key={itemName}
                  name={itemName}
                  redirectUrl={redirectUrl}
                />
              );
            })}
      </NavMenu>
    </Expander>
  );
};

CategoriesNav.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      count: PropTypes.number,
      redirectUrl: PropTypes.string.isRequired
    })
  ),
  isNameWithCount: PropTypes.bool,
  isLoading: PropTypes.bool,
  skeletonSize: PropTypes.number
};

export default CategoriesNav;
