export const RETURN_REQUEST_BY_BEGIN = 'RETURN_REQUEST_BY_BEGIN';
export const RETURN_REQUEST_BY_FAIL = 'RETURN_REQUEST_BY_FAIL';
export const RETURN_REQUEST_BY_SUCCESS = 'RETURN_REQUEST_BY_SUCCESS';
export const RETURN_ORDER_GET_BEGIN = 'RETURN_ORDER_GET_BEGIN';
export const RETURN_ORDER_GET_SUCCESS = 'RETURN_ORDER_GET_SUCCESS';
export const RETURN_ORDER_GET_FAIL = 'RETURN_ORDER_GET_FAIL';
export const RETURN_ORDER_HISTORY_GET_BEGIN = 'RETURN_ORDER_HISTORY_GET_BEGIN';
export const RETURN_ORDER_HISTORY_GET_SUCCESS =
  'RETURN_ORDER_HISTORY_GET_SUCCESS';
export const RETURN_ORDER_HISTORY_GET_FAIL = 'RETURN_ORDER_HISTORY_GET_FAIL';
export const RETURN_ORDER_HISTORY_RESET_STATUS =
  'RETURN_ORDER_HISTORY_RESET_STATUS';
