import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import usePagination from '@app/hooks/usePagination';
import { useBreakpoint } from '@app/hooks';
import {
  Drawer,
  NotificationHeader,
  ClearFilter,
  FilterTooltipButton,
  RefreshTooltipButton,
  NotificationKebab,
  NotificationSkeletonLoader,
  NoNotification,
  NotificationDetailError,
  NotificationNoResultBanner
} from '@cat-ecom/pcc-components';
import 'blocks-web-components/dist/bedrock/bedrock.css';
import {
  getNotificationHeaderList,
  handleDismissedSuccessInfo,
  notificationFilterChanged,
  updateNotification
} from '@app/store/notificationHeader/action';
import {
  PAGE_BREAKPOINTS,
  STATUS,
  USER_NOTIFICATION_PAGESIZE
} from '@app/constants/commonConstants';
import IncludeDismissNotification from '../IncludeDismissNotification';
import NotificationDetails from '../NotificationDetails';
import NotificationDismissedSuccess from '../NotificationDismissedSuccess';
import NotificationMenuPopOver from '../NotificationMenuPopOver';
import NotificationPagination from '../NotificationPagination';
import NotificationDismissAllConfirmationBanner from '../NotificationDismissAllConfirmationBanner';
import NotificationStatusChips from '../NotificationStatusChips';
import styles from '../NotificationItem.module.scss';

function NotificationDrawerWrapper({
  drawerProps,
  closeDrawer,
  handleFilterDrawer,
  handleFilter,
  filterResult,
  setListOfOrderStatus
}) {
  const [isDismissConfirmBannerOpen, setIsDismissConfirmBannerOpen] =
    useState(false);

  const [t] = useTranslation();
  const tooltipText = t('GO_BUTTON');

  const dispatch = useDispatch();

  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);

  const data = useSelector(s => s.notificationHeader?.data);

  const isLoading = useSelector(s => s.notificationHeader?.isLoading);

  const notificationStatus = useSelector(
    s => s.notificationHeader?.notificationStatus
  );
  const isDismissedSuccessBanner = useSelector(
    s => s.notificationHeader?.isDismissedSuccessBanner
  );
  const notificationFilter = useSelector(
    s => s.notificationHeader?.notificationFilter
  );
  const isIncludeDismissedNotifications = useSelector(
    s => s.notificationHeader?.includeDismissedNotifications
  );
  const isActiveNotification = useSelector(
    s => s.notificationHeader?.isActiveNotification
  );

  const isFilterApplied = useMemo(
    () =>
      notificationFilter?.orderNumberOrReference ||
      notificationFilter?.poNumber ||
      notificationFilter?.account ||
      notificationFilter?.orderStatus?.length > 0,
    [notificationFilter]
  );

  const showFilterAlterBanner = useMemo(() => {
    return (
      Object.keys(notificationFilter)?.length !== 0 &&
      filterResult?.length === 0
    );
  }, [notificationFilter, filterResult]);

  const isNoNotification = useMemo(() => {
    return (
      data &&
      data?.notifications?.length === 0 &&
      !isLoading &&
      notificationStatus === STATUS.RESOLVED
    );
  }, [data, notificationStatus, isLoading]);

  const {
    onPageChange,
    paginate,
    resultPerPage,
    currentPage,
    totalResultsCount
  } = usePagination(USER_NOTIFICATION_PAGESIZE);

  useEffect(() => {
    dispatch(notificationFilterChanged({}));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(notificationFilter)?.length > 0) {
      handleFilter(data?.notifications, notificationFilter);
    } else {
      paginate(data?.notifications);
    }
  }, [data?.notifications, notificationFilter, handleFilter, paginate]);

  useEffect(() => {
    if (filterResult && Object.keys(notificationFilter)?.length > 0) {
      paginate(filterResult);
    }
  }, [paginate, filterResult, notificationFilter]);

  const handleClearFilter = e => {
    e?.stopPropagation();
    dispatch(notificationFilterChanged({}));
    setListOfOrderStatus([]);
    if (isIncludeDismissedNotifications) {
      dispatch(getNotificationHeaderList(false));
    }
  };

  const unreadNotifications = isRefresh => {
    const request = [];

    if (resultPerPage?.length > 0) {
      resultPerPage.forEach(ele => {
        request.push({ notificationId: ele.notificationId, isRead: true });
      });
      dispatch(updateNotification({ notifications: request }, isRefresh));
    }
  };

  const refreshNotification = () => {
    //Once filter is added dispatch to clear Apply filter data
    const unreadNotification = resultPerPage?.filter(
      row => row.isRead === false
    );
    if (unreadNotification?.length > 0) {
      unreadNotifications(true);
    } else {
      dispatch(getNotificationHeaderList());
    }
    dispatch(notificationFilterChanged({}));
  };

  const handleDrawerClose = useCallback(() => {
    if (drawerProps.isActive) {
      closeDrawer();
      dispatch(handleDismissedSuccessInfo(false));
      const unreadNotification = resultPerPage?.filter(
        row => row.isRead === false
      );
      if (unreadNotification?.length > 0) {
        unreadNotifications(false);
      }
      dispatch(notificationFilterChanged({}));
      setListOfOrderStatus([]);
      setIsDismissConfirmBannerOpen(false);
    }
  }, [
    drawerProps.isActive,
    closeDrawer,
    dispatch,
    resultPerPage,
    unreadNotifications
  ]);

  const isNotificationError = useMemo(() => {
    return !isLoading && notificationStatus === STATUS.REJECTED;
  }, [notificationStatus, isLoading]);

  /** handle refresh and navigation to make unread notification to read*/
  useEffect(() => {
    window.addEventListener('beforeunload', handleDrawerClose);
    return () => {
      window.removeEventListener('beforeunload', handleDrawerClose);
    };
  }, [dispatch, handleDrawerClose]);

  const handleEventClose = useCallback(e => {
    e?.preventDefault();
    e?.stopPropagation();
  }, []);

  useEffect(() => {
    const myDiv = document.getElementsByClassName('cx-sidebar');
    if (drawerProps.isActive) {
      myDiv &&
        myDiv?.[0]?.classList.add(styles['notification_drawer-cx-sidebar']);
    } else {
      myDiv &&
        myDiv?.[0]?.classList.remove(styles['notification_drawer-cx-sidebar']);
    }
  }, [drawerProps.isActive]);

  return (
    <Drawer
      {...drawerProps}
      className={`${styles['notification_drawer']} ${
        resultPerPage?.length > 0 ? styles['drawer-remove-padding'] : ''
      }`}
      onBlDrawerClose={handleDrawerClose}
      data-testid="cat-notification-drawer"
    >
      <NotificationHeader title={t('NOTIFICATIONS')}>
        {isFilterApplied && (
          <ClearFilter
            title={t('CLEAR_FILTERS')}
            handleClearFilter={handleClearFilter}
            tabIndex={0}
          />
        )}

        <FilterTooltipButton
          handleEventClose={handleEventClose}
          isNotificationError={isNotificationError}
          isNoNotification={isNoNotification}
          isFilterApplied={isFilterApplied}
          handleFilterDrawer={handleFilterDrawer}
          tooltipText={tooltipText}
        />

        <RefreshTooltipButton
          handleEventClose={handleEventClose}
          refreshNotification={refreshNotification}
          title={t('REFRESH')}
        />

        <NotificationKebab
          handleEventClose={handleEventClose}
          title={t('VIEW_MORE_OPTIONS')}
        >
          <NotificationMenuPopOver
            setIsDismissConfirmBannerOpen={setIsDismissConfirmBannerOpen}
            isActiveNotification={isActiveNotification}
          />
        </NotificationKebab>
      </NotificationHeader>

      {/* Confirmation Banner */}
      {isDismissConfirmBannerOpen && (
        <NotificationDismissAllConfirmationBanner
          isDismissConfirmBannnerOpen={isDismissConfirmBannerOpen}
          setIsDismissConfirmBannerOpen={setIsDismissConfirmBannerOpen}
          notificationData={resultPerPage}
        />
      )}
      {/* Drawer body */}
      {isDismissedSuccessBanner && <NotificationDismissedSuccess />}
      {isLoading && <NotificationSkeletonLoader />}
      {isNoNotification && (
        <NoNotification NO_NOTIFICATIONS={t('NO_NOTIFICATIONS')} />
      )}
      {isNotificationError && (
        <NotificationDetailError
          NOTIFICATIONS_ERROR={t('NOTIFICATIONS_ERROR')}
        />
      )}

      {notificationFilter &&
        (notificationFilter?.orderStatus?.length > 0 ||
          notificationFilter?.account) && (
          <NotificationStatusChips
            notificationFilter={notificationFilter}
            setListOfOrderStatus={setListOfOrderStatus}
          />
        )}
      {showFilterAlterBanner && (
        <NotificationNoResultBanner
          adjustButton
          handleFilterDrawer={handleFilterDrawer}
          noResultText={t('CAL_NO_ORDERS_ERROR')}
          adjustButtonText={t('ADJUST_FILTERS')}
          isMD={isMD}
          notificationVariant="error"
        />
      )}
      {!isNoNotification &&
        !isLoading &&
        !isNotificationError &&
        !showFilterAlterBanner && (
          <NotificationDetails
            notificationData={resultPerPage}
            setIsDismissConfirmBannerOpen={setIsDismissConfirmBannerOpen}
          />
        )}

      <div
        slot="footer"
        className={`d-flex justify-content-center position-relative  ${styles['footer-button-group']}`}
      >
        <div className={`${styles['notification_drawer_footer_toggle']}`}>
          <IncludeDismissNotification />
        </div>
        {totalResultsCount && !isNoNotification ? (
          <NotificationPagination
            pageSize={USER_NOTIFICATION_PAGESIZE}
            onPageChange={onPageChange}
            totalRecords={totalResultsCount}
            currentPage={currentPage}
          />
        ) : null}
      </div>
    </Drawer>
  );
}

NotificationDrawerWrapper.propTypes = {
  drawerProps: PropTypes.shape({
    isActive: PropTypes.bool
  }),
  closeDrawer: PropTypes.func,
  handleFilterDrawer: PropTypes.shape({
    onClick: PropTypes.func
  }),
  handleFilter: PropTypes.func,
  filterResult: PropTypes.arrayOf(
    PropTypes.shape({
      isDismissed: PropTypes.bool,
      reviewQuoteLink: PropTypes.string,
      orderNumber: PropTypes.number,
      orderQuoteDetailLink: PropTypes.string,
      isPinned: PropTypes.bool,
      orderId: PropTypes.number,
      isRead: PropTypes.bool,
      orderStatus: PropTypes.string,
      formattedTime: PropTypes.string,
      dcnName: PropTypes.string,
      dcn: PropTypes.string,
      formattedTotalOrderAmount: PropTypes.string,
      orderAmount: PropTypes.number,
      orderShipmentDetailsLink: PropTypes.string,
      formattedDate: PropTypes.string,
      currency: PropTypes.string,
      notificationId: PropTypes.number,
      quoteExpireDays: PropTypes.string,
      reviewOrderLink: PropTypes.string
    })
  ),
  setListOfOrderStatus: PropTypes.func
};

export default NotificationDrawerWrapper;
