import { notificationInitialStore } from './initialStore';
import * as types from './constants';
import { STATUS } from '@app/constants/commonConstants';

const notificationReducer = (state = notificationInitialStore, action) => {
  switch (action.type) {
    case types.NO_GET_NOTIFICATIONS_BEGIN:
      return { ...state, notificationStatus: STATUS.PENDING };
    case types.NO_GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationStatus: STATUS.RESOLVED,
        data: action.payload.notifications,
        recordSetCount: action.payload.recordSetTotal
      };
    case types.NO_GET_NOTIFICATIONS_FAIL:
      return { ...state, notificationStatus: STATUS.REJECTED };
    case types.NO_SELECT_DCN:
      return { ...state, selectedDCN: action.payload };
    case types.NOTIFICATION_FILTER_CHANGED: {
      return {
        ...state,
        filter: action.filter
      };
    }
    case types.NO_DISMISS_NOTIFICATIONS_BEGIN:
    case types.NO_DISMISS_NOTIFICATIONS_SUCCESS: {
      const notificationsData = state.data.map(item =>
        item.notificationId === action.payload
          ? { ...item, isDismissed: true }
          : item
      );
      return {
        ...state,
        data: notificationsData
      };
    }

    case types.NO_DISMISS_NOTIFICATIONS_FAIL: {
      const notificationsData = state.data.map(item =>
        item.notificationId === action.payload
          ? { ...item, isDismissed: false }
          : item
      );
      return {
        ...state,
        data: notificationsData
      };
    }
    default:
      return state;
  }
};
export { notificationInitialStore };
export default notificationReducer;
