import { useSelector } from 'react-redux';
import { CSR_TEXTS } from '@app/components/pages/csr-landing/texts';
import { CatToast } from '@blocks-react/components/Toast';
import { CatHeading } from '@blocks-react/components/Heading';
import { CatIconWarningTriangle } from '@blocks-react/components/Icons/WarningTriangle';
import styles from './CSRCheckoutFlow.module.scss';

const CSRCheckoutFlow = () => {
  const isBuyOnBehalf = useSelector(s => s.common.isBuyOnBehalf);
  const isPCCCheckoutForCSR =
    useSelector(s => s.featureFlag?.PCCCheckoutForCSR) ?? true;
  const isFlagCustomerSupportCSRBannerMessagingEnabled =
    useSelector(
      state => state?.featureFlag?.PCC_CustomerSupportCSRBannerMessaging
    ) || false;

  if (
    isBuyOnBehalf &&
    !isPCCCheckoutForCSR &&
    !isFlagCustomerSupportCSRBannerMessagingEnabled
  ) {
    return (
      <CatToast
        variant="warning"
        delay={5}
        className={styles['toast-sm-width']}
      >
        <CatIconWarningTriangle slot="before" />
        <CatHeading variant="label-sm">
          {CSR_TEXTS.CHECKOUT_UNAVAILABLE}
        </CatHeading>
      </CatToast>
    );
  }

  return null;
};

export default CSRCheckoutFlow;
