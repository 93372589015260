import { ERROR_DOMAIN, ERROR_PATH } from '@app/constants/errorConstants';

export const COOKIE_BROWSING_HISTORY = 'browsingHistory';
export const MAX_DAYS = 14;
export const PATH = '/';
export const MAX_PRODUCTS = 200;
export const BY_COMMA = '%2C';
export const PROP_65_WARNING = 'Prop 65 warning';
export const ERROR_INFO = {
  domain: ERROR_DOMAIN.PDP,
  path: ERROR_PATH.MAIN
};
