import { v4 } from 'uuid';
import * as types from './constants';
import { isDev } from '@app/utils';
import { getHttpInstance } from '@app/services/defaultHttpService';
import {
  MIN_SEARCH_LENGTH,
  REQUEST_ID_HEADER
} from '@app/constants/commonConstants';
import { LUCID_INTEGRATION_FLAG } from '@app/constants/featureFlags';
import endpoints from '@app/constants/endpoints';

export const getSuggestions = searchString => async (dispatch, getState) => {
  if (!searchString || searchString.length < MIN_SEARCH_LENGTH) {
    return dispatch({ type: types.PF_CLEAR_SUGGESTIONS });
  }
  const { common, featureFlag } = getState();
  const { langId, storeId, catalogId, locale } = common;
  const isModernizedSearch = featureFlag[LUCID_INTEGRATION_FLAG] ?? false;
  const termKey = isModernizedSearch ? 'q' : 'term';
  const config = {
    headers: {
      ...(isModernizedSearch
        ? { [REQUEST_ID_HEADER]: v4() }
        : { 'Cache-Control': 'no-cache' })
    },
    params: {
      [termKey]: searchString.toLowerCase(),
      langId,
      storeId,
      catalogId,
      locale
    }
  };
  const { GET_LUCID_SUGGESTIONS, GET_SOLR_SUGGESTIONS } = endpoints;

  const url = isModernizedSearch ? GET_LUCID_SUGGESTIONS : GET_SOLR_SUGGESTIONS;
  const http = getHttpInstance(12000);

  try {
    const { data } = await http.get(url, config);
    dispatch({ type: types.PF_CLEAR_SUGGESTIONS });
    dispatch({
      type: types.PF_GET_SUGGESTIONS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: types.PF_GET_SUGGESTIONS_ERROR, payload: error.message });
  }
};

export const updateSearchString = value => (dispatch, getState) => {
  dispatch({ type: types.PF_UPDATE_SEARCH_STRING, payload: value });
};

export const setSearchSuggestionsOpen = isOpen => ({
  type: types.PF_SET_SEARCH_SUGGESTIONS_OPEN,
  payload: isOpen
});
