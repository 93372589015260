import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  SHOW_CHECK_PRICE_EVENT,
  SHOW_DEALER_LOCATOR_EVENT,
  SHOW_DEALER_LOCATOR_FROM_ADP_EVENT,
  SHOW_LOGIN_REGISTRATION_MODAL_EVENT
} from '@app/constants/events';
import useDealerLocator from '@app/components/dealer-locator';
import { getCookie, isEmpty } from '@app/utils';
import { useLocation } from 'react-router-dom';
import useCatCorpRedirect from '@app/components/login/redirect/hooks/useCatCorpRedirect';
import useLoginRegistration from '@app/components/login/hooks/useLoginRegistration';
import {
  DealerLocatorModalWrapper,
  CheckPriceDescription as CheckPriceDescriptionBlocks
} from '@app/components/dealer-locator-react';
import { PageLoader } from 'cat-ecommerce-alloy';
import { AssociatedDealerModal } from '@app/components/associated-dealer-block';
import Conditional from '@app/components/common/Conditional';
import PendingApprovalModal from './PendingApprovalModal';
import TaxInfoEntryModal from './TaxInfoEntryModal';
import { useModal } from '@cat-ecom/pcc-components';
import { SHOPPING_CART_ROUTE } from '@app/constants/routes';
import { USER_TYPE_GUEST } from '@app/constants/commonConstants';
import { noUcidCookieName } from './constants';

const HeaderDealerLocatorEffects = () => {
  const [t] = useTranslation();
  const { pathname = '' } = useLocation();
  const associatedDealerModal = useModal({
    isRequired: true,
    backButton: false,
    size: 'md'
  });
  const modal = useModal({
    isRequired: true,
    heading: t('CAT_CHECK_PRICE'),
    backButton: false,
    size: 'md'
  });
  const welcomeModal = useModal({
    isRequired: true,
    heading: t('WELCOME_TO_PARTS_COM'),
    backButton: false,
    size: 'md'
  });
  const pendingApprovalModal = useModal({
    isRequired: false,
    backButton: false,
    size: 'sm'
  });
  const taxInfoEntryModal = useModal({
    isRequired: true,
    heading: t('CAT_USER_REGISTRATION_EMAIL_SUBJECT1'),
    backButton: false,
    size: 'sm'
  });

  const { showDealerLocator } = useDealerLocator();
  const { showLoginRegistrationModal } = useLoginRegistration();
  const { shouldRedirect, pageLoading, redirect } = useCatCorpRedirect({
    associatedDealerModal
  });

  const userType = useSelector(s => s.common?.userType);
  const areTranslationsLoaded = useSelector(
    s => s.common?.areTranslationsLoaded
  );
  const showCartImportModal = useSelector(s => s.common?.showCartImportModal);
  const showWelcomeModal = useSelector(s => s.dealerAssociation?.welcomeModal);
  const isCatCSR = useSelector(s => s.common?.isCatCSR);
  const dealerName = useSelector(s => s.dealer?.dealerName);
  const showAssociatedDealerModal = useSelector(
    s => s.dealerAssociation?.customerHasAccessToDealer
  );
  const taxIdFlagEnabled = useSelector(state => state?.dealer?.taxIdFlag);
  const taxIdOption =
    useSelector(state => state?.common?.dealerFields?.taxIdOption) || '';
  const registrationSession = useSelector(
    state => state?.common?.registrationSession
  );
  const isPccUserTaxInfoUpdate = useSelector(
    s => s.featureFlag?.PCCUserTaxInfoUpdate
  );
  const isLoggedIn = !!userType && userType !== USER_TYPE_GUEST;
  const onShoppingCartPage = pathname.includes(SHOPPING_CART_ROUTE);

  const wasWelcomeModalShown = useRef(false);

  // Shows the dealer locator modal when a user clicks the "+" button on ADP or a "Check Price" button
  const openDealerLocatorFromAdp = useCallback(() => {
    showDealerLocator({ fromADP: true });
  }, [showDealerLocator]);

  useEffect(() => {
    document.addEventListener(
      SHOW_DEALER_LOCATOR_FROM_ADP_EVENT,
      openDealerLocatorFromAdp
    );
    return () =>
      document.removeEventListener(
        SHOW_DEALER_LOCATOR_FROM_ADP_EVENT,
        openDealerLocatorFromAdp
      );
  }, [openDealerLocatorFromAdp]);

  // opens login/registration splitmodal when legacy inithttpsecure() is called
  useEffect(() => {
    const showLoginRegistrationModalCb = e => {
      if (!isEmpty(e.detail)) {
        showLoginRegistrationModal(e.detail);
      }
    };
    document.addEventListener(
      SHOW_LOGIN_REGISTRATION_MODAL_EVENT,
      showLoginRegistrationModalCb
    );
    return () =>
      document.removeEventListener(
        SHOW_LOGIN_REGISTRATION_MODAL_EVENT,
        showLoginRegistrationModalCb
      );
  }, [showLoginRegistrationModal]);

  useEffect(() => {
    document.addEventListener(SHOW_DEALER_LOCATOR_EVENT, showDealerLocator);
    return () =>
      document.removeEventListener(
        SHOW_DEALER_LOCATOR_EVENT,
        showDealerLocator
      );
  }, [showDealerLocator]);

  const openCheckPriceModal = useCallback(() => {
    modal.initiatorProps.onClick();
  }, [modal.initiatorProps]);

  // Shows the check price dealer locator when users click on Check Price on PDP
  // or Shop Local
  useEffect(() => {
    document.addEventListener(SHOW_CHECK_PRICE_EVENT, openCheckPriceModal);
    return () =>
      document.removeEventListener(SHOW_CHECK_PRICE_EVENT, openCheckPriceModal);
  }, [openCheckPriceModal]);

  // Shows the cart-import modal, welcome modal if user has no dealer associations
  useEffect(() => {
    const noucidCookieExist = !isEmpty(getCookie(noUcidCookieName));
    const showPendingApprovalModal = noucidCookieExist && dealerName;
    const showCartImportModalForLoggedIn = showCartImportModal && isLoggedIn;
    const showCartImportModalForGuest =
      showCartImportModal && !isLoggedIn && onShoppingCartPage;
    if (
      !wasWelcomeModalShown.current &&
      areTranslationsLoaded &&
      !showPendingApprovalModal &&
      (showCartImportModalForLoggedIn ||
        showCartImportModalForGuest ||
        (showWelcomeModal && !isCatCSR))
    ) {
      wasWelcomeModalShown.current = true;
      welcomeModal.initiatorProps.onClick();
    }
  }, [
    areTranslationsLoaded,
    dealerName,
    isCatCSR,
    isLoggedIn,
    onShoppingCartPage,
    showCartImportModal,
    showDealerLocator,
    showWelcomeModal,
    t,
    welcomeModal.initiatorProps
  ]);

  // Shows the pending approval modal
  useEffect(() => {
    const showPendingApprovalModal =
      !pendingApprovalModal.modalControl.isActive &&
      !isEmpty(getCookie(noUcidCookieName)) &&
      dealerName &&
      areTranslationsLoaded;

    if (showPendingApprovalModal) {
      pendingApprovalModal.initiatorProps.onClick();
    }
  }, [
    areTranslationsLoaded,
    dealerName,
    pendingApprovalModal.initiatorProps,
    pendingApprovalModal.modalControl.isActive
  ]);

  useEffect(() => {
    shouldRedirect && redirect();
  }, [shouldRedirect]);

  //Show the Tax Info Entry Modal
  useEffect(() => {
    if (
      isPccUserTaxInfoUpdate &&
      taxIdFlagEnabled &&
      !taxIdOption &&
      registrationSession &&
      !!dealerName
    ) {
      taxInfoEntryModal.initiatorProps.onClick();
    }
  }, [
    isPccUserTaxInfoUpdate,
    dealerName,
    taxIdFlagEnabled,
    taxIdOption,
    registrationSession
  ]);

  return (
    <>
      <Conditional test={showAssociatedDealerModal !== false}>
        <AssociatedDealerModal modal={associatedDealerModal} />
      </Conditional>
      <DealerLocatorModalWrapper modal={modal}>
        <CheckPriceDescriptionBlocks />
      </DealerLocatorModalWrapper>
      <DealerLocatorModalWrapper modal={welcomeModal} showShopAsGuestBtn>
        {t('WELCOME_PCC_LOGGED_IN_NO_DEALER')}
      </DealerLocatorModalWrapper>
      <Conditional test={shouldRedirect && pageLoading}>
        <PageLoader />
      </Conditional>
      <PendingApprovalModal modal={pendingApprovalModal} />
      <TaxInfoEntryModal modal={taxInfoEntryModal} />
    </>
  );
};

export default HeaderDealerLocatorEffects;
