import enUS from 'date-fns/locale/en-US';
import ar from 'date-fns/locale/ar';
import id from 'date-fns/locale/id';
import pt from 'date-fns/locale/pt';
import zhCN from 'date-fns/locale/zh-CN';
import da from 'date-fns/locale/da';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import de from 'date-fns/locale/de';
import ja from 'date-fns/locale/ja';
import ko from 'date-fns/locale/ko';
import nl from 'date-fns/locale/nl';
import nb from 'date-fns/locale/nb';
import pl from 'date-fns/locale/pl';
import ru from 'date-fns/locale/ru';
import sv from 'date-fns/locale/sv';
import tr from 'date-fns/locale/tr';
import cs from 'date-fns/locale/cs';
import he from 'date-fns/locale/he';
import th from 'date-fns/locale/th';
import zhTW from 'date-fns/locale/zh-TW';
import it from 'date-fns/locale/it';
import hi from 'date-fns/locale/hi';
import ta from 'date-fns/locale/ta';
import bn from 'date-fns/locale/bn';
import vi from 'date-fns/locale/vi';
import el from 'date-fns/locale/el';
import bg from 'date-fns/locale/bg';
import kn from 'date-fns/locale/kn';
import fi from 'date-fns/locale/fi';

export const DATE_FNS_LOCALES = {
  en_US: enUS,
  ar_EG: ar,
  in_ID: id,
  pt_BR: pt,
  zh_CN: zhCN,
  da_DK: da,
  es_MX: es,
  fr_FR: fr,
  de_DE: de,
  ja_JP: ja,
  ko_KR: ko,
  nl_NL: nl,
  nb_NO: nb,
  pl_PL: pl,
  ru_RU: ru,
  sv_SE: sv,
  tr_TR: tr,
  cs_CZ: cs,
  he_IL: he,
  th_TH: th,
  zh_TW: zhTW,
  it_IT: it,
  hi_IN: hi,
  ta_IN: ta,
  bn_BD: bn,
  vi_VN: vi,
  el_GR: el,
  bg_BG: bg,
  kn_IN: kn,
  fi_FI: fi
};

export const LOCALES_FOR_REVERSE_NAME = ['ja_JP', 'ko_KR', 'zh_CN', 'zh_TW'];

/**
 * used to create help center links
 */
export const SALESFORCE_LOCALES_MAP = {
  ar_EG: 'ar',
  cs_CZ: 'cs',
  da_DK: 'da',
  de_DE: 'de',
  en_US: 'en_US',
  fr_FR: 'fr',
  pl_PL: 'pl',
  in_ID: 'in',
  tr_TR: 'tr',
  he_IL: 'iw',
  ja_JP: 'ja',
  ko_KR: 'ko',
  nl_NL: 'nl_NL',
  nb_NO: 'no',
  pt_BR: 'pt_BR',
  ru_RU: 'ru',
  sv_SE: 'sv',
  th_TH: 'th',
  zh_CN: 'zh_CN',
  es_MX: 'es',
  zh_TW: 'zh_TW',
  it_IT: 'it',
  hi_IN: 'hi',
  ta_IN: 'ta',
  bn_BD: 'bn',
  vi_VN: 'vi',
  el_GR: 'el',
  bg_BG: 'bg',
  kn_IN: 'kn',
  fi_FI: 'fi'
};

export const RTL_LANGUAGES = ['-23', '-33'];
