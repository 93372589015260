import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { AlloyButton } from 'cat-ecommerce-alloy';
import useLogout from '@app/components/login/hooks/useLogout';
import useDealerLocator from '@app/components/dealer-locator';
import { CSR_ACTIONS } from '@app/components/pages/csr-landing/constants';

const LogoutButton = ({
  buttonType = 'secondary',
  children,
  className = '',
  size = 'small',
  isMenuItem,
  isCSR = false,
  ...props
}) => {
  const { showDealerLocator } = useDealerLocator();

  const { logout } = useLogout();
  const [t] = useTranslation();
  const csrHandleClick = () =>
    showDealerLocator({
      bobRedirect: CSR_ACTIONS.TRANSACT_AS_GUEST
    });

  return (
    <AlloyButton
      buttonType={buttonType}
      className={cx(className)}
      onClick={isCSR ? csrHandleClick : logout}
      size={size}
      role={isMenuItem ? 'menuitem' : 'button'}
      {...props}
    >
      {children ? children : t('HOME_PAGE_MODAL_LOG_OUT')}
    </AlloyButton>
  );
};

LogoutButton.propTypes = {
  buttonType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string,
  logoutUrl: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  isMenuItem: PropTypes.bool,
  isCSR: PropTypes.bool
};

export default LogoutButton;
