import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'cat-ecommerce-alloy';
import Field from '../../../../common/form/Field';
import styles from './SkipThisStep.module.scss';

const SkipThisStep = () => {
  const [t] = useTranslation();

  return (
    <Field
      as={Checkbox}
      className={cx('pt-1', styles['skip-checkbox'])}
      helpText={t('SHOP_PREF_SKIP_ADP2')}
      helpTextClassName="u-color--DarkGray"
      id="associated-dealer__skipThisStep"
      label={t('SHOP_PREF_SKIP_ADP')}
      name="skipThisStep"
      type="checkbox"
    />
  );
};

export default SkipThisStep;
