import styles from './AddEquipmentDrawer.module.scss';
import { useTranslation } from 'react-i18next';
import { MyEquipmentFooterNote } from '@cat-ecom/pcc-components';

const AddEquipmentFootNote = () => {
  const [t] = useTranslation();
  return (
    <div className={`${styles['warning-message-box']} d-flex col-12 pb-4 px-0`}>
      <MyEquipmentFooterNote>{t('MEQ_WARNING_MESSAGE')}</MyEquipmentFooterNote>
    </div>
  );
};

export default AddEquipmentFootNote;
