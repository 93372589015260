import PropTypes from 'prop-types';

const SwitchField = ({
  as: Component,
  ariaLabel,
  setValue,
  value,
  ...props
}) => {
  return (
    <Component
      aria-label={ariaLabel}
      data-testid="field-switch"
      setChecked={setValue}
      {...props}
      checked={value}
    />
  );
};

SwitchField.propTypes = {
  as: PropTypes.elementType.isRequired,
  ariaLabel: PropTypes.string,
  checked: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.bool
};

export { SwitchField };
