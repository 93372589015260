import { useTranslation } from 'react-i18next';
import { replaceTokensInString, normalizeUrl } from '@app/utils';
import endpoints from '@app/constants/endpoints';
import { headlessLinks } from '@app/constants/links';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { FooterCommonLink } from '@cat-ecom/pcc-components';
import {
  USER_TYPE_GUEST,
  HEADER_GLOBAL_LOGIN,
  CAT_SIS_REDIRECT
} from '@app/constants/commonConstants';
import { appendUrl } from '@app/components/common/SisLink/utils';
import queryString from 'query-string';
import useLoginRegistration from '@app/components/login/hooks/useLoginRegistration';

const FooterFindPartsLinks = () => {
  const { t } = useTranslation();
  const esiteURL = useSelector(({ common }) => common.esiteURL);
  const isHeadlessPartsDiagramEnabled = useSelector(
    state => state.featureFlag.PCCPartsDiagramUrlFlag
  );
  const isSiteMapHeadlessFlag = useSelector(
    s => s.featureFlag?.PCCHeadlessSiteMapFeature
  );

  const getLinkUrl = url => {
    const linkEndpoint = replaceTokensInString(url, esiteURL);
    return normalizeUrl(linkEndpoint);
  };
  const {
    FOOTER_LINK_SHOP_BY_CATEGORY,
    FOOTER_LINK_PART_MANUAL,
    FOOTER_LINK_ABOUT_CAT_PARTS,
    FOOTER_LINK_SITE_MAP
  } = endpoints;
  const { HEADLESS_PARTS_DIAGRAM_LINK, HEADLESS_SITE_MAP_LINK } = headlessLinks;

  const href = useSelector(state => state?.header?.globalNavigation?.sisURL);
  const { pathname, search: searchParams } = useLocation();
  const { searchTerm } = queryString.parse(searchParams);
  const sisLinkParam = appendUrl(href, pathname, searchTerm);
  const { userType } = useSelector(s => s?.common);
  const isGuest = (userType || '') === USER_TYPE_GUEST;
  const { showLoginRegistrationModal } = useLoginRegistration();
  const handleButtonClick = () => {
    !!isGuest &&
      showLoginRegistrationModal({
        widgetId: HEADER_GLOBAL_LOGIN,
        murl: CAT_SIS_REDIRECT
      });
  };

  return (
    <>
      <li>
        <FooterCommonLink
          label={t('SHOP_BY_CATEGORY')}
          href={getLinkUrl(FOOTER_LINK_SHOP_BY_CATEGORY)}
          target={'_self'}
        />
      </li>
      <li>
        <FooterCommonLink
          label={t('CAT_PARTS_DIAGRAM')}
          href={getLinkUrl(
            isHeadlessPartsDiagramEnabled
              ? HEADLESS_PARTS_DIAGRAM_LINK
              : FOOTER_LINK_PART_MANUAL
          )}
          target={'_self'}
        />
      </li>
      <li>
        <FooterCommonLink
          label={t('FULL_SIS_LABEL')}
          href={!isGuest ? sisLinkParam : undefined}
          target={'_self'}
          onClick={isGuest && handleButtonClick}
        />
      </li>
      <li>
        <FooterCommonLink
          label={t('HEADER_ABOUT_CAT_PARTS')}
          href={getLinkUrl(FOOTER_LINK_ABOUT_CAT_PARTS)}
          target={'_self'}
        />
      </li>
      <li>
        <FooterCommonLink
          label={t('FOOTER_SITE_MAP')}
          href={getLinkUrl(
            isSiteMapHeadlessFlag
              ? HEADLESS_SITE_MAP_LINK
              : FOOTER_LINK_SITE_MAP
          )}
          target={'_self'}
        />
      </li>
    </>
  );
};

export default FooterFindPartsLinks;
