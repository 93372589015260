import { createPath } from 'history';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { preventDefaultLinkEvent } from '@app/utils';

/**
 * This hook allows react-router-dom navigation in anchor elements preventing the default behavior of the links
 */
const useRouterDomNavigation = () => {
  const history = useHistory();

  /**
   * Use it to prevent the default behavior of the links and navigate to given URL
   * avoiding navigate to the same current location and removing the basename in localhost
   * @param {MouseEvent} event Click event of the link
   * @param {String} url Relative URL to the new Location
   * @example
   *  const { navigatePreventingEvent } = useRouterDomNavigation();
   *  ...
   *  const handleClick = (ev) => {
   *    ...
   *    navigatePreventingEvent(ev, url)
   *  }
   *
   *  return <a href={otherUrl} onClick={handleClick}>Some link</a>
   */
  const navigatePreventingEvent = useCallback(
    (event, url) => {
      if (preventDefaultLinkEvent(event)) {
        // Get current router basename
        const basename = history.createHref({ pathname: '/' });
        // Un get new location without basename
        const newLocation = url.replace(new RegExp(`^${basename}`), '/');

        // Avoid navigation at the same page
        if (newLocation !== createPath(history.location)) {
          history.push(newLocation);
        }
      }
    },
    [history]
  );

  /**
   * Use it to prevent the default behavior of the links and navigate to anchor href
   * avoiding navigate to the same current location and removing the basename in localhost
   * @param {MouseEvent} event Click event of the anchor
   * @example
   *  const { anchorNavigate } = useRouterDomNavigation();
   *  ...
   *  return <a href={url} onClick={anchorNavigate} >Some link</a>
   * @example
   *  const { anchorNavigate } = useRouterDomNavigation();
   *  ...
   *  const handleClick = (ev) => {
   *    ...
   *    anchorNavigate(ev)
   *  }
   *
   *  return <a href={url} onClick={handleClick}>Some link</a>
   */
  const anchorNavigate = useCallback(
    event => {
      if (event.target && event.currentTarget === event.target) {
        // get target route
        const href = event.target.getAttribute('href');
        navigatePreventingEvent(event, href);
      } else if (event.currentTarget) {
        const href = event.currentTarget.getAttribute('href');
        navigatePreventingEvent(event, href);
      }
    },
    [navigatePreventingEvent]
  );

  return { navigatePreventingEvent, anchorNavigate };
};

export default useRouterDomNavigation;
