import { FILTER_KEYS } from './constants';
import { QUOTE_STATUS } from '@app/components/pages/account/pending-quotes/constants';
import { ORDER_STATUS } from '@app/constants/commonConstants';

export const handleDeleteGTM = cardName => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'formSubmitted',
    pagePath: document.location.pathname,
    formName: cardName,
    formContent: 'delete saved payment',
    formContent2: ' ',
    formStatus: ' success ',
    formFieldCausingError: null,
    formLocation: cardName
  });
};

export const setSavedListLineItemLoading = (
  savedLists,
  orderId,
  loadingStatus
) => {
  return savedLists.map(item =>
    item.orderId === orderId
      ? { ...item, isSavedListLineItemLoading: loadingStatus }
      : item
  );
};

const dateInBetween = (min, dateToCheck, max) => {
  if (!min && !max) {
    return true;
  }
  if (!max) {
    if (dateToCheck >= min?.setHours(0, 0, 0, 0)) {
      return true;
    } else if (dateToCheck <= min?.setHours(0, 0, 0, 0)) {
      return false;
    }
  }
  if (!min) {
    if (dateToCheck <= max?.setHours(0, 0, 0, 0)) {
      return true;
    } else if (dateToCheck >= max?.setHours(0, 0, 0, 0)) {
      return false;
    }
  }
  const isDateExist =
    dateToCheck >= min?.setHours(0, 0, 0, 0) &&
    dateToCheck <= max?.setHours(0, 0, 0, 0);

  return !!isDateExist;
};

const getDateFilter = (value, items, data) => {
  let found = false;
  if (value === 'D') {
    found = dateInBetween(
      items['displayStartDate'],
      new Date(data.quoteCreationDefaultFormatDate),
      items['displayEndDate']
    );
  }
  if (value === 'E') {
    found = dateInBetween(
      items['displayStartDate'],
      new Date(data.quoteExpirationDefaultFormatDate),
      items['displayEndDate']
    );
  }
  return found;
};

const isValueExist = (data, key, value) =>
  data[key]?.toString().toUpperCase().includes(value?.toString().toUpperCase());

const getStatusFilterValue = (data, value) => {
  if (value !== QUOTE_STATUS.EXPIRED.value) {
    if (value === QUOTE_STATUS.REJECTED.value) {
      return value === data?.orderStatus;
    } else {
      return value === data?.orderStatus && !data?.isQuoteExpired;
    }
  } else {
    return data?.isQuoteExpired && data?.orderStatus !== ORDER_STATUS.REJECTED;
  }
};

const checkFilterValue = (data, items) => {
  let found = false;
  let filteredValue = [];
  const removeKeysForIteration = ['displayStartDate', 'displayEndDate', 'dcn'];
  for (const [key, value] of Object.entries(items)) {
    if (value && !removeKeysForIteration.includes(key)) {
      switch (key) {
        case FILTER_KEYS.FILTER_BY_DATE:
          found = getDateFilter(value, items, data);
          filteredValue.push(found);
          break;
        case FILTER_KEYS.PART_NUM:
          found = data?.quoteItems?.some(({ partNumber }) =>
            partNumber
              ?.toString()
              .replace('-', '')
              .toUpperCase()
              .includes(value?.toString().replace('-', '').toUpperCase())
          );
          filteredValue.push(found);
          break;
        case FILTER_KEYS.QUOTE_STATUS:
          found = getStatusFilterValue(data, value);
          filteredValue.push(found);
          break;
        case FILTER_KEYS.EQUIPMENT:
          const findBy = ['serialNumber', 'model', 'assetId'];
          found = findBy.find(key => isValueExist(data, key, value));
          filteredValue.push(found);
          break;
        default:
          found = data[key] ? isValueExist(data, key, value) : false;
          typeof found === 'boolean' && filteredValue.push(found);
      }
    }
  }

  return filteredValue.every(val => Boolean(val));
};
export const filterHandler = (filterKeys, data = []) => {
  let result = [];

  data.forEach(element => {
    if (checkFilterValue(element, filterKeys)) {
      result.push(element);
    }
  });

  return result;
};
