import { useCallback, useEffect, useState, useContext } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  AlloyButton,
  ComponentLoader,
  ModalContext,
  StickySection
} from 'cat-ecommerce-alloy';
import DealerUser from './DealerUser';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
  ERROR_DOMAIN,
  ERROR_PATH
} from '../../../../../constants/errorConstants';
import { useSystemFeedback } from '@app/hooks';
import { dealerCustomerPropTypes } from '../../../declarations.propTypes';
import useDealerUserSearch from '../../../hooks/useDealerUserSearch';

const SearchResultsForm = ({ resetDealerSearchForm, results = [] }) => {
  const { t } = useTranslation();
  const DEFAULT_RESULTS_VISIBLE = 6;
  const feedback = useSelector(
    s => s.errors[ERROR_DOMAIN.DEALER_USER_SEARCH]?.[ERROR_PATH.SEARCH_RESULTS]
  );
  const { clearError } = useSystemFeedback();
  const [numDisplayed, setNumDisplayed] = useState(DEFAULT_RESULTS_VISIBLE);
  const resultsAvailable = results.length > 0;
  const moreResultsAvailable = results.length > numDisplayed;
  const { loading, selectDCN } = useDealerUserSearch();

  const clearFeedback = useCallback(() => {
    if (feedback) {
      clearError(ERROR_DOMAIN.DEALER_USER_SEARCH, ERROR_PATH.SEARCH_RESULTS);
    }
  }, [clearError, feedback]);

  const viewMore = () =>
    setNumDisplayed(currentNum => currentNum + DEFAULT_RESULTS_VISIBLE);

  const { removeCurrentModal } = useContext(ModalContext);

  const onSubmit = values => {
    if (loading) {
      return;
    }
    clearFeedback();
    const selectedDealerCustomer = results.find(
      r => r.customerNumber === values.dealerCustomerNumber
    );
    selectDCN(
      selectedDealerCustomer,
      ERROR_PATH.SEARCH_RESULTS,
      resetDealerSearchForm,
      removeCurrentModal
    );
  };

  const initialValues = {
    dealerCustomerNumber: results[0]?.customerNumber ?? ''
  };

  useEffect(() => {
    // Clear errors when the modal unmounts
    return clearFeedback;
  }, [clearFeedback]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {({ values }) => (
        <Form className="text-start" name="dealer-user__search-results-form">
          {loading && <ComponentLoader />}
          <ul className="list-group mt-2 ps-0">
            {resultsAvailable &&
              results
                .slice(0, numDisplayed)
                .map((dealerUser, index) => (
                  <DealerUser
                    clearFeedback={clearFeedback}
                    expanded={index === 0}
                    key={dealerUser.customerNumber}
                    {...dealerUser}
                  />
                ))}
            {moreResultsAvailable && (
              <li className="list-group-item mb-0 border-start-0 border-end-0 px-0">
                <AlloyButton
                  buttonType="ghost"
                  className="w-100"
                  onClick={viewMore}
                  size="large"
                  name="dealer-user-form__view-more-button"
                >
                  {t('VIEW_MORE_PRODUCTS')}
                </AlloyButton>
              </li>
            )}
          </ul>
          <StickySection
            className={cx('bg-white border-top py-3 z-index-1 text-center')}
          >
            <AlloyButton
              buttonType="secondary"
              className="my-3"
              size="large"
              type="submit"
              disabled={!values.dealerCustomerNumber}
              name={'dealer-user-form__select-customer-button'}
            >
              {t('SELECT_CUST_ACCT')}
            </AlloyButton>
          </StickySection>
        </Form>
      )}
    </Formik>
  );
};

export default SearchResultsForm;

SearchResultsForm.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape(dealerCustomerPropTypes))
    .isRequired,
  resetDealerSearchForm: PropTypes.func.isRequired
};
