import { STATUS } from '../../constants/commonConstants';

export const invoicesInitialStore = {
  invoicesList: [],
  filters: {
    invoiceNumber: '',
    orderNumber: '',
    purchaseOrderNumber: '',
    serialNumber: ''
  },
  searchStatus: STATUS.IDLE,
  invoice: {},
  invoiceDetailStatus: STATUS.IDLE,
  invoiceDetailError: null,
  invoiceDetailExportError: null
};
