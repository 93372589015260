export const ME_LOAD_EQUIPMENTDATA_SUCCESS = 'ME_LOAD_EQUIPMENTDATA_SUCCESS';
export const ME_SET_EQUIPMENT_FILTER_SUCCESS =
  'ME_SET_EQUIPMENT_FILTER_SUCCESS';
export const ME_LOAD_MCCRESPONSE_SUCCESS = 'ME_LOAD_MCCRESPONSE_SUCCESS';
export const ME_LOAD_MCCRESPONSE_FAIL = 'ME_LOAD_MCCRESPONSE_FAIL';
export const ME_LOAD_SELECTEDEQUIPMENT_SUCCESS =
  'ME_LOAD_SELECTEDEQUIPMENT_SUCCESS';
export const ME_LOAD_DESELECTEDEQUIPMENT_SUCCESS =
  'ME_LOAD_DESELECTEDEQUIPMENT_SUCCESS';
export const ME_DELETE_EQUIPMENTDATA_SUCCESS =
  'ME_DELETE_EQUIPMENTDATA_SUCCESS';
export const ME_UPDATE_EQUIPMENTDATA_SUCCESS =
  'ME_UPDATE_EQUIPMENTDATA_SUCCESS';
export const ME_ADD_EQUIPMENTDATA_SUCCESS = 'ME_ADD_EQUIPMENTDATA_SUCCESS';

export const ME_BEGIN_LOADING = 'ME_BEGIN_LOADING';
export const ME_END_LOADING = 'ME_END_LOADING';
export const ME_SET_EQUIPMENT_SAVE_SUCCESS = 'ME_SET_EQUIPMENT_SAVE_SUCCESS';
export const ME_IS_SAVING_EQUIPMENT = 'ME_IS_SAVING_EQUIPMENT';

// This should be moved to common error handler when this merges with checkout
export const ME_SET_ERROR = 'ME_SET_ERROR';
export const ME_CLEAR_ERROR = 'ME_CLEAR_ERROR';

export const ME_CHECK_FITMENT_BEGIN = 'ME_CHECK_FITMENT_BEGIN ';
export const ME_CHECK_FITMENT_SUCCESS = 'ME_CHECK_FITMENT_SUCCESS ';
export const ME_CHECK_FITMENT_FAIL = 'ME_CHECK_FITMENT_FAIL ';

export const GET_EQUIPMENT_FITMENT_SET_STATUS =
  'GET_EQUIPMENT_FITMENT_SET_STATUS';
export const PLP_GET_EQUIPMENT_FITMENT_SET_STATUS =
  'PLP_GET_EQUIPMENT_FITMENT_SET_STATUS';

export const ME_SERIAL_NUM_VALIDATION_SUCCESS =
  'ME_SERIAL_NUM_VALIDATION_SUCCESS';
export const ME_SERIAL_NUM_VALIDATION_BEGIN = 'ME_SERIAL_NUM_VALIDATION_BEGIN';
export const ME_SERIAL_NUM_VALIDATION_FAIL = 'ME_SERIAL_NUM_VALIDATION_FAIL';
export const ME_SERIAL_NUM_REMOVE_VALIDATION =
  'ME_SERIAL_NUM_REMOVE_VALIDATION';
export const SERIAL_NUM_VALIDATION_FAILED = 'SERIAL_NUM_VALIDATION_FAILED';
export const ME_AUTO_SAVE_BEGIN = 'ME_AUTO_SAVE_BEGIN';
export const ME_AUTO_SAVE_SUCCESS = 'ME_AUTO_SAVE_SUCCESS';
export const ME_AUTO_SAVE_FAIL = 'ME_AUTO_SAVE_FAIL';
export const ME_SAVE_QR_SCANNED_EQP_BEGIN = 'ME_SAVE_QR_SCANNED_EQP_BEGIN';
export const ME_SAVE_QR_SCANNED_EQP_SUCCESS = 'ME_SAVE_QR_SCANNED_EQP_SUCCESS';
export const ME_SAVE_QR_SCANNED_EQP_FAIL = 'ME_SAVE_QR_SCANNED_EQP_FAIL';
export const ME_SELECT_EQP_SAVE_BEGIN = 'ME_SELECT_EQP_SAVE_BEGIN';
export const ME_SELECT_EQP_SAVE_SUCCESS = 'ME_SELECT_EQP_SAVE_SUCCESS';
export const ME_SELECT_EQP_SAVE_FAIL = 'ME_SELECT_EQP_SAVE_FAIL';
export const ME_OMM_CATALOG_BEGIN = 'ME_OMM_CATALOG_BEGIN';
export const ME_OMM_CATALOG_SUCCESS = 'ME_OMM_CATALOG_SUCCESS';
export const ME_OMM_CATALOG_FAIL = 'ME_OMM_CATALOG_FAIL';
export const ME_OMM_CONTENT_BEGIN = 'ME_OMM_CONTENT_BEGIN';
export const ME_OMM_CONTENT_SUCCESS = 'ME_OMM_CONTENT_SUCCESS';
export const ME_OMM_CONTENT_FAIL = 'ME_OMM_CONTENT_FAIL';
export const EQP_SEL_DESEL_TOAST = 'EQP_SEL_DESEL_TOAST';
export const MAINTENANCE_VIDEOS_BEGIN = 'MAINTENANCE_VIDEOS_BEGIN';
export const MAINTENANCE_VIDEOS_SUCCESS = 'MAINTENANCE_VIDEOS_SUCCESS';
export const MAINTENANCE_VIDEOS_FAIL = 'MAINTENANCE_VIDEOS_FAIL';
export const SERVICE_RECOMMENDATION_PRICE_BEGIN =
  'SERVICE_RECOMMENDATION_PRICE_BEGIN';
export const SERVICE_RECOMMENDATION_PRICE_SUCCESS =
  'SERVICE_RECOMMENDATION_PRICE_SUCCESS';
export const SERVICE_RECOMMENDATION_PRICE_FAIL =
  'SERVICE_RECOMMENDATION_PRICE_FAIL';

export const GET_SERVICE_RECOMMENDATION_BEGIN =
  'GET_SERVICE_RECOMMENDATION_BEGIN';
export const GET_SERVICE_RECOMMENDATION_SUCCESS =
  'GET_SERVICE_RECOMMENDATION_SUCCESS';
export const GET_SERVICE_RECOMMENDATION_FAIL =
  'GET_SERVICE_RECOMMENDATION_FAIL';

export const SERVICE_RECOMMENDATION_SUBTOTAL_BEGIN =
  'SERVICE_RECOMMENDATION_SUBTOTAL_BEGIN';
export const SERVICE_RECOMMENDATION_SUBTOTAL_SUCCESS =
  'SERVICE_RECOMMENDATION_SUBTOTAL_SUCCESS';
export const SERVICE_RECOMMENDATION_SUBTOTAL_FAIL =
  'SERVICE_RECOMMENDATION_SUBTOTAL_FAIL';
export const GET_COMMERCIAL_OFFER_DETAILS_BEGIN =
  'GET_COMMERCIAL_OFFER_DETAILS_BEGIN';
export const GET_COMMERCIAL_OFFER_DETAILS_SUCCESS =
  'GET_COMMERCIAL_OFFER_DETAILS_SUCCESS';
export const GET_COMMERCIAL_OFFER_DETAILS_FAIL =
  'GET_COMMERCIAL_OFFER_DETAILS_FAIL';

export const SR_USER_STATE_UPDATE_BEGIN = 'SR_USER_STATE_UPDATE_BEGIN';
export const SR_USER_STATE_UPDATE_SUCCESS = 'SR_USER_STATE_UPDATE_SUCCESS';
export const SR_USER_STATE_UPDATE_FAIL = 'SR_USER_STATE_UPDATE_FAIL';

export const AUTO_SAVE_DEFAULT_PARAMS = {
  description: 'Wish List',
  descriptionName: 'Wish List',
  registry: 'false',
  quantityRequested: '1'
};

export const EQUIPMENT_METHOD = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete'
};

export const SERIAL_NUMBER = 'serialNumber';
export const GA_LOCATION_FOR_FIRST_ADD_EQ = 'shop parts for your cat equipment';
export const GA_LOCATION_FOR_ADD_EQ =
  'your equipment | Do you want to add more equipment';
export const GA_FORM_NAME_BY_SERIAL = 'By Serial Number | add equipment';
export const GA_FORM_NAME_BY_MODEL = 'By Model | add equipment';
export const SEARCH_UI_GET_EQUIPMENT_FITMENT_SET_STATUS =
  'SEARCH_UI_GET_EQUIPMENT_FITMENT_SET_STATUS';
export const GA_FORM_NAME_FOR_OMM =
  'Operation and maintenance Manuals | step 1';
export const PDP_GET_FITMENT_SET_STATUS = 'PDP_GET_FITMENT_SET_STATUS';

export const ommBookMeta = {
  SampleImageUrl: 'SampleImageUrl'
};
export const GA_FORM_NAME_FOR_PMKITS = 'pmkit | step 1';
export const GA_FORM_NAME_FOR_SSRO = 'ssro | step 1';
export const VIDEO_STATE = {
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected'
};

export const ME_TOAST = 'ME_TOAST';

export const TOAST_STATUS = {
  SELECT_SUCCESS: 'select-success',
  DESELECT_SUCCESS: 'success',
  ERROR: 'error'
};

export const INSTANCE_ACTIVE_STATE = 'active';
export const INSTANCE_USER_DISMISSED_STATE = 'dismissed';

export const LOAD_CVA_DETAILS_BEGIN = 'LOAD_CVA_DETAILS_BEGIN';
export const LOAD_CVA_DETAILS_SUCCESS = 'LOAD_CVA_DETAILS_SUCCESS';
export const LOAD_CVA_DETAILS_FAIL = 'LOAD_CVA_DETAILS_FAIL';
