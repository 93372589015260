import { STATUS } from '../../constants/commonConstants';

export const organizationsAndUsersInitialStore = {
  searchFormValues: {
    accountNumber: null,
    accountStatus: null,
    role: null,
    firstName: '',
    lastName: '',
    userName: ''
  },
  organizations: {},
  users: {},
  userDetails: {
    getUserDetailsStatus: STATUS.IDLE,
    setOrganizationUsersInfo: STATUS.IDLE,
    organizationRolesStatus: STATUS.IDLE,
    userApprovalAmountStatus: STATUS.IDLE,
    organizationRolesAssignStatus: STATUS.IDLE,
    setUserApprovalAmountStatus: STATUS.IDLE,
    organizations: [],
    organizationsRoles: {},
    currency: undefined,
    approvalAmount: undefined,
    futureDCNSelected: {}
  },
  searchStatus: STATUS.IDLE,
  dealerCustomerNumberStatus: STATUS.IDLE
};
