import * as GA from '../../components/pages/account/shopping-preferences/utils/analytics';
import { getHttpInstance } from '../../services/defaultHttpService';
import endpoints from '../../constants/endpoints';
import { replaceTokensInString, isEmpty } from '../../utils';
import {
  buildUpdateRequest,
  buildStoreChangeRequest,
  getHasAccountChanged,
  getSelectedAccountValue,
  getHasDealerStoreChanged,
  buildSimpleUpdateRequest
} from './updateUtil';
import { getDefaultValueEx, valueFromName, getDefaultValue } from './util';

import * as ACTION from './constants';

import { setError, clearError } from '../exception/actions';
import { normalizeError } from '../exception/utils';
import { ERROR_DOMAIN, ERROR_PATH } from '../../constants/errorConstants';
import {
  TIMEOUT_DOUBLED,
  AFFILIATION_DEALER
} from '../../constants/commonConstants';

export const onChangeStore = storeName => ({
  type: ACTION.SP_DEALER_STORE_CHANGE,
  payload: { storeName: isEmpty(storeName) ? '' : storeName }
});

export const onChangeToaster = data => ({
  type: ACTION.SP_TOASTER,
  payload: data
});

export const onChangeFulfillment = saveAndContinue => ({
  type: ACTION.SP_FULFILLMENT_CHANGE,
  payload: saveAndContinue
});

export const requestStores = accountId => (dispatch, getState) => {
  dispatch(clearError(ERROR_DOMAIN.PREFERENCES));
  // Make request to get list of stores for this account
  // This happens when user is changing the account
  dispatch({ type: ACTION.SP_LOAD_DSN_STORES_BEGIN });
  const prefs = getState().shoppingPreferences;
  const storeId = getState().common?.storeId;
  const langId = getState().common?.langId;
  const url = replaceTokensInString(
    endpoints.PREFERENCES_DSN_STORES_LOAD,
    storeId,
    getSelectedAccountValue(prefs) || accountId,
    langId
  );

  const http = getHttpInstance();
  return http
    .get(url)
    .then(({ data }) => {
      if (isEmpty(data.dealerStore)) {
        dispatch({ type: ACTION.SP_LOAD_DSN_STORES_FAIL });
        dispatch(
          setError(ERROR_DOMAIN.PREFERENCES, ERROR_PATH.MAIN, {
            title: 'UNKNOWN_ERROR_TITLE',
            message: 'UNKNOWN_ERROR_MESSAGE'
          })
        );
      } else {
        dispatch({
          type: ACTION.SP_LOAD_DSN_STORES_SUCCESS,
          payload: {
            data
          }
        });
      }
    })
    .catch(error => {
      dispatch({ type: ACTION.SP_LOAD_DSN_STORES_FAIL, payload: error });
      dispatch(
        setError(ERROR_DOMAIN.PREFERENCES, ERROR_PATH.MAIN, {
          ...normalizeError(error)
        })
      );
    });
};

export const onChangeAccount = accountName => (dispatch, getState) => {
  const prefs = getState().shoppingPreferences;
  if (accountName === prefs.selectedAccount) {
    //  accountName did not really change, do nothing
    return;
  }

  const accountId = valueFromName(
    accountName,
    prefs.shoppingPreferencesData.customerNumber
  );

  dispatch({ type: ACTION.SP_ACCOUNT_CHANGE, payload: { accountName } });
  return dispatch(requestStores(accountId));
};

export const setNewStore =
  (languageOptions = []) =>
  (dispatch, getState) => {
    dispatch(clearError(ERROR_DOMAIN.PREFERENCES));
    const prefs = getState().shoppingPreferences;
    const commonPrefs = getState().common;
    const {
      storeId,
      langId,
      userId,
      isBuyOnBehalf = false
    } = commonPrefs || {};
    const marketingData = [...prefs.shoppingPreferencesData.marketingData];

    dispatch({ type: ACTION.SP_NEW_STORE_BEGIN });

    let url = replaceTokensInString(
      endpoints.PREFERENCES_UPDATE,
      storeId,
      langId
    );
    url += isBuyOnBehalf && userId ? `&forUserId=${userId}` : '';
    const body = buildStoreChangeRequest({ ...prefs, ...commonPrefs });
    const http = getHttpInstance();
    return http
      .put(url, body)
      .then(({ data }) => {
        dispatch({
          type: ACTION.SP_NEW_STORE_SUCCESS,
          payload: { data, marketingData, languageOptions }
        });
      })
      .catch(error => {
        dispatch({ type: ACTION.SP_NEW_STORE_FAIL, payload: error });
        dispatch(
          setError(ERROR_DOMAIN.PREFERENCES, ERROR_PATH.MAIN, {
            ...normalizeError(error)
          })
        );
      });
  };

export const onResetPreferences =
  (saveAndContinue, languageOptions = []) =>
  (dispatch, getState) => {
    dispatch(clearError(ERROR_DOMAIN.PREFERENCES));
    const prefs = getState().shoppingPreferences;
    if (saveAndContinue) {
      const hasStoreChanged = getHasDealerStoreChanged(prefs);
      const hasAccountChanged = getHasAccountChanged(prefs);
      if (hasAccountChanged) {
        return dispatch(requestStores());
      } else if (hasStoreChanged) {
        return dispatch(setNewStore(languageOptions));
      }
    }

    dispatch({
      type: ACTION.SP_PREFERENCES_RESTORE
    });
  };

export const onApplyNewSettings =
  (languageOptions = []) =>
  (dispatch, getState) => {
    dispatch(clearError(ERROR_DOMAIN.PREFERENCES));
    const prefs = getState().shoppingPreferences;
    const hasStoreChanged = getHasDealerStoreChanged(prefs);
    const hasStore = !isEmpty(prefs.selectedDealerStore);

    if (hasStoreChanged && hasStore) {
      return dispatch(onResetPreferences(true, languageOptions));
    } else if (!hasStore) {
      return dispatch(onChangeStore());
    } else {
      return dispatch(setNewStore(languageOptions));
    }
  };

export const loadPreferences =
  ({ languages = [], scrollTo, storeId: storeIdParam, requestType } = {}) =>
  (dispatch, getState) => {
    dispatch(clearError(ERROR_DOMAIN.PREFERENCES));
    const http = getHttpInstance(TIMEOUT_DOUBLED);

    dispatch({
      type: ACTION.SP_LOAD_BEGIN,
      ...(scrollTo ? { payload: { scrollTo } } : {})
    });

    const {
      common: { storeId, langId, userAffiliation }
    } = getState();

    const resolvedStoreId = storeIdParam ?? storeId;

    let url = replaceTokensInString(
      endpoints.PREFERENCES_LOAD,
      resolvedStoreId,
      langId
    );

    if (requestType) {
      url = `${url}&requestType=${requestType}`;
    }

    const urlInfo = replaceTokensInString(
      endpoints.PREFERENCES_MKT_INFO,
      resolvedStoreId,
      langId
    );

    return Promise.all([http.get(url), http.get(urlInfo)])
      .then(([r1, r2]) => {
        // to test with mock data, use this next line
        // r1.data = { ...testData.mockNoDsn, isCustomerNotAssociatedwithUCID: true };
        const responseData = r1?.data;

        const {
          isCustomerNotAssociatedwithUCID,
          dealerName,
          customerNumber,
          dealerStore
        } = responseData ?? {};

        if (
          userAffiliation &&
          userAffiliation !== AFFILIATION_DEALER &&
          isEmpty(customerNumber)
        ) {
          dispatch({ type: ACTION.SP_LOAD_FAIL });

          const title = isCustomerNotAssociatedwithUCID
            ? 'ACCOUNT_PENDING'
            : 'UNKNOWN_ERROR_TITLE';

          const message = isCustomerNotAssociatedwithUCID
            ? 'MA_PREF_PENDING_ASSOCIATION'
            : 'UNKNOWN_ERROR_MESSAGE';

          const messageParams = isCustomerNotAssociatedwithUCID
            ? [dealerName]
            : undefined;

          dispatch(
            setError(ERROR_DOMAIN.PREFERENCES, ERROR_PATH.MAIN, {
              title,
              message,
              messageParams
            })
          );
        } else {
          dispatch({
            type: ACTION.SP_LOAD_SUCCESS,
            payload: {
              ...responseData,
              marketingData: r2?.data?.shoppingPrefMaketingData,
              languageOptions: languages
            }
          });

          // If array of dealer stores is not present and there is adefault DSN, request stores
          const defaultAccountId = getDefaultValue(customerNumber);
          if (isEmpty(dealerStore) && !isEmpty(defaultAccountId)) {
            dispatch(requestStores(defaultAccountId));
          }

          const accountId = getDefaultValueEx(customerNumber);
          if (isEmpty(defaultAccountId) && !isEmpty(accountId)) {
            // this occurs only when there there is no default DSN and the DSN list is of length one.
            dispatch(requestStores(accountId));
          }
        }
      })
      .catch(error => {
        dispatch({ type: ACTION.SP_LOAD_FAIL, payload: error });
        dispatch(
          setError(ERROR_DOMAIN.PREFERENCES, ERROR_PATH.MAIN, {
            ...normalizeError(error)
          })
        );
      });
  };
export const updateImagePreference = payload => dispatch => {
  return dispatch({
    type: ACTION.UPDATE_IMAGE_PREFERENCE,
    payload
  });
};
export const updatePreference =
  (formData, trackEvent = null, languageOptions) =>
  (dispatch, getState) => {
    dispatch(clearError(ERROR_DOMAIN.PREFERENCES));
    dispatch({ type: ACTION.SP_UPDATE_BEGIN });
    const prefs = getState().shoppingPreferences;
    const commonPrefs = getState().common;
    const {
      storeId,
      langId,
      userId,
      isBuyOnBehalf = false
    } = commonPrefs || {};
    let url = replaceTokensInString(
      endpoints.PREFERENCES_UPDATE,
      storeId,
      langId
    );
    url += isBuyOnBehalf && userId ? `&forUserId=${userId}` : '';
    const body = buildUpdateRequest(
      formData,
      { ...prefs, ...commonPrefs },
      languageOptions
    );
    const http = getHttpInstance();
    const marketingData = [...prefs.shoppingPreferencesData.marketingData];
    return http
      .put(url, body)
      .then(({ data }) => {
        dispatch({
          type: ACTION.SP_UPDATE_SUCCESS,
          payload: { data, marketingData, languageOptions }
        });
        trackEvent && trackEvent(GA.gaSubmitSuccess());
        if (
          data.defaultLanguage !== prefs.shoppingPreferencesData.defaultLanguage
        ) {
          dispatch({
            type: ACTION.SP_DEFAULT_LANGUAGE_UPDATE,
            payload: { defaultLanguage: data.defaultLanguage }
          });
        } else {
          dispatch(onChangeToaster({ submitSuccess: true }));
        }
      })
      .catch(error => {
        dispatch({ type: ACTION.SP_UPDATE_FAIL, payload: error });
        const normalizedError = normalizeError(error);
        dispatch(
          setError(ERROR_DOMAIN.PREFERENCES, ERROR_PATH.MAIN, {
            ...normalizedError
          })
        );
        trackEvent && trackEvent(GA.gaSubmitFail(error, normalizedError));
      });
  };

export const onCloseError = () => dispatch => {
  dispatch(clearError(ERROR_DOMAIN.PREFERENCES));
};

/**
 *
 * @param {Object} prefObj - updated preferences
 * @param {function} successCb - optional callback to invoke on successful response
 * @param {boolean} sendOnlyParams -
 *     optional flag - when true we don't add additional attributes to request object
 * @returns {Promise}
 */
export const simplePreferenceUpdate =
  (prefObj, successCb, sendOnlyParams) => (dispatch, getState) => {
    dispatch(clearError(ERROR_DOMAIN.PREFERENCES));
    dispatch({ type: ACTION.SP_UPDATE_BEGIN });
    const prefs = getState().shoppingPreferences;
    const commonPrefs = getState().common;
    const {
      storeId,
      langId,
      userId,
      isBuyOnBehalf = false
    } = commonPrefs || {};

    const updateRequest = sendOnlyParams
      ? { ...prefObj }
      : buildSimpleUpdateRequest(prefObj, {
          ...prefs,
          ...commonPrefs
        });

    let url = replaceTokensInString(
      endpoints.PREFERENCES_UPDATE,
      storeId,
      langId
    );
    url += isBuyOnBehalf && userId ? `&forUserId=${userId}` : '';

    const http = getHttpInstance();
    return http
      .put(url, updateRequest)
      .then(({ data }) => {
        dispatch({ type: ACTION.SP_SIMPLE_UPDATE_SUCCESS, payload: data });
        dispatch(onChangeToaster({ submitSuccess: true }));
        if (successCb) {
          successCb();
        }
      })
      .catch(error => {
        dispatch(onChangeToaster({ submitSuccess: false }));
        dispatch({ type: ACTION.SP_UPDATE_FAIL, payload: error });
        const normalizedError = normalizeError(error);
        dispatch(
          setError(ERROR_DOMAIN.PREFERENCES, ERROR_PATH.MAIN, {
            ...normalizedError
          })
        );
      });
  };

// getShoppingPreferences action makes one endpoint call, where as loadPreference action makes multiple.
export const getShoppingPreferences =
  ({ storeId: storeIdParam } = {}) =>
  async (dispatch, getState) => {
    const http = getHttpInstance();

    dispatch({
      type: ACTION.SHOPPING_PREFERENCES_BEGIN
    });

    const {
      common: { storeId, langId }
    } = getState();

    const resolvedStoreId = storeIdParam ?? storeId;

    let url = replaceTokensInString(
      endpoints.PREFERENCES_LOAD,
      resolvedStoreId,
      langId
    );

    try {
      const { data } = await http.get(url);
      dispatch({
        type: ACTION.SHOPPING_PREFERENCES_SUCCESS,
        payload: { data }
      });
    } catch (error) {
      dispatch({
        type: ACTION.SHOPPING_PREFERENCES_FAIL
      });
    }
  };
