// Min length of breadcrumbs to show the back button
export const MIN_BREADCRUMBS = 2;
export const BREADCRUMBS_PT_WIDTH_SKELETON = { DESKTOP: 25, MOBILE: 50 };

// TODO: remove it on legacy cleanup
export const MY_ACCOUNT_CONTAINER_ID = 'container_MyAccountDisplayB2B';
export const ACCOUNT_CONTAINER = 'AccountContainer';
export const ACCOUNT_BREADCRUMBS = 'react-root-account-breadcrumb';
export const ACCOUNT_NAV = 'react-root-account-nav';
export const MY_ACCOUNT_ESPOT1_TARGET = 'react-root-myaccount-espot1';
export const MY_ACCOUNT_ESPOT2_TARGET = 'react-root-myaccount-espot2';

export const DEFAULT_DEALER_DATE_FORMAT = 'M/d/yy';

export const MY_ACCOUNT_PENDING_QUOTES_TOP_ESPOT = 'Placeholder_page_top_espot';
export const ORDERS_TO_APPROVE_TOP_ESPOT = 'Placeholder_page_top_espot_CAL';

export const ACTIONS_KEY = 'actions';
export const EXPIRES_KEY = 'expires';
export const QUOTE_EXPIRATION_KEY = 'quoteExpirationDate';
export const MOBILE_CELL_KEY = 'mobileCell';
export const STATUS_KEY = 'status';

export const LEGACY_ACCOUNT_DESKTOP_BREAKPOINT = 801;
export const ORDER_REFERENCE_NUMBER_TOOLTIP_SIZE = 22;
export const RETURN_POLICY_ATTRIBUTE = 'Return_Policy_Modal_Viewed';
