import { useState } from 'react';
import PropTypes from 'prop-types';
import { AlloyButton } from 'cat-ecommerce-alloy';
import { useTranslation } from 'react-i18next';
import ExceptionFeedbackBySelector from '@app/components/common/Exception/ExceptionFeedbackBySelector';
import { ERROR_DOMAIN, ERROR_PATH } from '@app/constants/errorConstants';
import endpoints from '@app/constants/endpoints';
import useHelpCenterLink from '@app/components/pages/csr-landing/hooks/useHelpCenterLink';
import { useSelector } from 'react-redux';
import { getCookie, replaceTokensInString } from '@app/utils';
import { copyToClipboard } from '../../utils';

const ShoppingCartCriticalErrorMessage = ({ isCheckoutSelector }) => {
  const [t] = useTranslation();
  const storeId = useSelector(s => s.common?.storeId);
  const langId = useSelector(s => s.common?.langId);
  const catalogId = useSelector(s => s.common?.catalogId);
  const isHelpCenterEnabled = useSelector(
    s => s.featureFlag?.PCCCustomerSupportHelpCenter
  );
  const isCatCorp = useSelector(s => s.common?.isCatCorp);
  const esiteURL = useSelector(s => s.common?.esiteURL);
  const [alreadyClicked, setAlreadyClicked] = useState(false);

  const showHelpCenter = isCatCorp || isHelpCenterEnabled;
  const { getHelpCenterContactSupportURL } = useHelpCenterLink();
  const referenceId = getCookie('X-Cat-Pcc-Session-Id');

  const oldContactUsPageLink = replaceTokensInString(
    endpoints.CONTACT_US_DEALER,
    catalogId,
    langId,
    storeId
  );

  const contactUsLink = showHelpCenter
    ? getHelpCenterContactSupportURL()
    : `${esiteURL}${oldContactUsPageLink}`;

  const handleClickContactUs = () => {
    if (showHelpCenter) {
      window.open(contactUsLink, '_blank');
    } else {
      window.location.href = contactUsLink;
    }
  };

  const handleCopyReferenceId = () => {
    copyToClipboard(referenceId);
    setAlreadyClicked(true);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const errorDomainSelector = isCheckoutSelector
    ? ERROR_DOMAIN.CHECKOUT
    : ERROR_DOMAIN.CART;

  return (
    <ExceptionFeedbackBySelector
      className="mb-2"
      selector={state =>
        state?.errors?.[errorDomainSelector]?.[
          ERROR_PATH.SHOPPING_CART_CRITICAL_ERROR
        ]
      }
    >
      <div className="d-flex align-items-center my-4">
        <span
          className={`m-0 ${
            alreadyClicked ? 'u-color--MediumGray' : 'u-color--DarkGray'
          }`}
        >
          {t('ORDER_DETAILS_REFERENCE_ID')}: {referenceId}
        </span>
        {!alreadyClicked && (
          <AlloyButton
            buttonType="ghost"
            size="small"
            onClick={handleCopyReferenceId}
            className="ms-2"
          >
            {t('COPY')}
          </AlloyButton>
        )}
      </div>
      <div>
        <AlloyButton buttonType="secondary" size="small" onClick={refreshPage}>
          {t('REFRESH')}
        </AlloyButton>
        <AlloyButton
          buttonType="ghost"
          size="small"
          onClick={handleClickContactUs}
        >
          {t('FOOTER_CONTACT_US')}
        </AlloyButton>
      </div>
    </ExceptionFeedbackBySelector>
  );
};

ShoppingCartCriticalErrorMessage.propTypes = {
  isCheckoutSelector: PropTypes.bool
};

export default ShoppingCartCriticalErrorMessage;
