import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CartSaveToListButton from '../../common/CartSaveToListButton';
import { getProductURL, getGAItem } from '../utils';
import styles from './cellStyles.module.scss';
import useAnalytics from '../../../../../../../hooks/useAnalytics/useAnalytics';
import { LIST_PCC_CART_PAGE } from '../../../../../../../constants/analyticsConstants';
import LineItemAttributes from '../../common/LineItemAttributes';
import { STATUS } from '../../../../../../../constants/commonConstants';
import { getPartName } from '@app/utils/commonUtils';
import { PCC_HEADLESS_PDP_FLAG } from '@app/constants/featureFlags';

const NameCell = ({ item, rowIndex }) => {
  const { fireProductClickEvent } = useAnalytics();
  const { unitOfMeasure } = useSelector(state => state?.dealer) || '';
  const savedListsStatus = useSelector(s => s.account.savedListsStatus);
  const isPDPHeadlessFeatureFlagEnable =
    useSelector(s => s.featureFlag?.[PCC_HEADLESS_PDP_FLAG]) ?? false;
  const esiteURL = useSelector(s => s.common?.esiteURL);
  const {
    partNumber,
    name,
    seoURL,
    orderItemExtendAttribute: { unitWeight = '' } = {}
  } = item;

  const checkUnitWeight =
    !isNaN(parseFloat(unitWeight)) && !!parseFloat(unitWeight);
  const storeName = useSelector(s => s.store?.name) || '';
  const { emailCart } = useSelector(s => s.common);
  const handleClickEvent = () => {
    const gaItem = getGAItem(item, {
      rowIndex,
      storeName,
      emailCart,
      isPartNameJoined: false
    });

    fireProductClickEvent({
      list: LIST_PCC_CART_PAGE,
      products: [gaItem]
    });
  };

  const getupdatedName = (name, partNumber) => {
    if (name?.startsWith(partNumber + ':')) {
      return name?.replace(partNumber + ':', '');
    } else if (name?.startsWith(partNumber + ' ')) {
      return name?.replace(partNumber + ' ', '');
    } else {
      return name;
    }
  };

  const partName = getPartName(partNumber, getupdatedName(name, partNumber));
  const urlBasedonFeatureFlag = isPDPHeadlessFeatureFlagEnable
    ? `${esiteURL}/product/${partNumber}`
    : getProductURL(seoURL);

  return (
    <div className="d-flex flex-column">
      {partName && (
        <div>
          <p
            className={`exclude-global ${styles['product-description']}  ${styles['print__product-description']}`}
          >
            {seoURL ? (
              <>
                <a
                  className="d-print-none"
                  href={urlBasedonFeatureFlag}
                  onClick={() => handleClickEvent()}
                >
                  {partName}
                </a>
                <span
                  className={`d-none d-print-block u-color--Black m-0 ${styles['product-description']} ${styles['print__product-description']}`}
                >
                  {partName}
                </span>
              </>
            ) : (
              partName
            )}
          </p>
        </div>
      )}
      {!item.packageError && checkUnitWeight && unitOfMeasure && (
        <div className="my-1">
          <p
            className={`exclude-global m-0 ${styles['product-weight']}`}
          >{`${unitWeight} ${unitOfMeasure}`}</p>
        </div>
      )}

      {!item.packageError && (
        <div className="d-none d-md-block">
          <LineItemAttributes item={item} />
          {savedListsStatus !== STATUS.PENDING && (
            <CartSaveToListButton item={item} />
          )}
        </div>
      )}
    </div>
  );
};

NameCell.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    partNumber: PropTypes.string,
    seoURL: PropTypes.string,
    orderItemId: PropTypes.string,
    orderItemExtendAttribute: PropTypes.shape({
      unitWeight: PropTypes.string
    }),
    packageError: PropTypes.shape({
      packageQuantity: PropTypes.number
    })
  }),
  isMobile: PropTypes.bool,
  rowIndex: PropTypes.number
};

export default NameCell;
