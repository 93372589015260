import { v4 } from 'uuid';
import { clearError, setError } from '../store/exception/actions';
import { replaceTokensInString } from './stringUtils';

/**
 * util to process paste in product form input
 * @param {Object} param0
 * @param {Function} pararm.addRow Formik FieldArray prop
 * @param { Function} createSuccessToast methods from toast context
 * @param {Object} param.defaultRowValues form default row object
 * @param {Function} param.dispatch redux dispatch
 * @param {Object} param.errorInfo {domain: error domain, path: error path, message: error message}
 * @param {event} param.event paste event
 * @param {number} param.maxRows max number of row for the form
 * @param {number} param.numRows number of existing rows in form
 * @param {number} param.rowNum index of row where paste occurred
 * @param {Function} param.setFieldValue formik function
 * @param {String} param.toastMessage message to show in the toast
 * @param {Object} param.values formik context values
 * @returns void
 */
export const processPaste = ({
  addRow,
  createSuccessToast,
  defaultRowValues,
  dispatch,
  errorInfo,
  event,
  fileData,
  maxRows,
  numRows,
  rowNum,
  setFieldValue,
  toastMessage,
  values
}) => {
  let error;
  try {
    let entries = fileData;
    if (!fileData) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const data = clipboardData.getData('Text');
      const isCsvTsv = /[,\t]+/.test(data);
      if (isCsvTsv) {
        // block paste if value is CSV or TSV.
        event.stopPropagation();
        event.preventDefault();
      }
      // remove leading and trailing spaces. Split on line end
      entries = data
        ?.replace(
          /(^[\f\n\r\v\u00A0\u2028\u2029]+)|([\f\n\r\v\u00A0\u2028\u2029]+$)/g,
          ''
        )
        ?.split('\n');
    }
    if (entries.length + rowNum > maxRows) {
      return dispatch(
        setError(errorInfo.domain, errorInfo.path, {
          message: errorInfo.message
        })
      );
    }
    dispatch(clearError(errorInfo.domain, errorInfo.path));
    const columnKeys = Object.keys(defaultRowValues);
    let validRows = 0;
    const newItems = entries.reduce(
      (acc, item) => {
        // split on , or tab
        const list = item
          .replace(/^\t/, ' \t') // leading tab
          .replace(/\t\t/, '\t \t')
          .replace(/,,/, ', ,')
          .replace(/^,/, ' ,') // leading comma
          .match(/(?:[^,\t"]+|"[^"]*")+/g);
        if (
          list[0] &&
          list[1] &&
          !isNaN(list[0]) &&
          !isNaN(parseFloat(list[0])) // whitespace string
        ) {
          const row = rowNum + validRows;
          validRows++;
          const newValues = columnKeys.reduce((acc, key, i) => {
            // remove leading and trailing " or spaces
            const v = list[i]?.replace(/^"|^"|"$/g, '').trim() ?? '';
            return { ...acc, [key]: v };
          }, values.items[row] ?? {});
          // id property for every row item, it is used as key and referenceId
          acc[row] = { ...(acc[row] || {}), ...newValues, id: v4() };
        }
        return acc;
      },
      [...values.items]
    );
    if (validRows) {
      setFieldValue('items', newItems);
      if (toastMessage) {
        createSuccessToast(replaceTokensInString(toastMessage, validRows));
      }
      const lastRowAfterPaste = rowNum + validRows;
      if (lastRowAfterPaste !== maxRows && lastRowAfterPaste >= numRows) {
        addRow(lastRowAfterPaste);
      }
    }
  } catch (err) {
    error = err;
  }
  // return error to allow caller to handle file processing error
  if (error) {
    return { error };
  }
};

/**
 * util to find the target row to paste the file values
 * @param {Object} values formik context values.items
 * @returns string
 */
export const getLastRowWithValues = values => {
  return values.reduce((acc, item, i) => {
    if (
      item.quantity ||
      item.partNumber ||
      item.assetId ||
      item.customerItemNumber ||
      item.customerPartNumber ||
      item.lineItemNote
    ) {
      return i;
    }
    return acc;
  }, -1);
};
