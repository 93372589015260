import React from 'react';
import { AlloyButton } from 'cat-ecommerce-alloy';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { replaceTokensInString, normalizeUrl } from '@app/utils';
import links from '@app/constants/links';
import { navigateToUrl } from '@app/services/windowService';
import { USER_TYPE_GUEST } from '@app/constants/commonConstants';

const ShoppingPreferenceCTA = ({ type, buttonText }) => {
  const [t] = useTranslation();
  const userType = useSelector(s => s.common.userType);
  const isLoggedIn = userType !== USER_TYPE_GUEST;
  const storeId = useSelector(state => state?.common?.storeId);
  const langId = useSelector(state => state?.common?.langId);
  const catalogId = useSelector(state => state?.common?.catalogId);
  const { SHOPPING_PREFERENCES_URL } = links;
  const getLinkUrl = url => {
    const link = replaceTokensInString(url, catalogId, storeId, langId);
    return normalizeUrl(link);
  };
  const redirect = () => {
    const url = getLinkUrl(SHOPPING_PREFERENCES_URL);
    navigateToUrl(url);
  };

  return isLoggedIn ? (
    <AlloyButton
      buttonType={type}
      size="small"
      type="submit"
      onClick={redirect}
    >
      {buttonText ? buttonText : t('CAT_SHOPPING_PREFERENCES_TITLE')}
    </AlloyButton>
  ) : null;
};

export default ShoppingPreferenceCTA;
