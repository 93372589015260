import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DealerLocationExpanderButtonText = ({ distance, isClosest, uom }) => {
  const [t] = useTranslation();
  const unit = uom ? ` ${uom}` : '';
  const shouldShowDistance = distance && distance !== '0.0';
  return (
    <p className="m-0 text-end u-color--DarkGray px-1">
      {isClosest && (
        <>
          <span
            className={`exclude-global text-uppercase text-xs text-univers--bold line-height-167 u-color--DarkGray`}
          >
            {t('FYD_RESULTS_CLOSEST')}
          </span>
          <br />
        </>
      )}
      <em className="text-md text-nowrap">{`${
        shouldShowDistance ? `${distance}${unit}` : ''
      }`}</em>
    </p>
  );
};

export default DealerLocationExpanderButtonText;

DealerLocationExpanderButtonText.propTypes = {
  distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isClosest: PropTypes.bool,
  uom: PropTypes.string
};
