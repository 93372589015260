import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DismissedNotifications } from '@cat-ecom/pcc-components';
import { dismissNotification } from '@app/store/notificationHeader/action';

const DismissNotification = ({
  notificationId,
  isDismissed,
  dynamicClassName
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch(
      dismissNotification(
        {
          notifications: [
            { notificationId: notificationId, isDismissed: true, isRead: true }
          ]
        },
        true
      )
    );
  };

  const handleClose = e => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  return (
    <DismissedNotifications
      isDismissed={isDismissed}
      handleClose={handleClose}
      dismissedHoverLabel={t('NOTIFICATIONS_DISMISSED_HOVER_LABEL')}
      handleDismiss={handleDismiss}
      dismissHoverLabel={t('DISMISS')}
      dynamicClassName={dynamicClassName}
    />
  );
};

export default DismissNotification;

DismissNotification.propTypes = {
  notificationId: PropTypes.number.isRequired,
  isDismissed: PropTypes.bool,
  dynamicClassName: PropTypes.string
};
