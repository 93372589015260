export const ORDERS_TO_APPROVE_GET_BEGIN = 'ORDERS_TO_APPROVE_GET_BEGINS';
export const ORDERS_TO_APPROVE_GET_SUCCESS = 'ORDERS_TO_APPROVE_GET_SUCCESS';
export const ORDERS_TO_APPROVE_GET_FAIL = 'ORDERS_TO_APPROVE_GET_FAIL';

export const ORDERS_TO_APPROVE_SEARCH_FORM_SET =
  'ORDERS_TO_APPROVE_SEARCH_FORM_SET';

export const ORDERS_TO_APPROVE_STATUS_CHANGE_BEGIN =
  'ORDERS_TO_APPROVE_STATUS_CHANGE_BEGIN';
export const ORDERS_TO_APPROVE_STATUS_CHANGE_SUCCESS =
  'ORDERS_TO_APPROVE_STATUS_CHANGE_SUCCESS';
export const ORDERS_TO_APPROVE_STATUS_CHANGE_FAIL =
  'ORDERS_TO_APPROVE_STATUS_CHANGE_FAIL';

export const ORDERS_PENDING_TO_APPROVE_NUMBER_BEGIN =
  'ORDERS_PENDING_TO_APPROVE_NUMBER_BEGIN';
export const ORDERS_PENDING_TO_APPROVE_NUMBER_SUCCESS =
  'ORDERS_PENDING_TO_APPROVE_NUMBER_SUCCESS';
export const ORDERS_PENDING_TO_APPROVE_NUMBER_FAIL =
  'ORDERS_PENDING_TO_APPROVE_NUMBER_FAIL';
export const AC_ORDERS_TO_APPROVE_SYSTEMFEEDBACK =
  'AC_ORDERS_TO_APPROVE_SYSTEMFEEDBACK';

export const APPROVE_REJECT_ORDER_TO_APPROVE_BEGIN =
  'APPROVE_REJECT_ORDER_TO_APPROVE_BEGIN';

export const APPROVE_REJECT_ORDER_TO_APPROVE_SUCCESS =
  'APPROVE_REJECT_ORDER_TO_APPROVE_SUCCESS';

export const APPROVE_REJECT_ORDER_TO_APPROVE_FAIL =
  'APPROVE_REJECT_ORDER_TO_APPROVE_FAIL';

export const PLACE_PENDING_ORDER_BEGIN = 'PLACE_PENDING_ORDER_BEGIN';
export const PLACE_PENDING_ORDER_SUCCESS = 'PLACE_PENDING_ORDER_SUCCESS';
export const PLACE_PENDING_ORDER_FAIL = 'PLACE_PENDING_ORDER_FAIL';

export const DELETE_PENDING_ORDER_BEGIN = 'DELETE_PENDING_ORDER_BEGIN';
export const DELETE_PENDING_ORDER_SUCCESS = 'DELETE_PENDING_ORDER_SUCCESS';
export const DELETE_PENDING_ORDER_FAIL = 'DELETE_PENDING_ORDER_FAIL';

export const ABLE_TO_EDIT_ORDER_BEGIN = 'ABLE_TO_EDIT_ORDER_BEGIN';
export const ABLE_TO_EDIT_ORDER_SUCCESS = 'ABLE_TO_EDIT_ORDER_SUCCESS';
export const ABLE_TO_EDIT_ORDER_FAILURE = 'ABLE_TO_EDIT_ORDER_FAILURE';
export const SET_EDIT_STATUS_TO_IDLE = 'SET_EDIT_STATUS_TO_IDLE';

export const SAVE_PONUMBER_SERIAL_AND_RBD_BEGIN =
  'SAVE_PONUMBER_SERIAL_AND_RBD_BEGIN';
export const SAVE_PONUMBER_SERIAL_AND_RBD_SUCCESS =
  'SAVE_PONUMBER_SERIAL_AND_RBD_SUCCESS';
export const SAVE_PONUMBER_SERIAL_AND_RBD_FAIL =
  'SAVE_PONUMBER_SERIAL_AND_RBD_FAIL';
