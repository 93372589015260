import { useContext } from 'react';
import { ModalContext, AlloyButton } from 'cat-ecommerce-alloy';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ClearSearchButton = ({ removeResultsModal, resetDealerSearchForm }) => {
  const { t } = useTranslation();
  const { removeCurrentModal } = useContext(ModalContext);
  const clearSearch = () => {
    removeCurrentModal();
    removeResultsModal && removeResultsModal();
    resetDealerSearchForm();
  };
  return (
    <AlloyButton className={'mt-3'} buttonType="ghost" onClick={clearSearch}>
      {t('CLEAR_SEARCH')}
    </AlloyButton>
  );
};

export default ClearSearchButton;

ClearSearchButton.propTypes = {
  removeResultsModal: PropTypes.func,
  resetDealerSearchForm: PropTypes.func.isRequired
};
