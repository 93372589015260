import { useSelector } from 'react-redux';
import AvailabilityByDateContent from '../AvailabilityByDateContent';
import AvailabilityBySourceContent from '../AvailabilityBySourceContent';
import { useTranslation } from 'react-i18next';
import { AlloyButton, useModal, Modal } from 'cat-ecommerce-alloy';
import PropTypes from 'prop-types';

const MobileModalSecondaryAvailablity = props => {
  const [t] = useTranslation();
  const showAvailabilityBySource = useSelector(
    state =>
      !!state?.shoppingPreferences?.shoppingPreferencesData
        ?.showSourcingDetailToggle
  );

  const modalProps = useModal();

  return (
    <>
      <AlloyButton
        buttonType="ghost"
        size="large"
        className="p-0 text-sans-serif text-sm text-start text-transform-none"
        onClick={() => modalProps.open()}
      >
        {showAvailabilityBySource
          ? t('AVAIL_STANDARD_AVAILAIBILITY_DATES')
          : t('AVAIL_STANDARD_AVAILABILITY_SOURCE')}
      </AlloyButton>
      <ParentModalContent {...modalProps} {...props} />
    </>
  );
};
export default MobileModalSecondaryAvailablity;

export const ParentModalContent = props => {
  const [t] = useTranslation();
  const { isOpen, close } = props;
  const showAvailabilityBySource = useSelector(
    state =>
      !!state?.shoppingPreferences?.shoppingPreferencesData
        ?.showSourcingDetailToggle
  );
  const childModalProps = useModal({ isChild: true, parentIsOpen: isOpen });
  return (
    <Modal isOpen={isOpen} close={close} title={t('AVAILABILITY')}>
      {showAvailabilityBySource ? (
        <>
          <AvailabilityByDateContent
            {...props}
            {...childModalProps}
            parentIsOpen={isOpen}
          />
        </>
      ) : (
        <>
          <AvailabilityBySourceContent
            {...props}
            {...childModalProps}
            parentIsOpen={isOpen}
          />
        </>
      )}
    </Modal>
  );
};
ParentModalContent.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func
};
