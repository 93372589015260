import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HeaderStoreLocationButton } from '@cat-ecom/pcc-components';
import { AFFILIATION_DEALER } from '@app/constants/commonConstants';

const StoreLocationSelector = ({
  closeDrawer,
  openAssociatedDealerDrawer,
  openDealerLocatorDrawer
}) => {
  const isCSPCustomer = useSelector(s => s.common?.isCSPCustomer);
  const userAffiliation = useSelector(s => s.common?.userAffiliation);
  const userType = useSelector(s => s.common?.userType);

  const isLoggedIn = userType && userType !== 'G';
  const isDealerUser = userAffiliation === AFFILIATION_DEALER;

  const changeLocationLabel =
    isCSPCustomer || isDealerUser
      ? 'CHANGE_ADP_CSP_CUST'
      : 'MA_DROPDOWN_IA_CHANGE_STORE';

  const handleChangeLocationClick = useCallback(() => {
    closeDrawer();

    if (isLoggedIn) {
      openAssociatedDealerDrawer();
    } else {
      openDealerLocatorDrawer();
    }
  }, [
    closeDrawer,
    isLoggedIn,
    openAssociatedDealerDrawer,
    openDealerLocatorDrawer
  ]);

  const { t } = useTranslation();

  return (
    <HeaderStoreLocationButton
      label={t(changeLocationLabel)}
      onClick={handleChangeLocationClick}
      name={changeLocationLabel}
    />
  );
};

StoreLocationSelector.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  openAssociatedDealerDrawer: PropTypes.func.isRequired,
  openDealerLocatorDrawer: PropTypes.func.isRequired
};

export default StoreLocationSelector;
