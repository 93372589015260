import { useState } from 'react';
import useFilterNotification from '@app/components/common/UserNotification/useFilterNotification';
import { useDrawer } from '@cat-ecom/pcc-components';
import PropTypes from 'prop-types';
import NotificationDrawerWrapper from '../../../common/UserNotification/NotificationDrawerWrapper';
import NotificationFiltersDrawerWrapper from '../../../common/UserNotification/NotificationFilters/NotificationFiltersDrawerWrapper';

const NotificationDrawer = ({ drawerProps, closeDrawer }) => {
  const [listOfOrderStatus, setListOfOrderStatus] = useState([]);
  const filterDrawer = useDrawer({
    onClose: () => {
      document.getElementById('notification-heading')?.focus();
    }
  });
  const { filterResult, handleFilter } = useFilterNotification();

  return (
    <>
      <NotificationDrawerWrapper
        drawerProps={drawerProps}
        closeDrawer={closeDrawer}
        handleFilterDrawer={filterDrawer.initiatorProps}
        filterResult={filterResult}
        handleFilter={handleFilter}
        setListOfOrderStatus={setListOfOrderStatus}
      />
      <NotificationFiltersDrawerWrapper
        drawerProps={filterDrawer.drawerProps}
        closeDrawer={filterDrawer.closeDrawer}
        filterResult={filterResult}
        listOfOrderStatus={listOfOrderStatus}
        setListOfOrderStatus={setListOfOrderStatus}
      />
    </>
  );
};

NotificationDrawer.propTypes = {
  drawerProps: PropTypes.shape({
    isActive: PropTypes.bool
  }),
  closeDrawer: PropTypes.func
};

export default NotificationDrawer;
