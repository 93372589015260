import PropTypes from 'prop-types';
import {
  Drawer,
  MyEquipmentHeader,
  MyEquipmentForm,
  useDrawer,
  MyEquipmentSerialInput,
  MyEquipmentLink,
  MyEquipmentNickname,
  MyEquipmentButton,
  MyEquipmentCatIconSuccess,
  MyEquipmentSaveFooter,
  MyEquipmentFooter
} from '@cat-ecom/pcc-components';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  FIND_SERIAL_ID,
  FORM_FIELD_NICK_NAME,
  FORM_FIELD_SERIAL,
  DELETE_ID,
  WIDGET_IDENTIFIER
} from '../constant';
import AddEquipmentFindSerialDrawer from '../AddEquipmentDrawer/AddEquipmentFindSerialDrawer';
import { CatHeading } from 'blocks-react/bedrock/components/Heading';
import styles from './EditEquipmentDrawer.module.scss';
import SelectedEquipmentInfo from '../SelectedEquipmentInfo/SelectedEquipmentInfo';
import DeleteEquipmentDrawer from './DeleteEquipmentDrawer';
import {
  addOrUpdateEquipmentInWishList,
  clearErrors,
  validationSerialNumber
} from '@app/store/myequipment/actions';
import { validateSerialNumber } from '../AddEquipmentDrawer/utils';
import { useAnalytics } from '@app/hooks';
import { useTracking } from 'react-tracking';
import { formSubmittedEventPayloads, getFormattedSerialNumber } from '../utils';
import { isEmpty, replaceTokensInString } from '@app/utils';
import {
  HTML_SPECIAL_CHAR_ERROR,
  SEARCH_LENGTH,
  STATUS
} from '@app/constants/commonConstants';
import { CatLoadingIndicator } from 'blocks-react/bedrock/components/LoadingIndicator';
import ErrorBanner from '../ErrorBanner';
import { setEquipmentSaveSuccess } from '../../../../store/myequipment/actions';
import { capitalizeText } from '@app/utils/stringUtils';
import SerialPrefix from './SerialPrefix';
import { hasHtmlInjectionTags } from '@app/utils/commonUtils';

const EditEquipmentDrawer = ({
  drawerProps,
  currentEquipment = {},
  onDrawerClose,
  isChild
}) => {
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();
  const formMethods = useForm({
    defaultValues: {
      serial: currentEquipment.serialNumber ?? '',
      nickName: currentEquipment.assetId ?? ''
    },
    mode: 'all'
  });

  const [t] = useTranslation();
  const isCSPCustomer = useSelector(state => state.common.isCSPCustomer);
  const isLoading = useSelector(state => state.myEquipment.isLoading);
  const { equipmentSaveSuccessful } = useSelector(s => s.myEquipment);
  const { formState } = formMethods;
  const { isValid, isSubmitting, isDirty } = formState;

  const { fireFormSubmittedEvent } = useAnalytics();

  const drawerSerialLink = useDrawer({
    id: FIND_SERIAL_ID
  });

  const {
    drawerProps: deleteDrawerProps,
    handleOpen: handleDeleteOpen,
    handleClose: handleDeleteClose
  } = useDrawer({
    id: DELETE_ID,
    size: 'default'
  });

  const {
    model,
    productId,
    equipmentUniqueId,
    equipmentFamily = ''
  } = currentEquipment ?? {};
  const serialNumValidationStatus = useSelector(
    state => state.myEquipment.serialNumValidationStatus
  );

  const serialValidationLoading = serialNumValidationStatus === STATUS.PENDING;

  const isSerialValid =
    formMethods.getValues(FORM_FIELD_SERIAL).length === 8 &&
    !serialValidationLoading &&
    !formMethods.getFieldState(FORM_FIELD_SERIAL).error;

  const disableEdit =
    !isValid ||
    isSubmitting ||
    (!isSerialValid && formMethods.getValues(FORM_FIELD_SERIAL).length !== 0) ||
    !isDirty;

  const noEquipmentInfo =
    !isEmpty(currentEquipment) && currentEquipment.uniqueIndex.length === 0;
  const emptyUniqueNumber = !isEmpty(currentEquipment) ? noEquipmentInfo : true;

  const drawerTitle = emptyUniqueNumber ? 'SAVE_EQUIP' : 'MEQ_EDIT_EQUIP';

  const onClearError = useCallback(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  const onDelete = () => {
    onClearError();
    handleDeleteOpen();
  };

  const serialLinkDrawer = e => {
    e.stopPropagation();
    drawerSerialLink.initiatorProps.onClick();
  };

  const onClose = useCallback(
    (_e = null, closeParent = false) => {
      onClearError();
      formMethods.reset();
      onDrawerClose(closeParent);
    },
    [formMethods, onClearError, onDrawerClose]
  );

  const setFieldError = (field, message) => {
    if (field === 'serialNumber') {
      formMethods.setError(FORM_FIELD_SERIAL, {
        type: 'serverError',
        message: message
      });
    } else if (field === 'assetId') {
      formMethods.setError(FORM_FIELD_NICK_NAME, {
        type: 'serverError',
        message: message
      });
    }
  };

  const handleEditEquipment = formValue => {
    if (disableEdit) {
      return;
    }
    const serialNumber = getFormattedSerialNumber(formValue[FORM_FIELD_SERIAL]);
    const assetId = formValue[FORM_FIELD_NICK_NAME];
    const formSubmittedEventPayload = formSubmittedEventPayloads(
      serialNumber,
      currentEquipment,
      isCSPCustomer,
      false
    );
    const toastContent = replaceTokensInString(
      t('EDIT_EQUIP_SUCCESSFUL'),
      model,
      capitalizeText(equipmentFamily)
    );
    dispatch(
      addOrUpdateEquipmentInWishList(
        { ...currentEquipment, serialNumber, assetId },
        setFieldError,
        trackEvent,
        null,
        !emptyUniqueNumber ? true : false,
        toastContent,
        fireFormSubmittedEvent,
        formSubmittedEventPayload
      )
    );
  };

  const handleSearch = str => {
    dispatch(
      validationSerialNumber(currentEquipment, setFieldError, str, true, null)
    );
  };

  const serialSearch = str => {
    const validateSearch = async str => {
      await formMethods.trigger(FORM_FIELD_SERIAL);

      if (!formMethods.getFieldState(FORM_FIELD_SERIAL).error) {
        handleSearch(str);
      }
    };

    onClearError();
    if (str.length === 8) {
      validateSearch(str);
    }
  };

  useEffect(() => {
    formMethods.setValue(
      FORM_FIELD_SERIAL,
      currentEquipment.serialNumber ?? ''
    );
    formMethods.setValue(FORM_FIELD_NICK_NAME, currentEquipment.assetId ?? '');
  }, [currentEquipment.assetId, currentEquipment.serialNumber, formMethods]);

  useEffect(() => {
    return () => dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    if (equipmentSaveSuccessful) {
      dispatch(setEquipmentSaveSuccess(false));
      onClose(null, isChild);
    }
  }, [dispatch, equipmentSaveSuccessful, isChild, onClose]);
  return (
    <div id={styles['edit-equipment']}>
      <MyEquipmentForm
        formMethods={formMethods}
        handleSubmit={formMethods.handleSubmit(handleEditEquipment)}
      >
        <Drawer {...drawerProps}>
          <MyEquipmentHeader
            variant={'title-sm'}
            className="d-flex"
            onBackButtonClick={isChild ? onClose : null}
          >
            {t(drawerTitle)}
          </MyEquipmentHeader>
          <ErrorBanner />
          {isLoading ? (
            <div className={`${styles['loader-container']} text-center`}>
              <CatLoadingIndicator />
            </div>
          ) : (
            <>
              <SelectedEquipmentInfo
                currentEquipment={currentEquipment}
                identifier={WIDGET_IDENTIFIER.EDIT}
              />
              <hr className={`${styles['hr--border']} mx-n3`} />
              <div className="mb-3">
                <CatHeading variant="label-lg">
                  {t('BY_SERIAL_EQUIP_SEARCH_DESC')}
                </CatHeading>
              </div>
              <div className="position-relative">
                <MyEquipmentSerialInput
                  placeholder={t('ORDER_HISTORY_SERIALNUM_PLACEHOLDER')}
                  name={FORM_FIELD_SERIAL}
                  maxLength={SEARCH_LENGTH.MAX}
                  onChange={value => {
                    serialSearch(value);
                  }}
                  rules={{
                    validate: {
                      validateSerialNumber: fieldValue => {
                        if (fieldValue.length === 0) {
                          return true;
                        }
                        return (
                          validateSerialNumber(fieldValue).isValid ||
                          t('MEQ_SERIAL_INVALID')
                        );
                      }
                    }
                  }}
                  dataCsMask
                />
                <MyEquipmentCatIconSuccess showIcon={isSerialValid} />
                {serialValidationLoading && (
                  <CatLoadingIndicator
                    className={`${styles['inline-loader-container']}`}
                  />
                )}
              </div>
              <SerialPrefix
                model={model}
                productId={productId}
                equipmentUniqueId={equipmentUniqueId}
              />
              <MyEquipmentLink
                onClick={e => serialLinkDrawer(e)}
                className={`${styles['find-equipment-guide-text']} mb-3 p-0`}
              >
                <span className={`${styles['edit-button-link--padding']}`}>
                  {t('FIND_SERIAL_GUIDE_TEXT')}
                </span>
              </MyEquipmentLink>
              <MyEquipmentNickname
                placeholder={t('MEQ_NICKNAME')}
                name={FORM_FIELD_NICK_NAME}
                rules={{
                  validate: {
                    validateNickName: value => {
                      return hasHtmlInjectionTags(
                        value,
                        replaceTokensInString(
                          t('ADD_INV_CHAR_NEW_FIELD_WARNING'),
                          '',
                          HTML_SPECIAL_CHAR_ERROR
                        )
                      );
                    }
                  }
                }}
                hideLabel
                onChange={() => {
                  onClearError();
                }}
              />
              {!emptyUniqueNumber && (
                <MyEquipmentButton
                  type="button"
                  onClick={onDelete}
                  className="mt-3"
                  variant="danger"
                  disabled={isDirty}
                >
                  {t('DELETE_EQUIPMENT')}
                </MyEquipmentButton>
              )}
            </>
          )}
          <MyEquipmentFooter>
            <MyEquipmentSaveFooter
              disableSave={disableEdit}
              saveLabel={t('SAVE')}
              cancelLabel={t('CANCEL')}
              cancelClass={'ps-3'}
              onCancelHandler={onClose}
            />
          </MyEquipmentFooter>
        </Drawer>
      </MyEquipmentForm>
      <AddEquipmentFindSerialDrawer
        drawerSerialLink={drawerSerialLink}
        isChild={true}
      />
      {!emptyUniqueNumber && (
        <DeleteEquipmentDrawer
          drawerProps={deleteDrawerProps}
          onClose={(closeParent = null) => {
            handleDeleteClose();
            if (closeParent) {
              onClose(null, isChild);
            }
          }}
          currentEquipment={currentEquipment}
          isChild={true}
        />
      )}
    </div>
  );
};

EditEquipmentDrawer.propTypes = {
  drawerProps: PropTypes.object,
  currentEquipment: PropTypes.object,
  onDrawerClose: PropTypes.func,
  isChild: PropTypes.bool
};

export default EditEquipmentDrawer;
