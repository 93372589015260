import * as types from './constants';
import * as headerTypes from '../header/constants';
import { categoriesInitialStore } from './initialStore';
import { STATUS } from '@app/constants/commonConstants';

const categoriesReducer = (state = categoriesInitialStore, action) => {
  switch (action.type) {
    case types.CATEGORY_GET_DETAILS_BEGIN: {
      return {
        ...state,
        getCategoryDetailsStatus: STATUS.PENDING
      };
    }
    case types.CATEGORY_GET_DETAILS_SUCCESS: {
      const { payload } = action;
      const { data, category } = payload;
      return {
        ...state,
        getCategoryDetailsStatus: STATUS.RESOLVED,
        byId: {
          ...state.byId,
          [category]: {
            ...state.byId[category],
            ...data
          }
        }
      };
    }
    case types.CATEGORY_GET_DETAILS_FAIL: {
      return {
        ...state,
        getCategoryDetailsStatus: STATUS.REJECTED
      };
    }
    case types.CATEGORY_SET_DATA: {
      const { payload } = action;
      const { data, category } = payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [category]: {
            ...state.byId[category],
            ...data
          }
        }
      };
    }
    case types.CATEGORY_UPDATE_PRODUCTS: {
      const { payload } = action;
      const { productsId, category } = payload;

      return {
        ...state,
        byId: {
          ...state.byId,
          [category]: {
            ...state.byId[category],
            productsId: productsId ?? []
          }
        }
      };
    }

    case types.GET_HOMEPAGE_CATEGORIES_BEGIN: {
      const { payload } = action;
      return {
        ...state,
        [payload.statusKey]: STATUS.PENDING,
        [payload.statusErrorKey]: undefined
      };
    }

    case types.GET_HOMEPAGE_CATEGORIES_FAIL: {
      const { payload } = action;
      return {
        ...state,
        [payload.statusKey]: STATUS.REJECTED,
        [payload.statusErrorKey]: payload.error
      };
    }
    case types.GET_HOMEPAGE_CATEGORIES_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        categoriesRecentlyPurchased: payload.categoriesRecentlyPurchased,
        [payload.statusKey]: STATUS.RESOLVED,
        [payload.statusErrorKey]: undefined
      };
    }

    case types.CATEGORIES_GET_INFO_SUCCESS: {
      const {
        payload: { allCategories, shopByCategory }
      } = action;
      return {
        ...state,
        allCategories,
        shopByCategory
      };
    }

    case types.CATEGORIES_GET_INFO_FAIL: {
      return { ...state };
    }

    case headerTypes.HEADER_GET_INFO_BEGIN: {
      return {
        ...state,
        categoriesStatus: STATUS.PENDING
      };
    }

    case headerTypes.HEADER_GET_INFO_SUCCESS: {
      return {
        ...state,
        categoriesStatus: STATUS.RESOLVED
      };
    }

    case headerTypes.HEADER_GET_INFO_FAIL: {
      return {
        ...state,
        categoriesStatus: STATUS.REJECTED
      };
    }

    case types.LUCID_CATEGORY_GET_DETAILS_BEGIN: {
      return {
        ...state,
        lucidProductCategories: {
          ...categoriesInitialStore.lucidProductCategories,
          status: STATUS.PENDING
        }
      };
    }

    case types.LUCID_CATEGORY_GET_DETAILS_SUCCESS: {
      return {
        ...state,
        lucidProductCategories: {
          data: action.payload,
          status: STATUS.RESOLVED
        }
      };
    }

    case types.LUCID_CATEGORY_GET_DETAILS_FAIL: {
      return {
        ...state,
        lucidProductCategories: {
          ...categoriesInitialStore.lucidProductCategories,
          status: STATUS.REJECTED
        }
      };
    }

    default: {
      return state;
    }
  }
};

export { categoriesInitialStore };
export default categoriesReducer;
