import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import { CatPopover } from '@blocks-react/components/Popover';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import { CSR_TEXTS } from '@app/components/pages/csr-landing/texts';
import styles from './Restrictions.module.scss';

const RestrictionPopover = ({ children, content = [] }) => {
  const popoverRef = useRef(null);

  useEffect(() => {
    if (!!content[0].length) {
      popoverRef.current.open();
    }
  }, [content]);

  if (!content[0].length) {
    return children;
  }

  return (
    <CatPopover
      ref={popoverRef}
      headingVariant="title-sm"
      heading={CSR_TEXTS.RESTRICTIONS_APPLIED}
      className={styles.popoverContainer}
      behavior="responsive"
    >
      <div className={styles.popoverContainer__trigger} slot="trigger">
        {children}
      </div>
      <div className={styles.popoverContainer__wrapper}>
        {content.map(([title, subtitle, limitations]) => (
          <div
            className={styles.popoverContainer__wrapper__body}
            key={`e${Math.random()}`}
          >
            <p className={styles.popoverContainer__wrapper__body__title}>
              {title}
            </p>
            <p className={styles.popoverContainer__wrapper__body__subtitle}>
              {subtitle}
            </p>
            <ul className={styles.popoverContainer__wrapper__body__list}>
              {limitations.map(restriction => (
                <li key={`r${Math.random()}`}>{restriction}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <CatButton onClick={() => popoverRef.current.close()}>
        {CSR_TEXTS.CSR_CLOSE}
      </CatButton>
    </CatPopover>
  );
};

RestrictionPopover.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any))
};

export default RestrictionPopover;
