import parse from 'date-fns/parse';
import set from 'date-fns/set';

/**
 * a util to derive the date object of the default request by date given a string and format sptring
 * @param {Object} requestByDate requestByDate obj from xorder service
 * @param {string} requestByDate.value formated date string
 * @param {string} requestByDate.format format string
 * @returns {Date} a date object
 */
export const getDefaultRequestByDate = (requestByDate = {}) => {
  const { value, format } = requestByDate;
  return !!value && !!format ? parse(value, format, new Date()) : null;
};

/**
 * a util aggregate quantity of items
 * @param {Object[]} items  items object from xorder service
 * @returns {string} a string representation of the total quantities of items
 */
export const getCartItemsCount = (items = []) => {
  let itemsCount = 0;
  items.map(item => {
    const parsedItemQuantity = parseInt(item.quantity);
    const itemQuantity = isNaN(parsedItemQuantity) ? 0 : parsedItemQuantity;
    itemsCount = itemsCount + itemQuantity;
  });
  return itemsCount.toString();
};

export const currentDateFormatted = () =>
  set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

export const copyToClipboard = text => {
  if (navigator.clipboard?.writeText(text)) {
    return true;
  }
};

export const convertFormattedNumberToUnformatted = value => {
  return parseFloat(
    value
      .replace('(', '-')
      .replace(')', '')
      .replace(/[^0-9.-]+/g, '')
  );
};
