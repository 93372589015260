import * as types from './constants';
import { STATUS } from '@app/constants/commonConstants';

const RequestServiceReducer = (state = {}, action) => {
  switch (action.type) {
    case types.REQUEST_SERVICE_DRAFT_IDLE: {
      return {
        ...state,
        requestServiceDraftStatus: STATUS.IDLE
      };
    }
    case types.REQUEST_SERVICE_DRAFT_BEGIN: {
      return {
        ...state,
        requestServiceDraftStatus: STATUS.PENDING
      };
    }
    case types.REQUEST_SERVICE_DRAFT_SUCCESS: {
      return {
        ...state,
        requestServiceDraftStatus: STATUS.RESOLVED,
        requestServiceReferenceId: action.payload
      };
    }
    case types.REQUEST_SERVICE_DRAFT_FAIL: {
      return {
        ...state,
        requestServiceDraftStatus: STATUS.REJECTED
      };
    }
    case types.REQUEST_SERVICE_SUBMIT_IDLE: {
      return {
        ...state,
        requestServiceSubmitStatus: STATUS.IDLE,
        requestServiceReferenceId: null
      };
    }
    case types.REQUEST_SERVICE_SUBMIT_BEGIN: {
      return {
        ...state,
        requestServiceSubmitStatus: STATUS.PENDING
      };
    }
    case types.REQUEST_SERVICE_SUBMIT_SUCCESS: {
      return {
        ...state,
        requestServiceSubmitStatus: STATUS.RESOLVED,
        requestServiceSubmitResponse: action.payload
      };
    }
    case types.REQUEST_SERVICE_SUBMIT_FAIL: {
      return {
        ...state,
        requestServiceSubmitStatus: STATUS.REJECTED
      };
    }
    case types.EXISTING_SERVICE_STATUS_BEGIN: {
      return {
        ...state,
        ExistingServiceStatus: STATUS.PENDING
      };
    }
    case types.EXISTING_SERVICE_STATUS_SUCCESS: {
      return {
        ...state,
        ExistingServiceStatus: STATUS.RESOLVED,
        requestServices: action.payload
      };
    }
    case types.EXISTING_SERVICE_STATUS_FAIL: {
      return {
        ...state,
        ExistingServiceStatus: STATUS.REJECTED
      };
    }
    case types.EXISTING_SERVICE_STATUS_IDLE: {
      return {
        ...state,
        ExistingServiceStatus: STATUS.IDLE,
        requestServices: null
      };
    }
    default:
      return state;
  }
};

export default RequestServiceReducer;
