import { useEffect } from 'react';
import {
  ERROR_DOMAIN,
  ERROR_PATH,
  UNKNOWNERROR
} from '@app/constants/errorConstants';
import { useSystemFeedback } from '@app/hooks';
import ExceptionFeedbackBySelector from '../common/Exception/ExceptionFeedbackBySelector';
import { getCookie, removeCookie, isEmpty } from '@app/utils';

const NotificationDisplay = () => {
  let dealerSystemDownCookie;
  const { setError } = useSystemFeedback();

  useEffect(() => {
    dealerSystemDownCookie =
      !isEmpty(getCookie('dealerSystemDown')) &&
      getCookie('dealerSystemDown') === '2';
    if (dealerSystemDownCookie) {
      setError(ERROR_DOMAIN.GLOBAL, ERROR_PATH.DISABLED_USER, UNKNOWNERROR);
      removeCookie('dealerSystemDown');
    }
  }, []);

  return (
    <ExceptionFeedbackBySelector
      className={'mb-2 mt-1'}
      messageClassName={'mb-0'}
      selector={state =>
        state.errors[ERROR_DOMAIN.GLOBAL]?.[ERROR_PATH.DISABLED_USER]
      }
    ></ExceptionFeedbackBySelector>
  );
};

export default NotificationDisplay;
