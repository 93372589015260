import endpoints from '../../constants/endpoints';
import { getStoreInfo } from '../../services/storeInfoService';
import { navigateToUrl } from '../../services/windowService';
import {
  HTTP_FORBIDDEN,
  HTTP_UNAUTHORIZED
} from '../../constants/commonConstants';

const { LOGOFF } = endpoints;

const CODE_OFFLINE = 'OFFLINE';
export const CODE_TIMEOUT = 'ECONNABORTED';
const ERROR_TYPE_SESSION_EXPIRED = 'SESSION_EXPIRED';

const sessionExpirationCodes = [HTTP_UNAUTHORIZED, HTTP_FORBIDDEN];

const parseServerErrorsArray = errors =>
  errors.map(({ errorMessage }) => errorMessage);
export const normalizeError = (
  originalError,
  parsedError,
  shouldParsedOverride,
  skipRedirectOnError = false // used when you want to avoid the home redirect on 403/401 error (case use AVI error)
) => {
  let error;
  const { catalogId, langId, storeId } = getStoreInfo();
  const httpResponseCode = originalError?.response?.status;

  try {
    if (originalError && originalError.code === CODE_TIMEOUT) {
      // network timeout
      throw new Error(originalError.code);
    } else if (!navigator.onLine) {
      // user is offline
      throw new Error(CODE_OFFLINE);
    } else if (
      httpResponseCode &&
      sessionExpirationCodes.includes(httpResponseCode)
    ) {
      // user's session has expired
      if (!skipRedirectOnError) {
        navigateToUrl(
          `${LOGOFF}?catalogId=${catalogId}&langId=${langId}&storeId=${storeId}&errorType=${ERROR_TYPE_SESSION_EXPIRED}`
        );
      }
    } else if (parsedError) {
      // When there's a specific error message to be displayed
      error = parsedError;
    } else if (originalError.response?.data?.errors) {
      // When the server returns an error we can parse
      const parsedMessages = parseServerErrorsArray(
        originalError.response.data.errors
      );
      error = {
        title: 'UNKNOWN_ERROR_TITLE',
        message: [...parsedMessages]
      };
    } else {
      throw new Error(originalError?.code);
    }
  } catch (thrownErr) {
    const code = thrownErr.message ? thrownErr.message : null;
    error = {
      code,
      title: 'UNKNOWN_ERROR_TITLE',
      message: 'UNKNOWN_ERROR_MESSAGE',
      ...(shouldParsedOverride ? parsedError : {})
    };
  }

  return error;
};

export default {
  normalizeError
};
