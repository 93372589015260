import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Conditional, SubheaderPrimaryNav } from '@cat-ecom/pcc-components';
import ShopByCategoryWrapper from '../ShopByCategoryWrapper';
import SisWrapper from '../SisWrapper';
import QuickOrderWrapper from '../QuickOrderWrapper';
import PartDiagramsWrapper from '../PartDiagramsWrapper';
import SpecialsWrapper from '../SpecialsWrapper';
import HeaderWaffleMenu from '@app/components/header/HeaderWaffleMenuWrapper/HeaderWaffleMenuWrapper';
import SubheaderUtilityNavLinks from '../SubheaderUtilityNavLinks';
import LoginAndRegistrationButtons from './LoginAndRegistrationButtons';
import { useSelector } from 'react-redux';
import { USER_TYPE_GUEST } from '@app/constants/commonConstants';
import SignOut from '../SignOut';
import { PCC_PC_LUCID_FLAG } from '@app/constants/featureFlags';
import ShopByCategoryLucid from '../ShopByCategoryWrapper/ShopByCategoryLucid';
import CartListsWrapper from '../CartListsWrapper';

const SubHeaderPrimaryNavWrapper = ({
  isCollapsed = false,
  mobileBreakpoint,
  isMobile
}) => {
  const { t } = useTranslation();
  const userType = useSelector(s => s.common?.userType);
  const isBuyOnBehalf = useSelector(s => s.common.isBuyOnBehalf);
  const isLucidFeatureFlag = useSelector(s => s.featureFlag[PCC_PC_LUCID_FLAG]);
  const isGuest = userType === USER_TYPE_GUEST;
  const isCatCSR = useSelector(s => s.common?.isCatCSR) || false;
  const style = {
    '--cat-primary-nav-positon-top-mobile': '80px'
  };

  return (
    <SubheaderPrimaryNav
      isCollapsed={isCollapsed}
      style={style}
      moreDropdownLabel={t('MO_MORE_TITLE')}
      mobileBreakPoint={mobileBreakpoint}
    >
      <Conditional test={isMobile && isGuest}>
        <LoginAndRegistrationButtons />
      </Conditional>
      <Conditional
        test={isLucidFeatureFlag}
        fallback={<ShopByCategoryWrapper />}
      >
        <ShopByCategoryLucid />
      </Conditional>
      <SisWrapper mobileBreakPoint={mobileBreakpoint} isMobile={isMobile} />
      <Conditional test={isCatCSR}>
        <CartListsWrapper
          mobileBreakPoint={mobileBreakpoint}
          isMobile={isMobile}
        />
      </Conditional>
      <Conditional test={!isCatCSR}>
        <QuickOrderWrapper
          mobileBreakPoint={mobileBreakpoint}
          isMobile={isMobile}
        />
      </Conditional>
      <PartDiagramsWrapper
        mobileBreakPoint={mobileBreakpoint}
        isMobile={isMobile}
      />
      <SpecialsWrapper
        mobileBreakPoint={mobileBreakpoint}
        isMobile={isMobile}
      />
      <Conditional test={isMobile}>
        <SubheaderUtilityNavLinks displayMobileNavLink={isMobile} />
        <HeaderWaffleMenu displayMobileNavLink={isMobile} />
      </Conditional>
      <Conditional test={isMobile && !isGuest && !isBuyOnBehalf}>
        <SignOut />
      </Conditional>
    </SubheaderPrimaryNav>
  );
};

SubHeaderPrimaryNavWrapper.propTypes = {
  isCollapsed: PropTypes.bool,
  mobileBreakpoint: PropTypes.number,
  isMobile: PropTypes.bool
};

export default SubHeaderPrimaryNavWrapper;
