import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { USER_ROLES } from '@app/constants/commonConstants';
import { calUser } from '@app/store/notificationHeader/utils';
import {
  ORDER_STATUS_LIST,
  ORDER_STATUS_HEADING_SORTING_ORDER
} from '../../constants';

const useOrderStatusList = () => {
  const [t] = useTranslation();

  const orderStatusList = useSelector(
    state => state?.notificationHeader?.notificationOrderStatus
  );
  const userRoles = useSelector(s => s?.common?.userRoles);
  const quoteEnabled = useSelector(s => s?.common?.quoteEnabled);

  const notificationPreferencesStatus = useSelector(
    s => s.notificationHeader?.notificationPreferencesStatus
  );
  const isApproved = useMemo(() => {
    return [USER_ROLES.ADMIN, USER_ROLES.APPROVER].some(role =>
      userRoles?.includes(role)
    );
  }, [userRoles]);

  const isOrderPlaced = useMemo(() => {
    return [USER_ROLES.ADMIN, USER_ROLES.SUBMITTER].some(role =>
      userRoles?.includes(role)
    );
  }, [userRoles]);

  const isCalUser = useMemo(() => {
    return calUser(userRoles);
  }, [userRoles]);

  const isCalSubmitter = useMemo(
    () => userRoles?.includes(USER_ROLES.SUBMITTER),
    [userRoles]
  );

  const allowedOrderStatus = useMemo(() => {
    const response = _.cloneDeep(orderStatusList)
      .filter(
        item =>
          notificationPreferencesStatus[item?.value] === true ||
          notificationPreferencesStatus[item?.value] === undefined
      )
      .map(item => ({
        ...item,
        title: ORDER_STATUS_LIST[item.label]?.title || ''
      }));
    const translateOption = ({ label, value, title, ...rest }) => ({
      label: t(label),
      value,
      title: t(title),
      orderIndex: ORDER_STATUS_HEADING_SORTING_ORDER[title],
      ...rest
    });
    if (quoteEnabled) {
      notificationPreferencesStatus['NEW'] &&
        response.push(ORDER_STATUS_LIST.EXPIRING_TODAY_QUOTE);
      notificationPreferencesStatus['EXP'] &&
        response.push(ORDER_STATUS_LIST.EXPIRED_QUOTE);
    }
    if (isCalUser) {
      notificationPreferencesStatus['E'] &&
        response.push(ORDER_STATUS_LIST.NOTIF_PREF_EXPIRED_APPROVAL_TITLE);
    }
    if (isApproved || isCalSubmitter) {
      response.push(ORDER_STATUS_LIST.ORG_ORDER_REJECTED);
      response.push(ORDER_STATUS_LIST.CAT_ORD_STS_DELETED);
    }
    if (isApproved) {
      response.push(ORDER_STATUS_LIST.ORDER_APPROVED);
      response.push(ORDER_STATUS_LIST.SUBMITED_APPROVAL);
    }
    if (isOrderPlaced) {
      response.push(ORDER_STATUS_LIST.SHIPPED_OP);
    }
    response.push(ORDER_STATUS_LIST.QUEUED_ORDER_PROCESSING_HEADING);
    return _.orderBy(response.map(translateOption), 'orderIndex');
  }, [
    t,
    orderStatusList,
    quoteEnabled,
    isCalUser,
    isApproved,
    isOrderPlaced,
    notificationPreferencesStatus,
    isCalSubmitter
  ]);
  return [
    allowedOrderStatus.reduce(
      (acc, item) =>
        Object.assign(acc, {
          [item.title]: (acc[item.title] || []).concat(item)
        }),
      {}
    )
  ];
};

export default useOrderStatusList;
