/**
 * Checks if there is any meta tag with the respective name and content
 * @param {string} searchTerm string to search
 * @param {Array} listArray Array to be searched with
 * @param {Array} searchKey Array of properties to be searched with
 * @returns {Array}
 */
export const search = (searchTerm, listArray, searchKeys = null) => {
  return listArray.filter(listItem => {
    if (typeof listItem === 'object') {
      return Object.keys(listItem).some(key => {
        if (searchKeys.includes(key)) {
          return listItem[key].toLowerCase().includes(searchTerm.toLowerCase());
        }
        return null;
      });
    } else if (typeof listItem === 'string') {
      return listItem.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return listItem === searchTerm;
  });
};
