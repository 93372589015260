import * as types from './constants';
import { ectInitialStore } from './initialStore';
import { STATUS } from '@app/constants/commonConstants';

export const ectReducer = (state = ectInitialStore, action) => {
  switch (action.type) {
    case types.ERP_MESSAGE_LOAD_FAIL:
      return {
        ...state,
        erpMessageStatus: STATUS.REJECTED,
        error: action.payload
      };
    case types.ECT_MESSAGE_LOAD_FAIL:
      return {
        ...state,
        ectMessageStatus: STATUS.REJECTED,
        error: action.payload
      };
    default:
      return state;
  }
};
export { ectInitialStore };
export default ectReducer;
