import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useDrawer, Portal } from '@cat-ecom/pcc-components';
import LoginDrawer from '../LoginDrawer/LoginDrawer';
import ListContent from '../LoginDrawer/ListContent';
import { CatPrimaryNavItem } from 'blocks-react/bedrock/components/PrimaryNavItem/PrimaryNavItem.js';
import {
  CAT_SIS_REDIRECT,
  SIS,
  USER_TYPE_GUEST
} from '@app/constants/commonConstants';
import Conditional from '@app/components/common/Conditional/Conditional';
import {
  ImportPartsIcon,
  ShopKitsIcon,
  TaskCompleteIcon
} from '@app/components/common/SvgIcons';
import links from '@app/constants/links';
import { replaceTokensInString } from '@app/utils';
import { appendUrl } from '@app/components/common/SisLink/utils';
import { DRAWERS_ROOT } from '@app/constants/targets';
import { returnFocusToNavItem } from '@app/utils/DOMUtils';
import { MAIN_NAV_TRACK } from '@app/constants/analyticsConstants';
import styles from '../Subheader.modules.scss';
import cx from 'classnames';

const SisWrapper = ({ isMobile = false }) => {
  const { t } = useTranslation();
  const userType = useSelector(s => s.common?.userType);
  const storeId = useSelector(s => s.common.storeId);
  const langId = useSelector(s => s.common.langId);
  const catalogId = useSelector(s => s.common.catalogId);

  const isGuestUser = userType === USER_TYPE_GUEST;

  const sisUrl = replaceTokensInString(
    links.SIS_REDIRECT_URL,
    storeId,
    langId,
    catalogId
  );

  const { pathname, search: searchParams } = useLocation();
  const { searchTerm } = queryString.parse(searchParams);
  const userSisUrl = appendUrl(sisUrl, pathname, searchTerm);
  const sisNavItemId = 'sisNavItem';
  const { closeDrawer, drawerProps, initiatorProps, isDrawerOpen } = useDrawer({
    onClose: () => returnFocusToNavItem(`#${sisNavItemId}`)
  });
  const loginDrawerProps = {
    closeDrawer,
    title: SIS,
    drawerProps,
    signinCallback: {
      detail: { redirectUrl: CAT_SIS_REDIRECT }
    }
  };

  const navItemProps = {
    href: isGuestUser ? undefined : userSisUrl,
    ...(isGuestUser ? initiatorProps : {})
  };

  const listItems = [
    {
      background: true,
      icon: <ShopKitsIcon size="lg" />,
      text: t('SIS_BENEFIT_ACCESS')
    },
    {
      background: true,
      icon: <TaskCompleteIcon size="lg" />,
      text: t('SIS_BENEFIT_MAINTENANCE')
    },
    {
      background: true,
      icon: <ImportPartsIcon size="lg" />,
      text: t('SIS_BENEFIT_IMPORT')
    }
  ];
  const text = t('FULL_SIS_LABEL');
  const title = t('CAT_SIS_HOVER');
  return (
    <>
      <CatPrimaryNavItem
        data-testId={sisNavItemId}
        id={sisNavItemId}
        text={text}
        isCurrent={isDrawerOpen}
        title={title}
        {...navItemProps}
        data-track-event={MAIN_NAV_TRACK.EVENT}
        data-track-attr-nltext={MAIN_NAV_TRACK.SIS_TEXT}
        data-track-attr-context={MAIN_NAV_TRACK.TOP_MENU_CONTEXT}
        className={cx({ [styles['item-border-bottom']]: isMobile })}
      />
      <Conditional test={isGuestUser}>
        <Portal id={DRAWERS_ROOT}>
          <LoginDrawer {...loginDrawerProps}>
            <ListContent items={listItems} />
          </LoginDrawer>
        </Portal>
      </Conditional>
    </>
  );
};

SisWrapper.propTypes = {
  isMobile: PropTypes.bool
};

export default SisWrapper;
