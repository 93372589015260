import PropTypes from 'prop-types';
import { replaceTokensInString } from '@app/utils';
import { useTranslation } from 'react-i18next';
import ViewCart from '../ViewCart';

const ToastSuccessContent = ({ items = [] }) => {
  const [t] = useTranslation();
  const itemAttributes = items?.find(() => true) || '';
  const partName = itemAttributes.name;
  const partNumber = itemAttributes.partNumber;
  const partNumberAndName = `${partNumber}:${partName}`;
  const itemsAddedToCartSuccessMessage = replaceTokensInString(
    t('ITEM_ADD_SUCCESS'),
    partNumberAndName
  );
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column">
        <div className="fw-bold u-color--DarkGray">{t('ITEM_ADDED')}</div>
        <div className="u-color--DarkGray">
          {itemsAddedToCartSuccessMessage}
        </div>
      </div>
      <ViewCart />
    </div>
  );
};
ToastSuccessContent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  )
};
export default ToastSuccessContent;
