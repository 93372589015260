import {
  HeaderEquipmentWidgetButton,
  HeaderEquipmentWidgetNavItem
} from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import Proptypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTracking } from 'react-tracking';
import useEquipmentDrawer from '@app/hooks/useEquipmentDrawer';
import { returnFocusToNavItem } from '@app/utils/DOMUtils';
import Conditional from '@app/components/common/Conditional';
import { useCurrentPage } from '@app/hooks/useCurrentPage';

const HeaderEquipmentWidgetWrapper = ({
  isMobile = false,
  showDivider,
  classname,
  subLabel,
  showIcon
}) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const {
    openAddDrawer,
    openSelectedDrawer,
    openSelectDrawer,
    getDrawerComponent,
    isAddDrawerOpen,
    isEditDrawerOpen,
    isSelectedDrawerOpen
  } = useEquipmentDrawer({
    onClose: () => returnFocusToNavItem('#headerEquipmentWidget')
  });
  const isinitialLoading = useSelector(s => s.myEquipment?.initialLoading);
  const equipmentList = useSelector(
    s => s.myEquipment?.equipments?.myEquipmentList
  );
  const assetId = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment?.assetId
  );
  const imageURL = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment?.imageURL
  );
  const model = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment?.model
  );
  const serialNumber = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment?.serialNumber
  );
  const equipmentFamily = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment?.equipmentFamily
  );
  const selectedEquipment = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment
  );
  const selectedEquipmentInfo = {
    assetId,
    imageURL,
    model,
    serialNumber,
    equipmentFamily
  };
  const { isOnPMKitsOrSSROPage, isOnOMMPage } = useCurrentPage();
  const isOnEquipmentPages = isOnPMKitsOrSSROPage || isOnOMMPage;
  const hasEquipments = equipmentList?.length > 0;
  const labelKey = hasEquipments ? 'SELECT_EQUIPMENT' : 'MEQ_ADD_EQUIP';

  const trackFormVisible = modalType => {
    trackEvent({
      event: 'formVisible',
      pagePath: document.location.pathname,
      formName: `Step 1 | ${modalType} Equipment drawer view`,
      formContent: '',
      formContent2: '',
      formFieldCausingError: '',
      formLocation: '',
      formStatus: 'success'
    });
  };

  const onClickHandler = (hasSelectedEquipment = false) => {
    if (!hasEquipments) {
      openAddDrawer();
      trackFormVisible('Add');
    } else if (hasSelectedEquipment) {
      openSelectedDrawer();
      trackFormVisible('Centralized Select');
    } else {
      openSelectDrawer();
      trackFormVisible('Select');
    }
  };
  const showMobileNavActiveState =
    isAddDrawerOpen || isEditDrawerOpen || isSelectedDrawerOpen;

  return (
    <Conditional test={!isOnEquipmentPages}>
      {isMobile ? (
        <HeaderEquipmentWidgetNavItem
          isCurrent={showMobileNavActiveState}
          label={t(labelKey)}
          onClick={onClickHandler}
          selectedEquipmentInfo={selectedEquipmentInfo}
          showDivider={showDivider}
          classname={classname}
          subLabel={subLabel}
          showIcon={showIcon}
          isMobileSearch={isMobile}
          isinitialLoading={isinitialLoading}
        />
      ) : (
        <HeaderEquipmentWidgetButton
          label={t(labelKey)}
          selectedEquipmentInfo={selectedEquipmentInfo}
          onClick={onClickHandler}
          data-testid="headerEquipmentWidgetButton"
          subLabel={subLabel}
          isinitialLoading={isinitialLoading}
        />
      )}
      {getDrawerComponent({
        currentEquipment: selectedEquipment,
        myEquipmentList: equipmentList,
        isBackButton: true
      })}
    </Conditional>
  );
};

HeaderEquipmentWidgetWrapper.propTypes = {
  isMobile: Proptypes.bool,
  showDivider: Proptypes.bool,
  classname: Proptypes.string,
  subLabel: Proptypes.string,
  showIcon: Proptypes.bool
};

export default HeaderEquipmentWidgetWrapper;
