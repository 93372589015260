import { ONETRUST } from '@app/constants/commonConstants';
import { isConsentTypeAvailable } from '@app/utils';
import { useEffect, useState } from 'react';

const useOneTrust = () => {
  // Handle if the functional cookie is accepted
  const [isFunctionalAccepted, setIsFunctionalAccepted] = useState(() =>
    isConsentTypeAvailable(ONETRUST.FUNCTIONAL_COOKIES_ACCEPTED)
  );

  // Handle if the targeting cookie is accepted
  const [isTargetingAccepted, setIsTargetingAccepted] = useState(() =>
    isConsentTypeAvailable(ONETRUST.TARGET_COOKIES_ACCEPTED)
  );

  useEffect(() => {
    const listener = e => {
      const getGroup = e.detail;
      if (getGroup.includes(ONETRUST.FUNCTIONAL_COOKIES)) {
        setIsFunctionalAccepted(true);
      }

      if (getGroup.includes(ONETRUST.TARGET_COOKIES)) {
        setIsTargetingAccepted(true);
      }
    };

    const eventName = ONETRUST.GROUP_UPDATED_EVENT_LISTENER;
    window.addEventListener(eventName, listener);
    return () => window.removeEventListener(eventName, listener);
  }, []);

  return { isFunctionalAccepted, isTargetingAccepted };
};

export default useOneTrust;
