import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AssociatedDealerForm from './AssociatedDealerForm';

const AssociatedDealerModal = ({ isLoginAction, title, ...rest }) => {
  const isCSR = useSelector(s => s.common.isCatCSR) || false;
  const showWarning = !isLoginAction && !isCSR;
  return (
    <div>
      <h2 className="exclude-global h2 display-1 mb-4">{title}</h2>
      <AssociatedDealerForm
        showWarning={showWarning}
        isLoginAction={isLoginAction}
        {...rest}
      />
    </div>
  );
};

AssociatedDealerModal.propTypes = {
  isRequired: PropTypes.bool,
  showSkipThisStep: PropTypes.bool,
  isLoginAction: PropTypes.bool,
  title: PropTypes.string
};

export default AssociatedDealerModal;
