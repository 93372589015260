import {
  HOMEPAGE,
  PAGES_META_VALUES,
  PAGE_BREAKPOINTS
} from '../constants/commonConstants';

/**
 * Recognize if the browser is IE11
 * @returns {boolean}
 * @unused
 */
export const isIE11 = () =>
  !!window.MSInputMethodContext && !!document.documentMode;

/**
 * Recognize if the browser is IE
 * @returns {boolean}
 * @unused
 */
export const isIE = () => /MSIE|Trident/.test(navigator.userAgent) || isIE11();

/**
 * Checks if the current page is the Shoping Cart Page accord the current location.pathname
 * @returns {boolean}
 */
export const isShoppingCartPage = () =>
  (window.location?.pathname || '').includes('/AjaxOrderItemDisplayView');

/**
 * Checks if the current page is the summary and payment page accord the current location.pathname
 * @returns {boolean}
 */
export const isSummaryAndPaymentPage = () =>
  (window.location?.pathname || '').includes('/SingleShipmentOrderSummaryView');

/**
 * Checks if the current page is the pickup and delivery page according to the current location.pathname
 * @returns {boolean}
 */
export const isPickupAndDeliveryPage = () =>
  (window.location?.pathname || '').includes('/PickupAndDeliveryView');

/**
 * Checks if the current page is the Logoff Page accord the current location.pathname
 * @returns {boolean}
 */
export const isLogoffPage = () =>
  (window.location?.pathname || '').includes('/Logoff');

/**
 * Checks if there is any meta tag with the respective name and content
 * @param {string} metaName Name in the meta tag
 * @param {string} metaContent Content in the meta tag
 * @returns {boolean}
 */
export const metaValueExists = (metaName, metaContent) => {
  const elements = Array.from(document.getElementsByTagName('meta'));
  return elements.some(e => {
    return (
      e.getAttribute('name') === metaName &&
      e.getAttribute('content') === metaContent
    );
  });
};

/**
 * Checks if the current page is the Home Page accord the meta tag with name "pageIdentifier"
 * @returns {boolean}
 */
export const isHomepage = () => metaValueExists('pageIdentifier', HOMEPAGE);

/**
 * Checks if the current page is the Machine Landing Page accord the meta tag with name "pageGroup" and meta content is "MachineLanding"
 * @returns {boolean}
 */
export const isMLP = () =>
  metaValueExists(
    'pageGroup',
    PAGES_META_VALUES.MACHINE_LANDING_PAGE.pageGroup
  );

/**
 * Checks if the current page is the campaign Landing Page accord the meta tag with name "pageGroup" and meta content is "Content"
 * @returns {boolean}
 */
export const isCLP = () =>
  metaValueExists(
    'pageGroup',
    PAGES_META_VALUES.CAMPAIGN_LANDING_PAGE.pageGroup
  );

/**
 * Checks if the current page is the Product Detail Page accord the meta tag with name "pageGroup" and meta content is "Product"
 * @returns {boolean}
 */
export const isPDP = () =>
  metaValueExists('pageGroup', PAGES_META_VALUES.PRODUCT_DETAIL_PAGE.pageGroup);

/**
 * Checks if the current page is the Category Page accord the meta tag with name "pageGroup" and meta content is "Category"
 * @returns {boolean}
 */
export const isCategoryPage = () =>
  metaValueExists('pageGroup', PAGES_META_VALUES.CATEGORY_PAGE.pageGroup);

/**
 * Checks if the current page is the Search Page accord the meta tag with name "pageGroup" and meta content is "Search"
 * @returns {boolean}
 */
export const isSearchPage = () =>
  metaValueExists('pageGroup', PAGES_META_VALUES.SEARCH_PAGE.pageGroup);

/**
 * Recognize the current page based on the meta tags using PAGES_META_VALUES from commonConstants
 * @returns Page Name
 */

/**
 * Checks if the current page is the SiteMap accord the meta tag with name "pageGroup" and meta content is "SiteMapPage"
 * @returns {boolean}
 */
export const isSiteMap = () =>
  metaValueExists('pageGroup', PAGES_META_VALUES.SITE_MAP_PAGE.pageGroup);

/**
 * Checks if the current page is the SiteMap accord the meta tag with name "pageGroup" and meta content is "SiteMapPage"
 * @returns {boolean}
 */
export const isMyAccountPage = () =>
  metaValueExists('pageGroup', PAGES_META_VALUES.MY_ACCOUNT_PAGE.pageGroup);
/**
 * Recognize the current page based on the meta tags using PAGES_META_VALUES from commonConstants
 * @returns Page Name
 */
export const getCurrentPageBasedOnMetaTags = () => {
  const currentPage = Object.values(PAGES_META_VALUES).find(
    ({ pageGroup, pageIdentifier }) => {
      if (pageIdentifier) {
        return metaValueExists('pageIdentifier', pageIdentifier);
      }

      return metaValueExists('pageGroup', pageGroup);
    }
  );

  return currentPage?.name || '';
};

/**
 * Verify if the referrer is external
 * @param {*} referrer
 * @param {*} host
 * @returns
 */
export const isExternalReferrer = (referrer, host) =>
  referrer ? !referrer.split('//').pop().startsWith(host) : true;

/**
 * util to tell whether an element is visible in the window
 * @param {Element} element - element to test
 * @returns {boolean}
 */
export const isInViewport = element => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

/**
 * checks if the current window size is in device size range
 * @returns {boolean}
 * @deprecated use to breakpoints where possible
 */
export const isMobileDevice = () => {
  return window.innerWidth < PAGE_BREAKPOINTS.MD;
};

/**
 * Creates an html form with hidden inputs and adds it to the document.body
 * @param {string} path Path of the action
 * @param {Record<string, string>} params Object with the inputs with the shape { name: value }
 * @param {'GET'|'POST'} [method=POST] Method of the form
 * @returns {HTMLFormElement} Html form object
 */
export const formPost = (path, params, method = 'post') => {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  return form;
};

export const getPageGroupContent = () => {
  if (Array.from(document.querySelectorAll("meta[name='pageGroup']")).length) {
    return Array.from(
      document.querySelectorAll("meta[name='pageGroup']")
    )[0].getAttribute('content');
  }
  return '';
};

export const getPageIdentifierContent = () => {
  if (
    Array.from(document.querySelectorAll("meta[name='pageIdentifier']")).length
  ) {
    return Array.from(
      document.querySelectorAll("meta[name='pageIdentifier']")
    )[0].getAttribute('content');
  }
  return '';
};

/**
 * checks if the current device is Android.
 * @returns {boolean}
 */
export const isAndroidDevice = () => {
  // Take a look on here https://stackoverflow.com/questions/6031412/detect-android-phone-via-javascript-jquery
  return navigator?.userAgent.toLowerCase().includes('android');
};

/**
 * checks if the current device is IOS.
 * @returns {boolean}
 */
export const isIOSDevice = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator?.userAgent) ||
    // iPad on iOS 13 detection,Because on iPad iOS 13, the user agent is identical with that of a MacOS 13 computer (taken of this page https://stackoverflow.com/questions/9038625/detect-if-device-is-ios)
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};
