import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { isDev } from '@app/utils';
import { combined } from '@app/constants/resourceBundle';

// load initial set of keys blank to avoid flash of key in UI
const resources = { fallback: { translation: combined } };

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'fallback',
    resources,
    keySeparator: false,
    interpolation: { escapeValue: false },
    returnEmptyString: true,
    returnNull: true,
    missingKeyHandler: () => '',
    parseMissingKeyHandler: () => '',
    defaults: '',
    saveMissing: true,
    debug: isDev(),
    wait: false
  });

export default i18n;
