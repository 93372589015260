import PropTypes from 'prop-types';

const DateRangeField = ({ as: Component, ariaLabel, setTouched, ...props }) => {
  return (
    <Component
      aria-label={ariaLabel}
      data-testid="field-dateRangeField"
      onBlur={() => setTouched(true)}
      {...props}
    />
  );
};

DateRangeField.propTypes = {
  as: PropTypes.elementType.isRequired,
  ariaLabel: PropTypes.string,
  setTouched: PropTypes.func.isRequired
};

export { DateRangeField };
