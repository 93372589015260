import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { STATUS, TIMEOUT_FORTY_SECONDS } from '@app/constants/commonConstants';
import { replaceTokensInUrl } from '@app/utils';
import { getHttpInstance } from '@app/services/defaultHttpService';
import endpoints from '@app/constants/endpoints';

const useGetDealerCustomerData = () => {
  const controllerRef = useRef(new AbortController());
  const dealerUserStoreId = useSelector(
    state => state.common?.dealerUserStoreId
  );
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(STATUS.iDLE);

  const abort = () => {
    controllerRef.current.abort();
    controllerRef.current = new AbortController();
  };

  const reset = () => {
    setData(null);
    setError(null);
    setStatus(STATUS.IDLE);
  };

  const invoke = values => {
    setData(null);
    setError(null);
    setStatus(STATUS.PENDING);
    const http = getHttpInstance(TIMEOUT_FORTY_SECONDS);
    const url = replaceTokensInUrl(
      endpoints.DEALER_USER_SEARCH,
      dealerUserStoreId
    );
    const body = {
      ...values,
      storeId: dealerUserStoreId
    };

    http
      .request({
        data: body,
        method: 'post',
        url: encodeURI(url),
        signal: controllerRef.current.signal
      })
      .then(({ data }) => {
        setData(data);
        setStatus(STATUS.RESOLVED);
      })
      .catch(err => {
        setError(err);
        setStatus(STATUS.REJECTED);
        throw err;
      });
  };

  return {
    data,
    error,
    status,
    invoke,
    abort,
    reset
  };
};

export default useGetDealerCustomerData;
