import PropTypes from 'prop-types';

export const availability = PropTypes.arrayOf(
  PropTypes.shape({
    byDate: PropTypes.arrayOf(
      PropTypes.shape({ date: PropTypes.string, quantity: PropTypes.string })
    ),
    contactDealer: PropTypes.shape({
      quantity: PropTypes.string,
      message: PropTypes.string
    }),
    inStock: PropTypes.shape({
      quantity: PropTypes.string,
      message: PropTypes.string
    })
  })
);
