import resolvedEndpoints from '../constants/endpoints';
import { replaceTokensInString } from '@app/utils';
const {
  TERMS_CONDITIONS_HEADLESS_PAGE_URL,
  PRIVACY_POLICY_HEADLESS_URL,
  PRIVACY_NOTICE_HEADLESS_URL,
  TERMS_OF_USE_HEADLESS_URL
} = resolvedEndpoints;

export const dealersHeadlessTermsConditionsURL = seoURL => {
  const dealerURL = seoURL?.substring(1);
  return replaceTokensInString(TERMS_CONDITIONS_HEADLESS_PAGE_URL, dealerURL);
};

export const dealersHeadlessPrivacyPolicy = seoURL => {
  const dealerUrl = seoURL?.substring(1);
  const privacyPolicyUrl = replaceTokensInString(
    PRIVACY_POLICY_HEADLESS_URL,
    dealerUrl
  );
  return privacyPolicyUrl;
};

export const termsOfUseHeadlessURL = seoURL => {
  const dealerUrl = seoURL?.substring(1);
  const termsOfUseUrl = replaceTokensInString(
    TERMS_OF_USE_HEADLESS_URL,
    dealerUrl
  );
  return termsOfUseUrl;
};

export const privacyNoticeHeadlessURL = seoURL => {
  const dealerUrl = seoURL?.substring(1);
  const privacyNoticeUrl = replaceTokensInString(
    PRIVACY_NOTICE_HEADLESS_URL,
    dealerUrl
  );
  return privacyNoticeUrl;
};

export const getConsentTemplate = (
  isPCCShowDealerTerms,
  getEspotContent,
  t,
  ESPOT_NAME
) => {
  if (isPCCShowDealerTerms) {
    const espotContent = getEspotContent(ESPOT_NAME);
    const espotHtml =
      espotContent?.contents?.length > 0 ? espotContent?.contents[0]?.html : '';
    return espotHtml;
  } else {
    return t('UR_CAT_ACCEPT_TERMS');
  }
};
