import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NotificationPopover } from '@cat-ecom/pcc-components';
import { replaceTokensInUrl } from '@app/utils';
import links from '@app/constants/links';

const NotificationMenuPopOver = ({
  setIsDismissConfirmBannerOpen,
  isActiveNotification
}) => {
  const [t] = useTranslation();

  const { catalogId, langId, storeId } = useSelector(s => s.common);

  const contactUrl = replaceTokensInUrl(
    links.NOTIFICATION_PREFERENCES_URL,
    catalogId,
    langId,
    storeId
  );

  const handleOpenDismissConfirmationBanner = () => {
    setIsDismissConfirmBannerOpen(true);
  };

  const handleRedirect = () => {
    window.location.href = contactUrl;
  };

  return (
    <NotificationPopover
      handleRedirect={handleRedirect}
      title={t('CAT_NOTIFICATION_PREFERENCES_TITLE')}
      dismissText={t('DISMISS_ALL')}
      isActiveNotification={isActiveNotification}
      handleOpenDismissConfirmationBanner={handleOpenDismissConfirmationBanner}
    />
  );
};

export default NotificationMenuPopOver;

NotificationMenuPopOver.propTypes = {
  setIsDismissConfirmBannerOpen: PropTypes.func,
  isActiveNotification: PropTypes.bool
};
