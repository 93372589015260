import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'cat-ecommerce-alloy';
import { useTranslation } from 'react-i18next';
import { loadPreferences } from '../../store/shoppingPreferences/actions';
import {
  STATUS,
  AFFILIATION_DEALER,
  USER_TYPE_REGISTERED
} from '../../constants/commonConstants';
import { isEmpty } from '../../utils';
import useSelectDealer from './hooks/useSelectDealer';
import AssociatedDealerModal from './AssociatedDealerModal';
import DealerUserModal from './DealerUserModal';
import useSavedLists from '../saved-lists/useSavedLists';
import { toggleADPmodalOpenStatus } from '@app/store/common/actions';

const DEALER_USER_MODAL_ID = 'DEALER_USER_MODAL_ID';
const BYPASS = 'bypass';
const getDefault = p => p.default === true;

/**
 * @typedef {Object} changeAssociatedDealerOptions
 * @property {boolean} isOpen - indicates whether or not the 'change dealer' modal is open
 * @property {function} resolveDealerAfterLogin - Chooses a default dealer or shows the 'Change Dealer' modal depending on a user's shopping preferences
 * @property {function} showAssociatedDealer - Shows the 'change dealer' modal
 */

/**
 * React Hook enabling the alteration of a user's current dealer
 *
 * @returns {changeAssociatedDealerOptions} options relating to changing a dealer
 */
const useChangeAssociatedDealer = ({ associatedDealerModal } = {}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addModalWithOptions, modals = [] } = useContext(ModalContext);
  const { invoke: selectDealer } = useSelectDealer();
  const { setFinishedFlowADP } = useSavedLists();
  const [initResolveDealer, setInitResolveDealer] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [areADPServicesLoading, setAreADPServicesLoading] = useState(false);
  const [callingPrefData, setCallingPrefData] = useState(false);

  const dealerAssociationCallStatus = useSelector(
    s => s.dealerAssociation?.status
  );

  const defaultDealerId = useSelector(
    s => s.dealerAssociation?.defaultAssociation?.value
  );
  const prefData = useSelector(
    state => state.shoppingPreferences?.shoppingPreferencesData
  );
  const prefDataStatus = useSelector(
    state => state.shoppingPreferences?.shoppingPreferenceDataStatus
  );
  const userAffiliation = useSelector(s => s.common?.userAffiliation);
  const areTranslationsLoaded = useSelector(
    state => state.common?.areTranslationsLoaded
  );
  const userType = useSelector(s => s.common?.userType);
  const isDealerUser = userAffiliation === AFFILIATION_DEALER;
  const isCSR = useSelector(s => s.common?.isCatCSR) || false;

  const preferenceResolved =
    callingPrefData &&
    ((prefDataStatus === STATUS.RESOLVED && !isEmpty(prefData)) ||
      prefDataStatus === STATUS.REJECTED);

  const adpSetting = prefData?.adpSettings?.find(getDefault) ?? {};
  const skipAdp = adpSetting?.value === BYPASS;
  const isDealerUserModalOpen = modals.some(m => m.id === DEALER_USER_MODAL_ID);
  /**
   * Shows the 'change dealer' modal
   *
   * @param {boolean} isRequired when true displays ADP as required
   * @param {string} returnFocusSelector a css selector describing an element to which focus should be returned upon closing the modal
   * @param {boolean} showSkipThisStep shows the 'skip this step' checkbox if true; hidden otherwise;
   * @param {boolean} isLoginAction indicates if the user is in the login flow or only changing location
   */
  const showAssociatedDealer = useCallback(
    ({
      isRequired = false,
      returnFocusSelector,
      showSkipThisStep = false,
      isLoginAction = false,
      ...rest
    } = {}) => {
      const title = t('CAT_MA_ASSOCIATEDDEALERS');

      const onOpen = () => {
        setIsOpen(true);
      };
      const onClose = () => {
        if (returnFocusSelector) {
          const elementToFocus =
            document.querySelectorAll(returnFocusSelector)[0];
          if (elementToFocus) {
            elementToFocus.focus();
          }
        }
        setIsOpen(false);
      };
      if (isDealerUser && !isDealerUserModalOpen) {
        addModalWithOptions(<DealerUserModal title={title} />, {
          id: DEALER_USER_MODAL_ID,
          isRequired,
          onClose,
          onOpen,
          title: t('SEARCH_CATALOG')
        });
      } else {
        dispatch(toggleADPmodalOpenStatus(true));
        addModalWithOptions(
          <AssociatedDealerModal
            isRequired={isRequired}
            showSkipThisStep={showSkipThisStep}
            isLoginAction={isLoginAction}
            title={title}
            {...rest}
          />,
          {
            isRequired,
            onClose,
            onOpen,
            title
          }
        );
      }
    },
    [addModalWithOptions, isDealerUser, isDealerUserModalOpen, t]
  );

  /**
   * Chooses a default dealer or shows the 'Change Dealer' modal depending on a user's shopping preferences
   */
  const resolveDealerAfterLogin = () => {
    setInitResolveDealer(true);
    setAreADPServicesLoading(true);
  };

  // Wait for new userInfo
  // Open ADP if a dealer user.
  // Otherwise refreshes preferences available after dealer associations change. This should happen immediately after login.
  useEffect(() => {
    if (initResolveDealer && userType === USER_TYPE_REGISTERED) {
      if (isDealerUser && areTranslationsLoaded) {
        associatedDealerModal?.initiatorProps?.onClick?.();
        setInitResolveDealer(false);
        setAreADPServicesLoading(false);
      } else if (dealerAssociationCallStatus === STATUS.RESOLVED) {
        dispatch(loadPreferences({ storeId: defaultDealerId }));
        setCallingPrefData(true);
      }
    }
  }, [
    areTranslationsLoaded,
    dealerAssociationCallStatus,
    defaultDealerId,
    dispatch,
    initResolveDealer,
    isDealerUser,
    showAssociatedDealer,
    userType
  ]);

  // for non-dealer user we wait for preferences to determine whether to skip adp
  useEffect(() => {
    const handleChangeDealer = () => {
      const dcnNumberValue = prefData.customerNumber?.find(getDefault)?.value;
      if ((skipAdp || isCSR) && !isEmpty(dcnNumberValue)) {
        const dcnNumberName =
          prefData.customerNumber?.find(getDefault)?.displayName;
        const endUseCode = prefData.endUseCodes?.find(getDefault)?.value;
        const orderType = prefData.orderTypes?.find(getDefault)?.value;
        const storeLocationId = prefData.dealerStore?.find(getDefault)?.value;
        const body = {
          dcnNumberValue,
          dcnNumberName,
          dealer: defaultDealerId,
          endUseCode,
          orderType,
          storeLocationId,
          isLoginAction: true
        };
        selectDealer(body);
        setFinishedFlowADP();
      } else {
        associatedDealerModal?.initiatorProps?.onClick?.();
      }
      setAreADPServicesLoading(false);
    };

    const didDealerAssociationCallError =
      dealerAssociationCallStatus === STATUS.REJECTED;

    if (
      areTranslationsLoaded &&
      initResolveDealer &&
      (preferenceResolved || didDealerAssociationCallError) &&
      userAffiliation !== AFFILIATION_DEALER
    ) {
      handleChangeDealer();
      setCallingPrefData(false);
      setInitResolveDealer(false);
    }
  }, [
    areTranslationsLoaded,
    dealerAssociationCallStatus,
    defaultDealerId,
    initResolveDealer,
    preferenceResolved,
    selectDealer,
    showAssociatedDealer,
    skipAdp,
    userAffiliation,
    isCSR
  ]);

  return {
    areADPServicesLoading,
    isOpen,
    resolveDealerAfterLogin,
    showAssociatedDealer
  };
};

export default useChangeAssociatedDealer;
