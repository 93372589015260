import PropTypes from 'prop-types';

export const sourcingDetailsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    availabilityMessage: PropTypes.string,
    availableByDate: PropTypes.string,
    sequence: PropTypes.number,
    availStoreList: PropTypes.arrayOf(
      PropTypes.shape({
        quantityAvailable: PropTypes.string,
        leadTimeDateRange: PropTypes.string,
        storeName: PropTypes.string
      })
    )
  })
);
