import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContext } from 'cat-ecommerce-alloy';
import {
  addProductToSavedList,
  setConflictConfirmationFlag,
  setConflictResolutionFlag,
  setConflictFlag
} from '@app/store/account/actions';
import {
  getUpdatedNotes,
  keyValue,
  newItemNotes,
  oldItemNotes,
  getConflictData,
  getNonConflictingItems
} from '../../../utils';
import { ERROR_DOMAIN, ERROR_PATH } from '@app/constants/errorConstants';
import { TRUE_VALUE } from '@app/components/saved-lists/constants';

const useConflictNotes = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const ToastCtx = useContext(ToastContext);
  const [updatedNotes, setUpdatedNotes] = useState([]);
  const [isConflictPresent, setConflictPresent] = useState(null);
  const [confirmationModalFlag, setConfirmationModalFlag] = useState(false);
  const cartMergeEnabled = useSelector(s => s.common?.cartMergeEnabled);
  const isOpenConflictConfirmation = useSelector(
    state => state.account?.isOpenConflictConfirmation
  );
  const saveToListParts = useSelector(state => state.account?.saveListParts);
  const addItemSaveListData = useSelector(
    state => state.account?.addItemSaveListData
  );
  const requisitionListId = useSelector(
    state => state.account?.addItemSaveListData?.requisitionListId
  );
  const current = useSelector(s => s.orders.current);
  // ascendingOrderItems is new line item notes
  const ascendingOrderItemsNew = useSelector(
    state => state.orders?.byId?.[current]?.ascendingOrderItems
  );
  // requisitionListId is old line item notes
  const ascendingOrderItemsOld = useSelector(
    state => state.orders?.byId?.[requisitionListId]?.ascendingOrderItems
  );
  const resolutionData = {
    isOpen: false,
    clickData: addItemSaveListData,
    saveListData: saveToListParts
  };

  const filterUpdatedNotes = () => {
    const newLineItemParts = newItemNotes(
      ascendingOrderItemsNew,
      saveToListParts
    );
    const oldLineItemParts = oldItemNotes(
      ascendingOrderItemsOld,
      saveToListParts
    );
    let tempUpdatedNotes = [];

    if (newLineItemParts?.length > 0 && oldLineItemParts?.length > 0) {
      for (const a of newLineItemParts) {
        let notesObj;
        for (const s of oldLineItemParts) {
          const newNotes = a.lineItemNotes;
          const oldNotes = s.lineItemNotes;
          if (s.partNumber === a.partNumber) {
            notesObj = {
              partNumber: a.partNumber,
              partName: a.partName,
              quantity: a.quantity,
              lineItemNotes: getUpdatedNotes(newNotes, oldNotes)
            };
          }
        }
        notesObj && tempUpdatedNotes.push(notesObj);
        setUpdatedNotes(tempUpdatedNotes);
      }
    } else {
      for (const a of newLineItemParts) {
        setUpdatedNotes([
          ...updatedNotes,
          {
            partNumber: a.partNumber,
            quantity: a.quantity
          }
        ]);
      }
    }
  };

  const setConflictStatus = () => {
    const conflictArray = [];
    updatedNotes?.map(val => {
      let conflictObj;
      val?.lineItemNotes &&
        Object?.values(val?.lineItemNotes).filter(l => {
          if (l.isConflict) {
            conflictObj = {
              ...val
            };
          }
        });
      conflictObj && conflictArray.push(conflictObj);
    });
    const flag = conflictArray.length > 0 ? true : false;
    dispatch(setConflictFlag(flag));
    setConflictPresent(flag);
  };
  const onSaveDispatch = (data, close) => {
    const saveListData = getConflictData(data);
    const nonConflictItems = getNonConflictingItems(
      ascendingOrderItemsNew,
      saveListData
    );

    dispatch(
      addProductToSavedList({
        ...addItemSaveListData,
        fromConflictNotesModal: TRUE_VALUE,
        saveListData: saveListData.concat(nonConflictItems),
        close,
        ToastCtx,
        toastMessage: t('SUCCESS_MESSAGE'),
        errorData: {
          message: 'MLP_ERROR3',
          severity: 'error'
        },
        errorDomain: ERROR_DOMAIN.CONFLICT_MODAL,
        errorPath: ERROR_PATH.CONFLICT_RESOLUTION_MODAL,
        fromConflictModal: true
      })
    );
  };
  const onCloseDispatch = close => {
    const saveListData = [];
    for (let i = 0; i < updatedNotes?.length; i++) {
      const conflictObject = {};
      const val = updatedNotes[i];
      for (const [key, { conflictValue, isConflict }] of Object?.entries(
        val?.lineItemNotes
      )) {
        if (!isConflict) {
          conflictObject[keyValue(key)] = conflictValue;
        }
      }
      saveListData.push({
        partNumber: val?.partNumber,
        quantity: val?.quantity,
        ...conflictObject
      });
    }
    // Ensure all parts have been added
    saveToListParts?.forEach(part => {
      if (!saveListData.some(item => item.partNumber === part.partNumber)) {
        saveListData.push(part);
      }
    });
    dispatch(
      addProductToSavedList({
        ...addItemSaveListData,
        fromConflictNotesModal: TRUE_VALUE,
        saveListData: saveListData,
        close,
        ToastCtx,
        toastMessage: t('PDP_ADD_TO_LIST_TOAST')
      })
    );
  };

  const onClickOpenResolutionModal = () => {
    dispatch(setConflictConfirmationFlag(resolutionData));
    dispatch(setConflictResolutionFlag(true));
  };

  const getPartList = updatedNotes?.map(val => {
    let newArray = [];
    const arr = val?.lineItemNotes && Object.values(val?.lineItemNotes);
    newArray = arr?.find(({ isConflict }) => isConflict);
    return newArray ? { ...val, isPartNumberDisplay: true } : val;
  });

  useEffect(() => {
    if (updatedNotes?.length > 0) {
      setConflictStatus();
    } else {
      dispatch(setConflictFlag(null));
    }
  }, [updatedNotes]);
  useEffect(() => {
    if (
      ascendingOrderItemsOld?.length > 0 &&
      ascendingOrderItemsNew?.length > 0
    ) {
      filterUpdatedNotes();
    }
  }, [ascendingOrderItemsNew, ascendingOrderItemsOld]);
  useEffect(() => {
    if (isOpenConflictConfirmation && isConflictPresent && cartMergeEnabled) {
      setConfirmationModalFlag(true);
    }
  }, [cartMergeEnabled, isConflictPresent, isOpenConflictConfirmation]);
  return {
    updatedNotes,
    isConflictPresent,
    onCloseDispatch,
    onClickOpenResolutionModal,
    getPartList,
    confirmationModalFlag,
    onSaveDispatch
  };
};
export default useConflictNotes;
