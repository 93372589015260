import { getInitialStoreName, getInitialAccountName } from './updateUtil';
import { getInitialFormValues, updatePreferencesData } from './formUtil';
import { isEmpty } from '../../utils';
import { STATUS } from '@app/constants/commonConstants';

export const preferencesRestore = (state, action) => {
  return {
    ...state,
    selectedDealerStore: getInitialStoreName(state.shoppingPreferencesData),
    selectedAccount: getInitialAccountName(state.shoppingPreferencesData),
    askResetPreferences: false,
    heading: {},
    isChangingDealerStore: false
  };
};

export const preferenceUpdateSuccess = (state, action) => {
  const spd = {
    ...action.payload.data,
    marketingData: action.payload.marketingData,
    languageOptions: action.payload.languageOptions
  };

  const shoppingPreferencesData = updatePreferencesData(spd);

  return {
    ...state,
    shoppingPreferencesData,
    initialFormValues: getInitialFormValues(shoppingPreferencesData),
    isSubmitting: false
  };
};

export const newStoreSuccess = (state, action) => {
  // user has successfully changed the default store
  const initialFormValues = getInitialFormValues({
    ...action.payload.data,
    languageOptions: action.payload.languageOptions
  });
  const spd = {
    ...action.payload.data,
    marketingData: action.payload.marketingData
  };

  return {
    ...state,
    shoppingPreferencesData: updatePreferencesData(spd),
    initialFormValues,
    isLoading: false,
    heading: {},
    askResetPreferences: false,
    isChangingDealerStore: false
  };
};

export const loadSuccess = (state, action) => {
  let heading = {};
  let shoppingPreferencesData = updatePreferencesData(action.payload);
  let dealerStore = shoppingPreferencesData.dealerStore;
  let selectedDealerStore = getInitialStoreName(shoppingPreferencesData);
  let selectedAccount = getInitialAccountName(shoppingPreferencesData);
  shoppingPreferencesData.scrollTo = state.scrollTo;
  if (isEmpty(selectedAccount)) {
    // if there is no selected DCN, remove the store list (if any)
    // this is because there must be a default DCN to show store list
    shoppingPreferencesData.dealerStore = [];
    selectedDealerStore = '';
    heading = { showApply: false, onlyShowHeader: true };
    if (shoppingPreferencesData?.customerNumber?.length === 1) {
      selectedAccount = shoppingPreferencesData.customerNumber[0].displayName;
      heading = { showApply: true, onlyShowHeader: true };
    }
  } else {
    if (isEmpty(selectedDealerStore)) {
      heading = { showApply: true, onlyShowHeader: true };
      if ((dealerStore || []).length === 1) {
        selectedDealerStore = dealerStore[0].displayName;
      }
    }
  }

  return {
    ...state,
    error: undefined,
    heading,
    initialFormValues: getInitialFormValues(shoppingPreferencesData),
    isLoading: false,
    selectedAccount,
    selectedDealerStore,
    shoppingPreferencesData,
    scrollTo: undefined,
    shoppingPreferenceDataStatus: STATUS.RESOLVED
  };
};

export const loadStoresSuccess = (state, action) => {
  const dealerStore = action.payload.data.dealerStore;
  const heading = { showApply: true, onlyShowHeader: true };
  let selectedDealerStore = undefined;
  if ((dealerStore || []).length === 1 && !dealerStore[0].default) {
    //  Special handling for single entry that is not default
    // User must click apply even though only one choice
    selectedDealerStore = dealerStore[0].displayName;
  }

  const shoppingPreferencesData = {
    ...state.shoppingPreferencesData,
    dealerStore
  };

  return {
    ...state,
    shoppingPreferencesData,
    selectedDealerStore,
    heading,
    isLoading: false
  };
};
