import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IncludeDismissedNotifications } from '@cat-ecom/pcc-components';
import { getNotificationHeaderList } from '@app/store/notificationHeader/action';

const IncludeDismissNotification = () => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const includeDismissedNotifications = useSelector(
    s => s.notificationHeader?.includeDismissedNotifications
  );

  const handleToggleChange = useCallback(
    e => {
      dispatch(getNotificationHeaderList(e?.detail?.checked));
    },
    [dispatch]
  );

  return (
    <IncludeDismissedNotifications
      includeDismissedNotifications={includeDismissedNotifications}
      handleToggleChange={handleToggleChange}
      title={t('NOTIFICATIONS_INCLUDE_DISMISSED_NOTIFICATIONS')}
    />
  );
};

export default IncludeDismissNotification;
