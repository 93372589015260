import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AlloyButton,
  ChildModal,
  ListManager,
  Modal,
  PlusIcon,
  StickySection,
  useModal
} from 'cat-ecommerce-alloy';
import cx from 'classnames';
import { PLUS_ICON_SIZE, SAVED_LIST_ITEM_COUNT } from '../constants';
import { useBreakpoint } from '@app/hooks';
import useSavedLists from '../useSavedLists';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import SavedListsTable from '../SavedListsTable';
import CreateListForm from '../CreateListForm';
import ViewMoreButton from '../ViewMoreButton';
import styles from './AddSavedListModal.module.scss';

const AddSavedListModal = ({
  close,
  isOpen,
  addSavedListData,
  isCreateListOnly = false,
  fromPDPPage = false
}) => {
  const [t] = useTranslation();
  const savedLists = useSelector(s => s.account?.savedLists);
  const [numDisplayed, setNumDisplayed] = useState(SAVED_LIST_ITEM_COUNT);
  const moreResultsAvailable = savedLists?.length > numDisplayed;
  const childModalProps = useModal({
    isChild: true,
    parentIsOpen: isOpen
  });
  const viewMoreLists = val => {
    setNumDisplayed(val);
  };
  const isLG = useBreakpoint(PAGE_BREAKPOINTS.LG);
  const { setShowSavedListsModalAfterADP } = useSavedLists();
  const savedListsHeightClass = isLG
    ? styles['scrollable-section-desktop']
    : styles['scrollable-section-mobile'];
  const onCloseModal = () => {
    close();
    setShowSavedListsModalAfterADP(false);
  };
  return (
    <div className="container">
      <Modal
        close={onCloseModal}
        contentClassName={'pb-0 pe-0'}
        isOpen={isOpen}
        title={t('ADD_ITEM_TO_LIST')}
      >
        <ListManager list={savedLists}>
          <div
            className={cx(
              'mb-3',
              styles['scrollable-section'],
              `${savedListsHeightClass}`
            )}
          >
            <SavedListsTable
              saveListData={addSavedListData}
              close={onCloseModal}
              numDisplayed={numDisplayed}
              fromPDPPage={fromPDPPage}
            />
          </div>
          {moreResultsAvailable && (
            <ViewMoreButton viewMoreLists={viewMoreLists} />
          )}
        </ListManager>

        <StickySection
          className={cx(
            'd-flex justify-content-start align-items-center bg-white px-3',
            styles['modal__sticky-container']
          )}
        >
          <AlloyButton
            buttonType="ghost"
            size="small"
            className="d-flex align-items-center p-0"
            onClick={() => {
              childModalProps.open();
            }}
          >
            <PlusIcon height={PLUS_ICON_SIZE} width={PLUS_ICON_SIZE} />
            {t('CREATE_NEW_LIST')}
          </AlloyButton>
        </StickySection>
        <ChildModal
          title={t('CREATE_A_NEW_LIST')}
          backButtonMessage={t('BACK_ADD_ITEM_LIST')}
          {...childModalProps}
          className={'d-flex flex-column me-4 pe-2'}
          focusSelector="input"
        >
          <CreateListForm
            close={close}
            isOpen={isOpen}
            createSavedListData={addSavedListData}
            isCreateListOnly={isCreateListOnly}
            fromPDPPage={fromPDPPage}
          />
        </ChildModal>
      </Modal>
    </div>
  );
};

AddSavedListModal.propTypes = {
  close: PropTypes.func,
  isOpen: PropTypes.bool,
  isCreateListOnly: PropTypes.bool,
  addSavedListData: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.string,
        itemPartNumber: PropTypes.string
      })
    )
  }),
  fromPDPPage: PropTypes.bool
};

export default AddSavedListModal;
