import PropTypes from 'prop-types';

export const addressShape = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  addressId: PropTypes.string,
  city: PropTypes.string,
  className: PropTypes.string,
  country: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  nickName: PropTypes.string,
  phone: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string
};

export const address = PropTypes.shape(addressShape);

export const addresses = PropTypes.arrayOf(address);

export default {
  addressShape,
  address,
  addresses
};
