import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTracking } from 'react-tracking';
import queryString from 'query-string';
import { CSR_TEXTS } from '@app/components/pages/csr-landing/texts';
import { getStoreInfo } from '@app/services/storeInfoService';
import useHttp from '@app/hooks/useHttp';
import { useSystemFeedback } from '@app/hooks';
import endpoints from '@app/constants/endpoints';
import { getFormSubmitEvent } from '@app/components/common/analytics/analyticsUtils';
import {
  replaceTokensInUrl,
  isShoppingCartPage,
  normalizeUrl
} from '@app/utils';
import { normalizeError } from '@app/store/exception/utils';
import {
  STATUS_FAIL,
  SYSTEM_ERROR
} from '@app/components/common/analytics/analyticsConstants';
import { ERROR_DOMAIN, ERROR_PATH } from '@app/constants/errorConstants';
import { CSR_ACTIONS } from '@app/components/pages/csr-landing/constants';
import { MULTI_EQ_ROUTE } from '@app/components/pages/homepage/constants';
import { removeLocalStorage } from '@app/utils/localStorageUtils';
import {
  FULFILMENT_DATE_CHANGED,
  LAST_FULFILLMENT_DATE_CHANGED,
  TIMEOUT_EXTENDED
} from '@app/constants/commonConstants';
import { getGaEvent, getLoginFromPage } from './utils';

const useSelectDealer = () => {
  const { invoke: call, error, loading } = useHttp();
  const { invoke: callOrgSetting } = useHttp();
  const { invoke: dealerCall } = useHttp(
    useHttp({ timeout: TIMEOUT_EXTENDED })
  );
  const { route = '', searchTerm = '' } = queryString.parse(
    window?.location?.href?.split('?')?.pop()
  );
  const { trackEvent } = useTracking();
  const { setError } = useSystemFeedback();
  const model = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment?.model
  );
  const serialNumber = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment?.serialNumber
  );
  const langId = useSelector(s => s?.common?.langId);
  const redirectionPoint = useSelector(s => s.customers?.redirectionPoint);
  const selectedStore = useSelector(s => s.common?.selectedStore);

  const getDealerStore = useCallback(
    (dcnNumberValue, storeId, body) => {
      const dealerStoreUrl = replaceTokensInUrl(
        endpoints.SELECT_DEALER,
        storeId,
        dcnNumberValue,
        langId
      );
      return dealerCall({
        data: body,
        method: 'put',
        rethrowError: true,
        url: dealerStoreUrl
      });
    },
    [dealerCall, langId]
  );
  const invoke = useCallback(
    (
      {
        dcnNumberName,
        dcnNumberValue,
        dealer,
        endUseCode,
        isLoginAction,
        orderType,
        skipThisStep,
        storeLocationId
      } = {},
      bobRedirect = '',
      adpSubmitPromise,
      bobUserId,
      reorderFunc = false
    ) => {
      const fromPage = isShoppingCartPage() ? 'shopCart' : '';
      const isBuyOnBehalf =
        bobRedirect === CSR_ACTIONS.BUY_ON_BEHALF ||
        bobRedirect === CSR_ACTIONS.MANAGE_ACCOUNT;
      const body = {
        dcnNumberName,
        orderType,
        endUseCode,
        fromPage,
        bobRedirect: redirectionPoint,
        isLoginAction,
        saveToPreferencesFlag: skipThisStep,
        storeLocationId,
        loginFromPage: getLoginFromPage(),
        equipmentMakeModel: model || null,
        equipmentSerialNumber: serialNumber || null
      };
      if (route === MULTI_EQ_ROUTE && searchTerm) {
        body['searchTerm'] = searchTerm;
        body['route'] = route;
      }
      const gaEvent = getFormSubmitEvent(
        getGaEvent(isLoginAction, skipThisStep)
      );
      const { langId } = getStoreInfo();
      const commonUrl = encodeURI(
        replaceTokensInUrl(
          endpoints.SELECT_DEALER,
          dealer,
          dcnNumberValue,
          langId
        )
      );
      const buyOnBehalfUrl = encodeURI(
        replaceTokensInUrl(
          `${endpoints.SELECT_DEALER}&forUserId={3}`,
          dealer,
          dcnNumberValue,
          langId,
          bobUserId
        )
      );

      const selectDealerUrl = isBuyOnBehalf ? buyOnBehalfUrl : commonUrl;
      call({
        data: body,
        method: 'put',
        rethrowError: true,
        url: selectDealerUrl
      })
        .then(async ({ redirectURL }) => {
          // Calling dealercustomerOrgSetInSesion as work around proposed on Bug 188019
          const url = replaceTokensInUrl(
            endpoints.DEALER_CUSTOMER_ORG_SET_IN_SESSION,
            dealer
          );
          trackEvent(gaEvent);
          if (isBuyOnBehalf) {
            await adpSubmitPromise(dealer, true);
          }
          callOrgSetting({ url })
            // No need to handle the error, we just want to redirect even if the call fails
            // but we want to avoid throwing an error
            .catch(() => null)
            .finally(() => {
              if (selectedStore !== body.storeLocationId) {
                removeLocalStorage(LAST_FULFILLMENT_DATE_CHANGED);
                removeLocalStorage(FULFILMENT_DATE_CHANGED);
              }
              if (reorderFunc) {
                reorderFunc(dealer);
              } else if (redirectURL) {
                window.location = normalizeUrl(redirectURL);
              }
            });
        })
        .catch(error => {
          trackEvent({
            ...gaEvent,
            formFieldCausingError: SYSTEM_ERROR,
            formStatus: STATUS_FAIL
          });
          if (redirectionPoint) {
            setError(ERROR_DOMAIN.ADP, ERROR_PATH.STORES, {
              message: CSR_TEXTS.ADP_MODAL_ERROR_MESSAGE,
              severity: 'error'
            });
          } else {
            setError(
              ERROR_DOMAIN.ADP,
              ERROR_PATH.STORES,
              normalizeError(error)
            );
          }
        });
    },
    [
      call,
      callOrgSetting,
      model,
      serialNumber,
      setError,
      trackEvent,
      redirectionPoint
    ]
  );

  return { error, invoke, loading, getDealerStore };
};

export default useSelectDealer;
