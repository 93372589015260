import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CatToast } from '@blocks-react/components/Toast';
import { CatHeading } from '@blocks-react/components/Heading';
import { CatIconSuccess } from '@blocks-react/components/Icons/Success';
import { TOAST_TYPE } from '@app/constants/commonConstants';
import {
  DELAY_VERIFIED_TOAST_MESSAGE_IN_SECONDS,
  BANNER_TOASTS
} from '../constants';
import BannerToastOnce from '../BannerToastOnce';

const CSRVerifiedToast = () => {
  const isBuyOnBehalf = useSelector(s => s.common.isBuyOnBehalf) || false;
  const isCSPCustomer = useSelector(s => s.common.isCSPCustomer) || false;
  const isFlagCustomerSupportCSRBannerMessagingEnabled =
    useSelector(
      state => state?.featureFlag?.PCC_CustomerSupportCSRBannerMessaging
    ) || false;
  const [showToastContainer, setShowToastContainer] = useState(false);

  useEffect(() => {
    setShowToastContainer(false);
    if (!isCSPCustomer || !isBuyOnBehalf) {
      return;
    }

    setShowToastContainer(true);
  }, [isBuyOnBehalf, isCSPCustomer]);

  if (!showToastContainer || isFlagCustomerSupportCSRBannerMessagingEnabled) {
    return;
  }

  return (
    <BannerToastOnce name={BANNER_TOASTS.cspCustomer.name}>
      <CatToast
        variant={TOAST_TYPE.SUCCESS}
        delay={DELAY_VERIFIED_TOAST_MESSAGE_IN_SECONDS}
        autoClose
      >
        <CatIconSuccess slot="before" />
        <CatHeading variant="label-sm">
          {BANNER_TOASTS.cspCustomer.message}
        </CatHeading>
      </CatToast>
    </BannerToastOnce>
  );
};

export default CSRVerifiedToast;
