import PropTypes, { arrayOf } from 'prop-types';
import { BreadCrumb as AlloyBreadCrumb } from 'cat-ecommerce-alloy';
import { isExternalReferrer } from '../../../utils';
import { useHistory } from 'react-router-dom';
import { CatBreadcrumbs } from 'blocks-react/bedrock/components/Breadcrumbs';
import { useSelector } from 'react-redux';
import { ACCOUNT_NAVIGATION_FLAG } from '@app/components/pages/control-center/constants';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import { CatIconChevronLeft } from '@blocks-react/components/Icons/ChevronLeft';
const BreadCrumb = ({
  links,
  backButtonText,
  className = '',
  showBackButton = true,
  hideLastLink
}) => {
  const history = useHistory();

  const blocksNavigationFeatureFlag = useSelector(
    state => state.featureFlag[ACCOUNT_NAVIGATION_FLAG]
  );

  const innerShowBackButton =
    (!isExternalReferrer(document.referrer, document.location.host) &&
      window.history.length > 1) ||
    history?.length > 1;
  const interceptLinks = links?.map(
    ({ label, value, name: partName, seoURL, ...link }) => {
      let linkObj = {
        ...link,
        label: label || partName,
        value: value || seoURL
      };
      if (blocksNavigationFeatureFlag) {
        linkObj = { ...link, label: label || partName, href: value || seoURL };
      }
      return linkObj;
    }
  );

  const navigateBack = () => {
    history?.goBack?.();
  };

  const getRenderBreadcrumb = () => {
    return blocksNavigationFeatureFlag ? (
      <div
        className={`${className} d-print-none d-flex align-items-center py-3 cat-u-theme-typography-body-sm`}
      >
        {innerShowBackButton && showBackButton && (
          <CatButton
            variant="ghost"
            size="md"
            className="me-3"
            onClick={navigateBack}
          >
            <CatIconChevronLeft color="information" slot="before" />
            {backButtonText}
          </CatButton>
        )}
        <CatBreadcrumbs
          items={interceptLinks}
          maxCharacterCount={30}
          mobileBreakpoint={0}
        />
      </div>
    ) : (
      <AlloyBreadCrumb
        backButtonText={backButtonText}
        links={interceptLinks}
        showBackButton={innerShowBackButton && showBackButton}
        className={`${className} d-print-none`}
        hideLastLink={hideLastLink}
      />
    );
  };

  return interceptLinks ? <>{getRenderBreadcrumb()}</> : null;
};

export const linksShape = {
  key: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string
};

BreadCrumb.propTypes = {
  links: arrayOf(PropTypes.shape(linksShape)),
  backButtonText: PropTypes.string,
  className: PropTypes.string,
  showBackButton: PropTypes.bool,
  hideLastLink: PropTypes.bool
};

export default BreadCrumb;
