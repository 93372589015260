import http from './defaultHttpService';

export function getTranslations(keys, locale, storeId) {
  const TRANSLATION_BASE_URL = `/wcs/resources/store/${storeId}/catbundle`;

  return http
    .post(`${TRANSLATION_BASE_URL}/${locale}/keys`, { keys })
    .then(response => {
      return response;
    })
    .catch
    // TODO: HANDLE ERRORS
    ();
}

export default { getTranslations };
