import { useContext, useEffect } from 'react';
import { MapContext } from 'cat-ecommerce-alloy';
import { ACTIVE_MARKER_ICON, INACTIVE_MARKER_ICON } from '../../constants';
import { getLatLng } from '../../utils';
import PropTypes from 'prop-types';
import { dealerlocatorResultsPropTypes } from '../../declarations.proptypes';

const MarkerControl = ({ stores, selectedMarkerId }) => {
  const ctx = useContext(MapContext);
  const map = ctx.map;
  const markers = ctx.markers;
  const MAX_ZINDEX = 999;
  const MIN_ZINDEX = 1;
  useEffect(() => {
    if (map && markers && markers.length > 0 && selectedMarkerId) {
      const newActiveMarker = markers.find(
        marker => marker.id === selectedMarkerId
      );
      const newActiveMarkerData = stores.find(
        store => store.id === selectedMarkerId
      );
      if (newActiveMarker && newActiveMarkerData) {
        const newCenter = getLatLng(
          newActiveMarkerData.latitude,
          newActiveMarkerData.longitude
        );
        map.panTo(newCenter);
        markers.map(marker => {
          marker.setIcon(INACTIVE_MARKER_ICON);
          return marker.setZIndex(MIN_ZINDEX);
        });
        newActiveMarker.setIcon(ACTIVE_MARKER_ICON);
        newActiveMarker.setZIndex(MAX_ZINDEX);
      }
    }
  }, [selectedMarkerId, map, markers, stores]);

  return <></>;
};

MarkerControl.propTypes = {
  selectedMarkerId: PropTypes.string,
  stores: PropTypes.arrayOf(dealerlocatorResultsPropTypes)
};

export default MarkerControl;
