import orderBy from 'lodash/orderBy';
import { SORT_DIRECTIONS, SORT_TYPE } from '../constants/commonConstants';

export const sortText = (aVal, bVal) => {
  if ((!aVal && !bVal) || aVal === bVal) {
    return 0;
  }
  if (aVal < bVal) {
    return -1;
  }
  return 1;
};

export const sortNumeric = (numA, numB) => {
  return numA - numB;
};
const sortDate = (d1, d2) => {
  return new Date(d1) - new Date(d2);
};
const sortAlphaNumeric = (str1, str2) =>
  (str1 || '').toLowerCase().localeCompare((str2 || '').toLowerCase());

export const sortEquipment = (list, dir, order) => {
  const direction = dir === SORT_DIRECTIONS.DESC ? 'desc' : 'asc';
  return orderBy(list, order, [direction, direction, direction]);
};
export const sortDescendingOrder = (list, dir) => {
  if (dir === SORT_DIRECTIONS.DESC) {
    return list.reverse();
  }
};
export const sortList = ({
  id,
  order,
  dir = SORT_DIRECTIONS.ASC,
  type = SORT_TYPE.TEXT,
  data = []
}) => {
  let sortedList = [...data];
  switch (type) {
    case SORT_TYPE.NUMERIC:
      sortedList.sort((a, b) => sortNumeric(a[id], b[id]));
      sortDescendingOrder(sortedList, dir);
      break;
    case SORT_TYPE.ALPHANUMERIC:
      sortedList.sort((a, b) => sortAlphaNumeric(a[id], b[id]));
      sortDescendingOrder(sortedList, dir);
      break;
    case SORT_TYPE.DATE:
      sortedList.sort((a, b) => sortDate(a[id], b[id]));
      sortDescendingOrder(sortedList, dir);
      break;
    case SORT_TYPE.EQUIPMENT:
      sortedList = sortEquipment([...data], dir, order);
      break;
    default:
      sortedList.sort((a, b) => sortText(a[id], b[id]));
      sortDescendingOrder(sortedList, dir);
  }
  return sortedList;
};
