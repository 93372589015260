/* This makes error field visible below the sticky header
 * first scroll to error field
 * finding scrollY
 * adjusting scroll top position
 * includeNodeHeight prop used to adjust scrolling position from top based on selected node height.
 */

import { STICKY_TIMEOUT } from '@app/constants/commonConstants';

export const stickyScroll = (ref, includeNodeHeight = true) => {
  const node = ref?.current?.input ?? ref?.current;
  if (node) {
    node?.scrollIntoView(true);
    const stickyHeaderHeight = document
      ?.getElementById('react-root-header')
      ?.getBoundingClientRect()?.height;

    setTimeout(() => {
      if (window?.scrollY) {
        const topScrollPosition = includeNodeHeight
          ? window?.scrollY -
            stickyHeaderHeight -
            node.getBoundingClientRect()?.height
          : window?.scrollY - stickyHeaderHeight;
        window?.scroll({
          behavior: 'smooth',
          left: 0,
          top: topScrollPosition
        });
      }
    }, STICKY_TIMEOUT);
  }
};
