import * as types from './constants';
import endpoints from '../../constants/endpoints';
import { getHttpInstance } from '../../services/defaultHttpService';
import { getStoreInfo } from '../../services/storeInfoService';
import { replaceTokensInString } from '../../utils';
import { normalizeError } from '../exception/utils';
import { setError } from '../exception/actions';
import { normalizeUrl } from '../../utils';
import {
  ERROR_DOMAIN,
  ERROR_PATH,
  INFO_SHOPPING_CART_CRITICAL_ERROR
} from '@app/constants/errorConstants';
import { SHOPPING_CART_ROUTE } from '@app/constants/routes';

export const getDealerInfo =
  (errorInfo = {}) =>
  dispatch => {
    dispatch({ type: types.DEALER_GET_INFO_BEGIN });
    const isShoppingCartPage =
      window.location.pathname.includes(SHOPPING_CART_ROUTE);
    const { storeId, langId } = getStoreInfo();
    const http = getHttpInstance();
    const url = replaceTokensInString(
      endpoints.DEALER_FIELDS_INFO,
      storeId,
      langId
    );
    return http
      .get(url)
      .then(({ data } = {}) => {
        const seoURL = normalizeUrl(data.seoURL);
        dispatch({
          type: types.DEALER_GET_INFO_SUCCESS,
          payload: {
            ...data,
            seoURL
          }
        });
      })
      .catch(error => {
        if (isShoppingCartPage) {
          dispatch(
            setError(
              ERROR_DOMAIN.CHECKOUT,
              ERROR_PATH.SHOPPING_CART_CRITICAL_ERROR,
              INFO_SHOPPING_CART_CRITICAL_ERROR
            )
          );
        }
        dispatch({
          type: types.DEALER_GET_INFO_FAIL,
          error
        });
        const { domain, path } = errorInfo;
        if (domain && path) {
          dispatch(setError(domain, path, normalizeError(error)));
        }
      });
  };
