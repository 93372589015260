import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageLoader } from 'cat-ecommerce-alloy';
import PropTypes from 'prop-types';
import { changeLanguage } from '@app/store/footer/actions';
import {
  LOCATION_HASH_KEY,
  OMM_BOOK_COOKIE,
  STATUS
} from '@app/constants/commonConstants';
import { FooterLanguages } from '@cat-ecom/pcc-components';
import { getSessionStorage, isEmpty } from '@app/utils';
import Conditional from '@app/components/common/Conditional';
import styles from './FooterLanguageButtons.module.scss';

const FooterLanguageButtons = ({ latestHref }) => {
  const dispatch = useDispatch();
  const [languages, setLanguage] = useState([]);
  const changeLanguageStatus = useSelector(
    state => state.footer?.changeLanguageStatus
  );
  const getLanguageStatus = useSelector(
    state => state.footer?.getLanguageStatus
  );
  const supportedLanguages = useSelector(
    state => state.footer?.supportedLanguages
  );
  const { langId, locale, esiteURL } = useSelector(state => state.common);

  const languagesLoading =
    getLanguageStatus === STATUS.IDLE || getLanguageStatus === STATUS.PENDING;

  const languageChangeLoading =
    changeLanguageStatus === STATUS.PENDING ||
    changeLanguageStatus === STATUS.RESOLVED;

  const ommDetails = () => {
    const sessionValue = getSessionStorage(OMM_BOOK_COOKIE.BOOK_DETAILS) ?? {};
    if (!isEmpty(sessionValue)) {
      const { equipment = null } = JSON.parse(sessionValue);
      return `${equipment.seoUrl}${LOCATION_HASH_KEY.OMM}`;
    }
  };

  useEffect(() => {
    const renderLangHref = value => {
      let browserURL = latestHref;
      if (
        (browserURL.indexOf('SearchDisplay') !== -1 ||
          browserURL.indexOf('CategoryDisplay') !== -1) &&
        browserURL.indexOf('#') !== -1
      ) {
        const poundTokens = browserURL.split('#');
        browserURL = poundTokens[0];
      }
      if (browserURL.indexOf('RESTUserRegistrationUpdate') !== -1) {
        browserURL = browserURL.replace(
          'RESTUserRegistrationUpdate',
          'UserRegistrationForm?editRegistration=Y'
        );
      } else if (browserURL.indexOf('OmmDetailsView') !== -1) {
        browserURL = ommDetails() ?? browserURL;
      }
      let modifiedFormURL = browserURL;
      if (
        modifiedFormURL.indexOf(locale.substr(0, locale.indexOf('_'))) === -1 &&
        modifiedFormURL.indexOf('langId=') === -1 &&
        modifiedFormURL.indexOf(esiteURL?.split('/')[2]) >= 0
      ) {
        browserURL = browserURL.replace(
          `/${esiteURL?.split('/')[2]}`,
          `${esiteURL}`
        );
      }
      if (locale === 'zh_CN') {
        modifiedFormURL = browserURL.replace(`/zh-CN/`, `/${value.seoToken}/`);
      } else if (locale === 'zh_TW') {
        modifiedFormURL = browserURL.replace(`/zh-TW/`, `/${value.seoToken}/`);
      } else {
        modifiedFormURL = browserURL.replace(
          `/${locale.substr(0, locale.indexOf('_'))}/`,
          `/${value.seoToken}/`
        );
      }

      if (modifiedFormURL.indexOf('&') !== -1) {
        const tokens = modifiedFormURL.split('&');
        modifiedFormURL = '';
        for (let i = 0; i < tokens.length; i++) {
          if (tokens[i].indexOf('langId=') === -1) {
            if (modifiedFormURL === '') {
              modifiedFormURL = tokens[i];
            } else {
              modifiedFormURL = modifiedFormURL.concat('&', tokens[i]);
            }
          } else if (tokens[i].indexOf('langId=') >= 0) {
            if (i === 0) {
              modifiedFormURL = tokens[0].substring(
                0,
                tokens[0].indexOf('langId=')
              );
            } else {
              modifiedFormURL = `${modifiedFormURL}&`;
            }
            modifiedFormURL = modifiedFormURL.concat(
              'langId=',
              value.languageId
            );
          }
        }
      }
      //Fix for AZ-684316_Guest_MyEquipment_Translation
      if (modifiedFormURL.indexOf('GuestUserMyEquipmentView') !== -1) {
        //case1 : If browserURL contains langId field, replace the old lang_id value with selected language id.
        if (modifiedFormURL.indexOf('langId') !== -1) {
          const tokens = modifiedFormURL.split('&langId');
          for (const element of tokens) {
            if (element.indexOf('GuestUserMyEquipmentView') !== -1) {
              modifiedFormURL = element + '&langId=' + value.languageId;
            }
            break;
          }
        } else {
          // case2 : If langId field is missing in browserURL ,concat the field with selected language id.
          modifiedFormURL = modifiedFormURL.concat(
            '&langId=',
            value.languageId
          );
        }
      }

      //637022:ALP DIY Maintenance Videos-Handling Video Starts
      //Checking if request comes from AlpMaintenanceVideosView page
      if (modifiedFormURL.indexOf('AlpMaintenanceVideosView') !== -1) {
        //case1 : If browserURL contains langId field, replace the old lang_id value with selected language id.
        if (modifiedFormURL.indexOf('langId') !== -1) {
          const tokens = modifiedFormURL.split('&langId=');
          for (const element of tokens) {
            if (element.indexOf('AlpMaintenanceVideosView') !== -1) {
              modifiedFormURL = element + '&langId=' + value.languageId;
            }
            break;
          }
        } else {
          // case2 : If langId field is missing in browserURL ,concat the field with selected language id.
          modifiedFormURL = modifiedFormURL.concat(
            '&langId=',
            value.languageId
          );
        }
      }

      return modifiedFormURL;
    };
    setLanguage(
      supportedLanguages.map(item => ({
        ...item,
        href: renderLangHref(item)
      }))
    );
    // multiple renders because of renderLangHref dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportedLanguages, locale, latestHref, langId]);

  const onLanguageChange = (e, language) => {
    e.preventDefault();
    dispatch(changeLanguage(language));
  };

  const placeholder = (
    <div
      className={styles['footer__language-buttons__placeholder']}
      data-testid="language-buttons-placeholder"
    />
  );

  return (
    <>
      <Conditional test={!languagesLoading} fallback={placeholder}>
        <FooterLanguages
          languages={languages}
          langId={langId}
          onClick={onLanguageChange}
        />
      </Conditional>
      {languageChangeLoading && <PageLoader />}
    </>
  );
};
export default FooterLanguageButtons;

FooterLanguageButtons.propTypes = {
  latestHref: PropTypes.string
};
