export const PICKUP = 'pickup';
export const DROPBOX = 'dropBox';
export const SHOP_ONLINE = 'shopOnline';
export const DELIVERY = 'delivery';
const ESPOT_NAME = 'DROPBOX_ESTIMATED_DATE_TOOLTIP_ESPOT';
const DELIVERY_ESPOT_NAME = 'DELIVERY_ESTIMATED_DATE_TOOLTIP_ESPOT';
export const DROPBOX_DELIVERY_ESPOTS = {
  espotNames: [ESPOT_NAME].join(','),
  DROPBOX_ESTIMATED_DATE: ESPOT_NAME,
  DELIVERY_ESTIMATED_DATE: DELIVERY_ESPOT_NAME
};
export const ESTIMATED_DELIVERY_DATE_TOOLTIP_TYPE =
  'ESTIMATED_DELIVERY_DATE_TOOLTIP';
export const DROP_BOX_ESTIMATED_PICKUP_DATE_TOOLTIP_TYPE =
  'DROP_BOX_ESTIMATED_PICKUP_DATE_TOOLTIP';
export const ESTIMATED_CHARGE_ESPOT_NAME = 'DELIVERY_ESTIMATED_CHARGE_ESPOT';
export const ESTIMATED_CHARGE_TOOLTIP = 'ESTIMATED_CHARGE_TOOLTIP';
