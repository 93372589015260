import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { getStoreInfoLegacy } from './services/storeInfoService';
import App from './App';

const Root = () => {
  // Legacy store info vars will be removed once we are sure we have
  // replaced all dependencies with the values from the common store.
  const {
    catalogId,
    isCSPCustomer,
    langId,
    locale,
    selectedCustomerNumber,
    selectedDealer,
    selectedStore,
    storeId,
    userType
  } = getStoreInfoLegacy();
  const store = configureStore({
    catalogId,
    isCSPCustomer,
    langId,
    locale,
    selectedCustomerNumber,
    selectedDealer,
    selectedStore,
    storeId,
    userType
  });
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

export default Root;
