import FindMyModal from '@app/components/common/FindMyModal';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import { useBreakpoint } from '@app/hooks';
import {
  AlloyButton,
  ChevronRightIcon,
  ModalContext
} from 'cat-ecommerce-alloy';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FindMySerial from '../../FindMySerial';
import styles from '../SearchSuggestions.module.scss';

const SearchSuggestionsActions = () => {
  const { t } = useTranslation();
  const modalCtx = useContext(ModalContext);
  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);
  const addMyEquipmentButtonLabel = t(
    isMD ? 'FIND_MY_SERIAL_NUMBER' : 'ADD_EQUIP_FIND_SERIAL'
  );

  return (
    <div
      className={`d-flex w-100 justify-content-between ${styles['add-equipment__links']}`}
    >
      <AlloyButton
        buttonType="ghost"
        className="d-flex text-univers--bold align-items-center bg-none p-0"
        onClick={() =>
          modalCtx.addModalWithOptions(<FindMySerial />, {
            title: t('FIND_MY_SERIAL_NUMBER'),
            className: 'py-0'
          })
        }
      >
        {addMyEquipmentButtonLabel}
        <ChevronRightIcon />
      </AlloyButton>
      <AlloyButton
        buttonType="ghost"
        className="d-flex text-univers--bold align-items-center bg-none p-0"
        onClick={() =>
          modalCtx.addModalWithOptions(<FindMyModal type="model" />, {
            title: t('ADD_EQUIP_FIND_MODEL'),
            className: 'py-0'
          })
        }
      >
        {t('ADD_EQUIP_FIND_MODEL')}
        <ChevronRightIcon />
      </AlloyButton>
    </div>
  );
};

export default SearchSuggestionsActions;
