import PropTypes from 'prop-types';

const Conditional = ({ children, fallback = null, test }) => {
  return !!test ? children : fallback;
};

export default Conditional;

Conditional.propTypes = {
  fallback: PropTypes.node,
  test: PropTypes.bool
};
