import { useTranslation } from 'react-i18next';

const ToastFailureContent = () => {
  const [t] = useTranslation();
  const itemsAddedToCartFailureMessage = t('ITEM_ADD_ERROR');
  return (
    <div className="d-flex flex-column">
      <div className="u-color--DarkGray">{itemsAddedToCartFailureMessage}</div>
    </div>
  );
};

export default ToastFailureContent;
