import cx from 'classnames';
import { ContactPhoneIcon, Expander } from 'cat-ecommerce-alloy';
import DealerUserAddressLine from './DealerUserAddressLine';
import DealerUserExpanderTitle from './DealerUserExpanderTitle';
import { dealerCustomerPropTypes } from '../../../../declarations.propTypes';
import styles from './SearchResult.module.scss';

const DealerUser = ({
  address,
  city,
  clearFeedback,
  customerNumber,
  expanded,
  name,
  phone,
  zipcode
}) => {
  return (
    !!customerNumber && (
      <li className="list-group-item mb-0 border-start-0 border-end-0 pe-2 ps-0 py-3 text-univers u-color--DarkGray">
        <Expander
          expanded={expanded}
          id="dealerUserExpandable"
          title={
            <DealerUserExpanderTitle
              clearFeedback={clearFeedback}
              customerNumber={customerNumber}
              name={name}
            />
          }
          name="dealer-customer__expander-button"
        >
          <div
            data-testid="addressContainer"
            className={cx(
              'mb-3 mt-0',
              styles['search-results__details-indent']
            )}
          >
            <DealerUserAddressLine address={address?.line1} />
            <DealerUserAddressLine address={address?.line2} />
            <DealerUserAddressLine address={address?.line3} />
            <DealerUserAddressLine
              address={`${city ?? ''}${city && zipcode ? ' ' : ''}${
                zipcode ?? ''
              }`}
            />
          </div>

          {phone && (
            <div
              data-testid="phoneContainer"
              className={cx(styles['search-results__details-indent'])}
            >
              <a
                href={`tel:${phone}`}
                className={
                  'btn btn-sm btn-ghost d-inline-flex align-items-center ps-0 text-sm'
                }
                data-cs-mask
              >
                <ContactPhoneIcon className="me-2" height={16} width={16} />
                {phone}
              </a>
            </div>
          )}
        </Expander>
      </li>
    )
  );
};

export default DealerUser;

DealerUser.propTypes = {
  ...dealerCustomerPropTypes
};
