import PropTypes from 'prop-types';

export const commonItem = {
  default: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string
};
export const dealerAssociationPropTypes = PropTypes.shape({
  ...commonItem,
  customerNumber: PropTypes.arrayOf(PropTypes.shape(commonItem)),
  isCSPCustomer: PropTypes.bool
});
export const dealerAssociationsPropTypes = PropTypes.arrayOf(
  dealerAssociationPropTypes
);

export const dealerCustomerPropTypes = {
  address: PropTypes.shape({
    line1: PropTypes.string,
    line3: PropTypes.string,
    line2: PropTypes.string
  }),
  city: PropTypes.string,
  clearFeedback: PropTypes.func,
  customerNumber: PropTypes.string.isRequired,
  name: PropTypes.string,
  phone: PropTypes.string,
  zipcode: PropTypes.string
};

export const storeLocationPropTypes = PropTypes.shape({
  ...commonItem,
  endUseCode: PropTypes.arrayOf(PropTypes.shape(commonItem)),
  orderType: PropTypes.arrayOf(PropTypes.shape(commonItem))
});

export const storesLocationPropTypes = PropTypes.arrayOf(
  storeLocationPropTypes
);
