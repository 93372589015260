import { createPortal } from 'react-dom';
import { NOTIFICATION_ROOT } from '../../constants/targets';
import NotificationDisplay from './NotificationDisplay';

const Container = () => {
  const notificationPortalTarget = document.getElementById(NOTIFICATION_ROOT);
  return notificationPortalTarget
    ? createPortal(<NotificationDisplay />, notificationPortalTarget)
    : null;
};

export default Container;
