import PropTypes from 'prop-types';
import { FALLBACK_MOUNTAIN_IMAGE } from '@app/constants/commonConstants';
import styles from './ImageCell.module.scss';

const ImageCell = ({ item }) => {
  const { imageURL, name } = item;
  return (
    <img
      src={`${imageURL || FALLBACK_MOUNTAIN_IMAGE}?$pcc-pdp$`}
      width="96px"
      height="96px"
      alt={name}
      className={`${styles['table__image-size']}`}
    />
  );
};

ImageCell.propTypes = {
  item: PropTypes.shape({
    imageURL: PropTypes.string,
    name: PropTypes.string
  })
};

export default ImageCell;
