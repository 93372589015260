import { useCallback, useContext, useState } from 'react';

import { ModalContext } from 'cat-ecommerce-alloy';
import DealerLocatorModal from './DealerLocatorModal';
// TODO: Remove legacy and call the new one directly
import { showADP } from '../../services/legacyJSService';
import { useTranslation } from 'react-i18next';
import { getHttpInstance } from '@app/services/defaultHttpService';
import { TIMEOUT_EXTENDED } from '@app/constants/commonConstants';
import endpoints from '@app/constants/endpoints';

const useDealerLocator = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { addModalWithOptions } = useContext(ModalContext);
  const [t] = useTranslation();
  const DEFAULT_TITLE = t('FYD_SELECT_STORE');

  const invokeResetUser = async () => {
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    try {
      await http.get(endpoints.CSR_TABLE_RESET_CUSTOMER_SELECTION);
      sessionStorage.removeItem('isGuest');
    } catch (e) {
      console.error(e);
    }
  };

  const showDealerLocator = useCallback(
    ({
      adpSubmitPromise,
      description,
      focusSelector = 'input[name=dealerLocation]',
      fromADP,
      fromEspot = false,
      isRegistration = false,
      isRequired = false,
      onClose: onDealerLocatorClose,
      returnFocusSelector,
      showCancelImportButton,
      showShopAsGuestButton,
      subtitle,
      title = DEFAULT_TITLE,
      bobRedirect,
      bobUserId,
      reorderFunc
    } = {}) => {
      const onOpen = () => setIsOpen(true);
      const onClose = () => {
        if (bobRedirect) {
          invokeResetUser();
        }
        if (returnFocusSelector) {
          const elementToFocus =
            document.querySelectorAll(returnFocusSelector)[0];
          if (elementToFocus) {
            elementToFocus.focus();
          }
        }
        if (fromADP) {
          showADP();
        }
        if (onDealerLocatorClose) {
          onDealerLocatorClose();
        }
        setIsOpen(false);
      };
      addModalWithOptions(
        <DealerLocatorModal
          adpSubmitPromise={adpSubmitPromise}
          description={description}
          isRegistration={isRegistration}
          fromEspot={fromEspot}
          showCancelImportButton={showCancelImportButton}
          showShopAsGuestButton={showShopAsGuestButton}
          subtitle={subtitle}
          title={title}
          bobRedirect={bobRedirect}
          bobUserId={bobUserId}
          reorderFunc={reorderFunc}
        />,
        {
          focusSelector,
          isRequired,
          onClose,
          onOpen,
          title
        }
      );
    },
    [DEFAULT_TITLE, addModalWithOptions]
  );
  return { isOpen, showDealerLocator };
};

export default useDealerLocator;
