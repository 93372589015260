import { SCENE7_BASE_URL } from '@app/constants/commonConstants';

export const IMAGE_CELL_HEIGHT = 32;
export const IMAGE_CELL_WIDTH = 32;
export const ITEMS_PER_PAGE = 8;
export const MOBILE_ITEMS_PER_PAGE = 4;
export const SUBMIT_TIME = 'submitTime';
export const STATUS_NAME = 'statusName';
export const APPROVE_TIME = 'approveTime';
export const ORDER_EXPIRY_DATE = 'orderExpiryDate';
export const CONTROL_CENTER_DISPLAYNAME = 'Control Center';
export const RECENTLY_VIEWED_DEFAULT_QTY = '1';
export const CONTROL_CENTER_VALUE = 'cc';
export const RECENTLY_VIEWED_DEFAULT_QUANTITY = 1;
export const ZERO_PRICE = '0.0';
export const NOTIFICATION_FEATURE_FLAG = 'PCC_notificationWidgetFlag';
export const CUSTOMIZE_FEATURE_FLAG = 'PCC_customizeWidgetFlag';
export const NOTIFICATION_FILTERING_FLAG = 'PCC_notificationFilteringFlag';
export const ACCOUNT_NAVIGATION_FLAG = 'PCC_BlocksAccountNavigationFlag';
export const QUOTE_CREATION_DATE = 'quoteCreationDate';
export const UPDATED = 'updated';
export const ADD_TO_CART_ICON = {
  SHOW: 'show',
  HIDE: 'hide',
  UNAVAILABLE: 'unavailable'
};
export const RECENTLY_VIEWED_PRICE_AND_AVAILABILITY =
  'recentlyViewedPriceAndAvailability';
export const CC_INTRODUCTION_HIDE = 'CC_INTRODUCTION_HIDE';
export const LOGGED_USER_SUPPRESS_EMAIL_MARKETING_MODAL =
  'LOGGED_USER_SUPPRESS_EMAIL_MARKETING_MODAL';
export const RECENTLY_VIEWED_EVENT = 'CC PCC Recently Viewed';
export const CURRENT_CART_EVENT = 'CC PCC Current Cart';
export const ORDERS_TO_APPROVE_EVENT = 'Orders To Approve';
export const PENDING_QUOTE_EVENT = 'Pending Quotes';
export const RECENT_ORDERS_EVENT = 'My Recent Orders';
export const WIDGET_LOADER_SIZE = 'small';
export const FEATURES_AND_BENEFITS = [
  {
    src: `${SCENE7_BASE_URL}/CM20220909-efeb5-75a92?wid=48&hei=48&fmt=png-alpha`,
    alt: 'BirdsEyeView',
    title: 'CC_WELCOME_MODAL_EYEVIEW_TITLE',
    description: 'CC_WELCOME_MODAL_EYEVIEW_BODY'
  },
  {
    src: `${SCENE7_BASE_URL}/CM20220902-e2d52-25826?wid=48&hei=48&fmt=png-alpha`,
    alt: 'CustomizableDisplay',
    title: 'CC_WELCOME_MODAL_DISPLAY_TITLE',
    description: 'CC_WELCOME_MODAL_DISPLAY_BODY'
  },
  {
    src: `${SCENE7_BASE_URL}/CM20220902-30902-d743c?wid=48&hei=48&fmt=png-alpha`,
    alt: 'ConvenientOrderHistory',
    title: 'CC_WELCOME_MODAL_ORD_HISTORY_TITLE',
    description: 'CC_WELCOME_MODAL_ORD_HISTORY_BODY'
  },
  {
    src: `${SCENE7_BASE_URL}/CM20220902-10e02-fcb33?wid=48&hei=48&fmt=png-alpha`,
    alt: 'SavedLists',
    title: 'CC_WELCOME_MODAL_SAVED_LIST_TITLE',
    description: 'CC_WELCOME_MODAL_SAVED_LIST_BODY'
  },
  {
    src: `${SCENE7_BASE_URL}/CM20220902-cbecf-d811c?wid=48&hei=48&fmt=png-alpha`,
    alt: 'Notifications',
    title: 'CC_WELCOME_MODAL_NOTIFICATION_TITLE',
    description: 'CC_WELCOME_MODAL_NOTIFICATION_BODY'
  },
  {
    src: `${SCENE7_BASE_URL}/CM20220902-d1fc3-1e15f?wid=48&hei=48&fmt=png-alpha`,
    alt: 'QuickCartAccess',
    title: 'CC_WELCOME_MODAL_CART_ACCESS_TITLE',
    description: 'CC_WELCOME_MODAL_CART_ACCESS_BODY'
  }
];

export const CONTROL_CENTER_CTA_BUTTON_SIZE = 18;
export const NOTIFICATION_MODAL_GA_NAME = 'Notifications Modal';
export const NOTIFICATIONS_FILTER = 'Notifications Filter';
export const ALL_GA_TEXT = 'All';
export const FAILURE_GA_TEXT = 'failure';

export const SUPPORT_OPTIONS_WIDGET_ICON_SIZE = 16;
export const OMITTED_COLUMNS = [STATUS_NAME, APPROVE_TIME, ORDER_EXPIRY_DATE];
export const NULL_GA_TEXT = 'Null';
export const NO_RESULTS = 'No results';

export const ICON_SIZE = 16;
export const MOBILE_ICON_SIZE = 24;
export const NOTIFICATIONS = 'notifications';
export const REGIONAL_NUMBERS = [
  'direct',
  'phoneNumber',
  'tollFreeNumber',
  'phone'
];
