import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHttp } from '@app/hooks';
import { getStoreInfo } from '@app/services/storeInfoService';
import { replaceTokensInUrl } from '@app/utils';
import endpoints from '@app/constants/endpoints';

const useZipCodeService = () => {
  const countryData = useSelector(state => state.dealer.countryInfo);
  const { invoke } = useHttp();
  const { storeId } = getStoreInfo();

  const getCountry = useCallback(
    countryName => {
      return countryData.find(({ displayName }) => displayName === countryName);
    },
    [countryData]
  );

  const getState = useCallback(({ states, code: countryCode }, stateCode) => {
    return states.find(
      ({ code }) => stateCode === code || `${countryCode}-${stateCode}` === code
    );
  }, []);

  const getCityState = useCallback(
    async ({ countryName, zipCode }) => {
      const applicableCountries = ['US', 'CA', 'BR'];
      const countryObj = getCountry(countryName);
      if (
        countryName &&
        zipCode &&
        applicableCountries.includes(countryObj.code)
      ) {
        const url = replaceTokensInUrl(
          endpoints.CITY_STATE_FROM_ZIPCODE,
          storeId,
          zipCode,
          countryObj.code
        );
        const data = await invoke({ url });
        if (data && data.city && data.state) {
          const state = getState(countryObj, data.state);
          return { city: data.city, state: state.displayName };
        }
      }
      return { city: '', state: '' };
    },
    [invoke, getCountry, storeId, getState]
  );

  return { getCityState };
};

export default useZipCodeService;
