import PropTypes from 'prop-types';
import Espot from '@app/components/common/Espot/Espot';
import CategoriesGrid from '@app/components/common/CategoriesGrid';
import {
  PAGE_BREAKPOINTS,
  ESPOT_VARIANTS
} from '@app/constants/commonConstants';
import { useBreakpoint } from '@app/hooks';
import {
  CLP_PAGE_ESPOT_3_MIN_TILES,
  DEFAULT_CATEGORIES_SIZE
} from '../constants';
import { getCategoriesPagesCols } from '@app/components/common/CategoriesGrid/utils';

const TopCategoryGrid = ({
  espotElement,
  categories = [],
  isEspotLoading,
  isLoading,
  skeletonSize = DEFAULT_CATEGORIES_SIZE
}) => {
  const isXL = useBreakpoint(PAGE_BREAKPOINTS.XL);
  const isLG = useBreakpoint(PAGE_BREAKPOINTS.LG);
  const categoriesLength = isLoading ? skeletonSize : categories.length;

  const renderAdditionalRowContent = rowIndex => {
    const cols = getCategoriesPagesCols(isXL, isLG);
    const espotIndex = Math.ceil(CLP_PAGE_ESPOT_3_MIN_TILES / cols) - 1;

    const isEspotVisible =
      (cols === 2 ? categoriesLength > 6 : categoriesLength > 4) &&
      espotIndex === rowIndex;

    if (espotElement && isEspotVisible) {
      return (
        <Espot
          html={espotElement}
          isLoading={isEspotLoading}
          variant={ESPOT_VARIANTS.LARGE}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <CategoriesGrid
      className="mb-4 pb-2 mb-md-5 pb-md-0"
      isLoading={isLoading}
      categories={categories}
      skeletonSize={skeletonSize}
      renderAdditionalRowContent={renderAdditionalRowContent}
      isSkeletonCardLarge
    />
  );
};

TopCategoryGrid.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      imageUrl: PropTypes.string,
      redirectUrl: PropTypes.string
    })
  ),
  espotElement: PropTypes.node,
  isEspotLoading: PropTypes.bool,
  skeletonSize: PropTypes.number,
  isLoading: PropTypes.bool
};

export default TopCategoryGrid;
