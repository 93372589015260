import addDays from 'date-fns/addDays';
import { getCookie, setCookie } from '@app/utils';
import {
  isAndroidDevice,
  isCategoryPage,
  isHomepage,
  isIOSDevice,
  isMLP,
  isPDP,
  isSearchPage
} from '@app/utils/browserUtils';
import {
  COOKIE_EXPIRATION_DAYS,
  SKIP_MOBILE_APP_ADVERT_BANNER_COOKIE
} from './constants';
import { DEVICE_TYPE } from '../common/analytics/analyticsConstants';

/**
 * Determines if the app banner should be rendered
 */
export const shouldDisplayBanner = () => {
  // Validation for cookies values.
  const skipBannerCookie = getCookie(SKIP_MOBILE_APP_ADVERT_BANNER_COOKIE);

  // Validation for location page
  const isValidPage =
    isHomepage() || isMLP() || isPDP() || isSearchPage() || isCategoryPage();

  return isValidPage && !skipBannerCookie;
};

/**
 * Sets cookie to skip mobile app advert banner
 */
export const setCookieToSkipAppBanner = () => {
  const expiresDate = addDays(new Date(), COOKIE_EXPIRATION_DAYS).toUTCString();
  setCookie(SKIP_MOBILE_APP_ADVERT_BANNER_COOKIE, true, expiresDate);
};

export const getCurrentDeviceForAnalytics = () => {
  if (isAndroidDevice()) {
    return DEVICE_TYPE.ANDROID;
  }

  if (isIOSDevice()) {
    return DEVICE_TYPE.IOS;
  }

  return '';
};
