/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  UNKNOWN_ERROR_MESSAGE,
  UNKNOWN_ERROR_TITLE
} from '../../../constants/commonConstants';
import RenderException from './RenderException';
import ExceptionFeedback from './ExceptionFeedback';
import { isDev, replaceTokensInString } from '../../../utils';

class ExceptionBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    if (error instanceof RenderException) {
      return { hasError: true, error };
    } else {
      return {
        hasError: true
      };
    }
  }

  componentDidCatch(error, errorInfo) {
    if (isDev()) {
      // TODO: Replace this with a logging framework
      // eslint-disable-next-line no-console
      console.log(error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      const {
        code,
        message = 'UNKNOWN_ERROR_MESSAGE',
        title = 'UNKNOWN_ERROR_TITLE'
      } = this.state.error ?? {};

      const codeDisplay = code
        ? ` (${replaceTokensInString(this.props.t('MLP_ERROR4'), code)})`
        : '';
      return (
        <ExceptionFeedback
          className="py-3 bg-white"
          title={this.props.t(title, UNKNOWN_ERROR_TITLE)}
          showContact
        >
          <p>{`${this.props.t(
            message,
            UNKNOWN_ERROR_MESSAGE
          )}${codeDisplay}`}</p>
        </ExceptionFeedback>
      );
    }

    return this.props.children;
  }
}

ExceptionBoundary.propTypes = {
  children: PropTypes.node,
  t: PropTypes.func
};

export default withTranslation()(ExceptionBoundary);
