import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, SearchInput } from 'cat-ecommerce-alloy';
import Field from '../../../../common/form/Field';

const CustomerNameNumberInput = ({ expanded }) => {
  const [t] = useTranslation();
  const as = expanded ? Input : SearchInput;

  return (
    <Field
      as={as}
      className={`w-100 mb-0`}
      hideErrorMessage={true}
      id="dealer-user_customer-number"
      label={t('CUST_NAME_NUMBER_COMBINED_SPACE')}
      name="customerNumber"
      showRequired={!expanded}
      showOptional={expanded}
      type="text"
    />
  );
};

export default CustomerNameNumberInput;

CustomerNameNumberInput.propTypes = {
  expanded: PropTypes.bool.isRequired
};
