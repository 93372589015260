import PropTypes from 'prop-types';
import cx from 'classnames';
import { Radio } from 'cat-ecommerce-alloy';
import AddressLabelContent from '../../../../../common/address/AddressLabelContent';
import Field from '../../../../../common/form/Field';
import { useSystemFeedback } from '@app/hooks';
import {
  ERROR_DOMAIN,
  ERROR_PATH
} from '../../../../../../constants/errorConstants';
import styles from '../DealerLocation.module.scss';

const DealerLocationExpanderTitle = ({
  address,
  city,
  dealerName,
  id,
  name,
  state,
  uniqueID,
  zipcode
}) => {
  const { clearError } = useSystemFeedback();
  const handleOnChange = () => {
    clearError(ERROR_DOMAIN.DEALER_LOCATOR, ERROR_PATH.LOCATIONS);
  };
  const addressJsx = (
    <AddressLabelContent
      name={name}
      street={address}
      city={city}
      state={state}
      withoutBreaks={true}
      zipCode={zipcode}
    />
  );
  return (
    <div
      data-testid="radio-wrapper"
      className={`pe-2 d-flex flex-grow-1 flex-column text-md text-start`}
    >
      <Field
        aria-label={name}
        as={Radio}
        className={`w-100 ${styles['store-location__radio']}`}
        id={`storeLocation_${uniqueID}`}
        label={
          <b className="exclude-global text-md text-univers text-start">
            {dealerName}
          </b>
        }
        name="storeLocation"
        onChange={handleOnChange}
        type="radio"
        value={id}
      />
      <div
        className={cx(styles['dealer-location__details-indent'], 'text-start')}
      >
        {addressJsx}
      </div>
    </div>
  );
};

export default DealerLocationExpanderTitle;

DealerLocationExpanderTitle.propTypes = {
  address: PropTypes.string,
  city: PropTypes.string,
  dealerName: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  state: PropTypes.string,
  uniqueID: PropTypes.string,
  zipcode: PropTypes.string
};
