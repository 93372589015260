import { PrivateIcon, SharedIcon, StoreIcon } from 'cat-ecommerce-alloy';

export const params = {
  SAVED_LIST_ID: 'requisitionListId',
  SAVED_LIST_NAME: 'requisitionListName'
};

export const savedListStatusConst = {
  PRIVATE: 'Y',
  PUBLIC: 'Z',
  DEALER_LIST: 'DFL'
};
export const SAVE_LIST_DETAIL_TYPE = {
  Y: {
    icon: PrivateIcon,
    translation: 'REQUISTIONLIST_INFO_DROPDOWN_PRIVATE'
  },
  Z: {
    icon: SharedIcon,
    translation: 'REQUISTIONLIST_INFO_DROPDOWN_SHARED'
  },
  DFL: { icon: StoreIcon, translation: 'Footer_Dealer' }
};

export const defaultRowValues = {
  quantity: 1,
  itemNumber: ''
};

export const initialValues = {
  items: [defaultRowValues, defaultRowValues]
};

export const MAX_QUANTITY = 99999;
export const MAX_ROWS = 180;

export const ORDER_ITEM_ID = 'orderItemId';

export const SAVED_LIST_DETAILS_GA = 'PCC SAVED LIST DETAILS';
export const DELAY_UPDATE_QUANTITY = 1500;

export const CATSAS_SAVED_LIST_eSpot_1 = 'CATSAS_SAVED_LIST_eSpot-1';
export const CATSAS_SAVED_LIST_eSpot_2 = 'CATSAS_SAVED_LIST_eSpot-2';
