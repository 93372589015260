import _ from 'lodash';
import { notificationFilterChanged } from '@app/store/notificationHeader/action';
import { NOTIFICATION_ORDER_STATUS } from './constants';

export const getFormattedDate = date => {
  const dateObj = new Date(date);
  const month =
    dateObj.getMonth() > 8
      ? dateObj.getMonth() + 1
      : '0' + (dateObj.getMonth() + 1);
  const day =
    dateObj.getDate() > 9 ? dateObj.getDate() : '0' + dateObj.getDate();
  const year = String(dateObj.getFullYear()).substring(2);
  return `${month}-${day}-${year}`;
};

export const getRedirectionLink = (
  dynamicLinks,
  orderId,
  orderNumber,
  orderQuoteDetailLink
) => {
  if (orderQuoteDetailLink?.includes('PendingOrderDetails')) {
    return dynamicLinks.PENDING_ORDER_DETAILS.getUrl({ orderId });
  } else if (orderQuoteDetailLink?.includes('AjaxLogonForm')) {
    const isOderIdExistInUrl = orderQuoteDetailLink
      ?.split('orderId=')
      ?.pop()
      ?.split('&')
      ?.shift();
    return dynamicLinks.ORDER_DETAIL.getUrl({
      orderId: !!isOderIdExistInUrl ? isOderIdExistInUrl : orderId,
      dbsOrderId: orderNumber
        ? orderNumber
        : orderQuoteDetailLink?.split('dbsOrderId=')?.pop()?.split('&')?.[0]
    });
  }
  return orderQuoteDetailLink;
};

export const removeFullStop = translation_text => {
  if (translation_text.endsWith('.')) {
    return translation_text.slice(0, -1);
  } else {
    return translation_text;
  }
};

export const view_order_cta = ['DL', 'RP', 'PA', 'PU'];

export const changeOrderStatus = (
  changedStatus,
  setListOfOrderStatus,
  notificationFilter,
  setValue,
  dispatch
) => {
  setListOfOrderStatus(changedStatus);
  setValue('orderStatus', changedStatus, true);
  setTimeout(() => {
    dispatch(
      notificationFilterChanged({
        ...notificationFilter,
        orderStatus: changedStatus
      })
    );
  }, 1000);
};

const orderStatusFilter = (o, orderStatus, dependentCheck) => {
  const isOrderQueue = !!o?.ordersQueueStatus && dependentCheck?.orderRelease;
  const isOrderStatus = orderStatus.find(
    status => status.orderStatus === o?.orderStatus
  );
  let checkStatus = isOrderStatus;
  if (dependentCheck?.isToday) {
    checkStatus =
      getFormattedDate(new Date()) === getFormattedDate(o?.quoteExpireDays);
  } else if (isOrderQueue) {
    checkStatus = !dependentCheck?.orderPlaced && o?.orderStatus !== 'QX';
  } else if (dependentCheck?.orderPlaced && isOrderStatus) {
    checkStatus = dependentCheck?.orderPlaced && !o?.ordersQueueStatus;
  }
  return checkStatus;
};

export const orderAccountFilterHelper = (notificationApiData, orderStatus) => {
  const orderRelease = orderStatus.find(status => status.orderStatus === 'OR');
  const isToday = orderStatus.find(status => status.isToday);
  const orderPlaced = orderStatus.find(status => status.orderPlaced);

  const filterData = _.filter(notificationApiData, row =>
    orderStatusFilter(row, orderStatus, { orderRelease, isToday, orderPlaced })
  );
  return filterData;
};

export const requestCreation = (value, t, errors) => {
  const request = {};
  Object.keys(value).forEach(key => {
    if (
      !(
        value[key]?.value === t('ALL_ACCOUNTS') ||
        value[key]?.value === 'ALL_STATUSES' ||
        !value[key]
      )
    ) {
      if (errors?.[key]) {
        request[key] = '';
      } else {
        request[key] = value[key];
      }
    }
  });
  return request;
};

export const trimLeadingZero = str => _.trimStart(str, '0');

export const fireFormSubmittedEventWithAccount = (
  values,
  formStatus,
  fireFormSubmittedEvent
) => {
  const eventOptions = {
    pagePath: document.location.pathname,
    formLocation: 'Filter by account – Notification Preferences'
  };
  fireFormSubmittedEvent({
    ...eventOptions,
    formName: 'Notification Preferences Drawer - By Account',
    formContent: `Account selected : ${values?.account['label']}`,
    formContent2: '',
    formStatus: formStatus,
    formFieldCausingError:
      formStatus === 'fail' ? 'Invalid field(s) causing error' : ''
  });
};

export const fireFormSubmittedEventWithOrderNumber = (
  values,
  formStatus,
  fireFormSubmittedEvent
) => {
  const eventOptions = {
    pagePath: document.location.pathname,
    formLocation: 'Notification Preferences'
  };
  fireFormSubmittedEvent({
    ...eventOptions,
    formName: 'Notification Preferences Drawer Filter',
    formContent: values.orderNumberOrReference
      ? `Order/Quote reference Number: ${values.orderNumberOrReference}`
      : 'Order/Quote reference Number',
    formContent2: values.poNumber
      ? `Po Number: ${values.poNumber}`
      : 'Po Number',
    formStatus: formStatus,
    formFieldCausingError:
      formStatus === 'fail' ? 'Invalid field(s) causing error' : ''
  });
};

export const fireFormSubmittedEventWithOrderStatus = (
  values,
  formStatus,
  fireFormSubmittedEvent
) => {
  const eventOptions = {
    pagePath: document.location.pathname,
    formLocation: 'Filter by order status – Notification Preferences'
  };
  const checkedOrderStatus = status => {
    const isOrderStatusChecked = values?.orderStatus.find(
      item => item.orderStatus === status
    );
    return isOrderStatusChecked ? 'Y' : 'N';
  };

  fireFormSubmittedEvent({
    ...eventOptions,
    formName: 'Notification Preferences Drawer - By Order Status',
    formContent: `Order Processing: OPCS-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.ORDER_PROCESSING
    )}, CAN-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.CANCELLED
    )}, OPRB-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.ORDER_PROBLEM
    )}, OH-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.ON_HOLD
    )}, QONP-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.QUEUED_ORDER_NOT_PROCESSING
    )}, QFP-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.QUEUED_FOR_PROCESSING
    )} | Pickup: RFP-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.READY_FOR_PICKUP
    )}, PRFP-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.PARTIALLY_READY_FOR_PICKUP
    )}, PU-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.PICKED_UP
    )} | Delivery: PS-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.PARTIALLY_SHIPPED
    )}, FS-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.FULLY_SHIPPED
    )}, FD-${checkedOrderStatus(NOTIFICATION_ORDER_STATUS.FULLY_DELIVERD)}`,
    formContent2: `Quote Reminder: ETQ-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.EXPIRED_TODAY
    )}, EQ-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.EXPIRED_QUOTE
    )} | Order Approval : EA-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.EXPIRED_APPROVAL
    )}, SFA-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.SUBMIT_FOR_APPROVAL
    )}, OP-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.ORDER_PLACED
    )}, OA-${checkedOrderStatus(
      NOTIFICATION_ORDER_STATUS.ORDER_APPROVED
    )}, OR-${checkedOrderStatus(NOTIFICATION_ORDER_STATUS.ORDER_REJECTED)}`,
    formStatus: formStatus,
    formFieldCausingError:
      formStatus === 'fail' ? 'Invalid field(s) causing error' : ''
  });
};
