export const COOKIE_EQUIPMENT_SERIAL_NUMBER = 'equipmentSerialNumber';
export const CLEAR_SELECTION = 'CLEAR_SELECTION';
export const EDIT = 'MA_EDIT';
export const CHANGE_EQUIPMENT = 'CHANGE_EQUIPMENT';
export const SHOP_PARTS = 'SHOP_PARTS';
export const SHOP_PM_KITS = 'SHOP_PM_KITS';
export const CAT_PARTS_DIAGRAM = 'CAT_PARTS_DIAGRAM';
export const ALP_REGISTER_FOR_WARRANTY = 'ALP_REGISTER_FOR_WARRANTY';
export const SINGLE_SHIPMENT_PAY_CHANGE = 'SINGLE_SHIPMENT_PAY_CHANGE';
export const QUICK_CLEAR = 'QUICK_CLEAR';
export const FULL_SIS_LABEL = 'FULL_SIS_LABEL';
export const SIS_PART_LOOKUP = 'SIS_PART_LOOKUP';
export const SIS = 'SIS';
export const CUST_EDIT = 'MA_EDIT';
export const CLEAR_SELECTION_DESELECT = 'CLEAR_SELECTION_DESELECT';
export const SELECT = 'SELECT';
export const SELECT_EQUIPMENT = 'SELECT_EQUIPMENT';
export const ENGINE_WARRANTY = 'ENGINE_WARRANTY';
export const MLP_MEQ_KABOB1 = 'MLP_MEQ_KABOB1';
export const VIEW_DASHBOARD = 'VIEW_DASHBOARD';
export const DELETE = 'DELETE';
export const SERVICE_RECOMMENDATION = 'SERVICE_RECOMMENDATION';
export const ALP_MAINTENANCE_REPAIRS = 'ALP_MAINTENANCE_REPAIRS';
export const VIEW_SERVICE_INSIGHTS = 'VIEW_SERVICE_INSIGHTS';
