import axios from 'axios';
import axiosRetry from 'axios-retry';
import {
  TIMEOUT_DEFAULT,
  REST_CONTEXT_PATH,
  X_CAT_SESSION_ID_COOKIE_NAME
} from '../constants/commonConstants';
import { replaceUrlContextForPreview } from '../utils/urlUtils';
import { isEmpty, pccSessionId } from '@app/utils';

const DEFAULT_RETRIES = 0;
const DEFAULT_RETRY_DELAY = axiosRetry.exponentialDelay;

export const getHttpInstance = (
  timeout = TIMEOUT_DEFAULT,
  retries = DEFAULT_RETRIES,
  retryDelay = DEFAULT_RETRY_DELAY,
  removeSessionId = false
) => {
  let instance = axios.create({ timeout });
  instance.interceptors.request.use(function (config) {
    config.url = replaceUrlContextForPreview(config.url);

    //auth token not required for BFF or GET method
    if (
      config.method?.toLowerCase() !== 'get' &&
      config.url.includes(REST_CONTEXT_PATH)
    ) {
      let authToken = document.getElementById('WC_PCC_CSRF_authToken')?.value;
      if (isEmpty(authToken)) {
        authToken = window?.catReact?.authToken;
      }
      config.headers['WCAuthToken'] = authToken;
    }

    let pccSessionIdCookie = pccSessionId.get();
    if (!pccSessionIdCookie) {
      pccSessionIdCookie = pccSessionId.createNewId();
    }
    if (!removeSessionId) {
      config.headers[X_CAT_SESSION_ID_COOKIE_NAME] = pccSessionIdCookie;
    }

    return config;
  });
  axiosRetry(instance, {
    retries,
    retryCondition: error => {
      return (
        axiosRetry.isNetworkOrIdempotentRequestError(error) ||
        error.code === 'ECONNABORTED'
      );
    },
    retryDelay
  });
  return instance;
};

const instance = getHttpInstance();
export default instance;
