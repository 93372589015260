import { useSelector } from 'react-redux';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import { CatIconMessageMultiple } from '@blocks-react/components/Icons/MessageMultiple';
import useHelpCenterLink from '../csr-landing/hooks/useHelpCenterLink';
import { isRTLLang } from '@app/utils';
import styles from './HelpcenterChatWidget.module.scss';

const HelpcenterChatWidget = () => {
  const { getHelpCenterURL } = useHelpCenterLink();
  const helpCenterEnabled = useSelector(
    s => s.featureFlag?.PCCCustomerSupportHelpCenter
  );
  const isBuyOnBehalf = useSelector(s => s.common?.isBuyOnBehalf);
  const isCatCSR = useSelector(s => s.common?.isCatCSR);
  const PCCSalesForceChat = useSelector(s => s.featureFlag?.PCCSalesForceChat);
  const isCatCorp = useSelector(s => s.common?.isCatCorp);
  const langId = useSelector(s => s.common.langId);
  const isRtl = isRTLLang(langId);
  const isCSRAgent = isBuyOnBehalf || isCatCSR;
  const linkToHelpChat = `${getHelpCenterURL()}&chatopen=true`;
  const showWidget =
    (isCatCorp || helpCenterEnabled) && !PCCSalesForceChat && !isCSRAgent;

  if (!showWidget) {
    return null;
  }

  const rightToleftLangChatbox = `position-fixed top-0 start-0 ${styles['helpcenter-chat-widget--container']}`;
  const leftTorightLangChatbox = `position-fixed top-0 end-0 ${styles['helpcenter-chat-widget--container']}`;

  return (
    <section
      className={isRtl ? rightToleftLangChatbox : leftTorightLangChatbox}
    >
      <CatButton
        slot="trigger"
        data-testid="chatWidgetButton"
        href={linkToHelpChat}
        target="_blank"
      >
        <div className="py-1">
          <CatIconMessageMultiple size="lg" />
        </div>
      </CatButton>
    </section>
  );
};

export default HelpcenterChatWidget;
