import { AFFILIATION_DEALER, STATUS } from '@app/constants/commonConstants';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useGetDealerCustomerData from './useGetDealerCustomerData';
import { getCookie, isEmpty } from '@app/utils/index';
import { useForm } from 'react-hook-form';
import {
  CUSTOMER_NUMBER_DROPDOWN_NAME,
  CUSTOMER_NUMBER_RADIO_NAME,
  ADDRESS_INPUT_NAME,
  CITY_INPUT_NAME,
  CUSTOMER_NUMBER_INPUT_NAME,
  ZIP_CODE_INPUT_NAME,
  DEALER_DROPDOWN_NAME,
  END_USE_CODE_DROPDOWN_NAME,
  ORDER_TYPE_RADIO_NAME,
  STORE_LOCATION_DROPDOWN_NAME,
  ERRORS,
  TERMS_AND_CONDITIONS_CHECKBOX_NAME,
  SKIP_THIS_STEP_CHECKBOX_NAME
} from '../constants';
import { useDrawer, useModal } from '@cat-ecom/pcc-components';
import useSelectDealer from '@app/components/associated-dealer/hooks/useSelectDealer/useSelectDealer';
import useGetDealerCustomerStores from './useGetDealerCustomerStores';
import { removeSessionStorageCartSharingData } from '@app/components/pages/checkout/shopping-cart/ShoppingCartPage/utils';
import { applyShareCartLink } from '../utils';

const useAssociatedDealer = ({
  drawer,
  isLoginAction = false,
  buyOnBehalfParameters
} = {}) => {
  // * All these variables are for dealer user
  const [t] = useTranslation();
  const userAffiliation = useSelector(s => s.common?.userAffiliation);
  const isDealerUser = userAffiliation === AFFILIATION_DEALER;
  const dealerName = useSelector(s => s.common.selectedDealer);
  const dealerId = useSelector(s => s.common.dealerUserStoreId);
  const dealerAssociationStatus = useSelector(s => s.dealerAssociation?.status);
  const isCatCSR = useSelector(s => s.common?.isCatCSR);
  const PCC_CustomerSupportTransactAsGuestFromCSRCartList =
    useSelector(
      state =>
        state?.featureFlag?.PCC_CustomerSupportTransactAsGuestFromCSRCartList
    ) || false;
  const dealerAssociationCallError =
    dealerAssociationStatus === STATUS.REJECTED;
  const dealerAssociationCallLoading =
    dealerAssociationStatus === STATUS.PENDING;

  const dealerSystemDownCookie =
    !isEmpty(getCookie('dealerSystemDown')) &&
    getCookie('dealerSystemDown') === '1';

  const selectedDealer = useMemo(
    () => ({ label: dealerName, value: dealerId }),
    [dealerName, dealerId]
  );
  const [selectedCustomerNumber, setSelectedCustomerNumber] = useState();
  const [selectCustomerFormTitle, setSelectCustomerFormTitle] = useState();
  const [searchError, setSearchError] = useState('');
  const [searchResultsError, setSearchResultsError] = useState('');
  const { invoke: callSelectDealer, loading: isSelectDealerLoading } =
    useSelectDealer();
  // * Retrieves a list of customer nubmers given some data
  const {
    data: customerNumberData,
    status: customerNumberCallStatus,
    invoke: invokeCustomerNumberCall
  } = useGetDealerCustomerData();
  const customerNumberList = customerNumberData?.dealerCustomer;
  const customerNumberCallIsPending =
    customerNumberCallStatus === STATUS.PENDING;

  // * Retrieves a list of store locations given a dealer and a customer number
  const {
    data: storesData,
    status: storesCallStatus,
    invoke: invokeStoresCall
  } = useGetDealerCustomerStores();
  const storesCallIsPending = storesCallStatus === STATUS.PENDING;
  const storesCallIsRejected = storesCallStatus === STATUS.REJECTED;

  const showTryAgainSystemFeedback =
    dealerAssociationCallError ||
    storesCallIsRejected ||
    dealerSystemDownCookie;

  // * FORMS
  const selectCustomerFormMethods = useForm({
    defaultValues: {
      [DEALER_DROPDOWN_NAME]: '',
      [CUSTOMER_NUMBER_DROPDOWN_NAME]: '',
      [STORE_LOCATION_DROPDOWN_NAME]: '',
      [ORDER_TYPE_RADIO_NAME]: '',
      [END_USE_CODE_DROPDOWN_NAME]: '',
      [TERMS_AND_CONDITIONS_CHECKBOX_NAME]: false,
      [SKIP_THIS_STEP_CHECKBOX_NAME]: false
    }
  });

  const searchCustomerFormMethods = useForm({
    defaultValues: {
      [CUSTOMER_NUMBER_INPUT_NAME]: '',
      [ADDRESS_INPUT_NAME]: '',
      [CITY_INPUT_NAME]: '',
      [ZIP_CODE_INPUT_NAME]: ''
    }
  });

  const searchResultsFormMethods = useForm({
    defaultValues: {
      [CUSTOMER_NUMBER_RADIO_NAME]: ''
    }
  });

  // * MODALS
  const selectCustomerModal = useModal({
    isRequired: true,
    backButton: false,
    size: 'md'
  });

  const searchResultsModal = useModal({
    isRequired: true,
    backButton: false,
    size: 'md'
  });

  // * DRAWERS
  const selectCustomerDrawer = useDrawer({
    id: 'associated-dealer-select-customer-drawer',
    size: 'lg'
  });

  const searchResultsDrawer = useDrawer({
    id: 'associated-dealer-search-results-drawer',
    size: 'lg'
  });

  // * handlers for opening modals/drawers
  const handleOpenSearchResults = () => {
    if (isLoginAction && !searchResultsModal.modalProps.isActive) {
      searchResultsModal.initiatorProps.onClick();
    } else if (!searchResultsDrawer.isDrawerOpen) {
      searchResultsDrawer.openDrawer();
    }
  };

  const handleOpenSelectCustomer = () => {
    if (isLoginAction && !selectCustomerModal.modalProps.isActive) {
      selectCustomerModal.initiatorProps.onClick();
    } else if (!selectCustomerDrawer.isDrawerOpen) {
      selectCustomerDrawer.openDrawer();
    }
  };

  // * handlers for closing modals/drawers
  const handleCloseSearchResults = () => {
    if (isLoginAction && searchResultsModal.modalProps.isActive) {
      searchResultsModal.modalProps.onBlModalClose();
    } else if (searchResultsDrawer.isDrawerOpen) {
      searchResultsDrawer.handleClose();
    }
  };

  const handleCloseSelectCustomer = () => {
    if (isLoginAction && selectCustomerModal.modalProps.isActive) {
      selectCustomerModal.modalProps.onBlModalClose();
    } else if (selectCustomerDrawer.isDrawerOpen) {
      selectCustomerDrawer.handleClose();
    }
  };

  // * changes close handler so it closes all drawers when clicking X icon
  const closeAllDrawers = () => {
    if (drawer.isDrawerOpen) {
      drawer.closeDrawer();
    }
    handleCloseSearchResults();
    handleCloseSelectCustomer();
  };

  selectCustomerDrawer.drawerProps.onBlDrawerClose = event => {
    event.stopPropagation();
    closeAllDrawers();
  };

  searchResultsDrawer.drawerProps.onBlDrawerClose = event => {
    event.stopPropagation();
    closeAllDrawers();
  };

  // * HANDLERS
  const normalizeCustomerNumber = customerNumberItem => {
    const name = customerNumberItem?.name;
    const customerNumber = customerNumberItem?.customerNumber;
    return {
      label: `${customerNumber} - ${name}`,
      value: customerNumber
    };
  };

  const normalizeValue = value => {
    return value ?? null;
  };

  const handleSelectCustomerSubmit = data => {
    // When isDealerUser is true, the label we show to the user is  userName/userNumber
    // in that flow we receive an additional field "name" with only the name of the user
    const formattedValues = {
      isLoginAction,
      dealer: normalizeValue(data?.dealer?.value),
      dcnNumberName: isDealerUser
        ? normalizeValue(
            data.customerNumber?.label.replace(/\s*-\s*/, '-').trim()
          )
        : normalizeValue(data.customerNumber?.label),
      dcnNumberValue: normalizeValue(data.customerNumber?.value),
      endUseCode: normalizeValue(data.endUseCode?.value),
      orderType: normalizeValue(data?.orderType?.value),
      storeLocationId: normalizeValue(data?.storeLocation?.value),
      skipThisStep: normalizeValue(data?.[SKIP_THIS_STEP_CHECKBOX_NAME])
    };

    if (isCatCSR && PCC_CustomerSupportTransactAsGuestFromCSRCartList) {
      callSelectDealer(
        formattedValues,
        buyOnBehalfParameters?.bobRedirect,
        buyOnBehalfParameters?.adpSubmitPromise,
        buyOnBehalfParameters?.bobUserId,
        buyOnBehalfParameters?.reorderFunc
      );
    } else {
      callSelectDealer(formattedValues);
    }

    applyShareCartLink({
      storeId: formattedValues.dealer,
      storeLocationId: formattedValues.storeLocationId
    });
    removeSessionStorageCartSharingData();
  };

  const handleSearchCustomerSubmit = data => {
    if (searchError) {
      setSearchError('');
    }
    const isEveryFieldEmpty = () => {
      const fieldsArray = [
        ADDRESS_INPUT_NAME,
        CITY_INPUT_NAME,
        CUSTOMER_NUMBER_INPUT_NAME,
        ZIP_CODE_INPUT_NAME
      ];
      return fieldsArray.every(field => data?.[field] === '');
    };
    if (isEveryFieldEmpty()) {
      setSearchError(ERRORS.NO_SEARCH_VALUES);
    } else {
      invokeCustomerNumberCall({
        address: data?.[ADDRESS_INPUT_NAME],
        city: data?.[CITY_INPUT_NAME],
        customerName: data?.[CUSTOMER_NUMBER_INPUT_NAME],
        customerNumber: data?.[CUSTOMER_NUMBER_INPUT_NAME],
        zipcode: data?.[ZIP_CODE_INPUT_NAME]
      });
    }
  };

  const handleSearchResultsSubmit = data => {
    if (searchResultsError) {
      setSearchResultsError('');
    }
    const customerNumberFieldValue = data[CUSTOMER_NUMBER_RADIO_NAME];
    const customerNumberObj = customerNumberList?.find(
      result => result.customerNumber === customerNumberFieldValue
    );
    const selectedCustomerNumber = normalizeCustomerNumber(customerNumberObj);
    setSelectedCustomerNumber(selectedCustomerNumber);
    invokeStoresCall({
      dealer: selectedDealer.value,
      customerNumber: selectedCustomerNumber.value
    });
  };

  const handleClearSearch = () => {
    handleCloseSearchResults();
    handleCloseSelectCustomer();

    selectCustomerFormMethods.reset({
      [DEALER_DROPDOWN_NAME]: dealerName,
      [CUSTOMER_NUMBER_DROPDOWN_NAME]: '',
      [STORE_LOCATION_DROPDOWN_NAME]: '',
      [ORDER_TYPE_RADIO_NAME]: '',
      [END_USE_CODE_DROPDOWN_NAME]: ''
    });
    searchCustomerFormMethods.reset({
      [DEALER_DROPDOWN_NAME]: dealerName,
      [CUSTOMER_NUMBER_INPUT_NAME]: '',
      [ADDRESS_INPUT_NAME]: '',
      [CITY_INPUT_NAME]: '',
      [ZIP_CODE_INPUT_NAME]: ''
    });

    setSelectedCustomerNumber(null);
  };

  // * PROPS
  const searchCustomerProps = {
    formMethods: searchCustomerFormMethods,
    onSubmit: handleSearchCustomerSubmit,
    dealer: selectedDealer,
    loading: storesCallIsPending || customerNumberCallIsPending,
    error: searchError
  };

  const searchResultsProps = {
    drawer: searchResultsDrawer,
    modal: searchResultsModal,
    formMethods: searchResultsFormMethods,
    onSubmit: handleSearchResultsSubmit,
    searchResults: customerNumberList,
    loading: storesCallIsPending,
    error: searchResultsError
  };

  const selectCustomerProps = isDealerUser
    ? {
        drawer: selectCustomerDrawer,
        modal: selectCustomerModal,
        formTitle: selectCustomerFormTitle,
        formMethods: selectCustomerFormMethods,
        onSubmit: handleSelectCustomerSubmit,
        isSelectDealerLoading: isSelectDealerLoading,
        onClearSearch: handleClearSearch,
        dealer: selectedDealer,
        customerNumber: selectedCustomerNumber,
        stores: storesData?.store
      }
    : {
        formMethods: selectCustomerFormMethods,
        onSubmit: handleSelectCustomerSubmit,
        showTryAgainSystemFeedback: showTryAgainSystemFeedback,
        showTryAgainLoader: dealerAssociationCallLoading,
        isSelectDealerLoading: isSelectDealerLoading
      };

  const selectedRadio = searchResultsFormMethods.watch(
    CUSTOMER_NUMBER_RADIO_NAME
  );

  /**
   * clears error in search results drawer if the selection changes
   */
  useEffect(() => {
    setSearchResultsError(null);
  }, [selectedRadio]);

  /**
   * for dealer users
   * if the customerNumberCall is resolved, we check the quantity of search results
   * if search results > 1
   * - opens the search results drawer
   *
   * if search results === 0
   * - sets that search result as the selected customer number
   * - invokes the storesCall to retrieve the stores for that customer number
   *
   * if the customerNumberCall is rejected, we show an error on the search
   * customer modal/drawer
   */
  useEffect(() => {
    if (customerNumberCallStatus === STATUS.RESOLVED) {
      if (customerNumberList?.length > 1) {
        const drawerTitle = t('TITLE_SEARCH_RESULTS');
        setSelectCustomerFormTitle(drawerTitle);
        handleOpenSearchResults();
      }

      if (customerNumberList?.length === 1) {
        const drawerTitle = t('SEARCH_RESULTS_TEXT1');
        setSelectCustomerFormTitle(drawerTitle);
        const selectedCustomerNumber = normalizeCustomerNumber(
          customerNumberList[0]
        );
        setSelectedCustomerNumber(selectedCustomerNumber);
        invokeStoresCall({
          dealer: selectedDealer.value,
          customerNumber: selectedCustomerNumber.value
        });
      }

      if (!customerNumberList && !searchError) {
        setSearchError(ERRORS.NO_RESULTS);
      }
    } else if (customerNumberCallStatus === STATUS.REJECTED) {
      setSearchError('NO_VALID_DCN_NO_STORES');
    }
  }, [customerNumberCallStatus, customerNumberList]);

  /**
   * for dealer users
   * storesCallStatus changes whenever the customerNumberCall retrieves only 1 result
   * or if the search results form is submitted
   *
   * if the storesCall is resolved, the select customer drawer shall be open,
   * if the storesCall is rejected or retrieves 0 stores, an error will be shown
   * on the place where the storesCall was invoked (either search or search results form)
   */
  useEffect(() => {
    const handleError = () => {
      if (customerNumberList?.length > 1) {
        setSearchResultsError('NO_VALID_DCN_NO_STORES');
      }

      if (customerNumberList?.length === 1) {
        setSearchError('NO_VALID_DCN_NO_STORES');
      }
    };

    if (storesCallStatus === STATUS.RESOLVED) {
      const storesAvailable = storesData?.store?.length > 0;
      if (storesAvailable) {
        handleOpenSelectCustomer();
      } else {
        handleError();
      }
    } else if (storesCallStatus === STATUS.REJECTED) {
      handleError();
    }
  }, [storesCallStatus]);

  return {
    searchCustomerProps,
    searchResultsProps,
    selectCustomerProps,
    searchError
  };
};

export default useAssociatedDealer;
