import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DEFAULT_NUM_RESULTS } from '../constants';
import { getFilteredAndOrderedDealerStores, getLocation } from '../utils';
import { getAddress } from './utils';
import LogoutButton from '../../NavigationBarUtilitySection/LogoutButton';
import DealerLocatorForm from '../DealerLocatorForm';
import DealerLocationListForm from '../DealerLocationListForm';
import useFindDealers from '../hooks/useFindDealers/useFindDealers';
import CancelImportButton from '../CancelImportButton';

const DealerLocatorModal = ({
  adpSubmitPromise,
  description,
  isRegistration,
  showCancelImportButton,
  showShopAsGuestButton,
  subtitle,
  title,
  bobRedirect = null,
  bobUserId,
  fromEspot,
  reorderFunc = null
}) => {
  const [t] = useTranslation();
  const isCSPCustomer = useSelector(state => state?.common?.isCSPCustomer);
  const storeId = useSelector(state => state?.common?.storeId);
  const { address, city, country, state, zipcode } = useSelector(
    state => state?.store
  );
  const isCSR = useSelector(s => s.common.isCatCSR) || false;
  const userCountry = useSelector(state => state.common.userCountry);
  const userCity = useSelector(state => state.common.userCity);
  const storeName = useSelector(state => state.store.name);
  const showDefaultLocation = !(isCSPCustomer || storeName);
  const {
    invoke,
    data = {},
    error,
    loading,
    numInvocations
  } = useFindDealers();
  const DEFAULT_TITLE = t('FYD_SELECT_STORE');
  const DEFAULT_DESCRIPTION = t('CHECK_PRICE_ENTER_LOCATION');
  const location = showDefaultLocation
    ? getAddress([userCity, userCountry])
    : getLocation({
        isCSPCustomer,
        address,
        city,
        state,
        zipcode,
        country
      });

  const [numDisplayed, setNumDisplayed] = useState(DEFAULT_NUM_RESULTS);
  const [filters, setFilters] = useState(null);
  const [selectedSuggestion, setSelectedSuggestion] = useState(location);
  const signInRef = useRef();
  const {
    filteredAndOrderedVisibleDealerStores,
    allFilteredDealerStoresByDistance
  } = useMemo(
    () =>
      getFilteredAndOrderedDealerStores(
        data?.dealerStores,
        filters,
        numDisplayed
      ),
    [data.dealerStores, filters, numDisplayed]
  );

  const findDealers = useCallback(
    (suggestion, isSuggestion, skipGaFormSubmission = false) => {
      invoke(suggestion, isSuggestion, skipGaFormSubmission);
      setNumDisplayed(DEFAULT_NUM_RESULTS);
    },
    [invoke]
  );

  const handleSuggestionSelection = (suggestion, isSuggestion = false) => {
    if (suggestion) {
      findDealers(suggestion, isSuggestion);
    }
    setSelectedSuggestion(suggestion);
  };

  useEffect(() => {
    if (location) {
      findDealers(location, true, true);
    }
  }, [findDealers, location]);

  return (
    <div>
      <h2 className="exclude-global h2 display-1 mb-4">
        {title ?? DEFAULT_TITLE}
      </h2>
      {subtitle && (
        <h3
          data-testid="dealer-locator_subtitle"
          className="h4 color-extra-dark-gray"
        >
          {subtitle}
        </h3>
      )}
      <p className="exclude-global color-dark-gray mb-3" ref={signInRef}>
        {description ?? DEFAULT_DESCRIPTION}
      </p>

      <DealerLocatorForm
        dealerStores={data.dealerStores}
        filters={filters}
        selectedSuggestion={selectedSuggestion}
        setFilters={setFilters}
        setSelectedSuggestion={handleSuggestionSelection}
        storeId={storeId}
      />
      {selectedSuggestion ? (
        <DealerLocationListForm
          adpSubmitPromise={adpSubmitPromise}
          dealerStores={data.dealerStores}
          error={error}
          filters={filters}
          allFilteredDealerStoresByDistance={allFilteredDealerStoresByDistance}
          filteredAndOrderedVisibleDealerStores={
            filteredAndOrderedVisibleDealerStores
          }
          isRegistration={isRegistration}
          fromEspot={fromEspot}
          loading={loading}
          location={selectedSuggestion}
          numDisplayed={numDisplayed}
          numInvocations={numInvocations}
          selectedSuggestion={selectedSuggestion}
          setFilters={setFilters}
          setNumDisplayed={setNumDisplayed}
          setSelectedSuggestion={handleSuggestionSelection}
          showCancelImportButton={showCancelImportButton}
          showShopAsGuestButton={showShopAsGuestButton}
          uom={data.uom}
          storeId={storeId}
          signInRef={signInRef}
          bobRedirect={bobRedirect}
          bobUserId={bobUserId}
          reorderFunc={reorderFunc}
          showDefaultLocation={showDefaultLocation}
        />
      ) : (
        (showShopAsGuestButton || showCancelImportButton) && (
          <div
            data-testid="dealer-locator__buttons"
            className="text-center border-top py-3"
          >
            {showShopAsGuestButton && (
              <LogoutButton
                buttonType="ghost"
                className="my-3"
                size="large"
                name="welcome-modal__shop-as-guest-button"
                isCSR={isCSR}
              >
                {t('SHOP_AS_GUEST')}
              </LogoutButton>
            )}
            {showCancelImportButton && <CancelImportButton />}
          </div>
        )
      )}
    </div>
  );
};

export default DealerLocatorModal;

DealerLocatorModal.propTypes = {
  adpSubmitPromise: PropTypes.func,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  isRegistration: PropTypes.bool.isRequired,
  showCancelImportButton: PropTypes.bool,
  showShopAsGuestButton: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  bobRedirect: PropTypes.string,
  bobUserId: PropTypes.string
};
