import { useCallback, useRef, useState } from 'react';
import { getHttpInstance } from '../services/defaultHttpService';
import { normalizeError } from '../store/exception/utils';

const useHttp = ({ timeout, processData, retries, retryDelay } = {}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState();
  const [responseHeaders, setResponseHeaders] = useState();
  const [numInvocations, setNumInvocations] = useState(0);

  const http = getHttpInstance(timeout, retries, retryDelay);
  const httpRef = useRef(http);

  const invoke = useCallback(
    ({
      url,
      method = 'get',
      rethrowError = false,
      skipRedirectOnError = false,
      ...options
    }) => {
      setData(undefined);
      setError(undefined);
      setLoading(true);
      setNumInvocations(n => n + 1);
      return httpRef?.current
        .request({ method, url, ...options })
        .then(({ data, headers }) => {
          const processed = processData ? processData(data) : data;
          setData(processed);
          setResponseHeaders(headers);
          setLoading(false);
          return processed;
        })
        .catch(err => {
          setError(normalizeError(err, '', false, skipRedirectOnError));
          setLoading(false);
          if (rethrowError) {
            throw err;
          } else {
            return err;
          }
        });
    },
    [processData]
  );

  const reset = useCallback(() => {
    setData(undefined);
    setError(undefined);
    setLoading(false);
    setNumInvocations(0);
  }, []);

  return {
    data,
    responseHeaders,
    error,
    invoke,
    loading,
    numInvocations,
    reset
  };
};

export default useHttp;
