import PropTypes from 'prop-types';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerFooter,
  LoginDisclaimer,
  ListWithIcons,
  ListWithIconsItem
} from '@cat-ecom/pcc-components';

import { useTranslation } from 'react-i18next';
import { CatHeading } from 'blocks-react/bedrock/components/Heading';
import SignInButton from '@app/components/common/LoginAndRegisterButtons/SignInButton';
import RegisterButton from '@app/components/common/LoginAndRegisterButtons/RegisterButton';
import {
  ImportPartsIcon,
  ShopKitsIcon,
  TaskCompleteIcon
} from '@app/components/common/SvgIcons';
import { v4 as uuidv4 } from 'uuid';
import { SIS } from '@app/constants/commonConstants';

const SisEquipmentDrawer = ({ drawerProps, onDrawerClose }) => {
  const [t] = useTranslation();

  const items = [
    {
      background: true,
      icon: <ShopKitsIcon size="lg" />,
      text: t('SIS_BENEFIT_ACCESS')
    },
    {
      background: true,
      icon: <TaskCompleteIcon size="lg" />,
      text: t('SIS_BENEFIT_MAINTENANCE')
    },
    {
      background: true,
      icon: <ImportPartsIcon size="lg" />,
      text: t('SIS_BENEFIT_IMPORT')
    }
  ];

  return (
    <>
      <Drawer {...drawerProps}>
        <DrawerHeader>
          <CatHeading variant="title-sm">{SIS}</CatHeading>
        </DrawerHeader>
        <DrawerBody className="">
          <ListWithIcons>
            {items.map(item => (
              <ListWithIconsItem
                className="pb-2"
                icon={item.icon}
                key={`list-with-icon-item-${uuidv4()}`}
                text={item.text}
                background={item?.background}
              />
            ))}
          </ListWithIcons>
        </DrawerBody>
        <DrawerFooter>
          <LoginDisclaimer text={t('SIGN_CREATE_ACCOUNT_MSG')} />
          <SignInButton className="pb-3" />
          <RegisterButton closeDrawer={onDrawerClose} />
        </DrawerFooter>
      </Drawer>
    </>
  );
};

SisEquipmentDrawer.propTypes = {
  drawerProps: PropTypes.object,
  onDrawerClose: PropTypes.func,
  isChild: PropTypes.bool
};

export default SisEquipmentDrawer;
