import { format } from 'date-fns/format';
import { v4 } from 'uuid';
import {
  replaceTokensInString,
  replaceTokensInUrl,
  normalizeUrl,
  isEmpty,
  setSessionStorage,
  debounce,
  sortList
} from '@app/utils';
import { handleDeleteGTM, filterHandler } from './utils';
import resolvedEndpoints from '@app/constants/endpoints';
import resolvedLinks from '@app/constants/links';
import {
  ERROR_DOMAIN,
  ERROR_PATH,
  UNKNOWN_ERROR_MESSAGE
} from '@app/constants/errorConstants';
import { getHttpInstance } from '@app/services/defaultHttpService';
import { setError, clearError } from '../exception/actions';
import { getStoreInfo } from '@app/services/storeInfoService';
import { normalizeError } from '../exception/utils';
import * as types from './constants';
import {
  ISO_DATE_FORMAT,
  APPROVE_ORDER,
  TIMEOUT_DEFAULT,
  TIMEOUT_EXTENDED,
  SAVED_LIST_TYPES,
  PUBLIC_LIST,
  DEALER_LIST,
  SORT_DIRECTIONS,
  SORT_TYPE,
  REJECT_ORDER
} from '@app/constants/commonConstants';
import { LOCALES_FOR_REVERSE_NAME } from '@app/constants/languageConstants';
import {
  getItemsFromQuickOrderCookie,
  updateQuickOrderItemsCookie
} from '@app/components/pages/checkout/shopping-cart/QuickOrder/utils';
import { getOrder } from '../orders/actions';
import { navigateToUrl } from '@app/services/windowService';
import {
  E_CUSTOMER,
  CUSTOMER,
  TEPS_DEALER,
  TEPS_DEALER_CAP,
  DEALER_CUSTOMER
} from '@app/constants/affiliations';
import {
  QUOTES_AWAITING_APPROVAL,
  PENDING_QUOTE_COLUMN_IDS
} from '@app/components/pages/account/pending-quotes/constants';
import { formatNonRestResponse } from '@app/utils/stringUtils';

const {
  CREATE_SAVED_LIST,
  SAVED_LIST_ADD_ITEMS,
  SAVED_LIST_URL,
  SAVED_LIST_CREATE_NEW_LIST_URL,
  SAVED_LIST_DUPLICATE_LIST_URL,
  SAVED_LIST_DELETE_LIST_URL,
  SAVED_LIST_ADD_TO_CART_URL,
  SAVED_LIST_UPLOAD_LIST_URL,
  LOAD_PAYMENT_METHODS,
  DELETE_PAYMENT_METHOD,
  CONFIRM_PAYMENT_METHOD,
  RECENT_ORDER_HISTORY_URL,
  PENDING_QUOTE_APPROVE,
  PENDING_QUOTES_LIST_URL_DCN,
  PENDING_QUOTES_LIST_URL,
  PENDING_QUOTES_DELETE_URL,
  PENDING_QUOTES_EDIT_URL,
  PENDING_QUOTES_STOP_EDIT_URL,
  ORDERS_USER_INFORMATION
} = resolvedEndpoints;

const REQUEST_TIMEOUT = 60000;

export const loadPaymentMethodDetails = () => (dispatch, getState) => {
  const http = getHttpInstance(12000);
  const { storeId } = getState().common;
  const url = replaceTokensInString(LOAD_PAYMENT_METHODS, storeId);
  const config = { headers: { 'Cache-Control': 'no-cache' } };
  return http
    .get(url, config)
    .then(response => {
      const { CheckoutProfile, dealerName = 'current dealer' } = response.data;
      dispatch({
        type: types.AC_LOAD_PAYMENTMETHODS_SUCCESS,
        payload: {
          paymentMethods: CheckoutProfile,
          dealerName
        }
      });
    })
    .catch(() => {
      dispatch({ type: types.AC_CLEAR_PAYMENTMETHODS });
      dispatch(
        setError(ERROR_DOMAIN.PAYMENTMETHODS, ERROR_PATH.MAIN, {
          message: 'GENERIC_ERROR'
        })
      );
    });
};

export const deletePaymentMethod =
  (cardName, xchkout_creditCardProfileId) => (dispatch, getState) => {
    const http = getHttpInstance(12000);

    handleDeleteGTM(cardName);
    const orderId = xchkout_creditCardProfileId;
    const { storeId } = getState().common;

    return http
      .post(DELETE_PAYMENT_METHOD, null, {
        params: { orderId, storeId }
      })
      .then(response => {
        if (response.data.success) {
          dispatch({
            type: types.AC_DELETE_PAYMENTMETHODS_SUCCESS,
            payload: { profileId: xchkout_creditCardProfileId }
          });
          return http.post(CONFIRM_PAYMENT_METHOD, null, {
            params: { orderId, storeId }
          });
        }
      });
  };

export const loadRecentOrderHistoryData = recentOrderHistory => ({
  type: types.AC_LOAD_RECENTORDERHISTORY_SUCCESS,
  payload: {
    recentOrderHistory
  }
});

export const loadRecentOrderHistory = () => {
  const http = getHttpInstance();

  return async (dispatch, getState) => {
    dispatch({ type: types.AC_LOAD_RECENTORDERHISTORY_BEGINS });
    const { storeId, catalogId, langId } = getState().common;
    const url = replaceTokensInString(
      RECENT_ORDER_HISTORY_URL,
      [storeId],
      [catalogId],
      [langId]
    );
    try {
      const { data } = await http.get(url);
      dispatch(loadRecentOrderHistoryData(data));
    } catch (err) {
      dispatch({ type: types.AC_LOAD_RECENTORDERHISTORY_FAILS, payload: err });
      dispatch(
        setError(
          ERROR_DOMAIN.RECENT_ORDERS_HISTORY,
          ERROR_PATH.MAIN,
          normalizeError(err)
        )
      );
    }
  };
};

export const loadSavedLists = ({ savedLists }) => ({
  type: types.AC_LOAD_SAVEDLIST_SUCCESS,
  savedLists
});

export function loadSavedListDetails(
  searchOptions,
  paramSelfFOL,
  isPreferenceUpdated,
  onSuccessGAEvent,
  onFailGAEvent,
  isSelf = false
) {
  const http = getHttpInstance(REQUEST_TIMEOUT);
  // eslint-disable-next-line func-names
  return async function (dispatch, getState) {
    dispatch({
      type: types.AC_LOAD_SAVEDLIST_BEGIN,
      payload: { isPreferenceUpdated }
    });
    dispatch({ type: types.AC_SEARCH_OPTIONS_SET_DATA, searchOptions });
    const {
      common: { storeId, catalogId, langId, locale, userId },
      account: { selfFOL }
    } = getState();

    const isCATCSR = getState().common.isCatCSR || false;
    const isCustomerSupportSharedCartListsEnabled =
      getState().featureFlag?.PCC_CustomerSupportSharedCartLists || false;

    const urlParams = {
      storeId,
      catalogId,
      langId,
      self:
        searchOptions?.showMyLists !== undefined
          ? searchOptions?.showMyLists
          : !!(paramSelfFOL !== undefined ? paramSelfFOL : selfFOL),
      q: 'usable',
      orderBy: 'D-lastUpdate',
      favorite: searchOptions?.showMyFavs
    };

    if (isCustomerSupportSharedCartListsEnabled && isCATCSR) {
      urlParams.self = isSelf;
    }

    const savedListUrl = replaceTokensInUrl(SAVED_LIST_URL, storeId);
    if (searchOptions) {
      const startDate = searchOptions.startDate
        ? format(new Date(searchOptions.startDate), ISO_DATE_FORMAT.SHORT)
        : '';
      const endDate = searchOptions.endDate
        ? format(new Date(searchOptions.endDate), ISO_DATE_FORMAT.SHORT)
        : '';
      urlParams.createdBy = searchOptions.createdBy;
      urlParams.itemPartNumber = searchOptions.itemPartNumber;
      urlParams.folName = searchOptions.folName;
      urlParams.lastUpdateStartDate = startDate;
      urlParams.lastUpdateEndDate = endDate;
    }

    const headers = { 'Cache-Control': 'no-cache' };
    try {
      const { data: savedListsData } = await http.get(savedListUrl, {
        params: urlParams,
        headers
      });
      const { resultList = [] } = savedListsData;
      let savedLists = [];

      if (!isEmpty(resultList)) {
        // get dealer info updated
        // this is because some times the dealer response is slower
        const { dealer } = getState();
        const { dealerName, dealerDateFormat } = dealer;
        const dateFormat = dealerDateFormat ?? 'M/d/yy';

        savedLists = resultList.map(savedList => {
          const {
            orderId,
            status,
            description,
            userRegistration,
            memberId,
            lastUpdate,
            favorite,
            isSharedWithUCID,
            itemCount
          } = savedList;

          // Start Mapping data
          const name = description;

          // set List Type
          const type =
            SAVED_LIST_TYPES[status] ?? SAVED_LIST_TYPES[PUBLIC_LIST];

          // set createdBy
          const isDealerList = status === DEALER_LIST;
          const userName = LOCALES_FOR_REVERSE_NAME.includes(locale)
            ? `${userRegistration.lastName} ${userRegistration.firstName}`
            : `${userRegistration.firstName} ${userRegistration.lastName}`;
          const createdBy = isDealerList ? dealerName : userName;

          // Set actions
          const isOwnList = memberId === userId;
          const actions = {
            duplicatelist: 'show'
          };
          if (itemCount > 0) {
            actions['addtocart'] = 'show';
          }
          if (isOwnList) {
            actions['deletelist'] = 'show';
          }
          // set updated field
          const unformattedDate = lastUpdate && new Date(lastUpdate).toString();
          const formattedDate =
            lastUpdate && format(new Date(lastUpdate), dateFormat);
          const updated = Date.parse(unformattedDate);
          const updatedDisplayValue = formattedDate ?? unformattedDate;

          return {
            orderId,
            name,
            ...(favorite === 'true' ? { favorite: true } : { favorite: false }),
            quantity: itemCount,
            status,
            type,
            createdBy,
            actions,
            updated,
            updatedDisplayValue,
            lastUpdate: {
              formattedDate,
              unformattedDate
            },
            isSharedWithUCID
          };
        });
      }

      dispatch(loadSavedLists({ savedLists }));
      if (searchOptions) {
        dispatch({ type: types.AC_SAVEDLIST_SEARCH_SUCCESS, searchOptions });
      }
      if (onSuccessGAEvent) {
        const data = { ...searchOptions, savedLists };
        onSuccessGAEvent(data);
      }
      return true;
    } catch (error) {
      dispatch({ type: types.AC_LOAD_SAVEDLIST_FAIL, payload: error });
      if (onFailGAEvent) {
        onFailGAEvent(searchOptions);
      }
      return false;
    }
  };
}

export const addProductToSavedList =
  ({
    nameData,
    requisitionListId,
    fromConflictNotesModal,
    saveListData,
    close,
    ToastCtx,
    toastMessage,
    errorData = {
      title: 'SAVED_LIST_ADDITION_ERROR_HEADER',
      message: 'MLP_ERROR3',
      severity: 'error'
    },
    errorDomain = ERROR_DOMAIN.SHOPPING_LIST,
    errorPath = ERROR_PATH.ADD_PRODUCT_TO_LIST,
    fromConflictModal
  }) =>
  (dispatch, getState) => {
    const {
      account: { searchOptions }
    } = getState();
    const http = getHttpInstance(TIMEOUT_DEFAULT);
    const { storeId, catalogId, langId } = getStoreInfo();
    dispatch({ type: types.AC_ADD_TO_SAVED_LISTS_BEGIN });
    const config = {
      headers: { 'Cache-Control': 'no-cache' }
    };
    const url = replaceTokensInUrl(
      SAVED_LIST_ADD_ITEMS,
      storeId,
      langId,
      catalogId
    );
    const data = {
      requisitionListId,
      fromConflictNotesModal,
      items: saveListData
    };
    return http
      .post(url, data, config) // updated on story 251903, changed endpoint and data structure to use merge/unmerge functionality
      .then(response => {
        if (response.data && response.data.errors) {
          dispatch({ type: types.AC_ADD_TO_SAVED_LISTS_FAIL });
        } else if (response.data) {
          dispatch({
            type: types.AC_ADD_TO_SAVED_LISTS_SUCCESS,
            savedLists: response.data
          });
          close && close();
          dispatch(loadSavedListDetails(searchOptions));
          const toastData = fromConflictModal
            ? toastMessage
            : `${toastMessage} ${nameData}`;
          ToastCtx?.createSuccessToast(toastData);
        }
        return true;
      })
      .catch(_error => {
        dispatch({ type: types.AC_ADD_TO_SAVED_LISTS_FAIL });
        dispatch(setError(errorDomain, errorPath, errorData));
        return false;
      });
  };
//TODO: this method is not used anywhere, maybe remove
export const createSavedList =
  ({
    status,
    name,
    fromConflictNotesModal,
    saveListData,
    close,
    ToastCtx,
    toastMessage
  }) =>
  (dispatch, getState) => {
    const http = getHttpInstance(TIMEOUT_DEFAULT);
    const { storeId } = getState().common;
    const errorObject = {
      title: 'SAVED_LIST_CREATION_ERROR_HEADER',
      message: 'MLP_ERROR3',
      severity: 'error'
    };
    dispatch({ type: types.AC_ADD_TO_SAVED_LISTS_BEGIN });

    const config = {
      headers: { 'Cache-Control': 'no-cache' }
    };

    const url = replaceTokensInUrl(CREATE_SAVED_LIST, storeId);
    const data = {
      status,
      name
    };

    return http
      .post(url, data, config)
      .then(response => {
        if (response.data && response.data.errors) {
          dispatch({ type: types.AC_CREATE_SAVED_LIST_FAIL });
        } else if (response.data) {
          dispatch({
            type: types.AC_CREATE_SAVED_LIST_SUCCESS,
            savedLists: response.data
          });
          const nameData = name;
          const requisitionListId = response.data.requisitionListId;
          if (requisitionListId) {
            dispatch(
              addProductToSavedList({
                nameData,
                requisitionListId,
                fromConflictNotesModal,
                saveListData,
                close,
                ToastCtx,
                toastMessage
              })
            );
          }
          dispatch(loadSavedListDetails());
          close();
        }
      })
      .catch(error => {
        dispatch({ type: types.AC_CREATE_SAVED_LIST_FAIL });
        dispatch(
          setError(
            ERROR_DOMAIN.SHOPPING_LIST,
            ERROR_PATH.CREATE_SHOPPING_LIST_FORM,
            errorObject
          )
        );
      });
  };
export const setConflictConfirmationFlag = ({
  isOpen,
  clickData = null,
  saveListData = []
}) => ({
  type: types.AC_OPEN_CONFLICT_CONFIRMATION,
  payload: { isOpen, clickData, saveListData }
});
export const setConflictResolutionFlag = isOpen => ({
  type: types.AC_OPEN_CONFLICT_RESOLUTION,
  isOpen
});
export const setConflictFlag = (hasConflictFlag = false) => ({
  type: types.AC_HAS_CONFLICT,
  hasConflictFlag
});
/*
  @deprecated This action uses an ancient endpoint, use createSavedList action instead
*/
export const creatSavedList = () => ({
  type: types.AC_CREATE_SAVEDLIST_SUCCESS
});

/*
  @deprecated This action uses an ancient endpoint, use createSavedList action instead
*/
export function createSavedListDetails(
  folName,
  listType,
  errorObject,
  ToastCtx,
  toastMessage,
  onSuccess
) {
  const http = getHttpInstance(REQUEST_TIMEOUT);

  // eslint-disable-next-line func-names
  return function (dispatch, getState) {
    const {
      common: { storeId },
      account: { searchOptions }
    } = getState();

    const dispatchAndToastWithResponse = data => {
      dispatch(
        loadSavedListDetails(searchOptions, null, null, null, null, true)
      );
      dispatch(creatSavedList());
      if (onSuccess) {
        onSuccess(data);
      }
      if (ToastCtx) {
        ToastCtx.createSuccessToast(toastMessage);
      }
    };

    const handleError = () => {
      dispatch({ type: types.AC_CREATE_SAVEDLIST_FAIL });
      errorObject &&
        dispatch(
          setError(
            ERROR_DOMAIN.CONTROL_CENTER,
            ERROR_PATH.CREATE_LIST_MODAL,
            errorObject
          )
        );
    };

    dispatch({ type: types.AC_CREATE_SAVEDLIST_BEGIN });
    const authToken = document.getElementById('WC_PCC_CSRF_authToken');
    const config = {
      headers: { 'Cache-Control': 'no-cache', WCAuthToken: authToken?.value }
    };
    const url = replaceTokensInUrl(SAVED_LIST_CREATE_NEW_LIST_URL, storeId);
    const urlParams = { status: listType, name: folName };
    return http
      .post(url, urlParams, config)
      .then(({ data }) => {
        return dispatchAndToastWithResponse(data);
      })
      .catch(_payload => {
        return handleError();
      });
  };
}

export const duplicateSavedList = () => ({
  type: types.AC_DUPLICATE_SAVEDLIST_SUCCESS
});

export function duplicateSavedListDetails(name, orderId, status) {
  const http = getHttpInstance(REQUEST_TIMEOUT);
  const authToken = document.getElementById(
    'WC_MyAccountDisplay_authToken'
  )?.value;
  // eslint-disable-next-line func-names
  return function (dispatch, getState) {
    dispatch({
      type: types.AC_DUPLICATE_SAVEDLIST_BEGIN,
      payload: { orderId }
    });
    const {
      common: { storeId, catalogId, langId },
      account: { searchOptions }
    } = getState();
    const urlParams = {
      storeId,
      catalogId,
      langId,
      name,
      orderId,
      status,
      authToken
    };
    const url = SAVED_LIST_DUPLICATE_LIST_URL;
    const config = { headers: { 'Cache-Control': 'no-cache' } };

    return http
      .get(url, { params: urlParams }, config)
      .then(() => {
        dispatch({
          type: types.AC_DUPLICATE_SAVEDLIST_SUCCESS,
          payload: { orderId }
        });
        duplicateSavedList();
        dispatch(loadSavedListDetails(searchOptions));
        return true;
      })
      .catch(payload => {
        dispatch({
          type: types.AC_DUPLICATE_SAVEDLIST_FAIL,
          payload: { orderId }
        });
        return false;
      });
  };
}

export const deleteSavedList = () => ({
  type: types.AC_DELETE_SAVEDLIST_SUCCESS
});

export function deleteSavedListDetails(requisitionListId) {
  const http = getHttpInstance(REQUEST_TIMEOUT);
  const authToken = document.getElementById(
    'WC_MyAccountDisplay_authToken'
  )?.value;
  // eslint-disable-next-line func-names
  return function (dispatch, getState) {
    dispatch({
      type: types.AC_DELETE_SAVEDLIST_BEGIN,
      payload: { orderId: requisitionListId }
    });
    const {
      common: { storeId, catalogId, langId },
      account: { searchOptions }
    } = getState();
    const urlParams = {
      storeId,
      catalogId,
      langId,
      requisitionListId,
      filterOption: 'All',
      requesttype: 'ajax',
      authToken
    };
    const url = SAVED_LIST_DELETE_LIST_URL;
    const config = { headers: { 'Cache-Control': 'no-cache' } };

    return http
      .get(url, { params: urlParams }, config)
      .then(() => {
        deleteSavedList();
        dispatch(loadSavedListDetails(searchOptions));
        return true;
      })
      .catch(payload => {
        dispatch({
          type: types.AC_DELETE_SAVEDLIST_FAIL,
          payload: { orderId: requisitionListId }
        });
        return false;
      });
  };
}

export function addToCartDetails(requisitionListId, checkedItemsID = []) {
  const http = getHttpInstance(REQUEST_TIMEOUT);
  // eslint-disable-next-line func-names
  return function (dispatch, getState) {
    dispatch({
      type: types.AC_ADDCART_SAVEDLIST_BEGIN,
      payload: { orderId: requisitionListId }
    });
    const { storeId, catalogId, langId, userId } = getState().common;
    const orderItems =
      getState().orders.byId[requisitionListId]?.ascendingOrderItems ||
      getState().orders.byId[requisitionListId]?.orderInformation?.orderItem;
    const itemsToAdd = !!checkedItemsID.length
      ? orderItems?.filter(
          item => checkedItemsID.indexOf(item.orderItemId) !== -1
        )
      : orderItems;
    const urlParams = {
      storeId,
      catalogId,
      langId,
      requisitionListId,
      URL: 'AjaxOrderItemDisplayView',
      sosCheck: true
    };
    const url = SAVED_LIST_ADD_TO_CART_URL;
    const config = { headers: { 'Cache-Control': 'no-cache' } };

    http
      .get(url, { params: urlParams }, config)
      .then(({ data }) => {
        let currentQOItems = getItemsFromQuickOrderCookie(userId) || [];
        currentQOItems = currentQOItems.filter(item => item.itemNumber !== '');
        const newItems = itemsToAdd.reduce((prev, current) => {
          const adjustedCurrent = {
            ...current,
            itemNumber: current.partNumber,
            assetId:
              current?.orderItemExtendAttribute?.lineItemNotes
                ?.customerSerialNumber || '',
            lineItemNote:
              current?.orderItemExtendAttribute?.lineItemNotes?.partNote || '',
            customerPartNumber:
              current?.orderItemExtendAttribute?.lineItemNotes
                ?.customerPartNumber || '',
            customerItemNumber:
              current?.orderItemExtendAttribute?.lineItemNotes?.lineItemNo ||
              '',
            page: types.SAVED_LIST,
            id: v4()
          };
          return [...prev, adjustedCurrent];
        }, currentQOItems);
        updateQuickOrderItemsCookie(newItems, userId);
        setSessionStorage(types.ADDED_SAVED_LIST_TO_CART, true);

        const responseJSON = formatNonRestResponse(data);
        window.location.href = replaceTokensInString(
          resolvedLinks.PLACEHOLDER_LINK,
          responseJSON.redirecturl,
          catalogId,
          langId,
          storeId
        );
      })
      .catch(payload =>
        dispatch({ type: types.AC_ADDCART_SAVEDLIST_FAIL, payload })
      );
  };
}

export const loadSelfFOLList = (searchOptions, selfFOL) => dispatch => {
  return dispatch(loadSavedListDetails(searchOptions, selfFOL));
};

export const loadCspSelectedSavedList =
  (searchOptions, selfFOL, favListCsp) => dispatch => {
    dispatch({ type: types.AC_SHOW_FAV_LIST_BEGIN });
    return dispatch(loadSavedListDetails(searchOptions, selfFOL, favListCsp));
  };

export const uploadSavedList = () => ({
  type: types.AC_UPLOAD_SAVEDLIST_SUCCESS
});

export function uploadSavedListDetails(formData) {
  const http = getHttpInstance(REQUEST_TIMEOUT);
  // eslint-disable-next-line func-names
  return function (dispatch, getState) {
    dispatch({ type: types.AC_UPLOAD_SAVEDLIST_BEGIN });
    const url = SAVED_LIST_UPLOAD_LIST_URL;
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const { searchOptions } = getState().account;
    return http
      .post(url, formData, config)
      .then(response => {
        dispatch(loadSavedListDetails(searchOptions));
        dispatch(uploadSavedList());
        return response;
      })
      .catch(payload => {
        dispatch({ type: types.AC_UPLOAD_SAVEDLIST_FAIL, payload });
        return payload;
      });
  };
}

export const resetSearch = () => {
  // eslint-disable-next-line func-names
  return function (dispatch) {
    dispatch({ type: types.AC_SAVEDLIST_SEARCH_RESET });
  };
};

export const setDcnError = message => dispatch => {
  const dcnErrorMessage = {
    severity: 'error',
    message,
    title: ''
  };
  return dispatch(
    setError(ERROR_DOMAIN.PENDING_QUOTES, ERROR_PATH.MAIN, dcnErrorMessage)
  );
};

export const loadPendingQuotes =
  ({
    dcn,
    domain = ERROR_DOMAIN.PENDING_QUOTES,
    invalidCustomerNumberErrorMessage,
    isDCNPresent,
    path = ERROR_PATH.MAIN,
    filters = {},
    successCb,
    failureCb,
    quoteType
  }) =>
  (dispatch, getState) => {
    dispatch(clearError(domain, path));
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    const { storeId, userAffiliation } = getState().common;
    if (!!isDCNPresent) {
      dispatch({ type: types.AC_LOAD_PENDINGQUOTES_BEGIN });
      const message = {
        severity: 'error',
        message: 'UNKNOWN_ERROR_MESSAGE',
        title: 'UNKNOWN_ERROR_TITLE'
      };
      let requestURL = replaceTokensInString(PENDING_QUOTES_LIST_URL, storeId);
      if (
        (userAffiliation === E_CUSTOMER ||
          userAffiliation === CUSTOMER ||
          userAffiliation === TEPS_DEALER ||
          userAffiliation === TEPS_DEALER_CAP ||
          userAffiliation === DEALER_CUSTOMER) &&
        dcn
      ) {
        requestURL = replaceTokensInString(
          PENDING_QUOTES_LIST_URL_DCN,
          storeId,
          dcn
        );
      }

      return http
        .get(requestURL)
        .then(({ data }) => {
          const pendingQuotesList = data?.quotes?.filter(
            item => !item.isAwaitingApproval
          );
          const awaitingQuotesList = data?.quotes?.filter(
            item => item.isAwaitingApproval
          );
          const filteredQuotes = filterHandler(
            filters,
            quoteType === QUOTES_AWAITING_APPROVAL
              ? awaitingQuotesList
              : pendingQuotesList
          );
          const quotesWithItemsCount = filteredQuotes?.map(item => ({
            ...item,
            itemsCount: item.quoteItems?.length
          }));

          dispatch(
            quoteType === QUOTES_AWAITING_APPROVAL
              ? {
                  type: types.AC_LOAD_PENDING_APPROVAL_QUOTES_SUCCESS,
                  payload: {
                    ...data,
                    awaitingQuotesList: sortList({
                      id: PENDING_QUOTE_COLUMN_IDS.SUBMISSION_DATE_UNFORMATTED,
                      dir: SORT_DIRECTIONS.DESC,
                      type: SORT_TYPE.DATE,
                      data: quotesWithItemsCount
                    }),
                    dcn,
                    filters
                  }
                }
              : {
                  type: types.AC_LOAD_PENDINGQUOTES_SUCCESS,
                  payload: {
                    ...data,
                    pendingQuotesList: quotesWithItemsCount,
                    quotes: quotesWithItemsCount,
                    dcn,
                    filters
                  }
                }
          );
          if (successCb) {
            successCb(filters, quotesWithItemsCount.length || 0);
          }
          return { data, responseStatus: true };
        })
        .catch(() => {
          dispatch({ type: types.AC_LOAD_PENDINGQUOTES_FAIL });
          dispatch(setError(domain, path, message));
          if (failureCb) {
            failureCb(filters);
          }
          return { responseStatus: false };
        });
    } else {
      dispatch(setDcnError(invalidCustomerNumberErrorMessage));
    }
  };

export const filterPendingQuotes = values => dispatch => {
  dispatch({
    type: types.AC_PENDINGQUOTE_FILTER_CHANGE,
    payload: values
  });
};

export const deletePendingQuote =
  ({ orderId, domain, path, message }) =>
  (dispatch, getState) => {
    dispatch(clearError(domain, path));
    const http = getHttpInstance();
    const { storeId, isBuyOnBehalf, userId } = getState().common;
    const requestURL = replaceTokensInString(
      PENDING_QUOTES_DELETE_URL,
      storeId,
      orderId
    );
    const finalURL =
      isBuyOnBehalf && userId
        ? `${requestURL}&forUserId=${userId}`
        : requestURL;

    dispatch({
      type: types.AC_DELETE_PENDINGQUOTE_BEGIN,
      payload: {
        orderId
      }
    });

    return http
      .delete(finalURL)
      .then(({ data }) => {
        dispatch({
          type: types.AC_DELETE_PENDINGQUOTE_SUCCESS,
          payload: {
            orderId
          }
        });
        return data;
      })
      .catch(err => {
        dispatch({
          type: types.AC_DELETE_PENDINGQUOTE_FAIL,
          payload: { orderId }
        });
        dispatch(setError(domain, path, { message: message }));
        throw err;
      });
  };

export const approvePendingQuote =
  (
    {
      orderId,
      purchaseOrderInfo: { poNumber },
      domain,
      path,
      message,
      rejectOrder
    },
    trackFormSubmission,
    gaFormName
  ) =>
  (dispatch, getState) => {
    dispatch(clearError(domain, path));
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    const { isBuyOnBehalf, userId, storeId, langId } = getState().common;
    const urlWithStore = replaceTokensInString(
      PENDING_QUOTE_APPROVE,
      storeId,
      langId
    );
    const requestURL =
      isBuyOnBehalf && userId
        ? `${urlWithStore}&forUserId=${userId}`
        : urlWithStore;

    dispatch({
      type: types.AC_APPROVE_PENDING_QUOTE_BEGIN
    });

    rejectOrder &&
      dispatch({
        type: types.AC_REJECT_PENDING_QUOTE_BEGIN
      });
    const body = {
      quoteActivateFlag: rejectOrder ? REJECT_ORDER : APPROVE_ORDER,
      orderId,
      ...{ purchaseOrderNumber: poNumber }
    };
    return http
      .post(requestURL, body)
      .then(({ data }) => {
        trackFormSubmission &&
          trackFormSubmission({
            isPlaceOrderFailed: false,
            gaFormName
          });
        dispatch({
          type: types.AC_APPROVE_PENDING_QUOTE_SUCCESS
        });
        rejectOrder &&
          dispatch({
            type: types.AC_REJECT_PENDING_QUOTE_SUCCESS
          });
        return data;
      })
      .catch(err => {
        trackFormSubmission &&
          trackFormSubmission({
            isPlaceOrderFailed: true,
            gaFormName
          });
        dispatch({
          type: types.AC_APPROVE_PENDING_QUOTE_FAIL
        });
        rejectOrder &&
          dispatch({
            type: types.AC_REJECT_PENDING_QUOTE_FAIL
          });
        dispatch(setError(domain, path, { message: message }));
        throw err;
      });
  };

export const editPendingQuote = orderId => async (dispatch, getState) => {
  try {
    const http = getHttpInstance();
    const { storeId, langId } = getState().common;

    const url = replaceTokensInString(
      PENDING_QUOTES_EDIT_URL,
      storeId,
      orderId,
      langId
    );

    dispatch({
      type: types.AC_EDIT_PENDINGQUOTE_BEGIN,
      payload: { orderId }
    });

    const { data } = await http.put(url);
    const pendingQuoteUrl = data?.redirectUrl;

    const orgSetInSessionUrl = replaceTokensInUrl(
      resolvedEndpoints.DEALER_CUSTOMER_ORG_SET_IN_SESSION,
      storeId
    );
    await http.get(orgSetInSessionUrl);

    dispatch({
      type: types.AC_EDIT_PENDINGQUOTE_SUCCESS,
      payload: { orderId }
    });
    const pageRedirectUrl = normalizeUrl(pendingQuoteUrl);
    pendingQuoteUrl && navigateToUrl(pageRedirectUrl);
  } catch (err) {
    dispatch({
      type: types.AC_EDIT_PENDINGQUOTE_FAIL,
      payload: { orderId }
    });
    throw err;
  }
};

export const stopEditQuote = orderId => (dispatch, getState) => {
  const http = getHttpInstance();
  const { storeId, langId } = getState().common;

  const url = replaceTokensInString(
    PENDING_QUOTES_STOP_EDIT_URL,
    storeId,
    orderId,
    langId
  );
  dispatch({
    type: types.AC_STOP_EDIT_PENDINGQUOTE_BEGIN
  });
  return http
    .put(url)
    .then(({ data: { redirectUrl: stopPendingQuoteUrl } }) => {
      dispatch({
        type: types.AC_STOP_EDIT_PENDINGQUOTE_SUCCESS
      });
      const pageRedirectUrl = normalizeUrl(stopPendingQuoteUrl);
      stopPendingQuoteUrl && navigateToUrl(pageRedirectUrl);
    })
    .catch(() => {
      dispatch({
        type: types.AC_STOP_EDIT_PENDINGQUOTE_FAIL
      });
    });
};

export const savedListAddToCart =
  (requisitionListId, error = {}) =>
  async dispatch => {
    const {
      errorDomain = '',
      errorPath = '',
      message = UNKNOWN_ERROR_MESSAGE
    } = error;
    const handleSetError = () => setError(errorDomain, errorPath, { message });
    await dispatch(getOrder(requisitionListId, handleSetError));
    dispatch(addToCartDetails(requisitionListId));
  };
export const setCloseSystemFeedback = pendingQuotesErrorDismiss => {
  return {
    type: types.AC_PENDING_QUOTE_SYSTEMFEEDBACK,
    payload: pendingQuotesErrorDismiss
  };
};

export const updateFavoriteListLimitStatus =
  (orderId, isFavorite) => dispatch =>
    dispatch({
      type: types.AC_SAVED_LIST_LIMIT_STATUS,
      payload: { orderId, isFavorite }
    });

export const markListFavorite =
  ({ orderId, name, status }, isFavorite) =>
  (dispatch, getState) => {
    const http = getHttpInstance();
    const { storeId } = getState().common;
    const { searchOptions } = getState().account;

    const url = replaceTokensInString(
      ORDERS_USER_INFORMATION,
      storeId,
      orderId
    );
    dispatch({
      type: types.AC_MARK_LIST_FAVORITE_BEGIN,
      payload: { orderId }
    });
    dispatch(updateFavoriteListLimitStatus(orderId, isFavorite));
    return http
      .put(url, {
        favorite: isFavorite,
        name,
        status
      })
      .then(() => {
        dispatch(loadSavedListDetails(searchOptions));
        dispatch({
          type: types.AC_MARK_LIST_FAVORITE_SUCCESS,
          payload: { orderId, isFavorite }
        });
        return true;
      })

      .catch(() => {
        dispatch({
          type: types.AC_MARK_LIST_FAVORITE_FAIL,
          payload: { orderId }
        });
        return false;
      });
  };

export const getUCIDList =
  ({ searchTerm = '', pageSize = 50 }) =>
  async dispatch => {
    dispatch({ type: types.SAVED_LIST_UCID_GET_BEGIN });
    const { storeId } = getStoreInfo();
    const http = getHttpInstance();
    const url = replaceTokensInUrl(
      resolvedEndpoints.SAVED_LIST_UCID_LIST,
      storeId,
      pageSize
    );

    try {
      const { data } = await http.request({
        url,
        method: 'post',
        data: { q: searchTerm }
      });

      dispatch({
        type: types.SAVED_LIST_UCID_GET_SUCCESS,
        payload: data ?? []
      });
    } catch (error) {
      dispatch({
        type: types.SAVED_LIST_UCID_GET_FAIL
      });
    }
  };

export const resetUCIDStateToIdle = () => dispatch =>
  dispatch({
    type: types.SAVED_LIST_UCID_GET_IDLE
  });

export const debouncedGetUCIDlist = debounce((dispatch, val) => {
  dispatch(getUCIDList(val));
}, 500);
