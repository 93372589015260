export const CART_MODIFICATION_EVENT =
  'com.cat.digital.ecommerce.events.cartModification';

export const SHOW_DEALER_LOCATOR_EVENT =
  'com.cat.digital.ecommerce.events.showDealerLocator';

export const SHOW_DEALER_LOCATOR_FROM_ADP_EVENT =
  'com.cat.digital.ecommerce.events.showDealerLocatorFromADP';

export const SHOW_CREATE_AN_ACCOUNT_EVENT =
  'com.cat.digital.ecommerce.events.createAnAccount';

export const SHOW_ASSOCIATED_DEALERS_EVENT =
  'com.cat.digital.ecommerce.events.openAssociatedDealers';

export const SHOW_CHECK_PRICE_EVENT =
  'com.cat.digital.ecommerce.events.showCheckPrice';

export const SHOW_SAVED_LIST_MODAL_AFTER_LOGIN_EVENT =
  'com.cat.digital.ecommerce.events.showSavedListAfterLogin';

export const SHOW_SAVED_LIST_MODAL_AFTER_ADP_EVENT =
  'com.cat.digital.ecommerce.events.showSavedListAfterADP';

export const DISABLE_ORDERS_PROCEED_TO_CHECKOUT_BUTTON_EVENT =
  'com.cat.digital.ecommerce.events.disableOrdersProceedToCheckoutButton';

export const SHOW_LOGIN_REGISTRATION_MODAL_EVENT =
  'com.cat.digital.ecommerce.events.showLoginRegistrationModal';

export const SHOW_LOGIN_EVENT =
  'com.cat.digital.ecommerce.events.showLoginPage';

export const LOGOUT_EVENT = 'com.cat.digital.ecommerce.events.logoutPageEvent';
