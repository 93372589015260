export const MESSAGE_STATUS = {
  orderPlaced: 'This order has been placed.',
  orderHold: 'This order is currently on hold, pending dealer approval.',
  orderHoldPendingCAL: 'This order is currently on hold, pending CAL approval.',
  orderCancelled: 'This order has been cancelled.',
  orderApproved:
    'This order has been approved. A dealer will process this order as soon as possible.',
  orderReleased: 'This order has been {0}, currently being worked by {1}.',
  exceptionMsg: 'There is an {0} with this order.',
  fullyShipped: 'This order has been {0}.',
  partialShipped: 'This order has been {0}.',
  partialPickUp: 'This order is ready for {0}.',
  orderDelivered: 'This order has been {0}.',
  readyForPickUp: 'This order is ready for {0}.',
  pickedUp: 'This order has been {0}.'
};

export const DEALER_SHIPMENT_TRACKING_INFO_MSG =
  '{0} is currently not sending shipment tracking information for this order.';

export const STEPPER_POSITION_MSG = [
  ['pendingApproval', 'Pending Approval'],
  ['placed', 'Placed'],
  ['processedByDealer', 'Processed by dealer']
];
