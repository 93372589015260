import { useCallback } from 'react';
import useHttp from '../../../../hooks/useHttp';
import { useSelector } from 'react-redux';
import { replaceTokensInUrl } from '../../../../utils';
import endpoints from '../../../../constants/endpoints';
import { TIMEOUT_FORTY_SECONDS } from '../../../../constants/commonConstants';

const useGetStores = () => {
  const langId = useSelector(state => state.common?.langId);
  const { data, error, invoke, loading } = useHttp({
    timeout: TIMEOUT_FORTY_SECONDS
  });

  const getStores = useCallback(
    ({ customerNumber, dealer }) => {
      let url = replaceTokensInUrl(
        endpoints.DEALER_CUSTOMER_SERVICE,
        dealer,
        customerNumber,
        langId
      );
      url = encodeURI(url);
      return invoke({ rethrowError: true, url });
    },
    [invoke, langId]
  );

  return {
    invoke: getStores,
    data,
    error,
    loading
  };
};

export default useGetStores;
