import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { AlloyButton, DownIcon, ComponentLoader } from 'cat-ecommerce-alloy';
import { useSystemFeedback } from '@app/hooks';
import useDealerUserSearch from '../../hooks/useDealerUserSearch';
import AddressInput from './AddressInput';
import CityInput from './CityInput';
import CustomerNameNumberInput from './CustomerNameNumberInput';
import ZipCodeInput from './ZipCodeInput';
import { ERROR_DOMAIN, ERROR_PATH } from '../../../../constants/errorConstants';

const DealerUserSearchForm = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const selectedDealer = useSelector(s => s.common?.selectedDealer);
  const { invoke, loading } = useDealerUserSearch();
  const { clearError, setError } = useSystemFeedback();
  const validate = values => {
    const { address, city, customerNumber, zipCode } = values;
    const isValid = address || city || customerNumber || zipCode;
    if (!isValid) {
      setError(ERROR_DOMAIN.DEALER_USER_SEARCH, ERROR_PATH.MAIN, {
        severity: 'error',
        message: 'ADV_SEARCH_NULL_ERROR'
      });
      // we need to return something for the error so the inputs are highlighted.
      // we are not diplaying the message so the error doesn't matter
      return {
        address: 'error',
        city: 'error',
        customerNumber: 'error',
        zipCode: 'error'
      };
    }
    return {};
  };

  const onSubmit = (values, { resetForm }) => {
    if (loading) {
      return;
    }
    const resetDealerSearchForm = () => {
      setExpanded(false);
      resetForm();
    };

    clearError(ERROR_DOMAIN.DEALER_USER_SEARCH, ERROR_PATH.MAIN);
    const {
      address = '',
      city = '',
      customerNumber = '',
      zipCode = ''
    } = values;
    invoke(
      {
        address,
        city,
        customerName: customerNumber,
        customerNumber,
        zipcode: zipCode
      },
      resetDealerSearchForm
    );
  };

  const initialValues = {
    address: '',
    customerNumber: '',
    city: '',
    zipCode: ''
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {() => (
        <Form>
          {loading && <ComponentLoader />}
          <p className="exclude-global color-dark-gray">
            {t('WILDCARD_SEARCH')}
          </p>
          <label className="exclude-global color-dark-gray text-univers--bold uppercase">
            {t('Footer_Dealer')}
          </label>
          <p className="exclude-global color-dark-gray text-xs">
            {selectedDealer}
          </p>
          <CustomerNameNumberInput expanded={expanded} />
          {expanded ? (
            <>
              <AddressInput />
              <CityInput />
              <ZipCodeInput />
              <div className={'d-flex justify-content-center'}>
                <AlloyButton
                  buttonType="secondary"
                  className="col-12 col-lg-auto mt-2"
                  size="large"
                  type="submit"
                >
                  {t('SEARCH_CATALOG')}
                </AlloyButton>
              </div>
            </>
          ) : (
            <div className={'d-flex flex-column align-items-start'}>
              <AlloyButton
                buttonType="ghost"
                className="ps-0 mb-2"
                onClick={() => setExpanded(true)}
                type="button"
              >
                {t('ADP_ADDITIONAL_FIELDS')}
                <DownIcon className="ms-1" />
              </AlloyButton>
              <p className="mb-4 text-xs u-color--DarkGray text-start">
                {t('CAT_PARTS_MANUALS_REQ_FIELDS')}
              </p>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default DealerUserSearchForm;
