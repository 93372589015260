import { useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ModalContext } from 'cat-ecommerce-alloy';
import AddEquipmentModal from '../pages/my-equipment/AddEquipmentModal';
import useDealerLocator from '@app/components/dealer-locator/';
import useRegister from '@app/components/login/hooks/useRegister';
import {
  STATUS,
  PCC_MODALS,
  USER_TYPE_GUEST
} from '@app/constants/commonConstants';

const useLaunchModal = requestedModal => {
  const [t] = useTranslation();
  const { register } = useRegister();
  const modalCtx = useContext(ModalContext);
  const storeId = useSelector(s => s.common?.storeId);
  const isCatCorp = useSelector(s => s.common.isCatCorp) || false;
  const userType = useSelector(s => s.common?.userType);
  const dealerAssociations =
    useSelector(s => s.dealerAssociation?.dealerAssociations) || [];
  const dealerAssociationStatus = useSelector(s => s.dealerAssociation?.status);
  const dealerAssociationCallResolved =
    dealerAssociationStatus === STATUS.RESOLVED;
  const { showDealerLocator } = useDealerLocator();

  const checkModalExists = () => {
    const found = Object.entries(PCC_MODALS).find(
      element => element[1] === requestedModal.toLocaleLowerCase()
    );

    return found
      ? {
          exists: true,
          keyName: found[0]
        }
      : { exists: false, keyName: null };
  };

  const launchSelectStoreModal = useCallback(() => {
    const hasDealerAssociations = dealerAssociations.length > 0;

    if (
      isCatCorp ||
      (!isCatCorp && !hasDealerAssociations && dealerAssociationCallResolved)
    ) {
      return showDealerLocator({ title: t('FYD_SELECT_STORE') });
    }
  }, [isCatCorp, dealerAssociations.length]);

  const launchRegisterModal = useCallback(() => {
    if (isCatCorp || userType === USER_TYPE_GUEST) {
      return register();
    }
  }, [isCatCorp, userType]);

  const modals = {
    [PCC_MODALS.SELECT_STORE]: launchSelectStoreModal,

    [PCC_MODALS.MY_EQUIPMENT]: () =>
      modalCtx.addModal(
        <AddEquipmentModal storeId={storeId} widgetIdentifier={'subheader'} />,
        t('ADD_MY_EQUIPMENT')
      ),

    [PCC_MODALS.REGISTER]: launchRegisterModal
  };

  const showModal = () => {
    const { exists, keyName } = checkModalExists();
    if (!exists) {
      return null;
    }

    return modals[PCC_MODALS[keyName]];
  };

  return { showModal };
};

export default useLaunchModal;
