import PropTypes from 'prop-types';

const DateField = ({
  as: Component,
  ariaLabel,
  onChange,
  setValue,
  value,
  ...props
}) => {
  const handleChange = (newVal, shouldValidate = true) => {
    if (onChange) {
      onChange(newVal);
    }
    setValue(newVal, shouldValidate);
  };

  return (
    <Component
      aria-label={ariaLabel}
      data-testid="field-dateField"
      date={value}
      setDate={handleChange}
      {...props}
    />
  );
};

DateField.propTypes = {
  as: PropTypes.elementType.isRequired,
  ariaLabel: PropTypes.string,
  onChange: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
};
export { DateField };
