import * as AC from '../../../../common/analytics/analyticsConstants';

const gaInfo = {
  event: AC.EVENT_FORM_SUBMITTED,
  formName: 'Shopping Preferences',
  pagePath: document.location.pathname,
  formStatus: null,
  formError: null,
  formFieldCausingError: null,
  formContent: null,
  formContent2: null,
  formLocation: null
};

// build formError message using error thrown from http request
export const gaBuildFormErrorMessage = (error, normalizedError) => {
  let msg = error.toString();
  if (normalizedError && Array.isArray(normalizedError.message)) {
    msg = normalizedError.message.join(', ');
  }

  // Change double quote to single quote. Double quote not allowed in GA message
  return msg.replace(/\"/g, "'");
};

export const gaSubmitFail = (error, normalizedError) => ({
  ...gaInfo,
  formStatus: AC.STATUS_FAIL,
  formError: gaBuildFormErrorMessage(error, normalizedError)
});

export const gaSubmitSuccess = () => ({
  ...gaInfo,
  formStatus: AC.STATUS_SUCCESS
});

export const gaValidationError = data => ({
  ...gaInfo,
  formStatus: AC.STATUS_FAIL,
  formFieldCausingError: Object.keys(data).join(', ')
});
