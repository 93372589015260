import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { HeaderWaffleMenu, Portal, useDrawer } from '@cat-ecom/pcc-components';
import WaffleDrawer from './WaffleDrawer/WaffleDrawer';
import { DRAWERS_ROOT } from '@app/constants/targets';
import { useTranslation } from 'react-i18next';
import { returnFocusToNavItem } from '@app/utils/DOMUtils';

const HeaderWaffleMenuWrapper = ({ displayMobileNavLink }) => {
  const { t } = useTranslation();
  const mobileLabel = useSelector(s => s.header?.waffle?.mobileLabel);
  const { initiatorProps, drawerProps, isDrawerOpen } = useDrawer({
    onClose: () => returnFocusToNavItem('#headerWaffleMenu')
  });
  return (
    <>
      <HeaderWaffleMenu
        displayMobileNavLink={displayMobileNavLink}
        handleDrawerProps={initiatorProps}
        id="headerWaffleMenu"
        isCurrent={isDrawerOpen}
        text={mobileLabel}
        title={t('CAT_SITES_WAFFLE')}
      />
      <Portal id={DRAWERS_ROOT}>
        <WaffleDrawer {...drawerProps} />
      </Portal>
    </>
  );
};

HeaderWaffleMenuWrapper.propTypes = {
  displayMobileNavLink: PropTypes.bool
};

export default HeaderWaffleMenuWrapper;
