import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTracking } from 'react-tracking';
import { ModalContext } from 'cat-ecommerce-alloy';
import { useSystemFeedback, useHttp } from '@app/hooks';
import SearchResultModal from '../../DealerUserModal/SearchResultModal';
import { replaceTokensInUrl } from '../../../../utils';
import endpoints from '../../../../constants/endpoints';
import { ERROR_DOMAIN, ERROR_PATH } from '../../../../constants/errorConstants';
import { normalizeError } from '../../../../store/exception/utils';
import {
  getFormSubmitEvent,
  setTimeSessionStorage
} from '../../../common/analytics/analyticsUtils';
import useChangeAssociatedDealer from '../../useChangeAssociatedDealer';
import useGetStores from '../useGetStores';
import { getDealerUserSearchGAFormContent } from '../../utils';
import { CUSTOMER_LOOKUP_API_INITIAL_TIME } from '@app/constants/analyticsConstants';

const useDealerUserSearch = () => {
  const { t } = useTranslation();
  const dealerUserStoreId = useSelector(
    state => state.common?.dealerUserStoreId
  );
  const { trackEvent } = useTracking();
  const selectedDealer = useSelector(state => state.common?.selectedDealer);
  const { clearError, setError } = useSystemFeedback();
  const { showAssociatedDealer } = useChangeAssociatedDealer();
  const { invoke: getStores, loading: getStoresLoading } = useGetStores();
  const { invoke, loading } = useHttp();
  const { addModalWithOptions } = useContext(ModalContext);

  const handleGetStoresSuccess = useCallback(
    ({
      customerNumber,
      customerNumberLabel,
      dealerCustomerData,
      errorPath,
      removeResultsModal,
      resetDealerSearchForm
    }) => {
      const availableStores = dealerCustomerData.store;
      if (!Array.isArray(availableStores) || availableStores.length === 0) {
        const message = `${t('VALID_DCN_NO_STORES')} ${t(
          'VALID_DCN_NO_STORES_SYS_ADMIN'
        )}`;
        setError(ERROR_DOMAIN.DEALER_USER_SEARCH, errorPath, {
          severity: 'error',
          message
        });
      } else {
        showAssociatedDealer({
          isDealerUser: true,
          isLoginAction: true,
          isRequired: true,
          preSelectedDealer: [
            {
              label: selectedDealer,
              value: dealerUserStoreId,
              isCSPCustomer: true, //isCSPCustomer true to force ADP to get DCN from preSelectedDealer
              default: true,
              customerNumber: [
                {
                  default: true,
                  label: `${customerNumber} - ${customerNumberLabel}`,
                  name: customerNumberLabel,
                  value: customerNumber
                }
              ]
            }
          ],
          preSelectedStores: availableStores,
          removeResultsModal,
          resetDealerSearchForm
        });
      }
    },
    [dealerUserStoreId, selectedDealer, setError, showAssociatedDealer, t]
  );

  const selectDCN = useCallback(
    (
      selectedDealerCustomer,
      errorPath,
      resetDealerSearchForm,
      removeResultsModal,
      { singleResultGaEvent } = {}
    ) => {
      const customerNumber = selectedDealerCustomer.customerNumber;
      const customerNumberLabel = selectedDealerCustomer.name;
      const gaEvent = {
        ...getFormSubmitEvent({ formName: 'Dealer User Search Results' }),
        formFieldCausingError: ''
      };
      getStores({ customerNumber, dealer: dealerUserStoreId })
        .then(dealerCustomerData => {
          handleGetStoresSuccess({
            customerNumber,
            customerNumberLabel,
            dealerCustomerData,
            errorPath,
            removeResultsModal,
            resetDealerSearchForm
          });
          trackEvent(gaEvent);
        })
        .catch(error => {
          const errorEvent = singleResultGaEvent || gaEvent;
          setError(
            ERROR_DOMAIN.DEALER_USER_SEARCH,
            errorPath,
            normalizeError(error)
          );
          trackEvent({
            ...errorEvent,
            formFieldCausingError: 'invalid dcn',
            formStatus: 'fail'
          });
        });
    },
    [dealerUserStoreId, getStores, handleGetStoresSuccess, setError, trackEvent]
  );

  const handleDealerUserCustomerNumberSuccess = useCallback(
    ({ dealerCustomer }, resetDealerSearchForm, gaEvent) => {
      const title = t('SEARCH_RESULTS');
      const dataLength = (dealerCustomer || []).length;
      if (dataLength === 0) {
        trackEvent({
          ...gaEvent,
          formFieldCausingError: 'no results',
          formStatus: 'fail'
        });
        setError(ERROR_DOMAIN.DEALER_USER_SEARCH, ERROR_PATH.MAIN, {
          severity: 'error',
          message: 'SEARCH_GENERIC_NO_RESULTS'
        });
      } else if (dataLength === 1) {
        const selectedDealerCustomer = dealerCustomer[0] || {};
        selectDCN(
          selectedDealerCustomer,
          ERROR_PATH.MAIN,
          resetDealerSearchForm,
          undefined,
          {
            singleResultGaEvent: gaEvent
          }
        );
      } else {
        trackEvent(gaEvent);
        addModalWithOptions(
          <SearchResultModal
            title={title}
            results={dealerCustomer}
            resetDealerSearchForm={resetDealerSearchForm}
          />,
          {
            title
          }
        );
      }
    },
    [addModalWithOptions, selectDCN, setError, t, trackEvent]
  );

  const getDealerUserCustomerNumbers = useCallback(
    (values, resetDealerSearchForm) => {
      clearError(ERROR_DOMAIN.DEALER_USER_SEARCH, ERROR_PATH.MAIN);
      const url = replaceTokensInUrl(
        endpoints.DEALER_USER_SEARCH,
        dealerUserStoreId
      );
      const body = {
        ...values,
        storeId: dealerUserStoreId
      };
      const formContent = getDealerUserSearchGAFormContent(values);
      const gaEvent = {
        ...getFormSubmitEvent({ formName: 'Dealer User Search', formContent }),
        formFieldCausingError: ''
      };
      setTimeSessionStorage(CUSTOMER_LOOKUP_API_INITIAL_TIME);
      invoke({ data: body, method: 'post', rethrowError: true, url })
        .then((response = {}) =>
          handleDealerUserCustomerNumberSuccess(
            response,
            resetDealerSearchForm,
            gaEvent
          )
        )
        .catch(error => {
          trackEvent({
            ...gaEvent,
            formFieldCausingError: 'system error',
            formStatus: 'fail'
          });
          setError(ERROR_DOMAIN.DEALER_USER_SEARCH, ERROR_PATH.MAIN, {
            ...normalizeError(
              error,
              {
                severity: 'error',
                message: 'UNKNOWN_ERROR_MESSAGE',
                title: undefined
              },
              true
            )
          });
        });
    },
    [
      clearError,
      dealerUserStoreId,
      handleDealerUserCustomerNumberSuccess,
      invoke,
      setError,
      trackEvent
    ]
  );

  return {
    invoke: getDealerUserCustomerNumbers,
    loading: loading || getStoresLoading,
    selectDCN
  };
};

export default useDealerUserSearch;
