import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useOrderInfo = orderId => {
  const dealerAssociations =
    useSelector(s => s.dealerAssociation.dealerAssociations) || [];
  const storeId = useSelector(s => s?.orders?.byId[orderId]?.storeId);
  const [dealerInfo, setDealerInfo] = useState(null);
  const [termsCondAccepted, setTermsCondAccepted] = useState(false);
  const [orderDealerAssocToCustomer, setOrderDealerAssocToCustomer] =
    useState(false);

  useEffect(() => {
    if (!storeId || dealerAssociations.length === 0) {
      setTermsCondAccepted(false);
      return setOrderDealerAssocToCustomer(false);
    }

    const dealerFound = dealerAssociations.find(
      dealer => dealer.value === storeId
    );
    setDealerInfo(dealerFound);

    if (dealerFound) {
      setTermsCondAccepted(dealerFound.isTermsConditionAccepted);
      setOrderDealerAssocToCustomer(true);
    }
  }, [storeId, dealerAssociations]);

  return {
    dealerInfo,
    isOrderDealerAssocWithCustomer: orderDealerAssocToCustomer,
    isOrderDealerTCAccepted: termsCondAccepted
  };
};

export default useOrderInfo;
