import PropTypes from 'prop-types';
import { InfoIcon } from 'cat-ecommerce-alloy';
import styles from './InfoMessageBox.module.scss';
import cx from 'classnames';

const InfoMessageBox = ({ children }) => {
  return (
    <div
      className={cx(
        styles['info-message-box__container'],
        'd-flex bg--ExtraLightGray'
      )}
    >
      <div className="me-2">
        <InfoIcon className="u-fill--DarkGray" />
      </div>
      {children}
    </div>
  );
};

InfoMessageBox.propTypes = {
  children: PropTypes.node
};

export default InfoMessageBox;
