import PropTypes from 'prop-types';
import Conditional from '@app/components/common/Conditional/Conditional';
import { useControlCenterChecker } from '@app/components/pages/account/hooks';
import { USER_TYPE_GUEST } from '@app/constants/commonConstants';
import { DRAWERS_ROOT } from '@app/constants/targets';
import links from '@app/constants/links';
import useControlCenterUrl from '@app/hooks/useControlCenterUrl';
import { replaceTokensInString } from '@app/utils';
import {
  Portal,
  useDrawer,
  SubheaderControlCenterOrOrderHistory
} from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ListContent from '../LoginDrawer/ListContent';
import LoginDrawer from '../LoginDrawer/LoginDrawer';
import {
  AccountingDocumentIcon,
  PreviousOrderIcon,
  ReceiptIcon,
  ReorderPartsIcon,
  ReturnRequestIcon
} from '@app/components/common/SvgIcons';
import { returnFocusToNavItem } from '@app/utils/DOMUtils';
import {
  LEGACY_AJAX_LOGON_FORM_ROUTE,
  MY_ACCOUNT_SEARCH
} from '@app/constants/routes';
import { useRouteMatch, useLocation } from 'react-router-dom';
import queryString from 'query-string';

const ControlCenterOrOrderHistoryWrapper = ({ displayMobileNavLink }) => {
  const { t } = useTranslation();
  const match = useRouteMatch(LEGACY_AJAX_LOGON_FORM_ROUTE);
  const { search } = useLocation();
  const paramsUrl = queryString.parse(search);
  const { route: currentRoute } = paramsUrl;
  const isCurrentPage =
    !!match &&
    [MY_ACCOUNT_SEARCH.CONTROL_CENTER, MY_ACCOUNT_SEARCH.ORDERS].includes(
      currentRoute
    );
  const { closeDrawer, drawerProps, initiatorProps, isDrawerOpen } = useDrawer({
    onClose: () => returnFocusToNavItem('#controlCenterOrOrderHistoryNavItem')
  });
  const { isControlCenterEnabled } = useControlCenterChecker();
  const catalogId = useSelector(s => s.common?.catalogId);
  const isCSRUser = useSelector(s => s.common?.isCatCSR);
  const langId = useSelector(s => s.common?.langId);
  const storeId = useSelector(s => s.common?.storeId);
  const userType = useSelector(s => s.common?.userType);

  const isGuest = userType === USER_TYPE_GUEST;
  const controlCenterUrl = useControlCenterUrl();

  const navItemLabel = isControlCenterEnabled
    ? 'CONTROL_CENTER'
    : 'MA_ORDER_HISTORY';
  const link = isControlCenterEnabled
    ? controlCenterUrl
    : links.MA_ORDER_HISTORY_URL;
  const navItemHref = replaceTokensInString(link, catalogId, langId, storeId);

  const navItemProps = {
    text: t(navItemLabel),
    href: isGuest ? undefined : navItemHref,
    ...(isGuest ? initiatorProps : {})
  };

  const loginDrawerProps = {
    closeDrawer,
    title: t('MA_ORDER_HISTORY'),
    drawerProps
  };

  const listItems = [
    {
      background: true,
      icon: <ReceiptIcon size="lg" />,
      text: t('ORDER_HISTORY_BENEFIT_SUMMARY')
    },
    {
      background: true,
      icon: <AccountingDocumentIcon size="lg" />,
      text: t('ORDER_HISTORY_BENEFIT_INVOICES')
    },
    {
      background: true,
      icon: <PreviousOrderIcon size="lg" />,
      text: t('ORDER_HISTORY_BENEFIT_STATUS')
    },
    {
      background: true,
      icon: <ReorderPartsIcon size="lg" />,
      text: t('ORDER_HISTORY_BENEFIT_REORDER')
    },
    {
      background: true,
      icon: <ReturnRequestIcon size="lg" />,
      text: t('ORDER_HISTORY_BENEFIT_RETURN')
    }
  ];

  return (
    <Conditional test={!isCSRUser}>
      <SubheaderControlCenterOrOrderHistory
        data-testid="controlCenterOrOrderHistoryNavItem"
        displayMobileNavLink={displayMobileNavLink}
        id="controlCenterOrOrderHistoryNavItem"
        isControlCenterEnabled={isControlCenterEnabled}
        isCurrent={isGuest ? isDrawerOpen : isCurrentPage}
        navItemProps={navItemProps}
      />
      <Conditional test={isGuest}>
        <Portal id={DRAWERS_ROOT}>
          <LoginDrawer {...loginDrawerProps}>
            <ListContent items={listItems} />
          </LoginDrawer>
        </Portal>
      </Conditional>
    </Conditional>
  );
};

ControlCenterOrOrderHistoryWrapper.propTypes = {
  displayMobileNavLink: PropTypes.bool
};

export default ControlCenterOrOrderHistoryWrapper;
