export const COMMON_GET_VARS_BEGIN = 'COMMON_GET_VARS_BEGIN';
export const COMMON_GET_VARS_SUCCESS = 'COMMON_GET_VARS_SUCCESS';
export const COMMON_GET_VARS_FAIL = 'COMMON_GET_VARS_FAIL';
export const COMMON_SET_TRANSLATIONS_LOADED = 'COMMON_SET_TRANSLATIONS_LOADED';
export const COMMON_SET_USER_ID = 'COMMON_SET_USER_ID';
export const COMMON_CANCEL_CART_IMPORT = 'COMMON_CANCEL_CART_IMPORT';
export const COMMON_CREATE_GUEST_IDENTITY_BEGIN =
  'COMMON_CREATE_GUEST_IDENTITY_BEGIN';
export const COMMON_CREATE_GUEST_IDENTITY_FAIL =
  'COMMON_CREATE_GUEST_IDENTITY_FAIL';
export const COMMON_CREATE_GUEST_IDENTITY_SUCCESS =
  'COMMON_CREATE_GUEST_IDENTITY_SUCCESS';
export const SAVE_USER_INFO_BEGIN = 'SAVE_USER_INFO_BEGIN';
export const SAVE_USER_INFO_SUCCESS = 'SAVE_USER_INFO_SUCESS';
export const SAVE_TAX_INFO_SUCCESS = 'SAVE_TAX_INFO_SUCCESS';
export const SAVE_USER_INFO_FAIL = 'SAVE_USER_INFO_FAIL';
export const SAVE_USER_INFO_CONSTANTS = {
  registerType: 'RegisteredPerson',
  editRegistration: 'Y',
  profileUpdate: 'true'
};
export const COMMON_OPT_IN_EMAIL_MARKETING_BEGIN =
  'COMMON_OPT_IN_EMAIL_MARKETING_BEGIN';
export const COMMON_OPT_IN_EMAIL_MARKETING_SUCCESS =
  'COMMON_OPT_IN_EMAIL_MARKETING_SUCCESS';
export const COMMON_OPT_IN_EMAIL_MARKETING_FAIL =
  'COMMON_OPT_IN_EMAIL_MARKETING_FAIL';
export const COMMON_OPT_IN_EMAIL_MARKETING_BOT =
  'COMMON_OPT_IN_EMAIL_MARKETING_BOT';
export const GET_FEATURE_INFORMATION_BEGIN = 'GET_FEATURE_INFORMATION_BEGIN';
export const GET_FEATURE_INFORMATION_SUCCESS =
  'GET_FEATURE_INFORMATION_SUCCESS';
export const GET_FEATURE_INFORMATION_FAIL = 'GET_FEATURE_INFORMATION_FAIL';

export const COMMON_SET_TOAST_INFO = 'COMMON_SET_TOAST_INFO';
export const COMMON_SET_TOAST_TYPE = 'COMMON_SET_TOAST_TYPE';

export const ADP_MODAL_STATS_TOGGLE = 'COMMON_ADP_MODAL_STATUS_TOGGLE';
export const CMC_ESPOT_PCC_CATSAS_RIBBON_TOP =
  'CMC_ESPOT_PCC_CATSAS_RIBBON_TOP';
