import PropTypes from 'prop-types';
import { getIconSizeNumber } from '../utils';

const ReceiptIcon = ({ size }) => {
  const sizeNumber = getIconSizeNumber(size);

  return (
    <svg
      fill="none"
      height={sizeNumber}
      viewBox={`0 0 ${sizeNumber} ${sizeNumber}`}
      width={sizeNumber}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 23.25V2.25C21 1.85218 20.842 1.47064 20.5607 1.18934C20.2794 0.908035 19.8978 0.75 19.5 0.75H4.5C4.10218 0.75 3.72064 0.908035 3.43934 1.18934C3.15804 1.47064 3 1.85218 3 2.25V23.25L6 20.25L9 23.25L12 20.25L15 23.25L18 20.25L21 23.25Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6H12"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10.5H12"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 15H12"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.625 10.5C17.4179 10.5 17.25 10.3321 17.25 10.125C17.25 9.91789 17.4179 9.75 17.625 9.75"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M17.625 10.5C17.8321 10.5 18 10.3321 18 10.125C18 9.91789 17.8321 9.75 17.625 9.75"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M17.625 15C17.4179 15 17.25 14.8321 17.25 14.625C17.25 14.4179 17.4179 14.25 17.625 14.25"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M17.625 15C17.8321 15 18 14.8321 18 14.625C18 14.4179 17.8321 14.25 17.625 14.25"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M17.625 6C17.4179 6 17.25 5.83211 17.25 5.625C17.25 5.41789 17.4179 5.25 17.625 5.25"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M17.625 6C17.8321 6 18 5.83211 18 5.625C18 5.41789 17.8321 5.25 17.625 5.25"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
};

ReceiptIcon.propTypes = {
  size: PropTypes.string
};

export default ReceiptIcon;
