import { ACTIONS } from './constants';

const reducer = (state, action) => {
  if (action.type === ACTIONS.FIRST_STORE_ID) {
    return {
      ...state,
      firstStoreId: action.payload
    };
  } else if (action.type === ACTIONS.KEEP_STORE_SELECTION) {
    return {
      ...state,
      keepStoreSelection: action.payload
    };
  } else if (action.type === ACTIONS.SCROLL_TO_STORE) {
    return {
      ...state,
      scrollToStore: action.payload
    };
  }

  return state;
};

export default reducer;
