import { HEADER } from '@app/constants/targets';
import EquipmentToast from './EquipmentToast';
import { Portal } from '@cat-ecom/pcc-components';

const Container = () => {
  return (
    <Portal id={HEADER}>
      <EquipmentToast />
    </Portal>
  );
};

export default Container;
