// Template Identifiers
export const SINGLE_COLUMN_TEMPLATE = 'REACT_SingleColumn';

// Component Identifiers
export const ESPOT_COMPONENT = 'EMarketingSpot_CATPCC';
export const MY_EQUIPMENT_COMPONENT = 'REACT_MyEquipment_CATPCC';
export const PM_KIT_WIDGET_COMPONENT = 'REACT_PMKit_MEQ_CATPCC';
export const PRODUCTCARD_COMPONENT = 'REACT_ProductCards_CATPCC';
export const YOUTUBE_COMPONENT = 'REACT_Youtube_CATPCC';
// Constants
export const EMS_NAME_KEY = 'emsName';
export const FLEX_ALIGNMENT = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};
export const GA_TRACKING_TEMPLATE_NAME = 'PCC CMC Marketing';
export const GA_TRACKING_MARKETING_PRODUCT_CARD = 'PCC CMC Campaign Page';
export const MARKETING_PRODUCT_ORDERBY = 0;
export const MARKETING_PRODUCT_PAGE_SIZE = 16;
export const MARKETING_PAGE_UNIQUEID = 'MARKETING';
