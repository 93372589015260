import * as productTypes from '../products/constants';
import { marketingInitialStore } from './initialStore';
import * as types from './constants';

const marketingLandingPageUI = (state = marketingInitialStore, action) => {
  switch (action.type) {
    case types.MARKETING_SET_PAGE_NUMBER: {
      const { widgetId, pageNumber } = action.payload;
      return {
        ...state,
        [widgetId]: {
          ...state[widgetId],
          pageNumber: pageNumber
        }
      };
    }
    case productTypes.MARKETING_PRODUCTS_PRICE_SET_STATUS: {
      const { status, widgetId } = action.payload;
      return {
        ...state,
        [widgetId]: {
          ...state[widgetId],
          getMarketingProductPriceStatus: status
        }
      };
    }
    case types.MARKETING_SET_PRODUCT_CARD_EXPANDED: {
      const { productCardExpandedArr, widgetId } = action.payload;
      return {
        ...state,
        [widgetId]: {
          ...state[widgetId],
          productCardExpandedArr
        }
      };
    }
    case types.MARKETING_SET_PART_NUMBER: {
      const { partNumber, widgetId } = action.payload;
      return {
        ...state,
        [widgetId]: {
          ...state[widgetId],
          partNumbers: partNumber
        }
      };
    }
    case types.MARKETING_QUERY_PARAM: {
      const { widgetId, data, ...params } = action.payload;
      return {
        ...state,
        ...params,
        [widgetId]: {
          ...state[widgetId],
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default marketingLandingPageUI;
export { marketingInitialStore };
