export const servicesOffered = [
  'SERVICES_MACHINE',
  'SERVICES_ENGINE_SERVICE',
  'SERVICES_POWER_SYSTEMS',
  'SERVICES_RENTALS',
  'SERVICES_EQUIPT_SALES',
  'SERVICES_EQUIPT_SERVICE',
  'SERVICES_PARTS_PICKUP',
  'SERVICES_PARTS_SALES',
  'SERVICES_MARINE',
  'SERVICES_TECHNOLOGY_SOLUTIONS'
];
