import { useContext, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  AlloyButton,
  ChevronRightIcon,
  DeleteIcon,
  ModalContext
} from 'cat-ecommerce-alloy';
import CurrentEquipmentElement from './CurrentEquipmentElement';
import styles from './CurrentEquipmentModal.module.scss';
import meStyles from '../MyEquipment.module.scss';
import { singleEquipmentPropType } from '../propTypesDeclarations';
import SerialSuggestions from './SerialSuggestions';
import FindMySerial from '../AddEquipmentModal/FindMySerial';
import {
  PAGE_BREAKPOINTS,
  STATUS,
  USER_TYPE_GUEST
} from '@app/constants/commonConstants';
import { useBreakpoint, useHttp } from '@app/hooks';
import LabelValue from '@app/components/common/LabelValue/LabelValue';
import { replaceTokensInString } from '@app/utils';
import endpoints from '@app/constants/endpoints';
import InfoMessageBox from '@app/components/common/InfoMessageBox';
import DealerNotificationToggle from '../EditModal/DealerNotificationToggle';
import { ME_MODAL_COMPONENT_TYPE } from '@app/constants/myEquipmentConstants';
import cx from 'classnames';

const { EDIT_MODAL, VIEW_MODAL } = ME_MODAL_COMPONENT_TYPE;

const CurrentEquipmentModal = ({
  componentType,
  currentEquipment,
  className,
  toggleSerialNumError,
  setToggleSerialNumError,
  setFieldError,
  handleRemoveEquipment,
  inputValue,
  setInputValue,
  formikError,
  serialNumValidationStatus,
  isEditing,
  openSerialMismatch,
  isTempEquipmentMessage
}) => {
  const { invoke, data, loading: isLoadingSuggestions } = useHttp();
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSerialNumberSuggestion, setShowSerialNumberSuggestion] =
    useState(false);
  const serialNumberError = useSelector(
    state => state.myEquipment?.serialNumberError
  );
  const langId = useSelector(state => state.common?.langId);
  const storeId = useSelector(state => state.common?.storeId);
  const { model, productId } = currentEquipment ?? {};
  const { prefixList } = data ?? {};
  const isCSPCustomer = useSelector(state => state.common?.isCSPCustomer);
  const validSerialMessage = 'VALID_SERIAL_NUMBER_MEQ';
  const tempSerialMessage = 'TEMPORARY_RECORD';
  const saveBtnDisabled =
    serialNumValidationStatus === STATUS.PENDING ||
    serialNumValidationStatus === STATUS.REJECTED
      ? true
      : false;
  const { isQRCodeTempEquipment } = currentEquipment;
  const userType = useSelector(state => state.common?.userType);
  const isGuestUser = userType === USER_TYPE_GUEST;

  const isViewModal = componentType === VIEW_MODAL;
  const shouldShowDealerNotification = !isEditing && isCSPCustomer;

  const { t } = useTranslation();
  let serialRef = useRef();

  useEffect(() => {
    currentEquipment.serialNumber &&
      setInputValue &&
      setInputValue(currentEquipment.serialNumber);
  }, [currentEquipment.serialNumber, setInputValue]);

  useEffect(() => {
    if (Array.isArray(prefixList)) {
      const suggestions = prefixList.filter(prefix =>
        prefix.includes(inputValue)
      );
      setFilteredSuggestions(suggestions);
    }
  }, [prefixList, inputValue]);

  useEffect(() => {
    const url = replaceTokensInString(
      endpoints.SERIAL_PREFIX_SUGGESTIONS,
      storeId,
      model,
      productId,
      langId,
      storeId
    );

    invoke({ url });
  }, [model, productId, invoke, langId, storeId]);

  useEffect(() => {
    let handler = event => {
      if (!serialRef.current.contains(event.target)) {
        setShowSerialNumberSuggestion(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);
  const modalCtx = useContext(ModalContext);
  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);
  const findMySerialButtonLabel = t(
    isMD ? 'FIND_MY_SERIAL_NUMBER' : 'MEQ_FIND_SERIAL'
  );

  return (
    <div
      className={`d-lg-flex z-index-1 mt-4 mt-md-0 ${styles['equipment-modal__info']} ${className}`}
    >
      <div className={`${styles['equipment-modal__image-box']}`}>
        <img
          className={`${styles['equipment-modal__image']}`}
          alt={`${currentEquipment.model} ${currentEquipment.equipmentFamily}`}
          src={
            currentEquipment.imageURL &&
            `${currentEquipment.imageURL}?hei=240&wid=240&qlt=85,0&resMode=sharp2&op_usm=1.75,0.3,2,0`
          }
        />

        {currentEquipment.imageURL &&
          currentEquipment.imageURL.includes('CM20190709-5e51b-85605') && (
            <div className="d-none d-lg-block">{t('IMAGE_NOT_AVAILABLE')}</div>
          )}
      </div>
      <div
        className={`ms-lg-3 mb-0 w-100 ms-auto pt-3 ${styles['equipment-modal__content']}`}
      >
        <div className={`${styles['equipment-modal__modelWrapper']}`}>
          <LabelValue
            title={t('MODEL&FAMILY')}
            value={`${currentEquipment.model} ${currentEquipment.equipmentFamily}`}
          />
        </div>
        <div className="position-relative">
          <div
            className={`text-univers--bold color-dark-gray text-uppercase ${styles['equipment-modal__attribute']}`}
          >
            {t('ASSET_ID')}
            <span
              className={`text-univers text-transform-none ${styles['equipment-modal__attribute--opt']}`}
            >
              &nbsp;
              {t('OPTIONAL')}
            </span>
          </div>
          <div
            className={`mb-2 position-relative ${styles['equipment-modal__value']}`}
          >
            <CurrentEquipmentElement
              condition={componentType}
              inputType="assetId"
              attribute="assetId"
              currentEquipment={currentEquipment}
              setFieldError={setFieldError}
            >
              {currentEquipment.assetId}
            </CurrentEquipmentElement>
          </div>
        </div>
        <div className="position-relative">
          <div
            className={cx('d-flex align-items-end justify-content-between', {
              'flex-row-reverse': !isViewModal
            })}
          >
            {componentType === EDIT_MODAL && (
              <AlloyButton
                buttonType="ghost"
                className={`float-end d-flex align-items-center text-end justify-content-end text-sm text-univers--bold p-0 bg-none ${styles['equipment-modal__serial-link']}`}
                onClick={() =>
                  modalCtx.addModal(<FindMySerial />, findMySerialButtonLabel)
                }
              >
                {findMySerialButtonLabel}
                <ChevronRightIcon width={19} />
              </AlloyButton>
            )}
            <div
              className={`text-univers--bold color-dark-gray text-uppercase ${styles['equipment-modal__attribute']}`}
            >
              {t('CAT_ORDER_SERIAL_NO')}
            </div>
          </div>
          <div
            className={`mb-2 position-relative ${styles['equipment-modal__value']}`}
          >
            <CurrentEquipmentElement
              condition={componentType}
              inputType="serialNumber"
              attribute="serialNumber"
              hideLabel
              setToggleSerialNumError={setToggleSerialNumError}
              currentEquipment={currentEquipment}
              setFieldError={setFieldError}
              setShowSerialNumberSuggestion={setShowSerialNumberSuggestion}
              inputValue={inputValue}
              setInputValue={setInputValue}
              showSerialNumberSuggestion={showSerialNumberSuggestion}
              formikError={formikError}
              serialNumValidationStatus={serialNumValidationStatus}
              isEditing={isEditing}
              openSerialMismatch={openSerialMismatch}
            >
              {currentEquipment.serialNumber}
            </CurrentEquipmentElement>
          </div>

          <div ref={serialRef}>
            {showSerialNumberSuggestion &&
              !serialNumberError &&
              serialNumValidationStatus === STATUS.IDLE && (
                <SerialSuggestions
                  loading={isLoadingSuggestions}
                  results={filteredSuggestions}
                  setInputValue={setInputValue}
                  inputValue={inputValue}
                  setShowSerialNumberSuggestion={setShowSerialNumberSuggestion}
                />
              )}
          </div>
          {toggleSerialNumError && (
            <div>
              <span className={`${styles['equipment-modal_toggle_sn_error']}`}>
                {toggleSerialNumError}
              </span>
            </div>
          )}
        </div>
        {!isViewModal && (
          <div className="position-relative">
            <div className={`d-flex ${meStyles['equipment-modal__actions']}`}>
              <div
                className={`w-100 ${meStyles['equipment-modal__action-links']}`}
              >
                <div className="d-flex flex-column pb-3">
                  {!currentEquipment.serialNumber && (
                    <InfoMessageBox>
                      <p className="u-color--DarkGray u-fs--14">
                        {t(validSerialMessage)}
                      </p>
                    </InfoMessageBox>
                  )}
                  {isEditing && isGuestUser && (
                    <div className="position-relative">
                      {!currentEquipment.serialNumber && (
                        <hr
                          className={cx(
                            meStyles['equipment-modal__divider'],
                            'border-top-1 border-top-LightGray position-absolute'
                          )}
                        />
                      )}
                      <InfoMessageBox>
                        <p className="u-color--DarkGray u-fs--14">
                          {t(tempSerialMessage)}
                        </p>
                      </InfoMessageBox>
                    </div>
                  )}
                  {isTempEquipmentMessage && isQRCodeTempEquipment && (
                    <InfoMessageBox>
                      <p className="u-color--DarkGray u-fs--14">
                        {t(tempSerialMessage)}
                      </p>
                    </InfoMessageBox>
                  )}
                </div>
                {shouldShowDealerNotification && (
                  <DealerNotificationToggle
                    currentEquipment={currentEquipment}
                  />
                )}

                {!isViewModal && (
                  <AlloyButton
                    size="dynamic"
                    buttonType="secondary"
                    title={t('MEQ_SAVE_EQUIP')}
                    type="submit"
                    className={`col-sm-12 col-md-6 text-sm p-2 mb-3 ${meStyles['equipment-modal__action-save']}`}
                    disabled={saveBtnDisabled}
                  >
                    {t('MEQ_SAVE_EQUIP')}
                  </AlloyButton>
                )}

                {isEditing && (
                  <AlloyButton
                    buttonType="ghost"
                    className={`ps-md-0 fw-bold d-flex align-items-center text-univers text-uppercase border-0 mb-3 ${meStyles['equipment-modal__remove-link']}`}
                    onClick={() => handleRemoveEquipment()}
                  >
                    <DeleteIcon
                      width={13}
                      className={`${meStyles['equipment-modal-icon__link']}`}
                    />
                    {t('MEQ_REMOVE_EQUIP')}
                  </AlloyButton>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

CurrentEquipmentModal.propTypes = {
  className: PropTypes.string,
  componentType: PropTypes.oneOf([EDIT_MODAL, VIEW_MODAL]),
  currentEquipment: singleEquipmentPropType.isRequired,
  setFieldError: PropTypes.func,
  handleRemoveEquipment: PropTypes.func,
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  setToggleSerialNumError: PropTypes.func,
  toggleSerialNumError: PropTypes.string,
  formikError: PropTypes.shape({
    serialNumber: PropTypes.string
  }),
  serialNumValidationStatus: PropTypes.string,
  isEditing: PropTypes.bool,
  isTempEquipmentMessage: PropTypes.bool,
  openSerialMismatch: PropTypes.func
};

export default CurrentEquipmentModal;
