import PurifiedHtml from '@app/components/common/PurifiedHtml';
import React from 'react';

const useReplaceTokenWithElement = () => {
  const token = /\{\d+\}/;

  const replaceTokenWithElement = (
    message = '',
    className = '',
    ...elements
  ) => {
    const split = message.split(token);
    return split.map((messageSlice, index) => {
      const elementKey = `${messageSlice}_${index}`;
      return (
        <React.Fragment key={elementKey}>
          {!!split[index] ? (
            <span className={className} data-testid="span">
              <PurifiedHtml htmlString={messageSlice} as="span" />
            </span>
          ) : null}
          {!!elements[index] ? elements[index] : null}
        </React.Fragment>
      );
    });
  };
  return { replaceTokenWithElement };
};

export default useReplaceTokenWithElement;
