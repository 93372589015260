import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { servicesOffered } from './constants';
import Field from '../../common/form/Field';
import { Formik, Form } from 'formik';
import { useSystemFeedback } from '@app/hooks';
import { areServiceFiltersSelected, getFilteredDealerStores } from '../utils';
import cx from 'classnames';
import {
  AlloyButton,
  Checkbox,
  CheckboxGroup,
  CloseIcon,
  Dropdown,
  ModalContext,
  StickySection
} from 'cat-ecommerce-alloy';
import ExceptionFeedbackBySelector from '../../common/Exception/ExceptionFeedbackBySelector';
import { ERROR_PATH, ERROR_DOMAIN } from '../../../constants/errorConstants';
import { dealerlocatorResultsPropTypes } from '../declarations.proptypes';
import PropTypes from 'prop-types';
import { DISTANCE, DEALER_NAME } from '../constants';

const DealerLocatorFilterModal = ({ dealerStores, filters, setFilters }) => {
  const errorSelector = state =>
    state?.errors?.[ERROR_DOMAIN.DEALER_LOCATOR]?.[ERROR_PATH.FILTERS];
  const [t] = useTranslation();
  const { setError, clearError } = useSystemFeedback();
  const { removeCurrentModal } = useContext(ModalContext);

  const sortOptions = {
    [t('FYD_SORT_CLOSEST')]: DISTANCE,
    [t('CAT_EMAIL_DEALERNAME')]: DEALER_NAME
  };
  const sortOptionsDisplayNames = Object.keys(sortOptions);

  const initialFilters = servicesOffered.reduce(
    (prev, currentService) => {
      return {
        ...prev,
        [t(currentService)]: false
      };
    },
    { order_filter_dropdown: sortOptionsDisplayNames[0] }
  );
  const clearNoDealersException = () =>
    clearError(ERROR_DOMAIN.DEALER_LOCATOR, ERROR_PATH.FILTERS);

  const handleClickResetForm = resetForm => {
    resetForm({
      values: initialFilters
    });
    clearNoDealersException();
  };

  const displayNoDealersException = () =>
    setError(ERROR_DOMAIN.DEALER_LOCATOR, ERROR_PATH.FILTERS, {
      severity: 'error',
      message: `${t('FYD_NO_RESULTS')} ${t('FYD_ADJUST_SEARCH')}`
    });

  const onSubmit = formValues => {
    const sortOrder =
      sortOptions[formValues['order_filter_dropdown']] || DISTANCE;
    const selectedFilters = { ...formValues, sortOrder };

    if (areServiceFiltersSelected(selectedFilters)) {
      const filteredDealerStores = getFilteredDealerStores(
        dealerStores,
        selectedFilters
      );
      if (filteredDealerStores.length === 0) {
        // If no dealers available after applying filters
        // show ExceptionFeedback and do not save filters
        return displayNoDealersException();
      }
    }
    setFilters(selectedFilters);
    removeCurrentModal();
  };

  const validate = () => {
    clearNoDealersException();
    return {};
  };

  return (
    <div>
      <h2 className="exclude-global h2 display-1 mb-4">
        {t('Filter_And_Sort')}
      </h2>
      <ExceptionFeedbackBySelector
        messageClassName={'mb-3'}
        selector={errorSelector}
        skipTranslation={true}
      />
      <Formik
        initialValues={filters ?? initialFilters}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ resetForm }) => {
          return (
            <Form name="dealer-locator-filter__form">
              <div className="d-flex flex-column">
                <span className="flex-grow-1">
                  <Field
                    type="dropdown"
                    as={Dropdown}
                    id={'order_filter_dropdown'}
                    name={'order_filter_dropdown'}
                    items={sortOptionsDisplayNames}
                    label={t('SORT')}
                  />
                </span>
                <div className="form-group mb-0">
                  <CheckboxGroup
                    className="mb-0"
                    label={t('FYD_FILTER_SERVICES_OFFERED')}
                  >
                    {servicesOffered.map(service => {
                      return (
                        <Field
                          type="checkbox"
                          as={Checkbox}
                          id={t(service)}
                          key={t(service)}
                          name={t(service)}
                          label={t(service)}
                        />
                      );
                    })}
                  </CheckboxGroup>
                </div>
                <AlloyButton
                  buttonType="ghost"
                  className={cx(
                    'd-inline-flex',
                    'align-items-center',
                    'px-0',
                    'align-self-start'
                  )}
                  size="large"
                  onClick={() => handleClickResetForm(resetForm)}
                  name="dealer-locator-filter__clear-all-button"
                >
                  <CloseIcon width={16} height={16} className="me-2" />
                  {t('CLEAR_ALL_FILTERS')}
                </AlloyButton>
                <StickySection
                  className={cx(
                    'text-center',
                    'bg-white',
                    'z-index-1',
                    'border-top',
                    'border-cat-secondary'
                  )}
                >
                  <AlloyButton
                    buttonType="secondary"
                    className="my-4"
                    size="large"
                    type="submit"
                    name="dealer-locator-filter__apply-filter-button"
                  >
                    {t('APPLY_CHANGES')}
                  </AlloyButton>
                </StickySection>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DealerLocatorFilterModal;

DealerLocatorFilterModal.propTypes = {
  dealerStores: PropTypes.arrayOf(dealerlocatorResultsPropTypes),
  filters: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ),
  setFilters: PropTypes.func.isRequired
};
