import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { replaceTokensInString } from '../../../../../utils';
import {
  ComponentLoader,
  SystemFeedback,
  AlloyButton,
  ModalContext,
  CloseIcon
} from 'cat-ecommerce-alloy';
import MyEquipmentTitle from '../../MyEquipmentTitle';
import appStyles from './SerialMismatch.module.scss';
import useEquipmentSearch from '../../../../../hooks/useEquipmentSearch';
import { setError } from '../../../../../store/exception/actions';
import {
  ERROR_DOMAIN,
  ERROR_PATH
} from '../../../../../constants/errorConstants';
import ExceptionFeedbackBySelector from '../../../../common/Exception/ExceptionFeedbackBySelector';
import {
  addOrUpdateEquipmentInWishList,
  clearErrors
} from '@app/store/myequipment/actions';
import { useAnalytics } from '@app/hooks';
import {
  FORM_AD_EQ_UPDATE_EQUIPMENT,
  STATUS_SUCCESS
} from '@app/constants/analyticsConstants';

const SerialMismatch = ({
  toastContent,
  isEditing,
  formData,
  widgetIdentifier
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modalctx = useContext(ModalContext);
  const isLoading = useSelector(state => state.myEquipment.isLoading);
  const [serialError, setSerialError] = useState('');
  const { fireFormSubmittedEvent } = useAnalytics();
  const { searchResults, error, showLoading } = useEquipmentSearch(
    formData.serialNumber
  );

  const { model, imageURL, equipmentFamily } =
    !showLoading && searchResults.results?.length && searchResults.results[0];

  useEffect(() => {
    if (error) {
      dispatch(
        setError(
          ERROR_DOMAIN.MY_EQUIPMENT,
          ERROR_PATH.SERIAL_MISMATCH_MODAL,
          error
        )
      );
    }
  }, [dispatch, error]);

  const setSerialErrorMessage = (_, message) => {
    setSerialError(message);
  };

  const handleEquipmentSave = () => {
    const formSubmittedEventPayload = {
      event: 'formSubmitted',
      pagePath: document.location.pathname,
      formName: FORM_AD_EQ_UPDATE_EQUIPMENT,
      formContent: formData.serialNumber,
      formContent2: `${formData.model} ${formData.equipmentFamily}`,
      formFieldCausingError: '',
      formLocation: '',
      formStatus: STATUS_SUCCESS
    };

    dispatch(
      addOrUpdateEquipmentInWishList(
        searchResults.results.length && {
          ...searchResults.results[0],
          assetId: formData.assetId,
          serialNumber: formData.serialNumber,
          notifyDealer: formData.notifyDealer
        },
        setSerialErrorMessage,
        undefined,
        undefined,
        isEditing,
        toastContent,
        fireFormSubmittedEvent,
        formSubmittedEventPayload,
        widgetIdentifier
      )
    );
  };

  useEffect(() => {
    return () => dispatch(clearErrors());
  }, [dispatch]);

  return (
    <div className={`${appStyles['serial-mismatch']}`}>
      <MyEquipmentTitle
        title={t('MEQ_MODEL_SERIAL_VERIFICATION4')}
        componentType="modal"
      />
      {(isLoading || showLoading) && <ComponentLoader />}
      <ExceptionFeedbackBySelector
        selector={state =>
          state?.errors?.[ERROR_DOMAIN.MY_EQUIPMENT]?.[
            ERROR_PATH.SERIAL_MISMATCH_MODAL
          ]
        }
      />
      <div className="mt-3">
        <SystemFeedback
          type="warning"
          message={t('VALID_SN_DIFF_MACHINE')}
          className={`${appStyles['serial-mismatch__warning']}`}
        />
      </div>
      <p className="mt-3">
        {replaceTokensInString(
          t('MEQ_MODEL_VERIFICATION1'),
          formData.serialNumber || 'XXXXXXXX'
        )}
      </p>
      <h6 className="mb-3 mt-3 color-dark-gray">
        {t('MEQ_MODEL_SERIAL_Verification2')}
      </h6>
      <hr className="m-0" />
      <div className={`${appStyles['serial-mismatch__new-match']}`}>
        <p className="d-flex align-items-center">
          <span className="d-flex m-0">
            <img
              src={imageURL ? `${imageURL}?$pcc-minicart$` : ''}
              alt={equipmentFamily ? model + equipmentFamily : model}
              loading="lazy"
              className="w-75 h-75"
            />
          </span>
          <span className="d-flex m-0">
            {equipmentFamily ? model + equipmentFamily : model}
          </span>
        </p>
      </div>
      <hr className="m-0" />
      {serialError && (
        <span
          className={`d-flex m-0 u-Dark--Red ${appStyles['serial-mismatch__error-message']}`}
        >
          {serialError}
        </span>
      )}
      <div
        className={`flex-column-reverse d-flex justify-content-end p-md-4 px-md-0 px-lg-0 flex-lg-row ${appStyles['serial-mismatch-btn__actions']}`}
      >
        <AlloyButton
          buttonType="ghost"
          size="small"
          className={`me-md-4 position-relative ${appStyles['serial-mismatch__cancel-link']}`}
          onClick={() => modalctx.removeCurrentModal()}
        >
          <CloseIcon
            fill="#2677B7"
            width={15}
            className={`mb-1 me-1 ${appStyles['serial-mismatch__cancel-icon']}`}
          />
          {t('CANCEL')}
        </AlloyButton>
        <AlloyButton
          buttonType="secondary"
          size="small"
          className={`mb-3 ${appStyles['serial-mismatch__save-link']}`}
          onClick={() => handleEquipmentSave()}
        >
          {t('MEQ_MODEL_SERIAL_Verification3')}
        </AlloyButton>
      </div>
    </div>
  );
};

SerialMismatch.propTypes = {
  toastContent: PropTypes.string,
  isEditing: PropTypes.bool,
  formData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
  ),
  widgetIdentifier: PropTypes.string
};

export default SerialMismatch;
