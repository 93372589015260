import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CatToast } from '@blocks-react/components/Toast';
import { CatHeading } from '@blocks-react/components/Heading';
import { CatIconSuccess } from '@blocks-react/components/Icons/Success';
import { TOAST_TYPE, USER_TYPE_GUEST } from '@app/constants/commonConstants';
import {
  DELAY_VERIFIED_TOAST_MESSAGE_IN_SECONDS,
  BANNER_TOASTS
} from '../constants';
import BannerToastOnce from '../BannerToastOnce';

const CSRInstantAccessToast = () => {
  const isInstantAccess = useSelector(s => s.common.isInstantAccess) || false;
  const isBuyOnBehalf = useSelector(s => s.common.isBuyOnBehalf) || false;
  const isFlagCustomerSupportCSRBannerMessagingEnabled =
    useSelector(
      state => state?.featureFlag?.PCC_CustomerSupportCSRBannerMessaging
    ) || false;
  const userType = useSelector(s => s.common?.userType) || false;

  const isCSPCustomer = useSelector(s => s.common.isCSPCustomer) || false;
  const [showToastContainer, setShowToastContainer] = useState(false);

  useEffect(() => {
    setShowToastContainer(false);

    const isGuestUser = userType === USER_TYPE_GUEST;

    if (isGuestUser || !isInstantAccess || !isBuyOnBehalf || isCSPCustomer) {
      return;
    }

    setShowToastContainer(true);
  }, [isBuyOnBehalf, isInstantAccess]);

  if (!showToastContainer || isFlagCustomerSupportCSRBannerMessagingEnabled) {
    return;
  }

  return (
    <BannerToastOnce name={BANNER_TOASTS.instantAccess.name}>
      <CatToast
        variant={TOAST_TYPE.SUCCESS}
        delay={DELAY_VERIFIED_TOAST_MESSAGE_IN_SECONDS}
        autoClose
      >
        <CatIconSuccess slot="before" />
        <CatHeading variant="label-sm">
          {BANNER_TOASTS.instantAccess.message}
        </CatHeading>
      </CatToast>
    </BannerToastOnce>
  );
};

export default CSRInstantAccessToast;
