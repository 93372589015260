import { STATUS } from '../../constants/commonConstants';
import * as types from './constants';
import { mlpInitialStore } from './initialStore';

const MLPReducer = (state = mlpInitialStore, action) => {
  switch (action.type) {
    case types.MLP_LOAD_EQUIPMENTDETAILS_BEGIN: {
      return {
        ...state,
        equipmentDetails: {
          data: {},
          isLoading: true,
          status: STATUS.PENDING
        }
      };
    }
    case types.MLP_LOAD_EQUIPMENTDETAILS_SUCCESS: {
      const { equipmentDetails } = action.payload;
      return {
        ...state,
        equipmentDetails: {
          data: equipmentDetails,
          isLoading: false,
          status: STATUS.RESOLVED
        }
      };
    }
    case types.MLP_LOAD_EQUIPMENTDETAILS_FAIL: {
      return {
        ...state,
        equipmentDetails: {
          data: {},
          isLoading: false,
          status: STATUS.REJECTED
        }
      };
    }
    case types.MLP_LOAD_TOPSELLINGPARTS_BEGIN: {
      return {
        ...state,
        topSellingParts: {
          data: {},
          isLoading: true,
          status: STATUS.PENDING
        }
      };
    }
    case types.MLP_LOAD_TOPSELLINGPARTS_SUCCESS: {
      const { topSellingParts } = action.payload;
      return {
        ...state,
        topSellingParts: {
          data: topSellingParts,
          isLoading: false,
          status: STATUS.RESOLVED
        }
      };
    }
    case types.MLP_LOAD_TOPSELLINGPARTS_FAIL: {
      return {
        ...state,
        topSellingParts: {
          data: {},
          isLoading: false,
          status: STATUS.REJECTED
        }
      };
    }
    case types.MLP_LOAD_CATEGORIES_BEGIN: {
      return {
        ...state,
        partsByCategory: {
          data: [],
          totalCount: 0,
          isLoading: true,
          status: STATUS.PENDING
        }
      };
    }
    case types.MLP_LOAD_CATEGORIES_SUCCESS: {
      const { partsByCategory, totalCount } = action.payload;
      return {
        ...state,
        partsByCategory: {
          data: partsByCategory,
          totalCount: totalCount,
          isLoading: false,
          status: STATUS.RESOLVED
        }
      };
    }
    case types.MLP_LOAD_CATEGORIES_FAIL: {
      return {
        ...state,
        partsByCategory: {
          data: [],
          totalCount: 0,
          isLoading: false,
          status: STATUS.REJECTED
        }
      };
    }
    case types.MLP_LOAD_SSRO_BEGIN: {
      return {
        ...state,
        ssroOptions: {
          data: { ...state.ssroOptions.data, loaded: false },
          isLoading: true,
          status: STATUS.PENDING
        }
      };
    }
    case types.MLP_LOAD_SSRO_PM_KIT_BEGIN: {
      return {
        ...state,
        pmKitOptions: {
          ...state.pmKitOptions,
          isLoading: true,
          status: STATUS.PENDING
        }
      };
    }
    case types.MLP_LOAD_SSRO_SERIAL_SUCCESS:
    case types.MLP_LOAD_SSRO_MODEL_SUCCESS: {
      const { isPMKit } = action.payload.bundleData;
      if (isPMKit) {
        return {
          ...state,
          pmKitOptions: {
            data: {
              ...state.pmKitOptions.data,
              ...action.payload,
              loaded: true
            },
            isLoading: false
          }
        };
      } else {
        return {
          ...state,
          ssroOptions: {
            data: {
              ...state.ssroOptions.data,
              ...action.payload,
              loaded: true
            },
            isLoading: false
          }
        };
      }
    }
    case types.MLP_LOAD_SSRO_FAIL: {
      const { payload = { isPMKit: false } } = action;
      if (payload.isPMKit) {
        return {
          ...state,
          pmKitOptions: {
            data: {
              ssroBundlesExist: null,
              bundleData: {},
              loaded: true
            },
            isLoading: false
          }
        };
      } else {
        return {
          ...state,
          ssroOptions: {
            data: {
              ssroBundlesExist: null,
              bundleData: {},
              loaded: true
            },
            isLoading: false
          }
        };
      }
    }
    case types.PMKIT_EQUIPMENTDETAILS_BEGIN: {
      return {
        ...state,
        equipmentList: {
          data: {},
          isEquipmentLoading: true,
          isLoaded: false,
          status: STATUS.PENDING
        }
      };
    }
    case types.PMKIT_EQUIPMENTDETAILS_SUCCESS: {
      return {
        ...state,
        equipmentList: {
          data: action.payload,
          isEquipmentLoading: false,
          isLoaded: true,
          status: STATUS.RESOLVED
        }
      };
    }
    case types.PMKIT_EQUIPMENTDETAILS_FAIL: {
      return {
        ...state,
        equipmentList: {
          data: {},
          isEquipmentLoading: false,
          isLoaded: false,
          status: STATUS.REJECTED
        }
      };
    }
    case types.LUCID_BEST_SELLER_BEGIN: {
      return {
        ...state,
        getBestSellerStatus: STATUS.PENDING,
        getBestSellerDetailsError: undefined
      };
    }
    case types.LUCID_BEST_SELLER_FAIL: {
      const { payload } = action;
      return {
        ...state,
        getBestSellerDetailsStatus: STATUS.REJECTED,
        getBestSellerDetailsError: payload
      };
    }
    case types.LUCID_BEST_SELLER_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        bestSellerDetails: payload,
        getBestSellerDetailsStatus: STATUS.RESOLVED
      };
    }
    default:
      return state;
  }
};
export { mlpInitialStore };
export default MLPReducer;
