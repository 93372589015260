import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useQueryParamRoute } from '@app/hooks';
import { useHistory } from 'react-router';
import { MY_ACCOUNT_SEARCH } from '@app/constants/routes';
import { INVOICES_FLAG } from '../../invoices/constants';
import {
  isInvoiceRoute,
  removeFiltersSessionData
} from '../../invoices/invoiceUtils';

const useInvoiceChecker = route => {
  const hasInvoiceFlag = useSelector(s => s.featureFlag[INVOICES_FLAG]);
  const catalogId = useSelector(state => state.common.catalogId);
  const langId = useSelector(state => state.common.langId);
  const storeId = useSelector(state => state.common.storeId);
  const isCSPCustomer = useSelector(s => s.common.isCSPCustomer);
  const isInvoiceEnabledByUser = useSelector(s => s.common?.isInvoiceEnabled);

  const isInvoiceEnabled =
    hasInvoiceFlag && isCSPCustomer && isInvoiceEnabledByUser;

  if (isInvoiceEnabled && !!route && !isInvoiceRoute(route)) {
    removeFiltersSessionData();
  }

  const setRoute = useQueryParamRoute(useHistory())[1];
  const checkInvoicesPermission = useCallback(() => {
    if (!isInvoiceEnabled) {
      setRoute(null, MY_ACCOUNT_SEARCH.MY_ACCOUNT_SUMMARY, {
        catalogId,
        langId,
        storeId
      });
    }
  }, [catalogId, isInvoiceEnabled, langId, setRoute, storeId]);

  return {
    isInvoiceEnabled,
    checkInvoicesPermission
  };
};

export default useInvoiceChecker;
