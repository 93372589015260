import { NO, YES } from '../../../common/analytics/analyticsConstants';
import { isLogoffPage, getCookie } from '@app/utils';

export const ASSOCIATED_DEALERS_PAGE = 'Associated Dealers Page';
export const CHANGE_ASSOCIATED_DEALERS = 'Change Associated Dealers';

export const getFormContent = (isLoginAction, skipThisStep) => {
  let formContent;
  if (isLoginAction) {
    formContent = skipThisStep ? YES : NO;
  }
  return formContent;
};

export const getGaEvent = (isLoginAction, skipThisStep) => {
  const formName = isLoginAction
    ? ASSOCIATED_DEALERS_PAGE
    : CHANGE_ASSOCIATED_DEALERS;
  const formContent = getFormContent(isLoginAction, skipThisStep);
  return { formName, formContent };
};

export const getLoginFromPage = () => {
  if (getCookie('loginFromHomePage') === 'true') {
    return 'loginFromHomePage';
  } else if (isLogoffPage()) {
    return 'loginFromLogoutPage';
  }
  return '';
};
