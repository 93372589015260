export const CATEGORY_GET_DETAILS_BEGIN = 'CATEGORY_GET_DETAILS_BEGIN';
export const CATEGORY_GET_DETAILS_SUCCESS = 'CATEGORY_GET_DETAILS_SUCCESS';
export const CATEGORY_GET_DETAILS_FAIL = 'CATEGORY_GET_DETAILS_FAIL';

export const LUCID_CATEGORY_GET_DETAILS_BEGIN =
  'LUCID_CATEGORY_GET_DETAILS_BEGIN';
export const LUCID_CATEGORY_GET_DETAILS_SUCCESS =
  'LUCID_CATEGORY_GET_DETAILS_SUCCESS';
export const LUCID_CATEGORY_GET_DETAILS_FAIL =
  'LUCID_CATEGORY_GET_DETAILS_FAIL';

export const CATEGORY_SET_DATA = 'CATEGORY_SET_DATA';
export const CATEGORY_UPDATE_PRODUCTS = 'CATEGORY_UPDATE_PRODUCTS';

export const CATEGORIES_GET_INFO_SUCCESS = 'CATEGORIES_GET_INFO_SUCCESS';
export const CATEGORIES_GET_INFO_FAIL = 'CATEGORIES_GET_INFO_FAIL';

export const GET_HOMEPAGE_CATEGORIES_BEGIN = 'GET_HOMEPAGE_CATEGORIES_BEGIN';
export const GET_HOMEPAGE_CATEGORIES_FAIL = 'GET_HOMEPAGE_CATEGORIES_FAIL';
export const GET_HOMEPAGE_CATEGORIES_SUCCESS =
  'GET_HOMEPAGE_CATEGORIES_SUCCESS';

export const GET_CATEGORIES_NO_CACHE_HEADER = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0'
};
