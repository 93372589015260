import PropTypes from 'prop-types';
import {
  AssociatedDealerDropdown,
  Conditional
} from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import { END_USE_CODE_DROPDOWN_NAME } from '../../constants';
import { EndUseCode } from '../../types';

export const AssociatedDealerEndUseCodeDropdownWrapper = ({
  endUseCodeList,
  selectedValue,
  onSelect
}) => {
  const [t] = useTranslation();
  const label = t('CAT_SHOPPING_PREFERENCES_END_USE_CODE');
  const placeholder = t('CAT_SHOPPING_PREFERENCES_SEL_END_USE_CODE');
  const showEndUseCodeDropdown = endUseCodeList?.length > 0;
  const isReadOnlyValue = endUseCodeList?.length === 1;

  return (
    <Conditional test={showEndUseCodeDropdown}>
      <AssociatedDealerDropdown
        name={END_USE_CODE_DROPDOWN_NAME}
        label={label}
        placeholder={placeholder}
        itemList={endUseCodeList}
        required={true}
        readOnly={isReadOnlyValue}
        selectedValue={selectedValue}
        onSelect={onSelect}
      />
    </Conditional>
  );
};

AssociatedDealerEndUseCodeDropdownWrapper.propTypes = {
  endUseCodeList: PropTypes.arrayOf(EndUseCode),
  selectedValue: PropTypes.string,
  onSelect: PropTypes.func
};
