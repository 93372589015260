import PropTypes from 'prop-types';
import { useBreakpoint } from '@app/hooks';
import { AssociatedDealerSelectCustomerForm } from '../AssociatedDealerSelectCustomerForm';
import { AssociatedDealerTermsAndConditions } from '../../AssociatedDealerTermsAndConditions';
import {
  Conditional,
  useDrawer,
  Modal,
  useModal,
  MessageBanner,
  ModalBody,
  ModalFooter
} from '@cat-ecom/pcc-components';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import styles from '../../styles.module.scss';
import { DealerLocatorModalWrapper } from '@app/components/dealer-locator-react';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import { useTranslation } from 'react-i18next';
import { removeCookie, replaceTokensInString } from '@app/utils';
import { StoreLocation } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { BYPASS } from '../../constants';
import { useState } from 'react';
import { getDealerAssociations } from '@app/store/dealer-association/actions';
import { CatLoadingIndicator } from 'blocks-react/bedrock/components/LoadingIndicator';
import useLogout from '@app/components/login/hooks/useLogout';
import { DEALER_SYSTEM_DOWN } from '@app/components/login/redirect/constants';

export const AssociatedDealerSelectCustomerModal = ({
  modal,
  formTitle,
  formMethods,
  onSubmit,
  onClearSearch,
  dealer,
  customerNumber,
  stores,
  showTryAgainSystemFeedback = false,
  showTryAgainLoader = false
}) => {
  const [t] = useTranslation();
  const [scrollToPrivacyNotice, setScrollToPrivacyNotice] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState('');
  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);
  const dispatch = useDispatch();
  const title = isMD ? t('CAT_MA_ASSOCIATEDDEALERS') : 'ADP';
  const heading = replaceTokensInString(t('GENERAL_BACK_TO'), title);
  const unknownErrorMessage = t('UNKNOWN_ERROR_MESSAGE');
  const unknownErrorTitle = t('UNKNOWN_ERROR_TITLE');
  const tryAgainButton = t('TRY_AGAIN');
  const prefData = useSelector(
    state => state.shoppingPreferences?.shoppingPreferencesData
  );
  const isCSR = useSelector(s => s.common?.isCatCSR) || false;
  const adpSetting =
    prefData?.adpSettings?.find(setting => setting.default === true) ?? {};
  const skipAdp = adpSetting?.value === BYPASS;
  const showSkipThisStep = !(skipAdp || isCSR || dealer);
  const showTryAgainError = showTryAgainSystemFeedback || showTryAgainLoader;

  const tryAgain = () => {
    dispatch(getDealerAssociations());
    removeCookie(DEALER_SYSTEM_DOWN);
  };

  const { logout } = useLogout();
  const dealerLocatorModal = useModal({
    isRequired: false,
    heading,
    backButton: true,
    size: 'md'
  });

  const termsAndConditionsModal = useModal({
    isRequired: true,
    size: 'md'
  });

  const termsAndConditionsDrawer = useDrawer({
    id: 'associated-dealer-terms-and-conditions-drawer',
    size: 'lg'
  });

  return (
    <>
      <Modal
        className={styles['modal']}
        dismissible={false}
        {...modal.modalProps}
        heading={showTryAgainError ? t('CAT_MA_ASSOCIATEDDEALERS') : ''}
      >
        <Conditional test={modal.modalProps.isActive}>
          {showTryAgainError ? (
            <>
              <ModalBody>
                <MessageBanner variant={'error'} heading={unknownErrorTitle}>
                  {unknownErrorMessage}
                  <div slot="after">
                    <CatButton disabled={showTryAgainLoader} onClick={tryAgain}>
                      <span className="d-flex">
                        {tryAgainButton}
                        <Conditional test={showTryAgainLoader}>
                          <CatLoadingIndicator className="ms-2" small={true} />
                        </Conditional>
                      </span>
                    </CatButton>
                  </div>
                </MessageBanner>
              </ModalBody>
              <ModalFooter>
                <CatButton
                  disabled={showTryAgainLoader}
                  className="mt-3"
                  variant="primary"
                  onClick={logout}
                >
                  {t('ADS_CONTINUE_AS_GUEST_BUTTON')}
                </CatButton>
              </ModalFooter>
            </>
          ) : (
            <AssociatedDealerSelectCustomerForm
              formTitle={formTitle}
              formMethods={formMethods}
              onSubmit={onSubmit}
              onClearSearch={onClearSearch}
              handleClose={modal.modalProps.onBlModalClose}
              dealer={dealer}
              customerNumber={customerNumber}
              stores={stores}
              termsAndConditionsDrawer={termsAndConditionsDrawer}
              termsAndConditionsModal={termsAndConditionsModal}
              setScrollToPrivacyNotice={setScrollToPrivacyNotice}
              setShowTermsAndConditions={setShowTermsAndConditions}
              openDealerLocator={dealerLocatorModal.initiatorProps.onClick}
              showPricingWarning={false}
              showSkipThisStep={showSkipThisStep}
            />
          )}
        </Conditional>
      </Modal>
      <AssociatedDealerTermsAndConditions
        formMethods={formMethods}
        modal={termsAndConditionsModal}
        drawer={termsAndConditionsDrawer}
        dealer={dealer}
        scrollToPrivacyNotice={scrollToPrivacyNotice}
        showTermsAndConditions={showTermsAndConditions}
        isModal={true}
      />
      <DealerLocatorModalWrapper modal={dealerLocatorModal} />
    </>
  );
};

AssociatedDealerSelectCustomerModal.propTypes = {
  modal: PropTypes.any,
  formTitle: PropTypes.string,
  formMethods: PropTypes.any,
  drawerTitle: PropTypes.string,
  onClearSearch: PropTypes.func,
  onSubmit: PropTypes.func,
  dealer: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  customerNumber: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  stores: PropTypes.arrayOf(StoreLocation),
  showTryAgainSystemFeedback: PropTypes.bool,
  showTryAgainLoader: PropTypes.bool
};
