import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useBreakpoint, useOneTrust } from '@app/hooks';
import useDealerLocator from '@app/components/dealer-locator';
import { HeaderStore, Portal, useDrawer } from '@cat-ecom/pcc-components';
import { DRAWERS_ROOT } from '@app/constants/targets';
import {
  PAGE_BREAKPOINTS,
  DESKTOP_STORE_LOCATOR_MESSAGE,
  USER_TYPE_GUEST,
  STATUS
} from '@app/constants/commonConstants';
import { returnFocusToNavItem } from '@app/utils/DOMUtils';
import Proptypes from 'prop-types';
import { getLocationAriaLabel } from '@app/utils/commonUtils';
import { isMLP, isCategoryPage, isSearchPage } from '@app/utils';
import capitalize from 'lodash/capitalize';
import Conditional from '@app/components/common/Conditional';
import StoreDrawer from './StoreDrawer/StoreDrawer';
import { AssociatedDealerDrawer } from '@app/components/associated-dealer-block';
import { DealerLocatorWrapper } from '@app/components/dealer-locator-react';
import { startCase, toLower } from 'lodash';

const HeaderStoreWrapper = ({ subLabel, isMobileSearch = false }) => {
  const { t } = useTranslation();
  const isMobile = !useBreakpoint(PAGE_BREAKPOINTS.BLOCKS_LG);
  const [showTooltip, setShowTooltip] = useState(false);
  const userType = useSelector(state => state.common.userType);
  const isGuestUser = userType && userType === USER_TYPE_GUEST;
  const { isOpen } = useDealerLocator();
  const storeName = useSelector(s => s?.store?.name);
  const storeLocationName = useSelector(s => s?.common?.storeLocationName);
  const getCommonVarsStatus = useSelector(s => s.common?.getCommonVarsStatus);
  const storeLocationLoaded =
    storeLocationName && getCommonVarsStatus === STATUS.RESOLVED;
  const city = useSelector(state => state.store?.city) || '';
  const state = useSelector(state => state.store?.state) || '';
  const isCSRUser = useSelector(s => s.common.isCatCSR);

  const { isTargetingAccepted } = useOneTrust();
  const { closeDrawer, drawerProps, initiatorProps, openDrawer, isDrawerOpen } =
    useDrawer({
      onClose: () => returnFocusToNavItem('#selectStore')
    });

  const label = t('CAT_SELECT_STORE');
  const statePrefix = state ? ', ' : '';
  const hasStoreSelected = !!storeName;
  const storeLocation = hasStoreSelected
    ? `${startCase(toLower(city))}${statePrefix}${capitalize(state)}`
    : '';

  const associatedDealerDrawer = useDrawer({
    id: 'blocks-associated-dealer',
    size: 'lg',
    onClose: () => returnFocusToNavItem('#selectStore')
  });

  const dealerLocatorDrawer = useDrawer({
    id: 'blocks-dealer-locator',
    size: 'lg',
    onClose: () => returnFocusToNavItem('#selectStore')
  });

  const isStoreModalOpen =
    isOpen ||
    isDrawerOpen ||
    associatedDealerDrawer.isDrawerOpen ||
    dealerLocatorDrawer.isDrawerOpen;

  const handleAction = () => {
    if (!hasStoreSelected) {
      setShowTooltip(false);
      dealerLocatorDrawer.openDrawer();
    } else {
      openDrawer();
    }
  };

  const storeDrawerProps = {
    drawerProps,
    closeDrawer
  };

  const hasTooltipShown = useMemo(
    () => JSON.parse(localStorage.getItem('storeMessage')) || [],
    []
  );
  const currentPage = useMemo(() => {
    if (isMLP()) {
      return DESKTOP_STORE_LOCATOR_MESSAGE.MLP;
    } else if (isCategoryPage()) {
      return DESKTOP_STORE_LOCATOR_MESSAGE.PLP;
    } else if (isSearchPage()) {
      return DESKTOP_STORE_LOCATOR_MESSAGE.SEARCHPAGE;
    }
    return '';
  }, []);

  useEffect(() => {
    if (
      currentPage &&
      !hasTooltipShown.includes(currentPage) &&
      !hasStoreSelected &&
      !storeLocationLoaded &&
      isGuestUser &&
      isTargetingAccepted &&
      !isMobile
    ) {
      setShowTooltip(true);
      localStorage.setItem(
        'storeMessage',
        JSON.stringify([...hasTooltipShown, currentPage])
      );
    }
  }, [
    currentPage,
    hasTooltipShown,
    hasStoreSelected,
    storeLocationLoaded,
    isGuestUser,
    isTargetingAccepted,
    isMobile
  ]);

  return (
    <Conditional test={!isCSRUser}>
      <HeaderStore
        id="selectStore"
        label={storeName ?? label}
        subLabel={storeLocation || subLabel}
        action={handleAction}
        isMobile={isMobile}
        handleDrawerProps={hasStoreSelected ? initiatorProps : {}}
        isCurrent={isStoreModalOpen}
        isTooltipActive={showTooltip}
        storeLocatorMessage={t('ALP_DEALER_SECTION_DESCRIPTION')}
        ariaLabel={getLocationAriaLabel(
          storeLocation,
          storeName,
          label,
          t('STORE_NAME'),
          t('LOCATION')
        )}
        isMobileSearch={isMobileSearch}
      />
      <Conditional test={hasStoreSelected}>
        <Portal id={DRAWERS_ROOT}>
          <StoreDrawer
            {...storeDrawerProps}
            openAssociatedDealerDrawer={associatedDealerDrawer.openDrawer}
            openDealerLocatorDrawer={dealerLocatorDrawer.openDrawer}
          />
        </Portal>
      </Conditional>
      <Portal id={DRAWERS_ROOT}>
        <AssociatedDealerDrawer drawer={associatedDealerDrawer} />
        <DealerLocatorWrapper drawer={dealerLocatorDrawer} />
      </Portal>
    </Conditional>
  );
};
HeaderStoreWrapper.propTypes = {
  subLabel: Proptypes.string,
  isMobileSearch: Proptypes.bool
};

export default HeaderStoreWrapper;
