import { useSelector } from 'react-redux';
import {
  TRACTAFRIC_COUNTRIES_HIDE_MAP,
  TRACTAFRIC_DEALER_CODE
} from '../../constants/commonConstants';

const useHideMapsForTractafricDealer = () => {
  const dealerCode = useSelector(s => s.dealer?.dealerCode);
  const isHiddingMap = useSelector(
    s => s.featureFlag?.PCC_HidingMapFeatureFlag
  );
  const countryCode = window.catReact?.akamaiHeaderCountryCode;
  const isTractafricCountry = TRACTAFRIC_COUNTRIES_HIDE_MAP.has(countryCode);

  if (!isHiddingMap) {
    return {
      isMapHiddenForTractafric: false
    };
  }

  const isMapHiddenForTractafric =
    dealerCode === TRACTAFRIC_DEALER_CODE || isTractafricCountry;

  return {
    isMapHiddenForTractafric
  };
};

export default useHideMapsForTractafricDealer;
