export const isFeatureAvailableForUserAndDealer = (
  userCountryCode,
  dealerCountryCode,
  allowedCountries
) => {
  const countryList = allowedCountries.split('|');

  return (
    countryList.includes(userCountryCode) &&
    countryList.includes(dealerCountryCode)
  );
};
