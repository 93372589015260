import { APP_BANNER } from '@app/constants/analyticsConstants';
import { usePersonalizationAnalytics } from '@app/hooks';
import {
  getCurrentPageBasedOnMetaTags,
  isAndroidDevice,
  isIOSDevice
} from '@app/utils/browserUtils';
import { useEffect } from 'react';
import AppBanner from '../AppBanner';
import { CAT_APP_STORE_ANDROID, CAT_APP_STORE_IOS } from '../constants';
import { getCurrentDeviceForAnalytics } from '../utils';

const StaticBanner = () => {
  const {
    fireAppBannerClickEvent,
    fireAppBannerCloseEvent,
    fireAppBannerImpressionEvent
  } = usePersonalizationAnalytics();

  useEffect(() => {
    fireAppBannerImpressionEvent({
      id: APP_BANNER.CAMPAIGN_ID.STATIC,
      name: APP_BANNER.TYPE.STATIC,
      currentPage: getCurrentPageBasedOnMetaTags(),
      deviceType: getCurrentDeviceForAnalytics()
    });
  }, [fireAppBannerImpressionEvent]);

  const handleOpen = () => {
    fireAppBannerClickEvent({
      id: APP_BANNER.CAMPAIGN_ID.STATIC,
      name: APP_BANNER.TYPE.STATIC,
      currentPage: getCurrentPageBasedOnMetaTags(),
      deviceType: getCurrentDeviceForAnalytics()
    });
  };

  const handleClose = () => {
    fireAppBannerCloseEvent({
      id: APP_BANNER.CAMPAIGN_ID.STATIC,
      name: APP_BANNER.TYPE.STATIC,
      currentPage: getCurrentPageBasedOnMetaTags(),
      deviceType: getCurrentDeviceForAnalytics()
    });
  };

  const getUrlToOpen = () => {
    if (isAndroidDevice()) {
      return CAT_APP_STORE_ANDROID;
    } else if (isIOSDevice()) {
      return CAT_APP_STORE_IOS;
    }
  };

  return (
    <AppBanner
      isOpenMode
      openUrl={getUrlToOpen()}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  );
};

export default StaticBanner;
