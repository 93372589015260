import { MODALS_ROOT } from '@app/constants/targets';
import { Portal } from '@cat-ecom/pcc-components';
import AddEquipmentFindSerialDrawer from './AddEquipmentFindSerialDrawer';
const Container = props => {
  return (
    <Portal id={MODALS_ROOT}>
      <AddEquipmentFindSerialDrawer {...props} />
    </Portal>
  );
};

export default Container;
