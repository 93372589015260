import { useMemo } from 'react';
import { useEspot } from '@app/hooks';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MY_ACCOUNT_SEARCH } from '@app/constants/routes';
import {
  CATSAS_SAVED_LIST_eSpot_1,
  CATSAS_SAVED_LIST_eSpot_2
} from '../../saved-list-details/constants';
import {
  CATSAS_NOTIFICATION_PREFERENCES_eSpot_1,
  CATSAS_NOTIFICATION_PREFERENCES_eSpot_2
} from '@app/components/pages/account/notification-preferences/constants';
import {
  ORDERS_TO_APPROVE_TOP_ESPOT,
  MY_ACCOUNT_PENDING_QUOTES_TOP_ESPOT
} from '@app/components/pages/account/constants';
import { AFFILIATION_DEALER } from '@app/constants/commonConstants';
import {
  CATSAS_SHOPPINGPREFERENCES_ESPOT_1,
  CATSAS_SHOPPINGPREFERENCES_ESPOT_2
} from '@app/components/pages/account/shopping-preferences/constants';

const ALL_MY_ACCOUNT_ESPOTS = [
  CATSAS_SAVED_LIST_eSpot_1,
  CATSAS_SAVED_LIST_eSpot_2,
  CATSAS_NOTIFICATION_PREFERENCES_eSpot_1,
  CATSAS_NOTIFICATION_PREFERENCES_eSpot_2,
  MY_ACCOUNT_PENDING_QUOTES_TOP_ESPOT,
  ORDERS_TO_APPROVE_TOP_ESPOT,
  CATSAS_SHOPPINGPREFERENCES_ESPOT_1,
  CATSAS_SHOPPINGPREFERENCES_ESPOT_2
].join();

const useMyAccountEspots = () => {
  const { t } = useTranslation();
  const catalogId = useSelector(s => s?.common?.catalogId);
  const langId = useSelector(s => s?.common?.langId);
  const storeId = useSelector(s => s?.common?.storeId);
  const userAffiliation = useSelector(s => s.common?.userAffiliation);
  const isDealerUser = userAffiliation === AFFILIATION_DEALER;
  const ordersToApproveEspot1 = t('CAL_ESPOT1');
  const ordersToApproveEspot2 = t('CAL_ESPOT2');
  const ordersToApproveEspot3 = t('CAL_ESPOT3');
  const quoteEspot1 = t('QUOTE_ESPOT1');
  const quoteEspot2 = t('QUOTE_ESPOT2');
  const quoteEspot3 = t('QUOTE_ESPOT3');
  const ordersToApproveEspotParam = `&substitutionName1=[CAL_ESPOT1]&substitutionValue1=${ordersToApproveEspot1}&substitutionName2=[CAL_ESPOT2]&substitutionValue2=${ordersToApproveEspot2}&substitutionName3=[QUOTE_ESPOT3]&substitutionValue3=${ordersToApproveEspot3}&substitutionName4=[catalogId]&substitutionValue4=${catalogId}&substitutionName5=[storeId]&substitutionValue5=${storeId}&substitutionName6=[langId]&substitutionValue6=${langId}`;
  const quoteEspotParam = `&substitutionName1=[QUOTE_ESPOT1]&substitutionValue1=${quoteEspot1}&substitutionName2=[QUOTE_ESPOT2]&substitutionValue2=${quoteEspot2}&substitutionName3=[QUOTE_ESPOT3]&substitutionValue3=${quoteEspot3}&substitutionName4=[catalogId]&substitutionValue4=${catalogId}&substitutionName5=[storeId]&substitutionValue5=${storeId}&substitutionName6=[langId]&substitutionValue6=${langId}`;

  const { pathname, search } = useLocation();

  const { route } = queryString.parse(search);
  const currentPage = route ?? pathname;

  let eSpotParams = '';
  eSpotParams =
    currentPage === MY_ACCOUNT_SEARCH.ORDERS_TO_APPROVE
      ? ordersToApproveEspotParam
      : '';
  eSpotParams =
    currentPage === MY_ACCOUNT_SEARCH.PENDINGQUOTES ? quoteEspotParam : '';

  const { espotData, getEspotContent, isEspotLoading } = useEspot(
    ALL_MY_ACCOUNT_ESPOTS,
    eSpotParams
  );

  return useMemo(() => {
    switch (currentPage) {
      case MY_ACCOUNT_SEARCH.LISTS:
        return {
          top: getEspotContent(CATSAS_SAVED_LIST_eSpot_1),
          bottom: getEspotContent(CATSAS_SAVED_LIST_eSpot_2),
          isLoading: isEspotLoading
        };

      case MY_ACCOUNT_SEARCH.NOTIFICATION_PREFERENCES:
        return {
          top: getEspotContent(CATSAS_NOTIFICATION_PREFERENCES_eSpot_1),
          bottom: getEspotContent(CATSAS_NOTIFICATION_PREFERENCES_eSpot_2),
          isLoading: isEspotLoading
        };

      case MY_ACCOUNT_SEARCH.ORDERS_TO_APPROVE:
        return {
          top: getEspotContent(ORDERS_TO_APPROVE_TOP_ESPOT),
          bottom: '',
          isLoading: isEspotLoading
        };

      case MY_ACCOUNT_SEARCH.PENDINGQUOTES:
        return {
          top: !isDealerUser
            ? getEspotContent(MY_ACCOUNT_PENDING_QUOTES_TOP_ESPOT)
            : '',
          bottom: '',
          isLoading: isEspotLoading
        };

      case MY_ACCOUNT_SEARCH.PREFERENCES:
        return {
          top: getEspotContent(CATSAS_SHOPPINGPREFERENCES_ESPOT_1),
          bottom: getEspotContent(CATSAS_SHOPPINGPREFERENCES_ESPOT_2),
          isLoading: isEspotLoading
        };

      default:
        return {
          top: '',
          bottom: '',
          isLoading: false
        };
    }
  }, [espotData, getEspotContent, isEspotLoading, currentPage]);
};

export default useMyAccountEspots;
