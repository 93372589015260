import * as types from './constants';
import { STATUS } from '@app/constants/commonConstants';

const PartsDiagramReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_PARTS_DIAGRAM_BEGIN: {
      return {
        ...state,
        partsDiagramStatus: STATUS.PENDING
      };
    }
    case types.GET_PARTS_DIAGRAM_SUCCESS: {
      return {
        ...state,
        partsDiagramStatus: STATUS.RESOLVED,
        partsDiagram: action.payload
      };
    }
    case types.GET_PARTS_DIAGRAM_FAIL: {
      return {
        ...state,
        partsDiagramStatus: STATUS.REJECTED
      };
    }
    default:
      return state;
  }
};

export default PartsDiagramReducer;
