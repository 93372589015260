import { useSelector } from 'react-redux';
import useAnalytics from '@app/hooks/useAnalytics';
import { PCC_MINI_CART_LIST } from '@app/constants/analyticsConstants';

const useHeaderAnalytics = () => {
  const { fireProductClickEvent, fireProductImpressionEvent } = useAnalytics();
  const model = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment?.model
  );

  const getProductDetails = (item, index = 0) => ({
    brand: item.orderItemExtendAttribute?.googleAnalyticsAttributes?.brand,
    businessUnit: 'PCC',
    category:
      item.orderItemExtendAttribute?.googleAnalyticsAttributes?.category,
    id: item.partNumber,
    list: PCC_MINI_CART_LIST,
    name: item.name,
    partNumber: item.partNumber,
    price: item.unformattedUnitPrice,
    position: index + 1,
    quantity: item.quantity,
    relatedModel: model,
    productWeight: item.orderItemExtendAttribute?.unitWeight
  });

  const getMultipleProductDetails = items =>
    items.map((item, index) => getProductDetails(item, index));

  const productImpressionEvent = items => {
    fireProductImpressionEvent(
      PCC_MINI_CART_LIST,
      getMultipleProductDetails(items)
    );
  };
  const productClickEvent = (item, index) => {
    fireProductClickEvent({
      list: PCC_MINI_CART_LIST,
      products: [getProductDetails(item, index)]
    });
  };

  return { productImpressionEvent, productClickEvent };
};

export default useHeaderAnalytics;
