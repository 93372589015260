import PropTypes from 'prop-types';

const CapitalizeText = ({ text }) => {
  return <span className="text-capitalize">{text.toLowerCase()}</span>;
};

CapitalizeText.propTypes = {
  text: PropTypes.string
};

export default CapitalizeText;
