import PropTypes from 'prop-types';
import { NotificationCatPagination } from '@cat-ecom/pcc-components';
import 'blocks-web-components/dist/bedrock/bedrock.css';

const NotificationPagination = ({
  pageSize,
  totalRecords,
  onPageChange,
  currentPage
}) => {
  const handlePageChange = e => {
    onPageChange(e?.detail.pageNumber);
  };

  return (
    <NotificationCatPagination
      pageSize={pageSize}
      totalRecords={totalRecords}
      handlePageChange={handlePageChange}
      currentPage={currentPage}
    />
  );
};

NotificationPagination.propTypes = {
  pageSize: PropTypes.number,
  totalRecords: PropTypes.number,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.number
};
export default NotificationPagination;
