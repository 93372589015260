import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Custom hook that allows for Cross-Origin communication with an iframe
 *
 * @param {function} callback - a callback function to be called when a message is received. NOTE: This should be a memoized callback.
 * @param {Array} dependencies - An array of dependencies in the function
 *
 * @example
 * let counter = 0;
 * const cb = (event) => {
 *  if(event.data) {
 *    counter = counter + event.data.someNumber;
 *  }
 * }
 *
 * const [ref, sendMessage] = useCrossOriginCommunication(cb, counter);
 * sendMessage('hello', '*');
 */
const useCrossOriginCommunication = (callback, dependencies = []) => {
  const ref = useRef();

  const postMessage = (message, domain) =>
    ref.current.contentWindow.postMessage(message, domain);

  useEffect(() => {
    const receiveMessage = event => {
      const refOrigin = ref?.current?.src ?? null;
      // Ensure that the event is originating from the ref's domain
      if (!refOrigin || !refOrigin.includes(event.origin)) {
        return;
      }

      if (callback) {
        callback(event);
      }
    };

    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, ...dependencies]);

  return [ref, postMessage];
};

useCrossOriginCommunication.propTypes = {
  callback: PropTypes.func.isRequired,
  dependencies: PropTypes.arrayOf(PropTypes.any)
};

export default useCrossOriginCommunication;
