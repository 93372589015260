import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../../i18n';
import {
  CAT_CARDS_ALLOWED_COUNTRIES,
  CAT_CENTRAL_ALLOWED_COUNTRIES,
  GENERIC_USER_ID,
  PCC_OMM_ALLOWED_LANGUAGES,
  USER_TYPE_GUEST,
  CONTROL_CENTER_ALLOWED_USER_AFFILIATIONS,
  USER_TYPE_REGISTERED,
  PCC_ALP_NON_RELATED_CATEGORIES
} from '@app/constants/commonConstants';
import { CART_MODIFICATION_EVENT } from '@app/constants/events';
import resourceBundle from '@app/constants/resourceBundle';
import {
  SHOPPING_CART_ROUTES,
  SUMMARY_AND_PAYMENT_ROUTE_LEGACY,
  PICKUP_AND_DELIVERY_GUEST_ROUTE_LEGACY,
  PICKUP_AND_DELIVERY_ROUTE_LEGACY,
  MY_ACCOUNT_SEARCH,
  PICKUP_AND_DELIVERY_PAGE,
  ORDER_CONFIRMATION_ROUTE
} from '@app/constants/routes';
import links from '@app/constants/links';
import queryString from 'query-string';
import { getPathname, getLocationSearch } from '@app/services/windowService';
import { getTranslations } from '@app/services/translationService';
import { getFeatureFlags } from '@app/store/featureFlag/actions';
import {
  getCommonVars,
  createGuestIdentity,
  setTranslationsLoaded,
  getFeatureInformation
} from '@app/store/common/actions';
import {
  fetchPriceAvailability,
  getCurrentOrderInfo
} from '@app/store/orders/actions';
import { getDealerInfo } from '@app/store/dealer/actions';
import { getAssociationsdealerStore } from '@app/store/dealer-association/actions';
import { getStoreLocationInfo } from '@app/store/store/actions';
import { getShoppingPreferences } from '@app/store/shoppingPreferences/actions';
import { getStoreConfiguration } from '@app/store/storeConfiguration/actions';
import useSelectStore from '@app/components/dealer-locator/hooks/useSelectStore';
import useSelectDealer from '@app/components/associated-dealer/hooks/useSelectDealer';
import { normalizeUrl, replaceTokensInString } from '@app/utils';
import { isPDP } from '@app/utils/browserUtils';

const useAppStartup = () => {
  const dispatch = useDispatch();
  const [isCommonFirstCall, setIsCommonFirstCall] = useState(true);
  const [isGuestIdentityCalling, setIsGuestIdentityCalling] = useState(false);
  const isCatCorp = useSelector(s => s.common.isCatCorp);
  const langId = useSelector(s => s.common.langId);
  const selectedStore = useSelector(s => s.common.selectedStore);
  const storeId = useSelector(s => s.common.storeId);
  const userId = useSelector(s => s.common.userId);
  const catalogId = useSelector(state => state?.common?.catalogId);
  const userAffiliation = useSelector(s => s.common.userAffiliation);
  const quoteEnabled = useSelector(s => s.common?.quoteEnabled);
  const isCspCustomer = useSelector(s => s.common?.isCSPCustomer);
  const userType = useSelector(s => s.common.userType);
  const isLoggedIn = userType !== USER_TYPE_GUEST;
  const params = queryString.parse(getLocationSearch());
  const pendingQuotesRoutes = params?.route === MY_ACCOUNT_SEARCH.PENDINGQUOTES;
  const pendingQuoteDetailsRoutes =
    params?.route === MY_ACCOUNT_SEARCH.PENDING_QUOTE_DETAILS;
  const urlParams = new URLSearchParams(getLocationSearch());
  const controlCenterRoute = urlParams.get('route');
  const controlCenterRoutes =
    controlCenterRoute === MY_ACCOUNT_SEARCH.CONTROL_CENTER;
  const { getSelectStore } = useSelectStore();
  const { getDealerStore } = useSelectDealer();
  const isQuoteEnabledForCspUser =
    isLoggedIn &&
    !quoteEnabled &&
    isCspCustomer &&
    (pendingQuotesRoutes || pendingQuoteDetailsRoutes);
  const { CONTROL_CENTER_URL } = links;
  const getLinkUrl = url => {
    const link = replaceTokensInString(url, catalogId, langId, storeId);
    return normalizeUrl(link);
  };

  /** This needs to be called to allow usage of Google Maps's advanced markers.
   * The GoogleMapsLoader component from pcc-components should automatically be adding it
   * but for some reason it isn't.
   * More info at https://developers.google.com/maps/documentation/javascript/reference/advanced-markers
   **/
  useEffect(() => {
    window.google?.maps?.importLibrary('marker');
  }, []);

  useEffect(() => {
    if (isQuoteEnabledForCspUser) {
      window.location = getLinkUrl(CONTROL_CENTER_URL);
    }
  }, [
    CONTROL_CENTER_URL,
    getLinkUrl,
    isCspCustomer,
    pendingQuoteDetailsRoutes,
    pendingQuotesRoutes,
    quoteEnabled
  ]);

  // getUserInfo Service is included in this
  useEffect(() => {
    dispatch(getCommonVars()).then(() => {
      setIsCommonFirstCall(false);
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getStoreConfiguration(CONTROL_CENTER_ALLOWED_USER_AFFILIATIONS));
  }, [dispatch]);

  useEffect(() => {
    const createGuestIdentityDispatch = async () => {
      await dispatch(createGuestIdentity());
      setIsGuestIdentityCalling(false);
    };
    if (userId === GENERIC_USER_ID && !isCatCorp) {
      setIsGuestIdentityCalling(true);
      createGuestIdentityDispatch();
    }
  }, [dispatch, isCatCorp, userId]);

  // Orders Service
  useEffect(() => {
    const pathname = getPathname();
    const shoppingCartRoutes = SHOPPING_CART_ROUTES.some(route =>
      pathname.includes(route)
    );
    const isOrderConfirmationRoute = pathname.includes(
      ORDER_CONFIRMATION_ROUTE
    );
    const isSummaryAndPaymentRoute = pathname.includes(
      SUMMARY_AND_PAYMENT_ROUTE_LEGACY
    );

    const getOrders = () => {
      const onCheckOrderPage =
        pathname.includes(SUMMARY_AND_PAYMENT_ROUTE_LEGACY) ||
        pathname.includes(PICKUP_AND_DELIVERY_ROUTE_LEGACY) ||
        pathname.includes(PICKUP_AND_DELIVERY_GUEST_ROUTE_LEGACY) ||
        pathname.includes(PICKUP_AND_DELIVERY_PAGE);
      if (
        storeId &&
        langId &&
        userId &&
        userId !== GENERIC_USER_ID &&
        !isGuestIdentityCalling
      ) {
        const cartItems = onCheckOrderPage ? false : true;
        if (isSummaryAndPaymentRoute) {
          dispatch(
            fetchPriceAvailability({
              invokeXorder: false,
              skipAddressValidation: true
            })
          ).then(() => {
            dispatch(getCurrentOrderInfo({ cartItems }));
          });
        }
        if (
          !shoppingCartRoutes &&
          !pendingQuoteDetailsRoutes &&
          !controlCenterRoutes &&
          !isOrderConfirmationRoute &&
          !isSummaryAndPaymentRoute
        ) {
          dispatch(getCurrentOrderInfo({ cartItems }));
        }
      }
    };
    getOrders();

    document.addEventListener(CART_MODIFICATION_EVENT, getOrders);
    return () =>
      document.removeEventListener(CART_MODIFICATION_EVENT, getOrders);
  }, [dispatch, langId, storeId, userId, isGuestIdentityCalling]);

  // Dealer Service
  useEffect(() => {
    if (langId && storeId && userType) {
      dispatch(getDealerInfo());
    }
  }, [dispatch, langId, storeId, userType]);

  // Dealer Association Service
  useEffect(() => {
    const isUsableAffil = userAffiliation !== undefined;
    if (langId && storeId && userType !== USER_TYPE_GUEST && isUsableAffil) {
      dispatch(getAssociationsdealerStore(getSelectStore, getDealerStore));
    }
  }, [
    dispatch,
    getSelectStore,
    getDealerStore,
    langId,
    storeId,
    userAffiliation,
    userType
  ]);

  // Store Location Service

  // Check if is under PDP page to add extra query param for cutoff includeCutoffTime
  const isPDPPage = isPDP();

  useEffect(() => {
    if (langId && selectedStore && storeId && userType) {
      dispatch(getStoreLocationInfo({}, isPDPPage));
    }
  }, [dispatch, langId, selectedStore, storeId, userType, isPDPPage]);

  // Translations Service
  useEffect(() => {
    if (langId && storeId) {
      getTranslations(resourceBundle, langId, storeId).then(resp => {
        i18n.addResourceBundle('locale', 'translation', resp.data);
        i18n.changeLanguage('locale');
        dispatch(setTranslationsLoaded());
      });
    }
  }, [langId, storeId, dispatch]);

  // get feature flags
  useEffect(() => {
    if (storeId) {
      dispatch(getFeatureFlags());
    }
  }, [storeId, dispatch]);

  // get feature information
  useEffect(() => {
    if (storeId) {
      const featureNames = [
        CAT_CARDS_ALLOWED_COUNTRIES,
        CAT_CENTRAL_ALLOWED_COUNTRIES,
        PCC_OMM_ALLOWED_LANGUAGES,
        PCC_ALP_NON_RELATED_CATEGORIES
      ].join();

      dispatch(getFeatureInformation(featureNames));
    }
  }, [storeId, dispatch]);

  // get shopping preferences
  useEffect(() => {
    if (userType === USER_TYPE_REGISTERED) {
      dispatch(getShoppingPreferences());
    }
  }, [storeId, dispatch, userType]);

  return { isCommonFirstCall };
};

export default useAppStartup;
