export const DEALER_ASSOC_GET_BEGIN = 'DEALER_ASSOC_GET_BEGIN';
export const DEALER_ASSOC_GET_SUCCESS = 'DEALER_ASSOC_GET_SUCCESS';
export const CSR_ORDER_DETAIL_DEALER_ASSOC_GET_SUCCESS =
  'CSR_ORDER_DETAIL_DEALER_ASSOC_GET_SUCCESS';
export const DEALER_ASSOC_GET_FAIL = 'DEALER_ASSOC_GET_FAIL';
export const SELECTED_STORE_GET_SUCCESS = 'SELECTED_STORE_GET_SUCCESS';
export const SELECTED_STORE_GET_FAIL = 'SELECTED_STORE_GET_FAIL';
export const SELECTED_STORE_GET_BEGIN = 'SELECTED_STORE_GET_BEGIN';
export const SELECTED_ADP_URL_GET_SUCCESS = 'SELECTED_ADP_URL_GET_SUCCESS';
export const SELECTED_ADP_URL_GET_BEGIN = 'SELECTED_ADP_URL_GET_BEGIN';
export const SELECTED_ADP_URL_GET_FAIL = 'SELECTED_ADP_URL_GET_FAIL';
