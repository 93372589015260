import { useTranslation } from 'react-i18next';
import { AlloyButton } from 'cat-ecommerce-alloy';
import styles from './loginRegistrationModal.module.scss';
import useRegister from '@app/components/login/hooks/useRegister';
import useLogin from '../../hooks/useLogin';
import PropTypes from 'prop-types';

const LoginRegistrationModal = ({ details }) => {
  const { t } = useTranslation();
  const { login } = useLogin();
  const { register } = useRegister();
  return (
    <div className="container px-0" data-testid="loginRegistrationModel">
      <div className="login-registration-modal__content">
        <h2 className="h2 display-1"> {t('MEQ_SIGNIN_REG')}</h2>
        <div className="text-md fw-normal">{t('CONTINUE_WITH_ACTION')}</div>
        <div className="text-univers--bold text-md fw-normal text-uppercase pt-3 mb-2">
          {t('ALREADY_HAVE_ACCOUNT')}
        </div>
      </div>
      <div className="mt-2 mb-3">
        <AlloyButton
          buttonType="primary"
          id="buttons--primary-large-button"
          size="dynamic"
          onClick={() => {
            login(details);
          }}
        >
          {t('SIGN_IN')}
        </AlloyButton>
      </div>
      <div
        className={`${styles['login-registration-modal__footer']} pull-left pt-2 mt-3 pb-3`}
      >
        <div className="text-univers--bold text-md fw-normal text-uppercase">
          {t('GLOBALLOGIN_HAVING_NEW_ACCOUNT')}
        </div>
        <div className="my-2">
          <AlloyButton
            buttonType="secondary"
            size="dynamic"
            onClick={() => {
              register();
            }}
          >
            {t('GLOBALLOGIN_SIGN_IN_PANEL_REGISTER')}
          </AlloyButton>
        </div>
      </div>
    </div>
  );
};
LoginRegistrationModal.propTypes = {
  details: PropTypes.func
};
export default LoginRegistrationModal;
