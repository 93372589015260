import PropTypes from 'prop-types';
import { isEmpty } from '@app/utils';
import { Portal } from '@cat-ecom/pcc-components';
import { DRAWERS_ROOT } from '@app/constants/targets';
import SelectedEquipmentDrawer from './SelectedEquipmentDrawer';
const Container = ({ drawerProps, currentEquipment, onDrawerClose }) => {
  return !isEmpty(currentEquipment) ? (
    <Portal id={DRAWERS_ROOT}>
      <SelectedEquipmentDrawer
        drawerProps={drawerProps}
        currentEquipment={currentEquipment}
        onDrawerClose={onDrawerClose}
      />
    </Portal>
  ) : null;
};

Container.propTypes = {
  drawerProps: PropTypes.object,
  currentEquipment: PropTypes.object,
  onDrawerClose: PropTypes.func
};

export default Container;
