export const COOKIE_EXPIRATION_DAYS = 7;

export const SKIP_MOBILE_APP_ADVERT_BANNER_COOKIE = 'skipMobileAppAdvertBanner';

export const SFCID_COOKIE = 'SFCID';

export const INSTALL_ANDROID_APP_USER_CHOICE = {
  DISMISSED: 'dismissed',
  ACCEPTED: 'accepted'
};

export const ANDROID_APP_URI_SCHEME =
  'com.cat.digital.ecomm://home?app-argument=';

export const ANDROID_INSTALL_URL =
  'https://parts.cat.com/en/catcorp/app-download?utm_content=catcentral_install&utm_source=smart_banner&utm_medium=mobile_web&utm_campaign=';
const CAT_APP_ANDROID_ID = 'com.cat.digital.ecomm';
const CAT_APP_IOS_ID = '1608965148';
const CAT_APP_IOS_NAME = 'cat-ecommerce';

export const CAT_APP_STORE_ANDROID = `https://play.google.com/store/apps/details?id=${CAT_APP_ANDROID_ID}`;
export const CAT_APP_STORE_IOS = `https://apps.apple.com/us/app/${CAT_APP_IOS_NAME}/id${CAT_APP_IOS_ID}`;

const CAT_INSPECT_ANDROID_ID = 'com.cat.desd.mobile.inspect&hl=en_US&gl=US';
const CAT_INSPECT_IOS_ID = '1364673170';
const CAT_INSPECT_IOS_NAME = 'cat-inspect';

export const CAT_INSPECT_ANDROID = `https://play.google.com/store/apps/details?id=${CAT_INSPECT_ANDROID_ID}`;
export const CAT_CAT_INSPECT_IOS = `https://apps.apple.com/us/app/${CAT_INSPECT_IOS_NAME}/id${CAT_INSPECT_IOS_ID}`;

export const CUSTOMER_SEGMENTATION_TYPES = {
  PRO_BUYER: 'pro-buyer',
  RETAIL: 'retail'
};

export const APP_BANNER_TITLE = 'Cat® Central';
export const APP_BANNER_LOGO =
  'https://caterpillar.scene7.com/is/image/Caterpillar/CM20220802-6f5b0-875c2?wid=40&hei=40';
