/**
 * Search a local storage in the document
 * @param {string} name local storage name
 * @returns {string|null} local storage value or null
 */
export const getLocalStorage = name => {
  return localStorage.getItem(name);
};

/**
 * Set a local storage in the document
 * @param {string} name local storage name
 * @param {string} value local storage value
 */
export const setLocalStorage = (name, value) => {
  try {
    localStorage.setItem(name, value);
  } catch (error) {
    return;
  }
};

/**
 * Set a local storage in the document
 * @param {string} name local storage name
 * @param {string} value local storage value
 * @param {number} ttl local storage value Time To Live (days)
 */
export const setLocalStorageWithExpiry = (name, value, ttl) => {
  const now = new Date();
  const daysToMilliseconds = ttl * 24 * 60 * 60 * 1000;
  const item = {
    value: value,
    expiry: now.getTime() + daysToMilliseconds
  };
  try {
    localStorage.setItem(name, JSON.stringify(item));
  } catch (error) {
    return;
  }
};

/**
 * Search a local storage in the document
 * @param {string} name local storage name
 * @returns {string|null} local storage value or null
 */
export const getLocalStorageWithExpiry = name => {
  const itemStr = localStorage.getItem(name);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(name);
    return null;
  }
  return item.value;
};
export const removeLocalStorage = name => {
  localStorage.removeItem(name);
};
