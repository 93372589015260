import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useModal } from 'cat-ecommerce-alloy';
import useWebStorage from '../../../hooks/useWebStorage';
import { options } from '../../../hooks/useWebStorage/useWebStorage';
import useLoginRegistration from '@app/components/login/hooks/useLoginRegistration';
import { HEADER_GLOBAL_LOGIN, STATUS } from '@app/constants/commonConstants';
import {
  SHOW_SAVED_LIST_MODAL_AFTER_ADP_EVENT,
  SHOW_SAVED_LIST_MODAL_AFTER_LOGIN_EVENT
} from '@app/constants/events';
import { STORAGE_SAVE_LIST_ITEM } from '@app/constants/storageKeys';

const useSavedLists = () => {
  const savedLists = useSelector(s => s.account?.savedLists);
  const { showLoginRegistrationModal } = useLoginRegistration();

  const [showSavedListsModalAfterADP, setShowSavedListsModalAfterADP] =
    useWebStorage(SHOW_SAVED_LIST_MODAL_AFTER_ADP_EVENT, options.SESSION);

  // Flag isPendingSavedListsModal in SessionStorage is used when waiting for the Login
  // legacy code finishes in order to show the Saved lists modal flow.
  // It's activated (set to true) when the Legacy login modal is shown
  // and deactivated (set to false) when the Saved lists modal flow ends.
  // const isPendingSavedListsModal = useRef(false);
  const [isPendingSavedListsModal, setIsPendingSavedListsModal] = useWebStorage(
    SHOW_SAVED_LIST_MODAL_AFTER_LOGIN_EVENT,
    options.SESSION
  );

  const [itemToSave, setItemToSave] = useWebStorage(
    STORAGE_SAVE_LIST_ITEM,
    options.SESSION
  );

  const setFinishedFlowADP = useCallback(() => {
    if (isPendingSavedListsModal === true) {
      setShowSavedListsModalAfterADP(true);
    }
  }, [isPendingSavedListsModal, setShowSavedListsModalAfterADP]);

  const hasSavedLists = savedLists?.length > 0;
  const savedListsStatus = useSelector(s => s.account?.savedListsStatus);
  const saveListsResolved =
    savedListsStatus === STATUS.RESOLVED ||
    savedListsStatus === STATUS.REJECTED;
  const addSavedListModalProps = useModal();
  const createSavedListModalProps = useModal();

  // This method resolves wether the Saved lists modal or the Create new list
  // modal should be shown, receives a ref so it can be used
  // within a useEffect without causing re-renders

  const showPendingSavedListsWithStoredItem = useCallback(() => {
    setShowSavedListsModalAfterADP(false);
    setIsPendingSavedListsModal(false);
    if (itemToSave) {
      if (hasSavedLists) {
        addSavedListModalProps.open();
      } else {
        createSavedListModalProps.open();
      }
    }
  }, [
    setShowSavedListsModalAfterADP,
    setIsPendingSavedListsModal,
    itemToSave,
    hasSavedLists,
    addSavedListModalProps,
    createSavedListModalProps
  ]);

  const saveItemsToList = useCallback(
    values => {
      showLoginRegistrationModal({ widgetId: HEADER_GLOBAL_LOGIN });
      setIsPendingSavedListsModal(true);
      setItemToSave(values);
    },
    [showLoginRegistrationModal, setIsPendingSavedListsModal, setItemToSave]
  );

  return useMemo(
    () => ({
      setFinishedFlowADP,
      isPendingSavedListsModal,
      saveListsResolved,
      savedListsStatus,
      showPendingSavedListsWithStoredItem,
      saveItemsToList,
      setIsPendingSavedListsModal,
      setShowSavedListsModalAfterADP,
      itemToSave,
      showSavedListsModalAfterADP
    }),
    [
      setFinishedFlowADP,
      isPendingSavedListsModal,
      saveListsResolved,
      savedListsStatus,
      showPendingSavedListsWithStoredItem,
      saveItemsToList,
      setIsPendingSavedListsModal,
      setShowSavedListsModalAfterADP,
      itemToSave,
      showSavedListsModalAfterADP
    ]
  );
};

export default useSavedLists;
