import { STATUS } from '@app/constants/commonConstants';
import initialStore from '../initialStore';
import * as types from './constants';

const csrOrdersReducer = (state = initialStore.csrOrders, action) => {
  switch (action.type) {
    case types.CSR_ORDERS_SET_DATA_BEGIN: {
      return {
        ...state,
        ordersDataStatus: STATUS.PENDING
      };
    }

    case types.CSR_ORDERS_SET_DATA_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        byId: payload,
        ordersDataStatus: STATUS.RESOLVED
      };
    }

    case types.CSR_ORDERS_SET_DATA_FAIL: {
      return {
        ...state,
        ordersDataStatus: STATUS.REJECTED
      };
    }

    case types.GET_ON_BEHALF_USER_INFO_BEGIN: {
      return {
        ...state,
        onBehalfUserInfoStatus: STATUS.PENDING
      };
    }

    case types.GET_ON_BEHALF_USER_INFO_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        onBehalfUserInfo: payload,
        onBehalfUserInfoStatus: STATUS.RESOLVED
      };
    }

    case types.GET_ON_BEHALF_USER_INFO_FAIL: {
      return {
        ...state,
        onBehalfUserInfoStatus: STATUS.REJECTED
      };
    }

    default: {
      return state;
    }
  }
};

export default csrOrdersReducer;
