import { ChicletTypes } from 'cat-ecommerce-alloy';
export const ORDER_STATUS_KEYS = {
  DELETED: {
    id: '3',
    name: 'CAT_ORD_DELETED'
  },
  REJECTED: {
    id: '2',
    name: 'CAT_ORD_REJECTED'
  },
  APPROVED: {
    id: '1',
    name: 'ORG_STATUS_APPROVED'
  }
};

export const ORDER_STATUS = [
  { displayName: 'MO_OrderStatus_P', value: '0' },
  { displayName: 'ORG_STATUS_APPROVED', value: '1' },
  { displayName: 'CAT_ORD_REJECTED', value: '2' },
  { displayName: 'CAT_ORD_DELETED', value: '3' },
  { displayName: 'EXPIRED_STATE', value: '4' },
  { displayName: 'CAT_ORD_ALL', value: '' }
];

export const SORT_BY_DATE = [
  { displayKey: 'SUBMISSION_DATE', value: 'S' },
  { displayKey: 'SUMM_PAY_CARD_EXPIRATION_DATE', value: 'E' }
];

export const defaultFilterByDateStatus = SORT_BY_DATE.find(
  status => status.displayKey === 'SUBMISSION_DATE'
);
export const PENDING_ORDER_STATUS_VALUE = '0';
export const APPROVED_ORDER_STATUS_VALUE = '1';
export const REJECTED_ORDER_STATUS_VALUE = '2';
export const DELETED_ORDER_STATUS_VALUE = '3';
export const ALL_ORDER_STATUS_VALUE = '';
export const FILTER_BY_EXPIRATION = 'E';
export const defaultOrderStatus = ORDER_STATUS.find(
  status => status.displayName === 'MO_OrderStatus_P'
);

export const allOrdersStatus = ORDER_STATUS.find(
  status => status.displayName === 'CAT_ORD_ALL'
);

export const UTC_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ssxxxx";

export const ORDER_TO_APPROVE_STATUS_TYPE_MAP = {
  Approved: ChicletTypes.SUCCESS,
  Pending: ChicletTypes.INFORMATIONAL,
  Rejected: ChicletTypes.ERROR,
  Deleted: ChicletTypes.ERROR,
  Expired: ChicletTypes.ERROR
};
export const DCN_QP_KEY = 'dcn';
export const TEMP_VIEW_QP_KEY = 'tempview';
export const TEMP_VIEW_VALUES = {
  SUBMIT_ASCENDING: 'submitasc'
};
export const ORDER_TO_EXPIRY_DATE = 'orderExpiryDate';
export const ORDER_ID_LINK = 'orderIdLink';
export const NORMAL_EXPIRES_DAY = 8;
export const PENDING_STATUS = 'Pending';
export const URGENT = 'urgent';
export const WARNING = 'warning';
export const DEFAULT = 'default';
export const BOTTOM = 'BOTTOM';
export const FROM_ORDERS_TO_APPROVE = true;
export const ORDERS_APPROVE_ORDER_EXPIRY_DATE = 'ordersApprove_orderExpiryDate';
export const ORDERED_STATUS = { APPROVED: 'Approved' };
export const ORDER_TYPE_QOT = 'QOT';

export const NOT_VALID_ORDER_STATUS = ['W', 'N', 'AD', 'E'];
