import PropTypes from 'prop-types';
import {
  ContactEmailIcon,
  ContactPhoneIcon,
  ContactWorldWideWebsiteIcon,
  LocationPinIcon
} from 'cat-ecommerce-alloy';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

const StoreLocationContactInfo = ({
  autoFocusClassName = '',
  contactUrl,
  drivingDirections,
  email,
  phone,
  iconSize = 24,
  website
}) => {
  const [t] = useTranslation();
  const commonIconProps = {
    className: 'me-1',
    height: iconSize,
    width: iconSize
  };
  const linkClassName =
    'btn btn-sm btn-ghost d-inline-flex align-items-center ps-0 pe-2 text-sm';
  const protocol = 'https://';
  const getWebsiteUri = website =>
    !website.startsWith('http://') && !website.startsWith('https://')
      ? `${protocol}${website}`
      : website;
  return (
    <div className="d-flex flex-column">
      {phone && (
        <p className="text-start">
          <a
            href={`tel:${phone}`}
            className={cx(linkClassName, autoFocusClassName)}
          >
            <ContactPhoneIcon {...commonIconProps} />
            {phone}
          </a>
        </p>
      )}
      {email && (
        <p className="text-start">
          <a
            href={contactUrl}
            className={cx(linkClassName, autoFocusClassName)}
          >
            <ContactEmailIcon {...commonIconProps} />
            {t('MA_EMAIL')}
          </a>
        </p>
      )}
      {website && (
        <p className="text-start">
          <a
            href={getWebsiteUri(website)}
            className={cx(linkClassName, autoFocusClassName)}
            rel="noreferrer noopener"
            target="_blank"
          >
            <ContactWorldWideWebsiteIcon {...commonIconProps} />
            {t('CU_WEBSITE')}
          </a>
        </p>
      )}
      {drivingDirections && (
        <p className="text-start">
          <a
            href={drivingDirections}
            target="_blank"
            rel="noreferrer"
            className={cx(linkClassName, autoFocusClassName)}
          >
            <LocationPinIcon {...commonIconProps} />
            {t('DRIVING_DIRECTIONS')}
          </a>
        </p>
      )}
    </div>
  );
};

export default StoreLocationContactInfo;

StoreLocationContactInfo.propTypes = {
  autoFocusClassName: PropTypes.string,
  contactUrl: PropTypes.string.isRequired,
  drivingDirections: PropTypes.string,
  email: PropTypes.string,
  iconSize: PropTypes.number,
  phone: PropTypes.string,
  website: PropTypes.string
};
