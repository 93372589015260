import queryString from 'query-string';

export const useQueryParamRoute = (history, selectedDefault) => {
  const parsed = queryString.parse(history?.location?.search);
  const { route = '' } = parsed;
  const setRoute = (e, newRoute, params) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const queryParams = {
      ...parsed,
      ...selectedDefault,
      ...params,
      route: newRoute
    };
    history.push({
      pathname: history?.location?.pathname,
      search: queryString.stringify(queryParams)
    });
  };

  return [route, setRoute];
};

export default useQueryParamRoute;
