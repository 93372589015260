export const SP_LOAD_BEGIN = 'SP_LOAD_BEGIN';
export const SP_LOAD_FAIL = 'SP_LOAD_FAIL';
export const SP_LOAD_SUCCESS = 'SP_LOAD_SUCCESS';
export const SP_UPDATE_BEGIN = 'SP_UPDATE_BEGIN';
export const SP_UPDATE_FAIL = 'SP_UPDATE_FAIL';
export const SP_UPDATE_SUCCESS = 'SP_UPDATE_SUCCESS';
export const SP_DEALER_STORE_CHANGE = 'SP_DEALER_STORE_CHANGE';
export const SP_FULFILLMENT_CHANGE = 'SP_FULFILLMENT_CHANGE';
export const SP_ACCOUNT_CHANGE = 'SP_ACCOUNT_CHANGE';
export const SP_PREFERENCES_RESTORE = 'SP_PREFERENCES_RESTORE';
export const SP_LOAD_DSN_STORES_BEGIN = 'SP_LOAD_DSN_STORES_BEGIN';
export const SP_LOAD_DSN_STORES_FAIL = 'SP_LOAD_DSN_STORES_FAIL';
export const SP_LOAD_DSN_STORES_SUCCESS = 'SP_LOAD_DSN_STORES_SUCCESS';
export const UPDATE_BASIC = 'Basic';
export const UPDATE_ADVANCED = 'Advanced';
export const SP_NEW_STORE_SUCCESS = 'SP_NEW_STORE_SUCCESS';
export const SP_NEW_STORE_FAIL = 'SP_NEW_STORE_FAIL';
export const SP_NEW_STORE_BEGIN = 'SP_NEW_STORE_BEGIN';
export const SP_TOASTER = 'SP_TOASTER';
export const SP_SIMPLE_UPDATE_SUCCESS = 'SP_SIMPLE_UPDATE_SUCCESS';
export const SP_DEFAULT_LANGUAGE_UPDATE = 'SP_DEFAULT_LANGUAGE_UPDATE';
export const UPDATE_IMAGE_PREFERENCE = 'UPDATE_IMAGE_PREFERENCE';
export const SHOPPING_PREFERENCES_BEGIN = 'SHOPPING_PREFERENCES_BEGIN';
export const SHOPPING_PREFERENCES_SUCCESS = 'SHOPPING_PREFERENCES_SUCCESS';
export const SHOPPING_PREFERENCES_FAIL = 'SHOPPING_PREFERENCES_FAIL';
