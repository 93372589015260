import { db } from './db';
import { catchDexieErrors, getExpireTime, getStoreInfoKey } from './utils';

export const clearDealerCustomerInfo = () =>
  catchDexieErrors(
    db.dealerCustomerInfo
      .where('expireTime')
      .below(new Date().getTime())
      .delete()
  );

/**
 * @param {string} storeId - string that identifies the store.
 * @param {string} selectedStore - string that identifies the selected Store.
 * @param {string} dcn - string that identifies selectedCustomerNumber.
 * @param {string} endUseCode - string that identifies selectedEndUseCode.
 * @param {string} orderType - string that identifies selectedOrderType.
 */
export async function getDealerCustomerInfo({
  userId,
  langId,
  storeId,
  selectedStore,
  dcn,
  endUseCode,
  orderType
}) {
  await clearDealerCustomerInfo();

  const storeInfoKey = getStoreInfoKey({
    storeId,
    selectedStore,
    dcn,
    endUseCode,
    orderType
  });

  const key = `${userId}_${langId}_${storeInfoKey}`;

  const dealerCustomerInfo = await catchDexieErrors(
    db.dealerCustomerInfo.get(key)
  );

  return dealerCustomerInfo?.data ?? {};
}

/**
 * @param {string} storeId - string that identifies the store.
 * @param {string} selectedStore - string that identifies the selected Store.
 * @param {string} dcn - string that identifies selectedCustomerNumber.
 * @param {string} endUseCode - string that identifies selectedEndUseCode.
 * @param {string} orderType - string that identifies selectedOrderType.
 * @param {Object} customerInfo - the data about dealer info that is received after the request to the P&A endpoint is completed.
 */
export async function setDealerCustomerInfo({
  userId,
  langId,
  storeId,
  selectedStore,
  dcn,
  endUseCode,
  orderType,
  customerInfo
}) {
  const expireTime = getExpireTime();
  const storeInfoKey = getStoreInfoKey({
    storeId,
    selectedStore,
    dcn,
    endUseCode,
    orderType
  });

  const key = `${userId}_${langId}_${storeInfoKey}`;

  await catchDexieErrors(
    db.dealerCustomerInfo.put({
      key: key,
      expireTime: expireTime,
      data: customerInfo
    })
  );
}
