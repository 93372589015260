import { getFormattedSerialNumber } from '@app/components/pages/my-equipment/utils';
import PropTypes from 'prop-types';
import EditModal from '../../../../EditModal';

const SaveEquipment = ({
  currentEquipment,
  modalTitle,
  serialNumber = '',
  widgetIdentifier,
  isTempEquipmentMessage = false
}) => {
  const SaveEquipmentcurrent = {
    validSerialAndModel: true,
    unValidatedItemListId: currentEquipment.unValidatedItemListId || '',
    unValidatedEquipmentId: currentEquipment.unValidatedEquipmentId || '',
    model: currentEquipment.model,
    multiple: currentEquipment.multiple,
    assetId: '',
    equipmentFamily: currentEquipment.equipmentFamily,
    uniqueIndex: currentEquipment.uniqueIndex || '',
    serialNumber: getFormattedSerialNumber(serialNumber),
    validatedAsset: false,
    imageURL: currentEquipment.imageURL,
    productId: currentEquipment.productId,
    seoUrl: currentEquipment.seoUrl,
    isQRCodeTempEquipment: currentEquipment.isQRCodeTempEquipment,
    equipmentUniqueId: currentEquipment.equipmentUniqueId
  };

  return (
    <EditModal
      currentEquipment={SaveEquipmentcurrent}
      modalTitle={modalTitle}
      widgetIdentifier={widgetIdentifier}
      isTempEquipmentMessage={isTempEquipmentMessage}
    />
  );
};

export default SaveEquipment;

SaveEquipment.propTypes = {
  currentEquipment: PropTypes.shape({
    unValidatedEquipmentId: PropTypes.string,
    model: PropTypes.string,
    assetId: PropTypes.string,
    productId: PropTypes.string,
    equipmentFamily: PropTypes.string,
    uniqueIndex: PropTypes.string,
    serialNumber: PropTypes.string,
    validatedAsset: PropTypes.bool,
    imageURL: PropTypes.string,
    isQRCodeTempEquipment: PropTypes.bool,
    unValidatedItemListId: PropTypes.string,
    multiple: PropTypes.bool,
    seoUrl: PropTypes.string,
    equipmentUniqueId: PropTypes.string
  }),
  modalTitle: PropTypes.string,
  serialNumber: PropTypes.string,
  widgetIdentifier: PropTypes.string,
  isTempEquipmentMessage: PropTypes.bool
};
