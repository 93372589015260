export const SB = 'ShippingAndBilling';
export const B = 'Billing';
export const S = 'Shipping';

export const ADDRESSES_GET_BEGIN = 'ADDRESSES_GET_BEGIN';
export const ADDRESSES_GET_SUCCESS = 'ADDRESSES_GET_SUCCESS';
export const ADDRESSES_GET_FAIL = 'ADDRESSES_GET_FAIL';

export const ADDRESS_VERIFICATION_BEGIN = 'ADDRESS_VERIFICATION_BEGIN';
export const ADDRESS_VERIFICATION_SUCCESS = 'ADDRESS_VERIFICATION_SUCCESS';
export const ADDRESS_VERIFICATION_FAIL = 'ADDRESS_VERIFICATION_FAIL';

export const ADDRESS_SAVE_BEGIN = 'ADDRESS_SAVE_BEGIN';
export const ADDRESS_SAVE_SUCCESS = 'ADDRESS_SAVE_SUCCESS';
export const ADDRESS_SAVE_FAIL = 'ADDRESS_SAVE_FAIL';

export const ADDRESS_DELETE_BEGIN = 'ADDRESS_DELETE_BEGIN';
export const ADDRESSES_DELETE_SUCCESS = 'ADDRESSES_DELETE_SUCCESS';
export const ADDRESSES_DELETE_FAIL = 'ADDRESSES_DELETE_FAIL';

export const ADDRESS_BOOK_PATH = 'AddressBookForm';
export const ADDRESS_BOOK_FORM_NAME = 'AddressBookForm';
export const ADDRESS_BOOK_LOCATION = 'AddressBook';

export const REQUEST_TYPE = {
  addressList: 'ADDRESS_LIST',
  addressSave: 'ADDRESS_SAVE',
  addressDelete: 'ADDRESS_DELETE',
  addressVerification: 'ADDRESS_VERIFICATION'
};

export const INVALID_ADDRESSES = {
  BTO: 'BTO',
  DBSSHIPTO: 'DBSSHIPTO',
  JOBSITE: 'JOBSITE',
  DROPBOX: 'dropBoxAddress'
};
