import PropTypes from 'prop-types';
import { ComponentLoader, Highlighted } from 'cat-ecommerce-alloy';
import styles from '../CurrentEquipmentModal.module.scss';

const SerialSuggestions = ({
  loading = false,
  results = [],
  setInputValue,
  setShowSerialNumberSuggestion,
  inputValue,
  ...props
}) => {
  const displaySerialNumber = currentSerial => e => {
    e.preventDefault();
    setInputValue(currentSerial);
    setShowSerialNumberSuggestion(false);
  };
  const suggestionsTextColor = 'u-color--DarkGray';

  const suggestion = results.map(prefix => (
    <li key={prefix}>
      <button
        type="button"
        className={`p-2 text-start w-100 bg-none color-extra-dark-gray ${styles['equipment-modal__serial-suggestions-item']}`}
        onClick={displaySerialNumber(prefix)}
      >
        <Highlighted
          text={prefix}
          highlight={inputValue}
          className={suggestionsTextColor}
          highlightClassName={`${suggestionsTextColor} fw-bold`}
        />
      </button>
    </li>
  ));
  return (
    <div
      className={`h-auto position-absolute overflow-y-auto w-100 z-index-2 ${styles['equipment-modal__serial-suggestions']}`}
      {...props}
    >
      {loading && (
        <div className={styles['prefix-suggestions__loader-wrapper']}>
          <ComponentLoader
            className="d-flex justify-content-center align-items-center"
            contentClassName={styles['prefix-suggestions__loader-content']}
          />
        </div>
      )}
      <ul className={`m-0 p-0 ${styles['serialSuggestionData']}`}>
        {suggestion}
      </ul>
    </div>
  );
};
SerialSuggestions.propTypes = {
  loading: PropTypes.bool,
  results: PropTypes.arrayOf(PropTypes.string),
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  setShowSerialNumberSuggestion: PropTypes.func
};
export default SerialSuggestions;
