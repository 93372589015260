import { useContext, useCallback, useMemo } from 'react';
import { ModalContext } from 'cat-ecommerce-alloy';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { actions } from './actions';
import SaveEquipment from '@app/components/pages/my-equipment/AddEquipmentModal/SearchSuggestions/EquipmentSuggestions/SaveEquipment';

const useMyEquipment = () => {
  const [t] = useTranslation();
  const { SAVE_EQUIPMENT } = actions;
  const { addModalWithOptions } = useContext(ModalContext);
  const storeId = useSelector(s => s.common?.storeId);
  const selectedEquipment = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment
  );

  const { isQRCodeTempEquipment } = selectedEquipment;
  const renderOptions = useMemo(
    () => ({
      [SAVE_EQUIPMENT]: {
        Modal: SaveEquipment,
        props: {
          currentEquipment: selectedEquipment,
          modalTitle: t('SAVE_MY_EQUIPMENT'),
          storeId,
          isTempEquipmentMessage: isQRCodeTempEquipment
        },
        modalOptions: {
          title: t('SAVE_MY_EQUIPMENT')
        }
      }
    }),
    [SAVE_EQUIPMENT, isQRCodeTempEquipment, selectedEquipment, storeId, t]
  );

  const openMyEquipmentModal = useCallback(
    option => {
      const { Modal, modalOptions, props } = renderOptions[option];
      addModalWithOptions(<Modal {...props} />, { ...modalOptions });
    },
    [renderOptions, addModalWithOptions]
  );

  const openSaveEquipment = useCallback(() => {
    openMyEquipmentModal(SAVE_EQUIPMENT);
  }, [SAVE_EQUIPMENT, openMyEquipmentModal]);

  return {
    openSaveEquipment
  };
};

export default useMyEquipment;
