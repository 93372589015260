import PropTypes from 'prop-types';
import { getIconSizeNumber } from '../utils';

const AccessEquipmentIcon = ({ size }) => {
  const sizeNumber = getIconSizeNumber(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={sizeNumber}
      height={sizeNumber}
      viewBox={`0 0 ${sizeNumber} ${sizeNumber}`}
      fill="none"
    >
      <g clipPath="url(#clip0_1_4979)">
        <path
          d="M14.584 18.75C14.3237 19.2056 13.9476 19.5842 13.4938 19.8476C13.04 20.111 12.5247 20.2497 12 20.2497C11.4753 20.2497 10.96 20.111 10.5062 19.8476C10.0524 19.5842 9.67628 19.2056 9.416 18.75H0.75V20.25C0.75 21.0456 1.06607 21.8087 1.62868 22.3713C2.19129 22.9339 2.95435 23.25 3.75 23.25H20.25C21.0457 23.25 21.8087 22.9339 22.3713 22.3713C22.9339 21.8087 23.25 21.0456 23.25 20.25V18.75H14.584Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.75 18.75V14.12"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.25 18.75V9.75C2.25 8.95435 2.56607 8.19129 3.12868 7.62868C3.69129 7.06607 4.45435 6.75 5.25 6.75H14.25"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 0.75H16.5C15.2574 0.75 14.25 1.75736 14.25 3V12C14.25 13.2426 15.2574 14.25 16.5 14.25H21C22.2426 14.25 23.25 13.2426 23.25 12V3C23.25 1.75736 22.2426 0.75 21 0.75Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.25 11.25H14.25"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.25 3.75H14.25"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_4979">
          <rect width={sizeNumber} height={sizeNumber} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

AccessEquipmentIcon.propTypes = {
  size: PropTypes.string
};

export default AccessEquipmentIcon;
