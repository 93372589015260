import { useModal as useModalBlocks } from '@cat-ecom/pcc-components';
import { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ConsentAcceptanceModalBlocks from '../ConsentAcceptanceModalBlocks';

export const ConsentAcceptanceContext = createContext({
  isOpen: false,
  open: () => {},
  close: () => {}
});

export const ConsentAcceptanceModalContext = ({ children }) => {
  const [t] = useTranslation();

  const modal = useModalBlocks({
    isRequired: false,
    heading: `${t('REGISTER_MODAL_5_USER_CONSENT_HEADER')}`,
    backButton: false,
    size: 'md'
  });
  const consentAcceptanceBlocksModalProps = useMemo(
    () => ({
      open: modal.modalControl.open,
      close: modal.modalControl.close,
      isOpen: modal.modalControl.isActive
    }),
    [modal.modalControl]
  );

  return (
    <ConsentAcceptanceContext.Provider
      value={consentAcceptanceBlocksModalProps}
    >
      {children}
      <ConsentAcceptanceModalBlocks modal={modal} />
    </ConsentAcceptanceContext.Provider>
  );
};

ConsentAcceptanceModalContext.propTypes = {
  children: PropTypes.element
};
