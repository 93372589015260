import { STATUS } from '../../constants/commonConstants';
import initialStore from '../initialStore';
import * as types from './constants';

const customersReducer = (state = initialStore.customers, action) => {
  switch (action.type) {
    case types.CUSTOMERS_SET_DATA_BEGIN: {
      return {
        ...state,
        customersDataStatus: STATUS.PENDING
      };
    }
    case types.CUSTOMERS_SET_DATA_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        byId: payload,
        customersDataStatus: STATUS.RESOLVED
      };
    }
    case types.CUSTOMERS_SET_DATA_FAIL: {
      return {
        ...state,
        customersDataStatus: STATUS.REJECTED
      };
    }
    case types.CUSTOMERS_REDIRECTION: {
      const { payload } = action;
      return {
        ...state,
        redirectionPoint: payload
      };
    }
    default: {
      return state;
    }
  }
};

export default customersReducer;
