export const PICKUP = 'pickUp';
export const DROPBOX = 'dropBox';
export const SHOP_ONLINE = 'shopOnline';
export const DELIVERY = 'delivery';

export const MAX_ADDITIONAL_DETAILS_BYTES = 210;
export const MAX_SPECIAL_INSTRUCTIONS_BYTES = 210;
export const MAX_ORDERED_BY_BYTES = 40;
export const MAX_FIRST_NAME_BYTES = 20;
export const MAX_LAST_NAME_BYTES = MAX_FIRST_NAME_BYTES;
export const MAX_CONTACT_EMAIL_BYTES = 50;
export const MAX_CONTACT_PHONE_BYTES = 16;
export const MICO_VALUE = '1';
export const GUESS_USER = 'g';
export const REGISTER_USER = 'r';

export const MICO = 'Mico';
export const LOGGED = 'Logged';
export const GUEST = 'Guest';

export const CLIENT_TIME_FORMAT = 'EEE MMM dd yyyy HH:mm:ss';
export const PICKUP_AND_DELIVERY = 'pickupAndDelivery';
export const SUMMARY_AND_PAYMENT = 'summaryAndPayment';
export const REQUEST_BY_DATE = 'requestByDate';
export const PICKUP_AND_DELIVERY_FORM_VALUES =
  'PICKUP_AND_DELIVERY_FORM_VALUES';
export const ABORT_ERROR_CODE = 'ERR_CANCELED';
export const WILL_RECALCULATE_REASON = 'recalculate';

export const CONTACT_INFORMATION_ERROR_FIELD_NAME = 'contactInformationError';
export const DELIVERY_ADDRESS_ERROR_FIELD_NAME = `${SHOP_ONLINE}.addressError`;
export const DBSSHIPTO = 'DBSSHIPTO';
export const IS_SUGGESTED_AVI_ADDRESS_SELECTED =
  'isSuggestedAVIdAddressSelected';

export const PICKUPANDDELIVERY_PAGE_TOP_ESPOT =
  'PICKUPANDDELIVERY_PAGE_TOP_ESPOT';
export const PICKUPANDDELIVERY_PAGE_BOTTOM_ESPOT =
  'PICKUPANDDELIVERY_PAGE_BOTTOM_ESPOT';
export const CATSAS_PICKUPANDDELIVERY_SHIPPINGMETHOD_GUEST_ESPOT =
  'CATSAS_PICKUPANDDELIVERY_SHIPPINGMETHOD_GUEST_ESPOT';

export const SHIPPING_METHOD_LEARN_MORE_ESPOT = 'ShippingMethodLearnMoreEspot';

export const DEALER_MARKETING_OPTIN_INFO_CLAUSE_espot =
  'DEALER_MARKETING_OPTIN_INFO_CLAUSE_espot';
export const DEALER_LOCAL_COMPLIANCE_Info_espot =
  'DEALER_LOCAL_COMPLIANCE_Info_espot';
export const DEALER_LOCAL_COMPLIANCE_Details_espot =
  'DEALER_LOCAL_COMPLIANCE_Details_espot';

export const Legal_cookie_privacy_espot1 = 'Legal_cookie_privacy_espot1';
export const TERMS_AND_CONDITIONS_espot = 'TERMS_AND_CONDITIONS_espot';
export const PRIVACY_POLICY_espot = 'PRIVACY_POLICY_espot';
export const ESTIMATED_PICKUP_DATE_TOOLTIP = 'ESTIMATED_PICKUP_DATE_TOOLTIP';

export const PUD_ESPOTS = {
  espotNames: [
    PICKUPANDDELIVERY_PAGE_TOP_ESPOT,
    PICKUPANDDELIVERY_PAGE_BOTTOM_ESPOT,
    CATSAS_PICKUPANDDELIVERY_SHIPPINGMETHOD_GUEST_ESPOT,
    SHIPPING_METHOD_LEARN_MORE_ESPOT,
    DEALER_MARKETING_OPTIN_INFO_CLAUSE_espot,
    DEALER_LOCAL_COMPLIANCE_Info_espot,
    DEALER_LOCAL_COMPLIANCE_Details_espot
  ].join(','),
  top: PICKUPANDDELIVERY_PAGE_TOP_ESPOT,
  bottom: PICKUPANDDELIVERY_PAGE_BOTTOM_ESPOT,
  guess: CATSAS_PICKUPANDDELIVERY_SHIPPINGMETHOD_GUEST_ESPOT,
  deliveryMethod: SHIPPING_METHOD_LEARN_MORE_ESPOT,
  marketingOptinClause: DEALER_MARKETING_OPTIN_INFO_CLAUSE_espot,
  dealerComplianceInfo: DEALER_LOCAL_COMPLIANCE_Info_espot,
  dealerComplianceDetails: DEALER_LOCAL_COMPLIANCE_Details_espot
};

export const PUD_TERMS_AND_CONDITIONS_ESPOTS = {
  espotNames: [
    Legal_cookie_privacy_espot1,
    TERMS_AND_CONDITIONS_espot,
    PRIVACY_POLICY_espot
  ].join(','),
  legalCookie: Legal_cookie_privacy_espot1,
  termsAndConditions: TERMS_AND_CONDITIONS_espot,
  privacyPolicy: PRIVACY_POLICY_espot
};

export const zeroShippingCostTranslationKey = {
  I: 'FREIGHT_CHARGE_TIME_INVOICE',
  F: 'CAT_DEALER_FREE_SHIPPING',
  C: 'CAT_DEALER_SHIPPING_TAX_COLLECT',
  M: 'CAT_DEALER_FREIGHT_MANAGED'
};

export const END_USER_CERTIFICATION = {
  NO: 'no',
  YES: 'yes'
};

export const UOM_MILES = 'mi';

export const RBD_PARTS_AVAILABILITY_ESPOT = 'RBD_PARTS_AVAILABILITY_ESPOT';
export const REQUEST_BY_DATE_PART_AVAILABILITY =
  'REQUEST_BY_DATE_PART_AVAILABILITY';
export const CONTACT_DEALER = '[CONTACT_DEALER]';
export const SHIPPING_CODE = 'ShippingCode';
export const RBD_VALUE = 'RBDValue';
export const AVAILABLE_MSG = 'AvailableMsg';
export const AVAILABLE_DATE = 'AvailableDate';

export const IS_RBD_UPDATED = 'isRBDateUpdated';
