export const normalizeCSROrders = ordersArray => {
  if (!Array.isArray(ordersArray)) {
    return [];
  }
  const arrCopy = [...ordersArray];
  const normalizedList = {};
  arrCopy.forEach((order, index) => {
    normalizedList[order.orderId] = {
      ...order,
      index
    };
  });

  return normalizedList;
};
