import PropTypes from 'prop-types';
import SelectEquipmentDrawer from './SelectEquipmentDrawer';
import { Portal } from '@cat-ecom/pcc-components';
import { DRAWERS_ROOT } from '@app/constants/targets';
const Container = ({
  drawerProps,
  onDrawerClose,
  myEquipmentList,
  isChild
}) => {
  return (
    <Portal id={DRAWERS_ROOT}>
      <SelectEquipmentDrawer
        drawerProps={drawerProps}
        onDrawerClose={onDrawerClose}
        myEquipmentList={myEquipmentList}
        isChild={isChild}
      />
    </Portal>
  );
};

Container.propTypes = {
  drawerProps: PropTypes.object,
  onDrawerClose: PropTypes.func,
  myEquipmentList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string]))
  ),
  isChild: PropTypes.bool
};

export default Container;
