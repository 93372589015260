import { STATUS } from '../../constants/commonConstants';

export const mlpInitialStore = {
  equipmentDetails: {
    data: {},
    isLoading: false
  },
  topSellingParts: {
    data: {},
    isLoading: false
  },
  partsByCategory: {
    data: [],
    isLoading: false
  },
  ssroOptions: {
    data: {
      ssroBundlesExist: null,
      bundleData: {},
      loaded: false
    },
    isLoading: true
  },
  equipmentList: {
    data: {},
    isEquipmentLoading: true,
    isLoaded: false,
    status: STATUS.IDLE
  },
  pmKitOptions: {
    data: {
      ssroBundlesExist: null,
      bundleData: {},
      loaded: false
    },
    isLoading: true,
    status: STATUS.IDLE
  }
};
