import {
  PICKUP_AND_DELIVERY_GUEST_ROUTE_LEGACY,
  PICKUP_AND_DELIVERY_ROUTE_LEGACY,
  SHOPPING_CART_ROUTE,
  SUMMARY_AND_PAYMENT_ROUTE_LEGACY,
  PICKUP_AND_DELIVERY_PAGE,
  SUMMARY_AND_PAYMENT_ROUTE,
  REPAIR_OPTIONS_ROUTE,
  OMM_CONTENT_ROUTE
} from '@app/constants/routes';
import { useLocation } from 'react-router-dom';

export const useCurrentPage = () => {
  const { pathname = '' } = useLocation();

  const isOnSummaryAndPayment =
    pathname.includes(SUMMARY_AND_PAYMENT_ROUTE_LEGACY) ||
    pathname.includes(SUMMARY_AND_PAYMENT_ROUTE);

  const isOnPickupAndDelivery =
    pathname.includes(PICKUP_AND_DELIVERY_PAGE) ||
    pathname.includes(PICKUP_AND_DELIVERY_GUEST_ROUTE_LEGACY) ||
    pathname.includes(PICKUP_AND_DELIVERY_ROUTE_LEGACY);

  const isOnCheckoutPage = isOnSummaryAndPayment || isOnPickupAndDelivery;

  const isOnShoppingCartPage = pathname.includes(SHOPPING_CART_ROUTE);

  const isOnPMKitsOrSSROPage = pathname.includes(REPAIR_OPTIONS_ROUTE);
  const isOnOMMPage = pathname.includes(OMM_CONTENT_ROUTE);

  return {
    isOnSummaryAndPayment,
    isOnPickupAndDelivery,
    isOnCheckoutPage,
    isOnShoppingCartPage,
    isOnPMKitsOrSSROPage,
    isOnOMMPage
  };
};
