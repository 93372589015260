import { ONETRUST } from '../constants/commonConstants';
import { getCookie } from './cookieUtils';

/**
 * Determine passed consent type is available in one trust consent cookie
 * @param {string} consentType one trust consent type
 * @returns
 */
export const isConsentTypeAvailable = consentType => {
  const consentCookie = getCookie(ONETRUST.OPT_CONSENT_COOKIE);
  return consentCookie?.includes(consentType) ?? false;
};
