import { getUnitOfMeasureCookie } from '@app/components/common/UnitOfMeasureToggle/utils';
import { STATUS, UOM } from '../../constants/commonConstants';

export const plpInitialStore = {
  current: '',
  sortBy: '0',
  activeFacets: [],
  getPlpDetailsStatus: STATUS.IDLE,
  getMorePlpDetailsStatus: STATUS.IDLE,
  getPrevPlpDetailsStatus: STATUS.IDLE,
  filterByEquipment: true,
  unitOfMeasure: getUnitOfMeasureCookie('uom') || UOM.us
};
