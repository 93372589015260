import {
  BANNER_TOAST_MESSAGES_SHOWED,
  OUTAGE_MESSAGE_TOKENS_NAME
} from './constants';

/**
 * use this function for replace an array of tokens in an string
 *
 * @param {string} message the message that we need to replace
 * @param {object} outageMessage object with neccessary values to insert in the message string
 * @returns {string} message with tokens replaced .
 */
export const replaceNamedTokenInString = (message = '', outageMessage = {}) => {
  let resultMessage = message;
  OUTAGE_MESSAGE_TOKENS_NAME.forEach(token => {
    const tokenKey = Object.keys(token);
    resultMessage =
      !!tokenKey &&
      resultMessage?.replace?.(tokenKey, outageMessage[Object.values(token)]);
  });

  return resultMessage;
};

/**
 * use this function to verify if a toast was showed
 *
 * @param {string} toastName the toast name
 */
export const wasToastShowed = (toastName = '') => {
  const sessionStorageToastMessages = sessionStorage.getItem(
    BANNER_TOAST_MESSAGES_SHOWED
  );
  if (sessionStorageToastMessages && sessionStorageToastMessages === 'true') {
    sessionStorage.removeItem(BANNER_TOAST_MESSAGES_SHOWED);
  } else if (sessionStorageToastMessages) {
    const showedToasts = JSON.parse(sessionStorageToastMessages);

    return showedToasts.includes(toastName);
  }
  return false;
};

/**
 * use this function to add the showed toast to the list
 *
 * @param {string} toastName the toast name
 */
export const setShowedToast = (toastName = '') => {
  let showedToasts = [];
  const sessionStorageToastMessages = sessionStorage.getItem(
    BANNER_TOAST_MESSAGES_SHOWED
  );
  if (sessionStorageToastMessages && sessionStorageToastMessages === 'true') {
    sessionStorage.removeItem(BANNER_TOAST_MESSAGES_SHOWED);
  } else if (sessionStorageToastMessages) {
    showedToasts = JSON.parse(sessionStorageToastMessages);
  }

  if (!showedToasts.includes(toastName)) {
    sessionStorage.setItem(
      BANNER_TOAST_MESSAGES_SHOWED,
      JSON.stringify([...showedToasts, toastName])
    );
  }
};
