import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { ORDER_CONFIRMATION_ROUTE } from '@app/constants/routes';

const IframeRedirect = ({ basename }) => {
  const RedirectTopToIframeLocation = () => {
    useEffect(() => {
      if (window.self !== window.top) {
        window.top.location = window.self.location;
      }
    }, []);
    return null;
  };

  return (
    <Router basename={basename}>
      <Route
        path={ORDER_CONFIRMATION_ROUTE}
        render={() => <RedirectTopToIframeLocation />}
      />
    </Router>
  );
};

IframeRedirect.propTypes = {
  basename: PropTypes.string
};

export default IframeRedirect;
