import { SAVE_TYPE } from '@app/components/pages/checkout/shopping-cart/OrderItems/constants';
import { isEmpty } from '@app/utils';
import {
  CUSTOMER_ITEM_NO_LIMIT,
  LINE_ITEM_NOTES_LIMIT,
  NEW_FIELD,
  NOTE_FIELD_VALUE,
  OLD_FIELD,
  SERIAL_NO_AND_CUST_PART_LIMIT
} from './constants';

export const checkConflict = (maxLimit, newVal = '', oldVal = '') => {
  const concatVal = `${oldVal}${newVal}`;
  return concatVal.length > maxLimit;
};
export const setConflictValue = (newVal, oldVal) => {
  if (!oldVal) {
    return newVal ?? '';
  }
  if (!newVal) {
    return oldVal ?? '';
  }
  return `${oldVal}|${newVal}`;
};
export const getUpdatedNotes = (newNotes, oldNotes) => {
  const isCustomerSerialNumberConflict = checkConflict(
    SERIAL_NO_AND_CUST_PART_LIMIT,
    newNotes.customerSerialNumber,
    oldNotes.customerSerialNumber
  );
  const isLineItemNoteConflict = checkConflict(
    CUSTOMER_ITEM_NO_LIMIT,
    newNotes.lineItemNo,
    oldNotes.lineItemNo
  );
  const isCustomerPartNumberConflict = checkConflict(
    SERIAL_NO_AND_CUST_PART_LIMIT,
    newNotes.customerPartNumber,
    oldNotes.customerPartNumber
  );
  const isPartNoteConflict = checkConflict(
    LINE_ITEM_NOTES_LIMIT,
    newNotes.partNote,
    oldNotes.partNote
  );
  return {
    customerSerialNumber: {
      conflictValue: setConflictValue(
        newNotes.customerSerialNumber,
        oldNotes.customerSerialNumber
      ),
      isConflict: isCustomerSerialNumberConflict,
      newNotesVal: newNotes.customerSerialNumber,
      oldNotesVal: oldNotes.customerSerialNumber
    },
    partNote: {
      conflictValue: setConflictValue(newNotes.partNote, oldNotes.partNote),
      isConflict: isPartNoteConflict,
      newNotesVal: newNotes.partNote,
      oldNotesVal: oldNotes.partNote
    },
    customerPartNumber: {
      conflictValue: setConflictValue(
        newNotes.customerPartNumber,
        oldNotes.customerPartNumber
      ),
      isConflict: isCustomerPartNumberConflict,
      newNotesVal: newNotes.customerPartNumber,
      oldNotesVal: oldNotes.customerPartNumber
    },
    lineItemNo: {
      conflictValue: setConflictValue(newNotes.lineItemNo, oldNotes.lineItemNo),
      isConflict: isLineItemNoteConflict,
      newNotesVal: newNotes.lineItemNo,
      oldNotesVal: oldNotes.lineItemNo
    }
  };
};

export const keyValue = key => {
  if (key === 'lineItemNo') {
    return 'customerItemNumber';
  }
  if (key === 'partNote') {
    return 'lineItemNotes';
  }
  if (key === 'customerSerialNumber') {
    return 'serialNumber';
  }
  if (key === 'customerPartNumber') {
    return 'customerPartNumber';
  }
  return key;
};

export const oldItemNotes = (ascendingOrderItemsOld, saveListParts) => {
  const oldLineItemParts = ascendingOrderItemsOld?.filter(a =>
    saveListParts?.some(s => s.partNumber === a.partNumber)
  );
  return oldLineItemParts?.map(oldLineNote => {
    return {
      partNumber: oldLineNote?.partNumber,
      partName: oldLineNote?.name,
      quantity: oldLineNote?.quantity,
      lineItemNotes: oldLineNote?.orderItemExtendAttribute?.lineItemNotes
    };
  });
};

export const newItemNotes = (ascendingOrderItemsNew, saveListParts) => {
  const newLineItemParts = ascendingOrderItemsNew?.filter(a =>
    saveListParts?.some(s => s.partNumber === a.partNumber)
  );
  return newLineItemParts?.map(newLineNote => {
    return {
      partNumber: newLineNote?.partNumber,
      partName: newLineNote?.name,
      quantity: newLineNote?.quantity,
      lineItemNotes: newLineNote?.orderItemExtendAttribute?.lineItemNotes
    };
  });
};

export const getFieldState = (id, name) => {
  const fieldState = id?.split('_').pop();
  return fieldState === OLD_FIELD
    ? `${name}_${NEW_FIELD}`
    : `${name}_${OLD_FIELD}`;
};

export const getConflictData = data => {
  const saveListData = [];
  for (const [key, value] of Object.entries(data)) {
    const conflictObject = {};
    const splitKey = key?.split('_');
    if (value.radioValue) {
      conflictObject[keyValue(splitKey[1])] = value.inputValue;
    }
    if (!isEmpty(conflictObject)) {
      saveListData.push({
        partNumber: value?.partNumber,
        quantity: value?.quantity,
        ...conflictObject
      });
    }
  }
  const uniquePartObject = [];
  saveListData.forEach(item => {
    uniquePartObject[item.partNumber] = {
      ...(uniquePartObject[item.partNumber] ?? {}),
      ...item
    };
  });
  return Object.entries(uniquePartObject).map(([_name, item]) => ({
    ...item
  }));
};

export const getNonConflictingItems = (partsList, conflictingParts) => {
  return partsList?.filter(
    part =>
      !conflictingParts.find(
        conflictingPart => conflictingPart.partNumber === part.partNumber
      )
  );
};

export const getConflictInitialValue = partList => {
  return partList.reduce((acc, cur) => {
    const conflictingNotes = Object.keys(cur.lineItemNotes).reduce(
      (accum, curr) => {
        if (cur.lineItemNotes[curr].isConflict) {
          return {
            ...accum,
            [`${cur.partNumber}_${curr}_${OLD_FIELD}`]: {
              radioValue: true,
              inputValue: cur.lineItemNotes[curr].oldNotesVal,
              partNumber: cur.partNumber,
              quantity: cur.quantity
            },
            [`${cur.partNumber}_${curr}_${NEW_FIELD}`]: {
              radioValue: false,
              inputValue: cur.lineItemNotes[curr].newNotesVal,
              partNumber: cur.partNumber,
              quantity: cur.quantity
            }
          };
        }
        return accum;
      },
      {}
    );
    return {
      ...acc,
      ...conflictingNotes
    };
  }, {});
};

export const getFieldContents = item => {
  return Object.keys(item.lineItemNotes).reduce((acc, cur) => {
    const name = `${item.partNumber}_${cur}`;
    const oldNotesRadioId = `${name}_${OLD_FIELD}`;
    const newNotesRadioId = `${name}_${NEW_FIELD}`;
    if (item.lineItemNotes[cur].isConflict) {
      return [
        ...acc,
        {
          noteKey: cur,
          name,
          oldNotesRadioId,
          newNotesRadioId,
          value: item.lineItemNotes[cur],
          translationNotes: NOTE_FIELD_VALUE[cur]
        }
      ];
    }

    return acc;
  }, []);
};

const addItemsPayloadAll = (newLineItemNotes, items) =>
  newLineItemNotes.reduce((prev, current) => {
    const lineItemNotesObj = current.orderItemExtendAttribute?.lineItemNotes;
    const itemsData = items?.find(
      item =>
        current.partNumber === item.partNumber &&
        current.quantity === item.quantity
    );
    const currentObj = itemsData?.quantity &&
      itemsData?.partNumber && {
        partNumber: itemsData?.partNumber,
        quantity: itemsData?.quantity,
        customerPartNumber: lineItemNotesObj.customerPartNumber,
        serialNumber: lineItemNotesObj.customerSerialNumber,
        lineItemNotes: lineItemNotesObj.partNote,
        customerItemNumber: lineItemNotesObj.lineItemNo
      };
    return [...prev, currentObj];
  }, []);

const addItemsPayloadDefault = (newLineItemNotes, items) => {
  let addItemsPayloadData = [];
  newLineItemNotes.forEach(newNotesItem => {
    items.forEach(savedListItem => {
      const lineItemNotesObj =
        newNotesItem.orderItemExtendAttribute?.lineItemNotes;
      if (
        newNotesItem.partNumber === savedListItem.partNumber &&
        newNotesItem.quantity === savedListItem.quantity
      ) {
        addItemsPayloadData = [
          ...addItemsPayloadData,
          {
            partNumber: savedListItem.partNumber,
            quantity: savedListItem.quantity,
            customerPartNumber: lineItemNotesObj.customerPartNumber,
            serialNumber: lineItemNotesObj.customerSerialNumber,
            lineItemNotes: lineItemNotesObj.partNote,
            customerItemNumber: lineItemNotesObj.lineItemNo
          }
        ];
      }
    });
  });
  return [...new Map(addItemsPayloadData.map(m => [m.partNumber, m])).values()];
};

export const addItemsPayload = (newLineItemNotes, items, type) => {
  if (type === SAVE_TYPE.DEFAULT) {
    return addItemsPayloadDefault(newLineItemNotes, items);
  } else {
    return addItemsPayloadAll(newLineItemNotes, items);
  }
};
