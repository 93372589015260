import { Address } from '@cat-ecom/pcc-components';
import { useSelector } from 'react-redux';

const StoreAddress = () => {
  const address1 = useSelector(state => state.store?.address);
  const city = useSelector(state => state.store?.city);
  const country = useSelector(state => state.store?.country);
  const state = useSelector(state => state.store?.state);
  const zipCode = useSelector(state => state.store?.zipcode);
  const locale = useSelector(state => state.store?.locale);
  const storeName = useSelector(state => state.store?.name);

  const addressObj = {
    address1,
    city,
    country,
    state,
    zipCode
  };

  return (
    <>
      <p className="mb-0 exclude-global">
        <strong>{storeName}</strong>
      </p>
      <Address locale={locale} address={addressObj} />
    </>
  );
};

export default StoreAddress;
