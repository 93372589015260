import { Toggle, Popup, AlloyButton } from 'cat-ecommerce-alloy';
import { useTranslation } from 'react-i18next';
import Field from '../../../../common/form/Field';
import styles from './DealerNotificationToggle.module.scss';

const DealerNotificationToggle = () => {
  const { t } = useTranslation();
  const getButton = () => (
    <AlloyButton
      className={`${styles['dealer-notification-details-button']} mb-2 ps-0 d-block`}
      buttonType="link"
    >
      {t('MEQ_ADD_NOTIFICATION4')}
    </AlloyButton>
  );
  const getContent = () => (
    <p
      className={`${styles['dealer-notification-popup-content']} mb-0 text-xs`}
    >
      {t('MEQ_ADD_NOTIFICATION3')}
    </p>
  );
  return (
    <>
      <Field
        id="notifyDealer"
        name="notifyDealer"
        type="switch"
        as={Toggle}
        label={t('MEQ_ADD_NOTIFICATION1')}
        placement="start"
      />
      <Popup
        contents={getContent()}
        initiator={getButton()}
        popperOptions={{ placement: 'bottom' }}
      />
    </>
  );
};

export default DealerNotificationToggle;
