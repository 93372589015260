import endpoints from '@app/constants/endpoints';
import { replaceTokensInString } from '@app/utils';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MyEquipmentSerialPrefix } from '@cat-ecom/pcc-components';
import PropTypes from 'prop-types';
import { CatSkeleton } from 'blocks-react/bedrock/components/Skeleton';
import Conditional from '@app/components/common/Conditional/Conditional';
import { useHttp } from '@app/hooks';

const SerialPrefix = ({ model, productId, equipmentUniqueId }) => {
  const { invoke, data, loading } = useHttp();
  const [t] = useTranslation();
  const { langId, storeId, locale, storeIdentifier } = useSelector(
    state => state.common
  );
  const isEquipmentComponentFlag = useSelector(
    state => state.featureFlag.PCCEquipmentComponentFlag
  );
  const { prefixList } = data ?? [];
  const serialSuggestions = useMemo(() => {
    if (Array.isArray(prefixList)) {
      return prefixList.join(', ');
    }
    return '';
  }, [prefixList]);
  useEffect(() => {
    const url = isEquipmentComponentFlag
      ? replaceTokensInString(
          endpoints.LUCID_SERIAL_PREFIX_SUGGESTIONS,
          locale,
          storeIdentifier,
          equipmentUniqueId
        )
      : replaceTokensInString(
          endpoints.SERIAL_PREFIX_SUGGESTIONS,
          storeId,
          model,
          productId,
          langId,
          storeId
        );
    invoke({ url });
  }, [model, productId, invoke, langId, storeId]);
  return (
    <div className="mt-2 mb-2 pb-1">
      <Conditional test={!loading} fallback={<CatSkeleton size="sm" />}>
        <MyEquipmentSerialPrefix
          prefixLabel={t('SUGGESTED_PREFIXES')}
          suggestions={serialSuggestions}
        />
      </Conditional>
    </div>
  );
};
SerialPrefix.propTypes = {
  model: PropTypes.string,
  productId: PropTypes.string,
  equipmentUniqueId: PropTypes.string
};
export default SerialPrefix;
