import Conditional from '@app/components/common/Conditional';
import Espot from '@app/components/common/Espot/Espot';
import { useEspot } from '@app/hooks';
import { AlloyButton, ModalContext } from 'cat-ecommerce-alloy';
import PropTypes from 'prop-types';
import { memo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ChildModalEspot.scss';

const ChildModalEspot = ({ espotName, scrollIntoViewId, storeId }) => {
  const [t] = useTranslation();
  const modalCtx = useContext(ModalContext);
  const { isEspotLoading, getEspotContent } = useEspot(espotName, '', storeId);
  const espotHtml = getEspotContent(espotName);

  useEffect(() => {
    if (isEspotLoading) {
      modalCtx.showLoader();
    } else {
      modalCtx.hideLoader();
    }
  }, [isEspotLoading, modalCtx]);

  useEffect(() => {
    if (espotHtml && scrollIntoViewId) {
      const divToScrollIntoView = document.getElementById(scrollIntoViewId);
      divToScrollIntoView && divToScrollIntoView.scrollIntoView();
    }
  }, [espotHtml, scrollIntoViewId]);

  return (
    <div>
      <div
        className={`overflow-auto ${styles['child-modal-espot__container-espot']}`}
      >
        <Conditional test={!!espotHtml}>
          <Espot html={espotHtml} />
        </Conditional>
      </div>
      <div className="text-center mt-3">
        <AlloyButton
          buttonType="secondary"
          className="col-12 col-lg-auto"
          onClick={modalCtx.removeCurrentModal}
        >
          {t('CLOSE')}
        </AlloyButton>
      </div>
    </div>
  );
};

ChildModalEspot.propTypes = {
  espotName: PropTypes.string,
  scrollIntoViewId: PropTypes.string,
  storeId: PropTypes.string
};

export default memo(ChildModalEspot);
