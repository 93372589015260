import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

const useRetryAction = (shouldRetry, action) => {
  const dispatch = useDispatch();
  const [retried, setRetried] = useState(false);

  useEffect(() => {
    if (!retried && shouldRetry) {
      dispatch(action());
      setRetried(true);
    }
  }, [retried, shouldRetry, action, dispatch]);
  return null;
};

export default useRetryAction;

useRetryAction.propTypes = {
  shouldRetry: PropTypes.bool.isRequired,
  action: PropTypes.func.isRequired
};
