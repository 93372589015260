import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext, ToastContext } from 'cat-ecommerce-alloy';
import useHttp from './useHttp';
import endpoints from '../constants/endpoints';
import { getCurrentOrderInfo } from '../store/orders/actions';
import { replaceTokensInUrl } from '../utils';
import CartConfirmationModal from '../components/common/modals/CartConfirmationModal';

import {
  ADD_TO_CART_ONCOMPLETE_RENDER,
  TOAST_TYPE
} from '@app/constants/commonConstants';
import AddToCartToast from '@app/components/common/toasts/AddToCartToast';

const useAddToCart = (
  addToCartOnComplete = ADD_TO_CART_ONCOMPLETE_RENDER.MODAL
) => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(null);
  const storeId = useSelector(s => s.common?.storeId);
  const langId = useSelector(s => s.common?.langId);
  const url = replaceTokensInUrl(
    endpoints.BUNDLES_ADD_TO_CART_URL,
    storeId,
    langId
  );
  const { addModalWithOptions } = useContext(ModalContext);
  const { createSuccessToast, createErrorToast } = useContext(ToastContext);
  const { data, error, invoke, loading } = useHttp();
  const isToastOnAddCartComplete =
    addToCartOnComplete === ADD_TO_CART_ONCOMPLETE_RENDER.TOAST;
  const skipMessageElement =
    addToCartOnComplete === ADD_TO_CART_ONCOMPLETE_RENDER.SKIP;
  const isBlocksToast =
    addToCartOnComplete === ADD_TO_CART_ONCOMPLETE_RENDER.BLOCKS_TOAST;

  useEffect(() => {
    if (data) {
      dispatch(getCurrentOrderInfo({ cartItems: false }));
      const { orderItem } = data;
      const normalizedItems = Array.isArray(orderItem)
        ? orderItem.map(item => ({
            ...item,
            cartQuantity: item.quantity
          }))
        : [];

      if (isToastOnAddCartComplete) {
        createSuccessToast(<AddToCartToast items={normalizedItems} />);
      } else if (isBlocksToast) {
        setSuccess(true);
      } else if (!skipMessageElement) {
        addModalWithOptions(<CartConfirmationModal items={normalizedItems} />);
      }
    }
  }, [
    addModalWithOptions,
    isToastOnAddCartComplete,
    createSuccessToast,
    data,
    dispatch
  ]);

  useEffect(() => {
    if (error) {
      if (isToastOnAddCartComplete) {
        createErrorToast(<AddToCartToast type={TOAST_TYPE.FAIL} />);
      }
    }
  }, [isToastOnAddCartComplete, createErrorToast, error]);

  /**
   * @param {{quantity: Number, productId: String}[]} items Items to be added to the cart
   * @param {Object} params Additional post params to be sent
   * @param {boolean} rethrowError Pass in true to rethrow error and handle it in instance
   */
  const addToCart = useCallback(
    (items, params, rethrowError) => {
      const body = {
        orderId: '.',
        orderItem: items ?? [],
        x_inventoryValidation: 'true',
        ...params
      };
      // TODO: What should the timeout be?
      return invoke({
        data: body,
        method: 'post',
        url,
        rethrowError
      });
    },
    [invoke, url]
  );
  return { addToCart, error, loading, success, setSuccess };
};

export default useAddToCart;
