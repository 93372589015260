export const MLP_LOAD_EQUIPMENTDETAILS_BEGIN =
  'MLP_LOAD_EQUIPMENTDETAILS_BEGIN';
export const MLP_LOAD_EQUIPMENTDETAILS_SUCCESS =
  'MLP_LOAD_EQUIPMENTDETAILS_SUCCESS';
export const MLP_LOAD_EQUIPMENTDETAILS_FAIL = 'MLP_LOAD_EQUIPMENTDETAILS_FAIL';
export const MLP_LOAD_TOPSELLINGPARTS_BEGIN = 'MLP_LOAD_TOPSELLINGPARTS_BEGIN';
export const MLP_LOAD_TOPSELLINGPARTS_SUCCESS =
  'MLP_LOAD_TOPSELLINGPARTS_SUCCESS';
export const MLP_LOAD_TOPSELLINGPARTS_FAIL = 'MLP_LOAD_TOPSELLINGPARTS_FAIL';
export const MLP_LOAD_MLP_SUCCESS = 'MLP_LOAD_MLP_SUCCESS';
export const MLP_LOAD_CATEGORIES_BEGIN = 'MLP_LOAD_CATEGORIES_BEGIN';
export const MLP_LOAD_CATEGORIES_SUCCESS = 'MLP_LOAD_CATEGORIES_SUCCESS';
export const MLP_LOAD_CATEGORIES_FAIL = 'MLP_LOAD_CATEGORIES_FAIL';
export const MLP_LOAD_SSRO_BEGIN = 'MLP_LOAD_SSRO_BEGIN';
export const MLP_LOAD_SSRO_PM_KIT_BEGIN = 'MLP_LOAD_SSRO_PM_KIT_BEGIN';
export const MLP_LOAD_SSRO_MODEL_SUCCESS = 'MLP_LOAD_SSRO_MODEL_SUCCESS';
export const MLP_LOAD_SSRO_SERIAL_SUCCESS = 'MLP_LOAD_SSRO_SERIAL_SUCCESS';
export const MLP_LOAD_SSRO_FAIL = 'MLP_LOAD_SSRO_FAIL';
export const PMKIT_EQUIPMENTDETAILS_BEGIN = 'PMKIT_EQUIPMENTDETAILS_BEGIN';
export const PMKIT_EQUIPMENTDETAILS_SUCCESS = 'PMKIT_EQUIPMENTDETAILS_SUCCESS';
export const PMKIT_EQUIPMENTDETAILS_FAIL = 'PMKIT_EQUIPMENTDETAILS_FAIL';
export const ELECTRIC_MACHINE_CODE = 'ET';
export const LUCID_BEST_SELLER_BEGIN = 'LUCID_BEST_SELLER_BEGIN';
export const LUCID_BEST_SELLER_SUCCESS = 'LUCID_BEST_SELLER_SUCCESS';
export const LUCID_BEST_SELLER_FAIL = 'LUCID_BEST_SELLER_FAIL';
