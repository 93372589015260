export const GET_HEADER_NOTIFICATION_BEGIN = 'GET_HEADER_NOTIFICATION_BEGIN';
export const GET_HEADER_NOTIFICATION_SUCCESS =
  'GET_HEADER_NOTIFICATION_SUCCESS';
export const GET_HEADER_NOTIFICATION_FAIL = 'GET_HEADER_NOTIFICATION_FAIL';
export const NO_DISMISS_NOTIFICATIONS_BEGIN = 'NO_DISMISS_NOTIFICATIONS_BEGIN';
export const NO_DISMISS_NOTIFICATIONS_SUCCESS =
  'NO_DISMISS_NOTIFICATIONS_SUCCESS';
export const NO_DISMISS_NOTIFICATIONS_FAIL = 'NO_DISMISS_NOTIFICATIONS_FAIL';
export const READ_NOTIFICATION_BEGIN = 'READ_NOTIFICATION_BEGIN';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';
export const READ_NOTIFICATION_FAIL = 'READ_NOTIFICATION_FAIL';
export const DISMISS_NOTIFICATION_BEGIN = 'DISMISS_NOTIFICATION_BEGIN';
export const DISMISS_NOTIFICATION_SUCCESS = 'DISMISS_NOTIFICATION_SUCCESS';
export const DISMISS_NOTIFICATION_FAIL = 'DISMISS_NOTIFICATION_FAIL';
export const DISMISS_ALL_FOR_CURRENT_PAGE_NOTIFICATION_BEGIN =
  'DISMISS_ALL_FOR_CURRENT_PAGE_NOTIFICATION_BEGIN';
export const DISMISS_ALL_FOR_CURRENT_PAGE_NOTIFICATION_SUCCESS =
  'DISMISS_ALL_FOR_CURRENT_PAGE_NOTIFICATION_SUCCESS';
export const DISMISS_ALL_FOR_CURRENT_PAGE_NOTIFICATION_FAIL =
  'DISMISS_ALL_FOR_CURRENT_PAGE_NOTIFICATION_FAIL';
export const DISMISS_NOTIFICATION_SUCCESS_BANNER =
  'DISMISS_NOTIFICATION_SUCCESS_BANNER';
export const INCLUDE_DISMISSED_NOTIFICATIONS =
  'INCLUDE_DISMISSED_NOTIFICATIONS';
export const NOTIFICATION_FILTER = 'NOTIFICATION_FILTER';
export const FILTER_NOTIFICATIONS_BEGIN = 'FILTER_NOTIFICATIONS_BEGIN';
export const FILTER_NOTIFICATIONS_SUCCESS = 'FILTER_NOTIFICATIONS_SUCCESS';
export const FILTER_NOTIFICATIONS_FAIL = 'FILTER_NOTIFICATIONS_FAIL';
export const NOTIFICATION_FILTER_BEGIN = 'NOTIFICATION_FILTER_BEGIN';
export const NOTIFICATION_FILTER_SUCCESS = 'NOTIFICATION_FILTER_SUCCESS';
export const NOTIFICATION_FILTER_ERROR = 'NOTIFICATION_FILTER_ERROR';
export const NOTIFICATION_GET_DEALER_ACCOUNTS_SUCCESS =
  'NOTIFICATION_GET_DEALER_ACCOUNTS_SUCCESS';
export const NOTIFICATION_GET_DEALER_ACCOUNTS_FAIL =
  'NOTIFICATION_GET_DEALER_ACCOUNTS_FAIL';
