export const DEALER_CUSTOMER_STORES_SET_INFO =
  'DEALER_CUSTOMER_STORES_SET_INFO';
export const DEALER_CUSTOMER_STORES_GET_BEGIN =
  'DEALER_CUSTOMER_STORES_GET_BEGIN';
export const DEALER_CUSTOMER_STORES_GET_SUCCESS =
  'DEALER_CUSTOMER_STORES_GET_SUCCESS';
export const DEALER_CUSTOMER_STORES_GET_FAIL =
  'DEALER_CUSTOMER_STORES_GET_FAIL';
export const DEALER_CUSTOMER_SHARE_UCID_LIST_BEGIN =
  'DEALER_CUSTOMER_SHARE_UCID_LIST_BEGIN';
export const DEALER_CUSTOMER_SHARE_UCID_LIST_SUCCESS =
  'DEALER_CUSTOMER_SHARE_UCID_LIST_SUCCESS';
export const DEALER_CUSTOMER_SHARE_UCID_LIST_FAIL =
  'DEALER_CUSTOMER_SHARE_UCID_LIST_FAIL';
export const DEALER_CUSTOMER_SHARE_UCID_LIST_STATUS_IDLE =
  'DEALER_CUSTOMER_SHARE_UCID_LIST_STATUS_IDLE';
