import {
  NUMBER_REGEX,
  ALPHA_NUMERIC_REGEX
} from '@app/constants/commonConstants';

export const validateSerialNumber = searchString => {
  const first3char = searchString.substr(0, 3);
  let isValid = false;
  const suffixValue = searchString.slice(3);
  const numberRegex = new RegExp(NUMBER_REGEX);
  const alphaNumericRegex = new RegExp(ALPHA_NUMERIC_REGEX);

  if (searchString.length <= 3 && alphaNumericRegex.test(first3char)) {
    isValid = true;
  } else if (numberRegex.test(suffixValue)) {
    const suffixValueAsNumber = +suffixValue;

    if (suffixValueAsNumber === 0) {
      isValid = searchString.length < 8;
    } else {
      isValid = suffixValueAsNumber > 0;
    }
  }

  return {
    isValid,
    searchError: !isValid ? 'MEQ_SERIAL_INVALID' : ''
  };
};

export const isServerError = errorState => {
  return (
    errorState?.serial?.type === 'serverError' ||
    errorState?.model?.type === 'serverError'
  );
};
