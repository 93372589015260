import PropTypes from 'prop-types';
import {
  AssociatedDealerForm,
  AssociatedDealerInput,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  AssociatedDealerBanner
} from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import {
  SEARCH_CUSTOMER_FORM_ID,
  ADDRESS_INPUT_NAME,
  CITY_INPUT_NAME,
  CUSTOMER_NUMBER_INPUT_NAME,
  ZIP_CODE_INPUT_NAME,
  ERRORS
} from '../../constants';
import { AssociatedDealerDealerDropdownWrapper } from '../../AssociatedDealerFields';
import styles from '../../styles.module.scss';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import { CatLoadingIndicator } from 'blocks-react/bedrock/components/LoadingIndicator';
import Conditional from '@app/components/common/Conditional/Conditional';

export const AssociatedDealerSearchCustomerForm = ({
  formMethods,
  onSubmit,
  dealer,
  loading,
  error
}) => {
  const [t] = useTranslation();
  const id = SEARCH_CUSTOMER_FORM_ID;
  const title = t('CAT_MA_ASSOCIATEDDEALERS');
  const labelCustomerNumberText = t('CUST_NAME_NUMBER_COMBINED');
  const labelAddressText = t('ADDRESS_TITLE');
  const labelCityText = t('CAT_EMAIL_CITY');
  const labelZipCodeText = t('ZIPCODE_POSTALCODE');
  const wildCardSearchText = t('WILDCARD_SEARCH');
  const isErrorFields = error === ERRORS.NO_SEARCH_VALUES;
  const errorMessage = () => {
    if (error === ERRORS.NO_RESULTS) {
      return t('SEARCH_GENERIC_NO_RESULTS');
    } else if (error === ERRORS.NO_SEARCH_VALUES) {
      return t('ADV_SEARCH_NULL_ERROR');
    } else if (error === ERRORS.NO_VALID_DCN_NO_STORES) {
      const noStores = t('VALID_DCN_NO_STORES');
      const noStoreSysAdmin = t('VALID_DCN_NO_STORES_SYS_ADMIN');
      return noStores + ' ' + noStoreSysAdmin;
    }
  };

  return (
    <>
      <DrawerHeader>
        <h4 className="cat-u-theme-typography-title m-0">{title}</h4>
      </DrawerHeader>
      <DrawerBody className={styles['drawer-body']}>
        <Conditional test={error}>
          <AssociatedDealerBanner variant="error">
            {errorMessage()}
          </AssociatedDealerBanner>
        </Conditional>
        <div className={styles['wild-card-text']}>{wildCardSearchText}</div>
        <AssociatedDealerForm
          id={id}
          className={styles['drawer-form']}
          formMethods={formMethods}
          onSubmit={onSubmit}
        >
          <AssociatedDealerDealerDropdownWrapper dealer={dealer} />
          <AssociatedDealerInput
            label={labelCustomerNumberText}
            name={CUSTOMER_NUMBER_INPUT_NAME}
            isError={isErrorFields}
            disabled={loading}
          />
          <AssociatedDealerInput
            label={labelAddressText}
            name={ADDRESS_INPUT_NAME}
            isError={isErrorFields}
            disabled={loading}
          />
          <AssociatedDealerInput
            label={labelCityText}
            name={CITY_INPUT_NAME}
            isError={isErrorFields}
            disabled={loading}
          />
          <AssociatedDealerInput
            label={labelZipCodeText}
            name={ZIP_CODE_INPUT_NAME}
            isError={isErrorFields}
            disabled={loading}
          />
        </AssociatedDealerForm>
      </DrawerBody>
      <DrawerFooter className={styles['drawer-footer']}>
        <CatButton
          variant="primary"
          onClick={formMethods.handleSubmit(onSubmit)}
          disabled={loading}
          className={styles['cat-buttons']}
        >
          {t('SEARCH_RESULTS_TEXT1')}
          <Conditional test={loading}>
            <CatLoadingIndicator slot={'after'} small={true} />
          </Conditional>
        </CatButton>
      </DrawerFooter>
    </>
  );
};

AssociatedDealerSearchCustomerForm.propTypes = {
  formMethods: PropTypes.any,
  onSubmit: PropTypes.func,
  dealer: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  loading: PropTypes.bool,
  error: PropTypes.string
};
