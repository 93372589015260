import * as ACTION from './constants';
import { STATUS } from '@app/constants/commonConstants';
import { shoppingPreferencesInitialStore } from './initialStore';
import {
  preferencesRestore,
  preferenceUpdateSuccess,
  loadSuccess,
  loadStoresSuccess,
  newStoreSuccess
} from './reducerUtil';

const ShoppingPreferencesReducer = (
  state = shoppingPreferencesInitialStore,
  action = {}
) => {
  switch (action.type) {
    case ACTION.SP_TOASTER: {
      return {
        ...state,
        toaster: action.payload
      };
    }
    case ACTION.SP_FULFILLMENT_CHANGE: {
      return {
        ...state,
        askChangeFulfillment: true
      };
    }
    case ACTION.SP_PREFERENCES_RESTORE:
      return preferencesRestore(state, action);
    case ACTION.SP_NEW_STORE_SUCCESS:
      return newStoreSuccess(state, action);
    case ACTION.SP_NEW_STORE_FAIL: {
      return {
        ...state,
        isLoading: false,
        heading: { onlyShowHeader: true, showApply: true }
      };
    }
    case ACTION.SP_NEW_STORE_BEGIN: {
      return {
        ...state,
        isLoading: true,
        toaster: { submitSuccess: true },
        askResetPreferences: false,
        heading: { showApply: true, onlyShowHeader: true }
      };
    }
    case ACTION.SP_DEALER_STORE_CHANGE:
      const selectedDealerStore = action.payload.storeName;
      return {
        ...state,
        heading: { ...state?.heading, validationStore: true },
        selectedDealerStore
      };
    case ACTION.SP_ACCOUNT_CHANGE: {
      const selectedAccount = action.payload.accountName;
      return {
        ...state,
        selectedAccount
      };
    }
    case ACTION.SP_LOAD_DSN_STORES_BEGIN:
      return { ...state, askResetPreferences: false, isLoading: true };
    case ACTION.SP_LOAD_DSN_STORES_FAIL: {
      const shoppingPreferencesData = {
        ...state.shoppingPreferencesData,
        dealerStore: []
      };

      return {
        ...state,
        isLoading: false,
        shoppingPreferencesData,
        heading: { onlyShowHeader: true, showApply: false }
      };
    }
    case ACTION.SP_LOAD_DSN_STORES_SUCCESS:
      return loadStoresSuccess(state, action);
    case ACTION.SP_UPDATE_BEGIN:
      return { ...state, isSubmitting: true, toaster: {} };
    case ACTION.SP_UPDATE_SUCCESS:
      return preferenceUpdateSuccess(state, action);
    case ACTION.SP_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        error: action.payload
      };
    case ACTION.SP_SIMPLE_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        shoppingPreferencesData: {
          ...state.shoppingPreferencesData,
          ...action.payload
        }
      };
    case ACTION.SP_LOAD_BEGIN:
      return {
        ...state,
        error: undefined,
        isLoading: true,
        selectedDealerStore: '',
        scrollTo: action.payload?.scrollTo,
        toaster: {},
        shoppingPreferenceDataStatus: STATUS.PENDING
      };
    case ACTION.SP_LOAD_SUCCESS:
      return loadSuccess(state, action);
    case ACTION.SP_LOAD_FAIL:
      return {
        ...state,
        error: true,
        isLoading: false,
        scrollTo: undefined,
        shoppingPreferenceDataStatus: STATUS.REJECTED
      };
    case ACTION.SP_DEFAULT_LANGUAGE_UPDATE: {
      return {
        ...state,
        updatedDefaultLanguage: action.payload.defaultLanguage
      };
    }
    case ACTION.UPDATE_IMAGE_PREFERENCE: {
      return {
        ...state,
        isSubmitting: false,
        shoppingPreferencesData: {
          ...state.shoppingPreferencesData,
          imageSettings: state.shoppingPreferencesData.imageSettings.map(
            item => {
              return {
                ...item,
                default: action.payload
              };
            }
          )
        }
      };
    }
    case ACTION.SHOPPING_PREFERENCES_BEGIN: {
      return {
        ...state,
        shoppingPreferencesData: {},
        shoppingPreferenceDataStatus: STATUS.PENDING
      };
    }
    case ACTION.SHOPPING_PREFERENCES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        shoppingPreferencesData: {
          ...state.shoppingPreferencesData,
          ...data
        },
        shoppingPreferenceDataStatus: STATUS.RESOLVED
      };
    }
    case ACTION.SHOPPING_PREFERENCES_FAIL: {
      return {
        ...state,
        shoppingPreferencesData: {},
        shoppingPreferenceDataStatus: STATUS.REJECTED
      };
    }
    default:
      return state;
  }
};
export { shoppingPreferencesInitialStore };
export default ShoppingPreferencesReducer;
