import * as types from './constants';
import { getHttpInstance } from '@app/services/defaultHttpService';
import { TIMEOUT_EXTENDED } from '@app/constants/commonConstants';
import { clearError, setError } from '../exception/actions';
import {
  CSR_SEARCH_ERROR,
  ERROR_DOMAIN,
  ERROR_PATH
} from '@app/constants/errorConstants';

import {
  SEARCH_FIELDS,
  CSR_SEARCH_TIMEOUT_ERROR,
  CSR_UNAUTHORIZED_ACTION_ERROR
} from '@app/components/pages/csr-landing/texts';

export const getCustomersData =
  (url, searchTerms, itemsPerPage, pageNumber) => async dispatch => {
    dispatch(
      clearError(ERROR_DOMAIN.CSR_LANDING_PAGE, ERROR_PATH.SEARCH_RESULTS)
    );
    dispatch(
      clearError(
        ERROR_DOMAIN.CSR_LANDING_PAGE,
        ERROR_PATH.CSR_UNAUTHORIZED_ACTION
      )
    );
    dispatch({
      type: types.CUSTOMERS_SET_DATA_BEGIN
    });
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    try {
      const { data = {} } = await http.get(
        `${url}?${searchTerms}&pageSize=${itemsPerPage}&pageNumber=${pageNumber}`
      );
      return data;
    } catch ({ response }) {
      const { status, data } = response;
      dispatch({
        type: types.CUSTOMERS_SET_DATA_FAIL
      });

      if (
        data?.errors[0]?.errorKey === 'ERR_COOKIE_EXPIRED' ||
        data?.errors[0]?.errorKey === '_ERR_INVALID_COOKIE'
      ) {
        dispatch(
          setError(
            ERROR_DOMAIN.CSR_LANDING_PAGE,
            ERROR_PATH.CSR_UNAUTHORIZED_ACTION,
            CSR_UNAUTHORIZED_ACTION_ERROR
          )
        );

        return;
      } else if (status === 408) {
        const errorParameters = data?.errors[0]?.errorParameters;
        const problemFields =
          errorParameters.length &&
          errorParameters.map(value => SEARCH_FIELDS[value]).join(', ');
        dispatch(
          setError(
            ERROR_DOMAIN.CSR_LANDING_PAGE,
            ERROR_PATH.CSR_SEARCH_RESULTS_TIMEOUT,
            {
              ...CSR_SEARCH_TIMEOUT_ERROR,
              message: `${CSR_SEARCH_TIMEOUT_ERROR.message} ${problemFields}`
            }
          )
        );

        return;
      }

      dispatch(
        setError(
          ERROR_DOMAIN.CSR_LANDING_PAGE,
          ERROR_PATH.SEARCH_RESULTS,
          CSR_SEARCH_ERROR
        )
      );
    }
  };

export const setCustomersData = customers => dispatch => {
  return dispatch({
    type: types.CUSTOMERS_SET_DATA_SUCCESS,
    payload: customers
  });
};

export const setCustomersRedirection = redirectionPoint => dispatch => {
  return dispatch({
    type: types.CUSTOMERS_REDIRECTION,
    payload: redirectionPoint
  });
};
