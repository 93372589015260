import { RTL_LANGUAGES } from '../constants/languageConstants';

/**
 * Trunc a locale string in its short shape
 * @param {string} originalLocale Locale String Identifier
 * @returns {string}
 */
export const getShortLocale = originalLocale => {
  let shortLocale = originalLocale;
  if (originalLocale?.includes('_')) {
    shortLocale = originalLocale.split('_').shift();
  }
  return shortLocale;
};

/**
 * Checks if a given language is RTL
 * @param {number} langId Id of the language to checks
 * @returns {boolean}
 */
export const isRTLLang = langId => RTL_LANGUAGES.includes(langId);
