import PropTypes from 'prop-types';
import SaveToListButton from '@app/components/common/buttons/SaveToListButton';

const CartSaveToListButton = ({ item }) => {
  const { partNumber, quantity } = item;

  // TODO: Add ability to handle SSRO Attribution
  // to useSavedList, then we can use new Save List Button/Modal
  return (
    <div className="d-print-none">
      <SaveToListButton itemPartNumber={partNumber} quantity={quantity} />
    </div>
  );
};

CartSaveToListButton.propTypes = {
  item: PropTypes.shape({
    partNumber: PropTypes.string,
    quantity: PropTypes.string
  })
};

export default CartSaveToListButton;
