import PropTypes from 'prop-types';
import cx from 'classnames';
import { FALLBACK_MOUNTAIN_IMAGE } from '@app/constants/commonConstants';
import styles from './CategoryCardImage.module.scss';

const CategoryCardImage = ({ name, imageUrl }) => {
  return (
    <img
      className={cx('mb-2 mw-100 d-block', styles['sub-category__image'])}
      loading="lazy"
      src={`${imageUrl === '' ? FALLBACK_MOUNTAIN_IMAGE : imageUrl}?$pcc-e3$`}
      alt={name}
    />
  );
};

CategoryCardImage.propTypes = {
  name: PropTypes.string,
  imageUrl: PropTypes.string
};

export default CategoryCardImage;
