export const TAB_ID = {
  MODEL: 'model',
  SERIAL: 'serial'
};

export const SERIAL_PREFIX_REGEX = '^[0-9a-zA-Z]{3}';
export const NUMBER_REGEX = '[0-9]+$';
export const ALPHA_NUMERIC_REGEX = '^[a-zA-Z0-9]+$';

export const MOBILE_MAX_EQUIPMENT = 6;
export const TABLET_MAX_EQUIPMENT = 8;
export const DESKTOP_MAX_EQUIPMENT = 10;

export const GA_LOCATION_NO_EQ = 'shop parts for your cat equipment';
export const GA_LOCATION_FOR_EQ =
  'your equipment | Do you want to add more equipment';
export const GA_FORM_NAME_BY_SERIAL = 'By Serial Number | search Suggestion';
export const GA_FORM_NAME_BY_MODEL = 'By Model | search Suggestion';

export const SEARCH_RESULT_LIMIT = {
  MATCHING: 8,
  OTHERS: 12,
  LUCID_MATCHING: 21
};
