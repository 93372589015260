import PropTypes from 'prop-types';
import styles from './LineItemAttributes.module.scss';

const LineAttributeItem = ({ icon: Icon, title }) => {
  return (
    <div
      className={`d-flex mt-1 align-items-center  ${styles['description-decorator-button']} ${styles['attribute-icon']}`}
    >
      <span className="me-2 mb-0 mt-0 align-self-start">
        <Icon
          height={22}
          width={22}
          className={`${styles['attribute-icon']}`}
        />
      </span>
      <p className={`${styles['attribute-text']}`}>{title}</p>
    </div>
  );
};

LineAttributeItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string
};

export default LineAttributeItem;
