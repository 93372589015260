import { useSelector } from 'react-redux';
import { CONTROL_CENTER_BLOCK_FLAG } from '@app/constants/commonConstants';
import { replaceTokensInUrl } from '@app/utils';
import links from '@app/constants/links';

function useControlCenterUrl() {
  const isControlCenterBlocksEnabled = useSelector(
    s => s.featureFlag?.[CONTROL_CENTER_BLOCK_FLAG]
  );
  const catalogId = useSelector(s => s.common?.catalogId);
  const storeId = useSelector(s => s.common?.storeId);
  const langId = useSelector(s => s.common?.langId);
  const esiteURL = useSelector(s => s.common?.esiteURL);
  const controlCenterBlocksUrl = esiteURL
    ? `${esiteURL}/account/control-center`
    : '';
  return isControlCenterBlocksEnabled
    ? controlCenterBlocksUrl
    : replaceTokensInUrl(links.CONTROL_CENTER_URL, catalogId, langId, storeId);
}

export default useControlCenterUrl;
