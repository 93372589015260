import React from 'react';
import PropTypes from 'prop-types';

const ProductList = ({ children, className, ...props }) => (
  <ul className="list-group list-group-flush w-100" {...props}>
    {children}
  </ul>
);

ProductList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default ProductList;
