import { STATUS } from '../../constants/commonConstants';
import { getStoreInfoLegacy } from '../../services/storeInfoService';
import { sortArrayAlphabeticallyWithLocale } from '@app/utils';
import { filterValidUserAddresses } from './utils';
import * as types from './constants';

const addressBookReducer = (state = {}, action) => {
  switch (action.type) {
    case types.ADDRESSES_GET_BEGIN: {
      return {
        ...state,
        avi: null,
        status: STATUS.PENDING
      };
    }
    case types.ADDRESSES_DELETE_SUCCESS: {
      return {
        ...state,
        addresses: {
          options: [...state.addresses.options].filter(
            contact => contact.nickName !== action.payload
          )
        },
        status: STATUS.RESOLVED
      };
    }
    case types.ADDRESSES_GET_SUCCESS: {
      const { locale } = getStoreInfoLegacy();
      const sortedAddresses = sortArrayAlphabeticallyWithLocale(
        action.payload,
        locale,
        'nickName'
      ); //sorted array of addresses
      const filteredAddresses = filterValidUserAddresses(sortedAddresses); //filter nickNames or addressTypes set by dealer
      return {
        ...state,
        addresses: {
          options: [...filteredAddresses]
        },
        status: STATUS.RESOLVED
      };
    }
    case types.ADDRESS_DELETE_BEGIN: {
      return {
        ...state,
        avi: null,
        status: STATUS.PENDING
      };
    }
    case types.ADDRESSES_DELETE_FAIL: {
      return {
        ...state,
        status: STATUS.REJECTED,
        requestType: types.REQUEST_TYPE.addressDelete
      };
    }
    case types.ADDRESSES_GET_FAIL: {
      return {
        ...state,
        status: STATUS.REJECTED,
        requestType: types.REQUEST_TYPE.addressList
      };
    }
    case types.ADDRESS_VERIFICATION_BEGIN: {
      return {
        ...state,
        avi: null,
        addresses: {
          ...state.addresses,
          new: { ...action.payload }
        },
        aviLoading: true, // this attribute is used by AVIModal.js and is used for render the component loader.
        status: STATUS.PENDING // this is used by the Address form screen page loader
      };
    }
    case types.ADDRESS_VERIFICATION_SUCCESS: {
      return {
        ...state,
        avi: { ...action.payload },
        aviLoading: false,
        status: STATUS.RESOLVED
      };
    }
    case types.ADDRESS_VERIFICATION_FAIL: {
      return {
        ...state,
        avi: {
          enabled: false
        },
        aviLoading: false,
        status: STATUS.REJECTED,
        requestType: types.REQUEST_TYPE.addressVerification
      };
    }
    case types.ADDRESS_SAVE_BEGIN: {
      return { ...state, aviLoading: true, status: STATUS.PENDING };
    }
    case types.ADDRESS_SAVE_SUCCESS: {
      const { enabled, required, ...newAddressDetails } = action.payload;

      // if this was an edit, remove the old one from addresses.options
      const { nickName: oldNickName } = state.addresses.new;
      const oldAddressOptions =
        state.addresses?.options?.filter(
          ({ nickName }) => nickName !== oldNickName
        ) ?? []; // when there are no addresses use an empty array

      const newAddress = {
        ...state.addresses.new,
        ...newAddressDetails
      };

      const finalAddresses = [...oldAddressOptions, { ...newAddress }]; //unsorted addresses

      const { locale } = getStoreInfoLegacy();
      const sortedAddresses = sortArrayAlphabeticallyWithLocale(
        finalAddresses,
        locale,
        'nickName'
      ); //sorted array of addresses
      const filteredAddresses = filterValidUserAddresses(sortedAddresses); //filter nickNames or addressTypes set by dealer

      return {
        ...state,
        avi: null,
        addresses: {
          ...state.addresses,
          new: null,
          options: [...filteredAddresses],
          lastSaved: Date.now()
        },
        aviLoading: false,
        status: STATUS.RESOLVED
      };
    }
    case types.ADDRESS_SAVE_FAIL:
      return {
        ...state,
        aviLoading: false,
        status: STATUS.REJECTED,
        requestType: types.REQUEST_TYPE.addressSave
      };
    default: {
      return state;
    }
  }
};

export default addressBookReducer;
