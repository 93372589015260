import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import endpoints from '../constants/endpoints';
import { getHttpInstance } from '../services/defaultHttpService';
import { isEmpty, replaceTokensInString } from '../utils';

const { LOAD_ESPOTS } = endpoints;

export const useEspot = (espotName, params = '', storeIdParam) => {
  const [espotData, setEspotData] = useState({});
  const [isEspotLoading, setIsEspotLoading] = useState(false);
  const [espotError, setEspotError] = useState(null);
  const currentStoreId = useSelector(s => s.common?.storeId);
  const langId = useSelector(s => s.common?.langId);
  const storeId = storeIdParam || currentStoreId;

  const getEspotContent = useCallback(
    espotContentName => {
      const espotContent = espotData?.[0]?.[espotContentName] ?? '';

      /**
       * This validations are to support the old and new data response give us by marketingData
       */
      if (Array.isArray(espotContent)) {
        return espotContent;
      }
      if (espotContent?.contents) {
        return espotContent;
      }
      return '';
    },
    [espotData]
  );

  useEffect(() => {
    const controller = new AbortController();

    if (!isEmpty(espotName)) {
      const fetchData = async () => {
        const endpointURL = replaceTokensInString(
          LOAD_ESPOTS,
          storeId,
          langId,
          espotName
        );
        const http = getHttpInstance(12000);
        setIsEspotLoading(true);
        http
          .get(`${endpointURL}${params}`, {
            signal: controller.signal
          })
          .then(response => {
            const { marketingData = {} } = response?.data;
            setEspotData(marketingData);
          })
          .catch(err => {
            setEspotData({});
            setEspotError(err);
          })
          .finally(() => {
            setIsEspotLoading(false);
          });
      };
      fetchData();
      return () => {
        // Cancel request to espot endpoint if it's in flight
        controller.abort();
        // Cleanup state
        setEspotData({});
        setIsEspotLoading(false);
        setEspotError(null);
      };
    }
  }, [espotName, langId, params, storeId]);
  return { espotData, isEspotLoading, espotError, getEspotContent };
};

export default useEspot;
