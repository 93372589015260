export const HEADER_GET_INFO_BEGIN = 'HEADER_GET_INFO_BEGIN';
export const HEADER_GET_INFO_SUCCESS = 'HEADER_GET_INFO_SUCCESS';
export const HEADER_GET_INFO_FAIL = 'HEADER_GET_INFO_FAIL';
export const HEADER_GET_WAFFLE_BEGIN = 'HEADER_GET_WAFFLE_BEGIN';
export const HEADER_GET_WAFFLE_SUCCESS = 'HEADER_GET_WAFFLE_SUCCESS';
export const HEADER_GET_WAFFLE_FAIL = 'HEADER_GET_WAFFLE_FAIL';
export const HEADER_REDIRECT_BEGIN = 'HEADER_REDIRECT_BEGIN';
export const HEADER_REDIRECT_SUCCESS = 'HEADER_REDIRECT_SUCCESS';
export const HEADER_REDIRECT_FAIL = 'HEADER_REDIRECT_FAIL';

/* QUERY-PARAMS*/
export const PARAM_SUBCATEGORY_LIMIT = '&subCategoryLimit=4';
export const SUBCATEGORY_TIER = '&subCategoryLimit=-1,-1';
