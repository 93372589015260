import { debounce, replaceTokensInUrl } from '@app/utils';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { getSuggestions } from '@app/store/partsFilter/actions';
import { MILLISECONDS_DEBOUNCE } from '@app/constants/commonConstants';
import links from '@app/constants/links';

const useSearchSuggestions = () => {
  const [searchValue, setSearchValue] = useState();
  const { SUBHEADER_SEARCH_LINK } = links;
  const catalogId = useSelector(s => s.common.catalogId);
  const langId = useSelector(s => s.common.langId);
  const storeId = useSelector(s => s.common.storeId);
  const dispatch = useDispatch();
  const {
    searchSuggestions: { suggestedProducts = [] }
  } = useSelector(s => s.partsFilter.filter);

  // This handles the debounce for calling get suggestions
  // NOTE: exhaustive-deps need to be disabled because useCallback
  //        don't identify that debounce returns a function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDebouncedSuggestions = useCallback(
    debounce(value => {
      setSearchValue(value);
      dispatch(getSuggestions(value));
    }, MILLISECONDS_DEBOUNCE),
    [dispatch]
  );

  const onSearch = searchValue => {
    if (searchValue) {
      const searchUrl = replaceTokensInUrl(
        SUBHEADER_SEARCH_LINK,
        catalogId,
        langId,
        storeId,
        searchValue
      );
      if (suggestedProducts.length === 1) {
        const [{ value = '' }] = suggestedProducts;
        window.location.href = value;
      } else {
        window.location.href = searchUrl;
      }
    }
  };

  return { onSearch, getDebouncedSuggestions, searchValue };
};

export default useSearchSuggestions;
