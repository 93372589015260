import PropTypes from 'prop-types';
import { SubheaderHelpCenter } from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import useHelpCenterLink from '@app/components/pages/csr-landing/hooks/useHelpCenterLink';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { HELP_CENTER_ROUTE } from '@app/constants/routes';

const HelpCenter = ({ displayMobileNavLink }) => {
  const [t] = useTranslation();
  const helpCenterFaqUrl = `${HELP_CENTER_ROUTE}/faq`;
  const isCurrentPage = useRouteMatch(helpCenterFaqUrl);
  const { getHelpCenterURL } = useHelpCenterLink();
  const label = t('HELP_CTR_MNU_TITLE');
  const isCatCorp = useSelector(s => s.common?.isCatCorp);
  const helpCenterEnabled = useSelector(
    state => state.featureFlag?.PCCCustomerSupportHelpCenter
  );
  const resources = useSelector(s => s.header?.globalNavigation?.resources);

  const showHelpCenter = isCatCorp || helpCenterEnabled;

  return (
    <SubheaderHelpCenter
      label={label}
      href={showHelpCenter ? getHelpCenterURL() : resources}
      isTargetBlank={showHelpCenter}
      displayMobileNavLink={displayMobileNavLink}
      isCurrent={!!isCurrentPage}
    />
  );
};

HelpCenter.propTypes = {
  displayMobileNavLink: PropTypes.bool
};

export default HelpCenter;
