import { ComponentLoader } from 'cat-ecommerce-alloy';
import styles from '../../../MyEquipment.module.scss';

const SuggestionsLoader = () => (
  <div className={`${styles['add-equipment__search-suggest']}`}>
    <ComponentLoader size="small" />
  </div>
);

export default SuggestionsLoader;
