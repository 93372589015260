import { ONETRUST } from '../constants/commonConstants';

/**
 * Search a cookie in the document
 * @param {string} name Cookie name
 * @returns {string|null} Cookie value or null
 */
export const getCookie = name => {
  let value = null;
  const cookiesString = `; ${document.cookie}`;
  const cookies = cookiesString.split(`; ${name}=`);
  if (cookies.length >= 2) {
    value = cookies.pop().split(';').shift();
  }
  return value;
};

/**
 * Add new cookie to the Document
 * @param {string} name Cookie name
 * @param {string|number|boolean} value Cookie value
 * @param {date} expires Date for cookie expiration
 * @param {string} path Cookie domain path
 * @param {string} domain Cookie domain
 */
export const setCookie = (
  name,
  value,
  expires = null,
  path = '/',
  domain = null
) => {
  const expiresString = expires ? `;expires=${expires}` : '';
  const domainString = domain ? `;domain=${domain}` : '';
  document.cookie = `${name}=${value}${expiresString};path=${path}${domainString}`;
};

/**
 * Remove a cookie from the document
 * @param {string} name Cookie Name
 */
export const removeCookie = name => {
  if (getCookie(name)) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
};

/**
 * To get the UTC date for expiring cookies
 * @param {string} expirationDays number of days to add the new Date()
 */
export const getExpirationDate = expirationDays => {
  const date = new Date();
  date.setDate(date.getDate() + expirationDays);
  return expirationDays ? date.toUTCString() : '';
};

/**
 * To get ontrust functional cookie
 */
export const isFunctionalCookiesEnable = () => {
  let cookieValue = getCookie('OptanonConsent');
  return cookieValue?.includes(ONETRUST.FUNCTIONAL_COOKIES_ACCEPTED);
};
