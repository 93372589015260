export const SELECT_CUSTOMER_FORM_ID = 'select-customer-form';
export const SEARCH_CUSTOMER_FORM_ID = 'search-customer-form';
export const SEARCH_REUSLTS_FORM_ID = 'search-results-form';
export const DEALER_DROPDOWN_NAME = 'dealer-dropdown';
export const CUSTOMER_NUMBER_DROPDOWN_NAME = 'customer-number-dropdown';
export const STORE_LOCATION_DROPDOWN_NAME = 'store-location-dropdown';
export const ORDER_TYPE_RADIO_NAME = 'order-type';
export const END_USE_CODE_DROPDOWN_NAME = 'end-use-code-dropdown';
export const CUSTOMER_NUMBER_INPUT_NAME = 'name-number-input';
export const ADDRESS_INPUT_NAME = 'address-input';
export const CITY_INPUT_NAME = 'city-input';
export const ZIP_CODE_INPUT_NAME = 'zip-code-input';
export const CUSTOMER_NUMBER_RADIO_NAME = 'customer-number-radio';
export const TERMS_AND_CONDITIONS_CHECKBOX_NAME =
  'terms-and-conditions-checkbox';
export const SKIP_THIS_STEP_CHECKBOX_NAME = 'skip-this-step-checkbox';

export const BYPASS = 'bypass';
export const Legal_cookie_privacy_espot1 = 'Legal_cookie_privacy_espot1';
export const TERMS_AND_CONDITIONS_espot = 'TERMS_AND_CONDITIONS_espot';
export const PRIVACY_POLICY_espot = 'PRIVACY_POLICY_espot';
export const PRIVACY_NOTICE_SECTION_ID = 'privacy';

export const ADS_TERMS_AND_CONDITIONS_ESPOTS = {
  espotNames: [
    Legal_cookie_privacy_espot1,
    TERMS_AND_CONDITIONS_espot,
    PRIVACY_POLICY_espot
  ].join(','),
  legalCookie: Legal_cookie_privacy_espot1,
  termsAndConditions: TERMS_AND_CONDITIONS_espot,
  privacyPolicy: PRIVACY_POLICY_espot,
  privacyNoticeId: PRIVACY_NOTICE_SECTION_ID
};

export const ERRORS = {
  NO_RESULTS: 'NO_RESULTS',
  NO_SEARCH_VALUES: 'NO_SEARCH_VALUES',
  NO_VALID_DCN_NO_STORES: 'NO_VALID_DCN_NO_STORES'
};
