import Conditional from '@app/components/common/Conditional';
import {
  MyEquipmentToggleShareButton,
  MyEquipmentInfoToolTip
} from '@cat-ecom/pcc-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cx from 'classnames';

const AddEquipmentDealerNotification = ({
  notifyDealer,
  setNotifyDealer,
  classname = '',
  labelClassname = ''
}) => {
  const isCSPCustomer = useSelector(state => state.common.isCSPCustomer);
  const shouldShowDealerNotification = isCSPCustomer;
  const [t] = useTranslation();
  return (
    <Conditional test={shouldShowDealerNotification}>
      <div className={cx(classname, 'd-flex')} data-testid="share-toggle">
        <MyEquipmentToggleShareButton
          text={t('MEQ_ADD_NOTIFICATION1')}
          checked={notifyDealer}
          handleToggleChange={e => {
            setNotifyDealer(e.target.checked);
          }}
          classname={labelClassname}
        />
        <MyEquipmentInfoToolTip
          align="bottom"
          ariaLabel={t('MEQ_ADD_NOTIFICATION3')}
          className="pt-1"
        >
          {t('MEQ_ADD_NOTIFICATION3')}
        </MyEquipmentInfoToolTip>
      </div>
    </Conditional>
  );
};
AddEquipmentDealerNotification.propTypes = {
  setNotifyDealer: PropTypes.func,
  notifyDealer: PropTypes.bool,
  classname: PropTypes.string,
  labelClassname: PropTypes.string
};

export default AddEquipmentDealerNotification;
