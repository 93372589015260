import { dataTypes, columnTypes } from 'cat-ecommerce-alloy';

export const hasPriceAvailMapping = [
  {
    id: 'recordCount',
    columnType: columnTypes.FIXED,
    width: '33px',
    padding: '16px 8px 8px 4px',
    className: 'd-none d-md-table-cell'
  },
  {
    id: 'delete',
    width: '22px',
    padding: '6px 0px 0px 0px',
    className: 'd-none d-md-table-cell',
    dataType: dataTypes.ICON,
    columnType: columnTypes.FIXED
  },
  {
    id: 'quantity',
    dataType: dataTypes.ALPHANUMERIC,
    columnType: columnTypes.FIXED,
    width: '90px',
    label: 'Quantity',
    translationKey: 'QUANTITY1',
    padding: '12px 8px',
    className: 'd-none d-md-table-cell'
  },
  {
    id: 'image',
    padding: '16px 8px',
    dataType: dataTypes.ICON,
    columnType: columnTypes.HUGGED
  },
  {
    id: 'name',
    dataType: dataTypes.ALPHANUMERIC,
    columnType: columnTypes.STRETCHED,
    label: 'Product Description',
    padding: '16px 8px 8px',
    translationKey: 'CAT_COLLAPS_PRODUCT_DISCRIPTION',
    className: `d-none d-md-table-cell`
  },
  {
    id: 'notes',
    columnType: columnTypes.FIXED,
    label: 'Notes',
    width: '200px',
    padding: '16px 8px 8px',
    translationKey: 'NOTES',
    className: `d-none d-print-table-cell`
  },
  {
    id: 'availability',
    columnType: columnTypes.HUGGED,
    dataType: dataTypes.ALPHANUMERIC,
    label: 'Availability',
    translationKey: 'AVAILABILITY',
    padding: '16px 8px',
    className: `d-none d-md-table-cell`
  },
  {
    id: 'totalPrice',
    label: 'Total Price (USD) Includes GST',
    translationKey: 'TOTAL_PRICE',
    width: '30%',
    dataType: dataTypes.NUMBER,
    columnType: columnTypes.FIXED,
    padding: '16px 4px 16px 8px',
    className: 'd-none d-md-table-cell'
  },
  {
    id: 'mobilePriceAvailCell',
    width: '90%',
    padding: '15px 10px 10px 12px',
    className: 'd-md-none'
  }
];

export const packageQuantityMapping = [
  {
    id: 'recordCount',
    columnType: columnTypes.FIXED,
    width: '33px',
    padding: '16px 8px 8px 4px',
    className: 'd-none d-md-table-cell'
  },
  {
    id: 'delete',
    width: '22px',
    padding: '6px 0px 0px 0px',
    className: 'd-none d-md-table-cell',
    dataType: dataTypes.ICON,
    columnType: columnTypes.FIXED
  },
  {
    id: 'quantity',
    dataType: dataTypes.ALPHANUMERIC,
    columnType: columnTypes.FIXED,
    width: '90px',
    label: 'Quantity',
    translationKey: 'QUANTITY1',
    padding: '12px 8px',
    className: 'd-none d-md-table-cell'
  },
  {
    id: 'packageQuantity',
    width: '96px',
    label: 'Package Quantity',
    translationKey: 'PACKAGE_QUANTITY',
    padding: '16px 8px',
    className: `d-none d-md-table-cell`,
    dataType: dataTypes.ALPHANUMERIC,
    columnType: columnTypes.FIXED
  },
  {
    id: 'name',
    label: 'Product Description',
    translationKey: 'CAT_COLLAPS_PRODUCT_DISCRIPTION',
    padding: '16px 8px 8px',
    className: `d-none d-md-table-cell`,
    dataType: dataTypes.ALPHANUMERIC,
    columnType: columnTypes.STRETCHED
  },
  {
    id: 'availability',
    label: 'Availability',
    translationKey: 'AVAILABILITY',
    padding: '16px 8px',
    className: `d-none d-md-table-cell`,
    dataType: dataTypes.ALPHANUMERIC,
    columnType: columnTypes.HUGGED
  },
  {
    id: 'totalPrice',
    label: 'Total Price (USD) Includes GST',
    translationKey: 'TOTAL_PRICE',
    width: '124px',
    padding: '16px 4px 16px 8px',
    className: 'd-none d-md-table-cell',
    dataType: dataTypes.NUMBER,
    columnType: columnTypes.FIXED
  },
  {
    id: 'mobilePackageQuantityCell',
    width: '90%',
    padding: '16px 8px 16px 12px',
    className: 'd-md-none'
  }
];

export const MAX_QUANTITY = '99999';
export const MIN_QUANTITY = '1';

export const SERVER_ERRORS = {
  QUANTITY: 'QUANTITY',
  DELETE: 'DELETE'
};

export const AVAILABILITY_ERROR = 'AVAILABILITY';
export const PROP_65_WARNING = 'Prop 65 Warning';
export const CART_REQUEST_TYPE = 'cart';

export const HIDDEN_INPUT_NUM_ORDER_ITEMS = 'numOrderItemsInOrder';

export const QUICK_ORDER_SECTION_HEADER_TARGET = 'quickOrderSectionHeaderHR';

export const DISCLAIMER_MESSAGE_HIDE = 'disclaimerMessageHide';

export const FLOATING_CART_SCROLLY = 300;
export const FLOATING_CART_DEFAULT_TOP = '400px';
export const FLOATING_CART_SCROLLED_TOP = '40px';
export const MAX_QUANTITY_LENGTH = MAX_QUANTITY.length;
export const SAVE_TYPE = {
  SAVE_ALL: 'all',
  DEFAULT: 'default',
  CREATE_LIST: 'create'
};
export const iconSize = 16;
export const CAMPAIGN_ID_INPUT_NAME = 'campaignId';
export const CAMPAIGN_ID_INPUT_MAX_LIMIT = '150';
export const PROMO_CODE_INPUT_NAME = 'promoCode';
export const PROMO_CODE_INPUT_MAX_LIMIT = '20';
export const PCC_SHOPPING_CART = 'PCCShoppingCart';
export const PCC_SHOPPING_CART_LOGGEDIN = 'PCCCartLoggedIn';
export const ICON_WIDTH = 12;
export const ICON_HEIGHT = 11;
export const WARNING_ICON_HEIGHT = 16;
export const SIS_TO_CART_FALLBACK = 'sisToCartFallback';

export const CSR_SHARE_CART_LINK_FLOW_PARAM = 'flow';
export const CSR_SHARE_CART_LINK_STOREID_PARAM = 'storeId';
export const CSR_SHARE_CART_LINK_STORE_LOCATION_ID_PARAM = 'storeLocationId';
export const CSR_SHARE_CART_LINK_PRESELECTED_DCN_PARAM = 'preselectedDCN';

export const CSR_SHARE_CART_LINK_FLOW_PARAM_REGISTERED_USERS_VALUE =
  'CSR_SHARE';
