import { STATUS } from '@app/constants/commonConstants';

export const partsFilterInitialStore = {
  isSearchSuggestionsOpen: false,
  suggestionsStatus: STATUS.IDLE,
  error: '',
  filter: {
    searchString: '',
    searchSuggestions: {}
  }
};
