import { DRAWERS_ROOT } from '@app/constants/targets';
import { Portal } from '@cat-ecom/pcc-components';
import PropTypes from 'prop-types';
import AddEquipmentDrawer from './AddEquipmentDrawer';

const Container = ({ drawerProps, onDrawerClose, isChild }) => {
  return (
    <Portal id={DRAWERS_ROOT}>
      <AddEquipmentDrawer
        drawerProps={drawerProps}
        onDrawerClose={onDrawerClose}
        isChild={isChild}
      />
    </Portal>
  );
};

Container.propTypes = {
  drawerProps: PropTypes.object,
  onDrawerClose: PropTypes.func,
  isChild: PropTypes.bool
};

export default Container;
