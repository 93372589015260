export const convertDateToUtc = (date, timezone) => {
  if (!timezone) {
    return date;
  }
  const inputDate = new Date(`${date.replace(/-/g, '/')} UTC`);
  const [, offsetStr] = timezone.match(/GMT([+-]*\d+:\d+)/);
  const [hoursStr, minutesStr] = offsetStr.split(':');
  const timezoneOffset =
    (parseInt(hoursStr, 10) * 60 + parseInt(minutesStr, 10)) * 60 * 1000;
  const utcTime = new Date(inputDate.getTime() - timezoneOffset);
  return new Date(utcTime.toISOString());
};

export const getRemainingTime = cutoffTime => {
  if (!cutoffTime || isNaN(new Date(cutoffTime).getTime())) {
    return {
      total: 0,
      hours: 0,
      minutes: 0
    };
  }
  const currentTime = new Date().getTime();
  const cutoffMillis = new Date(cutoffTime).getTime() + 6e4;
  const totalMilliseconds =
    cutoffMillis > currentTime ? cutoffMillis - currentTime : 0;
  const remainHours = Math.floor(totalMilliseconds / 36e5);
  const remainMinutes =
    remainHours < 12 ? Math.floor((totalMilliseconds % 36e5) / 6e4) : 0;
  return {
    total: cutoffMillis,
    hours: remainHours,
    minutes: remainMinutes
  };
};
