import RecordCountCell from './customCells/RecordCountCell';
import ImageCell from '@app/components/common/ImageCell';
import QuantitySelector from './customCells/QuantitySelector';
import PackageQuantityCell from './customCells/PackageQuantityCell';
import NameCell from './customCells/NameCell';
import AvailabilityCell from './customCells/AvailabilityCell';
import TotalPriceCell from './customCells/TotalPriceCell';
import CartSaveToListButton from '../common/CartSaveToListButton';
import LineItemAttributes from '../common/LineItemAttributes';
import { isEmpty } from '@app/utils';
import NotesCell from './customCells/NotesCell';
import { getPartName } from '@app/utils/commonUtils';
import DeleteCell from './customCells/DeleteCell';

const getRecordCountCell = (item, _col, _isFooter, rowIndex) => (
  <RecordCountCell item={item} rowIndex={rowIndex} />
);
const getImageCell = item => <ImageCell item={item} />;
const getQuantityInput = (item, _col, _isFooter, rowIndex) => (
  <QuantitySelector item={item} rowIndex={rowIndex} />
);
const getDeleteCell = item => <DeleteCell item={item} />;
const getNameCell = (item, _col, _isFooter, rowIndex) => {
  return <NameCell item={item} rowIndex={rowIndex} />;
};
const getPackageQuantityCell = item => <PackageQuantityCell item={item} />;
const getAvailabilityCell = item => <AvailabilityCell item={item} />;
const getTotalPriceCell = (isMobile = false, item) => {
  return <TotalPriceCell item={item} isMobile={isMobile} />;
};
const getSavedListButton = item => <CartSaveToListButton item={item} />;
const getLineItemAttributes = item => <LineItemAttributes item={item} />;

const getMobileNoPriceAvailCell = (item, _col, _isFooter, rowIndex) => (
  <>
    {getNameCell(item, undefined, undefined, rowIndex)}
    <div className="d-flex my-3">
      {getDeleteCell(item)}
      {getQuantityInput(item, undefined, undefined, rowIndex)}
    </div>
    <div className="my-3">{getSavedListButton(item)}</div>
  </>
);

const getMobilePriceAvailCell = (item, _col, _isFooter, rowIndex) => (
  <>
    {getNameCell(item, undefined, undefined, rowIndex)}
    <div className="my-2">{getTotalPriceCell(true, item)}</div>
    <div className="d-flex my-2">
      {getDeleteCell(item)}
      {getQuantityInput(item, undefined, undefined, rowIndex)}
    </div>
    <div className="my-2">{getAvailabilityCell(item)}</div>
    <div className="my-1">{getLineItemAttributes(item)}</div>
    <div className="my-2">{getSavedListButton(item)}</div>
  </>
);
const getMobilePackageQuantityCell = (item, _col, _isFooter, rowIndex) => (
  <>
    {getNameCell(item)}
    <div className="my-2">{getTotalPriceCell(true, item)}</div>
    <div className="d-flex mt-2">
      {getDeleteCell(item)}
      {getQuantityInput(item, undefined, undefined, rowIndex)}
    </div>
    <div>{getPackageQuantityCell(item)}</div>
    <div className="my-2">{getAvailabilityCell(item)}</div>
  </>
);

const getPrintNotesCell = item => <NotesCell item={item} />;

export const getCustomRenderers = () => ({
  recordCount: getRecordCountCell,
  image: getImageCell,
  delete: getDeleteCell,
  quantity: getQuantityInput,
  packageQuantity: getPackageQuantityCell,
  name: getNameCell,
  availability: getAvailabilityCell,
  notes: getPrintNotesCell,
  totalPrice: (...args) => getTotalPriceCell(false, ...args),
  mobileNoPriceAvailCell: (...args) => getMobileNoPriceAvailCell(...args),
  mobilePriceAvailCell: (...args) => getMobilePriceAvailCell(...args),
  mobilePackageQuantityCell: (...args) => getMobilePackageQuantityCell(...args)
});

export const getGAItem = (
  item,
  { rowIndex, storeName, emailCart, isPartNameJoined }
) => {
  const {
    category,
    brand,
    checkFitment,
    classification,
    kitId,
    packageFlag,
    relatedModel
  } = item.orderItemExtendAttribute.googleAnalyticsAttributes;

  const displayItem = {
    name: !isPartNameJoined
      ? getPartName(item.partNumber, item.name)
      : item.name,
    id: item.partNumber || '',
    price: !emailCart ? item.unformattedUnitPrice || '' : '',
    brand: brand || '',
    category: category || '',
    inventoryStatus: !emailCart
      ? item.availability?.[0]?.availabilityMessage || ''
      : '',
    inventoryLocation: !emailCart ? storeName || '' : '',
    classification: classification || '',
    productWeight: !emailCart
      ? item.orderItemExtendAttribute?.unitWeight || ''
      : '',
    kitId: kitId || '',
    packageFlag: packageFlag || '',
    checkFitment: checkFitment || '',
    relatedModel: relatedModel || '',
    productAttributesVisible: '',
    position: rowIndex + 1,
    msrp: ''
  };

  return displayItem;
};

export const getProductURL = url => url?.replace('-sku', '');

export const getUpdateGAItem = (item, quantity, storeName, emailCart) => {
  const { category, brand, checkFitment, kitId, packageFlag, relatedModel } =
    item.orderItemExtendAttribute.googleAnalyticsAttributes;

  const getInventoryLocation = item => {
    if (item?.sourcingDetails?.length > 0) {
      return '';
    }

    return storeName || '';
  };
  const displayUpdateGaItem = {
    name: getPartName(item.partNumber, item.name) || '',
    id: item.partNumber || '',
    price: !emailCart ? item.unformattedUnitPrice || '' : '',
    brand: brand || '',
    category: category || '',
    quantity: quantity || '',
    inventoryStatus: !emailCart
      ? item.availability?.[0]?.availabilityMessage || ''
      : '',
    inventoryLocation: !emailCart ? getInventoryLocation(item) : '',
    productWeight: !emailCart
      ? item.orderItemExtendAttribute?.unitWeight || ''
      : '',
    kitId: kitId || '',
    packageFlag: packageFlag || '',
    checkFitment: checkFitment || '',
    relatedModel: relatedModel || '',
    productAttributesVisible: '',
    msrp: '',
    availability: item.availability,
    sourcingDetails: item.sourcingDetails || []
  };

  return displayUpdateGaItem;
};

export const getAddAndRemoveCartEvents = (updatedItems, storeName) => {
  const addToCartItems = [];
  const removeFromCartItems = [];
  updatedItems.forEach(item => {
    const { serverQuantity } = item;
    if (serverQuantity > item.quantity) {
      const quantity = serverQuantity - item.quantity;
      removeFromCartItems.push(getUpdateGAItem(item, quantity, storeName));
    } else if (serverQuantity < item.quantity) {
      const quantity = item.quantity - serverQuantity;
      addToCartItems.push(getUpdateGAItem(item, quantity, storeName));
    }
  });

  return { addToCartItems, removeFromCartItems };
};

export const getPackageErrorCondition = (value, packageQuantity) =>
  !!packageQuantity &&
  (parseInt(value) === 0 || parseInt(value) % packageQuantity !== 0);

export const getPackageErrorItemsIds = currentItems => {
  const packageErrorItemIds = [];
  currentItems.forEach(item => {
    if (
      !isEmpty(item.packageError) &&
      !getPackageErrorCondition(item.quantity, item.packageQuantity)
    ) {
      packageErrorItemIds.push(item.orderItemId);
    }
  });
  return packageErrorItemIds;
};
