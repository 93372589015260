import {
  ACCOUNT_REGISTRATION_BEGIN,
  ACCOUNT_REGISTRATION_FAIL,
  ACCOUNT_REGISTRATION_SUCCESS
} from './constants';
import { STATUS } from '../../constants/commonConstants';
import { registrationInitialStore } from './initialStore';

const registrationReducer = (state = registrationInitialStore, action) => {
  switch (action.type) {
    case ACCOUNT_REGISTRATION_BEGIN:
      return {
        ...state,
        status: STATUS.PENDING
      };
    case ACCOUNT_REGISTRATION_FAIL:
      return {
        ...state,
        status: STATUS.REJECTED,
        errors: {
          createAccount: action.payload?.createAccount ?? '',
          embargoAndCompliance: action.payload?.embargoAndCompliance ?? []
        }
      };
    case ACCOUNT_REGISTRATION_SUCCESS:
      return {
        ...state,
        status: STATUS.RESOLVED
      };
    default:
      return state;
  }
};
export { registrationInitialStore };
export default registrationReducer;
