import { useTranslation } from 'react-i18next';
import styles from './ModernAvailability.module.scss';
import { AlloyButton, useModal, Modal, Popup } from 'cat-ecommerce-alloy';
import PropTypes from 'prop-types';
import ContactDealerPopup from '../../ContactDealerPopup';
import { getPopperOptions } from './util';

const ContactDealerDisplay = ({
  catEntryId,
  msg,
  isSecondaryComp = false,
  buttonClassName = ''
}) => {
  const modalProps = useModal();

  const [t] = useTranslation();
  const className = `p-0 text-start ${buttonClassName}  ${styles['print__availability-text']}`;

  const getDealerInitiator = () => (
    <AlloyButton
      data-testid="availability-contact-dealer"
      buttonType="link"
      className={className}
    >
      {msg || t('CONTACT_YOUR_DEALER')}
    </AlloyButton>
  );
  const popperOptions = getPopperOptions();
  return (
    <>
      {isSecondaryComp ? (
        <>
          <AlloyButton
            data-testid="availability-contact-dealer"
            buttonType="link"
            className={className}
            onClick={modalProps.open}
          >
            {msg || t('CONTACT_YOUR_DEALER')}
          </AlloyButton>
          <div>
            <Modal
              close={modalProps.close}
              contentLabel="contact dealer dialog"
              isOpen={modalProps.isOpen}
              portalTarget="react-root-main"
              title={t('CONTACT_YOUR_DEALER')}
            >
              <ContactDealerPopup catEntryId={catEntryId} />
            </Modal>
          </div>
        </>
      ) : (
        <div className="d-flex h-100">
          <Popup
            contents={<ContactDealerPopup catEntryId={catEntryId} />}
            initiator={getDealerInitiator()}
            popperOptions={popperOptions}
          />
        </div>
      )}
    </>
  );
};

ContactDealerDisplay.propTypes = {
  catEntryId: PropTypes.string,
  msg: PropTypes.string,
  buttonClassName: PropTypes.string,
  isSecondaryComp: PropTypes.bool
};
export default ContactDealerDisplay;
