import { STATUS } from '../../constants/commonConstants';
import * as types from './constants';
import { dealerAssocoationInitialStore } from './initialStore';

const dealerAssociationReducer = (state = {}, action) => {
  switch (action.type) {
    case types.DEALER_ASSOC_GET_BEGIN: {
      return {
        ...state,
        status: STATUS.PENDING
      };
    }
    case types.DEALER_ASSOC_GET_SUCCESS: {
      const defaultAssociation = action.payload?.dealerAssociations?.find(
        ({ default: isDefault }) => !!isDefault
      );
      const dealerAssociations = action.payload?.dealerAssociations?.map(
        da => ({
          ...da,
          customerNumber: da.customerNumber?.map(cn => ({
            ...cn,
            name: cn.label.split('-')[1]
          }))
        })
      );
      return {
        ...state,
        defaultAssociation,
        status: STATUS.RESOLVED,
        ...action.payload,
        dealerAssociations
      };
    }
    case types.CSR_ORDER_DETAIL_DEALER_ASSOC_GET_SUCCESS: {
      return {
        ...state,
        orderDetailDealerAssoc: true
      };
    }
    case types.DEALER_ASSOC_GET_FAIL: {
      return {
        ...state,
        status: STATUS.REJECTED
      };
    }
    case types.SELECTED_ADP_URL_GET_BEGIN: {
      return {
        ...state,
        getAdpUrlStatus: STATUS.PENDING
      };
    }
    case types.SELECTED_ADP_URL_GET_SUCCESS: {
      return {
        ...state,
        getAdpUrlStatus: STATUS.RESOLVED
      };
    }
    case types.SELECTED_STORE_GET_BEGIN: {
      return {
        ...state,
        selectedStoreStatus: STATUS.PENDING
      };
    }
    case types.SELECTED_STORE_GET_SUCCESS: {
      return {
        ...state,
        selectedStoreStatus: STATUS.RESOLVED
      };
    }
    default: {
      return state;
    }
  }
};

export { dealerAssocoationInitialStore };
export default dealerAssociationReducer;
