import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Dropdown, Validator } from 'cat-ecommerce-alloy';
import Field from '../../../../common/form/Field';
import { replaceTokensInString } from '../../../../../utils';
import {
  dealerAssociationPropTypes,
  dealerAssociationsPropTypes
} from '../../../declarations.propTypes';

const DealerDropdown = ({
  isRequired = true,
  dealerAssociations = [],
  defaultCustomerNumber,
  defaultDealer
}) => {
  const { setFieldValue, setErrors } = useFormikContext();
  const [t] = useTranslation();

  const labelText = t('Footer_Dealer');
  const placeholderText = t('CAT_MA_SELECTDEALER');
  const placeholder =
    dealerAssociations.length === 1
      ? placeholderText
      : { label: placeholderText, value: '' };
  useEffect(() => {
    if (defaultDealer) {
      setFieldValue('dealer', defaultDealer);
      setFieldValue('customerNumber', defaultCustomerNumber);
    }
  }, [defaultCustomerNumber, defaultDealer, setFieldValue]);

  // blank out the values of dependent form fields when the value changes
  const handleOnChange = dealer => {
    const customerNumbers = dealer.customerNumber || [];
    const fallbackCustomerNumber =
      customerNumbers.length === 1 ? customerNumbers[0] : '';
    const customerNumber =
      customerNumbers.find(cn => !!cn.default) ?? fallbackCustomerNumber;

    if (dealerAssociations.length > 1) {
      setFieldValue('customerNumber', customerNumber);
      setFieldValue('storeLocation', '');
      setFieldValue('endUseCode', '');
      setErrors({});
    }

    setFieldValue('legalAcceptance', !!dealer?.isTermsConditionAccepted);
  };

  const FIELD_REQUIRED_MSG = t('1626_FIELD_REQUIRED_ERROR');
  const validator = val =>
    new Validator(val)
      .required(replaceTokensInString(FIELD_REQUIRED_MSG, labelText))
      .getError();

  return (
    <Field
      as={Dropdown}
      className={`w-100 mb-0 p-0`}
      id="associated-dealer_dealer"
      items={dealerAssociations}
      itemToString={({ label }) => label}
      keyToString={({ value }) => value}
      label={labelText}
      menuSize="small"
      name="dealer"
      onChange={handleOnChange}
      placeholder={placeholder}
      showRequired={isRequired}
      type="dropdown"
      validator={validator}
    />
  );
};

DealerDropdown.propTypes = {
  dealerAssociations: dealerAssociationsPropTypes,
  defaultCustomerNumber: PropTypes.shape({
    default: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  defaultDealer: dealerAssociationPropTypes,
  isRequired: PropTypes.bool
};

export default DealerDropdown;
