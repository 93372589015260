import PropTypes from 'prop-types';
import SearchResultsForm from './SearchResultsForm';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { ERROR_DOMAIN, ERROR_PATH } from '../../../../constants/errorConstants';
import ExceptionFeedbackBySelector from '../../../common/Exception/ExceptionFeedbackBySelector';
import { dealerCustomerPropTypes } from '../../declarations.propTypes';
import useCalculateGATiming from '@app/components/pages/checkout/hooks/useCalculateGATiming/useCalculateGATiming';
import {
  CUSTOMER_LOOKUP_API,
  CUSTOMER_LOOKUP_API_INITIAL_TIME
} from '@app/constants/analyticsConstants';

const SearchResultModal = ({ title, results, resetDealerSearchForm }) => {
  const { t } = useTranslation();
  const countResults = `${results.length} ${t('RESULTS')}`;

  useCalculateGATiming(CUSTOMER_LOOKUP_API_INITIAL_TIME, CUSTOMER_LOOKUP_API);

  return (
    <div>
      <h2 className="exclude-global h2 display-1 mb-4">{title}</h2>
      <ExceptionFeedbackBySelector
        className="mb-4"
        selector={state =>
          state.errors[ERROR_DOMAIN.DEALER_USER_SEARCH]?.[
            ERROR_PATH.SEARCH_RESULTS
          ]
        }
        skipTranslation={true}
      />
      <p
        className={cx(
          'exclude-global',
          'u-color--DarkGray',
          'text-univers',
          'text-start',
          'my-3'
        )}
      >
        {countResults}
      </p>
      <SearchResultsForm
        results={results}
        resetDealerSearchForm={resetDealerSearchForm}
      />
    </div>
  );
};

export default SearchResultModal;

SearchResultModal.propTypes = {
  title: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(PropTypes.shape(dealerCustomerPropTypes))
    .isRequired,
  resetDealerSearchForm: PropTypes.func.isRequired
};
