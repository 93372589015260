export const DISPLAY_TOAST = 'DISPLAY_TOAST';
export const CLOSE_TOAST = 'CLOSE_TOAST';

export const GET_USER_ENROLLMENT_STATUS_SUCCESS =
  'GET_USER_ENROLLMENT_STATUS_SUCCESS';
export const GET_USER_ENROLLMENT_STATUS_BEGIN =
  'GET_USER_ENROLLMENT_STATUS_BEGIN';
export const GET_USER_ENROLLMENT_STATUS_FAIL =
  'GET_USER_ENROLLMENT_STATUS_FAIL';

export const CVR_USER_ENROLLMENT_BEGIN = 'CVR_USER_ENROLLMENT_BEGIN';
export const CVR_USER_ENROLLMENT_FAIL = 'CVR_USER_ENROLLMENT_FAIL';
export const CVR_USER_ENROLLMENT_SUCCESS = 'CVR_USER_ENROLLMENT_SUCCESS';
export const CVR_USER_ALREADY_ENROLLED = 'CVR_USER_ALREADY_ENROLLED';

export const GET_CAT_VANTAGE_REWARDS_BALANCE_FAIL =
  'GET_CAT_VANTAGE_REWARDS_BALANCE_FAIL';
export const GET_CAT_VANTAGE_REWARDS_BALANCE_BEGIN =
  'GET_CAT_VANTAGE_REWARDS_BALANCE_BEGIN';
export const GET_CAT_VANTAGE_REWARDS_BALANCE_SUCCESS =
  'GET_CAT_VANTAGE_REWARDS_BALANCE_SUCCESS';

export const IS_CAT_VANTAGE_COVERING_ORDER_TOTAL =
  'IS_CAT_VANTAGE_COVERING_ORDER_TOTAL';

export const SET_CAT_VANTAGE_REWARDS_BALANCE =
  'SET_CAT_VANTAGE_REWARDS_BALANCE';
