import { format } from 'date-fns';

export const formatCSROrders = (dataObject, sortBy, orderBy) => {
  const formattedOrdersObject = { ...dataObject };
  for (let i in formattedOrdersObject) {
    if (formattedOrdersObject.hasOwnProperty(i)) {
      const parsedDate = formattedOrdersObject[i].orderDate
        ? format(new Date(formattedOrdersObject[i].orderDate), 'MMMM d,y')
        : null;
      const displayComma =
        formattedOrdersObject[i]?.address?.city &&
        formattedOrdersObject[i]?.address?.state
          ? `, `
          : '';

      formattedOrdersObject[i].orderDate = parsedDate;
      // Fullname Joint
      formattedOrdersObject[i].fullName = `${
        formattedOrdersObject[i]?.firstName ?? ''
      } ${formattedOrdersObject[i]?.lastName ?? ''}`;
      // Address Joint
      formattedOrdersObject[i].address = `${
        formattedOrdersObject[i]?.address?.city ?? ''
      }${displayComma}${formattedOrdersObject[i]?.address?.state ?? ''}`;
    }
  }

  return Object.values(formattedOrdersObject).sort((a, b) => a.index - b.index);
};
