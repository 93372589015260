import { useECT } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import {
  AlloyButton,
  InfoIcon,
  Popup,
  PopupTrigger
} from 'cat-ecommerce-alloy';
import cx from 'classnames';
import styles from './TooltipDisclaimer.module.scss';
import PurifiedHtml from '../../PurifiedHtml';

export const eSpot1 = 'DEALER_AVAILABILITY_TOOLTIP_espot';
const messageType = 'PCC_AVAILABLE_TOOLTIP_DISCLAIMER';

const TooltipDisclaimer = () => {
  const [t] = useTranslation();
  const { data: ectData } = useECT(messageType, eSpot1);
  const popperOptions = {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
  };

  const getInitiator = () => (
    <AlloyButton
      className="py-0 px-2"
      buttonType="transparent"
      data-testid="availabiltity-info__tooltip"
      style={{ boxShadow: 'none', minHeight: '1%' }} //required behavior, needed to override bootstrap default btn class
    >
      <InfoIcon
        width={16}
        height={16}
        title=""
        className={`d-print-none ${styles['grey-icon']}`}
      />
    </AlloyButton>
  );
  const getPopupContent = () => (
    <div
      className={cx(
        'text-xs exclude-global mb-0 text-transform-none',
        styles['toggle_msg']
      )}
      data-testid="content"
    >
      <PurifiedHtml
        aria-hidden="true"
        data-testid="ectData"
        htmlString={ectData?.text || t('ECT_AVAILIABILITY_TOOLTIP')}
      />
    </div>
  );
  return (
    <Popup
      contents={getPopupContent()}
      initiator={getInitiator()}
      popperOptions={popperOptions}
      trigger={PopupTrigger.CLICK}
    />
  );
};

export default TooltipDisclaimer;
