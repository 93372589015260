import * as types from './constants';
import endpoints from '../../constants/endpoints';
import { getHttpInstance } from '../../services/defaultHttpService';
import { replaceTokensInString } from '../../utils';

export const getFeatureFlags = () => async (dispatch, getState) => {
  const { storeId } = getState().common;
  const http = getHttpInstance();
  const url = replaceTokensInString(endpoints.STORE_FEATURE_FLAGS, storeId);

  dispatch({ type: types.GET_FEATURE_FLAG_BEGIN });
  try {
    const { data } = await http.get(url);
    dispatch({
      type: types.GET_FEATURE_FLAG_SUCCESS,
      payload: data.storefeatures ?? {}
    });
  } catch (error) {
    dispatch({ type: types.GET_FEATURE_FLAG_FAIL });
  }
};
