import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import { useBreakpoint } from '@app/hooks';
import { SkeletonLoader } from 'cat-ecommerce-alloy';

const PDPSkeletonLoader = () => {
  const styleHeight = height => ({
    height: `${height}px`
  });

  const isLG = useBreakpoint(PAGE_BREAKPOINTS.LG);

  return (
    <div className="legacy-wrapper container" data-testid="pdp-skeleton-loader">
      <SkeletonLoader
        className="height-30px my-3"
        style={{ width: isLG ? '500px' : '100%' }}
      />
      <SkeletonLoader className="height-100px mb-3 w-100" />
      <section className="row">
        <div className="col-12 col-lg mb-lg-5 mb-3">
          <SkeletonLoader style={styleHeight(isLG ? 500 : 350)} />
        </div>
        <div className="col-12 col-lg">
          <SkeletonLoader className="height-50px mb-3" />
          <SkeletonLoader
            className="mb-lg-5 mb-3"
            style={styleHeight(isLG ? 434 : 250)}
          />
        </div>
      </section>
      <SkeletonLoader className="mb-3 height-100px" />
      <SkeletonLoader className="mb-3 height-60px" />
      <SkeletonLoader className="mb-3 height-100px" />
      <SkeletonLoader className="mb-3 height-400px" />
      <SkeletonLoader className="mb-3 height-200px" />
      <SkeletonLoader className="mb-3 height-100px" />
      <SkeletonLoader className="mb-3 height-100px" />
      <SkeletonLoader className="mb-5 height-200px" />
    </div>
  );
};

export default PDPSkeletonLoader;
