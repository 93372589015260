import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const SimpleField = forwardRef(
  ({ as: Component, ariaLabel, onClear, ...props }, ref) => {
    return (
      <Component
        aria-label={ariaLabel}
        data-testid="field-input"
        ref={ref}
        {...props}
        {...(onClear ? { onClear } : {})}
      />
    );
  }
);

SimpleField.displayName = 'SimpleField';

SimpleField.propTypes = {
  as: PropTypes.elementType.isRequired,
  ariaLabel: PropTypes.string,
  onClear: PropTypes.func
};

export { SimpleField };
