import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import logger from 'redux-logger';
import defaultInitialStore from './initialStore';
import common from './common';
import catVantage from './catvantage';
import account from './account';
import myEquipment from './myequipment';
import partsFilter from './partsFilter';
import mlp from './mlp';
import checkout from './checkout';
import categories from './categories';
import customers from './customers';
import csrOrders from './csrOrders';
import dealer from './dealer';
import dealerAssociation from './dealer-association';
import dealerCustomer from './dealer-customer';
import errors from './exception';
import featureFlag from './featureFlag';
import header from './header';
import shoppingPreferences from './shoppingPreferences';
import organizationsAndUsers from './organizationsAndUsers';
import storeInfo from './store';
import orderHistory from './orderHistory';
import ordersToApprove from './ordersToApprove';
import orders from './orders';
import repairOptions from './repairOptions';
import registrationUI from './registration';
import returnRequest from './returnRequest';
import requestService from './requestService';
import pageLoad from './pageLoad';
import partsDiagram from './partsDiagram';
import plpUI from './plp';
import products from './products';
import footer from './footer';
import savedListDetailUI from './savedListDetail';
import schematics from './schematics';
import addresses from './addressBook';
import homepageUI from './homepage';
import user from './user';
import marketingLandingPageUI from './marketing';
import personalInfoUI from './personalInformation';
import searchUI from './searchUI';
import notifications from './notifications';
import notificationHeader from './notificationHeader';
import invoices from './invoices';
import ect from './ect';
import storeConfiguration from './storeConfiguration';
import visionLink from './visionLink';
import { composeWithDevToolsLogOnlyInProduction } from '@redux-devtools/extension';
import taxDetail from './taxDetail';

export default function ConfigureStore(
  oldCommon = {},
  initialStore = defaultInitialStore
) {
  const mw = [thunk];
  const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
  if (process.env.NODE_ENV === 'development' && !isChrome) {
    mw.push(logger);
    mw.push(reduxImmutableStateInvariant());
  }

  const store = createStore(
    combineReducers({
      common,
      account,
      mlp,
      checkout,
      categories,
      catVantage,
      csrOrders,
      customers,
      dealer,
      dealerAssociation,
      dealerCustomer,
      errors,
      featureFlag,
      header,
      myEquipment,
      notifications,
      notificationHeader,
      orderHistory,
      orders,
      ordersToApprove,
      organizationsAndUsers,
      partsFilter,
      plpUI,
      repairOptions,
      registrationUI,
      returnRequest,
      requestService,
      shoppingPreferences,
      store: storeInfo,
      pageLoad,
      partsDiagram,
      products,
      footer,
      savedListDetailUI,
      schematics,
      addresses,
      user,
      homepageUI,
      marketingLandingPageUI,
      personalInfoUI,
      invoices,
      searchUI,
      ect,
      storeConfiguration,
      visionLink,
      taxDetail
    }),
    {
      common: { ...initialStore.common, ...oldCommon },
      account: { ...initialStore.account },
      checkout: { ...initialStore.checkout },
      categories: { ...initialStore.categories },
      catVantage: { ...initialStore.catVantage },
      csrOrders: { ...initialStore.csrOrders },
      customers: { ...initialStore.customers },
      dealer: { ...initialStore.dealer },
      dealerAssociation: { ...initialStore.dealerAssociation },
      dealerCustomer: { ...initialStore.dealerCustomer },
      errors: { ...initialStore.errors },
      featureFlag: { ...initialStore.featureFlag },
      header: { ...initialStore.header },
      mlp: { ...initialStore.mlp },
      myEquipment: { ...initialStore.myEquipment },
      notifications: { ...initialStore.notifications },
      notificationHeader: { ...initialStore.notificationHeader },
      orderHistory: { ...initialStore.orderHistory },
      orders: { ...initialStore.orders },
      partsFilter: { ...initialStore.partsFilter },
      plpUI: { ...initialStore.plpUI },
      searchUI: initialStore.searchUI,
      repairOptions: { ...initialStore.repairOptions },
      registrationUI: { ...initialStore.registrationUI },
      returnRequest: { ...initialStore.returnRequest },
      requestService: { ...initialStore.requestService },
      partsDiagram: { ...initialStore.partsDiagram },
      shoppingPreferences: {
        ...initialStore.shoppingPreferences
      },
      ordersToApprove: { ...initialStore.ordersToApprove },
      organizationsAndUsers: { ...initialStore.organizationsAndUsers },
      store: { ...initialStore.store },
      pageLoad: { ...initialStore.pageLoad },
      products: { ...initialStore.products },
      footer: { ...initialStore.footer },
      savedListDetailUI: { ...initialStore.savedListDetailUI },
      schematics: { ...initialStore.schematics },
      addresses: { ...initialStore.addresses },
      user: { ...initialStore.user },
      homepageUI: { ...initialStore.homepageUI },
      marketingLandingPageUI: { ...initialStore.marketingLandingPageUI },
      personalInfoUI: { ...initialStore.personalInfoUI },
      invoices: { ...initialStore.invoices },
      ect: { ...initialStore.ect },
      storeConfiguration: { ...initialStore.storeConfiguration },
      visionLink: { ...initialStore.visionLink },
      taxDetail: { ...initialStore.taxDetail }
    },
    composeWithDevToolsLogOnlyInProduction(applyMiddleware(...mw))
  );
  return store;
}
