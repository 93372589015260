import { STATUS } from '../../constants/commonConstants';
import * as types from './constants';
import { invoicesInitialStore } from './initialStore';

const invoicesReducer = (state = invoicesInitialStore, action) => {
  switch (action.type) {
    case types.INVOICES_GET_INFO_BEGIN: {
      return {
        ...state,
        invoicesList: [],
        searchStatus: STATUS.PENDING
      };
    }
    case types.INVOICES_GET_INFO_SUCCESS: {
      return {
        ...state,
        invoicesList: action.payload?.invoices,
        searchStatus: STATUS.RESOLVED
      };
    }
    case types.INVOICES_GET_INFO_FAIL: {
      return {
        ...state,
        invoicesList: [],
        searchStatus: STATUS.REJECTED
      };
    }
    case types.CLEAR_INVOICES_LIST: {
      return {
        ...state,
        invoicesList: [],
        searchStatus: STATUS.IDLE
      };
    }
    case types.SET_INVOICES_CLIENT_FILTER_DATA: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case types.INVOICE_DETAIL_GET_INFO_BEGIN: {
      return {
        ...state,
        invoice: {},
        invoiceDetailStatus: STATUS.PENDING
      };
    }
    case types.INVOICE_DETAIL_GET_INFO_SUCCESS: {
      return {
        ...state,
        invoice: action.payload,
        invoiceDetailStatus: STATUS.RESOLVED
      };
    }
    case types.INVOICE_DETAIL_GET_INFO_FAIL: {
      return {
        ...state,
        invoice: {},
        invoiceDetailStatus: STATUS.REJECTED,
        invoiceDetailError: action.payload.invoiceDetailError
      };
    }
    case types.INVOICE_DETAIL_EXPORT_SUCCESS: {
      return {
        ...state,
        invoiceDetailExportError: false
      };
    }
    case types.INVOICE_DETAIL_EXPORT_FAIL: {
      return {
        ...state,
        invoiceDetailExportError: true
      };
    }

    default: {
      return state;
    }
  }
};

export { invoicesInitialStore };
export default invoicesReducer;
