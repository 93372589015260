import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { isEmpty } from '@app/utils';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import cx from 'classnames';
import {
  PopupContainer,
  SearchInput,
  usePopup,
  Validator
} from 'cat-ecommerce-alloy';
import useHttp from '../../../hooks/useHttp';
import Field from '../../common/form/Field';
import DealerLocationSearchSuggestions from './DealerLocationSearchSuggestions';
import DealerLocatorFormAutosuggestEffect from './DealerLocatorFormAutosuggestEffect/DealerLocatorFormAutosuggestEffect';
import DealerLocatorFilterButton from '../DealerLocatorFilterButton';
import { dealerlocatorResultsPropTypes } from '../declarations.proptypes';

const DealerLocatorForm = ({
  dealerStores,
  filters,
  selectedSuggestion = '',
  setFilters,
  setSelectedSuggestion,
  storeId
}) => {
  const [t] = useTranslation();
  const { invoke, data } = useHttp();
  const { closePopup, initiatorProps, popupProps, isDisplayed, openPopup } =
    usePopup({
      hideArrow: true,
      hideCloseButton: true
    });

  const initialValues = {
    dealerLocation: selectedSuggestion,
    isSuggestion: false
  };

  const locationValidator = val =>
    new Validator(val).required(t('FYD_INVALID_LOCATION')).getError();

  const onFocus = show => {
    if (show) {
      openPopup();
    }
  };
  const onSubmit = ({ dealerLocation, isSuggestion }, { setFieldValue }) => {
    setSelectedSuggestion(dealerLocation, isSuggestion);
    setFieldValue('isSuggestion', false);
  };

  useEffect(() => {
    if (!isEmpty(data?.suggestions)) {
      openPopup();
    }
  }, [data, openPopup]);

  return (
    <Formik
      initialValues={initialValues}
      /*
       * enableReinitialize true indicates that the form will rerender if initialValues changes
       * This is set to true in this instance because SearchThisAreaButton changes the value of selectedSuggestion
       * but is in a different form context, so it is unable to access this Form's context to properly
       * set the field value
       */
      enableReinitialize={true}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ setFieldValue, values }) => {
        const normalizedSuggestions = [...new Set(data?.suggestions)];
        const suggestionsAvailable =
          !!values.dealerLocation &&
          normalizedSuggestions &&
          normalizedSuggestions.length > 0;
        const showSuggestions =
          suggestionsAvailable && values.dealerLocation !== selectedSuggestion;
        const onClear = () => {
          setFieldValue('dealerLocation', '');
          setSelectedSuggestion('');
        };
        return (
          <Form name="dealer-locator__form">
            <DealerLocatorFormAutosuggestEffect
              invoke={invoke}
              openPopup={openPopup}
              closePopup={closePopup}
              storeId={storeId}
              selectedSuggestion={selectedSuggestion}
            />
            <div className="d-flex">
              <span className="flex-grow-1 m-0">
                <Field
                  as={SearchInput}
                  autoComplete="off"
                  label={t('LOCATION')}
                  name="dealerLocation"
                  onClear={onClear}
                  onFocus={() => onFocus(!isDisplayed && showSuggestions)}
                  showRequired
                  validator={locationValidator}
                  {...initiatorProps}
                >
                  <PopupContainer
                    className={cx('position-absolute', 'p-0', 'mt-0', 'w-100', {
                      'd-none': !isDisplayed,
                      'border-0 shadow-none': !suggestionsAvailable
                    })}
                    handleClose={closePopup}
                    {...popupProps}
                  >
                    <DealerLocationSearchSuggestions
                      closePopup={closePopup}
                      selectedSuggestion={selectedSuggestion}
                      showSuggestions={showSuggestions}
                      suggestions={normalizedSuggestions}
                    />
                  </PopupContainer>
                </Field>
              </span>
              {selectedSuggestion && (
                <div className="d-none d-lg-flex mb-1 ps-4 align-items-end">
                  <DealerLocatorFilterButton
                    className="mb-2"
                    dealerStores={dealerStores}
                    filters={filters}
                    name="dealer-locator__filter-button--desktop"
                    setFilters={setFilters}
                  />
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

DealerLocatorForm.propTypes = {
  dealerStores: PropTypes.arrayOf(dealerlocatorResultsPropTypes),
  filters: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ),
  selectedSuggestion: PropTypes.string,
  setFilters: PropTypes.func.isRequired,
  setSelectedSuggestion: PropTypes.func,
  storeId: PropTypes.string
};

export default DealerLocatorForm;
