import { CATEGORY_STATUS_KEY } from '@app/components/pages/homepage/constants';
import {
  TIMEOUT_EXTENDED,
  REQUEST_ID_HEADER
} from '@app/constants/commonConstants';
import endpoints from '@app/constants/endpoints';
import { ERROR_DOMAIN, ERROR_PATH } from '@app/constants/errorConstants';
import { getHttpInstance } from '@app/services/defaultHttpService';
import { getBFFUrl, isDev, replaceTokensInUrl } from '@app/utils';
import { setError } from '../exception/actions';
import { normalizeError } from '../exception/utils';
import { createURLWithParams } from '../plp/utils';
import * as types from './constants';
import { v4 } from 'uuid';

const timeout = isDev() ? TIMEOUT_EXTENDED : undefined;

export const getCategoryDetails = category => async (dispatch, getState) => {
  const http = getHttpInstance(timeout);
  const state = getState();
  const url = createURLWithParams(state);

  dispatch({ type: types.CATEGORY_GET_DETAILS_BEGIN });
  try {
    const { data } = await http.get(url, {
      headers: {
        [REQUEST_ID_HEADER]: v4()
      }
    });

    dispatch({
      type: types.CATEGORY_GET_DETAILS_SUCCESS,
      payload: { category, data }
    });
  } catch (error) {
    const err = normalizeError(error);
    dispatch({ type: types.CATEGORY_GET_DETAILS_FAIL });
    dispatch(setError(ERROR_DOMAIN.CATEGORY, ERROR_PATH.MAIN, err));
  }
};

export const setCategoryData =
  ({ data, category }) =>
  dispatch => {
    return dispatch({
      type: types.CATEGORY_SET_DATA,
      payload: {
        data,
        category
      }
    });
  };

export const updateCategoryProducts =
  ({ productsId, category }) =>
  dispatch => {
    return dispatch({
      type: types.CATEGORY_UPDATE_PRODUCTS,
      payload: { productsId, category }
    });
  };

export const getHomepageRecentlyPurchasedCategories =
  () => async (dispatch, getState) => {
    const http = getHttpInstance();
    const { storeId, langId } = getState().common;
    const url = replaceTokensInUrl(
      endpoints.CATEGORIES_RECENTLY_PURCHASED,
      storeId,
      langId
    );
    const searchConfig = {
      headers: types.GET_CATEGORIES_NO_CACHE_HEADER
    };

    const statusKey = CATEGORY_STATUS_KEY.recentlyPurchasedStatus;
    const statusErrorKey = CATEGORY_STATUS_KEY.recentlyPurchasedStatusError;

    const keys = {
      statusKey,
      statusErrorKey
    };
    dispatch({
      type: types.GET_HOMEPAGE_CATEGORIES_BEGIN,
      payload: { statusKey, statusErrorKey }
    });

    try {
      const { data } = await http.get(url, searchConfig);
      const categoriesRecentlyPurchased = data?.categories ?? [];
      dispatch({
        type: types.GET_HOMEPAGE_CATEGORIES_SUCCESS,
        payload: {
          categoriesRecentlyPurchased,
          statusKey: keys.statusKey,
          statusErrorKey: keys.statusErrorKey
        }
      });
    } catch (error) {
      dispatch({
        type: types.GET_HOMEPAGE_CATEGORIES_FAIL,
        payload: { error, statusKey, statusErrorKey }
      });
    }
  };

export const getLucidProductCategory = query => (dispatch, getState) => {
  const locale = getState().common.locale;
  const storeIdentifier = getState().common?.storeIdentifier;
  const params = {
    locale,
    storeIdentifier,
    ...query
  };

  const http = getHttpInstance();
  dispatch({
    type: types.LUCID_CATEGORY_GET_DETAILS_BEGIN
  });

  const url = getBFFUrl(endpoints.GET_LUCID_PRODUCT_CATEGORIES);
  return http
    .get(url, { params })
    .then(response => {
      const data = response.data ?? [];
      dispatch({
        type: types.LUCID_CATEGORY_GET_DETAILS_SUCCESS,
        payload: data
      });
    })
    .catch(error => {
      dispatch({
        type: types.LUCID_CATEGORY_GET_DETAILS_FAIL,
        payload: error
      });
    });
};
