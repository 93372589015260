import { FORM_SAVE_EQUIPMENT } from '@app/constants/analyticsConstants';

export const validateSerialNumber = (serialNumber, flag) => {
  const lastFiveChars = serialNumber.length === 8 && serialNumber.substr(3, 8);
  let key = '';
  if (serialNumber.length < 2) {
    key = !flag ? 'MEQ_ADD_EQ_MAX_ERROR1' : '';

    return { isValid: false, key };
  } else if (
    serialNumber.length > 8 ||
    (lastFiveChars && lastFiveChars.match(/[^0-9]/g))
  ) {
    key = 'CAT_FBE_HOME_INVALID_SNI';
    return { isValid: false, key };
  } else {
    return { isValid: true, key };
  }
};

export const getFormattedSerialNumber = serialNumber => {
  if (serialNumber.length > 3) {
    const serialPrefix = serialNumber.substring(0, 3);
    let restChars = serialNumber.slice(3);
    if (!isNaN(restChars) && restChars.length > 0) {
      while (restChars.length < 5) {
        restChars = `0${restChars}`;
      }
      return `${serialPrefix}${restChars}`.toUpperCase();
    }
  }
  return '';
};

export const saveEquipment = currentEquipment => {
  return {
    ...currentEquipment,
    validSerialAndModel: currentEquipment.validSerialAndModel ?? true,
    unValidatedItemListId: currentEquipment.unValidatedItemListId ?? '',
    unValidatedEquipmentId: currentEquipment.unValidatedEquipmentId ?? '',
    model: currentEquipment.model,
    multiple: currentEquipment.multiple,
    assetId: currentEquipment.assetId ?? '',
    equipmentFamily: currentEquipment.equipmentFamily,
    uniqueIndex: currentEquipment.uniqueIndex ?? '',
    serialNumber: getFormattedSerialNumber(currentEquipment.serialNumber ?? ''),
    validatedAsset: currentEquipment.validatedAsset ?? false,
    imageURL: currentEquipment.imageURL,
    productId: currentEquipment.productId,
    seoUrl: currentEquipment.seoUrl,
    isQRCodeTempEquipment: currentEquipment.isQRCodeTempEquipment,
    equipmentUniqueId: currentEquipment.equipmentUniqueId
  };
};

export const formSubmittedEventPayloads = (
  serialNumber,
  currentEquipment,
  isCSPCustomer,
  notifyDealer,
  formLocation = ''
) => {
  const { model, equipmentFamily } = currentEquipment;
  return {
    event: 'formSubmitted',
    pagePath: document.location.pathname,
    formName: FORM_SAVE_EQUIPMENT,
    formContent: isCSPCustomer
      ? `${serialNumber} | ${model} ${equipmentFamily}`
      : serialNumber,
    formContent2: isCSPCustomer ? !!notifyDealer : `${model}${equipmentFamily}`,
    formFieldCausingError: '',
    formStatus: '',
    formLocation: formLocation
  };
};
