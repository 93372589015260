import { useSelector } from 'react-redux';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  HeaderWaffleMenuItems,
  HeaderWaffleMenuTitle
} from '@cat-ecom/pcc-components';

const WaffleDrawer = drawerProps => {
  const mobileLabel = useSelector(s => s.header?.waffle?.mobileLabel) ?? '';
  const menuItems = useSelector(s => s.header?.waffle?.menuItems);
  const items = menuItems?.map(item => ({
    label: item?.appLabel,
    link: item?.appUrl
  }));

  return (
    <Drawer {...drawerProps}>
      <DrawerHeader>
        <HeaderWaffleMenuTitle title={mobileLabel.toLowerCase()} />
      </DrawerHeader>
      <DrawerBody>
        <HeaderWaffleMenuItems items={items} />
      </DrawerBody>
    </Drawer>
  );
};

export default WaffleDrawer;
