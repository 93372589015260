import {
  getDefaultDisplayName,
  getDefaultDisplayNameEx,
  getDefaultValue,
  fmtDropBoxAddress,
  getSelectedLanguage
} from './util';
import { IMAGE_PREFIX } from '../../components/pages/account/shopping-preferences/constants';
export const updatePreferencesData = spd => {
  // make additions to data for display purposes

  const dropBoxAddress = ((spd.dropBox || {}).Address || []).map((a, i) => ({
    ...a,
    value: i.toString(),
    displayName: fmtDropBoxAddress(a)
  }));

  return {
    ...spd,
    dropBox: { ...spd.dropBox, Address: dropBoxAddress },
    displayOrderTypes: spd.displayOrderTypes === false ? false : true
  };
};

export const getInitialFormValues = data => {
  const { pickup, dropBox, shipTo } = data;
  const {
    pickupMethods,
    pickupInst,
    specialInst: pickupSpecialInstructions
  } = pickup || {};

  const {
    dropBoxMethods,
    dropBoxInst,
    Address: dropBoxAddress,
    specialInst: dropBoxSpecialInstructions
  } = dropBox || {};

  const {
    shippingMethods,
    shipInst,
    specialInst: shipSpecialInstructions,
    Address: shipAddress
  } = shipTo || {};

  const initialValues = {
    dealerName: data.dealerName,
    dealerCustomerNumber: getDefaultDisplayName(data.customerNumber),
    dealerStoreLocation: getDefaultDisplayName(data.dealerStore),
    homePageSettings: getDefaultValue(data.adpSettings),
    loginStartPageSettings: getDefaultDisplayName(data.startPage),
    loginDefaultLangSettings: getSelectedLanguage(
      data.languageOptions,
      data.defaultLanguage
    ),
    orderDetailsDealerSettings: getDefaultValue(data.orderTypes),
    orderDetailsAttachQuote: data.attachQuoteToPDF,
    editOrderByInformation: data.editOrderedByFlag,
    orderDetailsEndUseCode: getDefaultDisplayNameEx(data.endUseCodes),

    shoppingCartAddNotes: data.displayLineItemNotes,
    shoppingCartDisplayOrderInfo: data.displayOrderInformation,
    shoppingCartQuickOrder: data.displayQuickOrder,

    shoppingSourcingDetail: data.showSourcingDetailToggle,

    fulfillment: getDefaultValue(data.deliveryPreference),
    fulfillmentSkipPickupDeliveryPage: data.skipSB,

    fulfillmentPickupAddress: (pickup || {}).Address, // single fixed value
    fulfillmentDropBoxAddress: getDefaultDisplayNameEx(dropBoxAddress),
    fulfillmentShippingAddress: getDefaultDisplayNameEx(shipAddress),

    fulfillmentPickupMethod: getDefaultDisplayNameEx(pickupMethods),
    fulfillmentdropBoxMethod: getDefaultDisplayNameEx(dropBoxMethods),
    fulfillmentShippingMethod: getDefaultDisplayNameEx(shippingMethods),

    fulfillmentDropBoxInstructions: getDefaultDisplayNameEx(dropBoxInst),
    fulfillmentPickupInstructions: getDefaultDisplayNameEx(pickupInst),
    fulfillmentShippingInstructions: getDefaultDisplayNameEx(shipInst),

    fulfillmentSpecialPickupInstructions: pickupSpecialInstructions || '',
    fulfillmentSpecialDropBoxInstructions: dropBoxSpecialInstructions || '',
    fulfillmentSpecialShippingInstructions: shipSpecialInstructions || '',

    paymentMethod: getDefaultDisplayName(data.paymentMethods),
    // initial state of the display PO toggle
    paymentPoNumberToggle:
      data.poToggle && data.displayPoNumber !== false
        ? true
        : data.displayPoNumber,
    paymentPoNumber: data.poNumber || '',
    paymentRequirePoSuffix: data.requirePOSuffix,
    imageSettings: data.imageSettings,
    shoppingCartPrefs: data.shoppingCartPrefs
  };

  (data.imageSettings || []).forEach((p, i) => {
    initialValues[`${IMAGE_PREFIX}${i}`] = p.default;
  });

  return initialValues;
};
