import {
  SubheaderShopByCategory,
  SubheaderCategoryItems
} from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  filterBuyingOptionById,
  prepareColumns,
  validateIfActive
} from './utils';
import { useSelector } from 'react-redux';
import { useBreakpoint } from '@app/hooks';
import {
  CAT_MARKETING_FEATURES,
  PAGE_BREAKPOINTS
} from '@app/constants/commonConstants';
import capitalize from 'lodash/capitalize';
import useBuyingOptionVisibility from '../hooks/useBuyingOptionVisibility';

const ShopByCategoryWrapper = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const currentPage = pathname?.split('/').slice(-1)[0];

  const ALL_CATEGORIES = t('CAT_ALL_CATEGORIES');
  const CHOOSE_A_CATEGORY = t('CHOOSE_A_CATEGORY');
  const BUYING_OPTIONS = t('BUYING_OPTIONS');
  const shopByCategory = useSelector(s => s.categories?.shopByCategory);
  const categories = useSelector(s => s.categories?.allCategories);
  const buyingOptions =
    useSelector(s => s.header?.globalNavigation?.buyingOptions) || [];

  const { showCatCardOption } = useBuyingOptionVisibility();

  const searchValue = showCatCardOption
    ? ''
    : [CAT_MARKETING_FEATURES.CAT_CARD];

  const filteredBuyingOptions = filterBuyingOptionById(
    buyingOptions,
    searchValue
  );

  const allCategories = [
    ...categories,
    {
      seoURL: shopByCategory,
      name: capitalize(t('CAT_SEARCH_CATEGORY')),
      subCategories: []
    }
  ];

  const allCategoriesWithActiveProp = allCategories?.map(item => {
    const isActive = validateIfActive({ url: item.seoURL, currentPage });
    return {
      ...item,
      isActive
    };
  });

  const buyingOptionsWithActiveProp = filteredBuyingOptions?.map(item => {
    const isActive = validateIfActive({ url: item.seoURL, currentPage });
    return {
      ...item,
      isActive
    };
  });

  const { preparedCategories, preparedBuyingOptions } = prepareColumns({
    categories: allCategoriesWithActiveProp,
    buyingOptions: buyingOptionsWithActiveProp
  });

  const isUserInPLPPage =
    Array.isArray(allCategoriesWithActiveProp) &&
    Array.isArray(buyingOptionsWithActiveProp) &&
    [...allCategoriesWithActiveProp, ...buyingOptionsWithActiveProp]?.some(
      ({ isActive }) => isActive
    );

  const isMobile = !useBreakpoint(PAGE_BREAKPOINTS.BLOCKS_LG);
  return (
    <SubheaderShopByCategory
      label={ALL_CATEGORIES}
      isMobile={isMobile}
      isCurrent={isUserInPLPPage}
    >
      <SubheaderCategoryItems
        categories={preparedCategories}
        categoriesLabel={CHOOSE_A_CATEGORY}
        buyingOptionsLabel={BUYING_OPTIONS}
        buyingOptions={preparedBuyingOptions}
        isMobile={isMobile}
        currentPage={currentPage}
      />
    </SubheaderShopByCategory>
  );
};

export default ShopByCategoryWrapper;
