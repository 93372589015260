import { STATUS } from '../../constants/commonConstants';
import * as types from './constants';
import { footerInitialStore } from './initialStore';

const footerReducer = (state = footerInitialStore, action) => {
  switch (action.type) {
    case types.FOOTER_GET_LANGUAGES_BEGIN: {
      return {
        ...state,
        getLanguageStatus: STATUS.PENDING
      };
    }
    case types.FOOTER_GET_LANGUAGES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        getLanguageStatus: STATUS.RESOLVED
      };
    }
    case types.FOOTER_GET_LANGUAGES_FAIL: {
      return {
        ...state,
        getLanguageStatus: STATUS.REJECTED
      };
    }
    case types.FOOTER_CHANGE_LANGUAGE_BEGIN: {
      return {
        ...state,
        changeLanguageStatus: STATUS.PENDING
      };
    }
    case types.FOOTER_CHANGE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        changeLanguageStatus: STATUS.RESOLVED
      };
    }
    case types.FOOTER_CHANGE_LANGUAGE_FAIL: {
      return {
        ...state,
        changeLanguageStatus: STATUS.REJECTED
      };
    }
    case types.FOOTER_GET_SOCIAL_LINKS_BEGIN: {
      return {
        ...state,
        getSocialMediaLinkStatus: STATUS.PENDING
      };
    }
    case types.FOOTER_GET_SOCIAL_LINKS_SUCCESS: {
      const { socialMediaLinks } = action.payload;
      return {
        ...state,
        socialMediaLinks: socialMediaLinks,
        getSocialMediaLinkStatus: STATUS.RESOLVED
      };
    }
    case types.FOOTER_GET_SOCIAL_LINKS_FAIL: {
      return {
        ...state,
        getSocialMediaLinkStatus: STATUS.REJECTED
      };
    }
    default: {
      return state;
    }
  }
};

export { footerInitialStore };
export default footerReducer;
