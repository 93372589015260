import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'cat-ecommerce-alloy';
import CreateListForm from '../CreateListForm';
import useSavedLists from '../useSavedLists';

const CreateSavedListModal = ({
  close,
  isOpen,
  createSavedListData,
  fromPDPPage = false
}) => {
  const { t } = useTranslation();
  const { setShowSavedListsModalAfterADP } = useSavedLists();
  const onCloseModal = () => {
    close();
    setShowSavedListsModalAfterADP(false);
  };

  return (
    <div className="container">
      <Modal
        isOpen={isOpen}
        close={onCloseModal}
        title={t('CREATE_NEW_LIST')}
        focusSelector="input"
      >
        <CreateListForm
          close={onCloseModal}
          isOpen={isOpen}
          createSavedListData={createSavedListData}
          fromPDPPage={fromPDPPage}
        />
      </Modal>
    </div>
  );
};

CreateSavedListModal.propTypes = {
  close: PropTypes.func,
  isOpen: PropTypes.bool,
  createSavedListData: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.string,
        partNumber: PropTypes.string
      })
    )
  })
};

export default CreateSavedListModal;
