import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const DropdownField = ({
  as: Component,
  ariaLabel,
  onChange,
  returnOnchange,
  setValue,
  setTouched,
  value,
  ...props
}) => {
  const valueRef = useRef(null);
  // consider value change to update reference that avoids infinite loop
  useEffect(() => {
    if (valueRef.current !== value) {
      valueRef.current = value;
    }
  }, [value]);

  const handleDropdownChange = newVal => {
    if (returnOnchange) {
      onChange(newVal);
    } else {
      if (valueRef.current !== newVal) {
        if (onChange) {
          onChange(newVal);
        }
        setValue(newVal);
        valueRef.current = newVal;
      }
    }
  };
  return (
    <Component
      aria-label={ariaLabel}
      onChange={handleDropdownChange}
      onClick={() => setTouched(true)}
      selected={value}
      {...props}
    />
  );
};

DropdownField.propTypes = {
  as: PropTypes.elementType.isRequired,
  ariaLabel: PropTypes.string,
  onChange: PropTypes.func,
  setTouched: PropTypes.func.isRequired,
  returnOnchange: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export { DropdownField };
