import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTracking } from 'react-tracking';
import {
  CONTROL_CENTER_PAGE_PATH,
  DEFAULT_BUSINESS_UNIT,
  EVENT_ADD_TO_CART,
  EVENT_CHECKOUT,
  EVENT_PROCED_TO_CHECKOUT,
  EVENT_FORM_SUBMITTED,
  EVENT_FORM_VISIBLE,
  EVENT_IMPRESSION_READY,
  EVENT_PRODUCT_CLICK,
  EVENT_PRODUCT_DETAIL_READY,
  EVENT_REFUND,
  EVENT_REMOVE_FROM_CART,
  EVENT_TRANSACTION_READY,
  STATUS_ERROR,
  STATUS_SUCCESS,
  EVENT_FREIGHT_CALC,
  CVR_E_CERTIFCATE_NUMBER,
  PCC_PAYMENT_PAGE
} from '../../constants/analyticsConstants';
import { useTranslation } from 'react-i18next';
import {
  PM_KIT_WIDGET_SERIAL,
  PM_KIT_WIDGET_MODEL
} from '../../constants/commonConstants';
import { useFitmentCheck } from '@app/hooks';

const defaultProps = {
  businessUnit: DEFAULT_BUSINESS_UNIT
};

/**
 * Reusable logic to build & fire enhanced eCommerce analytics events
 *
 * @typedef {Object} product
 * @property {string} name
 * @property {string} id
 * @property {string} brand
 * @property {number} quantity
 * @property {number} price
 * @property {string} category
 * @property {string} currencyCode
 * @property {string} businessUnit
 * @property {string} inventoryStatus
 * @property {string} inventoryLocation
 * @property {string} kitId
 * @property {number} productWeight
 * @property {string} classification
 * @property {string} list
 * @property {number} position
 * @property {string} packageFlag
 * @property {string} checkFitment
 * @property {string} relatedModel
 * @param {Object} props options for this instance of useAnalytics
 * @param {string} props.businessUnit The Caterpillar business unit that stocks the part, e.g. 'Filters', 'Undercarriage', 'PCC', 'GET', 'BCP'
 */
const useAnalytics = ({
  businessUnit = DEFAULT_BUSINESS_UNIT
} = defaultProps) => {
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();
  const currencyCode = useSelector(s => s.dealerCustomer?.currency) ?? '';
  const dealerId = useSelector(s => s.dealer?.dealerCode) ?? '';
  const cartId = useSelector(s => s.orders?.current) ?? '';
  const paymentMethod = useSelector(s => s.common?.paymentMethods);
  const storeName = useSelector(s => s.store?.name) ?? '';
  const model =
    useSelector(s => s.myEquipment?.equipments?.selectedEquipment?.model) ?? '';
  const cartDetails = useMemo(
    () => ({
      dealerId,
      cartId,
      businessUnit,
      paymentMethod: (paymentMethod ?? []).join(', ')
    }),
    [businessUnit, cartId, dealerId, paymentMethod]
  );
  const { t } = useTranslation();
  const getFormInfo = useCallback(
    ({
      event,
      pagePath,
      formName,
      formContent,
      formContent2,
      formFieldCausingError,
      formStatus,
      formLocation
    } = {}) => {
      return {
        event: event ?? '',
        pagePath: pagePath ?? '',
        formName: formName ?? '',
        formContent: formContent ?? '',
        formContent2: formContent2 ?? '',
        formStatus: formStatus ?? '',
        formFieldCausingError: formFieldCausingError ?? '',
        formLocation: formLocation ?? ''
      };
    },
    []
  );
  let quantityAvailability = [
    {
      quantity: 0,
      availabilityMessage: 'Instock'
    },
    {
      quantity: 0,
      availabilityMessage: 'Dealer Network'
    },
    {
      quantity: 0,
      availabilityMessage: 'Cat  DC'
    },
    {
      quantity: 0,
      availabilityMessage: 'Contact  Dealer'
    }
  ];
  const getInventoryStatus = (availability, sourcingDetails) => {
    if (sourcingDetails?.length > 0) {
      quantityAvailability.forEach((value, idx) => {
        let quantity = 0;
        sourcingDetails.forEach(item => {
          if (parseInt(item.sequence) === idx) {
            quantity = item.availStoreList.reduce(
              (acc, curr) => acc + Number(curr.quantityAvailable),
              0
            );
            value.quantity = quantity;
          }
        });
        value.quantity = quantity;
      });

      if (
        Array.isArray(availability) &&
        availability.some(
          itm => itm.availabilityMessage === t('CONTACT_YOUR_DEALER')
        )
      ) {
        const contactDealer = availability.find(
          itm => itm.availabilityMessage === t('CONTACT_YOUR_DEALER')
        );
        quantityAvailability[3].quantity = contactDealer.quantityAvailable;
      }
      const inventoryStatus = quantityAvailability
        .map(
          ({ quantity, availabilityMessage }) =>
            `${quantity} : ${availabilityMessage} `
        )
        .join('|');
      return inventoryStatus;
    }

    return Array.isArray(availability)
      ? availability
          .map(node => `${node.quantityAvailable}: ${node.availabilityMessage}`)
          .join(', ')
      : availability;
  };

  const { getFitmentCheck } = useFitmentCheck();

  const getProductInfo = useCallback(
    ({
      availability,
      brand = '',
      category = '',
      checkFitment = '',
      classification = '',
      msrp = '',
      partNumber = '',
      kitId = '',
      name = '',
      packageFlag,
      position = '',
      price = '',
      productAttributesVisible = '',
      productWeight = '',
      relatedModel,
      sourcingDetails,
      inventoryStatus,
      ...rest
    }) => ({
      brand: brand,
      businessUnit, // Do we really need businessUnit in two places?
      category: category,
      checkFitment: checkFitment,
      classification: classification,
      msrp: msrp,
      id: partNumber,
      inventoryLocation: sourcingDetails?.length > 0 ? '' : storeName,
      inventoryStatus: getInventoryStatus(
        availability ?? '',
        sourcingDetails ?? []
      ),
      kitId: kitId,
      name: name,
      packageFlag: packageFlag ?? 'No',
      position: position,
      price: price,
      productAttributesVisible: productAttributesVisible,
      productWeight: productWeight,
      relatedModel: relatedModel ?? model,
      ...rest
    }),
    [businessUnit, storeName]
  );

  /**
   * Builds and returns a addToCart event object
   * @param {string} list
   * @param {[product]} products
   * @returns
   */
  const buildAddToCartEvent = useCallback(
    (products = [{}], list = '') => {
      const productInfo = products.map(product =>
        getProductInfo({ list, ...product })
      );
      return {
        event: EVENT_ADD_TO_CART,
        cartDetails,
        ecommerce: {
          currencyCode,
          add: {
            products: productInfo
          }
        }
      };
    },
    [cartDetails, currencyCode, getProductInfo]
  );

  /**
   * Builds and returns a removeFromCart event object
   * @param {string} list
   * @param {[product]} products
   * @returns
   */
  const buildRemoveFromCartEvent = useCallback(
    (products = [{}]) => {
      const productInfo = products.map(product => getProductInfo(product));
      return {
        event: EVENT_REMOVE_FROM_CART,
        cartDetails,
        ecommerce: {
          currencyCode,
          remove: {
            products: productInfo
          }
        }
      };
    },
    [cartDetails, currencyCode, getProductInfo]
  );

  /**
   * Builds and returns a productClick event object
   * @param {string} list
   * @param {[product]} products
   * @returns
   */
  const buildProductClickEvent = useCallback(
    (list = '', products = [{}], dcnCurrency = currencyCode) => {
      const productInfo = products.map(product => getProductInfo(product));
      return {
        event: EVENT_PRODUCT_CLICK,
        cartDetails,
        ecommerce: {
          currencyCode: dcnCurrency,
          click: {
            actionField: { list },
            products: productInfo
          }
        }
      };
    },
    [cartDetails, currencyCode, getProductInfo]
  );

  /**
   * Builds and returns a productImpression event object
   * @param {string} list
   * @param {product[]} product
   * @param {string} customCurrencyCode
   * @returns
   */
  const buildProductImpressionEvent = useCallback(
    (list = '', productList = [], customCurrencyCode) => {
      const impressions = productList.map(product =>
        getProductInfo({ list, ...product })
      );
      return {
        event: EVENT_IMPRESSION_READY,
        cartDetails,
        ecommerce: {
          currencyCode: customCurrencyCode ?? currencyCode,
          impressions
        }
      };
    },
    [cartDetails, currencyCode, getProductInfo]
  );

  const buildHomeContainersProductClickEvent = useCallback(
    (list = '', products = [{}]) => {
      const productInfo = products.map(product => getProductInfo(product));
      return {
        event: EVENT_PRODUCT_CLICK,
        cartDetails,
        ecommerce: {
          currencyCode,
          click: {
            actionField: { list },
            products: productInfo
          }
        }
      };
    },
    [cartDetails, currencyCode, getProductInfo]
  );
  /**
   * Builds and returns a productDetailReady event object
   * @param {product} product
   * @returns
   */
  const buildProductDetailReadyEvent = useCallback(
    (product = {}) => {
      const productInfo = getProductInfo(product);
      return {
        event: EVENT_PRODUCT_DETAIL_READY,
        cartDetails,
        ecommerce: {
          currencyCode,
          detail: {
            actionField: { list: '' },
            products: [productInfo]
          }
        }
      };
    },
    [cartDetails, currencyCode, getProductInfo]
  );

  /**
   * build and returns a formSubmitted event object
   * @returns
   */
  const buildFormSubmittedEvent = useCallback(
    formInfo => {
      return getFormInfo({
        ...formInfo,
        event: EVENT_FORM_SUBMITTED
      });
    },
    [getFormInfo]
  );

  /**
   * build and returns a formVisible event object
   * @returns
   */
  const buildFormVisibleEvent = useCallback(
    formInfo => {
      return getFormInfo({
        ...formInfo,
        event: EVENT_FORM_VISIBLE
      });
    },
    [getFormInfo]
  );

  /**
   * Builds and returns a checkout event object
   * @param {string} list
   * @param {product[]} product
   * @param {string} checkoutType
   * @param {number} step
   * @returns
   */
  const buildCheckoutEvent = useCallback(
    ({
      list = '',
      products = [],
      checkoutType,
      step = 1,
      currencyNotation,
      billingMethod = '',
      fulfillmentType = ''
    }) => {
      const mappedProducts = products.map(product =>
        getProductInfo({ list, ...product })
      );
      return {
        event: EVENT_CHECKOUT,
        ecommerce: {
          currencyCode: currencyNotation || currencyCode,
          checkoutType,
          checkout: {
            actionField: {
              step,
              option: billingMethod,
              option1: fulfillmentType,
              option2: ''
            },
            products: mappedProducts
          }
        }
      };
    },
    [currencyCode, getProductInfo]
  );

  /**
   * Builds and returns a proced to checkout event object
   * @param {string} list
   * @param {product[]} product
   * @param {string} checkoutType
   * @param {number} step
   * @returns
   */
  const buildProceedToCheckoutEvent = useCallback(
    ({
      list = '',
      products = [],
      checkoutType,
      step = 5,
      currencyNotation,
      billingMethod = '',
      fulfillmentType = ''
    }) => {
      const mappedProducts = products.map(product =>
        getProductInfo({ list, ...product })
      );
      return {
        event: EVENT_PROCED_TO_CHECKOUT,
        ecommerce: {
          currencyCode: currencyNotation || currencyCode,
          checkoutType,
          beginCheckout: {
            actionField: {
              step,
              option: billingMethod,
              option1: fulfillmentType,
              option2: ''
            },
            products: mappedProducts
          }
        }
      };
    },
    [currencyCode, getProductInfo]
  );

  /**
   * Builds and returns a full refund event object
   * @param {string} id The transaction id for the refund. This is just the Commerce Order ID.
   */
  const buildFullRefundEvent = useCallback(
    id => ({
      event: EVENT_REFUND,
      ecommerce: {
        refund: {
          actionField: { id }
        }
      }
    }),
    []
  );

  /**
   * Dispatches a given action with and impression event attached to the promise
   * This can be useful for actions that retrieve products that are meant to fire an
   * impression event on success
   * @param {Function} action Action to be dispatched before firing the productImpressionEvent
   */
  const dispatchWithImpressionEvent = useCallback(
    action =>
      dispatch(action).then(
        ({ productDetails, currencyCode, gaListName } = {}) => {
          const event = buildProductImpressionEvent(
            gaListName,
            productDetails,
            currencyCode
          );
          trackEvent(event);
        }
      ),
    [buildProductImpressionEvent, dispatch, trackEvent]
  );

  /**
   * Builds and returns a PM Kit Impression event object
   * @param {object} pmKitOptions The response data from the PM Kit Dropdown
   * @param {string} list
   * @param {string} model
   * @returns
   */
  const buildPMKitImpressionEvent = useCallback(
    (pmKitsList = [], list = '', model = '') => {
      const impressions = pmKitsList.map(
        (
          { serviceOptionID = '', description = '', uniqueId = '', title = '' },
          index
        ) => {
          return {
            name: description || title,
            id: index + 1,
            kitId: serviceOptionID || uniqueId,
            category: '',
            packageFlag: '',
            checkFitment: 'fity',
            relatedModel: model,
            list,
            inventoryLocation: storeName,
            position: index + 1,
            classification: ''
          };
        }
      );
      return {
        event: EVENT_IMPRESSION_READY,
        cartDetails,
        ecommerce: {
          currencyCode,
          impressions
        }
      };
    },
    [cartDetails, currencyCode, storeName]
  );

  /**
   * Builds and returns a PM Kit Product Click event object
   * @param {object} selectedPMKit The selected value from the PM Kit Dropdown
   * @param {string} list
   * @param {string} model
   * @returns
   */
  const buildPMKitProductClickEvent = useCallback(
    (selectedPMKit = {}, list = '', model = '') => {
      const {
        displayValue = '',
        value = '',
        title = '',
        uniqueId = ''
      } = selectedPMKit;
      const productInfo = getProductInfo({
        name: displayValue || title,
        kitId: value || uniqueId,
        list,
        category: '',
        packageFlag: 'No',
        checkFitment: 'fity',
        relatedModel: model
      });
      return {
        event: EVENT_PRODUCT_CLICK,
        cartDetails,
        ecommerce: {
          currencyCode,
          click: {
            actionField: { list },
            products: productInfo
          }
        }
      };
    },
    [cartDetails, currencyCode, getProductInfo]
  );

  /**
   * Builds and returns a transactionReady event object
   * @param {string} list
   * @param {product[]} product
   * @param {string} customCurrencyCode
   * @param {string} checkoutType
   * @param {string} fulfillmentType
   * @param {object} actionField
   * @returns
   */
  const buildTransactionEvent = useCallback(
    ({
      list = '',
      customCurrencyCode,
      productList = [],
      checkoutType,
      fulfillmentType = '',
      actionField
    }) => {
      const productInfo = productList.map(product =>
        getProductInfo({ list, ...product })
      );
      return {
        event: EVENT_TRANSACTION_READY,
        ecommerce: {
          currencyCode: customCurrencyCode ?? currencyCode,
          checkoutType,
          fulfillmentType,
          purchase: {
            actionField,
            products: productInfo
          }
        }
      };
    },
    [currencyCode, getProductInfo]
  );

  /**
   * Builds a FreightCalc event Object
   * @param {Array} freightList Array of object that contains the freightMethod, and freightCost
   * @param {string} totalweight The total Weight of the orden .
   */
  const buildFreightCalcEvent = useCallback((freightList, totalweight) => {
    return {
      event: EVENT_FREIGHT_CALC,
      ecommerce: { freightList, freightWeight: totalweight }
    };
  }, []);

  /**
   * Builds a addToCart event and pushes it to the datalayer
   * @param {Object} props
   * @param {[product]} props.products
   */
  const fireAddToCartEvent = useCallback(
    ({ products, list = '' }) => {
      const event = buildAddToCartEvent(products, list);
      trackEvent(event);
    },
    [buildAddToCartEvent, trackEvent]
  );

  /**
   * Builds a removeFromCart event and pushes it to the datalayer
   * @param {Object} props
   * @param {[product]} props.products
   */
  const fireRemoveFromCartEvent = useCallback(
    ({ products }) => {
      const event = buildRemoveFromCartEvent(products);
      trackEvent(event);
    },
    [buildRemoveFromCartEvent, trackEvent]
  );

  /**
   * Builds a productClick event and pushes it to the datalayer
   * @param {Object} props
   * @param {string} props.list
   * @param {[product]} props.products
   */
  const fireProductClickEvent = useCallback(
    ({ list, products, dcnCurrency = currencyCode }) => {
      const event = buildProductClickEvent(list, products, dcnCurrency);
      trackEvent(event);
    },
    [buildProductClickEvent, trackEvent]
  );

  /**
   * Builds a product click event and pushes it to the datalayer
   * @param {string} list
   * @param {Array} products
   */
  const fireHomeContainersProductClickEvent = useCallback(
    ({ list, products }) => {
      const event = buildHomeContainersProductClickEvent(list, products);
      trackEvent(event);
    },
    [buildHomeContainersProductClickEvent, trackEvent]
  );

  /**
   * Builds a productImpression event and pushes it to the datalayer
   * @param {string} list
   * @param {Array} products
   * @param {string} currency
   */
  const fireProductImpressionEvent = useCallback(
    (list, products, customCurrencyCode) => {
      const event = buildProductImpressionEvent(
        list,
        products,
        customCurrencyCode
      );
      trackEvent(event);
    },
    [buildProductImpressionEvent, trackEvent]
  );

  /**
   * Builds a productImpression event and pushes it to the datalayer
   * @param {string} list
   * @param {Array} products
   */
  const fireProductDetailReadyEvent = useCallback(
    product => {
      const event = buildProductDetailReadyEvent(product);
      trackEvent(event);
    },
    [buildProductDetailReadyEvent, trackEvent]
  );

  /**
   * Builds a form submitted event and pushes it to the datalayer
   * @param {string} eventOptions
   */
  const fireFormSubmittedEvent = useCallback(
    eventOptions => {
      const event = buildFormSubmittedEvent(eventOptions);
      trackEvent(event);
    },
    [buildFormSubmittedEvent, trackEvent]
  );

  /**
   * Builds a form visible event and pushes it to the datalayer
   * @param {object} eventOptions
   */
  const fireFormVisibleEvent = useCallback(
    eventOptions => {
      const event = buildFormVisibleEvent(eventOptions);
      trackEvent(event);
    },
    [buildFormVisibleEvent, trackEvent]
  );

  /**
   * Builds a checkout event and pushes it to the datalayer
   * @param {string} list
   * @param {Array} products
   * @param {string} checkoutType
   * @param {number} step
   */
  const fireCheckoutEvent = useCallback(
    ({
      billingMethod,
      checkoutType,
      currencyNotation,
      emailCart,
      fulfillmentType,
      list,
      products,
      step
    }) => {
      const event = buildCheckoutEvent({
        products,
        checkoutType,
        step,
        currencyNotation,
        billingMethod,
        fulfillmentType,
        list,
        emailCart
      });
      trackEvent(event);
    },
    [buildCheckoutEvent, trackEvent]
  );

  /**
   * Builds a proceed to checkout event and pushes it to the datalayer
   * @param {string} list
   * @param {Array} products
   * @param {string} checkoutType
   * @param {number} step
   */
  const fireProceedToCheckoutEvent = useCallback(
    ({
      billingMethod,
      checkoutType,
      currencyNotation,
      emailCart,
      fulfillmentType,
      list,
      products,
      step
    }) => {
      const event = buildProceedToCheckoutEvent({
        products,
        checkoutType,
        step,
        currencyNotation,
        billingMethod,
        fulfillmentType,
        list,
        emailCart
      });
      trackEvent(event);
    },
    [buildProceedToCheckoutEvent, trackEvent]
  );

  /**
   * Builds a refund event and pushes it to the datalayer
   * @param {string} id The transaction id for the refund. This is just the Commerce Order ID.
   */
  const fireFullRefundEvent = useCallback(
    id => {
      const event = buildFullRefundEvent(id);
      trackEvent(event);
    },
    [buildFullRefundEvent, trackEvent]
  );

  /**
   * Builds a PM Kit Impression event and pushes it to the datalayer
   * @param {object} pmKitOptions The response data from the PM Kit Dropdown
   * @param {string} list
   * @param {string} model
   */
  const firePMKitImpressionEvent = useCallback(
    (pmKitOptions, list, model) => {
      const event = buildPMKitImpressionEvent(
        pmKitOptions?.data?.bundleData?.bundles || pmKitOptions,
        list,
        model
      );
      trackEvent(event);
    },
    [buildPMKitImpressionEvent, trackEvent]
  );

  /**
   * Builds a PM Kit Product Click event and pushes it to the datalayer
   * @param {object} selectedPMKit The selected value from the PM Kit Dropdown
   * @param {string} list
   * @param {string} model
   */
  const firePMKitProductClickEvent = useCallback(
    (selectedPMKit, list, model) => {
      const event = buildPMKitProductClickEvent(selectedPMKit, list, model);
      trackEvent(event);
    },
    [buildPMKitProductClickEvent, trackEvent]
  );

  /**
   * Builds a PM Kit Form Submit event and pushes it to the datalayer
   */
  const firePMKitFormSubmitEvent = useCallback(
    (
      prefix,
      low,
      high,
      isCATKitData,
      isPmKitsLoadedSuccessfully,
      salesModel,
      serialNumber,
      selectedUnit
    ) => {
      let event;
      if (!isPmKitsLoadedSuccessfully) {
        event = buildFormSubmittedEvent({ formStatus: 'Failure' });
      } else {
        if (serialNumber) {
          event = buildFormSubmittedEvent({
            formContent: '',
            formName: PM_KIT_WIDGET_SERIAL,
            formContent2: `Mdl: ${salesModel} SN: ${serialNumber} PM: ${isCATKitData.description} Int: ${isCATKitData.firstInterval} UOM: ${selectedUnit}`,
            formStatus: 'Success'
          });
        } else {
          event = buildFormSubmittedEvent({
            formContent: '',
            formName: PM_KIT_WIDGET_MODEL,
            formContent2: `Mdl: ${salesModel} SNP: ${prefix} SNR: ${low} ${high} PM: ${isCATKitData.description} Int: ${isCATKitData.firstInterval} UOM: ${selectedUnit}`,
            formStatus: 'Success'
          });
        }
      }
      trackEvent(event);
    },
    [buildFormSubmittedEvent, trackEvent]
  );

  /**
   * Builds a DCN Form Submit event and pushes it to the datalayer
   */
  const fireDcnFormEvent = useCallback(
    (dcn, formName, status) => {
      const event = buildFormSubmittedEvent({
        pagePath: CONTROL_CENTER_PAGE_PATH,
        formContent: dcn || 'All',
        formContent2: '',
        formFieldCausingError: status === STATUS_SUCCESS ? '' : STATUS_ERROR,
        formName: formName,
        formStatus: status
      });
      trackEvent(event);
    },
    [buildFormSubmittedEvent, trackEvent]
  );

  /**
   * Builds a transactionReady event and pushes it to the datalayer
   * @param {string} list
   * @param {product[]} product
   * @param {string} customCurrencyCode
   * @param {string} checkoutType
   * @param {string} fulfillmentType
   * @param {string} revenue
   * @param {string} shipping
   * @param {string} tax
   * @param {string} id
   * @param {string} coupon
   */
  const fireTransactionEvent = useCallback(
    ({
      list,
      customCurrencyCode,
      productList,
      checkoutType = '',
      fulfillmentType = '',
      revenue = '',
      tax = '',
      shipping = '',
      id = '',
      coupon = ''
    }) => {
      const actionField = {
        revenue,
        tax,
        shipping,
        id,
        coupon
      };
      const event = buildTransactionEvent({
        list,
        customCurrencyCode,
        productList,
        checkoutType,
        fulfillmentType,
        actionField
      });
      trackEvent(event);
    },
    [buildTransactionEvent, trackEvent]
  );

  /**
   * Builds a FreightCalc event and pushes it to the datalayer
   * @param {Array} freightList Array of object that contains the shipping cost, and methods
   * @param {string} totalweight The total Weight of the orden .
   */
  const fireFreightCalcEvent = useCallback(
    (freightList, totalweight) => {
      const event = buildFreightCalcEvent(freightList, totalweight);
      trackEvent(event);
    },
    [buildFreightCalcEvent, trackEvent]
  );

  /**Builds and returns a FBT Impression event object*/
  const buildFbtImpressionEvent = useCallback(
    (fbtItems = [], list = '', model = '') => {
      const impressions = fbtItems.map(
        (
          {
            partNumber,
            name,
            weight,
            availability,
            parentCatEntryId,
            unformattedTotalPrice
          },
          index
        ) => {
          return {
            name: `${partNumber}: ${name}`,
            id: partNumber,
            position: index + 1,
            price: unformattedTotalPrice,
            productWeight: weight,
            availability,
            kitId: parentCatEntryId,
            packageFlag: 'No',
            businessUnit: 'PCC',
            relatedModel: model,
            checkFitment: getFitmentCheck(partNumber),
            list
          };
        }
      );
      return {
        event: EVENT_IMPRESSION_READY,
        cartDetails,
        ecommerce: {
          currencyCode,
          impressions
        }
      };
    },
    [cartDetails, currencyCode, storeName]
  );

  /** Builds a FBT Items Impression event and pushes it to the datalayer*/
  const fireFbtImpressionEvent = useCallback(
    (fbtItems, list, model, fbtPriceAndAvailability) => {
      let fbtArray = [];
      fbtItems?.map(item => {
        const data = fbtPriceAndAvailability[item.partNumber];
        fbtArray.push({ ...data, name: item.name });
      });
      const event = buildFbtImpressionEvent(fbtArray, list, model);
      trackEvent(event);
    },
    [buildFbtImpressionEvent, trackEvent]
  );

  /**Builds and returns a PM Kit Product Click event object*/
  const buildFBTProductClickEvent = useCallback(
    (products = [{}], list = '', model = '') => {
      const productInfo = products.map(product =>
        getProductInfo({
          name: `${product.partNumber}: ${product.partName}`,
          id: product.partNumber,
          category: '',
          packageFlag: 'No',
          checkFitment: 'fity',
          relatedModel: model,
          position: product.position,
          price: product.price
        })
      );
      return {
        event: EVENT_PRODUCT_CLICK,
        cartDetails,
        ecommerce: {
          currencyCode,
          click: {
            actionField: { list },
            products: productInfo
          }
        }
      };
    },
    [cartDetails, currencyCode, getProductInfo]
  );

  /**Builds a FBT Product Click event and pushes it to the datalayer*/
  const fireFbtProductClickEvent = useCallback(
    (products, list, model) => {
      const event = buildFBTProductClickEvent(products, list, model);
      trackEvent(event);
    },
    [buildFBTProductClickEvent, trackEvent]
  );

  /*** Builds and returns a addToCart event object*/
  const buildFbtAddToCartEvent = useCallback(
    (products = [{}], list = '') => {
      const productInfo = products.map(product => getProductInfo(product));
      return {
        event: EVENT_ADD_TO_CART,
        cartDetails,
        ecommerce: {
          currencyCode,
          add: {
            actionField: { list },
            products: productInfo
          }
        }
      };
    },
    [cartDetails, currencyCode, getProductInfo]
  );

  /**Builds a FBT Add to cart event and pushes it to the datalayer*/
  const fireFbtAddToCartEvent = useCallback(
    ({ products }, list) => {
      const event = buildFbtAddToCartEvent(products, list);
      trackEvent(event);
    },
    [buildFbtAddToCartEvent, trackEvent]
  );

  /**Builds a CVR Enter e-certificate vent and pushes it to the datalayer*/
  const fireEnterECertficateSubmitEvent = useCallback(
    (formStatus, fieldError) => {
      const event = buildFormSubmittedEvent({
        formStatus: formStatus,
        formContent: '',
        formContent2: '',
        formName: CVR_E_CERTIFCATE_NUMBER,
        formLocation: PCC_PAYMENT_PAGE,
        pagePath: document.location.pathname,
        formFieldCausingError: fieldError ? fieldError : ''
      });
      trackEvent(event);
    },
    [buildFormSubmittedEvent, trackEvent]
  );

  /**
   * Builds an custom event and pushes it to the datalayer
   * @param {object} eventDetails
   */
  const fireCustomEvent = useCallback(
    eventDetails => {
      trackEvent({ ...eventDetails });
    },
    [trackEvent]
  );

  return {
    buildAddToCartEvent,
    buildRemoveFromCartEvent,
    buildProductClickEvent,
    buildProductImpressionEvent,
    buildProductDetailReadyEvent,
    buildCheckoutEvent,
    buildProceedToCheckoutEvent,
    buildFullRefundEvent,
    dispatchWithImpressionEvent,
    buildPMKitImpressionEvent,
    buildPMKitProductClickEvent,
    buildHomeContainersProductClickEvent,
    buildTransactionEvent,
    buildFbtImpressionEvent,
    buildFBTProductClickEvent,
    buildFreightCalcEvent,
    buildFbtAddToCartEvent,
    fireAddToCartEvent,
    fireRemoveFromCartEvent,
    fireProductClickEvent,
    fireProductImpressionEvent,
    fireProductDetailReadyEvent,
    fireFormSubmittedEvent,
    fireFormVisibleEvent,
    fireCheckoutEvent,
    fireProceedToCheckoutEvent,
    fireFullRefundEvent,
    firePMKitImpressionEvent,
    firePMKitProductClickEvent,
    firePMKitFormSubmitEvent,
    fireHomeContainersProductClickEvent,
    fireDcnFormEvent,
    fireTransactionEvent,
    fireFbtImpressionEvent,
    fireFbtProductClickEvent,
    fireFreightCalcEvent,
    fireFbtAddToCartEvent,
    fireEnterECertficateSubmitEvent,
    fireCustomEvent
  };
};

export default useAnalytics;
