import { useSelector } from 'react-redux';

const useShowToggleForExtendStoresList = showInCountryToggle => {
  const isShowingToggleFeatureFlag = useSelector(
    s => s.featureFlag?.PCC_CountryFirstDealerLocatorResultsFeatureFlag
  );

  const isToggleShownForExtendStoreList =
    !!isShowingToggleFeatureFlag && !!showInCountryToggle;

  return {
    isToggleShownForExtendStoreList
  };
};

export default useShowToggleForExtendStoresList;
