export const DEALER_GET_INFO_BEGIN = 'DEALER_GET_INFO_BEGIN';
export const DEALER_GET_INFO_SUCCESS = 'DEALER_GET_INFO_SUCCESS';
export const DEALER_GET_INFO_FAIL = 'DEALER_GET_INFO_FAIL';

export const DEALER_WARRANTY_RETURN_POLICY_BEGIN =
  'DEALER_WARRANTY_RETURN_POLICY_BEGIN';
export const DEALER_WARRANTY_RETURN_POLICY_SUCCESS =
  'DEALER_WARRANTY_RETURN_POLICY_SUCCESS';
export const DEALER_WARRANTY_RETURN_POLICY_FAIL =
  'DEALER_WARRANTY_RETURN_POLICY_FAIL';
