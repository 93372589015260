import { useTranslation } from 'react-i18next';
import { AlloyButton } from 'cat-ecommerce-alloy';
import useLogin from '@app/components/login/hooks/useLogin/useLogin';
import { useReplaceTokenWithElement } from '@app/hooks';

const CreateAccountDescription = () => {
  const [t] = useTranslation();
  const { replaceTokenWithElement } = useReplaceTokenWithElement();
  const { login } = useLogin();

  return (
    <>
      {`${t('REGISTER_MODAL_STEPS_SUBTITLE')}`}
      <br />
      <br />
      {`1) ${t('REGISTER_MODAL_1_SELECT_LOCAL_STORE')}`}
      <br />
      {`2) ${t('REGISTER_MODAL_2_CREATE_LOCAL_PROFILE')}`}
      <br />
      {`3) ${t('REGISTER_MODAL_3_VERIFY_EMAIL_ADDRESS')}`}
      <br />
      {`4) ${t('REGISTER_MODAL_4_CREATE_CAT_ACCOUNT')}`}
      <br />
      <br />
      {replaceTokenWithElement(
        t('REGISTER_MODAL_SIGN_IN_PROMPT'),
        '',
        <AlloyButton
          buttonType="link"
          size="large"
          onClick={login}
          className="p-0"
          style={{ verticalAlign: 'inherit' }}
        >
          {t('SIGN_IN')}
        </AlloyButton>
      )}
    </>
  );
};

export { CreateAccountDescription };
