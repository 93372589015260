import { createPortal } from 'react-dom';
import { HEADER as headerTarget } from '../../constants/targets';
import Header from './Header';
import Subheader from '../subheader';

const Container = () => {
  const headerRootElement = document.getElementById(headerTarget);

  return headerRootElement
    ? createPortal(
        <>
          <Header />
          <Subheader />
        </>,
        headerRootElement
      )
    : null;
};

export default Container;
