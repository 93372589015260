import * as types from './constants';
import * as categoriesTypes from '@app/store/categories/constants';
import endpoints from '../../constants/endpoints';
import { getHttpInstance } from '../../services/defaultHttpService';
import { getShortLocale, replaceTokensInString } from '../../utils';
import { clearError, setError } from '../exception/actions';
import { normalizeError } from '../exception/utils';
import { ERROR_DOMAIN, ERROR_PATH } from '../../constants/errorConstants';
import { checkForPref } from '@app/components/login/utils';
import { buildHeaderInfoUrlWithParams } from './utils';

export const getHeaderInfo = () => (dispatch, getState) => {
  dispatch({ type: types.HEADER_GET_INFO_BEGIN });
  const { storeId, langId } = getState().common;
  const http = getHttpInstance();
  const baseUrl = replaceTokensInString(
    endpoints.HEADER_INFO_SERVICE,
    storeId,
    langId
  );
  const url = buildHeaderInfoUrlWithParams(baseUrl);
  return http
    .get(url)
    .then(({ data } = {}) => {
      const { globalNavigation, ...rest } = data;

      const { categories, shopByCategory, ...globalNavigationRestParam } =
        globalNavigation;
      const payload = {
        globalNavigation: {
          ...globalNavigationRestParam
        },
        ...rest
      };

      dispatch({
        type: types.HEADER_GET_INFO_SUCCESS,
        payload
      });

      const categoriesPayload = {
        allCategories: categories,
        shopByCategory
      };

      dispatch({
        type: categoriesTypes.CATEGORIES_GET_INFO_SUCCESS,
        payload: categoriesPayload
      });
    })
    .catch(error => {
      dispatch({
        type: types.HEADER_GET_INFO_FAIL
      });
      dispatch(
        setError(ERROR_DOMAIN.HEADER, ERROR_PATH.NAVIGATION_BAR_GLOBAL_MODAL, {
          ...normalizeError(
            error,
            {
              severity: 'warning',
              message: 'UNKNOWN_ERROR_MESSAGE',
              title: undefined
            },
            true
          )
        })
      );

      dispatch({
        type: categoriesTypes.CATEGORIES_GET_INFO_FAIL
      });
    });
};

export const getWaffleInfo = () => (dispatch, getState) => {
  dispatch({ type: types.HEADER_GET_WAFFLE_BEGIN });
  const { storeId, locale } = getState().common;
  const chineseLocale = locale === 'zh_CN' ? 'zh_CN' : 'zh_TW';

  const http = getHttpInstance();
  const url = replaceTokensInString(
    endpoints.HEADER_WAFFLE_SERVICE,
    storeId,
    locale === chineseLocale ? chineseLocale : getShortLocale(locale)
  );
  const handleError = (error = {}) => {
    dispatch({
      type: types.HEADER_GET_WAFFLE_FAIL
    });
    dispatch(
      setError(ERROR_DOMAIN.HEADER, ERROR_PATH.WAFFLE_POPUP, {
        ...normalizeError(
          error,
          {
            severity: 'warning',
            message: 'UNKNOWN_ERROR_MESSAGE',
            title: undefined
          },
          true
        )
      })
    );
    dispatch(
      setError(ERROR_DOMAIN.HEADER, ERROR_PATH.NAVIGATION_BAR_GLOBAL_MODAL, {
        ...normalizeError(
          error,
          {
            severity: 'warning',
            message: 'UNKNOWN_ERROR_MESSAGE',
            title: undefined
          },
          true
        )
      })
    );
  };
  return http
    .get(url)
    .then(({ data: payload } = {}) => {
      if (!Array.isArray(payload.menuItems)) {
        handleError();
      } else {
        dispatch({
          type: types.HEADER_GET_WAFFLE_SUCCESS,
          payload
        });
        dispatch(clearError(ERROR_DOMAIN.HEADER, ERROR_PATH.WAFFLE_POPUP));
      }
    })
    .catch(error => handleError(error));
};

export const getRedirectInfo = onSuccess => (dispatch, getState) => {
  const { storeId, langId, catalogId } = getState().common;
  const url = replaceTokensInString(endpoints.CAT_SSOREDIRECT_URL, storeId);
  dispatch({ type: types.HEADER_REDIRECT_BEGIN });
  const http = getHttpInstance();
  return http
    .get(url, {
      params: {
        langId: langId,
        catalogId: catalogId,
        landing: '',
        checkForPrefs: checkForPref()
      }
    })
    .then(({ data }) => {
      dispatch({ type: types.HEADER_REDIRECT_SUCCESS, payload: data });
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch(() => {
      dispatch({ type: types.HEADER_REDIRECT_FAIL });
    });
};
