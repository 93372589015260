import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCountDown } from '@app/hooks';
import { convertDateToUtc, getRemainingTime } from './utils';

const withCutoffTime = WrappedComponent => {
  const WithCountDown = props => {
    const cutoffTimeGMT = useSelector(s => s.store.cutoffTimes);
    const showPickupCutoffFlag = useSelector(
      s => s.featureFlag?.PCC_showPickupCutoffFlag
    );
    const estimatedDeliveryDateFlag = useSelector(
      s => s.featureFlag?.PCC_pdpEstimatedDeliveryDateFlag
    );
    const pickupPrevTime = useRef({ hours: null, minutes: null });
    const deliveryPrevTime = useRef({ hours: null, minutes: null });
    const [pickupRemainingTime, setPickupRemainingTime] = useState({
      total: 0,
      hours: null,
      minutes: null,
      isReachedCutOff: false
    });
    const [deliveryRemainingTime, setDeliveryRemainingTime] = useState({
      total: 0,
      hours: null,
      minutes: null,
      isReachedCutOff: false
    });

    const updateRemainingTime = (enabled, setRemainingTime, date, timezone) => {
      if (!enabled || !date) {
        setRemainingTime({
          total: 0,
          hours: null,
          minutes: null,
          isReachedCutOff: false
        });
        return;
      }
      const dateUtc = convertDateToUtc(date, timezone);
      const { total, hours, minutes } = getRemainingTime(dateUtc);
      setRemainingTime(prev => ({
        ...prev,
        total,
        hours,
        minutes
      }));
    };

    useEffect(() => {
      const {
        pickup = new Date(),
        delivery = new Date(),
        timezone = ''
      } = cutoffTimeGMT || {};
      updateRemainingTime(
        showPickupCutoffFlag,
        setPickupRemainingTime,
        pickup,
        timezone
      );
      updateRemainingTime(
        estimatedDeliveryDateFlag,
        setDeliveryRemainingTime,
        delivery,
        timezone
      );
    }, [cutoffTimeGMT, estimatedDeliveryDateFlag, showPickupCutoffFlag]);

    const updateCalculateTime = ({ setTime, hours, minutes }) =>
      setTime(prev => {
        return {
          ...prev,
          ...(prev.hours !== hours && {
            hours: hours
          }),
          ...(hours + minutes <= 0 && {
            isReachedCutOff: true
          }),
          ...(prev.minutes !== minutes && {
            minutes: minutes
          })
        };
      });

    const isUpdateTimer = (hours, minutes, prevTime, remainingTime) => {
      return (
        Math.abs(hours + minutes) >= 0 &&
        !remainingTime.isReachedCutOff &&
        (prevTime.current.minutes !== minutes ||
          prevTime.current.hours !== hours)
      );
    };

    useCountDown(pickupRemainingTime?.total, (hours, minutes) => {
      if (isUpdateTimer(hours, minutes, pickupPrevTime, pickupRemainingTime)) {
        pickupPrevTime.current.minutes = minutes;
        pickupPrevTime.current.hours = hours;
        updateCalculateTime({
          setTime: setPickupRemainingTime,
          hours,
          minutes
        });
      }
    });

    useCountDown(deliveryRemainingTime?.total, (hours, minutes) => {
      if (
        isUpdateTimer(hours, minutes, deliveryPrevTime, deliveryRemainingTime)
      ) {
        deliveryPrevTime.current.minutes = minutes;
        deliveryPrevTime.current.hours = hours;
        updateCalculateTime({
          setTime: setDeliveryRemainingTime,
          hours,
          minutes
        });
      }
    });

    return (
      <WrappedComponent
        {...props}
        pickupRemainingTime={pickupRemainingTime}
        deliveryRemainingTime={deliveryRemainingTime}
      />
    );
  };

  return WithCountDown;
};

export default withCutoffTime;
