import { CatPrimaryNavItem } from 'blocks-react/bedrock/components/PrimaryNavItem/PrimaryNavItem.js';
import useLogout from '@app/components/login/hooks/useLogout/useLogout';
import { useTranslation } from 'react-i18next';
import styles from '../Subheader.modules.scss';
import cx from 'classnames';

const SignOut = () => {
  const { t } = useTranslation();

  const { logout } = useLogout();
  const signOutLabel = t('HEADER_SIGN_OUT');
  return (
    <CatPrimaryNavItem
      text={signOutLabel}
      onClick={logout}
      className={cx(styles['item-border-bottom'])}
    />
  );
};

export default SignOut;
