import { DISPLAY_DATE } from '@app/constants/commonConstants';
import { format } from 'date-fns';

export const getPopperOptions = () => {
  return {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          behavior: ['bottom']
        }
      },
      { name: 'offset', options: { offset: [0, 10] } }
    ]
  };
};

export const getAvailabilityTitle = (isDeliveryEnabled, isPickUpEnabled, t) => {
  return isDeliveryEnabled && !isPickUpEnabled
    ? t('PDP_AVAILABLE_TO_SHIP')
    : t('AVAILABLE_PICKUP');
};

export const getAvailabilityPickupText = (
  isDeliveryEnabled,
  isPickUpEnabled,
  t,
  className = 'ps-2'
) => {
  return (
    isDeliveryEnabled &&
    isPickUpEnabled && (
      <div className={className}>{t('PDP_DELIVERY_OPTIONS_AVAILABLE')} </div>
    )
  );
};

export const getFormattedDate = (
  date,
  dealerDateFormat = DISPLAY_DATE,
  locale
) => {
  const d = new Date(date);
  return format(d, dealerDateFormat, locale);
};
