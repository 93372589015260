import { getCookie } from '../../utils';

export const getRefApp = () => {
  const refAppDeepLink = document.getElementById('refAppDeepLink');
  let refApp = '';
  if (refAppDeepLink) {
    refApp = refAppDeepLink.value;
  } else {
    const refAppCookie = getCookie('refApp');
    if (refAppCookie) {
      refApp = refAppCookie;
    }
  }
  return refApp;
};

export const getSFIDFromCookie = () => {
  const salesForceCookie = getCookie('SFCID');
  return salesForceCookie || '';
};

export const getTemplateName = (viewName = '') => {
  let { value: templateName = '' } =
    document.getElementById('ga_templateName') ?? {};
  if (!templateName) {
    templateName = 'PCC';
  }
  if (viewName) {
    templateName += ` ${viewName}`;
  }
  return templateName;
};

export const getFormStatus = (totalItemsAdded, totalItemsRequested) => {
  let formStatus = '';
  if (
    totalItemsAdded === totalItemsRequested ||
    (totalItemsRequested > totalItemsAdded && totalItemsAdded > 0)
  ) {
    formStatus = 'success';
  } else if (totalItemsAdded === 0 && totalItemsRequested > 0) {
    formStatus = 'fail';
  }

  return formStatus;
};
