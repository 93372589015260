export const CSR_TEXTS = {
  BACK_TO_LIST: 'Back',
  WELCOME_CSR_AGENT: 'Welcome',
  CONNECTO_TO_CUSTOMER: 'Connect to Customer',
  CONNECT_TO_ACCOUNT: 'Connect to Account',
  CONTINUE_CONNECTING_TO_CUSTOMER: 'Continue',
  CSR_SEARCH: 'Search',
  CSR_BANNER_RESTRICTIONS_APPLY: 'Restrictions apply',
  CSR_BANNER_VERIFIED_USER: 'Verified User',
  CSR_BANNER_INSTANT_ACCESS_USER: 'Instant Access User',
  CSR_CART_ERROR_TOAST:
    'Not all items in your cart have been updated due to your session has timed-out.',
  CSR_ORDER_PLACEMENT_UNAVAILABLE:
    'Your session has timed-out. Please disconnect and connect again to complete your transaction.',
  CSR_QUICKLINKS_PLACEHOLDER: 'Select Option',
  CSR_REFRESH_ERROR_MESSAGE: `We're sorry, your session has timed-out. Please disconnect and connect again to edit the cart.`,
  CSR_REFRESH_ERROR_MESSAGE_IN_PDP: `Your session has timed-out. Please disconnect and connect again to edit the cart.`,
  CUSTOMER_CARE_CHECKOUT_FLOW:
    'Cat Customer Care checkout flow is not allowed for this dealer',
  CHECKOUT_UNAVAILABLE:
    'Cat Customer Care checkout flow is unavailable for this dealer',
  CUSTOMER_FORM_FIRSTNAME: 'First Name',
  CUSTOMER_FORM_LASTNAME: 'Last Name',
  CUSTOMER_FORM_LOGON_ID: 'Login Id',
  CUSTOMER_FORM_COMPANYNAME: 'Company Name',
  CUSTOMER_FORM_COUNTRY: 'Country',
  CUSTOMER_FORM_EMAIL: 'Email Address',
  CUSTOMER_FORM_PHONE: 'Phone Number',
  CUSTOMER_FORM_ADDRESS: 'Street Address',
  CUSTOMER_FORM_CITY: 'City',
  CUSTOMER_FORM_STATEPROVINCE: 'State/Province',
  CUSTOMER_FORM_ZIPCODE: 'ZIP/Postal Code',
  CUSTOMER_FORM_CLEARFILTERS_BTN: 'Clear Filters',
  CUSTOMER_FORM_ORDER_NUMBER: 'Order Number',
  CUSTOMER_FORM_REFERENCE_NUMBER: 'Reference Number',
  CUSTOMER_FORM_ORDER_STATUS: 'Status',
  CSR_FORM_DATE_RANGE: 'Date Range',
  CSR_FORM_DATE_START: 'Start Date',
  CSR_FORM_DATE_END: 'End Date',
  CSR_FORM_COMING_SOON_LABEL: 'Coming soon',
  CSR_FORM_BLANK_ERROR_TITLE: 'Missing Field(s)',
  CSR_FORM_BLANK_ERROR:
    'We are unable to complete your search request. At least one field must be populated to continue. Please try again.',
  CSR_FORM_DATE_RANGE_ERROR:
    'Date range field must be populated. Please try again.',
  CUSTOMER_FORM_SEARCH_BTN: 'Search',
  CUSTOMER_NO_RESULTS:
    'No results found for your search. Please modify your search selections and try again.',
  CUSTOMER_LARGE_RESULTS:
    'A large set of results has been returned. Consider refining your search results.',
  EMPTY_DATE_RANGE_MESSAGE: 'Please enter a valid date range.',
  GUEST_CUSTOMER: 'Guest Customer',
  INSTANT_ACCESS_TOAST_MESSAGE: 'This is an instant access account.',
  ORDER_FORM_CLEARFILTERS_BTN: 'Clear Filters',
  ORDER_FORM_SEARCH_BTN: 'Search',
  ORDER_DETAIL_DISCONNECT_BTN: 'Return to Order Search',
  POTENTIAL_ACCOUNT_ISSUE_MESSAGE:
    "This customer account either does not have an associated dealer or is pending for dealer's approval. Please proceed to transact as a guest.",
  POTENTIAL_ACCOUNT_ISSUE_TITLE: 'POTENTIAL ACCOUNT ISSUE',
  REORDER_DEALER_CANNOT_BE_CHANGED_SUBTITLE:
    'To order from a different dealer, please connect to customer or return to customer search.',
  REORDER_DEALER_CANNOT_BE_CHANGED_TITLE:
    'Dealer selection cannot be changed for reorders.',
  LOGGED_IN_AS: 'You are logged in as',
  DISCONNECT_BTN: 'Disconnect',
  DISCONNECT_FROM_ACCOUNT_BTN: 'Disconnect From Account',
  DISCONNECT_FROM_CART_BTN: 'Disconnect From Cart',
  CUSTOMER_SEARCH: 'Customer search',
  MANAGE_ACCOUNT: 'Manage Account',
  ORDER_SEARCH: 'Order search',
  ORDER_SEARCH_DISCLAIMER: 'Order Search is for guest session only.',
  GENERATE_LINK: 'GENERATE LINK',
  SHARE_CART_LINK: 'SHARE CART LINK',
  COPY_TO_CLIPBOARD: 'Copy to clipboard',
  NO_ACCOUNT_ASSOCIATED:
    'There is no registered account associated with this order.',
  REQUIRED_FIELDS: 'Required fields',
  REQUIRED_FIELDS_MESSAGE_ORDER_NUMBER:
    'Required fields (except when using ORDER NUMBER)',
  REQUIRED_FIELDS_MESSAGE_REFERENCE_NUMBER:
    'Required fields (except when using REFERENCE NUMBER)',
  RESTRICTIONS_APPLIED: 'Restrictions are applied',
  SEARCH_SUBTITLE: 'You may search for customers or orders.',
  REQUIRED_FIELDS_MESSAGE: 'Required fields (except when using ORDER NUMBER)',
  TRANSACTING_AS_GUEST: 'You are transacting as a guest customer',
  ADP_MODAL_ERROR_MESSAGE:
    "We're sorry, an unknown error has occurred. Please try again.",
  CONNECT_TO_CUSTOMER: 'Connect to customer',
  CSR_CHECKING_ORDER_BY_GUEST_MESSAGE:
    'No registered account associated with this order.',
  SUCCESS_INACTIVE_LOG_OUT: `You have been disconnected\nfrom the session due to\ninactivity.`,
  VERIFIED_ACCOUNT_MESSAGE: 'This is a verified account.',
  CSR_ACCOUNT_WITHOUT_TERMS_ACCEPTED:
    'This user has not accepted the Terms & Conditions for this dealer, therefore you cannot connect on their behalf.',
  NON_IA_DEALER_ERROR_MESSAGE:
    "We're sorry, you are not allowed to connect on behalf of this dealer. Please try another.",
  CSR_CLOSE: 'Close',
  MODAL_ORDER_TEXT: 'ORDER',
  CART_LISTS: 'Cart Lists'
};

export const CSR_SEARCH_TIMEOUT_ERROR = {
  title:
    'Due to technical issues, we are unable to complete your search query using your inputs.',
  message: `Please try again now using different search criteria for the following field(s): `
};

export const SEARCH_FIELDS = {
  firstName: CSR_TEXTS.CUSTOMER_FORM_FIRSTNAME,
  lastName: CSR_TEXTS.CUSTOMER_FORM_LASTNAME,
  email: CSR_TEXTS.CUSTOMER_FORM_EMAIL,
  documentNumber: CSR_TEXTS.CUSTOMER_FORM_ORDER_NUMBER,
  startDate: CSR_TEXTS.CSR_FORM_DATE_START,
  endDate: CSR_TEXTS.CSR_FORM_DATE_END,
  orderStatus: CSR_TEXTS.CUSTOMER_FORM_ORDER_STATUS,
  phone: CSR_TEXTS.CUSTOMER_FORM_PHONE,
  logonId: CSR_TEXTS.CUSTOMER_FORM_LOGON_ID,
  company: CSR_TEXTS.CUSTOMER_FORM_COMPANYNAME,
  country: CSR_TEXTS.CUSTOMER_FORM_COUNTRY
};

export const CSR_UNAUTHORIZED_ACTION_ERROR = {
  message: 'Please sign-in again to continue your request.',
  title:
    'Your session has timed out, and we are unable to complete your request.'
};

const CSR_DEALER_RESTRICTIONS = 'Dealer Restrictions:';
const CSR_ACCOUNT_RESTRICTIONS = 'Account Restrictions:';
const CSR_DEALER_LIMITED_ACCESS =
  'This dealer has limited CSR access to the following:';
const CSR_USERTYPE_LIMITED_ACCESS =
  'This user type has limited access to the following:';

const cspCustomerWithoutCheckout = [
  CSR_DEALER_RESTRICTIONS,
  CSR_DEALER_LIMITED_ACCESS,
  ['Buy on behalf']
];

const iaUserWithCheckout = [
  CSR_ACCOUNT_RESTRICTIONS,
  CSR_USERTYPE_LIMITED_ACCESS,
  ['Customer Specific Pricing and Promotions', 'Dealer Credit']
];

const iaUserWithoutCheckout = [
  [CSR_DEALER_RESTRICTIONS, CSR_DEALER_LIMITED_ACCESS, ['Buy on behalf']],
  [
    CSR_ACCOUNT_RESTRICTIONS,
    CSR_USERTYPE_LIMITED_ACCESS,
    ['Customer Specific Pricing and Promotions', 'Dealer Credit']
  ]
];

const guestUserWithoutCheckout = [
  CSR_DEALER_RESTRICTIONS,
  CSR_DEALER_LIMITED_ACCESS,
  ['Buy on behalf']
];

export const RESTRICTION_CASES = {
  cspCustomerWithoutCheckout,
  iaUserWithCheckout,
  iaUserWithoutCheckout,
  guestUserWithoutCheckout
};
