import PropTypes from 'prop-types';
import { CSR_TEXTS } from './../texts';
import { AlloyButton, SystemFeedback } from 'cat-ecommerce-alloy';

const CSRPotentialIssuesModal = ({ onCancel }) => {
  return (
    <div>
      <h2 className="h2 display-1">
        {CSR_TEXTS.POTENTIAL_ACCOUNT_ISSUE_TITLE}
      </h2>
      <div className="my-4">
        <SystemFeedback
          type="error"
          message={CSR_TEXTS.POTENTIAL_ACCOUNT_ISSUE_MESSAGE}
        />
      </div>
      <div className="d-flex justify-content-center">
        <AlloyButton buttonType="ghost" onClick={onCancel}>
          {CSR_TEXTS.BACK_TO_LIST}
        </AlloyButton>
      </div>
    </div>
  );
};

CSRPotentialIssuesModal.propTypes = {
  onCancel: PropTypes.func.isRequired
};

export default CSRPotentialIssuesModal;
