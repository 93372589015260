import * as types from './constants';
import { STATUS } from '../../constants/commonConstants';
import { myequipmentInitialStore } from './initialStore';

const equipmentReducer = (state = myequipmentInitialStore, action) => {
  switch (action.type) {
    case types.ME_BEGIN_LOADING:
      return {
        ...state,
        error: null,
        isLoading: true,
        equipmentSaveSuccessful: false,
        equipmentSaveMethod: null,
        equipmentSaveMessage: null
      };
    case types.ME_END_LOADING:
      return {
        ...state,
        error: null,
        isLoading: false
      };
    case types.ME_LOAD_EQUIPMENTDATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        initialLoading: false,
        equipments: action.equipments
      };
    case types.ME_LOAD_MCCRESPONSE_SUCCESS:
      return {
        ...state,
        mccResponse: true
      };
    case types.ME_LOAD_MCCRESPONSE_FAIL:
      /*
       * This is to facilitate the way the component was written.
       * It (MyEquipmentAssets.js) needs to be refactored.
       */
      return {
        ...state,
        isLoading: false,
        initialLoading: false,
        mccResponse: 'error'
      };
    case types.ME_SET_EQUIPMENT_FILTER_SUCCESS:
      return {
        ...state,
        ...action
      };

    case types.ME_LOAD_SELECTEDEQUIPMENT_SUCCESS:
    case types.ME_LOAD_DESELECTEDEQUIPMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fitmentCheckError: undefined,
        fitmentCheckStatus: STATUS.IDLE,
        equipments: {
          ...state.equipments,
          ...action
        }
      };
    case types.ME_DELETE_EQUIPMENTDATA_SUCCESS: {
      const selectedEquipmentMatchForRemove =
        state.equipments.selectedEquipment.unValidatedItemListId ===
        action.wishListItemId;
      const myEquipmentList = Array.from(
        state.equipments.myEquipmentList
      ).filter(e => action.wishListItemId !== e.unValidatedItemListId);

      const newEquipments = { ...state.equipments };
      if (selectedEquipmentMatchForRemove) {
        newEquipments.selectedEquipment = action.emptyEquipmentData;
      }
      return {
        ...state,
        isLoading: false,
        equipments: {
          ...newEquipments,
          myEquipmentList
        }
      };
    }
    case types.ME_UPDATE_EQUIPMENTDATA_SUCCESS: {
      const selectedEquipmentMatch =
        state.equipments.selectedEquipment.uniqueIndex ===
        action.currentEquipment.uniqueIndex;
      const items = state.equipments.myEquipmentList.filter(
        e => action.currentEquipment.uniqueIndex !== e.uniqueIndex
      );
      const newEquipments = {
        ...state.equipments
      };
      const updatedSelectedEquipment = {
        ...action.currentEquipment,
        isQRCodeTempEquipment: false
      };
      if (selectedEquipmentMatch) {
        newEquipments.selectedEquipment = updatedSelectedEquipment;
      }
      newEquipments.myEquipmentList = items.concat(updatedSelectedEquipment);

      return {
        ...state,
        isLoading: false,
        equipments: newEquipments
      };
    }
    case types.ME_ADD_EQUIPMENTDATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        equipments: {
          ...state.equipments,
          myEquipmentList: state.equipments.myEquipmentList.concat(
            action.currentEquipment
          )
        }
      };
    }
    // This should be moved to the common error reducer once this reconnects with checkout
    case types.ME_SET_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.ME_CLEAR_ERROR:
      return {
        ...state,
        error: null
      };
    case types.ME_IS_SAVING_EQUIPMENT:
      return {
        ...state,
        isSavingEquipment: true
      };
    case types.ME_SET_EQUIPMENT_SAVE_SUCCESS:
      return {
        ...state,
        equipmentSaveSuccessful: action.payload
      };

    case types.ME_CHECK_FITMENT_SUCCESS: {
      const serialKey = !!action?.payload?.namespace
        ? `${action.payload.namespace}SerialMatchPartNumbers`
        : 'serialMatchPartNumbers';
      const rangeKey = !!action?.payload?.namespace
        ? `${action.payload.namespace}RangeMatchPartNumbers`
        : 'rangeMatchPartNumbers';
      const newSerialMatchPartNumbers =
        action?.payload?.fitmentResult?.serialMatchPartNumbers || [];
      const newRangeMatchPartNumbers =
        action?.payload?.fitmentResult?.rangeMatchPartNumbers || [];
      return {
        ...state,
        equipments: {
          ...state.equipments,
          selectedEquipment: {
            ...state.equipments.selectedEquipment,
            [serialKey]: new Set([
              ...newSerialMatchPartNumbers,
              ...(state.equipments?.selectedEquipment?.[serialKey] ?? [])
            ]),
            [rangeKey]: new Set([
              ...newRangeMatchPartNumbers,
              ...(state.equipments?.selectedEquipment?.[rangeKey] ?? [])
            ])
          }
        }
      };
    }
    case types.ME_CHECK_FITMENT_FAIL: {
      return {
        ...state,
        [action?.payload?.loadingNamespace]: STATUS.REJECTED,
        fitmentCheckError: action?.payload?.error
      };
    }
    case types.ME_SERIAL_NUM_VALIDATION_BEGIN: {
      return {
        ...state,
        serialNumValidationStatus: STATUS.PENDING
      };
    }
    case types.ME_SERIAL_NUM_VALIDATION_FAIL: {
      return {
        ...state,
        serialNumValidationStatus: STATUS.REJECTED,
        serialNumberError: action.payload
      };
    }
    case types.SERIAL_NUM_VALIDATION_FAILED: {
      return {
        ...state,
        serialNumberError: action.payload
      };
    }
    case types.ME_SERIAL_NUM_VALIDATION_SUCCESS: {
      return {
        ...state,
        serialNumValidationStatus: STATUS.RESOLVED,
        serialNumberError: ''
      };
    }
    case types.ME_SERIAL_NUM_REMOVE_VALIDATION: {
      return {
        ...state,
        serialNumValidationStatus: STATUS.IDLE,
        serialNumberError: ''
      };
    }
    case types.ME_AUTO_SAVE_BEGIN:
      return {
        ...state,
        autoSaveStatus: STATUS.PENDING
      };
    case types.ME_AUTO_SAVE_SUCCESS:
      return {
        ...state,
        autoSaveStatus: STATUS.RESOLVED
      };
    case types.ME_AUTO_SAVE_FAIL:
      return {
        ...state,
        autoSaveStatus: STATUS.REJECTED
      };
    case types.ME_SELECT_EQP_SAVE_BEGIN:
      return {
        ...state,
        selectEquipmentStatus: STATUS.PENDING,
        selectEquipmentError: null
      };
    case types.ME_SELECT_EQP_SAVE_SUCCESS:
      return {
        ...state,
        selectEquipmentStatus: STATUS.RESOLVED
      };
    case types.ME_SELECT_EQP_SAVE_FAIL:
      return {
        ...state,
        selectEquipmentStatus: STATUS.REJECTED,
        selectEquipmentError: action.payload
      };
    case types.ME_SAVE_QR_SCANNED_EQP_BEGIN:
      return {
        ...state,
        qrScannedEqpSaveStatus: STATUS.PENDING
      };
    case types.ME_SAVE_QR_SCANNED_EQP_SUCCESS:
      return {
        ...state,
        qrScannedEqpSaveStatus: STATUS.RESOLVED
      };
    case types.ME_SAVE_QR_SCANNED_EQP_FAIL:
      return {
        ...state,
        qrScannedEqpSaveStatus: STATUS.REJECTED
      };
    case types.ME_OMM_CATALOG_BEGIN:
      return {
        ...state,
        ommCatalogStatus: STATUS.PENDING
      };
    case types.ME_OMM_CATALOG_SUCCESS:
      return {
        ...state,
        ommCatalogStatus: STATUS.RESOLVED,
        ommCatalog: action.payload
      };
    case types.ME_OMM_CATALOG_FAIL:
      return {
        ...state,
        ommCatalogStatus: STATUS.REJECTED,
        ommError: action.payload,
        ommCatalog: []
      };
    case types.PDP_GET_FITMENT_SET_STATUS:
      return {
        ...state,
        equipmentFitmentStatus: action.payload
      };
    case types.ME_OMM_CONTENT_BEGIN:
      return {
        ...state,
        ommContentStatus: STATUS.PENDING
      };
    case types.ME_OMM_CONTENT_SUCCESS:
      return {
        ...state,
        ommContentStatus: STATUS.RESOLVED,
        ommContent: action.payload
      };
    case types.ME_OMM_CONTENT_FAIL:
      return {
        ...state,
        ommContentStatus: STATUS.REJECTED,
        ommContentError: action.payload,
        ommContent: []
      };
    case types.MAINTENANCE_VIDEOS_BEGIN:
      return {
        ...state,
        maintenanceVideosStatus: STATUS.PENDING,
        maintenanceVideos: []
      };
    case types.MAINTENANCE_VIDEOS_SUCCESS:
      return {
        ...state,
        maintenanceVideosStatus: STATUS.RESOLVED,
        maintenanceVideos: action.payload
      };
    case types.MAINTENANCE_VIDEOS_FAIL:
      return {
        ...state,
        maintenanceVideosStatus: STATUS.REJECTED,
        maintenanceVideosError: action.payload,
        maintenanceVideos: []
      };
    case types.ME_TOAST:
      return {
        ...state,
        equipmentSaveMethod: action.payload?.method ?? '',
        equipmentSaveMessage: action.payload?.message ?? ''
      };
    case types.EQP_SEL_DESEL_TOAST:
      return {
        ...state,
        equipmentSelectionStatus: action.payload
      };
    case types.GET_SERVICE_RECOMMENDATION_BEGIN:
      return {
        ...state,
        serviceRecommendationCards: {
          status: STATUS.PENDING,
          data: {}
        }
      };
    case types.GET_SERVICE_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        serviceRecommendationCards: {
          status: STATUS.RESOLVED,
          data: action.payload
        }
      };
    case types.GET_SERVICE_RECOMMENDATION_FAIL:
      return {
        ...state,
        serviceRecommendationCards: {
          status: STATUS.REJECTED,
          data: {}
        }
      };
    case types.SERVICE_RECOMMENDATION_PRICE_BEGIN:
      return {
        ...state,
        serviceRecommendationPrice: {
          status: STATUS.PENDING,
          data: {}
        }
      };

    case types.SERVICE_RECOMMENDATION_PRICE_SUCCESS:
      return {
        ...state,
        serviceRecommendationPrice: {
          status: STATUS.RESOLVED,
          data: action.payload
        },
        serviceRecommendationDetails: {
          ...state.serviceRecommendationDetails,
          isLoading: false
        }
      };

    case types.SERVICE_RECOMMENDATION_PRICE_FAIL:
      return {
        ...state,
        serviceRecommendationPrice: {
          status: STATUS.REJECTED,
          data: {}
        },
        serviceRecommendationDetails: {
          ...state.serviceRecommendationDetails,
          isLoading: false
        }
      };
    case types.SERVICE_RECOMMENDATION_SUBTOTAL_BEGIN:
      return {
        ...state,
        serviceRecommendationSubTotal: {
          status: STATUS.PENDING,
          data: {}
        }
      };
    case types.SERVICE_RECOMMENDATION_SUBTOTAL_SUCCESS:
      return {
        ...state,
        serviceRecommendationSubTotal: {
          status: STATUS.RESOLVED,
          data: action.payload
        },
        serviceRecommendationDetails: {
          ...state.serviceRecommendationDetails,
          isLoading: false
        }
      };
    case types.SERVICE_RECOMMENDATION_SUBTOTAL_FAIL:
      return {
        ...state,
        serviceRecommendationSubTotal: {
          status: STATUS.REJECTED,
          data: {}
        },
        serviceRecommendationDetails: {
          ...state.serviceRecommendationDetails,
          isLoading: false
        }
      };
    case types.GET_COMMERCIAL_OFFER_DETAILS_BEGIN:
      return {
        ...state,
        serviceRecommendationDetails: {
          status: STATUS.PENDING,
          data: {},
          isLoading: true
        },
        serviceRecommendationPrice: {
          status: STATUS.PENDING,
          data: {}
        },
        serviceRecommendationSubTotal: {
          status: STATUS.PENDING,
          data: {}
        }
      };
    case types.GET_COMMERCIAL_OFFER_DETAILS_SUCCESS:
      return {
        ...state,
        serviceRecommendationDetails: {
          ...state.serviceRecommendationDetails,
          status: STATUS.RESOLVED,
          data: action.payload
        }
      };
    case types.GET_COMMERCIAL_OFFER_DETAILS_FAIL:
      return {
        ...state,
        serviceRecommendationDetails: {
          status: STATUS.REJECTED,
          data: {},
          isLoading: false
        }
      };
    case types.LOAD_CVA_DETAILS_BEGIN: {
      return {
        ...state,
        cvaDetails: {
          data: [],
          isLoading: true,
          status: STATUS.PENDING
        }
      };
    }
    case types.LOAD_CVA_DETAILS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        cvaDetails: {
          data: payload,
          isLoading: false,
          status: STATUS.RESOLVED
        }
      };
    }
    case types.LOAD_CVA_DETAILS_FAIL: {
      const { payload } = action;
      return {
        ...state,
        cvaDetails: {
          data: [],
          isLoading: false,
          status: STATUS.REJECTED,
          error: payload
        }
      };
    }
    default:
      return state;
  }
};
export { myequipmentInitialStore };
export default equipmentReducer;
