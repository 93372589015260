/**
 * Help to prevent the default behavior of links, allowing only left clicks and skipping external targets or modifiers keys
 * @param {MouseEvent} evt Click event to be prevented
 * @returns {Boolean} a boolean to know if the event was prevented or not
 */
// Code Snippet extracted and modified from https://github.com/remix-run/react-router/blob/v5.3.3/packages/react-router-dom/modules/Link.js#L44
export const preventDefaultLinkEvent = evt => {
  // onClick already default prevented
  if (!evt || evt.defaultPrevented) {
    return true;
  }

  if (
    evt.button === 0 && // ignore everything but left clicks
    (!evt.target?.target || evt.target.target === '_self') && // let browser handle "target=_blank" etc.
    !(evt.metaKey || evt.altKey || evt.ctrlKey || evt.shiftKey) // ignore clicks with modifier keys
  ) {
    evt.preventDefault();
    evt.stopPropagation();
    return true;
  }

  return false;
};

/**
 * Returns focus to the Nav Item with the id provided when using shadow dom.
 * @param {querySelector} string
 */
export const returnFocusToNavItem = querySelector => {
  const navItem = document.querySelector(querySelector);

  /*
   * Next line is a hack to avoid having the user pressing
   * TAB twice to move from the UtilityNavItem to the next
   * After closing the drawer.
   */
  if (navItem) {
    navItem.isActive = false;
  }

  const shadowRoot = navItem?.shadowRoot;
  const button = shadowRoot?.querySelector?.('button');

  /*
   * This setTimeout is needed to circumvent a delay added by blocks inside the
   * Drawer's source code that return focus to the drawer after 100ms.
   * See code here:https://github.com/cat-digital-platform/A-Blocks/blob/b50de828a0c44312da7f8344bde4780515e806f3/libs/blocks-v3.0/blocks-web-components/components/drawer/drawer.ts#L246C3-L250C8
   * This only happens when user use keyboard to focus on the X close button
   * inside the drawer and hit ENTER
   * */
  if (button) {
    setTimeout(() => button.focus(), 125);
  }
};
