import {
  CATCORP_STORE,
  CAT_MY_EQUIPMENT_REDIRECT,
  CAT_SAVED_LIST_REDIRECT,
  CAT_SIS_REDIRECT
} from '@app/constants/commonConstants';
import { navigateToUrl } from '@app/services/windowService';
import {
  getCookie,
  removeCookie,
  setCookie,
  replaceTokensInUrl
} from '@app/utils';
import {
  ACCT_PR_NEWFLOW_SHOWNFLAG,
  CAT_PREFS_CHECK,
  CAT_URI_FRAGMENT,
  DEALER_SYSTEM_DOWN,
  DEEPLINK_FLOW,
  HEADER_DATA,
  GUEST_SIS_HIT,
  GUEST_SAVED_LIST_HIT,
  GUEST_MY_EQUIPMENT
} from './redirect/constants';

const removeRedirectionCookies = () => {
  const cookiesToRemove = [
    GUEST_SIS_HIT,
    GUEST_SAVED_LIST_HIT,
    GUEST_MY_EQUIPMENT
  ];
  for (
    let cookieIndex = 0;
    cookieIndex < cookiesToRemove.length;
    cookieIndex++
  ) {
    removeCookie(cookiesToRemove[cookieIndex]);
  }
};

const getCookieName = murl => {
  const sisRedirect = CAT_SIS_REDIRECT === murl;
  const savedListRedirect = CAT_SAVED_LIST_REDIRECT === murl;
  const myEquipmentRedirect = CAT_MY_EQUIPMENT_REDIRECT === murl;
  if (sisRedirect) {
    return GUEST_SIS_HIT;
  } else if (savedListRedirect) {
    return GUEST_SAVED_LIST_HIT;
  } else if (myEquipmentRedirect) {
    return GUEST_MY_EQUIPMENT;
  }
};

const updateRedirectionCookie = murl => {
  removeRedirectionCookies();
  const redirectCookieToSet = getCookieName(murl);
  if (redirectCookieToSet) {
    setCookie(getCookieName(murl), 'true', null, '/');
  }
};

export const setHelperCookies = murl => {
  updateRedirectionCookie(murl);
};

export const findIndex = x => {
  return window.location.href.indexOf(x) === -1;
};
export function indexOfAll(array) {
  return array.every(findIndex);
}

export const checkForPref = () => {
  const dealerSystemDown = getCookie(DEALER_SYSTEM_DOWN);
  const headerDataCookie = getCookie(HEADER_DATA);
  const catPrefsCheck = getCookie(CAT_PREFS_CHECK);
  if (
    !headerDataCookie &&
    !dealerSystemDown &&
    !catPrefsCheck &&
    !sessionStorage.getItem(ACCT_PR_NEWFLOW_SHOWNFLAG) &&
    !sessionStorage.getItem(DEEPLINK_FLOW)
  ) {
    return true;
  }
  return false;
};
export const resetHeaderData = storeId => {
  if (storeId === CATCORP_STORE) {
    removeCookie(HEADER_DATA);
  }
};
/* this cookie is used to maintain facet and sorting in search and plp page*/
export function createFragmentCookie() {
  if (!!window?.location?.hash) {
    setCookie(CAT_URI_FRAGMENT, window.location.hash, null, '/');
  }
}

const showManualApprovalMessage = (approvalUrl, homePageURL) => {
  return navigateToUrl(!!homePageURL ? homePageURL : approvalUrl);
};

export const redirectBypassPage = (response, showADP, links) => {
  const {
    dealerLangId,
    dealerStoreId,
    homePageURL,
    dealerCatalogId,
    bypassRedirectURL,
    noUCID,
    langId,
    bypassDAPage,
    redirectToFindYourDealer,
    fromSSO
  } = response;

  const { MANUAL_APPROVAL_LINK } = links;
  const approvalUrl = replaceTokensInUrl(
    MANUAL_APPROVAL_LINK,
    dealerCatalogId,
    dealerLangId,
    dealerStoreId,
    langId
  );
  if (bypassDAPage && !!bypassRedirectURL && !fromSSO) {
    let bypassURL = bypassRedirectURL;
    bypassURL = bypassURL.replace(new RegExp('amp;', 'g'), '');
    navigateToUrl(bypassURL);
  } else {
    if (redirectToFindYourDealer) {
      removeCookie(DEALER_SYSTEM_DOWN);
      showADP();
    } else {
      noUCID ? showManualApprovalMessage(approvalUrl, homePageURL) : showADP();
    }
  }
};

//do we need this ? - Will create tech debt to remove
export const showProcessErrorMessage = () => {
  // eslint-disable-next-line no-undef
  storeLocatorJS.showStoreLocatorModal('', 'dealerError');
  // eslint-disable-next-line no-undef
  storeLocatorJS.setDealerInfoForErrorMessage();
};
