import PropTypes from 'prop-types';
import {
  MyEquipmentHeader,
  MyEquipmentLinkContent,
  MyEquipmentDrawerLinkImage,
  MyEquipmentDrawer
} from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import { MODEL_LOCATION } from '../../constant';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import cx from 'classnames';
import styles from './AddEquipmentFindModelDrawer.module.scss';

const AddEquipmentFindModelDrawer = ({
  drawerModelLink,
  showBackButton,
  isChild = false
}) => {
  const [t] = useTranslation();
  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);
  const onCloseFindModel = e => {
    e.stopPropagation();
    drawerModelLink.drawerProps.onBlDrawerClose(e);
  };
  const onBackButtonClickOnModel = () => {
    drawerModelLink.handleClose();
  };

  return (
    <MyEquipmentDrawer
      drawer={drawerModelLink}
      onCloseHandler={onCloseFindModel}
      size="lg"
      isChild={isChild}
    >
      <MyEquipmentHeader
        className="d-flex"
        variant={'title-sm'}
        onBackButtonClick={!showBackButton ? onBackButtonClickOnModel : ''}
      >
        {t('FIND_MODEL_GUIDE_TEXT')}
      </MyEquipmentHeader>
      <MyEquipmentLinkContent classname={styles['grid']}>
        <MyEquipmentHeader variant={isMD ? 'title' : 'headline'}>
          {t('ADD_EQUIP_FIND_MODEL')}
        </MyEquipmentHeader>
        <MyEquipmentDrawerLinkImage
          src={MODEL_LOCATION.src}
          alt={MODEL_LOCATION.alt}
          classname={cx(styles['image'], 'mt-md-n4 mt-1 pt-3 pt-md-0')}
        />
        <MyEquipmentHeader className="pt-3 mt-1 mt-md-0" variant="body-sm">
          {t('MY_EQUIP_FIND_SERIAL_TEXT1')}
        </MyEquipmentHeader>
      </MyEquipmentLinkContent>
    </MyEquipmentDrawer>
  );
};

AddEquipmentFindModelDrawer.propTypes = {
  drawerModelLink: PropTypes.shape({
    handleClose: PropTypes.func,
    drawerProps: PropTypes.shape({
      onBlDrawerClose: PropTypes.func
    })
  }),
  showBackButton: PropTypes.bool,
  isChild: PropTypes.bool
};

export default AddEquipmentFindModelDrawer;
