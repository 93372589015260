import { STATUS } from '../../constants/commonConstants';

export const productsInitialStore = {
  byId: {},
  getProductUrlTokenStatus: STATUS.IDLE,
  getProductDetailsStatus: STATUS.IDLE,
  getHomepageProductRecentlyViewedStatus: STATUS.IDLE,
  getHomepageProductBestSellersStatus: STATUS.IDLE,
  getHomepageProductFeaturedStatus: STATUS.IDLE,
  getJobsRecommendationsStatus: STATUS.IDLE,
  priceAndAvailability: {}
};
