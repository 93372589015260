import * as types from './constants';

export const getEctMessages =
  (errorResponse, messageType) => async dispatch => {
    dispatch({
      type:
        messageType === 'ERP_OUTAGE_NOTIFICATION'
          ? types.ERP_MESSAGE_LOAD_FAIL
          : types.ECT_MESSAGE_LOAD_FAIL,
      payload: errorResponse
    });
  };
