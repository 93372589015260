import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import {
  AlloyButton,
  ComponentLoader,
  InlineLoader,
  Label,
  ModalContext
} from 'cat-ecommerce-alloy';
import { getCookie, isEmpty, replaceTokensInString } from '../../../../utils';
import {
  STATUS,
  USER_TYPE_GUEST,
  PROD_URL
} from '../../../../constants/commonConstants';
import useGetStores from '../../hooks/useGetStores';
import useSelectDealer from '../../hooks/useSelectDealer';
import { getFormDisplayInfo } from './utils';
import AssociatedDealerFormEffects from './AssociatedDealerFormEffects';
import DealerDropdown from './DealerDropdown';
import SkipThisStep from './SkipThisStep';
import AddDealerButton from './AddDealerButton';
import styles from './AssociatedDealerForm.scss';
import ExceptionFeedback from '../../../common/Exception/ExceptionFeedback';
import { FEEDBACK_TYPES } from '../../../../constants/errorConstants';
import ClearSearchButton from './ClearSearchButton';
import AssociatedDealerFormExceptionFeedback from './AssociatedDealerFormExceptionFeedback';
import useSavedLists from '../../../saved-lists/useSavedLists';
import LegalAcceptance from './LegalAcceptance';

import {
  dealerAssociationsPropTypes,
  storesLocationPropTypes
} from '../../declarations.propTypes';
import CustomerNumberDropdown from '@app/components/common/orderInformationFields/CustomerNumberDropdown';
import StoreLocationDropdown from '@app/components/common/orderInformationFields/StoreLocationDropdown';
import EndUseCodeDropdown from '@app/components/common/orderInformationFields/EndUseCodeDropdown';
import OrderType from '@app/components/common/orderInformationFields/OrderType';
import Conditional from '@app/components/common/Conditional';
import { useContext, useState } from 'react';
import LogoutButton from '@app/components/NavigationBarUtilitySection/LogoutButton';
import { CSR_TEXTS } from '@app/components/pages/csr-landing/texts';
import { useQueryParams } from '@app/hooks';
import useOrderInfo from '../../hooks/useOrderInfo';
import { toggleADPmodalOpenStatus } from '@app/store/common/actions';
import AssociatedDealerFormPrepopulateCartShare from './AssociatedDealerFormPrepopulateCartShare';
import { getSessionStorageCartSharingData } from '@app/components/pages/checkout/shopping-cart/ShoppingCartPage/utils';
import {
  CSR_SHARE_CART_LINK_STOREID_PARAM,
  CSR_SHARE_CART_LINK_STORE_LOCATION_ID_PARAM
} from '@app/components/pages/checkout/shopping-cart/OrderItems/constants';

const AssociatedDealerForm = ({
  adpSubmitPromise,
  bobRedirect,
  bobUserId,
  isDealerUser,
  isLoginAction,
  isRequired,
  preSelectedDealer,
  preSelectedStores,
  removeResultsModal,
  reorderFunc,
  resetDealerSearchForm,
  showSkipThisStep,
  showWarning
}) => {
  const [t] = useTranslation();
  const { setFinishedFlowADP } = useSavedLists();
  const dispatch = useDispatch();
  const [okTermsAndConditions, setOkTermsAndConditions] = useState(false);
  const { removeCurrentModal } = useContext(ModalContext);
  const dealerAssociations =
    useSelector(s => s.dealerAssociation.dealerAssociations) || [];
  const dealerAssociationStatus = useSelector(s => s.dealerAssociation.status);
  const dealerSystemDownCookie =
    !isEmpty(getCookie('dealerSystemDown')) &&
    getCookie('dealerSystemDown') === '1';
  const getDealerAssociationLoading =
    dealerAssociationStatus === STATUS.PENDING;
  const dealerAssociationCallError =
    dealerAssociationStatus === STATUS.REJECTED;
  let resolvedDealerAssociations = preSelectedDealer || dealerAssociations;
  const CSR_SHARE_CART_DATA = getSessionStorageCartSharingData();
  const findDealerFromShareCartLink =
    CSR_SHARE_CART_DATA &&
    CSR_SHARE_CART_DATA[CSR_SHARE_CART_LINK_STOREID_PARAM]
      ? resolvedDealerAssociations.find(
          dealerD =>
            dealerD.value ===
            CSR_SHARE_CART_DATA[CSR_SHARE_CART_LINK_STOREID_PARAM]
        )
      : null;

  let defaultDealer = findDealerFromShareCartLink
    ? findDealerFromShareCartLink
    : resolvedDealerAssociations.find(da => da.default);
  const userType = useSelector(s => s?.common.userType);
  const isLoggedIn = userType !== USER_TYPE_GUEST;
  const isCSR = useSelector(s => s.common.isCatCSR) || false;
  const storeId = useSelector(s => s.common?.storeId);
  const orderId = useQueryParams().get('orderId') ?? false;

  const isReOderFlow =
    reorderFunc !== undefined && isCSR && orderId && storeId !== undefined;
  const isAddDealerButtonVisible = !isDealerUser && !isReOderFlow;
  let accountWithoutTermsAccepted =
    CSR_TEXTS.CSR_ACCOUNT_WITHOUT_TERMS_ACCEPTED;
  let dealerSelectionTitle = t('CAT_PRICE_MSG');
  let dealerSelectionSubtitle = t('CAT_PROMO_MSG');
  const { dealerInfo, isOrderDealerTCAccepted } = useOrderInfo(orderId);
  let showContinueButton = true;
  if (isReOderFlow) {
    const uniqueDealer = dealerInfo;
    resolvedDealerAssociations = uniqueDealer ? [uniqueDealer] : [];
    defaultDealer = uniqueDealer;
    dealerSelectionTitle = CSR_TEXTS.REORDER_DEALER_CANNOT_BE_CHANGED_TITLE;
    dealerSelectionSubtitle =
      CSR_TEXTS.REORDER_DEALER_CANNOT_BE_CHANGED_SUBTITLE;
    showWarning = dealerAssociations.length > 1 && isOrderDealerTCAccepted;
    showContinueButton = isCSR || isOrderDealerTCAccepted;

    if (!isOrderDealerTCAccepted) {
      accountWithoutTermsAccepted = accountWithoutTermsAccepted.replace(
        'connect',
        'reorder'
      );
    }
  }

  const defaultCustomerNumber =
    defaultDealer && defaultDealer.customerNumber.length > 0
      ? defaultDealer.customerNumber.find(cn => cn.default) ||
        findDealerFromShareCartLink.customerNumber[0]
      : undefined;

  const defaultStoreLocation =
    CSR_SHARE_CART_DATA &&
    CSR_SHARE_CART_DATA[CSR_SHARE_CART_LINK_STORE_LOCATION_ID_PARAM] &&
    defaultDealer?.value === findDealerFromShareCartLink?.value
      ? CSR_SHARE_CART_DATA[CSR_SHARE_CART_LINK_STORE_LOCATION_ID_PARAM]
      : '';

  const isTermsConditionAccepted = !!defaultDealer?.isTermsConditionAccepted;

  const showDealerDropDown =
    resolvedDealerAssociations.length > 0 &&
    defaultDealer &&
    defaultCustomerNumber;

  const {
    error: selectDealerError,
    invoke: callSelectDealer,
    loading: selectDealerLoading
  } = useSelectDealer();
  let initialValues = {
    customerNumber: defaultCustomerNumber,
    dealer: defaultDealer,
    endUseCode: '',
    orderType: '',
    storeLocation: defaultStoreLocation,
    legalAcceptance: isTermsConditionAccepted
  };

  const onSubmit = async values => {
    const {
      dealer = {},
      customerNumber = {},
      endUseCode = {},
      orderType,
      skipThisStep,
      storeLocation = {}
    } = values;

    // When isDealerUser is true, the label we show to the user is  userName/userNumber
    // in that flow we receive an additional field "name" with only the name of the user
    const formattedValues = {
      dealer: dealer.value ?? null,
      dcnNumberName: isDealerUser
        ? `${customerNumber.value?.trim()}-${customerNumber.name?.trim()}`
        : customerNumber.label ?? null,
      dcnNumberValue: customerNumber.value ?? null,
      endUseCode: endUseCode.value ?? null,
      isLoginAction,
      orderType: orderType ?? null,
      skipThisStep,
      storeLocationId: storeLocation.value ?? null
    };

    if (adpSubmitPromise) {
      await adpSubmitPromise();
    }

    callSelectDealer(
      formattedValues,
      bobRedirect,
      adpSubmitPromise,
      bobUserId,
      reorderFunc
    );
    setFinishedFlowADP();
    dispatch(toggleADPmodalOpenStatus(false));
  };

  const {
    data: dealerCustomerServiceData,
    error: dealerCustomerServiceError,
    invoke: callDealerCustomerService,
    loading: dealerCustomerLoading
  } = useGetStores();

  const storeLocations =
    preSelectedStores ||
    (Array.isArray(dealerCustomerServiceData?.store)
      ? dealerCustomerServiceData.store
      : []);

  const hasError = !!(
    dealerCustomerServiceError ||
    selectDealerError ||
    dealerAssociationCallError ||
    dealerSystemDownCookie
  );
  const ASSOCIATED_DEALER_ID_PREFIX = 'associated-dealer';
  const customerNumberLabelText = isDealerUser
    ? t('CUST_NAME_NUMBER_COMBINED_SPACE')
    : t('CAT_SHOPPING_PREFERENCES_CUST_NUM');

  const footerButtonStyles = cx([
    'd-flex',
    'flex-row',
    isReOderFlow
      ? 'justify-content-center align-items-center'
      : 'justify-content-end'
  ]);

  // Added tabIndex to loader to prevent a crash due to
  // FocusTrap component wrapping Alloy Modal.

  return getDealerAssociationLoading ? (
    <ComponentLoader contentClassName="mt-4" tabIndex={0} />
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {({ values }) => {
        const {
          customerNumbers,
          endUseCodes,
          isCSPCustomer,
          orderTypes,
          showCustomerNumberDropdown,
          showEndUseCodeDropdown,
          showOrderTypes,
          showStoreLocationDropDown
        } = getFormDisplayInfo({
          isLoggedIn,
          storeLocations,
          values
        });
        return (
          <Form>
            {selectDealerLoading && <ComponentLoader />}
            {showWarning && !hasError && (
              <ExceptionFeedback
                className="mb-3"
                preventScroll
                title={dealerSelectionTitle}
                type={FEEDBACK_TYPES.WARNING}
              >
                <p className="m-0">{dealerSelectionSubtitle}</p>
              </ExceptionFeedback>
            )}
            <Conditional test={isReOderFlow && !isOrderDealerTCAccepted}>
              <ExceptionFeedback
                className="mb-3"
                preventScroll
                type={FEEDBACK_TYPES.WARNING}
              >
                <p>{accountWithoutTermsAccepted}</p>
              </ExceptionFeedback>
            </Conditional>
            <Conditional
              test={
                !selectDealerLoading &&
                !!showDealerDropDown &&
                resolvedDealerAssociations.length > 0 &&
                findDealerFromShareCartLink
              }
            >
              <AssociatedDealerFormPrepopulateCartShare
                dealers={resolvedDealerAssociations}
                storeLocations={storeLocations}
              />
            </Conditional>
            {!preSelectedStores && (
              <AssociatedDealerFormEffects
                callDealerCustomerService={callDealerCustomerService}
                hasError={hasError}
                isCSPCustomer={isCSPCustomer}
                setOkTermsAndConditions={setOkTermsAndConditions}
              />
            )}
            <AssociatedDealerFormExceptionFeedback
              dealerAssociationCallError={dealerAssociationCallError}
              dealerCustomerServiceCallError={!!dealerCustomerServiceError}
              dealerSystemDownCookie={dealerSystemDownCookie}
              hasError={hasError}
              isRequired={isRequired}
            />
            {!isEmpty(getCookie('DSF200Session')) && (
              <div className={`${styles['ADP__PSW--label']} mb-4`}>
                {replaceTokensInString(t('WELCOME_PSW_ADP'), PROD_URL.name)}
              </div>
            )}

            {(isRequired && hasError) || dealerAssociationCallError ? null : (
              <>
                <Conditional test={!!showDealerDropDown}>
                  <DealerDropdown
                    dealerAssociations={resolvedDealerAssociations}
                    defaultCustomerNumber={defaultCustomerNumber}
                    defaultDealer={defaultDealer}
                    isRequired={!isReOderFlow}
                  />
                </Conditional>
                <Conditional test={isAddDealerButtonVisible}>
                  <AddDealerButton
                    adpSubmitPromise={adpSubmitPromise}
                    bobRedirect={bobRedirect}
                    bobUserId={bobUserId}
                    reorderFunc={reorderFunc}
                  />
                </Conditional>
                {showCustomerNumberDropdown && (
                  <CustomerNumberDropdown
                    idPrefix={ASSOCIATED_DEALER_ID_PREFIX}
                    customerNumbers={customerNumbers}
                    labelText={customerNumberLabelText}
                  />
                )}
                {dealerCustomerLoading && !preSelectedStores ? (
                  <>
                    <div>
                      <Label
                        className={`${styles['alloy_dropdown__label']}`}
                        showRequired
                      >
                        {t('MO_STORE_LOCATION')}
                      </Label>
                    </div>
                    <InlineLoader
                      className={cx(
                        'd-flex',
                        'align-items-center',
                        styles['dropDownHeight']
                      )}
                    />
                  </>
                ) : (
                  <>
                    {(showStoreLocationDropDown ||
                      dealerCustomerServiceError ||
                      (!showStoreLocationDropDown &&
                        showCustomerNumberDropdown &&
                        !isEmpty(values?.customerNumber))) && (
                      <StoreLocationDropdown
                        idPrefix={ASSOCIATED_DEALER_ID_PREFIX}
                        error={
                          !!dealerCustomerServiceError ||
                          !showStoreLocationDropDown
                        }
                        errorText={replaceTokensInString(
                          t('WELCOME_MESSAGE9'),
                          values?.dealer?.label
                        )}
                        labelText={t('MO_STORE_LOCATION')}
                        storeLocations={storeLocations}
                      />
                    )}
                    {showOrderTypes && (
                      <OrderType
                        idPrefix={ASSOCIATED_DEALER_ID_PREFIX}
                        orderTypes={orderTypes}
                      />
                    )}
                    {showEndUseCodeDropdown && (
                      <EndUseCodeDropdown endUseCodes={endUseCodes} />
                    )}
                  </>
                )}
                <Conditional
                  test={!values.dealer?.isTermsConditionAccepted && !isCSR}
                >
                  <LegalAcceptance />
                </Conditional>
                <p className="mb-4 text-xs color-dark-gray text-start">
                  {t('CAT_PARTS_MANUALS_REQ_FIELDS')}
                </p>
                <>
                  <div
                    className={
                      'd-flex flex-column justify-content-center align-items-center mb-4'
                    }
                  >
                    <Conditional test={showContinueButton}>
                      <AlloyButton
                        buttonType="secondary"
                        className="col-12 col-lg-auto"
                        size="large"
                        type="submit"
                        disabled={
                          (!isCSR && !values.legalAcceptance) ||
                          !showStoreLocationDropDown
                        }
                      >
                        {t('EF_CONTINUE')}
                      </AlloyButton>
                    </Conditional>
                    <Conditional test={isDealerUser}>
                      <ClearSearchButton
                        removeResultsModal={removeResultsModal}
                        resetDealerSearchForm={resetDealerSearchForm}
                      />
                    </Conditional>
                  </div>

                  <Conditional test={isReOderFlow}>
                    <div
                      className={footerButtonStyles}
                      style={{ gap: '0.75rem' }}
                    >
                      {!isReOderFlow && (
                        <LogoutButton buttonType="secondary" isCSR={isCSR}>
                          {t('1631_CONTINUE_AS_GUEST')}
                        </LogoutButton>
                      )}
                      {(!okTermsAndConditions || !isOrderDealerTCAccepted) && (
                        <AlloyButton
                          buttonType={isReOderFlow ? 'ghost' : 'primary'}
                          onClick={() => removeCurrentModal()}
                        >
                          {t('BACK')}
                        </AlloyButton>
                      )}
                    </div>
                  </Conditional>
                </>

                {!isDealerUser && showSkipThisStep && <SkipThisStep />}
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

AssociatedDealerForm.propTypes = {
  adpSubmitPromise: PropTypes.func,
  bobRedirect: PropTypes.string,
  bobUserId: PropTypes.string,
  isRequired: PropTypes.bool,
  showWarning: PropTypes.bool,
  isDealerUser: PropTypes.bool,
  isLoginAction: PropTypes.bool,
  preSelectedDealer: dealerAssociationsPropTypes,
  preSelectedStores: storesLocationPropTypes,
  removeResultsModal: PropTypes.func,
  reorderFunc: PropTypes.func,
  resetDealerSearchForm: PropTypes.func,
  showSkipThisStep: PropTypes.bool
};

export default AssociatedDealerForm;
