import { STATUS } from '@app/constants/commonConstants';
import * as types from './constants';
import { catVantageInitialStore } from './initialStore';

const catVantageReducer = (state = catVantageInitialStore, action) => {
  switch (action.type) {
    case types.DISPLAY_TOAST: {
      return {
        ...state,
        displayToast: true
      };
    }
    case types.CLOSE_TOAST: {
      return {
        ...state,
        displayToast: false
      };
    }
    case types.IS_CAT_VANTAGE_COVERING_ORDER_TOTAL: {
      return {
        ...state,
        isCatVantageCoveringOrderTotal: action.payload
      };
    }
    case types.GET_USER_ENROLLMENT_STATUS_BEGIN: {
      return {
        ...state,
        status: STATUS.PENDING
      };
    }
    case types.GET_USER_ENROLLMENT_STATUS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        status: STATUS.RESOLVED
      };
    }
    case types.GET_USER_ENROLLMENT_STATUS_FAIL: {
      return {
        ...state,
        status: STATUS.REJECTED
      };
    }
    case types.CVR_USER_ENROLLMENT_BEGIN: {
      return {
        ...state,
        cvrEnrollStatus: STATUS.PENDING
      };
    }
    case types.CVR_USER_ENROLLMENT_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        cvrEnrollStatus: STATUS.RESOLVED
      };
    }
    case types.CVR_USER_ALREADY_ENROLLED: {
      return {
        ...state,
        isCvrUserAlreadyEnrolled: true,
        cvrEnrollStatus: STATUS.RESOLVED
      };
    }
    case types.CVR_USER_ENROLLMENT_FAIL: {
      return {
        ...state,
        cvrEnrollStatus: STATUS.REJECTED
      };
    }
    case types.GET_CAT_VANTAGE_REWARDS_BALANCE_FAIL: {
      return {
        ...state,
        catRewardsBalanceStatus: STATUS.REJECTED,
        catVantageRewardsBalance: action.payload
      };
    }
    case types.GET_CAT_VANTAGE_REWARDS_BALANCE_BEGIN: {
      return {
        ...state,
        catRewardsBalanceStatus: STATUS.PENDING
      };
    }
    case types.GET_CAT_VANTAGE_REWARDS_BALANCE_SUCCESS: {
      return {
        ...state,
        catVantageRewardsBalance: action.payload,
        catRewardsBalanceStatus: STATUS.RESOLVED
      };
    }
    case types.SET_CAT_VANTAGE_REWARDS_BALANCE: {
      return {
        ...state,
        appliedRewards: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export { catVantageInitialStore };
export default catVantageReducer;
