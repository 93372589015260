export const formatCSRCustomers = dataObject => {
  const formattedCustomersObject = { ...dataObject };
  for (let i in formattedCustomersObject) {
    if (formattedCustomersObject.hasOwnProperty(i)) {
      const displayComma =
        formattedCustomersObject[i]?.address?.city &&
        formattedCustomersObject[i]?.address?.state
          ? `, `
          : '';
      // Fullname Joint
      formattedCustomersObject[i].fullName = `${
        formattedCustomersObject[i]?.firstName ?? ''
      } ${formattedCustomersObject[i]?.lastName ?? ''}`;
      // Address Joint
      if (typeof formattedCustomersObject[i].address === 'object') {
        formattedCustomersObject[i].address = `${
          formattedCustomersObject[i]?.address?.city ?? ''
        }${displayComma}${formattedCustomersObject[i]?.address?.state ?? ''}`;
      }
    }
  }

  const formattedArray = Object.values(formattedCustomersObject);

  return formattedArray.sort((a, b) => a.index - b.index);
};
