import { useContext } from 'react';
import ExceptionFeedbackBySelector from '../../../../common/Exception/ExceptionFeedbackBySelector';
import LogoutButton from '../../../../NavigationBarUtilitySection/LogoutButton';
import { AlloyButton, ModalContext } from 'cat-ecommerce-alloy';
import { useDispatch, useSelector } from 'react-redux';
import { getDealerAssociations } from '../../../../../store/dealer-association/actions';
import { useTranslation } from 'react-i18next';
import {
  ERROR_PATH,
  ERROR_DOMAIN,
  UNKNOWNERROR
} from '../../../../../constants/errorConstants';
import PropTypes from 'prop-types';
import { useSystemFeedback } from '@app/hooks';

const AssociatedDealerFormExceptionFeedback = ({
  dealerAssociationCallError,
  dealerCustomerServiceCallError,
  dealerSystemDownCookie,
  hasError,
  isRequired
}) => {
  const [t] = useTranslation();
  const { removeCurrentModal } = useContext(ModalContext);
  const dispatch = useDispatch();
  const { setError } = useSystemFeedback();
  const isCSR = useSelector(s => s.common.isCatCSR) || false;

  dealerSystemDownCookie &&
    setError(ERROR_DOMAIN.ADP, ERROR_PATH.STORES, UNKNOWNERROR);
  const errorSelector = state =>
    state.errors[ERROR_DOMAIN.ADP]?.[ERROR_PATH.STORES];
  const resetForm = () => {
    dispatch(getDealerAssociations());
  };

  const closeModal = () => {
    removeCurrentModal();
  };

  return (
    <div>
      <ExceptionFeedbackBySelector
        className={'mb-3'}
        messageClassName={'mb-2'}
        selector={errorSelector}
      >
        {dealerCustomerServiceCallError ||
        dealerAssociationCallError ||
        dealerSystemDownCookie ? (
          <AlloyButton
            buttonType="secondary"
            className="my-3 me-4"
            size="small"
            onClick={resetForm}
          >
            {t('TRY_AGAIN')}
          </AlloyButton>
        ) : null}
      </ExceptionFeedbackBySelector>

      {isRequired && hasError && (
        <>
          <div className={'d-flex justify-content-center'}>
            <LogoutButton
              buttonType="ghost"
              className="my-3 me-4"
              size="large"
              name="welcome-modal__shop-as-guest-button"
              isCSR={isCSR}
            >
              {t('1631_CONTINUE_AS_GUEST')}
            </LogoutButton>
          </div>
        </>
      )}
      {!isRequired && dealerAssociationCallError && (
        <>
          <div className={'d-flex justify-content-center'}>
            <AlloyButton
              buttonType="ghost"
              className="my-3 me-4"
              size="small"
              onClick={closeModal}
            >
              {t('MO_CancelButton')}
            </AlloyButton>
          </div>
        </>
      )}
    </div>
  );
};

AssociatedDealerFormExceptionFeedback.propTypes = {
  dealerAssociationCallError: PropTypes.bool,
  dealerCustomerServiceCallError: PropTypes.bool,
  hasError: PropTypes.bool,
  isRequired: PropTypes.bool
};

export default AssociatedDealerFormExceptionFeedback;
