import useMediaQuery from '../useMediaQuery';

/**
 * use this hook to watch if a media query breakpoint matches with the screen width.
 *
 * @param {number} breakpoint The min-width Breakpoint in pixels to be matched.
 * @returns {boolean} Breakpoint width matches.
 */
function useBreakpoint(breakpoint = 0) {
  return useMediaQuery(`(min-width: ${breakpoint}px)`);
}

export default useBreakpoint;
