import { getSessionStorageCartSharingData } from '../pages/checkout/shopping-cart/ShoppingCartPage/utils';

export const sortDealerName = (a, b) => a.label?.localeCompare(b.label);

export const applyShareCartLink = ({ storeId, storeLocationId }) => {
  const csrShareCart = getSessionStorageCartSharingData() || {};
  if (csrShareCart?.cartLinkUrl) {
    const isDealersMatching =
      storeId === csrShareCart.storeId &&
      storeLocationId === csrShareCart.storeLocationId;

    if (isDealersMatching) {
      window.location.href = csrShareCart.cartLinkUrl;
    }
  }
};
