import * as types from './constants';
import * as common from '../common/constants';
import * as dealer from '../dealer/constants';
import * as myEquipment from '../myequipment/constants';
import * as orders from '../orders/constants';
import * as store from '../store/constants';
import { addRegisteredServices, removeRegisteredService } from './utils';
import { pageLoadInitialStore } from './initialStore';

const pageLoadReducer = (state = {}, action) => {
  switch (action.type) {
    /**COMMON_SERVICE and DEALER_SERVICE are registered from initialStore so we don't need to register here*/
    case common.COMMON_GET_VARS_SUCCESS:
    case common.COMMON_GET_VARS_FAIL: {
      return removeRegisteredService(types.COMMON_SERVICE, state);
    }

    case dealer.DEALER_GET_INFO_SUCCESS:
    case dealer.DEALER_GET_INFO_FAIL: {
      return removeRegisteredService(types.DEALER_SERVICE, state);
    }

    case myEquipment.ME_BEGIN_LOADING: {
      return addRegisteredServices(types.MY_EQUIPMENT_SERVICE, state);
    }
    case myEquipment.ME_LOAD_EQUIPMENTDATA_SUCCESS:
    case myEquipment.ME_LOAD_MCCRESPONSE_FAIL: {
      return removeRegisteredService(types.MY_EQUIPMENT_SERVICE, state);
    }

    case orders.ORDERS_GET_CURRENT_BEGIN: {
      return addRegisteredServices(types.ORDER_SERVICE, state);
    }
    case orders.ORDERS_GET_CURRENT_SUCCESS:
    case orders.ORDERS_GET_CURRENT_FAIL: {
      return removeRegisteredService(types.ORDER_SERVICE, state);
    }

    case store.STORE_GET_INFO_BEGIN: {
      return addRegisteredServices(types.STORE_SERVICE, state);
    }
    case store.STORE_GET_INFO_SUCCESS:
    case store.STORE_GET_INFO_FAIL: {
      return removeRegisteredService(types.STORE_SERVICE, state);
    }

    default: {
      return state;
    }
  }
};

export { pageLoadInitialStore };
export default pageLoadReducer;
