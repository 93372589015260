import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MyEquipmentTitle from '../MyEquipmentTitle';
import SearchSuggestions from './SearchSuggestions';
import styles from '../MyEquipment.module.scss';
import { clearErrors } from '@app/store/myequipment/actions';
import { useAnalytics } from '@app/hooks';
import {
  FORM_ADD_EQ_MODAL_NAME,
  STATUS_SUCCESS
} from '@app/constants/analyticsConstants';

const AddEquipmentModal = ({ storeId, widgetIdentifier }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fireFormVisibleEvent } = useAnalytics();

  useEffect(() => {
    fireFormVisibleEvent({
      pagePath: document.location.pathname,
      formName: FORM_ADD_EQ_MODAL_NAME,
      formStatus: STATUS_SUCCESS
    });

    return () => dispatch(clearErrors());
  }, [dispatch, fireFormVisibleEvent]);

  return (
    <div className={`${styles['add-equipment']}`}>
      <MyEquipmentTitle title={t('ADD_MY_EQUIPMENT')} componentType="modal" />

      <p
        className={`mt-2 mb-2 color-extra-dark-gray text-univers ${styles['add-equipment__instructions']}`}
      >
        {t('MEQ_ADD_EQUIP_LABEL')}
      </p>
      <SearchSuggestions
        storeId={storeId}
        widgetIdentifier={widgetIdentifier}
      />

      <p
        className={`text-xs color-dark-gray position-relative ${styles['note']}`}
      >
        {t('MEQ_SUPPORTED_EQUIP')}
      </p>
    </div>
  );
};

AddEquipmentModal.propTypes = {
  storeId: PropTypes.string.isRequired,
  widgetIdentifier: PropTypes.string
};

export default AddEquipmentModal;
