import { STATUS } from '../../constants/commonConstants';

export const taxDetailInitialStore = {
  logonId: '',
  firstName: '',
  lastName: '',
  companyName: '',
  universalCustomerId: 0,
  dealerFields: {
    taxIdOption: '',
    taxId: '',
    stateTaxId: ''
  },
  address: {
    line1: '',
    line2: '',
    line3: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    email: '',
    phone: '',
    neighborhood: ''
  },
  isReceiveEmailEnabled: false,
  taxDetalStatus: STATUS.IDLE,
  isNotAuthorizedUser: false
};
