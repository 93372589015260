import {
  UOM,
  UOM_COOKIE,
  UOM_COOKIE_KEY
} from '@app/constants/commonConstants';
import { setCookie, getCookie } from '@app/utils';
import { getExpirationDate } from '@app/utils/cookieUtils';

export const setUnitOfMeasureCookie = value => {
  const expirationDate = getExpirationDate(30);
  const mapCookieValue = UOM_COOKIE[value];
  setCookie(UOM_COOKIE_KEY, mapCookieValue, expirationDate, '/');
};

export const getUnitOfMeasureCookie = (name = UOM_COOKIE_KEY) => {
  const cookieValue = getCookie(name);
  if (cookieValue) {
    return cookieValue === 'MMT' ? UOM.metric : UOM.us;
  }
  return null;
};
