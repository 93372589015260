import { getCookie, setCookie } from '@app/utils';
import {
  COOKIE_BROWSING_HISTORY,
  MAX_DAYS,
  PATH,
  MAX_PRODUCTS,
  BY_COMMA
} from './constant';
import { getExpiryDateFromNow, isDev } from '@app/utils/commonUtils';
import endpoints from '@app/constants/endpoints';

export function updateBrowsingHistoryCookie(productNumber) {
  const partNumber = productNumber.replace('-PRODUCT', '');
  const cookie = getCookie(COOKIE_BROWSING_HISTORY);
  const expiryDate = getExpiryDateFromNow(MAX_DAYS);
  if (cookie) {
    const productsViewed = cookie.split(BY_COMMA);
    const productList = new Set([partNumber, ...productsViewed]);

    // ArrayProducts musn't exceed 200 elements
    productList.length >= MAX_PRODUCTS &&
      productList.splice(MAX_PRODUCTS - 1, productList.length - MAX_PRODUCTS);

    const browsingHistoryCookieValue = [...productList].join(BY_COMMA);
    setCookie(
      COOKIE_BROWSING_HISTORY,
      browsingHistoryCookieValue,
      expiryDate,
      PATH
    );
  } else {
    setCookie(COOKIE_BROWSING_HISTORY, partNumber, expiryDate, PATH);
  }
}
export const getProductURL = productURL =>
  isDev() ? endpoints.PRODUCT_DISPLAY_PDP_LINK + productURL : productURL;
