import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import MyEquipmentTitle from '../../pages/my-equipment/MyEquipmentTitle';
import ContactUsOptions from '../ContactUsOptions';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';

const IMAGE_HEIGHTS = {
  mobile: 96,
  desktop: 128
};

const FindMyModal = () => {
  const { t } = useTranslation();
  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);

  return (
    <div>
      <MyEquipmentTitle
        title={t('ADD_EQUIP_FIND_MODEL')}
        componentType="modal"
      />
      <div>
        <div className="d-flex justify-content-center">
          <img
            src="https://caterpillar.scene7.com/is/image/Caterpillar/CM20200214-e3574-9840e?$pcc-e2$"
            alt="Equipment ID plate"
            height={isMD ? IMAGE_HEIGHTS.desktop : IMAGE_HEIGHTS.mobile}
          />
        </div>

        <span
          className={cx('u-color--DarkGray d-inline-block py-3 my-0', {
            'text-xs': !isMD,
            'text-md': isMD
          })}
        >
          {t('MY_EQUIP_FIND_SERIAL_TEXT1')}
        </span>
        <ContactUsOptions />
      </div>
    </div>
  );
};

export default FindMyModal;
