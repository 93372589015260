export const AC_LOAD_PAYMENTMETHODS_SUCCESS = 'AC_LOAD_PAYMENTMETHODS_SUCCESS';
export const AC_DELETE_PAYMENTMETHODS_SUCCESS =
  'AC_DELETE_PAYMENTMETHODS_SUCCESS';
export const AC_CLEAR_PAYMENTMETHODS = 'AC_CLEAR_PAYMENTMETHODS';
export const AC_LOAD_RECENTORDERHISTORY_BEGINS =
  'AC_LOAD_RECENTORDERHISTORY_BEGINS';
export const AC_LOAD_RECENTORDERHISTORY_SUCCESS =
  'AC_LOAD_RECENTORDERHISTORY_SUCCESS';
export const AC_LOAD_RECENTORDERHISTORY_FAILS =
  'AC_LOAD_RECENTORDERHISTORY_FAILS';
export const AC_LOAD_PERSONALINFO_BEGIN = 'AC_LOAD_PERSONALINFO_BEGIN';
export const AC_LOAD_PERSONALINFO_SUCCESS = 'AC_LOAD_PERSONALINFO_SUCCESS';
export const AC_LOAD_PERSONALINFO_FAIL = 'AC_LOAD_PERSONALINFO_FAIL';
export const AC_LOAD_SAVEDLIST_BEGIN = 'AC_LOAD_SAVEDLIST_BEGIN';
export const AC_LOAD_SAVEDLIST_SUCCESS = 'AC_LOAD_SAVEDLIST_SUCCESS';
export const AC_LOAD_SAVEDLIST_FAIL = 'AC_LOAD_SAVEDLIST_FAIL';
export const AC_CHANGE_SELFFOL_SUCCESS = 'AC_CHANGE_SELFFOL_SUCCESS';
export const AC_SHOW_FAV_LIST_BEGIN = 'AC_SHOW_FAV_LIST_BEGIN';

export const AC_CREATE_SAVEDLIST_BEGIN = 'AC_CREATE_SAVEDLIST_BEGIN';
export const AC_CREATE_SAVEDLIST_FAIL = 'AC_CREATE_SAVEDLIST_FAIL';
export const AC_CREATE_SAVEDLIST_SUCCESS = 'AC_CREATE_SAVEDLIST_SUCCESS';

export const AC_DUPLICATE_SAVEDLIST_BEGIN = 'AC_DUPLICATE_SAVEDLIST_BEGIN';
export const AC_DUPLICATE_SAVEDLIST_FAIL = 'AC_DUPLICATE_SAVEDLIST_FAIL';
export const AC_DUPLICATE_SAVEDLIST_SUCCESS = 'AC_DUPLICATE_SAVEDLIST_SUCCESS';

export const AC_ADD_LIST_TO_SAVEDLIST_TABLE_BEGIN =
  'AC_ADD_LIST_TO_SAVEDLIST_TABLE_BEGIN';
export const AC_ADD_LIST_TO_SAVEDLIST_TABLE_FAIL =
  'AC_ADD_LIST_TO_SAVEDLIST_TABLE_FAIL';
export const AC_ADD_LIST_TO_SAVEDLIST_TABLE_SUCCESS =
  'AC_ADD_LIST_TO_SAVEDLIST_TABLE_SUCCESS';

export const AC_DELETE_SAVEDLIST_BEGIN = 'AC_DELETE_SAVEDLIST_BEGIN';
export const AC_DELETE_SAVEDLIST_FAIL = 'AC_DELETE_SAVEDLIST_FAIL';
export const AC_DELETE_SAVEDLIST_SUCCESS = 'AC_DELETE_SAVEDLIST_SUCCESS';

export const AC_ADDCART_SAVEDLIST_BEGIN = 'AC_ADDCART_SAVEDLIST_BEGIN';
export const AC_ADDCART_SAVEDLIST_FAIL = 'AC_ADDCART_SAVEDLIST_FAIL';

export const AC_UPLOAD_SAVEDLIST_BEGIN = 'AC_UPLOAD_SAVEDLIST_BEGIN';
export const AC_UPLOAD_SAVEDLIST_FAIL = 'AC_UPLOAD_SAVEDLIST_FAIL';
export const AC_UPLOAD_SAVEDLIST_SUCCESS = 'AC_UPLOAD_SAVEDLIST_SUCCESS';

export const AC_SEARCH_OPTIONS_SET_DATA = 'AC_SEARCH_OPTIONS_SET_DATA';
export const AC_SAVEDLIST_SEARCH_SUCCESS = 'AC_SAVEDLIST_SEARCH_SUCCESS';
export const AC_SAVEDLIST_SEARCH_RESET = 'AC_SAVEDLIST_SEARCH_RESET';

export const AC_ADD_TO_SAVED_LISTS_BEGIN = 'AC_ADD_TO_SAVED_LISTS_BEGIN';
export const AC_ADD_TO_SAVED_LISTS_SUCCESS = 'AC_ADD_TO_SAVED_LISTS_SUCCESS';
export const AC_ADD_TO_SAVED_LISTS_FAIL = 'AC_ADD_TO_SAVED_LISTS_FAIL';

export const AC_ADD_TO_SAVED_LISTS_ON_LOGIN_BEGIN =
  'AC_ADD_TO_SAVED_LISTS_ON_LOGIN_BEGIN';
export const AC_ADD_TO_SAVED_LISTS_ON_LOGIN_SUCCESS =
  'AC_ADD_TO_SAVED_LISTS_ON_LOGIN_SUCCESS';
export const AC_ADD_TO_SAVED_LISTS_ON_LOGIN_FAIL =
  'AC_ADD_TO_SAVED_LISTS_ON_LOGIN_FAIL';

export const AC_CREATE_SAVED_LIST_BEGIN = 'AC_CREATE_SAVED_LIST_BEGIN';
export const AC_CREATE_SAVED_LIST_SUCCESS = 'AC_CREATE_SAVED_LIST_SUCCESS';
export const AC_CREATE_SAVED_LIST_FAIL = 'AC_CREATE_SAVED_LIST_FAIL';

export const AC_ADD_SAVED_LIST_BEGIN = 'AC_ADD_SAVED_LIST_BEGIN';
export const AC_ADD_SAVED_LIST_SUCCESS = 'AC_ADD_SAVED_LIST_SUCCESS';
export const AC_ADD_SAVED_LIST_FAIL = 'AC_ADD_SAVED_LIST_FAIL';

export const AC_APPROVE_PENDING_QUOTE_BEGIN = 'AC_APPROVE_PENDING_QUOTE_BEGIN';
export const AC_APPROVE_PENDING_QUOTE_SUCCESS =
  'AC_APPROVE_PENDING_QUOTE_SUCCESS';
export const AC_APPROVE_PENDING_QUOTE_FAIL = 'AC_APPROVE_PENDING_QUOTE_FAIL';
export const AC_LOAD_PENDINGQUOTES_BEGIN = 'AC_LOAD_PENDINGQUOTES_BEGIN';
export const AC_LOAD_PENDINGQUOTES_SUCCESS = 'AC_LOAD_PENDINGQUOTES_SUCCESS';
export const AC_LOAD_PENDING_APPROVAL_QUOTES_SUCCESS =
  'AC_LOAD_PENDING_APPROVAL_QUOTES_SUCCESS';
export const AC_LOAD_PENDINGQUOTES_FAIL = 'AC_LOAD_PENDINGQUOTES_FAIL';
export const AC_DELETE_PENDINGQUOTE_SUCCESS = 'AC_DELETE_PENDINGQUOTE_SUCCESS';
export const AC_DELETE_PENDINGQUOTE_BEGIN = 'AC_DELETE_PENDINGQUOTE_BEGIN';
export const AC_DELETE_PENDINGQUOTE_FAIL = 'AC_DELETE_PENDINGQUOTE_FAIL';
export const AC_EDIT_PENDINGQUOTE_SUCCESS = 'AC_EDIT_PENDINGQUOTE_SUCCESS';
export const AC_EDIT_PENDINGQUOTE_BEGIN = 'AC_EDIT_PENDINGQUOTE_BEGIN';
export const AC_EDIT_PENDINGQUOTE_FAIL = 'AC_EDIT_PENDINGQUOTE_FAIL';
export const AC_STOP_EDIT_PENDINGQUOTE_SUCCESS =
  'AC_STOP_EDIT_PENDINGQUOTE_SUCCESS';
export const AC_STOP_EDIT_PENDINGQUOTE_BEGIN =
  'AC_STOP_EDIT_PENDINGQUOTE_BEGIN';
export const AC_STOP_EDIT_PENDINGQUOTE_FAIL = 'AC_STOP_EDIT_PENDINGQUOTE_FAIL';
export const AC_PENDINGQUOTE_FILTER_CHANGE = 'AC_PENDINGQUOTE_FILTER_CHANGE';
export const AC_GET_NOTIFICATIONS_BEGIN = 'AC_GET_NOTIFICATIONS_BEGIN';
export const AC_GET_NOTIFICATIONS_SUCCESS = 'AC_GET_NOTIFICATIONS_SUCCESS';
export const AC_GET_NOTIFICATIONS_FAIL = 'AC_GET_NOTIFICATIONS_FAIL';
export const AC_OPEN_CONFLICT_CONFIRMATION = 'AC_OPEN_CONFLICT_CONFIRMATION';
export const AC_OPEN_CONFLICT_RESOLUTION = 'AC_OPEN_CONFLICT_RESOLUTION';
export const AC_HAS_CONFLICT = 'AC_HAS_CONFLICT';
export const AC_PENDING_QUOTE_SYSTEMFEEDBACK =
  'AC_PENDING_QUOTE_SYSTEMFEEDBACK';
export const LIST_TYPE = { private: 'Y', shared: 'Z' };

export const AC_MARK_LIST_FAVORITE_BEGIN = 'AC_MARK_LIST_FAVORITE_BEGIN';
export const AC_MARK_LIST_FAVORITE_SUCCESS = 'AC_MARK_LIST_FAVORITE_SUCCESS ';
export const AC_MARK_LIST_FAVORITE_FAIL = 'AC_MARK_LIST_FAVORITE_FAIL';
export const AC_SAVED_LIST_FAVORITE_TOGGLE = 'AC_SAVED_LIST_FAVORITE_TOGGLE';

export const AC_SAVED_LIST_LIMIT_STATUS = 'AC_SAVED_LIST_LIMIT_STATUS';
export const AC_FAVORITE_LIST_LIMIT = 50;
export const FILTER_KEYS = {
  PART_NUM: 'partNumber',
  QUOTE_STATUS: 'quoteStatus',
  FILTER_BY_DATE: 'filterByDate',
  EQUIPMENT: 'equipment'
};

export const ADDED_SAVED_LIST_TO_CART = 'ADDED_SAVED_LIST_TO_CART';
export const SAVED_LIST_UCID_GET_BEGIN = 'SAVED_LIST_UCID_GET_BEGIN';
export const SAVED_LIST_UCID_GET_SUCCESS = 'SAVED_LIST_UCID_GET_SUCCESS';
export const SAVED_LIST_UCID_GET_FAIL = 'SAVED_LIST_UCID_GET_FAIL';
export const SAVED_LIST_UCID_GET_IDLE = 'SAVED_LIST_UCID_GET_IDLE';
export const SAVED_LIST = 'PCC Saved List';

export const DEMO_DEALER_STORE_ID = '20195';
export const AC_REJECT_PENDING_QUOTE_BEGIN = 'AC_REJECT_PENDING_QUOTE_BEGIN';
export const AC_REJECT_PENDING_QUOTE_SUCCESS =
  'AC_REJECT_PENDING_QUOTE_SUCCESS';
export const AC_REJECT_PENDING_QUOTE_FAIL = 'AC_REJECT_PENDING_QUOTE_FAIL';
