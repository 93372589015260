import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../../constants/commonConstants';
import { getPriceAndAvailability } from '../../store/products/actions';
import { PRICE_AVAILABILITY_ATTRIBUTE_ID } from './constants';
const DEFAULT_NAMESPACE = '';
const defaultProps = {
  namespace: DEFAULT_NAMESPACE,
  useCacheService: false
};

/**
 * @param {Object} props options for this instance of useAnalytics
 * @param {string} props.namespace Name to be used in the products object in redux
 */
const usePriceAndAvailability = ({
  namespace = '',
  useCacheService = false
} = defaultProps) => {
  const [error, setError] = useState();
  const [status, setStatus] = useState(STATUS.IDLE);
  const dispatch = useDispatch();

  const normalizedNamespace = namespace
    ? `${namespace}PriceAndAvailability`
    : 'priceAndAvailability';
  const data = useSelector(s => s.products?.[normalizedNamespace]);
  const loading = status === STATUS.PENDING;

  const invoke = useCallback(
    ({ parts } = {}) => {
      dispatch(
        getPriceAndAvailability({
          namespace: normalizedNamespace,
          partsList: parts,
          setError,
          setStatus,
          attributeId: PRICE_AVAILABILITY_ATTRIBUTE_ID,
          useCacheService: useCacheService
        })
      );
    },
    [dispatch, normalizedNamespace]
  );

  return useMemo(
    () => ({
      data,
      error,
      invoke,
      loading,
      status
    }),
    [data, error, invoke, loading, status]
  );
};

export default usePriceAndAvailability;
