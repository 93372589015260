import { ERROR_DOMAIN, ERROR_PATH } from '@app/constants/errorConstants';

export const SEARCH_UI_EQUIPMENT_CHECKED = 'SEARCH_UI_EQUIPMENT_CHECKED';
export const SEARCH_UI_GET_DETAILS_BEGIN = 'SEARCH_UI_DETAILS_BEGIN';
export const SEARCH_UI_GET_DETAILS_SUCCESS = 'SEARCH_UI_DETAILS_SUCCESS';
export const SEARCH_UI_GET_DETAILS_FAIL = 'SEARCH_UI_DETAILS_FAIL';
export const SEARCH_UI_SET_QUERY_PARAM = 'SEARCH_UI_SET_QUERY_PARAM';
export const SEARCH_UI_SET_SEARCH_TERM = 'SEARCH_UI_SET_SEARCH_TERM';
export const SEARCH_UI_SET_PRODUCT_CARD_EXPANDED =
  'SEARCH_UI_SET_PRODUCT_CARD_EXPANDED';
export const SEARCH_UI_SET_PAGESIZE = 'SEARCH_UI_SET_PAGESIZE';
export const SEARCH_UI_SET_ACTIVE_FACETS = 'SEARCH_UI_SET_ACTIVE_FACETS';
export const SEARCH_UI_SET_FACETS_UOM_FILTERED =
  'SEARCH_UI_SET_FACETS_UOM_FILTERED';
export const PRODUCTS_LIST_RESET = 'PRODUCTS_LIST_RESET';
export const SEARCH_UI_GET_PREVIOUS_DETAILS_BEGIN =
  'SEARCH_UI_GET_PREVIOUS_DETAILS_BEGIN';
export const SEARCH_UI_GET_PREVIOUS_DETAILS_SUCCESS =
  'SEARCH_UI_GET_PREVIOUS_DETAILS_SUCCESS';
export const SEARCH_UI_GET_PREVIOUS_DETAILS_FAIL =
  'SEARCH_UI_GET_PREVIOUS_DETAILS_FAIL';
export const SEARCH_UI_GET_MORE_DETAILS_FAIL =
  'SEARCH_UI_GET_MORE_DETAILS_FAIL';
export const SEARCH_UI_GET_MORE_DETAILS_BEGIN =
  'SEARCH_UI_GET_MORE_DETAILS_BEGIN';
export const SEARCH_UI_GET_MORE_DETAILS_SUCCESS =
  'SEARCH_UI_GET_MORE_DETAILS_SUCCESS';
export const SEARCH_UI_SET_EQUIPMENT_CHECKED =
  'SEARCH_UI_SET_EQUIPMENT_CHECKED';
export const SORT_PRODUCTS_BY_BEGIN = 'SORT_PRODUCTS_BY_BEGIN';
export const SORT_PRODUCTS_BY_SUCCESS = 'SORT_PRODUCTS_BY_SUCCESS';
export const SORT_PRODUCTS_BY_FAIL = 'SORT_PRODUCTS_BY_FAIL';
export const FUSION_QUERY_ID = 'FUSION_QUERY_ID';
export const PRICE_AVAILABILITY_ATTRIBUTE_ID = 'partNumber';
export const ERROR_INFO = {
  domain: ERROR_DOMAIN.SEARCH_UI,
  path: ERROR_PATH.MAIN
};
