import PropTypes from 'prop-types';
import { AssociatedDealerDropdown } from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import { sortDealerName } from '../../utils';
import { CUSTOMER_NUMBER_DROPDOWN_NAME } from '../../constants';
import { CustomerNumber } from '../../types';

export const AssociatedDealerCustomerNumberDropdownWrapper = ({
  isCSPCustomer,
  customerNumberList,
  customerNumber,
  selectedValue,
  onSelect
}) => {
  const [t] = useTranslation();
  const label = t('CUST_NAME_NUMBER_COMBINED');
  const placeholder = t('CAT_MA_SELECTUCID');
  const sortedCustomerNumberList = customerNumberList?.sort(sortDealerName);
  const isObjectValue =
    typeof customerNumber === 'object' && customerNumber !== null;
  const isSingleCustomerNumber = customerNumberList?.length === 1;
  const showDropdown = isCSPCustomer || !!customerNumber;
  const isReadOnly = isObjectValue || isSingleCustomerNumber;

  return (
    <AssociatedDealerDropdown
      name={CUSTOMER_NUMBER_DROPDOWN_NAME}
      label={label}
      placeholder={placeholder}
      itemList={sortedCustomerNumberList}
      item={customerNumber}
      disabled={!!customerNumber}
      showDropdown={showDropdown}
      required={true}
      readOnly={isReadOnly && showDropdown}
      selectedValue={selectedValue}
      onSelect={onSelect}
    />
  );
};

AssociatedDealerCustomerNumberDropdownWrapper.propTypes = {
  isCSPCustomer: PropTypes.bool,
  customerNumberList: PropTypes.arrayOf(CustomerNumber),
  customerNumber: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  selectedValue: PropTypes.string,
  onSelect: PropTypes.func
};
