import { isSiteMap, isHomepage } from '@app/utils/browserUtils';
import { PARAM_SUBCATEGORY_LIMIT, SUBCATEGORY_TIER } from './constants';

const CURRENT_PAGE = {
  isHomepage: isHomepage(),
  isSiteMap: isSiteMap()
};

export const buildHeaderInfoUrlWithParams = url => {
  if (CURRENT_PAGE.isHomepage) {
    return `${url}${PARAM_SUBCATEGORY_LIMIT}`;
  }

  if (CURRENT_PAGE.isSiteMap) {
    return `${url}${SUBCATEGORY_TIER}`;
  }

  return url;
};
