import PropTypes from 'prop-types';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import {
  GRID_ESPOT_2_MIN_TILES,
  GRID_ESPOT_2_MIN_TILES_LG,
  GRID_ESPOT_3_MIN_TILES
} from '../constants';
import Espot from '@app/components/common/Espot/Espot';
import CategoriesGrid from '@app/components/common/CategoriesGrid';
import { getCategoriesPagesCols } from '@app/components/common/CategoriesGrid/utils';

const ShopAllCategoriesGrid = ({
  categories = [],
  isLoading,
  skeletonSize,
  espot2Html,
  espot3Html,
  isEspotLoading
}) => {
  const isXL = useBreakpoint(PAGE_BREAKPOINTS.XL);
  const isLG = useBreakpoint(PAGE_BREAKPOINTS.LG);

  const renderAdditionalRowContent = rowIndex => {
    const cols = getCategoriesPagesCols(isXL, isLG);
    const firstEspotIndex = isXL
      ? Math.ceil(GRID_ESPOT_2_MIN_TILES / cols) - 1
      : Math.ceil(GRID_ESPOT_2_MIN_TILES_LG / cols) - 1;
    const secondEspotIndex = Math.ceil(GRID_ESPOT_3_MIN_TILES / cols) - 1;

    const isFirstEspotVisible = firstEspotIndex === rowIndex;
    const isSecondEspotVisible = secondEspotIndex === rowIndex;

    if (isFirstEspotVisible) {
      return <Espot html={espot2Html} isLoading={isEspotLoading} />;
    } else if (isSecondEspotVisible) {
      return <Espot html={espot3Html} isLoading={isEspotLoading} />;
    } else {
      return null;
    }
  };

  return (
    <CategoriesGrid
      isLoading={isLoading}
      categories={categories}
      skeletonSize={skeletonSize}
      renderAdditionalRowContent={renderAdditionalRowContent}
    />
  );
};

ShopAllCategoriesGrid.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      imageUrl: PropTypes.string,
      redirectUrl: PropTypes.string
    })
  ),
  isLoading: PropTypes.bool,
  skeletonSize: PropTypes.number,
  espot2Html: PropTypes.node,
  espot3Html: PropTypes.node,
  isEspotLoading: PropTypes.bool
};

export default ShopAllCategoriesGrid;
