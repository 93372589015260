import { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import endpoints from '../constants/endpoints';
import { isEmpty, replaceTokensInString } from '../utils';
import { useHttp } from '@app/hooks';
import { TIMEOUT_EXTENDED } from '../constants/commonConstants';
import { getEctMessages } from '@app/store/ect/action';

const { ECT_MESSAGE_API } = endpoints;

export const useECT = (
  messageType,
  espotName,
  flagEnabled = true,
  params = '',
  storeIdParam = ''
) => {
  const currentStoreId = useSelector(s => s.common.storeId);
  const langId = useSelector(s => s.common.langId);
  const storeId = storeIdParam || currentStoreId;
  const dealerCode = useSelector(s => s.dealer?.dealerCode);
  const locale = useSelector(s => s.common?.locale);
  const { data, error, invoke, loading } = useHttp({
    timeout: TIMEOUT_EXTENDED
  });
  const [initialData, setInitialData] = useState(data);
  const [ectError, setEctError] = useState(false);
  const dispatch = useDispatch();
  const getECTContent = useCallback(async () => {
    if (
      flagEnabled &&
      !isEmpty(dealerCode) &&
      (!isEmpty(messageType) || !isEmpty(espotName))
    ) {
      try {
        let url = replaceTokensInString(
          ECT_MESSAGE_API,
          dealerCode,
          messageType,
          locale,
          storeId,
          langId,
          espotName
        );
        const response = await invoke({
          url,
          method: 'get',
          rethrowError: true
        });
        setInitialData(response);
        setEctError(false);
        return response;
      } catch (error) {
        const errorResponse = error?.response?.data;
        if (
          errorResponse?.errors?.length > 0 &&
          errorResponse?.errors[0]?.errorCode
        ) {
          setEctError(true);
          dispatch(getEctMessages(errorResponse, messageType));
        }
      }
    }
    return '';
  }, [
    invoke,
    messageType,
    dealerCode,
    langId,
    espotName,
    params,
    locale,
    storeId,
    flagEnabled
  ]);
  useEffect(() => {
    getECTContent();
  }, [getECTContent]);

  return useMemo(
    () => ({
      getECTContent,
      data: initialData,
      error: ectError,
      loading
    }),
    [error, getECTContent, initialData, loading]
  );
};

export default useECT;
