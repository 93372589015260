import PropTypes from 'prop-types';
import ExceptionFeedbackBySelector from '../../common/Exception/ExceptionFeedbackBySelector';
import DealerUserSearchForm from './DealerUserSearchForm';
import { ERROR_DOMAIN, ERROR_PATH } from '../../../constants/errorConstants';

const DealerUserModal = ({ title }) => {
  return (
    <div>
      <h2 className="exclude-global h2 display-1 mb-4">{title}</h2>
      <ExceptionFeedbackBySelector
        className="mb-4"
        selector={state =>
          state.errors[ERROR_DOMAIN.DEALER_USER_SEARCH]?.[ERROR_PATH.MAIN]
        }
      />
      <DealerUserSearchForm />
    </div>
  );
};

export default DealerUserModal;

DealerUserModal.propTypes = {
  title: PropTypes.string.isRequired
};
