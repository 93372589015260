import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ExpiredStatus } from '@cat-ecom/pcc-components';
import { getFormattedDate } from '../utils';

const Expiredstatus = ({ link, quoteExpireDate }) => {
  const { t } = useTranslation();
  const today = new Date();
  const isTodayDate =
    getFormattedDate(today) === getFormattedDate(quoteExpireDate);
  return (
    <ExpiredStatus
      isTodayDate={isTodayDate}
      link={link}
      REVIEW_QUOTE={t('REVIEW_QUOTE')}
      VIEW_QUOTE={t('VIEW_QUOTE')}
    />
  );
};

export default Expiredstatus;

Expiredstatus.propTypes = {
  orderStatus: PropTypes.string,
  link: PropTypes.string,
  quoteExpireDate: PropTypes.string
};
