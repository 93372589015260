import cx from 'classnames';
import { getCookie, setSessionStorage } from '@app/utils';
import { CILG_ITEMS } from '@app/constants/commonConstants';
import styles from './styles.module.scss';
import { SESSION_STORAGE_CSR_SHARE_CART_REGISTERED_USERS_KEY } from './constants';

const getItemsDesktopCell = (item, col) => {
  return (
    <span
      className={`${styles['cart-import-summary__text']} m-0`}
      data-testid="itemNameDesktop"
    >
      {item[col.id]}
    </span>
  );
};

const getItemsMobileCell = (itemText, labelText, isItemsSubmitted) => {
  return (
    <div
      className={cx('d-flex justify-content-between m-0', {
        'border-bottom': isItemsSubmitted,
        'py-2': isItemsSubmitted,
        'pt-2': !isItemsSubmitted
      })}
    >
      <span
        className={`${styles['mobile-table-head__text']} text-sm  m-0 text-start`}
      >
        {labelText}
      </span>
      <span
        data-testid="itemNameMobile"
        className={`${styles['mobile-table-cell__text']} text-sm m-0 w-50 text-end`}
      >
        {itemText}
      </span>
    </div>
  );
};

const getItemsSummaryMobileCell = (item, col) => {
  const isItemsSubmitted = true;
  return (
    <>
      {getItemsMobileCell(
        item.itemsSubmitted,
        col.submittedLabel,
        isItemsSubmitted
      )}
      {getItemsMobileCell(item.itemsAdded, col.addedLabel)}
    </>
  );
};

export const getCustomRenderers = () => ({
  itemsSubmitted: getItemsDesktopCell,
  itemsAdded: getItemsDesktopCell,
  itemsSummaryMobileCell: getItemsSummaryMobileCell
});

const getItemsNotAddedMobileCell = (item, col) => (
  <>
    {getItemsMobileCell(item.partQuantity, col.partQuantityLabel)}
    {getItemsMobileCell(item.partNumber, col.partNumberLabel)}
    {getItemsMobileCell(item.error, col.errorLabel)}
  </>
);

export const getItemsNotAddedCustomRenders = () => ({
  itemNotAddedQuantity: getItemsDesktopCell,
  itemNotAddedNumber: getItemsDesktopCell,
  itemNotAddedError: getItemsDesktopCell,
  itemsNotAddedMobileCell: getItemsNotAddedMobileCell
});

const getItemsReplacedMobileCell = (item, col) => (
  <>
    {getItemsMobileCell(item.partQuantity, col.partQuantityLabel)}
    {getItemsMobileCell(item.partNumber, col.partNumberLabel)}
    {getItemsMobileCell(item.partName, col.partNameLabel)}
  </>
);

export const getItemsReplacedCustomRenders = () => ({
  itemReplacedQuantity: getItemsDesktopCell,
  itemReplacedNumber: getItemsDesktopCell,
  itemReplacedPartName: getItemsDesktopCell,
  itemsReplacedMobileCell: getItemsReplacedMobileCell
});

export const setTimeSessionStorage = key => {
  const time = new Date();
  setSessionStorage(key, time);
};

export const filteredValue = (
  itemsAndQuantityAddedIntoCartObject,
  ascendingOrderItems
) => {
  const filteredNewItems = ascendingOrderItems.filter(item => {
    return itemsAndQuantityAddedIntoCartObject.find(
      part =>
        part.partNumber === item.partNumber?.replace(/-/g, '').toUpperCase()
    );
  });
  const quantityUpdatedFilteredItems = filteredNewItems.map(element => {
    const matchingElement = itemsAndQuantityAddedIntoCartObject.find(
      item =>
        item.partNumber === element.partNumber?.replace(/-/g, '').toUpperCase()
    );
    const newQuantity = matchingElement?.quantity;
    return {
      ...element,
      serverQuantity: newQuantity?.toString(),
      quantity: newQuantity?.toString()
    };
  });
  return quantityUpdatedFilteredItems;
};

export const getItemsFromCILGCookie = () => {
  const cookieValue = decodeURIComponent(getCookie(CILG_ITEMS) || '');
  const [itemsValue] = cookieValue.split(/;(.*)/s);
  const items = itemsValue.split('||');
  return items.reduce((acc, element = '', index) => {
    const values = element.split('~');
    acc[index] = {
      quantity: values[0] || '',
      partNumber: values[1] || ''
    };
    return acc;
  }, []);
};

export const removeSessionStorageCartSharingData = () => {
  sessionStorage.removeItem(
    SESSION_STORAGE_CSR_SHARE_CART_REGISTERED_USERS_KEY
  );
};

export const getSessionStorageCartSharingData = () => {
  if (
    sessionStorage.getItem(SESSION_STORAGE_CSR_SHARE_CART_REGISTERED_USERS_KEY)
  ) {
    return JSON.parse(
      sessionStorage.getItem(
        SESSION_STORAGE_CSR_SHARE_CART_REGISTERED_USERS_KEY
      )
    );
  }
  return null;
};

export const updateSessionStorageCartSharingData = cartSharingInfo => {
  const currentValues = getSessionStorageCartSharingData();
  sessionStorage.setItem(
    SESSION_STORAGE_CSR_SHARE_CART_REGISTERED_USERS_KEY,
    JSON.stringify({
      ...currentValues,
      ...cartSharingInfo
    })
  );
};

export const redirectToHomePage = (previousStoreId, baseHomeUrl) => {
  const homeUrl = new URL(
    `${window.location.protocol}//${window.location.host}${baseHomeUrl}`
  );
  homeUrl.searchParams.set('storeId', previousStoreId);
  window.location.replace(homeUrl.href);
};
