import { STATUS } from '../../constants/commonConstants';
import { orderHistoryInitialStore } from './initialStore';

import {
  ORDER_HISTORY_DEALER_ACCOUNT_SELECTED,
  ORDER_HISTORY_GET_DEALER_ACCOUNTS_SUCCESS,
  ORDER_HISTORY_GET_DEALER_ACCOUNTS_FAIL,
  ORDER_HISTORY_GET_EQUIPMENT_SUCCESS,
  ORDER_HISTORY_GET_EQUIPMENT_FAIL,
  ORDER_HISTORY_GET_STORE_LOCATIONS_SUCCESS,
  ORDER_HISTORY_GET_STORE_LOCATIONS_FAIL,
  ORDER_HISTORY_RETRIEVAL_FAIL,
  ORDER_HISTORY_ERP_DOWN,
  ORDER_HISTORY_RETRIEVAL_SUCCESS,
  ORDER_HISTORY_FILTER_CHANGED,
  ORDER_HISTORY_RESET,
  ORDER_HISTORY_RESET_RESTORE,
  ORDER_HISTORY_RETRIEVAL_BEGIN,
  REORDER_BUTTON_BEGIN,
  REORDER_BUTTON_SUCCESS,
  REORDER_BUTTON_FAIL,
  STORE_TYPE_CHANGED
} from './constants';

const orderHistoryReducer = (state = orderHistoryInitialStore, action) => {
  switch (action.type) {
    case ORDER_HISTORY_DEALER_ACCOUNT_SELECTED: {
      return {
        ...state,
        storeLocations: null,
        storeLocationsLoading: true
      };
    }
    case ORDER_HISTORY_GET_DEALER_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        dealerAccountsLoading: false,
        dealerAccounts: action.DealerCustomerNumber,
        dateFormat: action.supportedDateFormat,
        orderStatus: action.OrderStatus,
        storeLocations: null,
        equipmentList: undefined
      };
    }
    case ORDER_HISTORY_GET_DEALER_ACCOUNTS_FAIL: {
      return {
        ...state,
        dealerAccountsLoading: false,
        dealerAccounts: undefined,
        dateFormat: undefined,
        storeLocations: null,
        equipmentList: null
      };
    }
    case ORDER_HISTORY_GET_EQUIPMENT_SUCCESS: {
      return {
        ...state,
        equipmentLoading: false,
        equipmentList: action.equipmentList
      };
    }
    case ORDER_HISTORY_GET_EQUIPMENT_FAIL: {
      return {
        ...state,
        equipmentLoading: false,
        equipmentList: undefined
      };
    }
    case ORDER_HISTORY_GET_STORE_LOCATIONS_SUCCESS: {
      return {
        ...state,
        storeLocationsLoading: false,
        storeLocations: action.storeLocations
      };
    }
    case ORDER_HISTORY_GET_STORE_LOCATIONS_FAIL: {
      return {
        ...state,
        storeLocationsLoading: false,
        storeLocations: null
      };
    }
    case ORDER_HISTORY_RETRIEVAL_BEGIN: {
      return {
        ...state,
        searchStatus: STATUS.PENDING
      };
    }
    case ORDER_HISTORY_RETRIEVAL_FAIL: {
      return {
        ...state,
        searchStatus: STATUS.REJECTED,
        failReason: action.payload,
        orders: [],
        listSize: 0,
        customerType: undefined,
        currency: undefined
      };
    }
    case ORDER_HISTORY_ERP_DOWN: {
      return {
        ...state,
        searchStatus: STATUS.REJECTED,
        failReason: action.payload
      };
    }
    case ORDER_HISTORY_RETRIEVAL_SUCCESS: {
      const {
        orders,
        listSize,
        customerType,
        currency,
        sortBy,
        orderBy,
        equipment
      } = action;
      return {
        ...state,
        orders,
        listSize,
        customerType,
        currency,
        searchStatus: STATUS.RESOLVED,
        sortBy,
        orderBy,
        failReason: listSize > 0 ? '' : 'No Results',
        selectedEquipment: equipment
      };
    }
    case ORDER_HISTORY_FILTER_CHANGED: {
      return {
        ...state,
        filter: action.filter
      };
    }
    case ORDER_HISTORY_RESET: {
      return {
        ...state,
        clearFilter: STATUS.ON
      };
    }
    case ORDER_HISTORY_RESET_RESTORE: {
      return {
        ...state,
        clearFilter: STATUS.OFF
      };
    }
    case REORDER_BUTTON_BEGIN: {
      return {
        ...state,
        isReorderLoading: STATUS.PENDING
      };
    }
    case REORDER_BUTTON_SUCCESS: {
      return {
        ...state,
        isReorderLoading: STATUS.RESOLVED
      };
    }
    case REORDER_BUTTON_FAIL: {
      return {
        ...state,
        isReorderLoading: STATUS.REJECTED
      };
    }
    case STORE_TYPE_CHANGED: {
      return {
        ...state,
        filter: action.filter
      };
    }
    default:
      return state;
  }
};

export { orderHistoryInitialStore };
export default orderHistoryReducer;
