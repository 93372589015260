// Order History List Actions
export const ORDER_HISTORY_RETRIEVAL_FETCH = 'ORDER_HISTORY_RETRIEVAL_FETCH';
export const ORDER_HISTORY_SET_ORDERS = 'ORDER_HISTORY_SET_ORDERS';
export const ORDER_HISTORY_RETRIEVAL_BEGIN = 'ORDER_HISTORY_RETRIEVAL_BEGIN';
export const ORDER_HISTORY_RETRIEVAL_SUCCESS =
  'ORDER_HISTORY_RETRIEVAL_SUCCESS';
export const ORDER_HISTORY_RETRIEVAL_FAIL = 'ORDER_HISTORY_RETRIEVAL_FAIL';
export const ORDER_HISTORY_ERP_DOWN = 'ORDER_HISTORY_ERP_DOWN';
export const ORDER_HISTORY_FILTER_CHANGED = 'ORDER_HISTORY_FILTER_CHANGED';

// Filter Actions
export const REORDER_BUTTON_BEGIN = 'REORDER_BUTTON_BEGIN';

export const REORDER_BUTTON_SUCCESS = 'REORDER_BUTTON_SUCCESS';

export const REORDER_BUTTON_FAIL = 'REORDER_BUTTON_FAIL';

export const ORDER_HISTORY_DEALER_ACCOUNT_SELECTED =
  'ORDER_HISTORY_DEALER_ACCOUNT_SELECTED';

export const ORDER_HISTORY_GET_DEALER_ACCOUNTS_SUCCESS =
  'ORDER_HISTORY_GET_DEALER_ACCOUNTS_SUCCESS';
export const ORDER_HISTORY_GET_DEALER_ACCOUNTS_FAIL =
  'ORDER_HISTORY_GET_DEALER_ACCOUNTS_FAIL';

export const ORDER_HISTORY_GET_EQUIPMENT_SUCCESS =
  'ORDER_HISTORY_GET_EQUIPMENT_SUCCESS';
export const ORDER_HISTORY_GET_EQUIPMENT_FAIL =
  'ORDER_HISTORY_GET_EQUIPMENT_FAIL';

export const ORDER_HISTORY_GET_STORE_LOCATIONS_SUCCESS =
  'ORDER_HISTORY_GET_STORE_LOCATIONS_SUCCESS';
export const ORDER_HISTORY_GET_STORE_LOCATIONS_FAIL =
  'ORDER_HISTORY_GET_STORE_LOCATIONS_FAIL';

// reset
export const ORDER_HISTORY_RESET = 'ORDER_HISTORY_RESET';
export const ORDER_HISTORY_RESET_RESTORE = 'ORDER_HISTORY_RESET_RESTORE';

//radio filter
export const STORE_TYPE_CHANGED = 'STORE_TYPE_CHANGED';
