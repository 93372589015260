export const ROUTE_QRCODE = 'qrCode';
export const MULTI_EQ_ROUTE = 'multiEquipmentRoute';
export const PRODUCT_SOURCES_KEY = {
  recentlyViewed: 'recentlyViewed',
  bestSellers: 'bestSellers',
  featured: 'featured'
};

export const PRODUCT_STATUS_KEY = {
  recentlyViewedStatus: 'getHomepageProductRecentlyViewedStatus',
  recentlyViewedStatusError: 'getHomepageProductRecentlyViewedStatusError',
  bestSellersStatus: 'getHomepageProductBestSellersStatus',
  bestSellersStatusError: 'getHomepageProductBestSellersStatusError',
  featuredStatus: 'getHomepageProductFeaturedStatus',
  featuredStatusError: 'getHomepageProductFeaturedStatusError'
};

export const TOP_ESPOT_HEIGHT_SIZE = '60px';

export const HOME_PAGE_ESPOT_PLACEHOLDER_1 = 'HOME_PAGE_ESPOT_PLACEHOLDER_1';
export const HOME_PAGE_ESPOT_PLACEHOLDER_2 = 'HOME_PAGE_ESPOT_PLACEHOLDER_2';
export const HOME_PAGE_ESPOT_PLACEHOLDER_3 = 'HOME_PAGE_ESPOT_PLACEHOLDER_3';
export const HOME_PAGE_ESPOT_PLACEHOLDER_4 = 'HOME_PAGE_ESPOT_PLACEHOLDER_4';
export const SHOPPING_PREFERENCES_HOME_PAGE_ESPOT =
  'SHOPPING_PREFERENCES_HOME_PAGE_ESPOT';
export const HOME_PAGE_BANNER_ESPOT = 'CATSAS_HERO_SECTION_BANNER';

export const HOMEPAGE_ESPOTS_PLACEHOLDERS = [
  HOME_PAGE_ESPOT_PLACEHOLDER_1,
  HOME_PAGE_ESPOT_PLACEHOLDER_2,
  HOME_PAGE_ESPOT_PLACEHOLDER_3,
  HOME_PAGE_ESPOT_PLACEHOLDER_4,
  SHOPPING_PREFERENCES_HOME_PAGE_ESPOT
].join(',');

export const CATEGORIES_RECENTLY_PURCHASED = 'CATEGORIES_RECENTLY_PURCHASED';
export const CATEGORY_STATUS_KEY = {
  recentlyPurchasedStatus: 'getHomepageCategoriesRecentlyPurchasedStatus',
  recentlyPurchasedStatusError:
    'getHomepageCategoriesRecentlyPurchasedStatusError'
};

export const PRODUCT_LIST_NAME_EVENTS = {
  BEST_SELLERS: 'PCC Best Sellers',
  FEATURED_PRODCUTS: 'PCC Featured products',
  RECENTLY_PURCHASED_CATEGORIES: 'PCC Recently Purchased categories',
  RECENTLY_VIEWED_PRODUCTS: 'PCC Recently Viewed products'
};

export const PRODUCT_LIST_PAGE_SIZE = 8;

export const SHOP_BY_CATEGORY = {
  CATEGORIES_LIMIT: 10,
  SUBCATEGORIES_LIMIT: 2
};
export const PROFILE_ERROR_MSG_ID = 'profileServiceError';

export const SELECT_OR_ADD_EQUIPMENT = {
  src: 'https://caterpillar.scene7.com/is/image/Caterpillar/CM20240314-601e6-1c83d?wid=180&hei=120&fmt=webp',
  alt: 'Select or Add Equipment'
};
