import { useEffect, useState } from 'react';

/**
 * Use this hook to watch if a css media query is matched.
 *
 * @param {string} query Media Query to be matched.
 * @returns {boolean} Media query matches.
 */
function useMediaQuery(query = '') {
  const [mediaMatches, setMediaMatches] = useState(
    window.matchMedia(query).matches
  );

  useEffect(() => {
    const media = window.matchMedia(query);
    const handleMediaChange = ({ matches }) => setMediaMatches(matches);

    // use AddListener to bring IE support
    media.addListener(handleMediaChange);
    return () => media.removeListener(handleMediaChange);
  }, [query]);

  return mediaMatches;
}

export default useMediaQuery;
