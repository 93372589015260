export const ORDERS_GET_CURRENT_BEGIN = 'ORDERS_GET_CURRENT_BEGIN';
export const ORDERS_GET_CURRENT_SUCCESS = 'ORDERS_GET_CURRENT_SUCCESS';
export const ORDERS_GET_CURRENT_FAIL = 'ORDERS_GET_CURRENT_FAIL';

export const ORDER_GET_IDLE = 'ORDER_GET_IDLE';
export const ORDER_GET_BEGIN = 'ORDER_GET_BEGIN';
export const ORDER_GET_SUCCESS = 'ORDER_GET_SUCCESS';
export const ORDER_GET_FAIL = 'ORDER_GET_FAIL';

export const ORDER_GET_ERP_BEGIN = 'ORDER_GET_ERP_BEGIN';
export const ORDER_GET_ERP_SUCCESS = 'ORDER_GET_ERP_SUCCESS';
export const ORDER_GET_ERP_DOWN = 'ORDER_GET_ERP_DOWN';

export const ORDER_HEADER_INFO_BEGIN = 'ORDER_HEADER_INFO_BEGIN';
export const ORDER_HEADER_INFO_SUCCESS = 'ORDER_HEADER_INFO_SUCCESS';
export const ORDER_HEADER_INFO_FAIL = 'ORDER_HEADER_INFO_FAIL';

export const ORDERS_UPDATE_ORDERED_BY_BEGIN = 'ORDERS_UPDATE_ORDERED_BY_BEGIN';
export const ORDERS_UPDATE_ORDERED_BY_FAIL = 'ORDERS_UPDATE_ORDERED_BY_FAIL';
export const ORDERS_UPDATE_ORDERED_BY_SUCCESS =
  'ORDERS_UPDATE_ORDERED_BY_SUCCESS';
export const RBD_UPDATE_ORDERED_BY_SUCCESS = 'RBD_UPDATE_ORDERED_BY_SUCCESS';

export const PRICE_AND_AVAILABILITY_BEGIN = 'PRICE_AND_AVAILABILITY_BEGIN';
export const PRICE_AND_AVAILABILITY_FAIL = 'PRICE_AND_AVAILABILITY_FAIL';
export const PRICE_AND_AVAILABILITY_SUCCESS = 'PRICE_AND_AVAILABILITY_SUCCESS';

export const DECREMENT_ORDER_QUANTITY = 'DECREMENT_ORDER_QUANTITY';
export const INCREMENT_ORDER_QUANTITY = 'INCREMENT_ORDER_QUANTITY';
export const SET_QUANTITY = 'SET_QUANTITY';

export const ORDERS_UPDATE_LINE_ITEM_BEGIN = 'ORDERS_UPDATE_LINE_ITEM_BEGIN';
export const ORDERS_UPDATE_LINE_ITEM_FAIL = 'ORDERS_UPDATE_LINE_ITEM_FAIL';
export const ORDERS_UPDATE_LINE_ITEM_SUCCESS =
  'ORDERS_UPDATE_LINE_ITEM_SUCCESS';

export const ORDERS_REMOVE_PACKAGE_ERROR = 'ORDERS_REMOVE_PACKAGE_ERROR';
export const ORDERS_REMOVE_PACKAGE_ERROR_PACKAGE =
  'ORDERS_REMOVE_PACKAGE_ERROR_PACKAGE';
export const DELETE_CART_BEGIN = 'DELETE_CART_BEGIN';
export const DELETE_CART_FAIL = 'DELETE_CART_FAIL';
export const DELETE_CART_SUCCESS = 'DELETE_CART_SUCCESS';

export const ORDERS_UPDATE_TOTAL_BEGIN = 'ORDERS_UPDATE_TOTAL_BEGIN';
export const ORDERS_UPDATE_TOTAL_FAIL = 'ORDERS_UPDATE_TOTAL_FAIL';
export const ORDERS_UPDATE_TOTAL_SUCCESS = 'ORDERS_UPDATE_TOTAL_SUCCESS';

export const ORDERS_SET_LINE_ITEM_ERROR = 'ORDERS_SET_LINE_ITEM_ERROR';
export const ORDERS_CLEAR_LINE_ITEM_ERROR = 'ORDERS_CLEAR_LINE_ITEM_ERROR';

export const PROCEED_TO_CHECKOUT_BEGIN = 'PROCEED_TO_CHECKOUT_BEGIN';
export const PROCEED_TO_CHECKOUT_SUCCESS = 'PROCEED_TO_CHECKOUT_SUCCESS';
export const PROCEED_TO_CHECKOUT_FAIL = 'PROCEED_TO_CHECKOUT_FAIL';

export const DELETE_LINE_ITEM_BEGIN = 'DELETE_LINE_ITEM_BEGIN';
export const DELETE_LINE_ITEM_SUCCESS = 'DELETE_LINE_ITEM_SUCCESS';
export const DELETE_LINE_ITEM_FAIL = 'DELETE_LINE_ITEM_FAIL';

export const ORDERS_SET_TO_IDLE = 'ORDERS_SET_TO_IDLE';

export const ORDERS_UPDATE_LINE_ITEM_NOTES_SUCCESS =
  'ORDERS_UPDATE_LINE_ITEM_NOTES_SUCCESS';
export const ORDERS_UPDATE_LINE_ITEM_NOTES_FAIL =
  'ORDERS_UPDATE_LINE_ITEM_NOTES_FAIL';

export const ORDERS_EMAIL_CART_BEGIN = 'ORDERS_EMAIL_CART_BEGIN';
export const ORDERS_EMAIL_CART_SUCCESS = 'ORDERS_EMAIL_CART_SUCCESS';
export const ORDERS_EMAIL_CART_FAIL = 'ORDERS_EMAIL_CART_FAIL';

export const QTY_FOR_SAVED_LIST_ID = 'qtyForSavedList_{0}';
export const ORDERS_UPDATE_PARTIAL_REPLACEMENT_PARTS =
  'ORDERS_UPDATE_PARTIAL_REPLACEMENT_PARTS';

export const ORDERS_DISABLE_PROCEED_TO_CHECKOUT_BUTTON =
  'ORDERS_DISABLE_PROCEED_TO_CHECKOUT_BUTTON';

export const ORDERS_REMOVE_ZERO_PRICE_LINE_ITEM =
  'ORDERS_REMOVE_ZERO_PRICE_LINE_ITEM';

export const ORDERS_CLEAR_LINE_ITEM_NOTES_FOCUS =
  'ORDERS_CLEAR_LINE_ITEM_NOTES_FOCUS';

export const ORDERS_UPDATE_STATUS_BEGIN = 'ORDERS_UPDATE_STATUS_BEGIN';
export const ORDERS_UPDATE_STATUS_SUCCESS = 'ORDERS_UPDATE_STATUS_SUCCESS';
export const ORDERS_UPDATE_STATUS_FAIL = 'ORDERS_UPDATE_STATUS_FAIL';

export const SAVED_LIST_UPDATE_QUANTITY = 'SAVED_LIST_UPDATE_QUANTITY';
export const SAVED_LIST_UPDATE_QUANTITY_FAIL =
  'SAVED_LIST_UPDATE_QUANTITY_FAIL';
export const SAVED_LIST_DETAIL_UPDATE_QUANTITY_BEGIN =
  'SAVED_LIST_DETAIL_UPDATE_QUANTITY_BEGIN';

export const SAVED_LIST_DELETE_LINE_ITEM_BEGIN =
  'SAVED_LIST_DELETE_LINE_ITEM_BEGIN';
export const SAVED_LIST_DELETE_LINE_ITEM_SUCCESS =
  'SAVED_LIST_DELETE_LINE_ITEM_SUCCESS';
export const SAVED_LIST_DELETE_LINE_ITEM_FAIL =
  'SAVED_LIST_DELETE_LINE_ITEM_FAIL';

export const ORDERS_SAVE_PO_SUCCESS = 'ORDERS_SAVE_PO_SUCCESS';
export const OFFLINE_ORDER_DETAIL_EXPORT_SUCCESS_FAIL =
  'OFFLINE_ORDER_DETAIL_EXPORT_SUCCESS_FAIL';
export const OFFLINE_ORDER_HISTORY_EXPORT_SUCCESS_FAIL =
  'OFFLINE_ORDER_HISTORY_EXPORT_SUCCESS_FAIL';
export const ORDERS_UPDATE_BUNDLE_ERRORS = 'ORDERS_UPDATE_BUNDLE_ERRORS';

export const ORDERS_DELETE_LINE_ITEM_BUNDLE_BEGIN =
  'ORDERS_DELETE_LINE_ITEM_BUNDLE_BEGIN';
export const ORDERS_DELETE_LINE_ITEM_BUNDLE_FAIL =
  'ORDERS_DELETE_LINE_ITEM_BUNDLE_FAIL';
export const ORDERS_DELETE_LINE_ITEM_BUNDLE_SUCCESS =
  'ORDERS_DELETE_LINE_ITEM_BUNDLE_SUCCESS';

export const ORDERS_REMOVE_BUNDLE_MODAL_ERROR =
  'ORDERS_REMOVE_BUNDLE_MODAL_ERROR';

export const ORDERS_CLEAR_BUNDLE_ERROR = 'ORDERS_CLEAR_BUNDLE_ERROR';

export const SAVED_LIST_UPDATE_LINE_ITEM_NOTE =
  'SAVED_LIST_UPDATE_LINE_ITEM_NOTE';

export const SHIPMENTS_BY_ORDER_ID_SUCCESS = 'SHIPMENTS_BY_ORDER_ID_SUCCESS';
export const SHIPMENTS_BY_ORDER_ID_BEGIN = 'SHIPMENTS_BY_ORDER_ID_BEGIN';
export const SHIPMENTS_BY_ORDER_ID_FAIL = 'SHIPMENTS_BY_ORDER_ID_FAIL';

export const ORDER_FULFILLMENT_INFO_SUCCESS = 'ORDER_FULFILLMENT_INFO_SUCCESS';
export const ORDER_FULFILLMENT_INFO_BEGIN = 'ORDER_FULFILLMENT_INFO_BEGIN';
export const ORDER_FULFILLMENT_INFO_FAIL = 'ORDER_FULFILLMENT_INFO_FAIL';

export const ORDER_TOTAL_SUCCESS = 'ORDER_TOTAL_SUCCESS';
export const ORDER_TOTAL_BEGIN = 'ORDER_TOTAL_BEGIN';
export const ORDER_TOTAL_FAIL = 'ORDER_TOTAL_FAIL';

export const ORDER_INFORMATION_GET_BEGIN = 'ORDER_INFORMATION_GET_BEGIN';
export const ORDER_INFORMATION_GET_FAIL = 'ORDER_INFORMATION_GET_FAIL';
export const ORDER_INFORMATION_GET_SUCCESS = 'ORDER_INFORMATION_GET_SUCCESS';

export const ORDER_CAL_INFO_GET_BEGIN = 'ORDER_CAL_INFO_GET_BEGIN';
export const ORDER_CAL_INFO_GET_FAIL = 'ORDER_CAL_INFO_GET_FAIL';
export const ORDER_CAL_INFO_GET_SUCCESS = 'ORDER_CAL_INFO_GET_SUCCESS';

export const ORDER_BILLING_INFO_BEGIN = 'ORDER_BILLING_INFO_BEGIN';
export const ORDER_BILLING_INFO_SUCCESS = 'ORDER_BILLING_INFO_SUCCESS';
export const ORDER_BILLING_INFO_FAIL = 'ORDER_BILLING_INFO_FAIL';

export const GET_ORDER_ITEM_INFO_BEGIN = 'GET_ORDER_ITEM_INFO_BEGIN';
export const GET_ORDER_ITEM_INFO_SUCCESS = 'GET_ORDER_ITEM_INFO_SUCCESS';
export const GET_ORDER_ITEM_INFO_FAIL = 'GET_ORDER_ITEM_INFO_FAIL';

export const PRODUCT_DETAILS_BEGIN = 'PRODUCT_DETAILS_BEGIN';
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL';

export const ORDER_PRICE_AVAILABILITY_TOTALS_BEGIN =
  'ORDER_PRICE_AVAILABILITY_TOTALS_BEGIN';
export const ORDER_PRICE_AVAILABILITY_TOTALS_SUCCESS =
  'ORDER_PRICE_AVAILABILITY_TOTALS_SUCCESS';
export const ORDER_PRICE_AVAILABILITY_TOTALS_FAIL =
  'ORDER_PRICE_AVAILABILITY_TOTALS_FAIL';

export const DELETE_ORDER_PO_ATTACHMENT = 'DELETE_ORDER_PO_ATTACHMENT';
export const UPDATE_ORDER_PO_ATTACHMENT_SUCCESS =
  'UPDATE_ORDER_PO_ATTACHMENT_SUCCESS';
export const UPDATE_ORDER_PO_ATTACHMENT_BEGIN =
  'UPDATE_ORDER_PO_ATTACHMENT_BEGIN';
export const UPDATE_ORDER_PO_ATTACHMENT_FAIL =
  'UPDATE_ORDER_PO_ATTACHMENT_FAIL';
export const UPDATE_ORDER_PO_ATTACHMENT_RESET =
  'UPDATE_ORDER_PO_ATTACHMENT_RESET';
export const RESET_REPLACED_PARTS_INFO = 'RESET_REPLACED_PARTS_INFO';
export const RESET_REPLACED_PARTS_INFO_STATUS =
  'RESET_REPLACED_PARTS_INFO_STATUS';
export const SET_TOTAL_ITEMS_ADDED_TO_CART = 'SET_TOTAL_ITEMS_ADDED_TO_CART';

export const UPDATE_ORDER_REQUEST_BY_DATE = 'UPDATE_ORDER_REQUEST_BY_DATE';
