import { getHttpInstance } from '../../services/defaultHttpService';
import { replaceTokensInString } from '../../utils';
import endpoints from '../../constants/endpoints';
import * as types from './constants';
import { USER_TYPE_REGISTERED } from '@app/constants/commonConstants';

export const fetchLanguages = () => async (dispatch, getState) => {
  const { storeId } = getState().common;
  const http = getHttpInstance();
  dispatch({ type: types.FOOTER_GET_LANGUAGES_BEGIN });
  const LANGUAGES_URL = replaceTokensInString(endpoints.GET_LANGUAGES, storeId);

  try {
    const { data } = await http.get(LANGUAGES_URL);
    dispatch({ type: types.FOOTER_GET_LANGUAGES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: types.FOOTER_GET_LANGUAGES_FAIL });
  }
};

export const changeLanguage = selectedLanguageInfo => (dispatch, getState) => {
  const {
    storeId,
    userType,
    langId,
    userId,
    isBuyOnBehalf = false
  } = getState().common;
  const { languageId: selectedLangId, href } = selectedLanguageInfo;
  const isUserTypeRegistered = userType === USER_TYPE_REGISTERED;

  dispatch({ type: types.FOOTER_CHANGE_LANGUAGE_BEGIN });

  const http = getHttpInstance();
  const apiList = [];

  // Update language to create cookies
  const updateLanguageUrl = replaceTokensInString(
    endpoints.UPDATE_LANGUAGE,
    storeId,
    langId
  );
  apiList.push(http.put(updateLanguageUrl, { selectedLangId }));

  // Update user preferences
  if (isUserTypeRegistered) {
    let updateUserPreferencesLanguageUrl = replaceTokensInString(
      endpoints.PREFERENCES_UPDATE,
      storeId,
      langId
    );
    updateUserPreferencesLanguageUrl +=
      isBuyOnBehalf && userId ? `&forUserId=${userId}` : '';
    const payload = { updateFooterLang: true, selectedLangId };
    apiList.push(http.put(updateUserPreferencesLanguageUrl, payload));
  }

  return Promise.all(apiList)
    .then(() => {
      dispatch({ type: types.FOOTER_CHANGE_LANGUAGE_SUCCESS });
    })
    .catch(e => {
      dispatch({ type: types.FOOTER_CHANGE_LANGUAGE_FAIL });
    })
    .finally(() => (window.location.href = href));
};

export const fetchSocialLinks = () => async (dispatch, getState) => {
  const { storeId, langId } = getState().common;
  const http = getHttpInstance();
  dispatch({ type: types.FOOTER_GET_SOCIAL_LINKS_BEGIN });
  const SOCIAL_LINKS_URL = replaceTokensInString(
    endpoints.GET_SOCIAL_LINKS,
    storeId,
    langId
  );

  try {
    const { data } = await http.get(SOCIAL_LINKS_URL);
    dispatch({ type: types.FOOTER_GET_SOCIAL_LINKS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: types.FOOTER_GET_SOCIAL_LINKS_FAIL });
  }
};
