import PropTypes from 'prop-types';

export const DrawerType = PropTypes.shape({
  initiatorProps: PropTypes.shape({
    'aria-controls': PropTypes.string,
    'aria-expanded': PropTypes.bool,
    onClick: PropTypes.func
  }),
  drawerProps: PropTypes.shape({
    align: PropTypes.string,
    hasBackdrop: PropTypes.bool,
    id: PropTypes.string,
    isActive: PropTypes.bool,
    onBlDrawerClose: PropTypes.func,
    drawerRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    size: PropTypes.string
  }),
  drawerControl: PropTypes.shape({
    isActive: PropTypes.bool,
    open: PropTypes.func,
    close: PropTypes.func
  }),
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func
});

export const ModalType = PropTypes.shape({
  initiatorProps: PropTypes.shape({
    onClick: PropTypes.func
  }),
  modalProps: PropTypes.shape({
    isActive: PropTypes.bool,
    onBlModalClose: PropTypes.func,
    // hideCloseButton: isRequired,
    disableBackdropClick: PropTypes.bool,
    disableEscClick: PropTypes.bool,
    heading: PropTypes.string,
    size: PropTypes.string,
    backButton: PropTypes.bool
  }),
  modalControl: PropTypes.shape({
    isActive: PropTypes.bool,
    open: PropTypes.func,
    close: PropTypes.func
  })
});

export const FormMethodsType = PropTypes.shape({});

export const EndUseCode = PropTypes.shape({
  default: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string
});

export const OrderType = PropTypes.shape({
  default: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string
});

export const StoreLocation = PropTypes.shape({
  default: PropTypes.bool,
  endUseCode: PropTypes.arrayOf(EndUseCode),
  label: PropTypes.string,
  orderType: PropTypes.arrayOf(OrderType),
  value: PropTypes.string
});

export const CustomerNumber = PropTypes.shape({
  default: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string
});

export const Dealer = PropTypes.shape({
  default: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  isCSPCustomer: PropTypes.bool,
  customerNumber: PropTypes.arrayOf(CustomerNumber)
});
