import PropTypes from 'prop-types';
import { NavMenuLink } from 'cat-ecommerce-alloy';
import styles from './CategoriesNavItem.module.scss';
import cx from 'classnames';

const CategoriesNavItem = ({ name, redirectUrl }) => {
  return (
    <NavMenuLink
      className={cx(styles['sidenav-menu-item-wrapper'], 'mb-1')}
      linkClassName={cx(
        styles['sidenav-menu-item'],
        'text-uppercase text-break'
      )}
      key={name}
      href={redirectUrl}
      showArrow
      aria-label={name}
    >
      {name}
    </NavMenuLink>
  );
};

CategoriesNavItem.propTypes = {
  name: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired
};

export default CategoriesNavItem;
