import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  StoreHoursInfo,
  StoreHoursInfoDetails
} from '@cat-ecom/pcc-components';
import { getStoreHours } from '@app/components/dealer-locator/DealerLocatorModal/utils';
import { isEmpty } from '@app/utils';

const StoreHoursWrapper = () => {
  const [t] = useTranslation();
  const storeHours = useSelector(s => s.store?.storeHours) ?? [];
  const storeHoursInfoDetails = getStoreHours(storeHours);
  const isStoreAvailable24Hrs = useSelector(s => s.store?.alwaysOpen);
  const isStoreClosed =
    !isEmpty(storeHours) && storeHours.every(({ value }) => value === 'Closed');

  return (
    <div className="my-3">
      <StoreHoursInfo
        isStoreAvailable24Hrs={isStoreAvailable24Hrs}
        isStoreClosed={isStoreClosed}
        open24HoursTitle={t('OPEN_24_HOURS_7_DAYS_A_WEEK')}
        title={`${t('STORE_HOURS')}:`}
      >
        <div className="mt-2">
          {storeHoursInfoDetails.map(val => (
            <StoreHoursInfoDetails
              key={val.label}
              storeHoursInfoDetails={val}
            />
          ))}
        </div>
      </StoreHoursInfo>
    </div>
  );
};

export default StoreHoursWrapper;
