import queryString from 'query-string';

/**
 * Map an object to a query params url shape
 * @param {Object} obj Object with the query params mapping
 * @returns {string} Query string with the params
 * @example
 *  getObjectAsQueryParams({
 *     key1: 'val1',
 *     key2: 'val2',
 *     key3: 'val3'
 *  }) // "key1=val1&key2=val2&key3=val3"
 */
export const getObjectAsQueryParams = obj =>
  Object.entries(obj)
    .filter(([key, val]) => key && val)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');

/**
 * util to get search and use util to convert search params to object
 * @returns object
 * @example ?ham=sandwich&soda=pop -> { ham="sandwich", soda: "pop" }
 */
export const getQueryParamsAsObject = () => {
  const searchString = window.location.search || '';
  return queryString.parse(searchString, { decode: false });
};
