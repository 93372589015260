import * as types from './constants';
import initialStore from '../initialStore';

const homePageReducer = (state = initialStore.homepageUI, action) => {
  switch (action.type) {
    case types.SET_PRODUCT_INFO: {
      const { payload } = action;
      return {
        ...state,
        [payload.sourceKey]: {
          products: payload.products,
          viewMoreUrl: payload.viewMoreUrl,
          productTotalCount: payload.productTotalCount
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default homePageReducer;
