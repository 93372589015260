import * as types from './constants';
import endpoints from '../../constants/endpoints';
import { getHttpInstance } from '../../services/defaultHttpService';
import { replaceTokensInUrl } from '../../utils';
import { NOT_AUTHORIZED_FOR_QUERY } from '@app/constants/errorConstants';

export const getTaxDetail = (userId, storeId) => async (dispatch, getState) => {
  const http = getHttpInstance();
  const url = replaceTokensInUrl(endpoints.GET_TAX_DETAIL, storeId, userId);
  dispatch({ type: types.GET_TAX_DETAIL_BEGIN });
  try {
    const { data } = await http.get(url);
    if (!data.logonId) {
      return dispatch({
        type: types.GET_TAX_DETAIL_FAIL,
        payload: { isNotAuthorizedUser: true }
      });
    }
    dispatch({
      type: types.GET_TAX_DETAIL_SUCCESS,
      payload: { data } ?? {}
    });
    return data;
  } catch (error) {
    if (
      error?.response?.data?.errors[0]?.errorKey === NOT_AUTHORIZED_FOR_QUERY
    ) {
      return dispatch({
        type: types.GET_TAX_DETAIL_FAIL,
        payload: { isNotAuthorizedUser: true }
      });
    }
    dispatch({
      type: types.GET_TAX_DETAIL_FAIL,
      payload: { isNotAuthorizedError: false }
    });
  }
};
