import { STATUS } from '../../constants/commonConstants';
import {
  defaultOrderStatus,
  defaultFilterByDateStatus
} from '../../components/pages/account/common/OrdersToApproveTable/constants';

export const ordersToApproveInitialStore = {
  orders: {},
  searchFormValues: {
    dateRange: {},
    orderNumber: null,
    filterByDate: defaultFilterByDateStatus,
    orderStatus: defaultOrderStatus,
    priceRangeMin: null,
    priceRangeMax: null,
    submitterFirstName: '',
    submitterLastName: '',
    dcn: ''
  },
  searchStatus: STATUS.IDLE,
  deleteStatus: STATUS.IDLE,
  ordersToApproveErrorDismiss: false,
  ordersProperties: {
    ordersPendingToApproveNumberStatus: STATUS.IDLE,
    ordersPendingToApproveNumber: 0
  },
  approveRejectStatus: STATUS.IDLE
};
