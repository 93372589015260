import { STATUS } from '../../constants/commonConstants';

export const ordersInitialStore = {
  byId: {},
  current: '',
  recentOrders: [],
  cacheOrderHistory: {},
  hasPriceData: false,
  hasOrderData: false,
  hasOrderWithPrice: false,
  emptyingCartStatus: STATUS.IDLE,
  updatingStatus: STATUS.IDLE,
  getCurrentOrderStatus: STATUS.IDLE,
  shipmentsStatus: STATUS.IDLE,
  isERPDown: false,
  isOrderPriceAvailabilityLoading: false,
  isPriceLoadComplete: false,
  replacedPartsInfo: {},
  replacedParts: [],
  totalItemsAddedToCart: 0,
  taxDisclaimerMessageFooter: '',
  taxDisclaimerMessage: '',
  taxExcludedDisclaimerMessage: '',
  currentGuestOrder: ''
};
