export const SAVED_LIST_DETAIL_GET_BEGIN = 'SAVED_LIST_DETAIL_GET_BEGIN';
export const SAVED_LIST_DETAIL_GET_SUCCESS = 'SAVED_LIST_DETAIL_GET_SUCCESS';
export const SAVED_LIST_DETAIL_GET_FAIL = 'SAVED_LIST_DETAIL_GET_FAIL';
export const ITEM_NOTES_UPDATE_BEGIN = 'ITEM_NOTES_UPDATE_BEGIN';
export const ITEM_NOTES_UPDATE_SUCCESS = 'ITEM_NOTES_UPDATE_SUCCESS';
export const ITEM_NOTES_UPDATE_FAIL = 'ITEM_NOTES_UPDATE_FAIL';
export const SAVED_LIST_DETAIL_ADD_ITEM_BEGINS =
  'SAVED_LIST_DETAIL_ADD_ITEM_BEGINS';
export const SAVED_LIST_DETAIL_ADD_ITEM_SUCCESS =
  'SAVED_LIST_DETAIL_ADD_ITEM_SUCCESS';
export const SAVED_LIST_DETAIL_ADD_ITEM_FAIL =
  'SAVED_LIST_DETAIL_ADD_ITEM_FAIL';
export const SAVED_LIST_DETAIL_PATH_STRING = 'RequisitionListDetailView';
export const SAVED_LIST_IMPORT_ITEM_BEGIN = 'SAVED_LIST_IMPORT_ITEM_BEGIN';
export const SAVED_LIST_IMPORT_ITEM_SUCCESS = 'SAVED_LIST_IMPORT_ITEM_SUCCESS';
export const SAVED_LIST_IMPORT_ITEM_FAIL = 'SAVED_LIST_IMPORT_ITEM_FAIL';
