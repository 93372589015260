import PropTypes from 'prop-types';
import { getIconSizeNumber } from '../utils';

const PreviousOrderIcon = ({ size }) => {
  const sizeNumber = getIconSizeNumber(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={sizeNumber}
      height={sizeNumber}
      viewBox={`0 0 ${sizeNumber} ${sizeNumber}`}
      fill="none"
    >
      <g clipPath="url(#clip0_1_5038)">
        <path
          d="M3.19412 10.1577C3.40433 10.4338 3.67799 10.6551 3.99192 10.8029C4.30585 10.9508 4.6508 11.0208 4.99753 11.007C6.10306 11.007 6.99991 10.3343 6.99991 9.50542C6.99991 8.67651 6.10597 8.00485 5.00141 8.00485C3.89685 8.00485 3 7.33221 3 6.50234C3 5.67246 3.89685 5.00079 5.00141 5.00079C5.34817 4.98674 5.6932 5.05661 6.00717 5.20447C6.32115 5.35233 6.59478 5.57381 6.80482 5.85008"
          stroke="black"
          strokeWidth="1.45592"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.00146 11.0068V12.0075"
          stroke="black"
          strokeWidth="1.45592"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.00146 4V5.00071"
          stroke="black"
          strokeWidth="1.45592"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 5.75H14"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 9.25H14"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 19.45C12 19.45 14.3 16 17.75 16C21.2 16 23.5 19.45 23.5 19.45C23.5 19.45 21.2 22.9 17.75 22.9C14.3 22.9 12 19.45 12 19.45Z"
          stroke="black"
          strokeWidth="1.15"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.1875 19.45C19.1875 18.656 18.5439 18.0125 17.75 18.0125C16.9561 18.0125 16.3125 18.656 16.3125 19.45C16.3125 20.2439 16.9561 20.8875 17.75 20.8875C18.5439 20.8875 19.1875 20.2439 19.1875 19.45Z"
          stroke="black"
          strokeWidth="1.15"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 13.5V1.5C18 1.10218 17.842 0.72064 17.5607 0.43934C17.2794 0.158035 16.8978 0 16.5 0H1.5C1.10218 0 0.72064 0.158035 0.43934 0.43934C0.15804 0.72064 0 1.10218 0 1.5V22.5L3 19.5L6 22.5L9 19.5"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_5038">
          <rect width="23.5" height="22.9" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

PreviousOrderIcon.propTypes = {
  size: PropTypes.string
};

export default PreviousOrderIcon;
