import { useCallback, useRef } from 'react';
import { stickyScroll } from '@app/utils/stickyScroll';

const useScrollTo = () => {
  const ref = useRef(null);
  const includeNodeHeight = false;
  const scroll = useCallback(() => {
    stickyScroll(ref, includeNodeHeight);
  }, []);
  return { ref, scroll };
};

export default useScrollTo;
