export const FULLSIZE_PRESET = 'pcc-pdp2';
export const CAROUSEL_FALLBACK_URL = `https://caterpillar.scene7.com/is/image/Caterpillar/CM20170508-76204-57647?$${FULLSIZE_PRESET}$`;
export const CAROUSEL_LIMIT = 6;
export const IMAGEPICKER_FALLBACK_URL =
  'https://caterpillar.scene7.com/is/image/Caterpillar/CM20170508-76204-57647';
export const PRODUCT_IMAGE_SIZE = '355px';
export const PRODUCT_IMAGE_SIZE_SMALL = '180px';
export const PROP_65_WARNING = 'Prop 65 Warning';
export const MAX_QUANTITY = '9999';
export const MIN_QUANTITY = '0';
export const TOP_ESPOT_NAME = 'CAT_BUNDLES_EMARKETINGSPOT_TOP';
export const BOTTOM_ESPOT_NAME = 'CAT_BUNDLES_EMARKETINGSPOT_BOTTOM';
export const TOTAL_PRICE_KEY = 'totalPrice';
export const DISCOUNTED_PRICE_KEY = 'discountedPrice';
export const ADD_ALL_TO_CART = 'addAlltoCart';
export const PARTS_LIST = 'partsList';
export const RECOMMENDED_TOOLS = 'recommendedTools';
export const RELATED_PARTS = 'relatedParts';
export const CATCORP_ESITE_URL = '/en/catcorp';
export const FLUID_PART_QUANTITY = '1';
export const PARTS_LABEL = 'parts';
export const PARTS_STEPS_LABEL = 'partsAndSteps';
export const STEPS_LABEL = 'steps';
export const PRODUCT_IMAGE_SIZE_TABLET = '20rem';
export const PRODUCT_GRID_IMAGE_SIZE = '28rem';
export const THUMBNAIL_IMAGE_SIZE = '96px';
export const THUMBNAIL_IMAGE_SIZE_TABLET = '72px';
export const THUMBNAIL_POPUP_IMAGE_SIZE = '240px';
export const IMAGE_TAB_INDEX = '0';
export const FALLBACK_MOUNTAIN_IMAGE_SIZE = '489px';
export const SHOPPING_CART_ICON_WIDTH = 16;
export const SHOPPING_CART_ICON_HEIGHT = 15;
export const HTML_TAG_REGEX = /<(?:[^>]+)>/gi;
