import endpoints from '../../constants/endpoints';
import { getObjectAsQueryParams } from '../../components/../utils';
import { sortAlphabeticByAttribute } from '../../utils';
import {
  BUCKET_MODEL_NUMBER_FACET_ID,
  STATUS,
  UOM,
  USER_TYPE_GUEST
} from '../../constants/commonConstants';
import { getPriceAndAvailability } from '../products/actions';
import { getModernizedEquipmentFitment } from '../myequipment/actions';
import { getFitmentCheck } from '../../components/common/analytics/analyticsUtils';
import { PLP_CATEGORY_GA_LIST_NAME } from '../../constants/analyticsConstants';
import { ERROR_DOMAIN, ERROR_PATH } from '../../constants/errorConstants';
import { clearError } from '../exception/actions';
import * as types from './constants';
import { isEmpty } from '@app/utils';
import { hasBothMetricAndUSSpecs } from '../products/utils';
import { getUnitOfMeasureCookie } from '@app/components/common/UnitOfMeasureToggle/utils';
import { cloneDeep } from 'lodash';
import * as myEquipmentTypes from '../../store/myequipment/constants';

export const normalizeHashFacets = facets => {
  if (Array.isArray(facets) || !facets) {
    return facets;
  }
  return [facets];
};

export const createURLWithParams = (state, params = {}, parsedHash = {}) => {
  const { storeIdentifier, locale, storeId, langId } = state.common ?? {};
  const { current, sortBy, activeFacets, filterByEquipment } =
    state.plpUI ?? {};
  const { model, serialNumber } =
    state.myEquipment?.equipments?.selectedEquipment ?? {};
  const normalizedHashFacets = normalizeHashFacets(parsedHash.facets);

  const facet = (
    normalizedHashFacets ?? activeFacets?.map(({ facet }) => facet)
  )?.join('&facet=');
  const queryParams = getObjectAsQueryParams({
    ...params,
    ...((parsedHash.filterByEquipment ?? filterByEquipment) && model
      ? { model, serialNumber }
      : {}),
    sortBy: parsedHash.sortBy ?? sortBy,
    facet,
    urlKeyword: current,
    storeIdentifier: encodeURIComponent(storeIdentifier),
    locale,
    storeId,
    langId
  });

  return `${endpoints.PLP_DETAILS}?${queryParams}`;
};

/**
 * Function format the facets array when these comes with a dash (subcategories)
 * @param {{facet: string, label: string, count: number}[]} facets array of facets
 * @returns Array with formated facets
 */
export const handleSubspecifications = facets => {
  const sortedFacets = sortAlphabeticByAttribute(facets, 'label');
  const subspecifications = [
    ...new Set(sortedFacets.map(({ label }) => label.split(' - ')[0]))
  ];
  return subspecifications.map(subspecificationValue => ({
    [subspecificationValue]: sortedFacets
      .filter(({ label }) => {
        return label.split(' - ')[0] === subspecificationValue;
      })
      .map(({ count, facet, label }) => ({
        count,
        facet: decodeURIComponent(facet),
        label: label.split(' - ')[1] ?? label.split(' - ')[0]
      }))
  }));
};

/**
 * Function to fire and await P&A and fitment data for PLP
 * @param {object} state most updated Redux state
 * @param {function} dispatch
 * @param {object} data information received from the plpDetails service
 * @returns {object} priceAndAvailability, fitment and dealerCustomer data
 * to be used in GA events
 */
export const firePriceAndFitmentCalls = async (getState, dispatch, data) => {
  let priceResponse;
  const state = getState();
  const {
    isCatCorp,
    selectedStore = '',
    isInstantAccess,
    userType
  } = state.common;
  const {
    priceAndAvailibiltyStatus,
    equipmentFitmentStatus,
    partsWithFitmentData = {},
    filterByEquipment
  } = state.plpUI;
  const isGuest = userType === USER_TYPE_GUEST;
  const isGuestNonIA = isGuest && !isInstantAccess;
  const { isFilterByEquipmentEnabled, products: plpProducts } = data;
  const { priceAndAvailability = {}, byId: productsById } = state.products;
  const productsArr = Object.values(productsById);

  const { serialNumber } =
    state.myEquipment?.equipments?.selectedEquipment || {};
  const shouldFireFitment =
    serialNumber && isFilterByEquipmentEnabled && filterByEquipment;

  let partNumbersForFitment;
  if (!isCatCorp && selectedStore && !isGuestNonIA) {
    let entries = cloneDeep(plpProducts);
    // if this status is rejected the previous call failed. We need to fetch data for failed entries along with new entries
    if (priceAndAvailibiltyStatus === STATUS.REJECTED) {
      dispatch(clearError(ERROR_DOMAIN.PLP, ERROR_PATH.MAIN));
      const existingEntriesWithMissingPriceData = productsArr.filter(
        entry => !priceAndAvailability[entry.partNumber]
      );
      entries = [...existingEntriesWithMissingPriceData, ...entries];
    }
    const partsList = entries.map(ce => ({
      partNumber: ce.partNumber,
      quantity: 1
    }));
    priceResponse = await dispatch(
      getPriceAndAvailability({
        partsList,
        namespace: 'priceAndAvailability',
        statusActionPrefix: 'PLP',
        errorInfo: types.ERROR_INFO,
        useCacheService: true,
        attributeId: types.PRICE_AVAILABILITY_ATTRIBUTE_ID
      })
    );
  }
  if (shouldFireFitment) {
    let entries = cloneDeep(plpProducts);
    // if this status is rejected the previous call failed. We need to fetch data for failed entries along with new entries
    if (equipmentFitmentStatus === STATUS.REJECTED) {
      const existingEntriesWithMissingFitmentData = productsArr.filter(
        entry => !partsWithFitmentData[entry.partNumber]
      );
      dispatch(clearError(ERROR_DOMAIN.PLP, ERROR_PATH.MAIN));
      entries = [...existingEntriesWithMissingFitmentData, ...entries];
    }
    partNumbersForFitment = entries.map(ce => ce.partNumber);
    await dispatch(
      getModernizedEquipmentFitment({
        partNumbers: partNumbersForFitment,
        type: myEquipmentTypes.PLP_GET_EQUIPMENT_FITMENT_SET_STATUS,
        errorInfo: types.ERROR_INFO
      })
    );
  }

  return {
    priceAndAvailability: priceResponse?.priceAndAvailability,
    dealerCustomer: priceResponse?.dealerCustomer,
    partNumbersForFitment
  };
};

const maskPersonalInfo = info =>
  info
    .split(' ')
    .map(item => (item.includes('@') ? 'xxx@xx.xxx' : item))
    .join(' ');

/**
 * Function to create the product details object for GA events
 * @param {object[]} entries product list received from plpDetails service
 * @param {object} priceAndAvailability data received from P&A service
 * @param {object} fitmentData data received from fitment service
 * @param {object} state most updated state
 * @returns product details object to be used in GA events
 */
export const getProductDetails = (
  entries,
  priceAndAvailability = {},
  fitmentData,
  getState
) => {
  const state = getState();
  const serialNumber =
    state.myEquipment.equipments.selectedEquipment?.serialNumber;
  const filterByEquipment = state.plpUI.filterByEquipment;
  const shouldGetFitmentCheck =
    filterByEquipment && serialNumber && fitmentData;
  return entries.map((part, index) => ({
    availability: priceAndAvailability?.[
      part.partNumber
    ]?.availability?.includes('@')
      ? maskPersonalInfo(priceAndAvailability?.[part.partNumber]?.availability)
      : priceAndAvailability?.[part.partNumber]?.availability,
    brand: part.manufacturer,
    category: part.categoryPathList?.[0],
    checkFitment: shouldGetFitmentCheck
      ? getFitmentCheck(part.partNumber, fitmentData)
      : '',
    partNumber: part.partNumber,
    name: part.name,
    position: index + 1,
    price: priceAndAvailability?.[part.partNumber]?.unformattedUnitPrice,
    productWeight: priceAndAvailability?.[part.partNumber]?.weight,
    quantity: 1,
    relatedModel: state.myEquipment.equipments.selectedEquipment?.model
  }));
};

export const handlePLPDetailsResults = async (state, dispatch, data) => {
  const { priceAndAvailability, dealerCustomer, partNumbersForFitment } =
    await firePriceAndFitmentCalls(state, dispatch, data);
  const fitmentData =
    await state().myEquipment.equipments.selectedEquipment
      .serialMatchPartNumbers;
  if (fitmentData && partNumbersForFitment) {
    const payload = partNumbersForFitment.reduce((acc, pn) => {
      acc[pn] = true;
      return acc;
    }, {});
    dispatch({ type: types.PLP_SET_PARTS_WITH_FITMENT_DATA, payload });
  }

  return {
    productDetails: getProductDetails(
      data?.products,
      priceAndAvailability,
      fitmentData,
      state
    ),
    currencyCode: dealerCustomer?.currency,
    gaListName: PLP_CATEGORY_GA_LIST_NAME
  };
};

/**
 * Function to convert url hash to params object to store in redux for plp domain
 * @param {object} param
 * @param {object} param.parsedHash object of parsed hash from url
 * @param {object} param.data object of data returned from plpDetail fetch
 * @returns object to store in redux for plp domain
 */

export const getParamsForReduxFromHash = ({ parsedHash, data }) => {
  const { filterByEquipment, facets, sortBy } = parsedHash;
  const params = {
    filterByEquipment: filterByEquipment ?? true,
    ...(sortBy ? { sortBy } : {})
  };
  if (!isEmpty(facets) && !isEmpty(data.facets)) {
    const normalizedFacets = Array.isArray(facets) ? facets : [facets];
    const facetsAsObject = normalizedFacets.reduce((acc, f) => {
      acc[f] = true;
      return acc;
    }, {});
    params.activeFacets = data.facets.reduce((acc, f) => {
      const addFacet = (facet, skipDecode = false) => {
        const decodedFacet = skipDecode
          ? facet.facet
          : decodeURIComponent(facet.facet);
        if (facetsAsObject[decodedFacet]) {
          acc.push({
            facet: decodedFacet,
            label: facet.label,
            uom: f.uom ?? UOM.null,
            groupLabel: facet.groupLabel ?? f.label
          });
        }
      };
      //check if there are subfacets
      if (!isEmpty(f.value[0]?.values)) {
        f.value.forEach(value => {
          value.values.map(subfacet =>
            addFacet({ ...subfacet, groupLabel: value.label }, true)
          );
        });
      }
      f.value.forEach(value => {
        addFacet(value);
      });
      return acc;
    }, []);
    if (
      !isEmpty(params.activeFacets) &&
      params.activeFacets.some(f => f.uom === UOM.metric)
    ) {
      params.unitOfMeasure = UOM.metric;
    }
  }
  return params;
};

/**
 * Function remove the bucket model number from the facets array
 * @param {string} isBucketGetSubcategory isBucketGetSubcategory constant
 * @param {{facet: string, label: string, count: number}[]} facetsArray facets array
 * @returns facets array without bucket model number subcategory if it exists
 */
export const removeBucketFromFacetsArray = (
  isBucketGetSubcategory,
  facetsArray
) => {
  if (isBucketGetSubcategory) {
    return facetsArray.filter(
      ({ facetId }) => facetId !== BUCKET_MODEL_NUMBER_FACET_ID
    );
  } else {
    return facetsArray;
  }
};

/**
 * This function is meant to normalize the data with the criteria needed
 * before storing it in the categories domain
 * @param {object} data category data to be normalized
 * @param {boolean} shouldSortFacets determines if facets should be sorted on front-end or not
 * @returns {object} category data normalized with next criteria:
 * subcategories sorted alphabetically,
 * showSpecificationsToogle added to object and
 * facets sorted alphabetically
 */
export const normalizeCategoryDetails = (data, shouldSortFacets) => {
  const bucketModelNumberFacet = data?.facets?.find(
    ({ facetId }) => facetId === BUCKET_MODEL_NUMBER_FACET_ID
  );
  const isBucketGetSubcategory = !isEmpty(bucketModelNumberFacet);
  const bucketModelNumber =
    isBucketGetSubcategory &&
    bucketModelNumberFacet?.displayType === 'input' &&
    bucketModelNumberFacet?.value;
  return {
    ...data,
    subcategories: sortAlphabeticByAttribute(data.subcategories, 'name'),
    showSpecificationsToggle: data.facets
      ? hasBothMetricAndUSSpecs(data.facets)
      : false,
    bucketModelNumber,
    facets: sortAlphabeticByAttribute(
      removeBucketFromFacetsArray(isBucketGetSubcategory, data?.facets),
      'label'
    ).map(facet => {
      const sortedFacets = shouldSortFacets
        ? sortAlphabeticByAttribute(facet.value, 'label')
        : facet.value;
      return {
        ...facet,
        facetGroupShouldBeDisabled: facet.value?.every(
          ({ count }) => count === '0'
        ),
        value: sortedFacets.map(
          ({ count, facet: subfacet, label, values }) => ({
            count,
            facet: decodeURIComponent(subfacet),
            label,
            uom: facet.uom ?? UOM.null,
            groupLabel: facet.label,
            ...(!isEmpty(values) && { values })
          })
        )
      };
    })
  };
};

/**
 * This function is meant to get the unitOfMeasure
 * @param {object} param
 * @param {string} param.defaultPlpUom defaultUnitOfMeasure from PLP
 * @param {string || null} param.dealerUnitOfMeasure defaultUnitOfMeasure from common Store (DMT)
 * @param {string || null} param.shoppingPreferencesUom defaultUnitOfMeasure from shoppingPreferencesUom Store
 * @returns {string} unitOfMeasure
 */
export const getUnitOfMeasure = ({
  shoppingPreferencesUom,
  dealerUnitOfMeasure,
  defaultPlpUom = UOM.us
}) => {
  const cookieUnitOfMeasure = getUnitOfMeasureCookie();

  const getDealerUnitOfMeasure = () => {
    if (dealerUnitOfMeasure) {
      if (dealerUnitOfMeasure.toLowerCase().includes(UOM.metric)) {
        return UOM.metric;
      }
      return UOM.us;
    }
    return null;
  };

  return (
    cookieUnitOfMeasure ??
    shoppingPreferencesUom ??
    getDealerUnitOfMeasure() ??
    defaultPlpUom
  );
};

export const getFacetsWithSubValues = facetValues =>
  !isEmpty(facetValues?.[0]?.values)
    ? facetValues?.map(({ count, facet: subfacet, label, values }) => ({
        count,
        facet: decodeURIComponent(subfacet),
        label,
        groupLabel: label,
        values: values?.map(value => ({ ...value, groupLabel: label }))
      }))
    : facetValues;
