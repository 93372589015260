export const getDealerUserSearchGAFormContent = values =>
  Object.entries(values)
    .map(([key, value]) => {
      if (value === '') {
        return null;
      } else if (key === 'customerName') {
        return 'customer number/name';
      } else if (key === 'zipCode') {
        return 'zipcode';
      } else {
        return key;
      }
    })
    .filter(k => k !== 'customerNumber' && k !== null)
    .join(', ');
