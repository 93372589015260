import { useContext, lazy } from 'react';
import { createPortal } from 'react-dom';
import { ModalContext } from 'cat-ecommerce-alloy';
import {
  SUB_CAT_ADD_EQUIPMENT,
  SUB_CAT_DISPLAY_EQUIPMENT,
  SUB_CAT_SELECT_EQUIPMENT
} from '../../../constants/targets';
import { useSelector } from 'react-redux';
import useEquipmentDrawer from '@app/hooks/useEquipmentDrawer';

const AddEquipmentCTA = lazy(() =>
  import(
    /* webpackChunkName: 'addEquipmentCTA' */ './equipmentPanel/AddEquipmentCTA'
  )
);
const SelectEquipmentCTA = lazy(() =>
  import(
    /* webpackChunkName: 'selectEquipmentCTA' */ './equipmentPanel/SelectEquipmentCTA'
  )
);
const DisplayEquipment = lazy(() =>
  import(
    /* webpackChunkName: 'displayEquipment' */ './equipmentPanel/DisplayEquipment'
  )
);

const MyEquipmentHeaderWrapper = () => {
  const storeId = useSelector(s => s.common.storeId);
  const selectedEquipment = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment
  );
  const isFilteredByEquipment = useSelector(
    s => s.myEquipment?.isFilteredByEquipment
  );
  const initialLoading = useSelector(s => s.myEquipment?.initialLoading);
  const isLoading = useSelector(s => s.myEquipment?.isLoading);
  const myEquipmentList = useSelector(
    s => s.myEquipment.equipments?.myEquipmentList
  );

  const modalCtx = useContext(ModalContext);
  const { openAddDrawer, openEditDrawer, getDrawerComponent } =
    useEquipmentDrawer();
  const addEquipmentTarget = document.getElementById(SUB_CAT_ADD_EQUIPMENT);
  const displayEquipmentTarget = document.getElementById(
    SUB_CAT_DISPLAY_EQUIPMENT
  );
  const selectEquipmentTarget = document.getElementById(
    SUB_CAT_SELECT_EQUIPMENT
  );

  // we don't want to show this loader if a modal is open
  const loading =
    (isLoading || initialLoading) && (modalCtx.modals || []).length === 0;

  if (addEquipmentTarget) {
    return createPortal(
      <AddEquipmentCTA storeId={storeId} loading={loading} />,
      addEquipmentTarget
    );
  }

  if (selectEquipmentTarget) {
    return createPortal(
      <>
        <SelectEquipmentCTA
          selectedEquipment={selectedEquipment}
          myEquipmentList={myEquipmentList}
          loading={loading}
          openAddDrawer={openAddDrawer}
        />
        {getDrawerComponent()}
      </>,
      selectEquipmentTarget
    );
  }

  if (displayEquipmentTarget) {
    return createPortal(
      <>
        <DisplayEquipment
          selectedEquipment={selectedEquipment}
          isFilteredByEquipment={isFilteredByEquipment}
          loading={loading}
          openEditDrawer={openEditDrawer}
        />
        {getDrawerComponent({ currentEquipment: selectedEquipment })}
      </>,
      displayEquipmentTarget
    );
  }

  return null;
};

export default MyEquipmentHeaderWrapper;
