/**
 * Use when throwing an exception while rending a component. This type
 * of exception accepts an object containing a title, message, and code which will
 * display iside of a SystemFeedback block by the nearest ExceptionBoundary
 *
 * @extends Error
 * @example throw new RenderException({ code: '123', message: 'My message', title: 'My Title' });
 */
class RenderException extends Error {
  constructor(error, ...params) {
    super(params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RenderException);
    }

    const { title, message, code } = error;

    this.name = 'RenderException';
    this.title = title;
    this.message = message;
    this.code = code;
  }
}

export default RenderException;
