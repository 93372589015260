import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './CSRBannerBlocksWhite.module.scss';

const CSRBannerStripWhite = ({ variant = 'default', children }) => {
  return (
    <div
      className={cx({
        [styles['CSRBannerStrip__hidden_height']]: variant === 'default',
        [styles['CSRBannerStrip__small_height']]: variant === 'small'
      })}
    >
      <div
        className={cx(
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'py-2',
          styles['CSRBannerStrip']
        )}
        style={{ gap: '16px' }}
      >
        {children}
      </div>
    </div>
  );
};

CSRBannerStripWhite.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['default', 'small'])
};

export default CSRBannerStripWhite;
