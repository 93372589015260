import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useReplaceTokenWithElement } from '@app/hooks';
import { AssociatedDealerSearchResultsForm } from '../AssociatedDealerSearchResultsForm';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import {
  Conditional,
  Drawer,
  DrawerBackHeader,
  DrawerBody,
  DrawerFooter,
  AssociatedDealerBanner
} from '@cat-ecom/pcc-components';
import styles from '../../styles.module.scss';
import cx from 'classnames';
import { CatLoadingIndicator } from 'blocks-react/bedrock/components/LoadingIndicator';
import { ERRORS } from '../../constants';
import { useEffect, useRef } from 'react';

export const AssociatedDealerSearchResultsDrawer = ({
  drawer,
  formMethods,
  onSubmit,
  searchResults,
  loading,
  error,
  className,
  hideCloseButton = false
}) => {
  const [t] = useTranslation();
  const { replaceTokenWithElement } = useReplaceTokenWithElement();
  const drawerRef = useRef();
  const title = replaceTokenWithElement(
    t('GENERAL_BACK_TO'),
    'm-0',
    t('SEARCH_RESULTS_TEXT1')
  );

  const errorMessage = () => {
    if (error === ERRORS.NO_VALID_DCN_NO_STORES) {
      const noStores = t('VALID_DCN_NO_STORES');
      const noStoreSysAdmin = t('VALID_DCN_NO_STORES_SYS_ADMIN');
      return noStores + ' ' + noStoreSysAdmin;
    }
  };

  // TODO: delete this when implementing modals on modals
  // * when you put a drawer inside a modal, and you try to do scrollIntoView,
  // * it will shift the whole drawer upwards, this will mimic that behaviour by
  // * changing the scrollTop of the drawer body to 0
  useEffect(() => {
    const shadowBody = drawerRef.current.shadowRoot.querySelector(
      '.cat-c-drawer__body'
    );
    if (error && shadowBody && hideCloseButton) {
      shadowBody.scrollTop = 0;
    }
  }, [error, drawerRef, hideCloseButton]);

  return (
    <Drawer
      className={cx(styles['drawer'], className)}
      hideCloseButton={hideCloseButton}
      ref={drawerRef}
      {...drawer.drawerProps}
    >
      <Conditional test={drawer.drawerProps.isActive}>
        <DrawerBackHeader handleClose={drawer.handleClose}>
          <h1 className="cat-u-theme-typography-title m-0">{title}</h1>
        </DrawerBackHeader>
        <DrawerBody className={styles['drawer-body']}>
          <Conditional test={error}>
            <AssociatedDealerBanner
              variant="error"
              ignoreScroll={hideCloseButton}
            >
              {errorMessage()}
            </AssociatedDealerBanner>
          </Conditional>
          <AssociatedDealerSearchResultsForm
            formMethods={formMethods}
            searchResults={searchResults}
            loading={loading}
            error={error}
          />
        </DrawerBody>
        <DrawerFooter className={styles['drawer-footer']}>
          <CatButton
            variant="primary"
            onClick={formMethods.handleSubmit(onSubmit)}
            disabled={loading}
          >
            {t('SELECT_CUST_ACCT')}
            <Conditional test={loading}>
              <CatLoadingIndicator slot={'after'} small={true} />
            </Conditional>
          </CatButton>
        </DrawerFooter>
      </Conditional>
    </Drawer>
  );
};

AssociatedDealerSearchResultsDrawer.propTypes = {
  drawer: PropTypes.any,
  formMethods: PropTypes.any,
  onSubmit: PropTypes.any,
  searchResults: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  hideCloseButton: PropTypes.bool
};
