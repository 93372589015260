import PropTypes from 'prop-types';

const MyEquipmentTitle = ({ title, componentType }) => {
  return (
    <>
      {componentType === 'component' && (
        <h1
          data-test="component__MyEquipmentTitle"
          className={`h1 display-1 text-uppercase`}
        >
          {title}
        </h1>
      )}
      {componentType === 'modal' && (
        <h2
          data-test="component__ModalTitle"
          className={`h2 display-1 text-uppercase`}
        >
          {title}
        </h2>
      )}
    </>
  );
};

MyEquipmentTitle.propTypes = {
  componentType: PropTypes.oneOf(['component', 'modal']).isRequired,
  title: PropTypes.string.isRequired
};

export default MyEquipmentTitle;
