export const PRODUCTS_GET_URL_TOKEN_BEGIN = 'PRODUCTS_GET_URL_TOKEN_BEGIN';
export const PRODUCTS_GET_URL_TOKEN_SUCCESS = 'PRODUCTS_GET_URL_TOKEN_SUCCESS';
export const PRODUCTS_GET_URL_TOKEN_FAIL = 'PRODUCTS_GET_URL_TOKEN_FAIL';

export const PRODUCTS_GET_DETAILS_BEGIN = 'PRODUCTS_GET_DETAILS_BEGIN';
export const PRODUCTS_GET_DETAILS_SUCCESS = 'PRODUCTS_GET_DETAILS_SUCCESS';
export const PRODUCTS_GET_DETAILS_FAIL = 'PRODUCTS_GET_DETAILS_FAIL';

export const KIT_PARTS_GET_DETAILS_BEGIN = 'KIT_PARTS_GET_DETAILS_BEGIN';
export const KIT_PARTS_GET_DETAILS_SUCCESS = 'KIT_PARTS_GET_DETAILS_SUCCESS';
export const KIT_PARTS_GET_DETAILS_FAIL = 'KIT_PARTS_GET_DETAILS_FAIL';

export const PRODUCTS_SET_PRICE = 'PRODUCTS_SET_PRICE';
export const PRODUCTS_GET_PRICE_BEGIN = 'PRODUCTS_GET_PRICE_BEGIN';
export const PRODUCTS_GET_PRICE_FAIL = 'PRODUCTS_GET_PRICE_FAIL';
export const PRODUCTS_GET_PRICE_SUCCESS = 'PRODUCTS_GET_PRICE_SUCCESS';

export const GET_HOMEPAGE_PRODUCTS_BEGIN = 'GET_HOMEPAGE_PRODUCTS_BEGIN';
export const GET_HOMEPAGE_PRODUCTS_FAIL = 'GET_HOMEPAGE_PRODUCTS_FAIL';
export const GET_HOMEPAGE_PRODUCTS_SUCCESS = 'GET_HOMEPAGE_PRODUCTS_SUCCESS';
export const PRODUCTS_GET_RECENTLY_VIEWED_BEGIN =
  'PRODUCTS_GET_RECENTLY_VIEWED_BEGIN';
export const PRODUCTS_GET_RECENTLY_VIEWED_FAIL =
  'PRODUCTS_GET_RECENTLY_VIEWED_FAIL';
export const PRODUCTS_GET_RECENTLY_VIEWED_SUCCESS =
  'PRODUCTS_GET_RECENTLY_VIEWED_SUCCESS';

export const PRODUCTS_RESET = 'PRODUCTS_RESET';

export const PRODUCTS_SET_PDP_CURRENT = 'PRODUCTS_SET_PDP_CURRENT';

export const PLP_PRODUCTS_PRICE_SET_STATUS = 'PLP_PRODUCTS_PRICE_SET_STATUS';
export const SEARCH_UI_PRODUCTS_PRICE_SET_STATUS =
  'SEARCH_UI_PRODUCTS_PRICE_SET_STATUS';
export const PRODUCTS_SET_PRODUCT_LIST = 'PRODUCTS_SET_PRODUCT_LIST';
export const PRODUCTS_UPDATE_PRODUCT_LIST = 'PRODUCTS_UPDATE_PRODUCT_LIST';
export const MARKETING_PRODUCTS_PRICE_SET_STATUS =
  'MARKETING_PRODUCTS_PRICE_SET_STATUS';
export const PRODUCTS_LIST_RESET = 'PRODUCTS_LIST_RESET';
export const SAVED_PRICE_ORIGINAL_STATE = 'SAVED_PRICE_ORIGINAL_STATE';
export const RESET_PRICE_AND_AVAILABILITY = 'RESET_PRICE_AND_AVAILABILITY';

export const GET_FBT_ITEMS_BEGIN = 'GET_FBT_ITEMS_BEGIN ';
export const GET_FBT_ITEMS_SUCCESS = 'GET_FBT_ITEMS_SUCCESS ';
export const GET_FBT_ITEMS_FAIL = 'GET_FBT_ITEMS_FAIL';

export const FBT_GET_PRICE_BEGIN = 'FBT_GET_PRICE_BEGIN ';
export const FBT_GET_PRICE_SUCCESS = 'FBT_GET_PRICE_SUCCESS ';
export const FBT_GET_PRICE_FAIL = 'FBT_GET_PRICE_FAIL';

export const FBT_TOTAL_PRICE_BEGIN = 'FBT_TOTAL_PRICE_BEGIN ';
export const FBT_TOTAL_PRICE_SUCCESS = 'FBT_TOTAL_PRICE_SUCCESS ';
export const FBT_TOTAL_PRICE_FAIL = 'FBT_TOTAL_PRICE_FAIL';

export const MAX_QUANTITY = 99999;
export const PRICE_AVAILABILITY_DEFAULT_ATTRIBUTE_ID = 'uniqueId';
export const PRICE_AVAILABILITY_DEFAULT_ATTRIBUTE_PART_NUMBER = 'partNumber';

export const SUMMARY_ITEMS_BEGIN = 'SUMMARY_ITEMS_BEGIN';
export const SUMMARY_ITEMS_SUCCESS = 'SUMMARY_ITEMS_SUCCESS';
export const SUMMARY_ITEMS_FAIL = 'SUMMARY_ITEMS_FAIL';

export const GET_JOBS_RECOMMENDATIONS_BEGIN = 'GET_JOBS_RECOMMENDATIONS_BEGIN ';
export const GET_JOBS_RECOMMENDATIONS_SUCCESS =
  'GET_JOBS_RECOMMENDATIONS_SUCCESS ';
export const GET_JOBS_RECOMMENDATIONS_FAIL = 'GET_JOBS_RECOMMENDATIONS_FAIL';

export const OTHER_STORES_PRODUCTS_BEGIN = 'OTHER_STORES_PRODUCTS_BEGIN';
export const OTHER_STORES_PRODUCTS_SUCCESS = 'OTHER_STORES_PRODUCTS_SUCCESS';
export const OTHER_STORES_PRODUCTS_FAIL = 'OTHER_STORES_PRODUCTS_FAIL';
