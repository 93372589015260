import { useSelector } from 'react-redux';
import { FooterCommonLink, FooterSocialIcons } from '@cat-ecom/pcc-components';
import styles from './FooterSocialLinks.module.scss';
import Conditional from '@app/components/common/Conditional';

const FooterSocialLinks = () => {
  const socialLinks = useSelector(s => s.footer?.socialMediaLinks);
  const socialLinksLoaded = socialLinks?.length > 0;
  return (
    <Conditional
      test={socialLinksLoaded}
      fallback={
        <div
          className={styles['footer__social-links__placeholder']}
          data-testid="social-links-placeholder"
        />
      }
    >
      {socialLinks?.map(socialLink => (
        <li key={socialLink.socialId}>
          <FooterCommonLink
            label={
              <FooterSocialIcons
                socialId={socialLink.socialId}
                socialName={socialLink.socialName}
              />
            }
            href={socialLink.url}
            target="_blank"
          />
        </li>
      ))}
    </Conditional>
  );
};
export default FooterSocialLinks;
