import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useSystemFeedback } from '@app/hooks';
import {
  ERROR_DOMAIN,
  ERROR_PATH
} from '../../../../../constants/errorConstants';
import { useTranslation } from 'react-i18next';
import { normalizeError } from '../../../../../store/exception/utils';

const AssociatedDealerFormEffects = ({
  callDealerCustomerService,
  hasError,
  isCSPCustomer,
  setOkTermsAndConditions,
  retry
}) => {
  const { values } = useFormikContext();
  const { setError, clearError } = useSystemFeedback();
  const [t] = useTranslation();
  /**
   * If user is IA, then there is only one object inside dealers.customerNumber
   * with the value we need for the service call thats why we pick the first object in the array [0].
   */
  const firstCustomerNumber = values.dealer?.customerNumber
    ? values.dealer?.customerNumber[0].value
    : '';
  const customerNumber = isCSPCustomer
    ? values.customerNumber?.value
    : firstCustomerNumber;
  const dealer = values.dealer?.value ?? '';

  useEffect(() => {
    if (customerNumber) {
      callDealerCustomerService({
        customerNumber,
        dealer
      }).catch(err => {
        setError(ERROR_DOMAIN.ADP, ERROR_PATH.STORES, normalizeError(err));
      });
    }
  }, [callDealerCustomerService, customerNumber, dealer, retry, setError]);

  useEffect(() => {
    if (values?.dealer) {
      setOkTermsAndConditions(values?.dealer?.isTermsConditionAccepted);
    } else {
      setOkTermsAndConditions(true);
    }
  }, [setOkTermsAndConditions, values?.dealer]);

  useEffect(() => {
    if (!hasError) {
      clearError(ERROR_DOMAIN.ADP, ERROR_PATH.STORES);
    }
  }, [t, clearError, hasError]);

  return null;
};

export default AssociatedDealerFormEffects;
