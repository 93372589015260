import { useEffect, useContext, lazy, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ModalContext } from 'cat-ecommerce-alloy';
import { isHomepage } from '@app/utils/browserUtils';
import { fetchCsrAuditSummary, validateCSRAudits } from './utils';
import {
  CSR_ACTIONS_MODAL_TITLE,
  SUMMARY_OF_EDITS_MODAL_ID
} from './constants';

const CsrActionSummaryModal = lazy(() =>
  import(
    /* webpackChunkName: 'csrActionSummaryModal' */ './CsrActionSummaryModal/CsrActionSummaryModal'
  )
);

const CsrActionSummary = () => {
  const userType = useSelector(s => s.common?.userType);
  const storeId = useSelector(s => s.common?.storeId);
  const dealerName = useSelector(s => s.dealer?.dealerName) || null;
  const isCatCorp = useSelector(s => s.common?.isCatCorp) || false;
  const isCatCSR = useSelector(s => s.common?.isCatCSR) || false;
  const isBuyOnBehalf = useSelector(s => s.common?.isBuyOnBehalf) || false;
  const startPage = useSelector(
    s => s.shoppingPreferences?.shoppingPreferencesData?.startPage
  );
  const { addModalWithOptions, removeCurrentModal } = useContext(ModalContext);
  const isHomePage = isHomepage();
  const isRegularCustomer = userType === 'R' && !isCatCorp;
  const isRegisteredUser = userType !== 'G';
  const alreadyOpen = useRef(false);

  const checkByURL = urlPiece => {
    return window.location.href.includes(urlPiece);
  };

  const visitingPreferredStartPage = useCallback(() => {
    const startPagePreference = startPage?.find(d => d.default)?.value;
    const preferenceSetting = {
      cc: checkByURL('route=ControlCenter'),
      partManuals: checkByURL('PartLookup'),
      shopCart: checkByURL('AjaxOrderItemDisplayView'),
      homePage: isHomepage()
    };

    return preferenceSetting[startPagePreference];
  }, [startPage]);

  useEffect(() => {
    (async () => {
      if (!isRegisteredUser && !dealerName) {
        return;
      }
      if (isCatCSR || isBuyOnBehalf) {
        return;
      }

      let displayCSRActionSummaryModal = false;
      if (startPage) {
        if (visitingPreferredStartPage()) {
          displayCSRActionSummaryModal = true;
        }
      }

      if (isRegularCustomer && isHomePage) {
        displayCSRActionSummaryModal = true;
      }

      displayCSRActionSummaryModal =
        displayCSRActionSummaryModal && !alreadyOpen.current;

      if (!displayCSRActionSummaryModal) {
        return;
      }

      const csrEvents = await fetchCsrAuditSummary(storeId);
      if (csrEvents?.length > 0 && validateCSRAudits(csrEvents)) {
        addModalWithOptions(
          <CsrActionSummaryModal
            csrEvents={csrEvents}
            onClose={() => removeCurrentModal()}
          />,
          {
            id: SUMMARY_OF_EDITS_MODAL_ID,
            title: CSR_ACTIONS_MODAL_TITLE,
            focusSelector: '#closeSummaryEditsModal'
          }
        );

        alreadyOpen.current = true;
      }
    })();
  }, [
    storeId,
    dealerName,
    startPage,
    isRegisteredUser,
    isCatCSR,
    isBuyOnBehalf,
    isRegularCustomer,
    isHomePage,
    visitingPreferredStartPage,
    addModalWithOptions,
    removeCurrentModal
  ]);

  return null;
};

export default CsrActionSummary;
