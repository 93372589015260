export function getStoreInfo() {
  const { storeId, catalogId, langId } = window.catReact || {};
  return { storeId, catalogId, langId };
}

export function getStoreInfoLegacy() {
  const {
    catalogId,
    isCSPCustomer,
    langId,
    locale,
    salesModel,
    selectedCustomerNumber,
    selectedDealer,
    selectedStore,
    storeId,
    userType,
    productId
  } = window.catReact || {};
  return {
    catalogId,
    isCSPCustomer,
    langId,
    locale,
    salesModel,
    selectedCustomerNumber,
    selectedDealer,
    selectedStore,
    storeId,
    userType,
    productId
  };
}

export default { getStoreInfo };
