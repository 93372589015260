import {
  CAT_CARD_MENU_CRITERIA_COUNTRY,
  CAT_CARD_MENU_CRITERIA_LANGID
} from '@app/constants/commonConstants';

export const setColumns = unvalidatedList => {
  const list = Array.isArray(unvalidatedList) ? [...unvalidatedList] : [];
  const COLUMN_LENGTH = Math.ceil(list.length / 3);
  const firstColumn = list.splice(0, COLUMN_LENGTH);
  const secondColumn = list.splice(
    0,
    COLUMN_LENGTH === list.length ? Math.ceil(list.length / 2) : COLUMN_LENGTH
  );
  return [
    { key: 1, data: firstColumn },
    { key: 2, data: secondColumn },
    { key: 3, data: list }
  ];
};
export const prepareColumns = ({ categories, buyingOptions }) => {
  return {
    preparedCategories: setColumns(categories),
    preparedBuyingOptions: setColumns(buyingOptions)
  };
};

export const validateIfActive = ({ url = '', currentPage = '' }) => {
  const currentRoute = url?.split('/').slice(-1)[0];
  return currentPage?.replace(/-/g, '') === currentRoute?.replace(/-/g, '');
};

export const filterBuyingOptionById = (
  buyingOptions = [],
  buyingOptionsId = []
) => {
  return buyingOptionsId.length > 0
    ? buyingOptions?.filter(option => !buyingOptionsId.includes(option.id))
    : buyingOptions;
};

export const isCatCardMenu = (country, locale) => {
  return !!(
    country === CAT_CARD_MENU_CRITERIA_COUNTRY[country] &&
    locale === CAT_CARD_MENU_CRITERIA_LANGID[locale]
  );
};
