import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  DEALER_SYSTEM_DOWN,
  FROM_PSW_PREF,
  PAGE_LISTS,
  PORTAL_REQUEST,
  PROCESS_ERROR,
  PRSN
} from '../../constants';
import { STATUS, USER_TYPE_REGISTERED } from '@app/constants/commonConstants';
import { getCookie, removeCookie } from '@app/utils';
import {
  checkForPref,
  indexOfAll,
  redirectBypassPage,
  resetHeaderData,
  showProcessErrorMessage
} from '../../../utils';
import useChangeAssociatedDealer from '@app/components/associated-dealer/useChangeAssociatedDealer';
import { getCommonVars } from '@app/store/common/actions';
import links from '@app/constants/links';
import { getRedirectInfo } from '@app/store/header/actions';

const useCatCorpRedirect = ({ associatedDealerModal } = {}) => {
  const dispatch = useDispatch();
  const processError =
    document.getElementById(PROCESS_ERROR) &&
    document.getElementById(PROCESS_ERROR).value;
  const storeId = useSelector(state => state.common?.storeId);
  const userType = useSelector(state => state.common?.userType);
  const areTranslationsLoaded = useSelector(
    state => state?.common?.areTranslationsLoaded
  );
  const redirectStatus = useSelector(state => state.header?.redirect?.status);
  const { resolveDealerAfterLogin, areADPServicesLoading } =
    useChangeAssociatedDealer({ associatedDealerModal });
  const fromPSWPref = getCookie(FROM_PSW_PREF);
  const portalRequest = getCookie(PORTAL_REQUEST);
  const portalRequestSerialNumber = getCookie(PRSN);
  const registeredUser = userType === USER_TYPE_REGISTERED;
  const ispageFound = indexOfAll(PAGE_LISTS);
  const isUserAllowed =
    registeredUser &&
    (window.catReact?.landing !== 'Y' || window.landing !== 'Y') &&
    !fromPSWPref &&
    !portalRequestSerialNumber &&
    !portalRequest;
  const allowPreferences = checkForPref() && registeredUser;
  const isRedirecting =
    redirectStatus === STATUS.IDLE || redirectStatus === STATUS.PENDING;
  const redirectFlag = () => {
    return ispageFound && (isUserAllowed || allowPreferences);
  };
  const shouldRedirect = redirectFlag() && areTranslationsLoaded;
  const pageLoading = isRedirecting || areADPServicesLoading;

  resetHeaderData(storeId);

  const showADP = useCallback(() => {
    dispatch(getCommonVars());
    resolveDealerAfterLogin({ associatedDealerModal });
  }, [dispatch, resolveDealerAfterLogin, associatedDealerModal]);

  const redirect = useCallback(() => {
    const handleRedirect = response => {
      const {
        successRedirect,
        noDealerInfo,
        redirectToFindYourDealer,
        errorMessage
      } = response;
      const noDealerInfoAvailable = noDealerInfo === 'Y';
      const shouldShowADP =
        redirectToFindYourDealer || (noDealerInfoAvailable && !errorMessage);
      if (!!successRedirect && noDealerInfo !== 'Y') {
        redirectBypassPage(response, showADP, links);
        return;
      } //removed CATHomePageModalDisplayCmd
      if (!!processError) {
        //tech debt will handle if we need this piece of code
        showProcessErrorMessage();
        return;
      }
      if (shouldShowADP) {
        redirectToFindYourDealer && removeCookie(DEALER_SYSTEM_DOWN);
        showADP();
      }
    };
    dispatch(getRedirectInfo(handleRedirect));
  }, [dispatch]);
  return { shouldRedirect, pageLoading, redirect };
};
export default useCatCorpRedirect;
