import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Conditional } from '@cat-ecom/pcc-components';
import { useBreakpoint, useEspot } from '@app/hooks';
import { HOME_PAGE_BANNER_ESPOT } from '@app/components/pages/homepage/constants';
import { useTranslation } from 'react-i18next';
import {
  HEADER_OVERLAY_ON_MOBILE,
  PAGE_BREAKPOINTS
} from '@app/constants/commonConstants';
import {
  APP_BANNERS,
  APP_BANNER_ROOT,
  HEADER,
  REACT_ROOT_MAIN
} from '@app/constants/targets';
import HeaderSearchBarWrapper from '../header/HeaderSearchBarWrapper';

const HomePageSearchBarWrapper = () => {
  const { t } = useTranslation();
  const [isHeroBannerInView, setIsHeroBannerInView] = useState(true);
  const portalTarget = document.getElementById('search-bar-banner-homepage');
  const { espotData, espotError } = useEspot(HOME_PAGE_BANNER_ESPOT);
  const espotFinishedLoading = Array.isArray(espotData);
  const homePageBannerEspotLoaded = espotFinishedLoading || espotError;
  const sessionValue = sessionStorage.getItem('part-search');
  const isMobile = !useBreakpoint(PAGE_BREAKPOINTS.BLOCKS_LG);

  const onSearchCallback = useCallback(searchValue => {
    sessionStorage.setItem('part-search', searchValue ?? '');
  }, []);

  useEffect(() => {
    sessionStorage.removeItem('part-search');
    if (!espotFinishedLoading && !espotError) {
      return;
    } else if (espotError) {
      setIsHeroBannerInView(false);
      return;
    }
    if (!portalTarget) {
      setIsHeroBannerInView(false);
      return;
    }
    const handleScroll = () => {
      const rect = portalTarget.getBoundingClientRect();
      const headerElement = document
        .getElementById(HEADER)
        .getBoundingClientRect();
      const globalBannersElement = document
        .getElementById(APP_BANNERS)
        ?.getBoundingClientRect();
      const appBannerElement = document
        .getElementById(APP_BANNER_ROOT)
        ?.getBoundingClientRect();
      const bannerElementHeight =
        globalBannersElement?.height || 0 + appBannerElement?.height || 0;
      if (rect.bottom - headerElement.height - bannerElementHeight <= 0) {
        setIsHeroBannerInView(false);
      } else {
        setIsHeroBannerInView(true);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [espotError, espotFinishedLoading, portalTarget]);
  const onBlMobileOverlayOpen = () => {
    const body = document.getElementById(REACT_ROOT_MAIN);
    body?.classList.add(HEADER_OVERLAY_ON_MOBILE);
  };
  const onBlMobileOverlayClose = () => {
    const body = document.getElementById(REACT_ROOT_MAIN);
    body.classList.remove(HEADER_OVERLAY_ON_MOBILE);
  };
  return (
    <>
      <Conditional test={homePageBannerEspotLoaded}>
        {isHeroBannerInView && portalTarget ? (
          createPortal(
            <div>
              <HeaderSearchBarWrapper
                subLabel={!isMobile ? t('RESULTS_MEQ_ADD_EQUIP') : ''}
                initialSearchValue={sessionValue}
                onSearchCallback={onSearchCallback}
                onBlMobileOverlayOpen={onBlMobileOverlayOpen}
                onBlMobileOverlayClose={onBlMobileOverlayClose}
              />
            </div>,
            portalTarget
          )
        ) : (
          <div className="flex-grow-1">
            <HeaderSearchBarWrapper
              initialSearchValue={sessionValue}
              onSearchCallback={onSearchCallback}
            />
          </div>
        )}
      </Conditional>
    </>
  );
};

export default HomePageSearchBarWrapper;
