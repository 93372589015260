import PropTypes from 'prop-types';
import { GridSystem, GridSystemItem } from 'cat-ecommerce-alloy';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import { getCategoriesPagesCols } from './utils';
import CategoryCardImage from './CategoryCardImage';
import CategoryCardTitle from './CategoryCardTitle';
import SkeletonCategoryCard from './SkeletonCategoryCard';
import { DEFAULT_CATEGORIES_SIZE } from '@app/components/pages/top-category/constants';
import styles from './CategoriesGrid.module.scss';

const CategoriesGrid = ({
  className,
  categories,
  isLoading = false,
  skeletonSize = DEFAULT_CATEGORIES_SIZE,
  isSkeletonCardLarge,
  renderAdditionalRowContent = () => {}
}) => {
  const isXL = useBreakpoint(PAGE_BREAKPOINTS.XL);
  const isLG = useBreakpoint(PAGE_BREAKPOINTS.LG);

  const renderGridItems = category => {
    if (category) {
      const { name, imageUrl = '', redirectUrl } = category;
      return (
        <GridSystemItem key={name} variant="divider">
          <GridSystemItem.Element>
            <a
              href={redirectUrl}
              aria-label={name}
              className={`d-block overflow-hidden ${styles['categories__grid-item-link']} `}
            >
              <CategoryCardImage name={name} imageUrl={imageUrl} />
              <CategoryCardTitle name={name} />
            </a>
          </GridSystemItem.Element>
        </GridSystemItem>
      );
    } else {
      return <SkeletonCategoryCard isSkeletonCardLarge={isSkeletonCardLarge} />;
    }
  };

  return (
    <GridSystem
      className={className}
      items={isLoading ? Array.from(Array(skeletonSize)) : categories}
      cols={getCategoriesPagesCols(isXL, isLG)}
      render={renderGridItems}
      renderBelowRow={renderAdditionalRowContent}
    />
  );
};

CategoriesGrid.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      imageUrl: PropTypes.string,
      redirectUrl: PropTypes.string
    })
  ),
  renderAdditionalRowContent: PropTypes.func,
  isLoading: PropTypes.bool,
  isSkeletonCardLarge: PropTypes.bool,
  skeletonSize: PropTypes.number
};

export default CategoriesGrid;
