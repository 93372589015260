import { getHeliosInfoFromMyEquipmentList } from '@app/utils/commonUtils';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useServiceInsights = (equipmentDetail = null) => {
  const isCSPCustomer = useSelector(s => s.common.isCSPCustomer);
  const isServiceRecommendationFeatureEnable = useSelector(
    s => s.featureFlag.PCC_ServiceRecommandationFlag
  );
  const { data: { commercialOffers = [] } = {} } = useSelector(
    s => s.myEquipment.serviceRecommendationCards
  );
  const myEquipmentList = useSelector(
    s => s.myEquipment.equipments.myEquipmentList
  );
  const selectedEquipment = useSelector(
    s => s.myEquipment.equipments.selectedEquipment
  );

  const heliosInfoFromMyEquipmentList = useMemo(() => {
    return getHeliosInfoFromMyEquipmentList(
      myEquipmentList,
      equipmentDetail || selectedEquipment
    );
  }, [myEquipmentList, selectedEquipment, equipmentDetail]);

  const isServiceInsightsEnabled = useMemo(
    () =>
      commercialOffers?.some(
        offer => offer?.assetId === heliosInfoFromMyEquipmentList?.heliosAssetId
      ),
    [heliosInfoFromMyEquipmentList, commercialOffers]
  );

  return {
    isServiceInsightsEnabled,
    isServiceRecommendationFeatureEnable,
    isCSPCustomer,
    isServiceInsights:
      isServiceInsightsEnabled &&
      isServiceRecommendationFeatureEnable &&
      isCSPCustomer
  };
};
export default useServiceInsights;
