import {
  NOTIFICATION_INDICATOR,
  NOTIFICATION_ORDER_STATUS,
  PREFERENCES_CONTEXT,
  USER_ROLES,
  CAL_NOTIFICATIONS
} from '@app/constants/commonConstants';
import _ from 'lodash';

export const sortNotifications = notificationData => {
  let sorted = _.orderBy(
    notificationData,
    [
      'isRead',
      o => o?.isRead === false && !!NOTIFICATION_INDICATOR[o?.orderStatus],
      o => {
        return new Date(o?.formattedDate);
      }
    ],
    ['asc', 'desc', 'desc']
  );
  return sorted;
};

export const isUnRearNotificationCount = notificationData => {
  return _.filter(notificationData, o => {
    return o.isRead === false && o.isDismissed === false;
  });
};

export const getContextAttributes = (user, storeId) => {
  const contextAttribute = user?.contextAttribute.filter(
    value =>
      value?.attributeName?.includes('_Web') ||
      value?.attributeName?.includes('orderApprovalLimitRange')
  );
  const NotificationActiveChecks = {};
  if (contextAttribute?.length > 0) {
    contextAttribute.forEach(element => {
      const isValueExist = element?.attributeValue?.filter(
        info =>
          (info?.storeId === storeId && info?.value[0] !== 'false') ||
          (info?.storeId !== storeId &&
            info?.value[0] === 'true' &&
            element?.attributeValue?.length === 1) // add this check to capture default preference web notification
      );

      if (
        PREFERENCES_CONTEXT[element.attributeName] &&
        PREFERENCES_CONTEXT[element.attributeName] !== 'LIMIT'
      ) {
        NotificationActiveChecks[PREFERENCES_CONTEXT[element.attributeName]] =
          !!isValueExist.length > 0;
        if (
          PREFERENCES_CONTEXT[element.attributeName] ===
          PREFERENCES_CONTEXT.isOrdProcOrdProcessingPreferred_Web
        ) {
          NotificationActiveChecks['OP'] = !!isValueExist.length > 0;
        }
      } else if (
        PREFERENCES_CONTEXT[element.attributeName] === 'LIMIT' &&
        isValueExist?.filter(val => val?.storeId === storeId)?.length > 0
      ) {
        NotificationActiveChecks[PREFERENCES_CONTEXT[element.attributeName]] =
          isValueExist?.filter(val => val?.storeId === storeId)?.[0]?.value[0];
      }
    });
  }
  return NotificationActiveChecks;
};

export const orderStatusFlat = () => {
  const cloneOrderStatus = _.cloneDeep(NOTIFICATION_ORDER_STATUS);
  const status = [];

  for (const key in cloneOrderStatus) {
    status.push({ label: cloneOrderStatus[key], value: key });
  }
  return status;
};

export const isALLNotificationDismissed = notificationData => {
  return _.some(notificationData, ['isDismissed', false]);
};

export const calUser = userRoles => {
  return [USER_ROLES.ADMIN, USER_ROLES.APPROVER, USER_ROLES.SUBMITTER].some(
    role => userRoles?.includes(role)
  );
};

export const getPreferedNotification = (data = {}, prefferd = {}) => {
  const rangeArray = prefferd?.LIMIT?.split(' - ');
  const notificationData =
    Object.keys(prefferd).length > 0
      ? data?.notifications.filter(e => {
          const filterRange =
            CAL_NOTIFICATIONS?.includes(e?.orderStatus) && rangeArray
              ? e?.orderAmount >= parseInt(rangeArray?.[0]) &&
                e?.orderAmount <= parseInt(rangeArray?.[1])
              : e;
          const checkOrderStatus =
            prefferd[e?.orderStatus] || prefferd[e?.orderStatus] === undefined;

          if (e?.orderStatus === 'OP' && e?.ordersQueueStatus === 'PROCESSED') {
            return filterRange && checkOrderStatus;
          } else if (e?.orderStatus === 'OP') {
            return filterRange;
          }
          return filterRange && checkOrderStatus;
        })
      : data?.notifications;
  return {
    notifications: notificationData,
    recordSetTotal: notificationData?.length
  };
};
