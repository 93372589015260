import PropTypes from 'prop-types';
import { AssociatedDealerSearchCustomerForm } from '../AssociatedDealerSearchCustomerForm';
import { Conditional, Drawer } from '@cat-ecom/pcc-components';
import styles from '../../styles.module.scss';
import { DrawerType, FormMethodsType } from '../../types';

export const AssociatedDealerSearchCustomerDrawer = ({
  drawer,
  formMethods,
  onSubmit,
  dealer,
  loading,
  error
}) => {
  return (
    <Drawer className={styles['drawer']} {...drawer.drawerProps}>
      <Conditional test={drawer.drawerProps.isActive}>
        <AssociatedDealerSearchCustomerForm
          formMethods={formMethods}
          onSubmit={onSubmit}
          dealer={dealer}
          loading={loading}
          error={error}
        />
      </Conditional>
    </Drawer>
  );
};

AssociatedDealerSearchCustomerDrawer.propTypes = {
  drawer: DrawerType,
  formMethods: FormMethodsType,
  onSubmit: PropTypes.func,
  dealer: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  loading: PropTypes.bool,
  error: PropTypes.string
};
