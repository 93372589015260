import { STATUS } from '../../constants/commonConstants';

export const accountInitialStore = {
  ajaxCallsInProgress: 0,
  dealerName: '',
  paymentMethods: [],
  storeId: {},
  catalogId: {},
  customerOrganizations: [],
  customerOrganizationsStatus: STATUS.IDLE,
  langId: {},
  recentOrderHistory: [],
  personalInformation: {},
  pendingQuotes: {
    pendingQuotesErrorDismiss: false,
    status: STATUS.IDLE,
    approvingStatus: STATUS.IDLE
  },
  pendingApprovalQuotes: {
    approvingStatus: STATUS.IDLE
  },
  savedLists: [],
  savedListsStatus: STATUS.IDLE,
  searchOptions: {
    folName: '',
    createdBy: '',
    itemPartNumber: '',
    startDate: '',
    endDate: ''
  },
  selfFOL: true,
  isLoading: false,
  showFavoriteSavedLists: false,
  paymentMethodsStatus: STATUS.IDLE
};
