import { AlloyButton, Popup, PopupTrigger } from 'cat-ecommerce-alloy';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AvailabilityByDateContent from '../AvailabilityByDateContent';
import AvailabilityBySourceContent from '../AvailabilityBySourceContent';
import MobileModalSecondaryAvailability from './mobileModalSecondaryAvailability';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';

const SecondaryAvailability = props => {
  const [t] = useTranslation();
  const isSM = !useBreakpoint(PAGE_BREAKPOINTS.MD);
  const showAvailabilityBySource = useSelector(
    state =>
      !!state?.shoppingPreferences?.shoppingPreferencesData
        ?.showSourcingDetailToggle
  );

  const getInitiator = () => (
    <AlloyButton
      buttonType="ghost"
      size="large"
      className="p-0 text-start text-sans-serif text-sm text-transform-none shadow-none"
    >
      {showAvailabilityBySource
        ? t('AVAIL_STANDARD_AVAILAIBILITY_DATES')
        : t('AVAIL_STANDARD_AVAILABILITY_SOURCE')}
    </AlloyButton>
  );

  return (
    <>
      {isSM ? (
        <MobileModalSecondaryAvailability {...props} />
      ) : (
        <Popup
          contents={
            showAvailabilityBySource ? (
              <AvailabilityByDateContent {...props} isSecondaryComp />
            ) : (
              <AvailabilityBySourceContent {...props} isSecondaryComp />
            )
          }
          initiator={getInitiator()}
          popperOptions={{ placement: 'right' }}
          trigger={PopupTrigger.CLICK}
        />
      )}
    </>
  );
};

export default SecondaryAvailability;
