export const CLEAR_ALL_ERRORS = 'CLEAR_ALL_ERRORS';
export const CLEAR_DOMAIN = 'CLEAR_DOMAIN';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_ERROR = 'SET_ERROR';

export default {
  CLEAR_ALL_ERRORS,
  CLEAR_DOMAIN,
  CLEAR_ERROR,
  SET_ERROR
};
