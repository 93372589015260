import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RegisterButton as RegisterButtonPCC } from '@cat-ecom/pcc-components';
import useRegister from '@app/components/login/hooks/useRegister';
import { CONSENT_SESSION_KEY } from '@app/constants/commonConstants';
import { getSessionStorage } from '@app/utils';
import { useContext } from 'react';
import { ConsentAcceptanceContext } from '../../ConsentAcceptanceModalContext/ConsentAcceptanceModalContext';

const RegisterButton = ({ className, closeDrawer, openDealerLocator }) => {
  const { t } = useTranslation();
  const { register } = useRegister();

  const dealerName = useSelector(state => state?.dealer?.dealerName);
  const modalProps = useContext(ConsentAcceptanceContext);

  const userConsentDisplay = () => {
    const isConsentSelected =
      getSessionStorage(CONSENT_SESSION_KEY) &&
      JSON.parse(getSessionStorage(CONSENT_SESSION_KEY));
    if (dealerName && isConsentSelected == null) {
      modalProps.open();
    } else {
      register(openDealerLocator);
    }
  };
  const handleOnClick = () => {
    closeDrawer?.();
    userConsentDisplay();
  };

  return (
    <RegisterButtonPCC className={className} onClick={handleOnClick}>
      {t('SL_REGISTER')}
    </RegisterButtonPCC>
  );
};

RegisterButton.propTypes = {
  openDealerLocator: PropTypes.func,
  className: PropTypes.string,
  closeDrawer: PropTypes.func
};

export default RegisterButton;
