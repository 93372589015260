export const SERIAL_NO_AND_CUST_PART_LIMIT = 20;
export const LINE_ITEM_NOTES_LIMIT = 50;
export const CUSTOMER_ITEM_NO_LIMIT = 5;

export const NOTE_FIELD_VALUE = {
  customerPartNumber: 'CAT_ORDER_CPART_NUMBER',
  customerSerialNumber: 'ASSET_ID_ESN',
  lineItemNo: 'CAT_ORDER_CUST_ITEM_NO',
  partNote: 'CAT_ORDER_LINE_ITEM_NO'
};

export const NOTE_FIELD_MAX_LENGTH = {
  customerPartNumber: SERIAL_NO_AND_CUST_PART_LIMIT,
  customerSerialNumber: SERIAL_NO_AND_CUST_PART_LIMIT,
  lineItemNo: CUSTOMER_ITEM_NO_LIMIT,
  partNote: LINE_ITEM_NOTES_LIMIT
};

export const OLD_FIELD = 'old';
export const NEW_FIELD = 'new';
