export const FORM_FIELD_MODEL = 'model';
export const FORM_FIELD_SERIAL = 'serial';
export const FORM_FIELD_NICK_NAME = 'nickName';

export const SERIAL_NICKNAME = 'serialNickName';
export const MODEL_NICKNAME = 'modelNickName';

export const FIND_SERIAL_ID = 'blocks-my-equipment-drawer-serial-link';
export const FIND_MODEL_ID = 'blocks-my-equipment-drawer-model-link';
export const DELETE_ID = 'blocks-my-equipment-drawer-delete-link';

export const EQUIPMNET_IMAGE_PARAMS =
  '?hei=60&wid=80&qlt=85,0&resMode=sharp2&op_usm=1.75,0.3,2,0';
export const SERIAL_MATCH_IMAGE_PARAMS =
  '?hei=44&wid=57&qlt=85,0&resMode=sharp2&op_usm=1.75,0.3,2,0';

export const NEWER_ASSETS = {
  src: 'https://caterpillar.scene7.com/is/image/Caterpillar/CM20220208-8552a-03070?wid=319&hei=160',
  alt: 'newer assets'
};
export const NEWER_ASSETS_MODEL = {
  src: 'https://caterpillar.scene7.com/is/image/Caterpillar/CM20220208-e9624-c99b5?wid=300&hei=81',
  alt: 'newer asset model'
};
export const ASSET_PLATE_BEFORE = {
  src: 'https://caterpillar.scene7.com/is/image/Caterpillar/CM20220208-e3a2c-0b730?wid=252&hei=160',
  alt: 'asset plate before 2002'
};
export const ASSET_PLATE_BEFORE_MODEL = {
  src: 'https://caterpillar.scene7.com/is/image/Caterpillar/CM20220208-ab41f-ff534?wid=140&hei=112',
  alt: 'asset plate before 2002 model'
};
export const ENGINES_AND_GENERATORS = {
  src: 'https://caterpillar.scene7.com/is/image/Caterpillar/CM20220208-a2894-08f88?wid=289&hei=160',
  alt: 'engines and generators'
};
export const ENGINES_AND_GENERATORS_MODEL = {
  src: 'https://caterpillar.scene7.com/is/image/Caterpillar/CM20220208-37a04-9841a?wid=140&hei=81',
  alt: 'engines and generators model'
};
export const WHERE_TO_FIND_SERIAL = {
  src: 'https://caterpillar.scene7.com/is/image/Caterpillar/CM20220208-ad620-d01ab?wid=325&hei=160',
  alt: 'Where to find an asset serial number plate?'
};
export const MODEL_LOCATION = {
  src: 'https://caterpillar.scene7.com/is/image/Caterpillar/CM20200214-e3574-9840e?wid=374&hei=186',
  alt: 'model location'
};

export const WIDGET_IDENTIFIER = {
  ADD: 'add',
  EDIT: 'edit',
  SELECT: 'select'
};

export const EQUIPMENT_METHOD = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  SELECT: 'select'
};

export const START_TYPING_IMAGE = {
  imgUrl:
    'https://caterpillar.scene7.com/is/image/Caterpillar/CM20230710-4a31c-f981b',
  imgAlt: 'Start Typing'
};

export const START_TYPING_IMAGE_SIZE = { MOB: '?hei=136&wid=204' };

export const SELECTED_EQUIPMENT_IMAGE_SIZE = { DESK: '?hei=60&wid=80' };
export const SELECT_EQUIPMNET_IMAGE_PARAMS =
  '?hei=354&qlt=85,0&resMode=sharp2&op_usm=1.75,0.3,2,0&$fmt=webp';

export const DELETE_EQUIPMENT_IMAGE_PARAMS =
  '?hei=44&wid=74&qlt=85,0&resMode=sharp2&op_usm=1.75,0.3,2,0';

export const EQUIPMENT_LIST_LIMIT = 25;
