import { isEmpty } from '@app/utils';
import { DISTANCE, DEALER_NAME } from './constants';

/**
 * Compares 2 store locations based on distance first, then 'city state' second
 *
 * @param {Object} store1 a store location to compare
 *  @param {number} store1.distance the distance to a store
 *  @param {string} store1.city the city in which a store is located
 *  @param {string} store1.state the state in which a store is located
 * @param {Object} store2 a store location to compare
 *  @param {number} store2.distance the distance to a store
 *  @param {string} store3.city the city in which a store is located
 *  @param {string} store3.state the state in which a store is located
 */
export const compareStoreLocations = (store1, store2) => {
  const dist1 = store1.distance ?? Infinity; // undefined/null values are sorted to bottom of list
  const dist2 = store2.distance ?? Infinity; // undefined/null values are sorted to bottom of list
  const location1 = `${store1.city} ${store1.state}`;
  const location2 = `${store2.city} ${store2.state}`;
  const distDiff = dist1 - dist2;
  return distDiff === 0 ? location1.localeCompare(location2) : distDiff;
};

/**
 * Compares 2 store locations based on dealerName first, then 'city state' second
 *
 * @param {Object} store1 a store location to compare
 *  @param {string} store1.dealerName the dealer name
 *  @param {string} store1.city the city in which a store is located
 *  @param {string} store1.state the state in which a store is located
 * @param {Object} store2 a store location to compare
 *  @param {string} store1.dealerName the dealer name
 *  @param {string} store3.city the city in which a store is located
 *  @param {string} store3.state the state in which a store is located
 */
export const compareStoreNames = (store1, store2) => {
  const dealerName1 = store1.dealerName;
  const dealerName2 = store2.dealerName;
  const storeName1 = store1.name;
  const storeName2 = store2.name;
  const areNamesEqual = dealerName1 === dealerName2;
  //send undefined to the bottom of the list
  if (!dealerName1) {
    return 1;
  } else if (!dealerName2) {
    return -1;
  }
  return areNamesEqual
    ? storeName1.localeCompare(storeName2)
    : dealerName1.localeCompare(dealerName2);
};

/**
 * Return de list of dealers ordered by distance or dealer name
 *
 * @param {Array} dealers a dealers list
 * @param {Boolean} dealers.isClosest Boolean flag to indicate which is the closest store
 * @param {String} property a the property you want to order the dealer's list, can be DISTANCE OR DEALER_NAME
 * @param {Number} numDisplayed amount of the closest dealers you want to order
 */
export const getDealersOrderedByProperty = (
  dealers = [],
  property = DISTANCE,
  numDisplayed = dealers.length
) => {
  if (dealers.length === 0) {
    return dealers;
  }
  const sortedDealers = [...dealers].sort(compareStoreLocations);
  const byDistanceDealers = sortedDealers.map((item = {}) => ({
    ...item,
    /**
     * We only want the "closest" indicator when sorting by distance
     **/
    isClosest: false
  }));

  /**
   *  We only want to sort those that are visible in the dealer's list
   **/
  const byDistanceVisibleDealers = byDistanceDealers.slice(0, numDisplayed);

  if (property === DEALER_NAME) {
    return byDistanceVisibleDealers.sort(compareStoreNames);
  } else {
    byDistanceVisibleDealers[0].isClosest = true;
    return byDistanceVisibleDealers;
  }
};

export const getFilteredDealerStores = (dealerStores = [], filters = {}) =>
  dealerStores?.filter(store =>
    store?.serviceOffered?.some(service => filters && filters[service] === true)
  );

export const areServiceFiltersSelected = filters =>
  !!filters && Object.values(filters).includes(true);

/**
 * Return the list of dealers ordered by distance or dealer name and filtered based on filters object
 *
 * @param {Array} dealerStores a dealers list to order and filter
 * @param {Object} filters an object containing the filters to be applied and the order property we want the dealer's list to be ordered by
 * @param {Number} numDisplayed amount of the closest dealers you want to order by dealer's name
 */
export const getFilteredAndOrderedDealerStores = (
  dealerStores,
  filters,
  numDisplayed
) => {
  if (isEmpty(dealerStores)) {
    return {
      allFilteredDealerStoresByDistance: [],
      filteredAndOrderedVisibleDealerStores: []
    };
  }

  // We make a copy of dealerStores in order to avoid mutate the original object
  let filteredDealers = [...dealerStores];
  if (areServiceFiltersSelected(filters)) {
    filteredDealers = getFilteredDealerStores(filteredDealers, filters);
  }

  const filteredAndOrderedDealerStores = getDealersOrderedByProperty(
    [...filteredDealers],
    filters?.sortOrder,
    numDisplayed
  );

  return {
    filteredAndOrderedVisibleDealerStores: filteredAndOrderedDealerStores,
    allFilteredDealerStoresByDistance: filteredDealers
  };
};

export const getLatLng = (lat, lng) => ({
  lat: parseFloat(lat),
  lng: parseFloat(lng)
});

export const roundCompare = (x, y, precision) => {
  return x.toPrecision(precision) === y.toPrecision(precision);
};

// Example Format: 1 Capitol Drive, East Peoria, Illinois, 61611-4820, USA
export const getAddress = addressArray =>
  addressArray.filter(v => v).join(', '); // remove empty values;

export const getLocation = ({
  address,
  city,
  country,
  isCSPCustomer,
  state,
  zipcode
}) =>
  isCSPCustomer ? '' : getAddress([address, city, state, zipcode, country]);
