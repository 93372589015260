const TYPE_VIDEO = 'video';

export function isVideo(multimediaType) {
  return multimediaType === TYPE_VIDEO;
}

export function getYouTubeVideoId(videoUrl = '') {
  videoUrl = videoUrl || '';
  return videoUrl.match(
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|e\/|v\/|vi\/|watch\?v=|watch\?vi=|watch\?.+&v=|watch\?.+&vi=|\?v=|\?vi=))((\w|-){11})(?:\S+)?$/
  )
    ? RegExp.$1
    : null;
}

export function getYouTubeStill(videoUrl) {
  return `https://img.youtube.com/vi/${getYouTubeVideoId(videoUrl)}/0.jpg`;
}

export default { isVideo, getYouTubeStill, getYouTubeVideoId };
