import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DealerLocationServiceOffered = ({ serviceOffered = [] }) => {
  const [t] = useTranslation();
  return (
    <section>
      <header>
        <h3 className="h6 mb-1 text-start">{t('SERVICES_OFFERED')}:</h3>
      </header>
      <p className="exclude-global text-sans-serif u-color--DarkGray text-start">
        {serviceOffered.join(', ')}
      </p>
    </section>
  );
};

export default DealerLocationServiceOffered;

DealerLocationServiceOffered.propTypes = {
  serviceOffered: PropTypes.arrayOf(PropTypes.string).isRequired
};
