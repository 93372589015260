import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AlloyButton } from 'cat-ecommerce-alloy';
import { SAVED_LIST_ITEM_COUNT } from '../constants';

const ViewMoreButton = ({ viewMoreLists }) => {
  const { t } = useTranslation();
  const [numDisplayed, setNumDisplayed] = useState(SAVED_LIST_ITEM_COUNT);

  useEffect(() => {
    viewMoreLists(numDisplayed);
  }, [numDisplayed, viewMoreLists]);

  const viewMore = () =>
    setNumDisplayed(currentNumber => currentNumber + SAVED_LIST_ITEM_COUNT);

  return (
    <div className="d-flex justify-content-center">
      <AlloyButton
        buttonType="secondary"
        size="small"
        onClick={viewMore}
        className="mb-3"
      >
        {t('LOAD_MORE')}
      </AlloyButton>
    </div>
  );
};

ViewMoreButton.propTypes = {
  viewMoreLists: PropTypes.func
};

export default ViewMoreButton;
