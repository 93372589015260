import { format, subYears } from 'date-fns';
import { convertStringToDate } from '@app/utils/dateUtils';
import { DATE_FORMAT_YYYY_MM_DD } from '@app/constants/commonConstants';
import { MY_ACCOUNT_SEARCH } from '@app/constants/routes';

export const getFiltersInitialValues = preFillCopy => ({
  storeLocation: preFillCopy?.storeLocation ?? '',
  dateRange: preFillCopy?.dateRange
    ? {
        start: convertStringToDate(preFillCopy.dateRange.dateFrom, 'CET'),
        end: convertStringToDate(preFillCopy.dateRange.dateTo, 'CET')
      }
    : {
        start: subYears(new Date(), 2),
        end: new Date()
      },
  invoiceNumber: preFillCopy?.invoiceNumber ?? '',
  partNumber: preFillCopy?.partNumber ?? '',
  orderNumber: preFillCopy?.orderNumber ?? '',
  serialNumber: preFillCopy?.serialNumber ?? '',
  purchaseOrderNumber: preFillCopy?.purchaseOrderNumber ?? ''
});

export const formatDateRange = (
  dateRange,
  dateFormat = DATE_FORMAT_YYYY_MM_DD
) => {
  const dateFrom = format(dateRange?.start, dateFormat);
  const dateTo = format(dateRange?.end, dateFormat);
  return { dateFrom, dateTo };
};

export const getFiltersSessionData = () => {
  const previousInvoicesFilters = sessionStorage.getItem(
    'previousInvoicesFilters'
  );
  const previousCurrentPageInvoicesFilters = sessionStorage.getItem(
    'currentPageInvoicesFilters'
  );
  return { previousInvoicesFilters, previousCurrentPageInvoicesFilters };
};

export const removeFiltersSessionData = () => {
  if (
    !!sessionStorage.getItem('previousInvoicesFilters') ||
    !!sessionStorage.getItem('currentPageInvoicesFilters')
  ) {
    sessionStorage.removeItem('previousInvoicesFilters');
    sessionStorage.removeItem('currentPageInvoicesFilters');
  }
};

export const isInvoiceRoute = route =>
  route === MY_ACCOUNT_SEARCH.INVOICES ||
  route === MY_ACCOUNT_SEARCH.INVOICE_DETAILS;

export const getInvoiceListFitltered = (invoices, invoicesClientFilters) =>
  invoices?.filter(
    invoice =>
      invoice?.invoiceNumber
        ?.toLowerCase()
        .includes(invoicesClientFilters.invoiceNumber?.trim().toLowerCase()) &&
      invoice?.documentNumber
        ?.toLowerCase()
        .includes(invoicesClientFilters.orderNumber?.trim().toLowerCase()) &&
      invoice?.equipmentSerial
        ?.toLowerCase()
        .includes(invoicesClientFilters.serialNumber?.trim().toLowerCase()) &&
      invoice?.poNumber
        ?.toLowerCase()
        .includes(
          invoicesClientFilters.purchaseOrderNumber?.trim().toLowerCase()
        )
  );
