import PropTypes from 'prop-types';
import { TOAST_TYPE } from '@app/constants/commonConstants';
import ToastSuccessContent from './ToastSuccessContent';
import ToastFailureContent from './ToastFailureContent';

const AddToCartToast = ({ type = TOAST_TYPE.SUCCESS, items = [] }) => {
  return type === TOAST_TYPE.SUCCESS ? (
    <ToastSuccessContent items={items} />
  ) : (
    <ToastFailureContent />
  );
};

AddToCartToast.propTypes = {
  type: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      partName: PropTypes.string
    })
  )
};

export default AddToCartToast;
