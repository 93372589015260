export const getIconSizeNumber = size => {
  if (!size) {
    return 16;
  }
  if (size === 'lg') {
    return 24;
  }
  if (size === 'xl') {
    return 32;
  }
};
