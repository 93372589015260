export const repairOptionsInitialStore = {
  isPageLoading: false,
  isRequiredPriceAndAvailabilityLoading: false,
  hasPriceData: false,
  isHeaderSelected: false,
  isPMKit: false,
  writeReadyEvents: false,
  formattedZeroPrice: '',
  bundleInfo: {},
  breadCrumb: [],
  partsListOptions: [],
  allPartsLists: {},
  selectedGroupNumber: '',
  selectedPartsImages: [],
  selectedPartsList: [],
  enableAddAllToCartCached: false,
  selectedRecommendedTools: [],
  selectedOptionalParts: [],
  isRelatedPriceAndAvailabilityLoading: false,
  isRecommendedPriceAndAvailabilityLoading: false,
  recommendedToolsPriceLoading: false,
  partsTablePriceLoading: false,
  relatedPartsPriceLoading: false
};
