import PropTypes from 'prop-types';
import {
  AssociatedDealerDropdown,
  Conditional
} from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import { STORE_LOCATION_DROPDOWN_NAME } from '../../constants';
import { StoreLocation } from '../../types';

export const AssociatedDealerStoreLocationDropdownWrapper = ({
  storeLocationList,
  selectedValue,
  onSelect,
  loading = false
}) => {
  const [t] = useTranslation();
  const label = t('MO_STORE_LOCATION');
  const placeholder = t('CAT_MA_SELECTDEALAERSTORE');
  const showStoreLocationDropdown = storeLocationList?.length > 0 || loading;
  const isReadOnlyValue = storeLocationList?.length === 1;

  return (
    <Conditional test={showStoreLocationDropdown}>
      <AssociatedDealerDropdown
        id={STORE_LOCATION_DROPDOWN_NAME}
        name={STORE_LOCATION_DROPDOWN_NAME}
        label={label}
        placeholder={placeholder}
        itemList={storeLocationList}
        loading={loading}
        required={true}
        readOnly={isReadOnlyValue}
        selectedValue={selectedValue}
        onSelect={onSelect}
      />
    </Conditional>
  );
};

AssociatedDealerStoreLocationDropdownWrapper.propTypes = {
  storeLocationList: PropTypes.arrayOf(StoreLocation),
  loading: PropTypes.bool,
  selectedValue: PropTypes.string,
  onSelect: PropTypes.func
};
