import PropTypes from 'prop-types';
import styles from './cellStyles.module.scss';

const RecordCountCell = ({ rowIndex }) => {
  return (
    <div className="d-flex" data-testid="record-count-cell">
      <span className={`m-0 ${styles['order__items-record-count']}`}>
        {`${rowIndex + 1})`}
      </span>
    </div>
  );
};

RecordCountCell.propTypes = {
  rowIndex: PropTypes.number
};

export default RecordCountCell;
