import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Radio, RadioGroup } from 'cat-ecommerce-alloy';
import Field from '@app/components/common/form/Field';

const ORDER_TYPE_STOCK = 'STK';
const ORDER_TYPE_STANDARD = 'STD';
const FIELD_NAME = 'orderType';

const OrderType = ({ idPrefix = '', orderTypes = [] }) => {
  const [t] = useTranslation();
  const { setFieldValue, values } = useFormikContext();

  const defaultStdLabel = t('CAT_MA_STANDARD');
  const defaultStockLabel = t('CAT_MA_STOCK');

  const defaultStandardOrderType = {
    default: false,
    value: ORDER_TYPE_STANDARD,
    label: defaultStdLabel
  };
  const defaultStockOrderType = {
    default: false,
    value: ORDER_TYPE_STOCK,
    label: defaultStockLabel
  };
  const orderType = values.orderType;
  const standardOrderType =
    orderTypes.find(type => type.value === ORDER_TYPE_STANDARD) ??
    defaultStandardOrderType;
  const stockOrderType =
    orderTypes.find(type => type.value === ORDER_TYPE_STOCK) ??
    defaultStockOrderType;

  const defaultOrderType =
    orderTypes.find(type => !!type.default)?.value ?? ORDER_TYPE_STANDARD;
  useEffect(() => {
    if (!orderType) {
      setFieldValue(FIELD_NAME, defaultOrderType ?? '');
    }
  }, [defaultOrderType, orderType, setFieldValue]);

  return (
    <RadioGroup label={t('CAT_SHOPPING_PREFERENCES_ORDER_TYPE')} showRequired>
      <Field
        type="radio"
        as={Radio}
        name={FIELD_NAME}
        id={`${idPrefix}_order-type_stock`}
        label={stockOrderType.label || defaultStockLabel}
        value={stockOrderType.value}
      />
      <Field
        type="radio"
        as={Radio}
        name={FIELD_NAME}
        id={`${idPrefix}_order-type_standard`}
        label={standardOrderType.label || defaultStdLabel}
        value={standardOrderType.value}
      />
    </RadioGroup>
  );
};

OrderType.propTypes = {
  idPrefix: PropTypes.string,
  orderTypes: PropTypes.arrayOf(
    PropTypes.shape({
      default: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.string
    })
  )
};

export default OrderType;
