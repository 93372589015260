import PropTypes from 'prop-types';
import Container from '../Container';
import FooterCheckout from '../FooterCheckout';
import { FOOTER_ROOT, FOOTER_CHECKOUT_ROOT } from '../../../constants/targets';
import { normalizeUrl } from '../../../utils';
import { DealerLocatorWrapper } from '@app/components/dealer-locator-react';
import { useDrawer } from '@cat-ecom/pcc-components';

const FooterWrapper = ({ location }) => {
  const footerTarget = document.getElementById(FOOTER_ROOT);
  const checkoutFooterTarget = document.getElementById(FOOTER_CHECKOUT_ROOT);
  const languageURL = `${location?.pathname}${location?.search}${location?.hash}`;
  const drawer = useDrawer({
    id: 'footer-blocks-dealer-locator',
    size: 'lg'
  });

  return (
    <>
      {footerTarget && (
        <Container
          portalTarget={footerTarget}
          href={normalizeUrl(languageURL)}
          openDealerLocatorDrawer={drawer.openDrawer}
        />
      )}
      {checkoutFooterTarget && (
        <FooterCheckout portalTarget={checkoutFooterTarget} />
      )}
      <DealerLocatorWrapper drawer={drawer} />
    </>
  );
};

export default FooterWrapper;

FooterWrapper.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
    hash: PropTypes.string
  })
};
