export const convertStringToDate = (date, timeZone) => {
  if (typeof date === 'string') {
    return new Date(new Date(date).toLocaleString('en-US', { timeZone }));
  }
  return new Date(
    date.toLocaleString('en-US', {
      timeZone
    })
  );
};
/**
 * 02/10/2024 to FEB 10 format
 * @param  {dateConfig} Eg:- { month: "short", day: '2-digit'  }
 * @returns {Object} {month: FEB , day:02}
 */

export const formatDateToLocale = (
  date,
  dateConfig = { month: 'short', day: '2-digit' }
) => {
  return {
    month: new Date(date)
      .toLocaleString('en-US', { month: dateConfig.month })
      .toUpperCase(),
    day: new Date(date).toLocaleString('en-US', { day: dateConfig.day })
  };
};
