import { STATUS } from '@app/constants/commonConstants';
import { headerInitialStore } from './initialStore';
import * as types from './constants';

const headerReducer = (state = headerInitialStore, action) => {
  switch (action.type) {
    case types.HEADER_GET_INFO_BEGIN: {
      return {
        ...state,
        getHeaderInfoLoading: true,
        getHeaderInfoSuccess: undefined
      };
    }
    case types.HEADER_GET_INFO_SUCCESS: {
      return {
        ...state,
        getHeaderInfoLoading: false,
        getHeaderInfoSuccess: true,
        ...action.payload
      };
    }
    case types.HEADER_GET_INFO_FAIL: {
      return {
        ...state,
        getHeaderInfoSuccess: false,
        getHeaderInfoLoading: false
      };
    }
    case types.HEADER_GET_WAFFLE_BEGIN: {
      return {
        ...state,
        getWaffleInfoLoading: true,
        getWaffleInfoSuccess: undefined
      };
    }
    case types.HEADER_GET_WAFFLE_SUCCESS: {
      const waffle = { ...action.payload };
      return {
        ...state,
        getWaffleInfoSuccess: true,
        getWaffleInfoLoading: false,
        waffle
      };
    }
    case types.HEADER_GET_WAFFLE_FAIL: {
      return {
        ...state,
        getWaffleInfoSuccess: false,
        getWaffleInfoLoading: false
      };
    }
    case types.HEADER_REDIRECT_BEGIN: {
      return {
        ...state,
        redirect: { status: STATUS.PENDING }
      };
    }
    case types.HEADER_REDIRECT_FAIL: {
      return {
        ...state,
        redirect: { status: STATUS.REJECTED }
      };
    }
    case types.HEADER_REDIRECT_SUCCESS: {
      const redirectInfo = { ...action.payload, status: STATUS.RESOLVED };
      return {
        ...state,
        redirect: redirectInfo
      };
    }
    default: {
      return state;
    }
  }
};

export { headerInitialStore };

export default headerReducer;
