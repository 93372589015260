import PropTypes from 'prop-types';
import { AssociatedDealerSearchCustomerForm } from '../AssociatedDealerSearchCustomerForm';
import { Conditional, Modal } from '@cat-ecom/pcc-components';
import styles from '../../styles.module.scss';
import cx from 'classnames';

export const AssociatedDealerSearchCustomerModal = ({
  modal,
  formMethods,
  onSubmit,
  searchError,
  dealer,
  loading,
  error,
  className,
  children
}) => {
  return (
    <Modal
      className={cx(styles['modal'], className)}
      dismissible={false}
      {...modal.modalProps}
    >
      <Conditional test={modal.modalProps.isActive}>
        <AssociatedDealerSearchCustomerForm
          formMethods={formMethods}
          searchError={searchError}
          onSubmit={onSubmit}
          dealer={dealer}
          loading={loading}
          error={error}
        />
        {children}
      </Conditional>
    </Modal>
  );
};

AssociatedDealerSearchCustomerModal.propTypes = {
  modal: PropTypes.any,
  formMethods: PropTypes.any,
  onSubmit: PropTypes.func,
  dealer: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  loading: PropTypes.bool,
  error: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.element
};
