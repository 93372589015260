import { useState } from 'react';
import PropTypes from 'prop-types';
import { AlloyButton } from 'cat-ecommerce-alloy';
import { useTranslation } from 'react-i18next';
import EquipmentSuggestions from '../EquipmentSuggestions';
import styles from '../../../MyEquipment.module.scss';
import {
  EQUIPMENT_PAGINATION_LIMIT,
  EQUIPMENT_RESULTS_LIMIT
} from './constants';

const MoreSuggestions = ({
  modalCtx,
  results = [],
  otherResults = [],
  limit,
  resetState,
  formikProps,
  highlight
}) => {
  const [resultlimit, setResultLimit] = useState(limit);
  const [showRefiningText, setShowRefiningText] = useState(false);
  const handleMoreSuggestions = () => {
    setResultLimit(
      resultlimit + EQUIPMENT_PAGINATION_LIMIT >
        results.length + otherResults.length
        ? results.length + otherResults.length
        : resultlimit + EQUIPMENT_PAGINATION_LIMIT
    );
    setShowRefiningText(true);
  };
  modalCtx.hideOverflow();
  const { t } = useTranslation();
  return (
    <div data-testid="more-suggests-wrapper">
      <EquipmentSuggestions
        resetState={resetState}
        formikProps={formikProps}
        results={results}
        otherResults={otherResults}
        limit={resultlimit}
        modalCtx={modalCtx}
        highlight={highlight}
        skipGAEvent={true} // this will prevent redundant gaEvent fire
        showRefiningText={showRefiningText}
      />
      {resultlimit < results.length + otherResults.length &&
        resultlimit < EQUIPMENT_RESULTS_LIMIT && (
          <div className="d-flex justify-content-center mb-2">
            <AlloyButton
              buttonType="primary"
              addClass={`${styles['myEquipment--LoadMore']}`}
              onClick={() => handleMoreSuggestions()}
            >
              {t('LOAD_MORE')}
            </AlloyButton>
          </div>
        )}
    </div>
  );
};

export default MoreSuggestions;

MoreSuggestions.propTypes = {
  limit: PropTypes.number.isRequired,
  resetState: PropTypes.func.isRequired,
  formikProps: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired
  }).isRequired,
  modalCtx: PropTypes.shape({
    hideOverflow: PropTypes.func.isRequired
  }).isRequired,
  highlight: PropTypes.string,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      equipmentFamily: PropTypes.string,
      imageURL: PropTypes.string,
      model: PropTypes.string,
      partNumber: PropTypes.string,
      productId: PropTypes.string
    })
  ),
  otherResults: PropTypes.arrayOf(
    PropTypes.shape({
      equipmentFamily: PropTypes.string,
      imageURL: PropTypes.string,
      model: PropTypes.string,
      partNumber: PropTypes.string,
      productId: PropTypes.string
    })
  )
};
