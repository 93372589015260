import SignInButton from '@app/components/common/LoginAndRegisterButtons/SignInButton/SignInButton';
import RegisterButton from '@app/components/common/LoginAndRegisterButtons/RegisterButton/RegisterButton';
import {
  SubheaderLoginButtonsWrapper,
  useModal
} from '@cat-ecom/pcc-components';
import { DealerLocatorModalWrapper } from '@app/components/dealer-locator-react';
import { CreateAccountDescription } from '@app/components/dealer-locator-react/CreateAccountDescription/CreateAccountDescription';
import { useTranslation } from 'react-i18next';

const LoginAndRegistrationButtons = () => {
  const { t } = useTranslation();
  const modal = useModal({
    isRequired: true,
    heading: `${t('STEP_1')}: ${t('REGISTER_MODAL_SELECT_DEALER_STORE')}`,
    backButton: false,
    size: 'md'
  });

  return (
    <>
      <SubheaderLoginButtonsWrapper>
        <SignInButton />
        <RegisterButton openDealerLocator={modal.initiatorProps.onClick} />
      </SubheaderLoginButtonsWrapper>
      <DealerLocatorModalWrapper isRegistration modal={modal}>
        <CreateAccountDescription />
      </DealerLocatorModalWrapper>
    </>
  );
};

export default LoginAndRegistrationButtons;
