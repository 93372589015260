import PropTypes from 'prop-types';

import { CatButton } from 'blocks-react/bedrock/components/Button/Button.js';
import { CatIconClose } from 'blocks-react/bedrock/components/Icons/Close/Close.js';
import { CatIconMenu } from 'blocks-react/bedrock/components/Icons/Menu/Menu.js';
import { BUTTON_SIZES } from '@app/constants/commonConstants';

const HeaderMobileMenu = ({ isMobileMenuActive, clickHandler }) => {
  return (
    <CatButton
      className="c-top-nav__menu-button"
      aria-expanded={isMobileMenuActive}
      onClick={clickHandler}
      hideText={true}
      size={BUTTON_SIZES.LG}
      inverted={true}
      data-testid="header-hamburger-menu-icon"
    >
      {isMobileMenuActive ? (
        <CatIconClose data-testId="cat-icon-close" slot="before" />
      ) : (
        <CatIconMenu data-testId="cat-icon-menu" slot="before" size="lg" />
      )}
      Menu
    </CatButton>
  );
};

HeaderMobileMenu.propTypes = {
  isMobileMenuActive: PropTypes.bool,
  clickHandler: PropTypes.func
};

export default HeaderMobileMenu;
