import { useTranslation } from 'react-i18next';
import { Input } from 'cat-ecommerce-alloy';
import Field from '../../../../common/form/Field';

const CityInput = () => {
  const [t] = useTranslation();

  return (
    <Field
      as={Input}
      className={`w-100 mb-0`}
      hideErrorMessage={true}
      id="dealer-user_city"
      label={t('CAT_EMAIL_CITY')}
      name="city"
      showOptional={true}
      type="text"
    />
  );
};

export default CityInput;
