import * as types from './constants';
import * as savedListDetailTypes from '../savedListDetail/constants';
import { AC_FAVORITE_LIST_LIMIT } from './constants';
import { STATUS, PENDING_QUOTE_ACTIONS } from '@app/constants/commonConstants';
import { accountInitialStore } from './initialStore';
import { setSavedListLineItemLoading, filterHandler } from './utils';

const accountReducer = (state = accountInitialStore, action) => {
  switch (action.type) {
    case types.AC_LOAD_PAYMENTMETHODS_SUCCESS: {
      const { paymentMethods, dealerName } = action.payload;
      return {
        ...state,
        paymentMethods,
        dealerName,
        paymentMethodsStatus: STATUS.RESOLVED
      };
    }
    case types.AC_DELETE_PAYMENTMETHODS_SUCCESS: {
      const paymentMethods = Array.from(state.paymentMethods).filter(
        e => action.payload.profileId !== e.xchkout_creditCardProfileId
      );
      return {
        ...state,
        paymentMethods
      };
    }
    case types.AC_LOAD_RECENTORDERHISTORY_BEGINS:
      return {
        ...state,
        recentOrderHistoryStatus: STATUS.PENDING
      };
    case types.AC_LOAD_RECENTORDERHISTORY_SUCCESS:
      return {
        ...state,
        recentOrderHistory: action.payload.recentOrderHistory,
        recentOrderHistoryStatus: STATUS.RESOLVED
      };
    case types.AC_LOAD_RECENTORDERHISTORY_FAILS:
      return {
        ...state,
        recentOrderHistoryStatus: STATUS.REJECTED
      };
    case types.AC_CLEAR_PAYMENTMETHODS:
      return {
        ...state,
        paymentMethods: [],
        dealerName: ''
      };
    case types.AC_LOAD_SAVEDLIST_BEGIN:
      return {
        ...state,
        isLoading: true,
        savedListsStatus: STATUS.PENDING,
        savedListsLoadingState: STATUS.PENDING,
        listPreferenceLoading: action.payload.isPreferenceUpdated ? true : false
      };
    case types.AC_LOAD_SAVEDLIST_SUCCESS:
      return {
        ...state,
        savedLists: action.savedLists,
        isLoading: false,
        searchReset: false,
        updatedFieldSortFlag: false,
        savedListsStatus: STATUS.RESOLVED,
        savedListsLoadingState: STATUS.RESOLVED,
        isNewListLoading: false,
        listPreferenceLoading: false,
        isFavoriteListLimitExceeds:
          state.savedLists?.filter(item => item.favorite).length >=
          AC_FAVORITE_LIST_LIMIT
      };
    case types.AC_LOAD_SAVEDLIST_FAIL:
      return {
        ...state,
        isLoading: false,
        savedListsStatus: STATUS.REJECTED,
        savedListsLoadingState: STATUS.REJECTED,
        listPreferenceLoading: false
      };
    case types.AC_CHANGE_SELFFOL_SUCCESS:
      return {
        ...state,
        updatedFieldSortFlag: true,
        selfFOL: action.selfFOL,
        resetSavedListPagination: true
      };
    case types.AC_SHOW_FAV_LIST_BEGIN:
      return {
        ...state,
        listPreferenceLoading: true,
        resetSavedListPagination: true
      };
    case types.AC_CREATE_SAVEDLIST_BEGIN:
      return {
        ...state,
        isLoading: true,
        isNewListLoading: true,
        createListError: false
      };
    case types.AC_DUPLICATE_SAVEDLIST_BEGIN: {
      const updatedList = setSavedListLineItemLoading(
        state.savedLists,
        action.payload.orderId,
        true
      );
      return {
        ...state,
        savedLists: updatedList,
        isLoading: true,
        isNewListLoading: true
      };
    }
    case types.AC_CREATE_SAVEDLIST_FAIL:
      return {
        ...state,
        isLoading: false,
        isNewListLoading: false,
        createListError: true
      };
    case types.AC_DUPLICATE_SAVEDLIST_FAIL: {
      const updatedList = setSavedListLineItemLoading(
        state.savedLists,
        action.payload.orderId,
        false
      );
      return {
        ...state,
        savedLists: updatedList,
        isLoading: false,
        isNewListLoading: false
      };
    }
    case types.AC_CREATE_SAVEDLIST_SUCCESS:
      return {
        ...state,
        updatedFieldSortFlag: true,
        createListError: false,
        resetSavedListPagination: true
      };
    case types.AC_DUPLICATE_SAVEDLIST_SUCCESS:
    case types.AC_DELETE_SAVEDLIST_FAIL: {
      const updatedList = setSavedListLineItemLoading(
        state.savedLists,
        action.payload.orderId,
        false
      );
      return {
        ...state,
        savedLists: updatedList,
        isLoading: false,
        resetSavedListPagination: true
      };
    }
    case types.AC_DELETE_SAVEDLIST_BEGIN: {
      const updatedList = setSavedListLineItemLoading(
        state.savedLists,
        action.payload.orderId,
        true
      );
      return {
        ...state,
        savedLists: updatedList,
        isLoading: true
      };
    }
    case types.AC_DELETE_SAVEDLIST_SUCCESS:
      return { ...state, isLoading: false, resetSavedListPagination: true };
    case types.AC_ADDCART_SAVEDLIST_BEGIN: {
      // This action also hits the savedListDetail reducer, so the way to differentiate is by looking at the pathname
      const updatedList = setSavedListLineItemLoading(
        state.savedLists,
        action.payload.orderId,
        true
      );
      if (
        window.location.pathname.includes(
          savedListDetailTypes.SAVED_LIST_DETAIL_PATH_STRING
        )
      ) {
        return { ...state };
      } else {
        return {
          ...state,
          savedLists: updatedList,
          isLoading: true,
          isSavedListAddToCartLoading: STATUS.PENDING,
          savedListsLoadingState: STATUS.PENDING
        };
      }
    }
    case types.AC_ADDCART_SAVEDLIST_FAIL:
      // This action also hits the savedListDetail reducer, so the way to differentiate is by looking at the pathname
      if (
        window.location.pathname.includes(
          savedListDetailTypes.SAVED_LIST_DETAIL_PATH_STRING
        )
      ) {
        return { ...state };
      } else {
        return {
          ...state,
          isLoading: false,
          isSavedListAddToCartLoading: STATUS.REJECTED,
          savedListsLoadingState: STATUS.REJECTED
        };
      }
    case types.AC_UPLOAD_SAVEDLIST_BEGIN:
      return { ...state, isLoading: true };
    case types.AC_UPLOAD_SAVEDLIST_FAIL:
      return { ...state, isLoading: false };
    case types.AC_UPLOAD_SAVEDLIST_SUCCESS:
      return { ...state, updatedFieldSortFlag: true };
    case types.AC_SEARCH_OPTIONS_SET_DATA:
      return {
        ...state,
        searchOptions: action.searchOptions,
        resetSavedListPagination: true
      };
    case types.AC_SAVEDLIST_SEARCH_SUCCESS:
      return {
        ...state,
        searchOptions: action.searchOptions,
        searchSuccess: true,
        updatedFieldSortFlag: true
      };
    case types.AC_SAVEDLIST_SEARCH_RESET:
      return {
        ...state,
        searchOptions: {},
        searchReset: true,
        searchSuccess: false
      };

    case types.AC_ADD_TO_SAVED_LISTS_BEGIN:
      return { ...state, isLoading: true, addProductToSavedListError: false };
    case types.AC_ADD_TO_SAVED_LISTS_SUCCESS:
      return { ...state, isLoading: false, addProductToSavedListError: false };
    case types.AC_ADD_TO_SAVED_LISTS_FAIL:
      return { ...state, isLoading: false, addProductToSavedListError: true };
    case types.AC_CREATE_SAVED_LIST_BEGIN:
      return { ...state };
    case types.AC_CREATE_SAVED_LIST_SUCCESS:
      return { ...state };
    case types.AC_CREATE_SAVED_LIST_FAIL:
      return { ...state };
    case types.AC_APPROVE_PENDING_QUOTE_BEGIN:
      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          pendingQuoteActionsStatus: STATUS.PENDING,
          placeOrderStatus: STATUS.PENDING,
          pendingQuoteActionsName: PENDING_QUOTE_ACTIONS.PLACE_ORDER
        }
      };
    case types.AC_APPROVE_PENDING_QUOTE_SUCCESS:
      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          pendingQuoteActionsStatus: STATUS.RESOLVED,
          placeOrderStatus: STATUS.RESOLVED,
          pendingQuoteActionsName: PENDING_QUOTE_ACTIONS.PLACE_ORDER
        }
      };
    case types.AC_APPROVE_PENDING_QUOTE_FAIL:
      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          pendingQuoteActionsStatus: STATUS.REJECTED,
          placeOrderStatus: STATUS.REJECTED,
          pendingQuoteActionsName: PENDING_QUOTE_ACTIONS.PLACE_ORDER
        }
      };
    case types.AC_REJECT_PENDING_QUOTE_BEGIN:
      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          rejectOrderStatus: STATUS.PENDING
        }
      };
    case types.AC_REJECT_PENDING_QUOTE_SUCCESS:
      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          rejectOrderStatus: STATUS.RESOLVED
        }
      };
    case types.AC_REJECT_PENDING_QUOTE_FAIL:
      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          rejectOrderStatus: STATUS.REJECTED
        }
      };
    case types.AC_PENDING_QUOTE_SYSTEMFEEDBACK:
      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          pendingQuotesErrorDismiss: action.payload
        }
      };

    case types.AC_LOAD_PENDINGQUOTES_BEGIN:
      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          status: STATUS.PENDING
        }
      };
    case types.AC_LOAD_PENDINGQUOTES_SUCCESS:
      return {
        ...state,
        pendingQuotes: {
          ...action.payload,
          status: STATUS.RESOLVED,
          expiringQuotesCount: action.payload?.expireQuoteCount,
          dcn: action.payload.dcn,
          filters: action.payload.filters
        }
      };
    case types.AC_LOAD_PENDING_APPROVAL_QUOTES_SUCCESS:
      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          status: STATUS.RESOLVED
        },
        pendingApprovalQuotes: {
          ...action.payload,
          dcn: action.payload.dcn,
          filters: action.payload.filters
        }
      };
    case types.AC_LOAD_PENDINGQUOTES_FAIL:
      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          status: STATUS.REJECTED
        }
      };
    case types.AC_DELETE_PENDINGQUOTE_BEGIN: {
      const quoteOrderPayloadId = action.payload.orderId;
      const quotesUpdate = state.pendingQuotes.quotes?.map(data =>
        data.orderId === quoteOrderPayloadId
          ? {
              ...data,
              quoteLineItemLoading: true
            }
          : data
      );
      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          quotes: quotesUpdate,
          pendingQuoteActionsStatus: STATUS.PENDING,
          pendingQuoteActionsName: PENDING_QUOTE_ACTIONS.DELETE
        }
      };
    }
    case types.AC_DELETE_PENDINGQUOTE_FAIL: {
      const quoteOrderPayloadId = action.payload.orderId;
      const quotesUpdate = state.pendingQuotes.quotes?.map(data =>
        data.orderId === quoteOrderPayloadId
          ? {
              ...data,
              quoteLineItemLoading: false
            }
          : data
      );

      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          quotes: quotesUpdate,
          pendingQuoteActionsStatus: STATUS.REJECTED,
          pendingQuoteActionsName: PENDING_QUOTE_ACTIONS.DELETE
        }
      };
    }
    case types.AC_DELETE_PENDINGQUOTE_SUCCESS: {
      const quoteOrderPayloadId = action.payload.orderId;
      const quotesPostDelete =
        state.pendingQuotes?.quotes &&
        Array.from(state.pendingQuotes.quotes).filter(
          quote => quoteOrderPayloadId !== quote.orderId
        );
      const quotesUpdate = quotesPostDelete?.map(data =>
        data.orderId === quoteOrderPayloadId
          ? {
              ...data,
              quoteLineItemLoading: false
            }
          : data
      );
      const pendingQuotes = {
        ...state.pendingQuotes,
        quotes: quotesPostDelete && quotesUpdate,
        pendingQuoteActionsStatus: STATUS.RESOLVED,
        pendingQuoteActionsName: PENDING_QUOTE_ACTIONS.DELETE
      };
      return {
        ...state,
        pendingQuotes
      };
    }
    case types.AC_EDIT_PENDINGQUOTE_BEGIN: {
      const quoteOrderPayloadId = action.payload.orderId;
      const quotesUpdate = state.pendingQuotes.quotes?.map(data =>
        data.orderId === quoteOrderPayloadId
          ? {
              ...data,
              quoteLineItemLoading: true
            }
          : data
      );
      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          quotes: quotesUpdate,
          pendingQuoteActionsStatus: STATUS.PENDING,
          pendingQuoteActionsName: PENDING_QUOTE_ACTIONS.EDIT
        }
      };
    }
    case types.AC_EDIT_PENDINGQUOTE_SUCCESS: {
      const quoteOrderPayloadId = action.payload.orderId;
      const quotesUpdate = state.pendingQuotes.quotes?.map(data =>
        data.orderId === quoteOrderPayloadId
          ? {
              ...data,
              quoteLineItemLoading: false
            }
          : data
      );
      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          quotes: quotesUpdate,
          pendingQuoteActionsStatus: STATUS.RESOLVED,
          pendingQuoteActionsName: PENDING_QUOTE_ACTIONS.EDIT
        }
      };
    }
    case types.AC_EDIT_PENDINGQUOTE_FAIL: {
      const quoteOrderPayloadId = action.payload.orderId;
      const quotesUpdate = state.pendingQuotes.quotes?.map(data =>
        data.orderId === quoteOrderPayloadId
          ? {
              ...data,
              quoteLineItemLoading: false
            }
          : data
      );
      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          quotes: quotesUpdate,
          pendingQuoteActionsStatus: STATUS.REJECTED,
          pendingQuoteActionsName: PENDING_QUOTE_ACTIONS.EDIT
        }
      };
    }
    case types.AC_OPEN_CONFLICT_CONFIRMATION:
      return {
        ...state,
        isOpenConflictConfirmation: action.payload.isOpen,
        addItemSaveListData: action.payload.clickData,
        saveListParts: action.payload.saveListData
      };
    case types.AC_OPEN_CONFLICT_RESOLUTION:
      return {
        ...state,
        isOpenConflictResolution: action.isOpen
      };
    case types.AC_HAS_CONFLICT:
      return {
        ...state,
        hasConflictFlag: action.hasConflictFlag
      };

    case types.AC_MARK_LIST_FAVORITE_BEGIN: {
      const updatedList = setSavedListLineItemLoading(
        state.savedLists,
        action.payload.orderId,
        true
      );
      return {
        ...state,
        markFavoriteStatus: STATUS.PENDING,
        savedLists: updatedList,
        isFavoriteListLimitExceeds: false
      };
    }
    case types.AC_MARK_LIST_FAVORITE_SUCCESS:
      const selectedList = state.savedLists.map(item =>
        item.orderId === action.payload.orderId
          ? {
              ...item,
              favorite: action.payload.isFavorite,
              isSavedListLineItemLoading: false
            }
          : item
      );
      return {
        ...state,
        savedLists: selectedList,
        markFavoriteStatus: STATUS.RESOLVED,
        resetSavedListPagination: false
      };
    case types.AC_MARK_LIST_FAVORITE_FAIL: {
      const updatedList = setSavedListLineItemLoading(
        state.savedLists,
        action.payload.orderId,
        false
      );
      return {
        ...state,
        savedLists: updatedList,
        markFavoriteStatus: STATUS.REJECTED,
        isFavoriteListLimitExceeds: false,
        resetSavedListPagination: false
      };
    }

    case types.AC_SAVED_LIST_LIMIT_STATUS: {
      const selectedList = state.savedLists.map(item =>
        item.orderId === action.payload.orderId
          ? {
              ...item,
              isSavedListLineItemLoading:
                state.savedLists.filter(item => item.favorite).length <
                  AC_FAVORITE_LIST_LIMIT || !action.payload.isFavorite
                  ? true
                  : false
            }
          : item
      );
      return {
        ...state,
        savedLists: selectedList,
        resetSavedListPagination: false,
        isFavoriteListLimitExceeds:
          state.savedLists.filter(item => item.favorite).length >=
          AC_FAVORITE_LIST_LIMIT
      };
    }
    case types.AC_SAVED_LIST_FAVORITE_TOGGLE:
      return {
        ...state,
        showFavoriteSavedLists: action.payload,
        resetSavedListPagination: true
      };
    case types.SAVED_LIST_UCID_GET_BEGIN: {
      return {
        ...state,
        customerOrganizationsStatus: STATUS.PENDING,
        customerOrganizations: []
      };
    }
    case types.SAVED_LIST_UCID_GET_SUCCESS: {
      return {
        ...state,
        customerOrganizationsStatus: STATUS.RESOLVED,
        customerOrganizations: action.payload?.customerOrganizations
      };
    }
    case types.SAVED_LIST_UCID_GET_FAIL: {
      return {
        ...state,
        customerOrganizationsStatus: STATUS.REJECTED,
        customerOrganizations: []
      };
    }

    case types.SAVED_LIST_UCID_GET_IDLE: {
      return {
        ...state,
        customerOrganizationsStatus: STATUS.IDLE,
        customerOrganizations: []
      };
    }

    case types.AC_PENDINGQUOTE_FILTER_CHANGE: {
      return {
        ...state,
        pendingQuotes: {
          ...state.pendingQuotes,
          filters: action.payload,
          quotes: filterHandler(
            action.payload,
            state.pendingQuotes.quotes || []
          )
        }
      };
    }

    default:
      return state;
  }
};
export { accountInitialStore };
export default accountReducer;
