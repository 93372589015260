import { isDev } from './commonUtils';
import {
  BFF_ENDPOINT_HOST,
  IMAGE_PRESETS,
  IMAGE_URL,
  LOCALHOST_CONTEXT_PREFIX,
  LOCALHOST_CONTEXT_PREVIEW_PREFIX
} from '../constants/commonConstants';

export const replaceUrlContextForPreview = (url = '') => {
  if (
    typeof url === 'string' &&
    'catReact' in window &&
    'envInPreview' in window.catReact
  ) {
    url =
      window.catReact.envInPreview === 'true'
        ? url.replace('/wcs/resources/', '/wcs/previewresources/')
        : url;
    url =
      window.catReact.envInPreview === 'true'
        ? url.replace('/webapp/wcs/stores/', '/webapp/wcs/preview/')
        : url;
  }

  return url;
};

/**
 * Normalize an URL with the Localhost Prefix if it's necesary
 * @param {string} url URL to be normalized
 * @returns {string}
 */
export const normalizeUrl = (url = '') => {
  const getLeadingForwardSlash = urlString => (urlString[0] !== '/' ? '/' : '');
  if (
    isDev() &&
    typeof url === 'string' &&
    url.substring(0, 4) !== 'http' &&
    url.substring(0, 4) !== 'tel:' &&
    !url.startsWith(LOCALHOST_CONTEXT_PREFIX) &&
    !url.startsWith(LOCALHOST_CONTEXT_PREVIEW_PREFIX)
  ) {
    url = `${LOCALHOST_CONTEXT_PREFIX}${getLeadingForwardSlash(url)}${url}`;
  }

  return replaceUrlContextForPreview(url);
};

/**
 * Map a Object with links in its values to normalized links using ROOT_URL variable from the environment
 * @param {Record<string, string>} links Object of links
 * @returns {Record<string, string>}
 */
export const normalizeLinks = links => {
  const rootUrl = isDev() ? LOCALHOST_CONTEXT_PREFIX : '';
  return Object.entries(links).reduce(
    (allLinks, [linkKey, linkValue]) => ({
      ...allLinks,
      [linkKey]: `${rootUrl}${linkValue}`
    }),
    {}
  );
};

/**
 *
 * @param {string} link Normalized link
 * @returns {string}
 */
export const unNormalizeLink = linkValue => {
  return linkValue.startsWith(LOCALHOST_CONTEXT_PREFIX)
    ? linkValue.slice(LOCALHOST_CONTEXT_PREFIX.length)
    : linkValue;
};

// TODO: Document this function
export const getImageUrl = (cNum, presets) => {
  const imagePresets = presets || IMAGE_PRESETS;
  return `${IMAGE_URL}${cNum}?$PCC-e3$${imagePresets}`;
};

export const getImageUrlPM = (cNumUrl, presets) => {
  const imagePresets = presets || IMAGE_PRESETS;
  return `${cNumUrl}?$PCC-e3$${imagePresets}`;
};

export const getBFFUrl = url => {
  return isDev() ? `${BFF_ENDPOINT_HOST}${url}` : url;
};

export const getHttpsURL = url =>
  !url.startsWith('http://') && !url.startsWith('https://')
    ? `https://${url}`
    : url;
