import PropTypes from 'prop-types';
import { Portal } from '@cat-ecom/pcc-components';
import { DRAWERS_ROOT } from '@app/constants/targets';
import SisEquipmentDrawer from './SisEquipmentDrawer';

const Container = ({ drawerProps, onDrawerClose }) => {
  return (
    <Portal id={DRAWERS_ROOT}>
      <SisEquipmentDrawer
        drawerProps={drawerProps}
        onDrawerClose={onDrawerClose}
      />
    </Portal>
  );
};

Container.propTypes = {
  drawerProps: PropTypes.object,
  onDrawerClose: PropTypes.func
};

export default Container;
