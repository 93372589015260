import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import FooterLegalLinksRow from '../FooterLegalLinksRow';
import { Footer } from '@cat-ecom/pcc-components';

const FooterCheckout = ({ portalTarget }) => {
  return createPortal(
    <Footer data-testid="footer" isCheckout>
      <FooterLegalLinksRow isCheckout />
    </Footer>,
    portalTarget
  );
};

FooterCheckout.propTypes = {
  portalTarget: PropTypes.instanceOf(Element).isRequired
};

export default FooterCheckout;
