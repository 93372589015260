import { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { AlloyButton, CloseIcon } from 'cat-ecommerce-alloy';
import { APP_BANNER_LOGO, APP_BANNER_TITLE } from '../constants';
import styles from './AppBanner.module.scss';
import { useTranslation } from 'react-i18next';
import { setCookieToSkipAppBanner } from '../utils';
import { useOneTrust } from '@app/hooks';
import { isAndroidDevice } from '@app/utils/browserUtils';
import { DEVICE_TYPE } from '@app/constants/analyticsConstants';
import { useSelector } from 'react-redux';

const AppBanner = ({
  isClosed = false,
  handleClick = () => {},
  onClose = () => {}
}) => {
  const { isFunctionalAccepted } = useOneTrust();
  const [isClosedBanner, setIsClosedBanner] = useState(false);
  const [t] = useTranslation();
  const handleCloseButtonClick = () => {
    onClose && onClose();
    if (isFunctionalAccepted) {
      setCookieToSkipAppBanner();
    }
    setIsClosedBanner(true);
  };
  const pccSmartBannerNewStyleFlag = useSelector(
    state => state.featureFlag.PCC_SmartBannerNewStyleFlag
  );
  const device = isAndroidDevice() ? DEVICE_TYPE.ANDROID : DEVICE_TYPE.IOS;

  const newStylesAndroid = {
    availableX: false,
    bannerContainer: '',
    textStyle: {
      title: 'text-xs text-white text-univers--bold text-uppercase m-0',
      subTitle: 'text-xxs u-color--MediumGray m-0'
    },
    imageIcon: 'app-banner__logo-android',
    background: 'app-banner__container--new-styles',
    closeButton: ''
  };

  const newStylesIOS = {
    availableX: true,
    bannerContainer: isClosedBanner ? '' : 'py-2',
    background: 'app-banner__container--new-styles',
    textStyle: {
      title: 'text-white text-univers--bold text-uppercase m-0',
      subTitle: 'text-xs u-color--MediumGray m-0'
    },
    imageIcon: 'app-banner__logo-ios',
    closeButton: 'app-banner__close-button-new-styles'
  };

  const regularStyles = {
    availableX: true,
    imageIcon: 'app-banner__logo',
    closeButton: 'app-banner__close-button',
    background: 'app-banner__container--regular',
    bannerContainer: '',
    textStyle: {
      title: 'text-univers--bold text-uppercase m-0',
      subTitle: 'text-xs u-color--MediumGray m-0'
    }
  };

  const stylesAndroid = pccSmartBannerNewStyleFlag
    ? newStylesAndroid
    : regularStyles;

  const stylesIOS = pccSmartBannerNewStyleFlag ? newStylesIOS : regularStyles;

  const stylesAccordingDevices = {
    [DEVICE_TYPE.ANDROID]: stylesAndroid,
    [DEVICE_TYPE.IOS]: stylesIOS
  };

  const containerClassNames = cx(
    [
      styles['app-banner__container'],
      stylesAccordingDevices[device].bannerContainer,
      styles[stylesAccordingDevices[device].background]
    ],
    {
      [styles['app-banner__container--hidden']]: isClosedBanner || isClosed
    }
  );

  return (
    <div className={containerClassNames} data-testid="app-banner">
      <div
        className={cx('d-flex align-items-center', {
          'ps-2': !stylesAccordingDevices[device].availableX
        })}
      >
        {stylesAccordingDevices[device].availableX ? (
          <AlloyButton
            className={cx([styles[stylesAccordingDevices[device].closeButton]])}
            onClick={handleCloseButtonClick}
            size="large"
          >
            <CloseIcon />
          </AlloyButton>
        ) : null}
        <div className={styles[stylesAccordingDevices[device].imageIcon]}>
          <img src={APP_BANNER_LOGO} loading="lazy" alt="app-banner" />
        </div>
        <div>
          <p className={stylesAccordingDevices[device].textStyle.title}>
            {APP_BANNER_TITLE}
          </p>
          <p className={stylesAccordingDevices[device].textStyle.subTitle}>
            {t('RETAIL_APP_SHORT_DESCRIPTION')}
          </p>
        </div>
      </div>
      <AlloyButton
        onClick={handleClick}
        buttonType="ghost"
        size="dynamic"
        className="px-3"
      >
        {t('OPEN_BUTTON')}
      </AlloyButton>
    </div>
  );
};

AppBanner.propTypes = {
  onClose: PropTypes.func,
  isClosed: PropTypes.bool,
  handleClick: PropTypes.func
};

export default AppBanner;
