import * as types from './constants';
import { STATUS } from '@app/constants/commonConstants';
import { dealercustomerInitialStore } from './initialStore';

const dealerCustomerReducer = (state = dealercustomerInitialStore, action) => {
  switch (action.type) {
    case types.DEALER_CUSTOMER_STORES_SET_INFO: {
      const {
        payload: {
          currency,
          formattedZeroPrice,
          gstIncludesTax,
          isGstAvailable,
          taxDisclaimerMessage,
          taxDisclaimerMessageFooter
        }
      } = action;

      return {
        ...state,
        currency: currency ?? state.currency,
        formattedZeroPrice: formattedZeroPrice ?? state.formattedZeroPrice,
        gstIncludesTax: gstIncludesTax ?? state.gstIncludesTax,
        isGstAvailable: isGstAvailable ?? state.isGstAvailable,
        taxDisclaimerMessage:
          taxDisclaimerMessage ?? state.taxDisclaimerMessage,
        taxDisclaimerMessageFooter:
          taxDisclaimerMessageFooter ?? state.taxDisclaimerMessageFooter
      };
    }
    case types.DEALER_CUSTOMER_STORES_GET_BEGIN: {
      return {
        ...state,
        getDealerCustomerStoresStatus: STATUS.PENDING
      };
    }
    case types.DEALER_CUSTOMER_STORES_GET_SUCCESS: {
      return {
        ...state,
        getDealerCustomerStoresStatus: STATUS.RESOLVED,
        requestByDateEnabled: action.payload.requestByDateEnabled,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        }
      };
    }
    case types.DEALER_CUSTOMER_STORES_GET_FAIL: {
      return {
        ...state,
        getDealerCustomerStoresStatus: STATUS.REJECTED
      };
    }
    case types.DEALER_CUSTOMER_SHARE_UCID_LIST_BEGIN: {
      return {
        ...state,
        shareListStatus: STATUS.PENDING
      };
    }
    case types.DEALER_CUSTOMER_SHARE_UCID_LIST_FAIL:
    case types.DEALER_CUSTOMER_SHARE_UCID_LIST_STATUS_IDLE:
    case types.DEALER_CUSTOMER_SHARE_UCID_LIST_SUCCESS: {
      return {
        ...state,
        shareListStatus: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export { dealercustomerInitialStore };
export default dealerCustomerReducer;
