import { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AlloyButton,
  ListContext,
  ToastContext,
  Table,
  dataTypes,
  columnTypes,
  PrivateIcon,
  SharedIcon
} from 'cat-ecommerce-alloy';
import { useSystemFeedback } from '@app/hooks';
import { isEmpty } from '@app/utils';
import {
  addProductToSavedList,
  loadSavedListDetails,
  setConflictConfirmationFlag,
  setConflictFlag
} from '@app/store/account/actions';
import { getOrder } from '@app/store/orders/actions';
import ExceptionFeedbackBySelector from '@app/components/common/Exception/ExceptionFeedbackBySelector';
import { ERROR_DOMAIN, ERROR_PATH } from '@app/constants/errorConstants';
import {
  PRIVATE_LIST,
  PUBLIC_LIST,
  SORTING_DATE
} from '@app/constants/commonConstants';
import { ICON_SIZE, FALSE_VALUE } from '../constants';
import useConflictNotes from '../ConflictNotes/common/hooks/useConflictNotes';
import useSavedLists from '../useSavedLists';
import { addItemsPayload } from '../ConflictNotes/utils';

const SavedListsTable = ({
  saveListData,
  close,
  numDisplayed,
  fromPDPPage
}) => {
  const { items, saveType } = saveListData;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [clickData, setClickData] = useState(null);
  const [pdpPage, setPdpPage] = useState(false);
  const listCtx = useContext(ListContext);
  const ToastCtx = useContext(ToastContext);
  const cartMergeEnabled = useSelector(s => s.common?.cartMergeEnabled);
  const hasConflictFlag = useSelector(s => s.account?.hasConflictFlag);
  const { isConflictPresent } = useConflictNotes();
  const {
    setShowSavedListsModalAfterADP,
    showSavedListsModalAfterADP = false
  } = useSavedLists();
  const lists = listCtx.getList() || [];
  const { setError, clearError } = useSystemFeedback();
  const current = useSelector(s => s.orders.current);
  const newLineItemNotes = useSelector(
    state => state.orders?.byId?.[current]?.ascendingOrderItems
  );

  const addItemsPayloadData = addItemsPayload(
    newLineItemNotes,
    items,
    saveType
  );
  useEffect(() => {
    return () => {
      clearError(ERROR_DOMAIN.SHOPPING_LIST, ERROR_PATH.ADD_PRODUCT_TO_LIST);
    };
  }, [clearError]);
  const onListSelection = data => {
    setClickData(data);
    const payloadData = {
      isOpen: false,
      clickData: data,
      saveListData: items
    };
    dispatch(setConflictConfirmationFlag(payloadData));
    const conflictFlag = data.quantity === 0 ? false : null;
    dispatch(setConflictFlag(conflictFlag));
    clearError(ERROR_DOMAIN.SHOPPING_LIST, ERROR_PATH.ADD_PRODUCT_TO_LIST);
    const pdpPageFlag = fromPDPPage || showSavedListsModalAfterADP;
    pdpPageFlag && setPdpPage(true);
    setShowSavedListsModalAfterADP(false);
  };
  const addItem = () => {
    dispatch(
      addProductToSavedList({
        ...clickData,
        fromConflictNotesModal: FALSE_VALUE,
        saveListData: pdpPage ? items : addItemsPayloadData,
        close,
        ToastCtx,
        toastMessage: t('PDP_ADD_TO_LIST_TOAST')
      })
    );
    dispatch(loadSavedListDetails());
  };
  useEffect(() => {
    const nonConflictFlag = pdpPage && cartMergeEnabled && !isEmpty(clickData);
    if (cartMergeEnabled && pdpPage === false) {
      if (hasConflictFlag) {
        dispatch(setConflictFlag(null));
        const payloadData = {
          isOpen: true,
          clickData: clickData,
          saveListData: items
        };
        dispatch(setConflictConfirmationFlag(payloadData));
        close();
      } else if (hasConflictFlag === false && !pdpPage) {
        if (addItemsPayloadData.length > 0) {
          !isEmpty(clickData) && addItem();
        }
      }
    } else {
      nonConflictFlag && addItem();
      pdpPage && setPdpPage(false);
    }
  }, [cartMergeEnabled, hasConflictFlag, pdpPage, isConflictPresent]);
  useEffect(() => {
    if (!cartMergeEnabled) {
      !isEmpty(clickData) && addItem();
    }
  }, [cartMergeEnabled, clickData]);

  const type = ({ typeData }) =>
    typeData === 'private' ? (
      <PrivateIcon
        style={{ fill: '#565656' }}
        height={ICON_SIZE}
        width={ICON_SIZE}
      />
    ) : (
      <SharedIcon
        style={{ fill: '#565656' }}
        height={ICON_SIZE}
        width={ICON_SIZE}
      />
    );

  const updatedDisplayValue = ({ updatedDisplayValueData }) => (
    <span className="pe-2 color-dark-gray u-text-sm">
      {updatedDisplayValueData}
    </span>
  );

  const name = ({ nameData, typeData, orderId, quantity }) => {
    const handleClick = () => {
      dispatch(getOrder(orderId, setError));
      onListSelection({
        status: typeData === 'private' ? PRIVATE_LIST : PUBLIC_LIST,
        nameData,
        requisitionListId: orderId,
        quantity
      });
    };

    return (
      <AlloyButton
        buttonType="link"
        className="ps-2 text-md text-start text-break"
        onClick={handleClick}
      >
        {nameData}
      </AlloyButton>
    );
  };
  const getCustomRenderers = () => ({
    type,
    name,
    updatedDisplayValue
  });

  const getItems = () =>
    lists
      .slice(0, numDisplayed)
      .map(({ name, type, orderId, updatedDisplayValue, quantity }) => ({
        nameData: name,
        typeData: type,
        orderId,
        quantity,
        updatedDisplayValueData: updatedDisplayValue,
        reactKey: orderId
      }));

  const schema = [
    {
      id: 'name',
      label: t('REQUISITIONLIST_NAME'),
      align: 'start',
      isSortable: true,
      verticalAlign: 'align-center',
      padding: '4px',
      className: 'd-table-cell',
      dataType: dataTypes.ALPHANUMERIC,
      columnType: columnTypes.STRETCHED
    },
    {
      id: 'type',
      label: t('TYPE'),
      align: 'center',
      isSortable: true,
      verticalAlign: 'align-center',
      padding: '4px',
      className: 'd-table-cell',
      dataType: dataTypes.ICON,
      columnType: columnTypes.HUGGED
    },
    {
      id: 'updatedDisplayValue',
      label: t('MODIFIED'),
      align: 'end',
      isSortable: true,
      verticalAlign: 'align-center',
      padding: '4px',
      className: 'd-table-cell',
      dataType: dataTypes.NUMBER,
      sortType: SORTING_DATE,
      columnType: columnTypes.STRETCHED
    }
  ];

  return (
    <>
      <ExceptionFeedbackBySelector
        className="mb-3"
        selector={s =>
          s.errors?.[ERROR_DOMAIN.SHOPPING_LIST]?.[
            ERROR_PATH.ADD_PRODUCT_TO_LIST
          ]
        }
      />
      <div>
        <Table
          data={getItems()}
          schema={schema}
          customRenderers={getCustomRenderers()}
        />
      </div>
    </>
  );
};

SavedListsTable.propTypes = {
  saveListData: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        partNumber: PropTypes.string,
        quantity: PropTypes.string
      })
    ),
    saveType: PropTypes.string
  }),
  close: PropTypes.func,
  numDisplayed: PropTypes.number,
  fromPDPPage: PropTypes.number
};

export default SavedListsTable;
