import { Portal, useDrawer } from '@cat-ecom/pcc-components';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ModalContext, AlloyButton } from 'cat-ecommerce-alloy';
import PropTypes from 'prop-types';
import { USER_TYPE_GUEST } from '@app/constants/commonConstants';
import { DRAWERS_ROOT } from '@app/constants/targets';
import { DealerLocatorWrapper } from '@app/components/dealer-locator-react';
import { AssociatedDealerDrawer } from '@app/components/associated-dealer-block';

const FindYourDealerCTA = ({ type, buttonText }) => {
  const modalCtx = useContext(ModalContext);
  const [t] = useTranslation();
  const userType = useSelector(s => s.common.userType);
  const drawer = useDrawer({ id: 'blocks-dealer-locator', size: 'lg' });
  const ADSDrawer = useDrawer({
    id: 'associated-dealer-drawer',
    size: 'lg'
  });
  const isLoggedIn = (userType || '').toUpperCase() !== USER_TYPE_GUEST;

  const findDealerFunction = () => {
    modalCtx.closeAllModals();
    if (isLoggedIn) {
      ADSDrawer.openDrawer();
    } else {
      drawer.openDrawer();
    }
  };

  return (
    <>
      <Portal id={DRAWERS_ROOT}>
        <DealerLocatorWrapper drawer={drawer} />
        <AssociatedDealerDrawer drawer={ADSDrawer} />
      </Portal>
      <AlloyButton
        buttonType={type}
        name="find-your-dealer-cta"
        size="small"
        type="submit"
        onClick={findDealerFunction}
      >
        {buttonText ? buttonText : t('CAT_HEADER_FIND_YOUR_LOCATOR')}
      </AlloyButton>
    </>
  );
};

FindYourDealerCTA.propTypes = {
  type: PropTypes.string,
  buttonText: PropTypes.string
};

export default FindYourDealerCTA;
