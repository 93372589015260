import { AFFILIATION_DEALER } from '@app/constants/commonConstants';
import { useSelector } from 'react-redux';
import { AssociatedDealerSelectCustomerDrawer } from '../AssociatedDealerSelectCustomer/';
import { AssociatedDealerSearchCustomerDrawer } from '../AssociatedDealerSearchCustomer/';
import { AssociatedDealerSearchResultsDrawer } from '../AssociatedDealerSearchResults/';
import useAssociatedDealer from '../hooks/useAssociatedDealer';
import { DrawerType } from '../types';

export const AssociatedDealerDrawer = ({ drawer }) => {
  const userAffiliation = useSelector(s => s.common?.userAffiliation);
  const isDealerUser = userAffiliation === AFFILIATION_DEALER;

  const { searchCustomerProps, searchResultsProps, selectCustomerProps } =
    useAssociatedDealer({ drawer });

  return (
    <>
      {isDealerUser ? (
        <>
          <AssociatedDealerSearchCustomerDrawer
            drawer={drawer}
            {...searchCustomerProps}
          />
          <AssociatedDealerSearchResultsDrawer {...searchResultsProps} />
          <AssociatedDealerSelectCustomerDrawer {...selectCustomerProps} />
        </>
      ) : (
        <AssociatedDealerSelectCustomerDrawer
          drawer={drawer}
          {...selectCustomerProps}
        />
      )}
    </>
  );
};

AssociatedDealerDrawer.propTypes = {
  drawer: DrawerType
};
