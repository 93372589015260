import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { APP_BANNERS } from '@app/constants/targets';
import CSRBannerBlocks from '../CSRBannerBlocks/CSRBannerBlocks';
import CSRBannerBlocksWhite from '../CSRBannerBlocksWhite/CSRBannerBlocksWhite';
import { useSelector } from 'react-redux';

let node;
const app = APP_BANNERS;

const Container = () => {
  const [newNode, setNewNode] = useState(null);
  const isPCCCustomerSupportCSRBannerMessaging =
    useSelector(s => s.featureFlag?.PCC_CustomerSupportCSRBannerMessaging) ??
    false;

  useEffect(() => {
    if (!document.getElementById(app)) {
      node = document.createElement('header');
      node.setAttribute('id', app);
      node.setAttribute('role', 'banner');
      node.setAttribute('style', 'overflow-x: hidden;overflow-y: auto;');
      const pageNode = document.getElementById('page');
      pageNode && pageNode.prepend(node);
    } else {
      node = document.getElementById(app);
    }
    setNewNode(node);
  }, []);

  if (!isPCCCustomerSupportCSRBannerMessaging) {
    return newNode ? createPortal(<CSRBannerBlocks />, newNode) : null;
  }
  return newNode ? createPortal(<CSRBannerBlocksWhite />, newNode) : null;
};

export default Container;
