export const SUMMARY_OF_EDITS_MODAL_ID = 'SUMMARY_OF_EDITS_MODAL_ID';
export const CSR_ACTIONS_MODAL_TITLE =
  'ACTIONS_BY_CAT_CUSTOMER_CARE_ON_YOUR_BEHALF';
export const PERSONAL_INFO_UPDATED = 'PersonalInfoUpdated';
export const ADDRESS_BOOK_UPDATED = 'AddressBookUpdated';
export const PAYMENT_METHOD_UPDATED = 'PaymentMethodUpdated';
export const ABANDONED_CART = 'AbandonedCart';
export const SHOPPING_PREFERENCES_UPDATED = 'ShoppingPreferencesUpdated';
export const ORDER_CREATED = 'OrderCreated';
export const MY_EQUIPMENT_UPDATE = 'MyEquipmentUpdated';
export const QUOTE_CREATED = 'QuoteCreated';
export const QUOTE_CONVERTED = 'QuoteConverted';
export const QUOTE_DELETED = 'QuoteDeleted';
export const QUOTE_EDITED = 'QuoteEdited';
export const ORDER_QUEUED = 'OrderQueued';

export const CSR_SUPPORTED_EDIT_ACTIONS = [
  PERSONAL_INFO_UPDATED,
  ADDRESS_BOOK_UPDATED,
  PAYMENT_METHOD_UPDATED,
  ABANDONED_CART,
  SHOPPING_PREFERENCES_UPDATED,
  ORDER_CREATED,
  MY_EQUIPMENT_UPDATE,
  QUOTE_CREATED,
  QUOTE_CONVERTED,
  QUOTE_DELETED,
  ORDER_QUEUED,
  QUOTE_EDITED
];
