import * as types from './constants';
import { STATUS } from '@app/constants/commonConstants';
import { visionLinkInitialStore } from './initialStore';

const VisionLinkReducer = (state = visionLinkInitialStore, action) => {
  switch (action.type) {
    case types.GET_VL_ASSET_BEGIN: {
      return {
        ...state,
        vlAsset: {
          data: {},
          status: STATUS.PENDING
        }
      };
    }
    case types.GET_VL_ASSET_SUCCESS: {
      return {
        ...state,
        vlAsset: {
          data: action.payload,
          status: STATUS.RESOLVED
        }
      };
    }
    case types.GET_VL_ASSET_FAIL: {
      return {
        ...state,
        vlAsset: {
          data: {},
          status: STATUS.REJECTED
        }
      };
    }
    case types.GET_VL_ASSET_SUMMARY_BEGIN: {
      return {
        ...state,
        vlAssetSummary: {
          data: {},
          status: STATUS.PENDING
        }
      };
    }
    case types.GET_VL_ASSET_SUMMARY_SUCCESS: {
      return {
        ...state,
        vlAssetSummary: {
          data: action.payload,
          status: STATUS.RESOLVED
        }
      };
    }
    case types.GET_VL_ASSET_SUMMARY_FAIL: {
      return {
        ...state,
        vlAssetSummary: {
          data: {},
          status: STATUS.REJECTED
        }
      };
    }
    default: {
      return state;
    }
  }
};

export { visionLinkInitialStore };
export default VisionLinkReducer;
