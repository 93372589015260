import { useCallback, useState } from 'react';
import { replaceTokensInString } from '@app/utils';
import { useTranslation } from 'react-i18next';

const usePagination = itemsPerPage => {
  const [totalResults, setTotalResults] = useState([]);
  const [totalResultsCount, setTotalResultsCount] = useState(0);
  const [resultPerPage, setResultsPerPage] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [t] = useTranslation();

  const paginationLabel = replaceTokensInString(
    t('ORGANIZATIONUSERSLIST_PAGE_DISPLAY2'),
    `${currentPage * itemsPerPage - itemsPerPage + 1}`,
    `${
      currentPage * itemsPerPage < totalResultsCount
        ? currentPage * itemsPerPage
        : totalResultsCount
    }`,
    `${totalResultsCount}`
  );

  const viewingPaginationLabel = replaceTokensInString(
    t('VIEWING_COUNT'),
    `${currentPage * itemsPerPage - itemsPerPage + 1} -
     ${
       currentPage * itemsPerPage < totalResultsCount
         ? currentPage * itemsPerPage
         : totalResultsCount
     }`,
    `${totalResultsCount}`
  );

  const paginate = useCallback(
    results => {
      setTotalResults(results);
      setTotalResultsCount(results.length);
      setResultsPerPage(results.slice(0, itemsPerPage));
      setCurrentPage(1);
    },
    [itemsPerPage]
  );

  const onPageChange = useCallback(
    currentPage => {
      const begin = (currentPage - 1) * itemsPerPage;
      const end = begin + itemsPerPage;
      setResultsPerPage(totalResults.slice(begin, end));
      setCurrentPage(currentPage);
    },
    [itemsPerPage, totalResults]
  );

  return {
    resultPerPage,
    totalResultsCount,
    currentPage,
    paginate,
    onPageChange,
    paginationLabel,
    viewingPaginationLabel
  };
};

export default usePagination;
