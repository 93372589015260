/**
 * Global GlobalLoginJS, logout, runCatCorpRedirect, showChangeDealerpopup, CATStoreCommonUtilitiesJS
 */

export const fireCatCorpRedirect = () => window.runCatCorpRedirect;

export const showADP = () => {
  // eslint-disable-next-line no-undef
  showChangeDealerpopup();
};

export const addPartToBrowsingHistory = partNumber => {
  // eslint-disable-next-line no-undef
  updateBrowsingHistoryCookie(partNumber);
};
export const displaySaveToListModal = () => {
  // eslint-disable-next-line no-undef
  CATStoreCommonUtilitiesJS.displaySaveToListModal('CART_ALL');
};

export const cartSavedListModalCookieCreate = () => {
  // eslint-disable-next-line no-undef
  CATStoreCommonUtilitiesJS.cartSavedListModalCookieCreate('CART_ALL');
};
