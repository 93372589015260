import { STATUS } from '../../constants/commonConstants';
import * as types from './constants';
import * as productTypes from '../products/constants';
import * as myEquipmentTypes from '../myequipment/constants';
import { plpInitialStore } from './initialStore';

const plpReducer = (state = plpInitialStore, action) => {
  switch (action.type) {
    case types.PLP_GET_CURRENT_CATEGORY: {
      const { payload } = action;
      return {
        ...state,
        current: payload
      };
    }

    case types.PLP_GET_DETAILS_BEGIN: {
      return {
        ...state,
        getPlpDetailsStatus: STATUS.PENDING,
        getPlpDetailsError: undefined,
        partsWithFitmentData: undefined
      };
    }
    case types.PLP_GET_DETAILS_SUCCESS: {
      const { lowestLoadedPage, unitOfMeasure } = action.payload;
      return {
        ...state,
        getPlpDetailsStatus: STATUS.RESOLVED,
        lowestLoadedPage,
        unitOfMeasure
      };
    }
    case types.PLP_GET_MORE_DETAILS_SUCCESS: {
      return {
        ...state,
        getMorePlpDetailsStatus: STATUS.RESOLVED
      };
    }
    case types.PLP_GET_PREVIOUS_DETAILS_SUCCESS: {
      const { lowestLoadedPage } = action.payload;
      return {
        ...state,
        getPrevPlpDetailsStatus: STATUS.RESOLVED,
        lowestLoadedPage
      };
    }
    case productTypes.PLP_PRODUCTS_PRICE_SET_STATUS: {
      const { status } = action.payload;
      return {
        ...state,
        priceAndAvailibiltyStatus: status
      };
    }
    case myEquipmentTypes.PLP_GET_EQUIPMENT_FITMENT_SET_STATUS: {
      return {
        ...state,
        equipmentFitmentStatus: action.payload
      };
    }
    case types.PLP_GET_DETAILS_FAIL: {
      const { payload } = action;
      return {
        ...state,
        getPlpDetailsStatus: STATUS.REJECTED,
        getPlpDetailsError: payload
      };
    }
    case types.PLP_GET_MORE_DETAILS_FAIL: {
      const { payload } = action;
      return {
        ...state,
        getMorePlpDetailsStatus: STATUS.REJECTED,
        getMorePlpDetailsError: payload
      };
    }
    case types.PLP_GET_MORE_DETAILS_BEGIN: {
      return {
        ...state,
        getMorePlpDetailsStatus: STATUS.PENDING,
        getMorePlpDetailsError: undefined
      };
    }
    case types.PLP_GET_PREVIOUS_DETAILS_FAIL: {
      const { payload } = action;
      return {
        ...state,
        getPrevPlpDetailsStatus: STATUS.REJECTED,
        getPrevPlpDetailsError: payload
      };
    }
    case types.PLP_GET_PREVIOUS_DETAILS_BEGIN: {
      return {
        ...state,
        getPrevPlpDetailsStatus: STATUS.PENDING,
        getPrevPlpDetailsError: undefined
      };
    }
    case types.PLP_SET_QUERY_PARAM: {
      const { payload } = action;
      return {
        ...state,
        ...payload
      };
    }
    case types.PLP_SET_EQUIPMENT_CHECKED: {
      const { payload } = action;
      return {
        ...state,
        filterByEquipment: payload
      };
    }
    case types.PLP_SET_PARTS_WITH_FITMENT_DATA: {
      const { payload } = action;
      return {
        ...state,
        partsWithFitmentData: {
          ...(state.partsWithFitmentData || {}),
          ...payload
        }
      };
    }
    case types.PLP_SET_FACETS_LOADING: {
      const { payload } = action;
      return {
        ...state,
        isFacetsLoading: payload
      };
    }
    case types.PLP_SET_PRODUCT_CARD_EXPANDED: {
      const { payload } = action;
      return {
        ...state,
        productCardExpandedArr: payload
      };
    }
    case types.PLP_SET_BUCKET_MODEL_ACTIVE_FACET: {
      const { payload } = action;
      return {
        ...state,
        bucketModelActiveFacet: payload
      };
    }
    default:
      return state;
  }
};

export { plpInitialStore };
export default plpReducer;
