import { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import {
  DEFAULT_BUSINESS_UNIT,
  NOT_APPLICABLE,
  PERSONALIZATION
} from '@app/constants/analyticsConstants';

/**
 * Reusable logic to build & fire personalization analytics events
 * This analytics are not related with the eCommerce core, it's related with the banners or ads in the page
 *
 * @param {Object} props options for this instance of useAnalytics
 * @param {string} props.businessUnit The Caterpillar business unit that stocks the part, e.g. 'Filters', 'Undercarriage', 'PCC', 'GET', 'BCP'
 */
const usePersonalizationAnalytics = ({
  businessUnit = DEFAULT_BUSINESS_UNIT
} = {}) => {
  const { trackEvent } = useTracking();

  /**
   * Azure #269039 - Google Analytics - Attachment: GA Non interaction Studio Personalization Doc
   * Builds and returns a generic personalization event object
   * @param {string} eventType personalization_impression | personalization_clickthrough | personalization_close
   * @param {string} id banner Id / Ad Id
   * @param {string} name Name of Personalization
   * @param {string} type Type of Personalization
   * @param {string} currentPage Page where the event is fired
   * @param {string} experienceId For A/B Testing
   * @param {string} experienceName Type of experience
   */
  const buildPersonalizationEvent = useCallback(
    ({
      eventType,
      id,
      name,
      type,
      currentPage,
      experienceId,
      experienceName
    }) => ({
      event: eventType,
      campaignId: id,
      campaignName: name,
      campaignType: PERSONALIZATION.CAMPAIGN_TYPE,
      experienceId,
      experienceName,
      statTypeAll: {
        control: PERSONALIZATION.STAT_TYPE_ALL.CONTROL
      },
      payload: {
        contentZone: `${businessUnit}_${currentPage}_${type}`,
        userGroup: PERSONALIZATION.USER_GROUP
      }
    }),
    [businessUnit]
  );

  /**
   * Azure #269039 - Google Analytics - Attachment: GA Non interaction Studio Personalization Doc
   * Builds and returns a personalization impression event object
   * @param {string} id banner Id / Ad Id
   * @param {string} name Name of Personalization
   * @param {string} type Type of Personalization
   * @param {string} currentPage Page where the event is fired
   * @param {string} experienceId For A/B Testing
   * @param {string} experienceName Type of experience
   */
  const buildImpressionEvent = useCallback(
    ({ id, name, type, currentPage, experienceId, experienceName }) =>
      buildPersonalizationEvent({
        id,
        name,
        type,
        currentPage,
        experienceId,
        experienceName,
        eventType: PERSONALIZATION.EVENT_TYPE.IMPRESSION
      }),
    [buildPersonalizationEvent]
  );

  /**
   * Azure #269039 - Google Analytics - Attachment: GA Non interaction Studio Personalization Doc
   * Builds and returns a personalization click event object
   * @param {string} id banner Id / Ad Id
   * @param {string} name Name of Personalization
   * @param {string} type Type of Personalization
   * @param {string} currentPage Page where the event is fired
   * @param {string} experienceId For A/B Testing
   * @param {string} experienceName Type of experience
   */
  const buildClickEvent = useCallback(
    ({ id, name, type, currentPage, experienceId, experienceName }) =>
      buildPersonalizationEvent({
        id,
        name,
        type,
        currentPage,
        experienceId,
        experienceName,
        eventType: PERSONALIZATION.EVENT_TYPE.CLICK
      }),
    [buildPersonalizationEvent]
  );

  /**
   * Azure #269039 - Google Analytics - Attachment: GA Non interaction Studio Personalization Doc
   * Builds and returns a personalization close event object
   * @param {string} id banner Id / Ad Id
   * @param {string} name Name of Personalization
   * @param {string} type Type of Personalization
   * @param {string} currentPage Page where the event is fired
   * @param {string} experienceId For A/B Testing
   * @param {string} experienceName Type of experience
   */
  const buildCloseEvent = useCallback(
    ({ id, name, type, currentPage, experienceId, experienceName }) =>
      buildPersonalizationEvent({
        id,
        name,
        type,
        currentPage,
        experienceId,
        experienceName,
        eventType: PERSONALIZATION.EVENT_TYPE.CLOSE
      }),
    [buildPersonalizationEvent]
  );

  /**
   * Azure #269039 - Google Analytics - Attachment: GA Non interaction Studio Personalization Doc
   * Fire a Retail App Banner Impression event
   * @param {string} id Banner Id
   * @param {string} name Banner Name
   * @param {string} currentPage Page where the event is fired
   * @param {string} deviceType Android | IOS
   */
  const fireAppBannerImpressionEvent = useCallback(
    ({ id, name, currentPage, deviceType }) => {
      const event = buildImpressionEvent({
        id,
        name,
        currentPage,
        type: PERSONALIZATION.TYPE.APP_BANNER,
        experienceId: NOT_APPLICABLE,
        experienceName: deviceType
      });
      trackEvent(event);
    },
    [trackEvent, buildImpressionEvent]
  );

  /**
   * Azure #269039 - Google Analytics - Attachment: GA Non interaction Studio Personalization Doc
   * Fire a Retail App Banner Click event
   * @param {string} id Banner Id
   * @param {string} name Banner Name
   * @param {string} currentPage Page where the event is fired
   * @param {string} deviceType Android | IOS
   */
  const fireAppBannerClickEvent = useCallback(
    ({ id, name, currentPage, deviceType }) => {
      const event = buildClickEvent({
        id,
        name,
        currentPage,
        type: PERSONALIZATION.TYPE.APP_BANNER,
        experienceId: NOT_APPLICABLE,
        experienceName: deviceType
      });
      trackEvent(event);
    },
    [trackEvent, buildClickEvent]
  );

  /**
   * Azure #269039 - Google Analytics - Attachment: GA Non interaction Studio Personalization Doc
   * Fire a Retail App Banner Close event
   * @param {string} id Banner Id
   * @param {string} name Banner Name
   * @param {string} currentPage Page where the event is fired
   * @param {string} deviceType Android | IOS
   */
  const fireAppBannerCloseEvent = useCallback(
    ({ id, name, currentPage, deviceType }) => {
      const event = buildCloseEvent({
        id,
        name,
        currentPage,
        type: PERSONALIZATION.TYPE.APP_BANNER,
        experienceId: NOT_APPLICABLE,
        experienceName: deviceType
      });
      trackEvent(event);
    },
    [trackEvent, buildCloseEvent]
  );

  return {
    fireAppBannerImpressionEvent,
    fireAppBannerClickEvent,
    fireAppBannerCloseEvent
  };
};

export default usePersonalizationAnalytics;
