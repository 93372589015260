export const ALTERNATE = 'Alternate';
export const CLASSIC = 'Classic';
export const DOWNSELL = 'DOWNSELL';
export const GENUINE = 'NPE';
export const INSTALLATION_PARTS = 'Installation-Parts';
export const REMAN = 'Reman';
export const UPSELL = 'UPSELL';
export const XSELL = 'X-SELL';
export const DEALERREMAN = 'Dealer Reman';
export const DEALERALTERNATE = 'Dealer Alternate';
