export const getCustomerNumberInfo = (isCSPCustomer, isLoggedIn, values) => {
  const showCustomerNumberDropdown =
    !!values.dealer && isLoggedIn && isCSPCustomer;
  const customerNumbers = values.dealer?.customerNumber || [];
  return { customerNumbers, showCustomerNumberDropdown };
};

export const getStoreLocationInfo = (isCSPCustomer, storeLocations, values) => {
  const doStoresExist =
    Array.isArray(storeLocations) && storeLocations.length > 0;
  const showStoreLocationDropDown =
    doStoresExist &&
    ((isCSPCustomer && !!values.customerNumber && !!values.dealer) ||
      (!isCSPCustomer && !!values.dealer));

  return showStoreLocationDropDown;
};

export const getEndUseCodeInfo = values => {
  const endUseCodes = values.storeLocation?.endUseCode || [];
  const showEndUseCodeDropdown =
    Array.isArray(endUseCodes) && endUseCodes.length > 0;
  return { endUseCodes, showEndUseCodeDropdown };
};

export const getOrderTypeInfo = values => {
  const orderTypes = values.storeLocation?.orderType || [];
  const showOrderTypes = Array.isArray(orderTypes) && orderTypes.length > 1;
  return { orderTypes, showOrderTypes };
};

export const getFormDisplayInfo = ({ isLoggedIn, storeLocations, values }) => {
  const isCSPCustomer = values.dealer?.isCSPCustomer ?? false;
  const { customerNumbers, showCustomerNumberDropdown } = getCustomerNumberInfo(
    isCSPCustomer,
    isLoggedIn,
    values
  );
  const showStoreLocationDropDown = getStoreLocationInfo(
    isCSPCustomer,
    storeLocations,
    values
  );
  const { endUseCodes, showEndUseCodeDropdown } = getEndUseCodeInfo(values);
  const { orderTypes, showOrderTypes } = getOrderTypeInfo(values);
  return {
    customerNumbers,
    endUseCodes,
    isCSPCustomer,
    orderTypes,
    showCustomerNumberDropdown,
    showEndUseCodeDropdown,
    showOrderTypes,
    showStoreLocationDropDown
  };
};
