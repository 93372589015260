import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  FORM_SAVE_EQUIPMENT,
  STATUS_FAIL
} from '@app/constants/analyticsConstants';

const GAFormEventError = ({
  isCSPCustomer,
  serialNumberError,
  fireFormSubmittedEvent
}) => {
  const { values } = useFormikContext();
  useEffect(() => {
    if (serialNumberError) {
      fireFormSubmittedEvent({
        event: 'formSubmitted',
        pagePath: document.location.pathname,
        formName: FORM_SAVE_EQUIPMENT,
        formContent: isCSPCustomer
          ? `${values.serialNumber} | ${values.model}${values.equipmentFamily}`
          : values.serialNumber,
        formContent2: isCSPCustomer
          ? !!values?.notifyDealer
          : `${values.model}${values.equipmentFamily}`,
        formFieldCausingError: `Serial Number Error - ${serialNumberError}`,
        formStatus: STATUS_FAIL,
        formLocation: ''
      });
    }
  }, [
    fireFormSubmittedEvent,
    isCSPCustomer,
    serialNumberError,
    values.equipmentFamily,
    values.model,
    values?.notifyDealer,
    values.serialNumber
  ]);

  return null;
};

export default GAFormEventError;
