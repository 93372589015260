import { STATUS } from '@app/constants/commonConstants';
import { orderStatusFlat } from './utils';

export const notificationHeaderInitialStore = {
  data: { notifications: [] },
  isLoading: false,
  notificationStatus: STATUS.IDLE,
  includeDismissedNotifications: false,
  isDismissedSuccessBanner: false,
  notificationFilter: {},
  isUnreadNotifications: [],
  notificationOrderStatus: orderStatusFlat(),
  isActiveNotification: false,
  notificationPreferencesStatus: {}
};
