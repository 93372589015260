export const getLocationSearch = () => window.location.search;
export const getRelativeURL = () => window.getRelativeURL();

//Added getPageRedirectURL for Azure-203120
export const getPageRedirectURL = () => window?.catReact?.pageRedirectUrl;

export const getMyEquipmentVars = () => {
  if (window.catReact) {
    const {
      showMyEquipmentWidget,
      isFilteredByEquipment,
      refreshPageAfterChangeInSelectedEquipment,
      userId,
      userType
    } = window.catReact;
    return {
      showMyEquipmentWidget: showMyEquipmentWidget === 'true',
      isFilteredByEquipment: isFilteredByEquipment === 'true',
      refreshPageAfterSelectEquipment:
        refreshPageAfterChangeInSelectedEquipment === 'true',
      userId,
      userType
    };
  }

  return {
    showMyEquipmentWidget: true,
    isFilteredByEquipment: true,
    refreshPageAfterSelectEquipment: true,
    userId: '',
    userType: ''
  };
};

export const navigateToUrl = url => {
  window.location.href = url;
};

export const replaceUrl = url => window.location.replace(url);

export const getPathname = () => window.location.pathname;

export const getEnvInPreview = () => window.catReact.envInPreview;

export default {
  getLocationSearch,
  getMyEquipmentVars,
  getPathname,
  navigateToUrl,
  replaceUrl,
  getEnvInPreview
};
