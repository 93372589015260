import { useBreakpoint } from '@app/hooks';
import { useCurrentPage } from '@app/hooks/useCurrentPage';
import {
  HeaderDivider,
  HeaderNavSection,
  HeaderRow,
  useActiveControl,
  HideOnScroll,
  SubheaderRow
} from '@cat-ecom/pcc-components';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import Conditional from '@app/components/common/Conditional';
import SubHeaderPrimaryNavWrapper from '@app/components/subheader/SubHeaderPrimaryNavWrapper';
import HeaderAccount from '../HeaderAccountWrapper';
import HeaderCart from '../HeaderCartWrapper';
import HeaderEquipmentWidget from '../HeaderEquipmentWidgetWrapper';
import HeaderLogo from '../HeaderLogoWrapper';
import HeaderMobileMenu from '../HeaderMobileMenu';
import HeaderNotification from '../HeaderNotificationWrapper';
import HeaderSearchBar from '../HeaderSearchBarWrapper';
import HeaderStore from '../HeaderStoreWrapper';
import HeaderWaffleMenu from '../HeaderWaffleMenuWrapper';
import { isHomepage } from '@app/utils';
import { useSelector } from 'react-redux';
import HomePageSearchBarWrapper from '@app/components/HomePageSearchBarWrapper';

const FullHeader = () => {
  const { isActive: isMobileMenuActive, close, toggle } = useActiveControl();
  const isMobile = !useBreakpoint(PAGE_BREAKPOINTS.BLOCKS_LG);
  const { isOnPMKitsOrSSROPage, isOnOMMPage } = useCurrentPage();
  const isCSR = useSelector(s => s.common?.isCatCSR);
  const isOnEquipmentPages = isOnPMKitsOrSSROPage || isOnOMMPage;
  const showSecondRow = !(isCSR && isOnEquipmentPages);
  const showStoreMobileItem = !isCSR;

  if (!isMobile && isMobileMenuActive) {
    close();
  }

  const mobileBreakpoint = PAGE_BREAKPOINTS.BLOCKS_LG;

  return (
    <>
      <HeaderRow>
        <Conditional test={isMobile}>
          <HeaderMobileMenu
            isMobileMenuActive={isMobileMenuActive}
            clickHandler={toggle}
          />
        </Conditional>
        <HeaderLogo isMobile={isMobile} />
        <Conditional test={!isMobile}>
          {!isHomepage() ? <HeaderSearchBar /> : <HomePageSearchBarWrapper />}
        </Conditional>
        <HeaderNavSection>
          <Conditional test={!isMobile}>
            <HeaderStore />
          </Conditional>
          <HeaderAccount />
          <Conditional test={!(isMobile && isCSR)}>
            <HeaderNotification />
          </Conditional>
          <HeaderCart />
          <Conditional test={!isMobile}>
            <HeaderWaffleMenu />
          </Conditional>
        </HeaderNavSection>
      </HeaderRow>

      <Conditional test={isMobile && !isMobileMenuActive}>
        <Conditional test={showSecondRow}>
          <HeaderDivider />
          <SubheaderRow>
            <HeaderNavSection fullWidth>
              <Conditional test={showStoreMobileItem}>
                <HeaderStore />
              </Conditional>
              <Conditional test={!isOnEquipmentPages}>
                <HeaderEquipmentWidget
                  isMobile
                  showDivider={showStoreMobileItem}
                />
              </Conditional>
            </HeaderNavSection>
          </SubheaderRow>
        </Conditional>

        <SubheaderRow>
          {!isHomepage() ? <HeaderSearchBar /> : <HomePageSearchBarWrapper />}
        </SubheaderRow>
      </Conditional>

      <Conditional test={isMobile}>
        <SubHeaderPrimaryNavWrapper
          isCollapsed={isMobileMenuActive}
          mobileBreakpoint={mobileBreakpoint}
          isMobile={isMobile}
        />
      </Conditional>
    </>
  );
};

export default FullHeader;
