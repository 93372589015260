import { useState, useEffect } from 'react';
import { getHttpInstance } from '../services/defaultHttpService';
import { useSelector } from 'react-redux';
import { normalizeError } from '../store/exception/utils';

const useEquipmentSearch = serialNumber => {
  const [showLoading, setShowLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [searchResults, setSearchResults] = useState([]);
  const { catalogId, storeId, langId } = useSelector(state => state.common);
  useEffect(() => {
    const http = getHttpInstance(12000);
    const url = `equipmentSearch?searchTerm=${serialNumber}&catalogId=${catalogId}&langId=${langId}&storeId=${storeId}`;

    const searchConfig = {
      headers: { 'Cache-Control': 'no-cache' }
    };
    http
      .get(url, searchConfig)
      .then(response => {
        if (response.data.equipments) {
          setSearchResults({
            query: serialNumber,
            results: response.data.equipments,
            success: !!response.data.equipments
          });
        }
        if (response.data.errors) {
          setError(normalizeError(response.data.errors));
          setSearchResults({
            query: serialNumber,
            results: [],
            success: false
          });
        }
        setShowLoading(false);
      })
      .catch(err => {
        setError(normalizeError(err));
        setShowLoading(false);
      });
  }, [serialNumber, catalogId, storeId, langId]);
  return { searchResults, error, showLoading };
};

export default useEquipmentSearch;
