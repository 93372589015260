import { v4 } from 'uuid';
import {
  Conditional,
  DealerLocatorCancelButton,
  DealerLocatorConfirmButton,
  DealerLocatorHeader,
  DealerLocatorTermsLoader,
  Drawer,
  DrawerBody,
  Modal,
  ModalBody,
  ModalFooter,
  useDrawer,
  useModal
} from '@cat-ecom/pcc-components';
import { TermsWrapper } from '../TermsWrapper';
import Espot from '../Espot/Espot';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useContext, useState } from 'react';
import { replaceTokensInString } from '@app/utils';
import { useEspot } from '@app/hooks';
import { TERMS_CHECKED } from '@app/constants/dealerLocatorConstants';
import { useForm, FormProvider } from 'react-hook-form';
import useSelectStore from '@app/components/dealer-locator/hooks/useSelectStore';
import { ConsentAcceptanceContext } from '../ConsentAcceptanceModalContext';

const ConsentAcceptanceModalBlocks = () => {
  const [t] = useTranslation();
  const { isOpen, close } = useContext(ConsentAcceptanceContext);
  const modal = useModal({
    isRequired: false,
    heading: `${t('REGISTER_MODAL_5_USER_CONSENT_HEADER')}`,
    backButton: false,
    size: 'md'
  });

  const DEFAULT_DESCRIPTION = t('REGISTER_MODAL_5_USER_CONSENT_SUBTEXT');
  const dealerName = useSelector(state => state?.dealer?.dealerName);
  const esiteURL = useSelector(s => s.common?.esiteURL);
  const storeId = useSelector(state => state?.common?.storeId);
  const storeName = useSelector(state => state?.store?.name);
  const selectedStore = useSelector(state => state?.common?.selectedStore);
  const locationId = storeId + selectedStore;
  const [espot, setEspot] = useState({ name: null, scrollId: null });
  const storeFormMethods = useForm({ defaultValues: { termsChecked: false } });
  const continueBtnIsDisabled = !storeFormMethods.watch(TERMS_CHECKED);
  const isPCCShowDealerTerms = useSelector(
    s => s?.featureFlag?.PCCShowDealerTerms
  );

  const adpSubmitPromise = () => {};

  const { invoke } = useSelectStore();
  const onSubmit = values => {
    invoke({
      adpSubmitPromise,
      dealerName,
      isRegistration: true,
      locationId,
      storeName,
      bobRedirect: null,
      consent: values.termsChecked,
      isConsentSelected: true
    });
  };

  const backButtonMessage = replaceTokensInString(
    t('GENERAL_BACK_TO'),
    modal.modalProps.heading
  );

  const termsDrawer = useDrawer({
    id: 'consent-terms-drawer',
    size: 'md',
    onClose: () => {
      setEspot({ name: null, scrollId: null });
      close();
    }
  });

  const handleTermsClick = (name, scrollId = null) => {
    setEspot({ name, scrollId });
    termsDrawer.openDrawer();
  };
  const handleCloseTerms = () => {
    termsDrawer.drawerControl.close();
    setEspot({ name: null, scrollId: null });
  };
  const { isEspotLoading, getEspotContent } = useEspot(espot.name, '', storeId);
  const espotHtml = getEspotContent(espot.name);

  return (
    <FormProvider {...storeFormMethods}>
      <Modal
        {...modal.modalProps}
        isActive={isOpen}
        id={v4()}
        onBlModalClose={close}
      >
        {/* Increase modal Height to fit terms inside (modal is not adapting to child drawer height automatically)*/}
        {termsDrawer.isDrawerOpen && <div style={{ height: '800px' }} />}
        <ModalBody>
          <p className="exclude-global cat-u-theme-typography-body color-extra-dark-gray mb-0 text-md">
            {DEFAULT_DESCRIPTION}
          </p>
        </ModalBody>
        <ModalFooter>
          <TermsWrapper
            dealerName={dealerName}
            dealerURL={esiteURL}
            onTermsClick={handleTermsClick}
            displayDealerTermsAndCond={isPCCShowDealerTerms}
          />
          <DealerLocatorConfirmButton
            disabled={continueBtnIsDisabled}
            onClick={storeFormMethods.handleSubmit(onSubmit)}
          >
            {t('EF_CONTINUE')}
          </DealerLocatorConfirmButton>
          <DealerLocatorCancelButton onCancel={close}>
            {t('CANCEL')}
          </DealerLocatorCancelButton>
        </ModalFooter>
        <Drawer {...termsDrawer.drawerProps}>
          <DealerLocatorHeader
            onBackButtonClick={handleCloseTerms}
            title={backButtonMessage}
          />
          <DrawerBody>
            <Conditional
              test={!isEspotLoading}
              fallback={<DealerLocatorTermsLoader />}
            >
              <Espot html={espotHtml} scrollId={espot.scrollId} />
            </Conditional>
          </DrawerBody>
        </Drawer>
      </Modal>
    </FormProvider>
  );
};

export default ConsentAcceptanceModalBlocks;
