import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Dropdown, Validator } from 'cat-ecommerce-alloy';
import { replaceTokensInString } from '@app/utils';
import Field from '@app/components/common/form/Field';
import PropTypes from 'prop-types';
import { storesLocationPropTypes } from '@app/components/associated-dealer/declarations.propTypes';

const StoreLocationDropdown = ({
  error,
  errorText,
  idPrefix = '',
  labelText,
  storeLocations = []
}) => {
  const { t } = useTranslation();
  const {
    initialValues,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    values
  } = useFormikContext();
  const placeholderText = t('CAT_MA_SELECTDEALAERSTORE');
  const hasOneStore = storeLocations.length === 1;
  const placeholder = hasOneStore
    ? placeholderText
    : { label: placeholderText, value: '' };

  const defaultStore = hasOneStore
    ? storeLocations[0]
    : storeLocations.find(({ default: defaultStore }) => defaultStore === true);
  const storeLocation = values.storeLocation;
  const setDefaultOnInitialRender =
    !storeLocation || !initialValues.storeLocation;
  useEffect(() => {
    if (setDefaultOnInitialRender) {
      setFieldValue('storeLocation', defaultStore ?? '');
    }
  }, [defaultStore, setFieldValue, setDefaultOnInitialRender]);

  // blank out the values of dependent form fields when the value changes
  const handleOnChange = () => {
    setFieldValue('endUseCode', '');
    setFieldValue('orderType', '');
  };

  const FIELD_REQUIRED_MSG = t('1626_FIELD_REQUIRED_ERROR');
  const validator = val => {
    if (error) {
      return errorText;
    }
    return new Validator(val)
      .required(replaceTokensInString(FIELD_REQUIRED_MSG, labelText))
      .getError();
  };

  useEffect(() => {
    if (error) {
      if (storeLocation) {
        setFieldValue('storeLocation', '');
      }
      setFieldTouched('storeLocation', true, false);
      setFieldError('storeLocation', errorText);
    } else {
      setFieldError('storeLocation', '');
    }
  }, [
    error,
    errorText,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    storeLocation
  ]);

  return (
    <Field
      as={Dropdown}
      className={`w-100`}
      id={`${idPrefix}_store-location`}
      items={storeLocations}
      itemToString={({ label }) => label}
      keyToString={({ value }) => value}
      label={labelText}
      menuSize="small"
      name="storeLocation"
      onChange={handleOnChange}
      placeholder={placeholder}
      showRequired
      type="dropdown"
      validator={validator}
    />
  );
};

export default StoreLocationDropdown;

StoreLocationDropdown.propTypes = {
  error: PropTypes.bool,
  errorText: PropTypes.string,
  idPrefix: PropTypes.string,
  labelText: PropTypes.string,
  storeLocations: storesLocationPropTypes
};
