import endpoints from '@app/constants/endpoints';
import { normalizeUrl, replaceTokensInString } from '@app/utils';
import { StoreContactInfo } from '@cat-ecom/pcc-components';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CustomerServiceWrapper = () => {
  const { t } = useTranslation();
  const catalogId = useSelector(s => s.common?.catalogId);
  const langId = useSelector(s => s.common?.langId);
  const storeId = useSelector(s => s.common?.storeId);
  const seoURL = useSelector(s => s.dealer?.seoURL);
  const phone = useSelector(s => s.store?.phone);
  const website = useSelector(s => s.store?.website);
  const longitude = useSelector(s => s.store?.longitude);
  const latitude = useSelector(s => s.store?.latitude);

  const contactUrl = normalizeUrl(
    replaceTokensInString(
      `${seoURL}${endpoints.CONTACT_US_DEALER}`,
      catalogId,
      langId,
      storeId
    )
  );

  const contactInfoProps = {
    title: t('LOCAL_DEALER_SUPPORT'),
    phone,
    contactUrl,
    website,
    longitude,
    latitude,
    websiteLabel: t('CU_WEBSITE'),
    drivingDirectionsLabel: t('DRIVING_DIRECTIONS'),
    phoneNumberLabel: t('MOSB_PHONE_NUMBER')
  };

  return (
    <div className="d-flex flex-column mt-3" style={{ gap: 16 }}>
      <StoreContactInfo {...contactInfoProps} />
    </div>
  );
};

CustomerServiceWrapper.propTypes = {
  latitude: Proptypes.string,
  longitude: Proptypes.string
};

export default CustomerServiceWrapper;
