import { ModalContext } from 'cat-ecommerce-alloy';
import queryString from 'query-string';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useDealerLocator from '@app/components/dealer-locator';
import useSelectStore from '@app/components/dealer-locator/hooks/useSelectStore';
import RegistrationDescription from '@app/components/NavigationBarUtilitySection/RegistrationDescription';
import { CONSENT_SESSION_KEY, STATUS } from '@app/constants/commonConstants';
import endpoints from '@app/constants/endpoints';
import { SHOW_CREATE_AN_ACCOUNT_EVENT } from '@app/constants/events';
import { useHttp } from '@app/hooks';
import { navigateToUrl } from '@app/services/windowService';
import { getSessionStorage } from '@app/utils';
import { replaceTokensInUrl } from '../../../../utils';
import { createFragmentCookie } from '../../utils';

const useRegister = (fromEspot = false) => {
  const [t] = useTranslation();
  const ctx = useContext(ModalContext);
  const { showDealerLocator } = useDealerLocator();
  const { invoke, loading } = useHttp();
  const { invoke: selectedStoreLocation } = useSelectStore();

  const { LOAD_DEALER_REGISTRATION_PAGE } = endpoints;
  const dealerRegistrationURL = useSelector(
    state => state.common.dealerRegistrationURL
  );
  const selectedStore = useSelector(state => state.common.selectedStore);
  const storeId = useSelector(s => s.common.storeId);
  const isCatCorp = useSelector(state => state.common.isCatCorp);
  const dealerName = useSelector(s => s?.dealer?.dealerName);
  const storeName = useSelector(s => s.store?.name);
  const PCCUserRegistrationRedirect = useSelector(
    s => s?.featureFlag?.PCCUserRegistrationRedirect
  );
  const featureFlagStatus = useSelector(s => s?.featureFlag?.featureFlagStatus);

  const showNewRegistrationLink = () => {
    const { search } = window.location;
    const { inviteId } = queryString.parse(search);
    const isConsentSelected =
      getSessionStorage(CONSENT_SESSION_KEY) &&
      JSON.parse(getSessionStorage(CONSENT_SESSION_KEY));
    selectedStoreLocation({
      adpSubmitPromise: () => {},
      dealerName,
      isRegistration: true,
      fromEspot,
      locationId: `${storeId}${selectedStore}`,
      storeName,
      bobRedirect: null,
      consent: isConsentSelected,
      isConsentSelected: isConsentSelected,
      inviteId,
      callBackFailure: () => {}
    });
  };

  const getDealerRegistrationUrl = useCallback(() => {
    const StoreLocationNotFound = dealerRegistrationURL.indexOf('stlocId');
    let finalDealerRegistrationURL = `${dealerRegistrationURL}&fromEspot=${fromEspot}`;
    if (StoreLocationNotFound < 0) {
      finalDealerRegistrationURL = `${finalDealerRegistrationURL}&stlocId=${selectedStore}`;
    }

    return finalDealerRegistrationURL;
  }, [dealerRegistrationURL, selectedStore, fromEspot]);

  const showRegistrationPage = useCallback(() => {
    const endpointURL = replaceTokensInUrl(
      LOAD_DEALER_REGISTRATION_PAGE,
      storeId
    );
    const preRegistrationUrl = getDealerRegistrationUrl();
    invoke({ method: 'get', rethrowError: true, url: endpointURL })
      .then(data => {
        if (data?.jsonResponse) {
          if (featureFlagStatus === STATUS.RESOLVED) {
            if (PCCUserRegistrationRedirect) {
              showNewRegistrationLink();
            } else {
              navigateToUrl(preRegistrationUrl);
            }
          }
        }
      })
      .catch(() => navigateToUrl(preRegistrationUrl))
      .finally(() => ctx.closeAllModals());
  }, [
    LOAD_DEALER_REGISTRATION_PAGE,
    ctx,
    getDealerRegistrationUrl,
    invoke,
    storeId,
    featureFlagStatus
  ]);

  const register = useCallback(
    (showBlocksDealerLocator = null) => {
      createFragmentCookie();
      if (!dealerRegistrationURL || !dealerRegistrationURL?.length > 0) {
        if (typeof showBlocksDealerLocator === 'function') {
          showBlocksDealerLocator();
        } else {
          showDealerLocator({
            isRegistration: true,
            fromEspot,
            title: `${t('STEP_1')}: ${t('REGISTER_MODAL_SELECT_DEALER_STORE')}`,
            description: <RegistrationDescription />
          });
        }
      } else if (dealerRegistrationURL?.length > 0 && !isCatCorp) {
        showRegistrationPage();
      }
    },
    [
      dealerRegistrationURL,
      isCatCorp,
      showDealerLocator,
      showRegistrationPage,
      t
    ]
  );

  useEffect(() => {
    document.addEventListener(SHOW_CREATE_AN_ACCOUNT_EVENT, register);
    return () =>
      document.removeEventListener(SHOW_CREATE_AN_ACCOUNT_EVENT, register);
  }, [register]);

  return { register, loading, showNewRegistrationLink };
};

export default useRegister;
