import * as types from './constants';
import endpoints from '../../constants/endpoints';
import { getHttpInstance } from '../../services/defaultHttpService';
import { replaceTokensInString } from '@app/utils';
import { normalizeError } from '../exception/utils';
import { setError } from '../exception/actions';
import { isAxiosError } from 'axios';

export const getIsEnrolledUser =
  ({ errorDomain = {} } = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.GET_USER_ENROLLMENT_STATUS_BEGIN });

    const http = getHttpInstance();
    const storeId = getState().common?.storeId;

    try {
      const response = await http.get(
        replaceTokensInString(endpoints.GET_USER_CVR_ENROLLMENT_STATUS, storeId)
      );

      dispatch({
        type: types.GET_USER_ENROLLMENT_STATUS_SUCCESS,
        payload: {
          ...response.data
        }
      });
    } catch (error) {
      dispatch({ type: types.GET_USER_ENROLLMENT_STATUS_FAIL });
      const { domain, path } = errorDomain;
      if (domain && path) {
        dispatch(setError(domain, path, normalizeError(error)));
      }
    }
  };

export const catVantageEnrollUser = () => async (dispatch, getState) => {
  const { storeId, langId } = getState().common;
  const http = getHttpInstance();
  dispatch({ type: types.CVR_USER_ENROLLMENT_BEGIN });
  try {
    const response = await http.post(
      replaceTokensInString(endpoints.CVR_ENROLL_USER, storeId, langId)
    );

    dispatch({
      type: types.CVR_USER_ENROLLMENT_SUCCESS,
      payload: {
        ...response.data
      }
    });
    dispatch({ type: types.DISPLAY_TOAST });
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response.status === 400) {
        return dispatch({
          type: types.CVR_USER_ALREADY_ENROLLED
        });
      } else {
        return dispatch({ type: types.CVR_USER_ENROLLMENT_FAIL });
      }
    }
  }
};

export const getCatVantageRewardsBalance = () => async (dispatch, getState) => {
  const storeId = getState().common?.storeId;
  const http = getHttpInstance();
  dispatch({ type: types.GET_CAT_VANTAGE_REWARDS_BALANCE_BEGIN });
  try {
    const response = await http.get(
      replaceTokensInString(endpoints.GET_CAT_VANTAGE_REWARDS_BALANCE, storeId)
    );

    dispatch({
      type: types.GET_CAT_VANTAGE_REWARDS_BALANCE_SUCCESS,
      payload: {
        ...response.data
      }
    });
  } catch (error) {
    const response = error?.response || {
      data: {
        errors: [{ errorKey: 'CVR_GET_BALANCE_ERROR_MESSAGE' }]
      }
    };
    dispatch({
      type: types.GET_CAT_VANTAGE_REWARDS_BALANCE_FAIL,
      payload: { ...response.data }
    });
  }
};

export const setCatVantageRewards = amount => async (dispatch, getState) => {
  dispatch({
    type: types.SET_CAT_VANTAGE_REWARDS_BALANCE,
    payload: amount
  });
};
