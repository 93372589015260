import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AppBanner from '../AppBanner';
import { ANDROID_APP_URI_SCHEME, ANDROID_INSTALL_URL } from '../constants';
import { setCookieToSkipAppBanner } from '../utils';
import { usePersonalizationAnalytics } from '@app/hooks';
import { APP_BANNER, DEVICE_TYPE } from '@app/constants/analyticsConstants';
import { getCurrentPageBasedOnMetaTags } from '@app/utils/browserUtils';
import { useSelector } from 'react-redux';

const SmartBanner = () => {
  const [isClosedBanner, setIsClosedBanner] = useState(false);
  const isFirstImpression = useRef(true);
  const {
    fireAppBannerClickEvent,
    fireAppBannerCloseEvent,
    fireAppBannerImpressionEvent
  } = usePersonalizationAnalytics();
  const dealerName = useSelector(state => state.common.esiteURL);
  const utm_campaign = `${dealerName?.substring(4)}-app-download`;
  const clippedUTM = utm_campaign.substring(0, 30);
  const installURL = `${ANDROID_INSTALL_URL}${clippedUTM}&utm_term=install_now`;
  const currentUrl = window.location.href;
  const openAndroidAppURL = ANDROID_APP_URI_SCHEME + currentUrl;

  useEffect(() => {
    if (isFirstImpression.current) {
      isFirstImpression.current = false;
      fireAppBannerImpressionEvent({
        id: APP_BANNER.CAMPAIGN_ID.SMART,
        name: APP_BANNER.TYPE.SMART_INSTALL,
        currentPage: getCurrentPageBasedOnMetaTags(),
        deviceType: DEVICE_TYPE.ANDROID
      });
    }
  }, [fireAppBannerImpressionEvent]);

  const handleInstallApp = async () => {
    setTimeout(function () {
      // in case redirect to app doesn't work, then redirect to play store
      fireAppBannerClickEvent({
        id: APP_BANNER.CAMPAIGN_ID.SMART,
        name: APP_BANNER.TYPE.SMART_INSTALL,
        currentPage: getCurrentPageBasedOnMetaTags(),
        deviceType: DEVICE_TYPE.ANDROID
      });
      window.location = installURL;
      setIsClosedBanner(true);
    }, 25);
    // redirect to open the installed app
    window.location = openAndroidAppURL;
    setIsClosedBanner(true);
  };

  const handleClose = () => {
    fireAppBannerCloseEvent({
      id: APP_BANNER.CAMPAIGN_ID.SMART,
      name: APP_BANNER.TYPE.SMART_INSTALL,
      currentPage: getCurrentPageBasedOnMetaTags(),
      deviceType: DEVICE_TYPE.ANDROID
    });

    setCookieToSkipAppBanner();
  };

  return (
    <AppBanner
      isClosed={isClosedBanner}
      handleClick={handleInstallApp}
      onClose={handleClose}
    />
  );
};

SmartBanner.propTypes = {
  installEvent: PropTypes.shape({
    prompt: PropTypes.func,
    userChoice: PropTypes.instanceOf(Promise)
  })
};

export default SmartBanner;
