import { HeaderAccount, Portal, useDrawer } from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import AccountDrawer from './AccountDrawer/AccountDrawer';
import { DRAWERS_ROOT } from '@app/constants/targets';
import { returnFocusToNavItem } from '@app/utils/DOMUtils';
import { AFFILIATION_DEALER } from '@app/constants/commonConstants';

const HeaderAccountWrapper = () => {
  const { t } = useTranslation();
  const userType = useSelector(s => s.common?.userType);
  const firstName = useSelector(s => s.common?.firstName);
  const lastName = useSelector(s => s.common?.lastName);
  const isCSPCustomer = useSelector(s => s.common?.isCSPCustomer);
  const isCSRUser = useSelector(s => s.common.isCatCSR);
  const userAffiliation = useSelector(s => s.common.userAffiliation);
  const selectedCustomerNumber = useSelector(
    s => s.common?.selectedCustomerNumber
  );

  const isLoggedIn = userType && userType !== 'G';
  const fullName = [firstName, lastName].join(' ').trim();
  const label = isLoggedIn ? fullName : t('SIGN_IN');
  const isDealerUser = userAffiliation === AFFILIATION_DEALER;
  const dealerCustomerNumber =
    (isCSPCustomer || isDealerUser) && selectedCustomerNumber
      ? selectedCustomerNumber
      : '';

  const isDesktop = useBreakpoint(PAGE_BREAKPOINTS.BLOCKS_LG);
  const { closeDrawer, initiatorProps, drawerProps, isDrawerOpen } = useDrawer({
    onClose: () => {
      // Avoid focusing the header when the consent acceptance modal shows up.
      if (document.activeElement === document.getElementById(drawerProps.id)) {
        returnFocusToNavItem('#headerAccount');
      }
    }
  });
  const accountDrawerProps = {
    closeDrawer,
    ...drawerProps
  };

  return (
    <>
      <HeaderAccount
        dealerCustomerNumber={dealerCustomerNumber}
        displayHeaderAccountLabel={isDesktop}
        handleDrawerProps={initiatorProps}
        id="headerAccount"
        isCurrent={isDrawerOpen}
        label={label}
        roleLabel={`(${t('CONTACT_US_CONF1')})`}
        showRole={!!isCSRUser}
      />
      <Portal id={DRAWERS_ROOT}>
        <AccountDrawer {...accountDrawerProps} />
      </Portal>
    </>
  );
};

export default HeaderAccountWrapper;
