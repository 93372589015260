import endpoints from '@app/constants/endpoints';
import { getHttpInstance } from '@app/services/defaultHttpService';
import { replaceTokensInString } from '@app/utils';
import { CSR_SUPPORTED_EDIT_ACTIONS } from './constants';

export const fetchCsrAuditSummary = async storeId => {
  const finalURL = replaceTokensInString(endpoints.CSR_AUDIT_SUMMARY, storeId);
  const http = getHttpInstance();
  let csrEvents = [];

  try {
    const response = await http.get(finalURL);
    csrEvents = response.data.csrEvents;
  } catch (error) {
    console.error({ error });
  }

  return csrEvents;
};

export const validateCSRAudits = changesMade => {
  return changesMade.some(({ eventType }) => {
    return CSR_SUPPORTED_EDIT_ACTIONS.includes(eventType);
  });
};

export const generateAnchor = (linkUrl, linkText) => {
  return (
    <a href={linkUrl} target="_blank" rel="noreferrer">
      {linkText}
    </a>
  );
};

export const CSRActionListItem = ({ children, eventType }) => (
  <li id={eventType} key={eventType} data-testid={eventType}>
    {children}
  </li>
);
