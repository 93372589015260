export const NAME_ERROR = '\\, <, >, [, ], {, }';
export const LOGGED_USER_DECLINE_EMAIL_MARKETING_OFFER_COOKIE =
  'loggedDeclineEmailMarketingOffer';
export const GUEST_USER_DECLINE_EMAIL_MARKETING_OFFER_COOKIE =
  'guestDeclineEmailMarketingOffer';
export const LOGGED_USER_SUPPRESS_EMAIL_MARKETING_MODAL_COOKIE =
  'loggedSuppressEmailMarketingModal';
export const GUEST_USER_SUPPRESS_EMAIL_MARKETING_MODAL_COOKIE =
  'guestSuppressEmailMarketingModal';
export const EMAIL_MARKETING_MODAl_ID = 'EMAIL_MARKETING_MODAl';
export const EMAIL_MARKETING_MODAl_FOCUS = '#ACCEPT_EMAIL_MARKETING_OPT-IN';
export const GUEST_USER_SUPPRESS_EMAIL_MARKETING_MODAL =
  'GUEST_USER_SUPPRESS_EMAIL_MARKETING_MODAL';
export const LOGGED_USER_SUPPRESS_EMAIL_MARKETING_MODAL =
  'LOGGED_USER_SUPPRESS_EMAIL_MARKETING_MODAL';
export const LOGGED_USER_EMAIL_MARKETING_DECLINE_COUNT_COOKIE =
  'loggedEmailMarketingDeclineCount';
export const GUEST_USER_EMAIL_MARKETING_DECLINE_COUNT_COOKIE =
  'guestUserEmailMarketingDeclineCount';
export const IS_LOGGED_USER_SHOWN_EMAIL_MARKETING_COOKIE =
  'isLoggedUserShownEmailMarketing';
export const IS_GUEST_USER_SHOWN_EMAIL_MARKETING_COOKIE =
  'isGuestUserShownEmailMarketing';
export const DECLINE_EMAIL_MARKETING_COOKIE_EXPIRATION_DAYS_1 = 1;
export const DECLINE_EMAIL_MARKETING_COOKIE_EXPIRATION_DAYS_14 = 14;
export const DECLINE_EMAIL_MARKETING_COOKIE_EXPIRATION_DAYS_60 = 60;
export const QUALTRICS_MAX_ATTEMPT_LIMIT = 5;
// Content translation keys
export const MODAL_TITLE = 'CONNECT_WITH_CATERPILLAR';
export const MODAL_DESCRIPTION = 'GET_EXCLUSIVE_OFFERS';
export const OPT_IN_SUCCESS_MESSAGE = 'OPTIN_CONFIRMATION';
export const OPT_IN_ERROR_MESSAGE = 'OPTIN_SYSTEM_ERROR';
export const MODAL_ACCEPT_OPT_IN_BUTTON_TEXT = 'SIGN_ME_UP';
export const MODAL_EMAIL_PLACEHOLDER = 'WISHLIST_EMAIL';
export const MODAL_FIRSTNAME_PLACEHOLDER = 'CAT_EMAIL_FIRSTNAME';
export const MODAL_LASTNAME_PLACEHOLDER = 'CAT_EMAIL_LASTNAME';
export const MODAL_COUNTRY_PLACEHOLDER = 'SELECT_COUNTRY_REGION';
export const MODAL_COUNTRY_LABEL = 'SELECT_COUNTRY_MARKETING_TITLE';
export const MODAL_EMAIL_ERROR = 'INVALID_EMAIL';
