import { getHttpInstance } from '@app/services/defaultHttpService';
import { replaceTokensInUrl } from '@app/utils';
import { TIMEOUT_EXTENDED } from '@app/constants/commonConstants';
import endpoints from '@app/constants/endpoints';

export const verifyDealerAssociation = async (storeId, userId) => {
  const http = getHttpInstance(TIMEOUT_EXTENDED);
  const url = replaceTokensInUrl(
    endpoints.VERIFY_DEALER_ASSOCIATION,
    storeId,
    userId
  );
  let response = { hasPotentialIssues: false, error: null };

  try {
    const {
      data: { potentialIssues }
    } = await http.get(url);

    response.hasPotentialIssues = potentialIssues !== 'N';
  } catch (e) {
    response.error = e;
  }

  return Promise.resolve(response);
};
