import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Conditional,
  useDrawer,
  Drawer,
  useModal
} from '@cat-ecom/pcc-components';
import { useBreakpoint } from '@app/hooks';
import { AssociatedDealerSelectCustomerForm } from '../AssociatedDealerSelectCustomerForm';
import { AssociatedDealerTermsAndConditions } from '../../AssociatedDealerTermsAndConditions';
import { DealerLocatorWrapper } from '@app/components/dealer-locator-react';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import { StoreLocation } from '../../types';
import { getDealerCustomerDcn } from '@app/store/checkout/actions';
import styles from '../../styles.module.scss';
import { removeSessionStorageCartSharingData } from '@app/components/pages/checkout/shopping-cart/ShoppingCartPage/utils';

export const AssociatedDealerSelectCustomerDrawer = ({
  drawer,
  formTitle,
  formMethods,
  onSubmit,
  isSelectDealerLoading,
  onClearSearch,
  dealer,
  customerNumber,
  stores
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const dealerLocatorDrawer = useDrawer({
    id: 'adp-blocks-dealer-locator-drawer',
    size: 'lg'
  });
  const [scrollToPrivacyNotice, setScrollToPrivacyNotice] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState('');
  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);
  const dealerLocatorHeading = isMD ? t('CAT_MA_ASSOCIATEDDEALERS') : 'ADP';
  const storeId = useSelector(s => s.common?.dealerUserStoreId);
  const { value } = customerNumber || '';

  useEffect(() => {
    if (storeId && value) {
      dispatch(getDealerCustomerDcn({ store: storeId, customerNumber: value }));
    }
  }, [dispatch, value, storeId]);

  const termsAndConditionsDrawer = useDrawer({
    id: 'associated-dealer-terms-and-conditions-drawer',
    size: 'lg'
  });

  const termsAndConditionsModal = useModal({
    isRequired: true,
    size: 'md'
  });

  const handleCloseADPDrawer = () => {
    removeSessionStorageCartSharingData();
    return drawer.handleClose();
  };

  return (
    <>
      <Drawer className={styles['drawer']} {...drawer.drawerProps}>
        <Conditional test={drawer.drawerProps.isActive}>
          <AssociatedDealerSelectCustomerForm
            formTitle={formTitle}
            formMethods={formMethods}
            onSubmit={onSubmit}
            isSelectDealerLoading={isSelectDealerLoading}
            onClearSearch={onClearSearch}
            handleClose={handleCloseADPDrawer}
            dealer={dealer}
            customerNumber={customerNumber}
            stores={stores}
            isModal={false}
            termsAndConditionsModal={termsAndConditionsModal}
            termsAndConditionsDrawer={termsAndConditionsDrawer}
            setScrollToPrivacyNotice={setScrollToPrivacyNotice}
            setShowTermsAndConditions={setShowTermsAndConditions}
            openDealerLocator={dealerLocatorDrawer.openDrawer}
            showPricingWarning={true}
            showSkipThisStep={false}
          />
        </Conditional>
      </Drawer>
      <DealerLocatorWrapper
        drawer={dealerLocatorDrawer}
        heading={dealerLocatorHeading}
      />
      <AssociatedDealerTermsAndConditions
        modal={termsAndConditionsModal}
        drawer={termsAndConditionsDrawer}
        formMethods={formMethods}
        dealer={dealer}
        showTermsAndConditions={showTermsAndConditions}
        scrollToPrivacyNotice={scrollToPrivacyNotice}
        isModal={false}
      />
    </>
  );
};

AssociatedDealerSelectCustomerDrawer.propTypes = {
  showModal: PropTypes.bool,
  drawer: PropTypes.any,
  modal: PropTypes.any,
  formMethods: PropTypes.any,
  formTitle: PropTypes.string,
  onClearSearch: PropTypes.func,
  onSubmit: PropTypes.func,
  dealer: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  customerNumber: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  stores: PropTypes.arrayOf(StoreLocation),
  className: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  isSelectDealerLoading: PropTypes.bool
};
