import PropTypes from 'prop-types';
import { AssociatedDealerDropdown } from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sortDealerName } from '../../utils';
import { DEALER_DROPDOWN_NAME } from '../../constants';
import { Dealer } from '../../types';
import {
  AFFILIATION_DEALER,
  USER_TYPE_GUEST
} from '@app/constants/commonConstants';

export const AssociatedDealerDealerDropdownWrapper = ({
  dealerList,
  dealer,
  selectedValue,
  onSelect
}) => {
  const [t] = useTranslation();
  const label = t('Footer_Dealer');
  const placeholder = t('CAT_MA_SELECTDEALER');
  const sortedDealerList = dealerList?.sort(sortDealerName);
  const userAffiliation = useSelector(s => s.common?.userAffiliation);
  const userType = useSelector(s => s?.common.userType);
  const isDealerUser = userAffiliation === AFFILIATION_DEALER;
  const isLoggedIn = userType !== USER_TYPE_GUEST;
  const isReadOnlyValue = typeof dealer === 'object' && dealer !== null;
  const isSingleDealer = dealerList?.length === 1;

  return (
    <AssociatedDealerDropdown
      name={DEALER_DROPDOWN_NAME}
      label={label}
      placeholder={placeholder}
      itemList={sortedDealerList}
      item={dealer}
      disabled={!!dealer}
      required={isLoggedIn && !isDealerUser}
      readOnly={isReadOnlyValue || isSingleDealer}
      selectedValue={selectedValue}
      onSelect={onSelect}
    />
  );
};

AssociatedDealerDealerDropdownWrapper.propTypes = {
  dealerList: PropTypes.arrayOf(Dealer),
  dealer: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  selectedValue: PropTypes.string,
  onSelect: PropTypes.func
};
