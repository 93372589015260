import ShoppingPreferenceCTA from '@app/components/common/ShoppingPreferenceCTA';
import RegistrationCTA from '@app/components/common/RegistrationCTA';
import MyEquipmentCTA from '@app/components/common/MyEquipmentCTA';
import FindYourDealerCTA from '@app/components/common/FindYourDealerCTA';
import { createPortal } from 'react-dom';
import SearchBannerWrapper from '@app/components/HomePageSearchBarWrapper/SearchBannerWrapper';

const EspotContentPortal = ({ type, buttonText, component, portalTarget }) => {
  let CTA;
  switch (component) {
    case 'myequipment':
      CTA = MyEquipmentCTA;
      break;
    case 'findyourdealer':
      CTA = FindYourDealerCTA;
      break;
    case 'shoppingpreferences':
      CTA = ShoppingPreferenceCTA;
      break;
    case 'hero-searchbox':
      CTA = SearchBannerWrapper;
      break;
    default:
      CTA = RegistrationCTA;
  }
  const props = {
    type: type,
    buttonText: buttonText
  };
  return portalTarget ? createPortal(<CTA {...props} />, portalTarget) : null;
};

export default EspotContentPortal;
