import { isEmpty } from '@app/utils';
import { UOM } from '../../constants/commonConstants';
import { waitForValidation } from '@app/utils/commonUtils';
import { cloneDeep } from 'lodash';

/**
 * This function is meant to tell if an array of specifications has both metric
 * and us units of measure within the specifications
 * @param {object[]} attributes array of objects with unit of measure attribute
 * @returns {boolean} wheter the attributes array has objects with both units of
 * measure (US and Metric)
 */
export const hasBothMetricAndUSSpecs = attributes =>
  attributes.some(a => a.uom === UOM.metric) &&
  attributes.some(a => a.uom === UOM.us);

/**
 * This function is meant to convert an array of products as objects
 * to an object with the structure: Object[uniqueId] = product
 * @param {object} params
 * @argument {object[]} params.products array of products as objects
 * @returns object of normalized products
 * @example
 * [
 *   { uniqueId: 1234, partNumber: '1R-1808'}, { uniqueId: 4321, partNumber: '110-6331'}
 * ]
 * =>
 * {
 *    1r-1808: { uniqueId: 1234, partNumber: '1R-1808'},
 *    110-6331: { uniqueId: 4321, partNumber: '110-6331'}
 * }
 *
 */
export const normalizeProducts = ({ products }) => {
  return products.reduce(
    (products, product) =>
      Object.assign(products, {
        [product.uniqueId ?? product.partNumber]: product
      }),
    {}
  );
};

/**
 * This function is meant to extract the product's uniqueId
 * @param {object[]} products array of products as objects
 * @returns array with all the uniqueIds of the products
 */
export const extractProductsIds = products => {
  if (Array.isArray(products) && !isEmpty(products)) {
    return products.map(({ uniqueId, partNumber }) => uniqueId ?? partNumber);
  } else {
    return [];
  }
};

/**
 * This function is meant to extract the product's details from ids list
 * @param {object[]} products array of products as objects
 * @param {object[]} uniqueIdsList array of product's ids
 * @returns array with all the product's details, with the same size of idsList size
 */
export const extractProductsDetailsFromIdsList = (
  productsById,
  uniqueIdsList
) => uniqueIdsList?.map(uniqueId => productsById[uniqueId]);

/**
 * Map a list of parts data into an Object by id using uniqueId or PartNumber as key
 * @param {Array} parts list of parts data to map into an Object
 * @param {boolean} checkForId check if use uniqueId or PartNumber
 * @returns
 */
export const mapPartsToObjById = (parts = [], checkForId = true) => {
  return Object.fromEntries(
    parts.map(part => [
      checkForId ? part.uniqueId ?? part.partNumber : part.partNumber,
      part
    ])
  );
};

/**
 * Helper to wait until store info is in the store
 */
export const waitForStoreInfo = (getState, options) =>
  waitForValidation(() => !!getState().common.selectedStore, options);

/**
 * This function is merge price and availblity data with fbt items
 * @param {object[]} fbtData array of fbt items
 * @param {object[]} fbtPriceData array of price and availablity of fbt items
 * @returns array with all the price details of fbt items
 */
export const getFbtDataWithPricing = (fbtData, fbtPriceData) => {
  return fbtData?.slice(0, 3)?.map((current, index) => {
    const priceData = fbtPriceData?.find(
      item => current.partNumber === item.partNumber
    );
    return {
      totalPrice: priceData?.totalPrice,
      enableAddToCart: priceData?.enableAddToCart,
      availability: priceData?.availability,
      weight: priceData?.weight,
      parentCatEntryId: priceData?.parentCatEntryId,
      itemCatEntryId: priceData?.itemCatEntryId,
      unformattedTotalPrice: priceData?.unformattedTotalPrice,
      unformattedUnitPrice: priceData?.unformattedUnitPrice,
      discountedPrice: priceData?.discountedPrice,
      position: index + 2,
      ...current
    };
  });
};

/**
 * This function is meant to update the name of the replacementParts from P&A
 * @param {object[]} replacementParts array of replacementParts items
 * @param {object[]} replacementSummaryItems array of replacementSummaryItems from summary API call
 * @returns array with updated replacementParts items
 */
export const transformReplacementPartsItems = (
  replacementParts,
  replacementSummaryItems
) => {
  const updatedReplacementItems = cloneDeep(replacementParts);
  replacementParts.forEach((item, index) => {
    replacementSummaryItems.forEach(summaryItem => {
      if (item?.partNumber === summaryItem?.partNumber) {
        updatedReplacementItems[index].name = summaryItem?.name;
      }
    });
  });
  return updatedReplacementItems;
};
