import PropTypes from 'prop-types';
import { createElement } from 'react';

const DOMRoute = ({ component, target, render, children = null }) => {
  const targetElement = document.getElementById(target);
  const getComponent = () => {
    if (render) {
      return render();
    }
    if (component) {
      return createElement(component);
    }
    return children;
  };

  return targetElement ? getComponent() : null;
};

export default DOMRoute;

DOMRoute.propTypes = {
  component: PropTypes.elementType,
  target: PropTypes.string,
  render: PropTypes.func,
  children: PropTypes.node
};
