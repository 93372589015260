import { STATUS } from '../../constants/commonConstants';
import {
  getPriceAndAvailabilityCacheData,
  setPriceAndAvailabilityCacheData
} from '@app/services/cacheService';
import { MAX_QUANTITY } from './constants';
import { isEmpty } from '@app/utils';
import { mapPartsToObjById } from './utils';

export const getInitialPriceAndAvailabilityById = async ({
  state,
  namespace,
  partsList,
  checkPartListId,
  useCacheService
}) => {
  const {
    common: {
      storeId,
      userId,
      selectedStore,
      userType,
      selectedCustomerNumber,
      selectedEndUseCode,
      selectedOrderType,
      langId
    }
  } = state;

  const cachePriceAndAvailability = useCacheService
    ? await getPriceAndAvailabilityCacheData({
        userType,
        userId,
        dcn: selectedCustomerNumber,
        endUseCode: selectedEndUseCode?.value,
        orderType: selectedOrderType?.value,
        langId,
        storeId,
        selectedStore,
        partsList
      })
    : [];

  // use PartNumber as key
  const cachePriceAndAvailabilityById = mapPartsToObjById(
    cachePriceAndAvailability,
    false
  );

  const existingData = state.products?.priceAndAvailability;
  const existingDataInNamespace = state.products?.[namespace];
  const initialPriceAndAvailability = partsList.map(item => {
    /**
     * Preserve existing data like unitPrice, discountedAmount, coreCharges, etc (data depending on the part)
     *  but reset data like total price and availability (data depending on the quantity)
     */
    const quantity = parseInt(item.quantity);
    const partIdentifier = checkPartListId
      ? item.uniqueId ?? item.partNumber
      : item.partNumber;
    const initialValue = {
      ...existingDataInNamespace?.[partIdentifier],
      ...existingData?.[partIdentifier],
      ...item,
      quantity,
      totalPrice: '',
      unformattedTotalPrice: '',
      availability: [],
      sourcingDetails: []
    };

    /**
     * Set status rejected if price suppress max quantity (99999)
     */
    if (quantity > MAX_QUANTITY) {
      return {
        ...initialValue,
        loadingStatus: STATUS.REJECTED
      };
    }

    /**
     * if using cacheService, set data from cache if exist
     */
    const cachedData = cachePriceAndAvailabilityById[item.partNumber];
    if (useCacheService && !isEmpty(cachedData)) {
      return {
        ...cachedData,
        ...item,
        loadingStatus: STATUS.RESOLVED
      };
    }

    /**
     * Set default values with status pending
     */
    return {
      ...initialValue,
      loadingStatus: STATUS.PENDING
    };
  });

  return mapPartsToObjById(initialPriceAndAvailability, checkPartListId);
};

export const getResponsePriceAndAvailabilityById = async ({
  state,
  namespace,
  partsToRequest,
  priceAndAvailability,
  checkPartListId,
  customerInfo,
  useCacheService
}) => {
  const priceAndAvailabilityById = mapPartsToObjById(
    priceAndAvailability,
    checkPartListId
  );

  const existingData = state.products?.priceAndAvailability;
  const existingDataInNamespace = state.products?.[namespace];
  const responsePriceAndAvailability = partsToRequest?.map(item => {
    const partIdentifier = checkPartListId
      ? item.uniqueId ?? item.partNumber
      : item.partNumber;
    const prevState = {
      ...existingDataInNamespace?.[partIdentifier],
      ...existingData?.[partIdentifier]
    };
    const quantity = parseInt(item.quantity);
    const partPriceAndAvailability = priceAndAvailabilityById[partIdentifier];
    if (!isEmpty(partPriceAndAvailability)) {
      // TODO: Why is this needed?
      const packageQty =
        prevState.packageQty ?? partPriceAndAvailability.packageQty;

      /**
       * set data from prev state + response + status Resolved
       */
      return {
        ...prevState,
        ...partPriceAndAvailability,
        ...item,
        quantity,
        packageQty,
        loadingStatus: STATUS.RESOLVED
      };
    } else if (quantity === 0) {
      /**
       * Preserve existing data about part and set total price as 0, and availability as empty.
       */
      const unformattedZeroPrice = '0.0';
      const formattedZeroPrice =
        state.dealerCustomer?.formattedZeroPrice ||
        customerInfo?.formattedZeroPrice ||
        unformattedZeroPrice;

      return {
        ...prevState,
        ...item,
        quantity,
        totalPrice: formattedZeroPrice,
        unformattedTotalPrice: unformattedZeroPrice,
        availability: [],
        sourcingDetails: [],
        loadingStatus: STATUS.RESOLVED
      };
    } else {
      /**
       * Use data similar as initial state with status rejected
       */
      return {
        ...prevState,
        ...item,
        quantity,
        totalPrice: '',
        unformattedTotalPrice: '',
        availability: [],
        sourcingDetails: [],
        loadingStatus: STATUS.REJECTED
      };
    }
  });

  if (useCacheService) {
    const {
      common: {
        storeId,
        userId,
        selectedStore,
        userType,
        selectedCustomerNumber,
        selectedEndUseCode,
        selectedOrderType,
        langId
      }
    } = state;

    const resolvedPriceAndAvailability = responsePriceAndAvailability.filter(
      ({ loadingStatus }) => loadingStatus === STATUS.RESOLVED
    );

    await setPriceAndAvailabilityCacheData({
      userType,
      userId,
      dcn: selectedCustomerNumber,
      storeId,
      selectedStore,
      langId,
      endUseCode: selectedEndUseCode?.value,
      orderType: selectedOrderType?.value,
      priceAndAvailability: resolvedPriceAndAvailability
    });
  }

  return mapPartsToObjById(responsePriceAndAvailability, checkPartListId);
};

export const getRejectedPriceAndAvailabilityById = ({
  state,
  namespace,
  partsToRequest,
  checkPartListId
}) => {
  const existingData = state.products?.priceAndAvailability;
  const existingDataInNamespace = state.products?.[namespace];
  const rejectedPriceAndAvailability = partsToRequest.map(item => {
    const partIdentifier = checkPartListId
      ? item.uniqueId ?? item.partNumber
      : item.partNumber;
    const prevState = {
      ...existingDataInNamespace?.[partIdentifier],
      ...existingData?.[partIdentifier]
    };

    /**
     * Preserve existing data like unitPrice, discountedAmount, coreCharges, etc (data depending on the part)
     *  but reset data like total price and availability (data depending on the quantity)
     *  and status as rejected
     */
    return {
      ...prevState,
      ...item,
      quantity: parseInt(item.quantity),
      totalPrice: '',
      unformattedTotalPrice: '',
      availability: [],
      sourcingDetails: [],
      loadingStatus: STATUS.REJECTED
    };
  });

  return mapPartsToObjById(rejectedPriceAndAvailability, checkPartListId);
};
