import useBuyOnBehalf from '../../../pages/csr-landing/hooks/useBuyOnBehalf';
import endpoints from '@app/constants/endpoints';
import links from '@app/constants/links';
import queryString from 'query-string';
import * as React from 'react';
import { getHttpInstance } from '@app/services/defaultHttpService';
import { isDev, replaceTokensInString } from '@app/utils';
import { useLocation } from 'react-router';

import { useSelector } from 'react-redux';
import { idleTimer } from '../CSRBanner/utils';
import {
  TIMEOUT_EXTENDED,
  LOCALHOST_CONTEXT_PREFIX,
  USER_TYPE_GUEST
} from '@app/constants/commonConstants';
import CSRBannerStrip from './CSRBannerStrip';
import { CatButton } from '@blocks-react/components/Button';
import { CSR_TEXTS } from '@app/components/pages/csr-landing/texts';

const CSRBannerBlocks = () => {
  const searchParams = useLocation().search;
  const { orderId } = queryString.parse(searchParams);
  const { firstName, lastName, isBuyOnBehalf, catalogId, langId, storeId } =
    useSelector(s => s.common);
  const userType = useSelector(s => s?.orders?.byId[orderId]?.userType);
  const orderCreatedBy = useSelector(
    s => s?.orders.byId[orderId]?.orderCreatedBy
  );
  const { CSR_LANDING_PAGE } = links;
  const { search } = useLocation();
  const [csrActive, setCsrActive] = React.useState(null);
  const { handleConnectToCustomer } = useBuyOnBehalf();

  const disconnect = async () => {
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    const url = endpoints.CSR_TABLE_RESET_CUSTOMER_SELECTION;
    try {
      const response = await http.get(url);
      let redirectionRoute = JSON.parse(
        response.data.replace('*/', '').replace('/*', '')
      );
      if (response) {
        sessionStorage.removeItem('isGuest');
        if (isDev()) {
          window.location.href = `${LOCALHOST_CONTEXT_PREFIX}${redirectionRoute.url}`;
        } else {
          window.location.href = redirectionRoute.url;
        }
      }
    } catch (e) {
      sessionStorage.removeItem('_userInactive');
    }
  };

  const onBackToOrdersForm = () => {
    const url = replaceTokensInString(
      CSR_LANDING_PAGE,
      catalogId,
      langId,
      storeId
    );

    window.location.href = url;
  };

  const isFromCSROrderSearch = sessionStorage.getItem('fromCSR');
  const isTransactingAsGuest = sessionStorage.getItem('isGuest');
  const isGuestOrder = userType === USER_TYPE_GUEST;

  const showOrderDetailBanner =
    search.includes('OrderDetail') || search.includes('OrderReturnRequest');

  React.useEffect(() => {
    if (showOrderDetailBanner) {
      setCsrActive(!!isFromCSROrderSearch);
    }
  }, [isFromCSROrderSearch, showOrderDetailBanner]);

  React.useEffect(() => {
    if (isBuyOnBehalf) {
      const timer = idleTimer({
        timeout: 900,
        onTimeout: () => disconnect()
      });

      return () => {
        timer.cleanUp();
      };
    }
  }, [isBuyOnBehalf]);

  const onDisconnect = async () => {
    disconnect();
  };

  const handleOptionClick = () => {
    handleConnectToCustomer(orderCreatedBy);
  };

  if (!isBuyOnBehalf && !csrActive) {
    return null;
  }

  if (isBuyOnBehalf) {
    return (
      <CSRBannerStrip>
        <span>
          {isTransactingAsGuest
            ? `${CSR_TEXTS.TRANSACTING_AS_GUEST}`
            : `${CSR_TEXTS.LOGGED_IN_AS} ${firstName ?? ''} ${lastName ?? ''}`}
        </span>
        <CatButton variant="contrast" onClick={onDisconnect}>
          <React.Fragment>{CSR_TEXTS.DISCONNECT_BTN}</React.Fragment>
        </CatButton>
      </CSRBannerStrip>
    );
  } else if (csrActive) {
    return (
      <CSRBannerStrip>
        <CatButton variant="contrast" onClick={onBackToOrdersForm}>
          <React.Fragment>
            {CSR_TEXTS.ORDER_DETAIL_DISCONNECT_BTN}
          </React.Fragment>
        </CatButton>
        <CatButton
          variant="contrast"
          disabled={isGuestOrder ? 'disabled' : ''}
          onClick={!isGuestOrder ? handleOptionClick : null}
        >
          <React.Fragment>{CSR_TEXTS.CONNECTO_TO_CUSTOMER}</React.Fragment>
        </CatButton>
      </CSRBannerStrip>
    );
  }
};

export default CSRBannerBlocks;
