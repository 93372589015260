/**
 * Search a session storage in the document
 * @param {string} name session storage name
 * @returns {string|null} session storage value or null
 */
export const getSessionStorage = name => {
  if (sessionStorage.getItem(name)) {
    return sessionStorage.getItem(name);
  }
  return null;
};
/**
 * Remove a session storage from the document
 * @param {string} name session storage Name
 */
export const removeSessionStorage = name => {
  if (getSessionStorage(name)) {
    sessionStorage.removeItem(name);
  }
};

/**
 * Set a session storage in the document
 * @param {string} name session storage name
 * @param {string} value session storage value
 */
export const setSessionStorage = (name, value) => {
  try {
    sessionStorage.setItem(name, value);
  } catch (error) {
    return;
  }
};
