import { useSelector } from 'react-redux';

import Conditional from '@app/components/common/Conditional';

import SmartBanner from '../SmartBanner';
import StaticBanner from '../StaticBanner';
import { shouldDisplayBanner } from '../utils';
import { isAndroidDevice, isIOSDevice } from '@app/utils/browserUtils';
import { STATUS } from '@app/constants/commonConstants';

const AppBannerWrapper = () => {
  const pccAndroidRetailAppBannerFlag = useSelector(
    state => state.featureFlag.PCCAndroidRetailAppBanner
  );
  const pccIOSRetailAppBannerFlag = useSelector(
    state => state.featureFlag?.PCCIOSRetailAppBanner
  );
  const pccStaticRetailAppBannerFlag = useSelector(
    state => state.featureFlag?.PCCStaticRetailAppBanner
  );

  const showBannerForAndroid =
    pccAndroidRetailAppBannerFlag && isAndroidDevice();
  const showBannerForIOS = pccIOSRetailAppBannerFlag && isIOSDevice();
  const showStaticBanner =
    pccStaticRetailAppBannerFlag && (showBannerForAndroid || showBannerForIOS);
  const showSmartBanner = !pccStaticRetailAppBannerFlag && showBannerForAndroid;

  const featureFlagStatus = useSelector(
    state => state.featureFlag.featureFlagStatus
  );

  const isFeatureFlagRequestResolved = featureFlagStatus === STATUS.RESOLVED;

  return (
    <Conditional test={shouldDisplayBanner() && isFeatureFlagRequestResolved}>
      <Conditional test={showStaticBanner}>
        <div data-testid="static-banner">
          <StaticBanner />
        </div>
      </Conditional>
      <Conditional test={showSmartBanner}>
        <div data-testid="smart-banner">
          <SmartBanner />
        </div>
      </Conditional>
    </Conditional>
  );
};

export default AppBannerWrapper;
