import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { REACT_ROOT } from '@app/constants/targets';

const WrapperContainer = ({ targetId = REACT_ROOT, children }) => {
  const target = document.getElementById(targetId);
  return target ? createPortal(<>{children}</>, target) : null;
};

WrapperContainer.propTypes = {
  targetId: PropTypes.string,
  children: PropTypes.node
};

export default WrapperContainer;
