import { useTranslation } from 'react-i18next';
import { AlloyButton, AlloyIcon } from 'cat-ecommerce-alloy';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { FIND_MY_SERIAL_DOTS, FIND_MY_SERIAL_LAST_INDEX } from './constants';
import ContactUsOptions from '../../../../common/ContactUsOptions';
import styles from './findMySerial.module.scss';

const FindMySerialItem = ({
  setPageIndex,
  pageIndex,
  item: { title, contactOption, image, button, id },
  children
}) => {
  const { t } = useTranslation();
  const containerInlineClasses = `w-100 ${styles['find-my-serial__text-align-center']} mt-3 d-flex flex-column flex-lg-row-reverse justify-content-lg-center`;

  return (
    <div
      className={cx(
        'w-100 position-relative',
        id === pageIndex ? 'd-block' : 'd-none'
      )}
    >
      <div className={containerInlineClasses} key={id} data-testid="firstImage">
        {image?.map((imgUrl, index) => {
          return (
            <img
              src={imgUrl}
              alt="img"
              key={imgUrl}
              className={`position-relative start-0 my-1 m-auto ${styles['findmyserialitem__image']}`}
            />
          );
        })}
      </div>
      <div
        className={`d-lg-none mt-3 mb-3 justify-content-center d-flex ${styles['findmyserial__sliderdots']}`}
      >
        {FIND_MY_SERIAL_DOTS.map(dot => {
          return (
            <AlloyButton
              buttonType="ghost"
              onClick={() => setPageIndex(dot)}
              key={dot}
              className={`border-0 ${styles['findmyserial__sliderdot--outer']}`}
            >
              <div
                className={`rounded-circle ms-2 border-0  ${
                  pageIndex === dot
                    ? styles['findmyserial__sliderdot--active']
                    : styles['findmyserial__sliderdot']
                }`}
              ></div>
            </AlloyButton>
          );
        })}
      </div>
      <div className="w-100 mt-4">
        <p className="fw-bold text-sans-serif text-xl u-color--ExtraDarkGray">
          {t(`${title}`)}
        </p>
      </div>
      <div className="w-100">{children}</div>
      <div className="d-flex position-relative align-items-lg-end justify-content-lg-end justify-content-center align-items-center">
        <AlloyButton
          buttonType="ghost"
          onClick={() =>
            setPageIndex(
              pageIndex !== FIND_MY_SERIAL_LAST_INDEX ? pageIndex + 1 : 0
            )
          }
          className="mt-3 border-0 text-uppercase mx-1"
        >
          {t(`${button}`)}
          <AlloyIcon
            icon="icon-chevron-right"
            className={`position-relative ${styles['findmyserial__alloy--icon']}`}
          />
        </AlloyButton>
      </div>
      <div className="mt-4">{contactOption && <ContactUsOptions />}</div>
    </div>
  );
};

FindMySerialItem.propTypes = {
  setPageIndex: PropTypes.func,
  pageIndex: PropTypes.number,
  item: PropTypes.shape({
    title: PropTypes.string,
    contactOption: PropTypes.bool,
    button: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.arrayOf(PropTypes.string)
  }),
  children: PropTypes.node
};
export default FindMySerialItem;
