import { useEspot, useReplaceTokenWithElement } from '@app/hooks';
import { Checkbox } from 'cat-ecommerce-alloy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Field from '../form/Field';
import {
  dealersHeadlessPrivacyPolicy,
  dealersHeadlessTermsConditionsURL,
  privacyNoticeHeadlessURL,
  termsOfUseHeadlessURL
} from '@app/utils/headlessUtils';
import {
  PRIVACY_POLICY_FLAG,
  TERMS_CONDITIONS_FEATURE_FLAG
} from '@app/constants/featureFlags';
import {
  CAT_TERMS_DEALER_PRIVACY_POLICY_ESPOT,
  PCC_ESPOT_DEALER_PRIVACY_POLICY_SNP
} from '@app/components/associated-dealer/AssociatedDealerModal/AssociatedDealerForm/LegalAcceptance/ChildModalESpot/constants';

const ConsentAcceptance = ({ dealer }) => {
  const [t] = useTranslation();
  const { replaceTokenWithElement } = useReplaceTokenWithElement();
  const enableTermsConditionFlag = useSelector(
    s => s.featureFlag?.[TERMS_CONDITIONS_FEATURE_FLAG]
  );
  let {
    seoURL,
    dealerName,
    dealerURL,
    dealerStoreId,
    displayDealerTermsAndCond
  } = dealer;
  const defaultSeoURL = useSelector(s => s.dealer?.seoURL);
  seoURL = seoURL ? seoURL : defaultSeoURL;

  const legalNoticesURL = `${seoURL}/LegalNotices`;
  const legalNoticesPrivacyURL = `${seoURL}/LegalNotices#privacy`;
  const dealersTermsConditionsURL = `${seoURL}/TermsConditionsPage`;
  const dealersPrivacyPolicyURL = `${seoURL}/PrivacyPolicyPage`;
  const privacyPolicyFlagValue = useSelector(
    s => s.featureFlag?.[PRIVACY_POLICY_FLAG]
  );
  const privacyPolicyHeadlessurl = dealersHeadlessPrivacyPolicy(dealerURL);
  const privacyNoticeUrl = privacyNoticeHeadlessURL(dealerURL);
  const termsOfUseUrl = termsOfUseHeadlessURL(dealerURL);

  const termsOfUseLink = privacyPolicyFlagValue
    ? { href: termsOfUseUrl, target: '_blank' }
    : {
        href: legalNoticesURL
      };

  const privacyNoticeLink = privacyPolicyFlagValue
    ? { href: privacyNoticeUrl, target: '_blank' }
    : { href: legalNoticesPrivacyURL };

  const privacyPolicyTextLinks = privacyPolicyFlagValue
    ? { href: privacyPolicyHeadlessurl, target: '_blank' }
    : { href: dealersPrivacyPolicyURL };

  const catTextLinkProps = enableTermsConditionFlag
    ? { href: dealersHeadlessTermsConditionsURL(dealerURL), target: '_blank' }
    : { href: dealersTermsConditionsURL };

  const defaultDealersTermsOfUse = replaceTokenWithElement(
    t('DEALERS_TERMS_OF_USE'),
    'text-sm',
    <span data-testId="dealer">{dealerName}</span>
  );

  const defaultDealersPrivacyNotice = replaceTokenWithElement(
    displayDealerTermsAndCond
      ? t('UR_DEALER_PRIVACY_NOTICE_HREF')
      : t('UR_CAT_DEALER_PRIVACY_NOTICE_HREF'),
    'text-sm',
    <span data-testId="dealer">{dealerName}</span>
  );
  /**
   * Returns clickable view of dealer specific Terms and condition.
   * @returns
   */
  const getDealerTermsAndConditions = () => (
    <a {...catTextLinkProps} className="text-sm">
      {defaultDealersTermsOfUse}
    </a>
  );

  /**
   * Returns clickable view of dealer specific Privacy notice.
   * @returns
   */
  const getDealerPrivacyNotice = () => (
    <a className="text-sm" {...privacyPolicyTextLinks}>
      {defaultDealersPrivacyNotice}
    </a>
  );
  const eSpotNames = [
    CAT_TERMS_DEALER_PRIVACY_POLICY_ESPOT,
    PCC_ESPOT_DEALER_PRIVACY_POLICY_SNP
  ].join(',');
  const { getEspotContent } = useEspot(eSpotNames, '', dealerStoreId);

  const espotContent = getEspotContent(CAT_TERMS_DEALER_PRIVACY_POLICY_ESPOT);
  const espotHtml =
    espotContent?.contents?.length > 0 ? espotContent?.contents[0]?.html : '';

  const espotDealerPrivacyPolicySNP =
    getEspotContent(PCC_ESPOT_DEALER_PRIVACY_POLICY_SNP) || '';
  const espotHtmlSP =
    espotDealerPrivacyPolicySNP?.contents?.length > 0
      ? espotDealerPrivacyPolicySNP?.contents[0]?.html
      : '';
  /**
   * Returns the consent label based on displayDealerTermsAndCond flag.
   * @param {string} consentKey
   * @returns
   */
  const defaultTermsAndConditions = consentKey =>
    replaceTokenWithElement(
      consentKey,
      'color-dark-gray text-sm',
      <a className="text-sm" {...termsOfUseLink}>
        {t('UR_PARTS_TERMS_OF_USE_HREF')}
      </a>,
      <a className="text-sm" {...privacyNoticeLink}>
        {t('UR_CAT_PRIVACY_NOTICE_HREF')}
      </a>,
      ...(!displayDealerTermsAndCond
        ? [getDealerTermsAndConditions(), getDealerPrivacyNotice()]
        : [
            replaceTokenWithElement(
              espotHtmlSP,
              'color-dark-gray text-sm',
              getDealerPrivacyNotice()
            )
          ])
    );

  const defaultTermsText = (
    <>
      <p className="color-dark-gray text-sm mb-0 fw-bold text-uppercase">
        {t('YES_I_ACCEPT')}
      </p>
      {!displayDealerTermsAndCond
        ? defaultTermsAndConditions(t('CHECKOUT_PRIVACY_TC_STATEMENT'))
        : defaultTermsAndConditions(espotHtml)}
    </>
  );

  const validateCheckbox = val =>
    !val ? t('ERROR_CAT_ACCEPT_TERMS_REQUIRED') : null;

  return (
    <div className="text-start">
      <Field
        as={Checkbox}
        type="checkbox"
        name="caterpillarPrivacyStatement"
        id="caterpillarPrivacyStatement"
        data-testid="caterpillarPrivacyStatement"
        label={defaultTermsText}
        validator={validateCheckbox}
        ariaLabel="Terms-and-conditions"
      />
    </div>
  );
};

export default ConsentAcceptance;

ConsentAcceptance.propTypes = {
  dealer: PropTypes.shape({
    seoURL: PropTypes.string,
    dealerName: PropTypes.string,
    dealerURL: PropTypes.string,
    dealerStoreId: PropTypes.string,
    displayDealerTermsAndCond: PropTypes.bool
  })
};
