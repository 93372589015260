import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { HeaderLogo } from '@cat-ecom/pcc-components';
import Conditional from '@app/components/common/Conditional';

const HeaderLogoWrapper = ({ isMobile }) => {
  const dealerLoaded = useSelector(s => s.dealer?.getDealerInfoSuccess);
  const url = useSelector(s => s?.dealer?.seoURL);
  const dealerName = useSelector(s => s?.dealer?.dealerName);
  const image = useSelector(s => s?.dealer?.imageURL);
  const isCSR = useSelector(s => s.common?.isCatCSR);
  const isCatCorp = useSelector(s => s.common?.isCatCorp);
  const isCatImage = (image ?? '').includes('catcorp');
  const isCatLogo = isCatCorp || isCatImage || isCSR;

  const height = isMobile ? '&hei=32' : '';
  const imageUrl = `${image}?fmt=png-alpha${height}&qlt=85,0&resMode=sharp2&op_usm=1.75,0.3,2,0`;

  return (
    <Conditional test={dealerLoaded}>
      <HeaderLogo
        url={url}
        image={imageUrl}
        alt={dealerName}
        isCatLogo={isCatLogo}
      />
    </Conditional>
  );
};

HeaderLogoWrapper.propTypes = {
  isMobile: PropTypes.bool
};
export default HeaderLogoWrapper;
