import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { SystemFeedback } from 'cat-ecommerce-alloy';
import ContactUsOptions from '../ContactUsOptions';
import styles from './ExceptionFeedback.module.scss';
const FEEDBACK_TYPE_ERROR = 'error';

const ExceptionFeedback = ({
  children,
  className,
  icon,
  preventScroll = false,
  showContact = false,
  title,
  message,
  type = FEEDBACK_TYPE_ERROR,
  isClosed = false,
  showCloseButton = false,
  setClose
}) => {
  const ref = useRef();

  const showFeedback = !!title || !!message || !!children;

  useEffect(() => {
    if (showFeedback && !preventScroll) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [preventScroll, showFeedback]);

  return (
    showFeedback && (
      <div
        className={cx('d-print-none', styles['legacy-wrapper'], className)}
        ref={ref}
      >
        <SystemFeedback
          setClose={setClose}
          message={message}
          title={title}
          type={type}
          icon={icon}
          showCloseButton={showCloseButton}
          isClosed={isClosed}
          className="w-100"
        >
          {children && children}
        </SystemFeedback>
        {showContact && <ContactUsOptions error />}
      </div>
    )
  );
};

ExceptionFeedback.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  preventScroll: PropTypes.bool,
  isClosed: PropTypes.bool,
  showContact: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  setClose: PropTypes.func,
  showCloseButton: PropTypes.bool
};

export default ExceptionFeedback;
