import { useSelector } from 'react-redux';
import { isFeatureAvailableForUserAndDealer } from '@app/utils/featureUtils';

const useBuyingOptionVisibility = () => {
  const featureValues = useSelector(s => s.common?.featureDetails?.data);
  const userCountryCode = useSelector(s => s.common?.userCountryCode);
  const dealerCountry = useSelector(s => s.dealer?.defaultCountry);
  const isCatCorp = useSelector(s => s.common?.isCatCorp);
  const catCardsAllowedCountries =
    featureValues?.PCC_FEATURE_CATCARDS_ALLOWED_COUNTRIES || '';

  const showCatCardOption = isCatCorp
    ? false
    : isFeatureAvailableForUserAndDealer(
        userCountryCode,
        dealerCountry,
        catCardsAllowedCountries
      );

  return {
    showCatCardOption
  };
};

export default useBuyingOptionVisibility;
