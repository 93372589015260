import cx from 'classnames';
import { Radio } from 'cat-ecommerce-alloy';
import Field from '../../../../../../common/form/Field';
import styles from '../SearchResult.module.scss';
import PropTypes from 'prop-types';

const DealerUserExpanderTitle = ({ clearFeedback, customerNumber, name }) => {
  return (
    <div
      data-testid="radio-wrapper"
      className={`pe-2 d-flex flex-grow-1 flex-column text-univers text-md text-start`}
    >
      <Field
        aria-label={customerNumber}
        as={Radio}
        className={`w-100 ${styles['dealer-customer__radio']}`}
        id={`customerNumber_${customerNumber}`}
        label={
          <b className="exclude-global" data-cs-mask>
            {customerNumber}
          </b>
        }
        name="dealerCustomerNumber"
        onChange={clearFeedback}
        type="radio"
        value={customerNumber}
      />
      {!!name && (
        <div
          data-testid="nameContainer"
          className={cx(
            styles['search-results__details-indent'],
            styles['search-result__name'],
            'text-start'
          )}
        >
          <b className="exclude-global u-color--DarkGray" data-cs-mask>
            {name}
          </b>
        </div>
      )}
    </div>
  );
};

export default DealerUserExpanderTitle;

DealerUserExpanderTitle.propTypes = {
  clearFeedback: PropTypes.func,
  customerNumber: PropTypes.string.isRequired,
  name: PropTypes.string
};
