import { preventDefaultLinkEvent, unNormalizeLink } from '@app/utils';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import useMyAccountLinks from '../useMyAccountLinks';

const useBackToQuotesSearchPage = () => {
  const { staticLinks } = useMyAccountLinks();
  const history = useHistory();

  const getQuotesSearchPageUrl = () => {
    const { url } = staticLinks.PENDING_QUOTES;
    const { pathname, search } = new URL(url, window.location);
    const pendingQuotePageParams = queryString.parse(search);
    // exclude current pending quote id to come back
    const { orderId: _orderId, ...params } = queryString.parse(
      history.location.search
    );
    // combine existent params with pending quote page params
    return `${pathname}?${queryString.stringify({
      ...params,
      ...pendingQuotePageParams
    })}`;
  };

  const goBackToQuotesSearchPage = e => {
    if (preventDefaultLinkEvent(e)) {
      const url = getQuotesSearchPageUrl();
      history.push(unNormalizeLink(url));
    }
  };

  return { getQuotesSearchPageUrl, goBackToQuotesSearchPage };
};

export default useBackToQuotesSearchPage;
