import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import links from '@app/constants/links';
import useEspot from '@app/hooks/useEspot';
import { isEmpty, replaceTokensInUrl } from '@app/utils';
import ProductList from '../../../common/product/ProductList';
import ProductListItem from '../../../common/product/ProductList/ProductListItem';
import Espot from '../../Espot/Espot';
import { getPartName } from '@app/utils/commonUtils';
import { SystemFeedback } from 'cat-ecommerce-alloy';
import styles from './CartConfirmationModal.module.scss';

const CartConfirmationModal = ({
  items = [],
  disclamer,
  stickyFooter = false,
  modalTitleOverRide
}) => {
  const [t] = useTranslation();
  const { storeId, catalogId, langId } = useSelector(state => state.common);
  const { SHOPPING_CART_LINK } = links;
  const isPMKit = useSelector(state => state.repairOptions?.isPMKit);

  let modalTitle;
  if (modalTitleOverRide && isPMKit) {
    modalTitle = modalTitleOverRide;
  } else {
    modalTitle =
      items?.length > 1
        ? t('ITEMS_CART_CONFIRMATIONS_PLURAL')
        : t('ITEM_CART_CONFIRMATION');
  }

  const cartUrl = replaceTokensInUrl(
    SHOPPING_CART_LINK,
    storeId,
    langId,
    catalogId
  );
  const espotName = 'timeSpecificShoppingCartContent';
  const { getEspotContent } = useEspot(espotName);
  const espotHtml = getEspotContent(espotName);

  return (
    <>
      <div
        className={cx(
          'container p-0',
          { 'p-2 overflow-y-auto': stickyFooter },
          { [styles['modal__height']]: stickyFooter }
        )}
      >
        <header>
          <h1 className="h2 display-1">{modalTitle}</h1>
        </header>
        <ProductList>
          {items.length > 0 &&
            items.map(
              ({
                name,
                partNumber,
                imageURL,
                isGenericPart,
                isDealerPartSelected,
                selectedDealerPart,
                cartQuantity
              }) => (
                <ProductListItem
                  key={name}
                  item={{
                    imageURL,
                    name:
                      isGenericPart && isDealerPartSelected
                        ? `${selectedDealerPart.partNumber}: ${selectedDealerPart.partDescription}`
                        : getPartName(partNumber, name),
                    quantity: cartQuantity
                  }}
                />
              )
            )}
        </ProductList>

        {!isEmpty(espotHtml) && (
          <Espot className="mt-4 mb-2" html={espotHtml} />
        )}
      </div>

      <div
        className={cx(
          { 'position-sticky bottom-0 p-2': stickyFooter },
          { [styles['modal__footer']]: stickyFooter }
        )}
      >
        <div className="text-center my-3">
          <a className="btn btn-primary btn-sm w-100 w-lg-auto" href={cartUrl}>
            {t('MSC_GO_TO_CART')}
          </a>
        </div>
        {!!disclamer && (
          <div data-testid="mlp--optionsText">
            <SystemFeedback
              type={disclamer.warning}
              message={disclamer.message}
            ></SystemFeedback>
          </div>
        )}
      </div>
    </>
  );
};

CartConfirmationModal.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      imageURL: PropTypes.string,
      name: PropTypes.string,
      cartQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ),
  disclamer: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.string,
      message: PropTypes.string
    })
  ),
  stickyFooter: PropTypes.bool,
  modalTitleOverRide: PropTypes.string
};
export default CartConfirmationModal;
