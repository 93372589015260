import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  SubheaderMyEquipment,
  useDrawer,
  Portal
} from '@cat-ecom/pcc-components';
import { CatIconMachineExcavator } from 'blocks-react/bedrock/components/Icons/MachineExcavator/MachineExcavator.js';
import { AccessEquipmentIcon } from '@app/components/common/SvgIcons';
import { CatIconSupportHeadphones } from 'blocks-react/bedrock/components/Icons/SupportHeadphones/SupportHeadphones.js';
import Conditional from '@app/components/common/Conditional';
import LoginDrawer from '../LoginDrawer';
import ListContent from '../LoginDrawer/ListContent';
import { USER_TYPE_GUEST } from '@app/constants/commonConstants';
import { DRAWERS_ROOT } from '@app/constants/targets';
import links, { headlessLinks } from '@app/constants/links';
import { replaceTokensInString } from '@app/utils';
import { returnFocusToNavItem } from '@app/utils/DOMUtils';
import { useRouteMatch, useLocation } from 'react-router-dom';
import {
  LEGACY_AJAX_LOGON_FORM_ROUTE,
  MY_ACCOUNT_SEARCH,
  GUEST_EQUIPMENT_VIEW
} from '@app/constants/routes';
import queryString from 'query-string';
import { PCC_HEADLESS_MY_EQUIPMENT_FLAG } from '@app/constants/featureFlags';

const MyEquipmentWrapper = ({ displayMobileNavLink }) => {
  const { t } = useTranslation();

  const match = useRouteMatch(LEGACY_AJAX_LOGON_FORM_ROUTE);
  const isGuestCurrentPage = useRouteMatch(GUEST_EQUIPMENT_VIEW);
  const { search } = useLocation();
  const paramsUrl = queryString.parse(search);
  const { route: currentRoute } = paramsUrl;
  const isCurrentPage = !!match && currentRoute === MY_ACCOUNT_SEARCH.EQUIPMENT;
  const userType = useSelector(s => s.common?.userType);
  const storeId = useSelector(s => s.common.storeId);
  const langId = useSelector(s => s.common.langId);
  const catalogId = useSelector(s => s.common.catalogId);
  const esiteURL = useSelector(s => s.common.esiteURL);

  const defaultSeoURL = useSelector(s => s.dealer?.seoURL);

  const isHeadlessMyEquipmentFeatureFlag = useSelector(
    s => s.featureFlag[PCC_HEADLESS_MY_EQUIPMENT_FLAG]
  );

  const myEquipmentUrl = replaceTokensInString(
    links.SIS_EQUIP_LIST_URL,
    catalogId,
    langId,
    storeId
  );
  const guestUrl = `${defaultSeoURL}/GuestUserMyEquipmentView?storeId=${storeId}`;

  const isGuestUser = userType === USER_TYPE_GUEST;

  const { closeDrawer, drawerProps, initiatorProps } = useDrawer({
    onClose: () => returnFocusToNavItem('#SubheaderMyEquipment')
  });

  const isCurrent = isGuestUser ? !!isGuestCurrentPage : isCurrentPage;

  const navItemProps = {
    href: isHeadlessMyEquipmentFeatureFlag
      ? replaceTokensInString(
          headlessLinks.HEADLESS_MY_EQUIPMENT_LINK,
          esiteURL
        )
      : isGuestUser
        ? guestUrl
        : myEquipmentUrl,
    ...(isGuestUser ? initiatorProps : {})
  };
  const loginDrawerProps = {
    closeDrawer,
    title: t('SIS_EQUIP_LIST'),
    drawerProps
  };

  const listItems = [
    {
      background: true,
      icon: <CatIconMachineExcavator size="lg" />,
      text: t('MEQ_BENEFIT_SIMPLIFY')
    },
    {
      background: true,
      icon: <AccessEquipmentIcon size="lg" />,
      text: t('MEQ_BENEFIT_ACCESS')
    },
    {
      background: true,
      icon: <CatIconSupportHeadphones size="lg" />,
      text: t('MEQ_BENEFIT_SUPPORT')
    }
  ];
  const label = t('SIS_EQUIP_LIST');

  return (
    <>
      <SubheaderMyEquipment
        displayMobileNavLink={displayMobileNavLink}
        id="SubheaderMyEquipment"
        label={label}
        navItemProps={navItemProps}
        isCurrent={isCurrent}
      />
      <Conditional test={false}>
        <Portal id={DRAWERS_ROOT}>
          <LoginDrawer {...loginDrawerProps}>
            <ListContent items={listItems} />
          </LoginDrawer>
        </Portal>
      </Conditional>
    </>
  );
};

MyEquipmentWrapper.propTypes = {
  displayMobileNavLink: PropTypes.bool
};

export default MyEquipmentWrapper;
