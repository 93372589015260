import { useState, useCallback } from 'react';

// This is intended to be used as a callback ref when DOM measurements like width, height, etc. are needed.
// See the following for more information:
// https://reactjs.org/docs/hooks-reference.html#useref
// https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
// https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
const useClientMeasurements = () => {
  const [measurements, setMeasurements] = useState(null);
  const ref = useCallback(node => {
    if (node !== null) {
      const boundingClientRect = node.getBoundingClientRect();
      setMeasurements({
        ...boundingClientRect,
        clientHeight: node.clientHeight,
        clientWidth: node.clientWidth
        // Add more measurements here as needed
      });
    }
  }, []);
  return [measurements, ref];
};

export default useClientMeasurements;
