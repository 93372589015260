import stylesModule from '@app/components/pages/summary-and-payment/styles.module.scss';
import cx from 'classnames';
import PropTypes from 'prop-types';

const LabelValue = ({
  complementItem,
  containerClassName,
  labelClassName = '',
  valueClassName = 'u-color--DarkGray',
  title,
  value,
  contentSquareMask,
  inputLabelClass = true,
  printDataSideBySide = true
}) => {
  return (
    <div
      className={cx(
        'row',
        containerClassName,
        stylesModule['row-print'],
        stylesModule['mt-print-negative-6']
      )}
    >
      <div
        className={cx('col-12', {
          [stylesModule['col-print-5']]: printDataSideBySide
        })}
      >
        <p
          className={cx(
            'exclude-global',
            stylesModule['text-print-sm'],
            labelClassName,
            { input__label: inputLabelClass }
          )}
        >
          {title}
        </p>
      </div>
      <div
        className={cx('col-12', {
          [stylesModule['col-print-7']]: printDataSideBySide
        })}
      >
        {Array.isArray(value) ? (
          value.map((item, index) => (
            <p
              data-cs-mask={contentSquareMask}
              key={index}
              className={cx(
                'exclude-global text-break overflow-hidden',
                stylesModule['text-print-sm'],
                valueClassName
              )}
            >
              {item}
            </p>
          ))
        ) : (
          <p
            data-cs-mask={contentSquareMask}
            className={cx(
              'exclude-global text-break overflow-hidden',
              stylesModule['text-print-sm'],
              valueClassName
            )}
          >
            {value}
          </p>
        )}
      </div>
      {complementItem && (
        <div
          className={cx('col-12', stylesModule['offset-print-5'], {
            [stylesModule['col-print-7']]: printDataSideBySide
          })}
        >
          {complementItem}
        </div>
      )}
    </div>
  );
};

LabelValue.propTypes = {
  containerClassName: PropTypes.string,
  complementItem: PropTypes.element,
  labelClassName: PropTypes.string,
  title: PropTypes.string,
  contentSquareMask: PropTypes.bool,
  value: PropTypes.node,
  valueClassName: PropTypes.string,
  inputLabelClass: PropTypes.bool,
  printDataSideBySide: PropTypes.bool
};

export default LabelValue;
