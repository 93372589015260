import { setShowedToast, wasToastShowed } from '../utils';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const BannerToastOnce = ({ children, name = '' }) => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (wasToastShowed(name)) {
      setShowToast(false);
    } else {
      setShowToast(true);
    }
  }, [name]);

  if (!showToast) {
    return;
  }
  setShowedToast(name);

  return <>{children}</>;
};

BannerToastOnce.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired
};

export default BannerToastOnce;
