export const FOOTER_GET_LANGUAGES_SUCCESS = 'FOOTER_GET_LANGUAGES_SUCCESS';
export const FOOTER_GET_LANGUAGES_FAIL = 'FOOTER_GET_LANGUAGES_FAIL';
export const FOOTER_GET_LANGUAGES_BEGIN = 'FOOTER_GET_LANGUAGES_BEGIN';
export const FOOTER_CHANGE_LANGUAGE_BEGIN = 'FOOTER_CHANGE_LANGUAGE_BEGIN';
export const FOOTER_CHANGE_LANGUAGE_SUCCESS = 'FOOTER_CHANGE_LANGUAGE_SUCCESS';
export const FOOTER_CHANGE_LANGUAGE_FAIL = 'FOOTER_CHANGE_LANGUAGE_FAIL';
export const FOOTER_GET_SOCIAL_LINKS_BEGIN = 'FOOTER_GET_SOCIAL_LINKS_BEGIN';
export const FOOTER_GET_SOCIAL_LINKS_SUCCESS =
  'FOOTER_GET_SOCIAL_LINKS_SUCCESS';
export const FOOTER_GET_SOCIAL_LINKS_FAIL = 'FOOTER_GET_SOCIAL_LINKS_FAIL';
