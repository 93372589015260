/**
 * Validation function that can be used in the onKeyDown prop in a Input element
 * to prevent user from typing non positive integers
 * @param {e} KeyboardEvent<HTMLInputElement>
 */

export const preventNonPositiveIntegerInput = e => {
  //regexp test for numbers only
  const isNumber = /[0-9]/g.test(e.key);
  // e.key.length is always 1 expect for especial keys like 'Space', 'Enter', 'Backspace' etc..
  const isSpecialKey = e.key.length > 1;
  //We want to let the user to copy/paste
  const isCopingOrPasting =
    (e.key?.toLowerCase() === 'v' || e.key?.toLowerCase() === 'c') && e.ctrlKey;
  // This conditional avoids the user to type anything different from numbers
  if (!isNumber && !isSpecialKey && !isCopingOrPasting) {
    e.preventDefault();
  }
};

/**
 * Returns a given number truncated to n decimal places
 * @param {number} number Number to be truncated
 * @param {number} decimalPlaces Numer of decimal places to trunc
 * @returns {number}
 */
export const restrictDecimalPoints = (number, decimalPlaces) => {
  const finalNumber = parseFloat(number.toFixed(decimalPlaces));
  return finalNumber;
};
