import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { AlloyButton, Popup, InlineLoader } from 'cat-ecommerce-alloy';
import ContactDealerPopup from '../../common/ContactDealerPopup';
import { availability as availabilityPropTypes } from './Availability.proptypes';
import TooltipDisclaimer from './TooltipDisclaimer';
import { useSelector } from 'react-redux';
import { getAvailabilityPickupText, getAvailabilityTitle } from './Common/util';
import styles from './Availability.module.scss';

const defaultValuesHoseAssembly = {
  hoseAssemblyIndicator: null,
  hoseAvailabilityLabel: null
};

const Availability = ({
  availabilityInformation,
  catEntryId,
  className,
  hideLabel,
  isLoading,
  hoseAssemblyDetails = defaultValuesHoseAssembly,
  showAdditionalInfo = false
}) => {
  const [t] = useTranslation();
  const isNotVoidOrZero = value => !!value && value !== '0';
  const showContactDealerOnly = (availabilityInformation || []).some(
    ({ quantityAvailable, showContactDealerPopUp }) =>
      !isNotVoidOrZero(quantityAvailable) && !!showContactDealerPopUp
  );
  const isDeliveryEnabled = useSelector(
    state => state.common.isDeliveryEnabled
  );
  const isPickUpEnabled = useSelector(state => state.dealer.isPickupEnabled);

  const cellStyles = showContactDealerOnly
    ? 'text-start m-0 py-1'
    : 'text-start m-0 ps-md-1 py-1';
  const popperOptions = {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'bottom'],
          rootBoundary: 'document'
        }
      }
    ]
  };
  const getDealerInitiator = msg => (
    <AlloyButton buttonType="link" className="p-0 text-start">
      {msg || t('CONTACT_YOUR_DEALER')}
    </AlloyButton>
  );

  const isQuantityAvailable = () =>
    (availabilityInformation || []).some(({ quantityAvailable }) =>
      isNotVoidOrZero(quantityAvailable)
    );

  const normalizeString = str => (str ?? '')?.toString().trim();

  const getHoseAssemblyDetails = () => {
    const hoseLabel = hoseAssemblyDetails.hoseAvailabilityLabel?.trim();
    const hoseDetails = hoseLabel || t('HOSE_DEFAULT_MESSAGE');
    return (
      <span
        className={`${styles['hose-availability-text']} ${styles['print__availability-text']}`}
      >
        {hoseDetails}
      </span>
    );
  };

  const getLabel = () =>
    !hideLabel && (
      <div className="d-flex align-items-center justify-content-start mb-2">
        <span className="text-sm text-uppercase text-univers--bold u-color--ExtraDarkGray m-0">
          {getAvailabilityTitle(isDeliveryEnabled, isPickUpEnabled, t)}
        </span>
        <TooltipDisclaimer />
      </div>
    );

  const getPopupMessage = (
    showContactDealerPopUp,
    msg,
    catEntryId,
    popperOptions
  ) => {
    return showContactDealerPopUp || !msg ? (
      <>
        <div className="d-flex d-print-none">
          <Popup
            contents={<ContactDealerPopup catEntryId={catEntryId} />}
            initiator={getDealerInitiator(msg)}
            className={`${styles['availability-contact-dealer__popup']}`}
            popperOptions={popperOptions}
          />
        </div>
        <div
          className={`${styles['print__availability-text']} d-none d-print-block`}
        >
          {msg || t('CONTACT_YOUR_DEALER')}
        </div>
      </>
    ) : (
      <>
        <div className={`${styles['print__availability-text']}`}>{msg}</div>
      </>
    );
  };
  const getAvailabilityTable = () =>
    (isQuantityAvailable() || showContactDealerOnly) && (
      <>
        {getLabel()}
        <table>
          <tbody>
            {hoseAssemblyDetails.hoseAssemblyIndicator
              ? getHoseAssemblyDetails()
              : (availabilityInformation || []).map(
                  ({
                    quantityAvailable,
                    availabilityMessage,
                    showContactDealerPopUp
                  }) => {
                    const msg = normalizeString(availabilityMessage);
                    const qty = normalizeString(quantityAvailable);
                    return isNotVoidOrZero(qty) || showContactDealerOnly ? (
                      <tr key={`${qty}-${msg || 'contact-dealer'}`}>
                        {!showContactDealerOnly ? (
                          <td
                            className={cx(
                              cellStyles,
                              styles['availability-text'],
                              styles['print__availability-text']
                            )}
                          >
                            {showAdditionalInfo ? qty : `${qty}:`}
                          </td>
                        ) : null}
                        <td
                          className={cx(
                            cellStyles,
                            'u-color--DarkGray text-wrap break-all'
                          )}
                        >
                          {getPopupMessage(
                            showContactDealerPopUp,
                            msg,
                            catEntryId,
                            popperOptions
                          )}
                        </td>
                      </tr>
                    ) : null;
                  }
                )}
          </tbody>
        </table>
        {showAdditionalInfo &&
          getAvailabilityPickupText(
            isDeliveryEnabled,
            isPickUpEnabled,
            t,
            'ps-2'
          )}
      </>
    );

  return (
    <div className={className}>
      {isLoading ? (
        <>
          {getLabel()}
          <InlineLoader />
        </>
      ) : (
        getAvailabilityTable()
      )}
    </div>
  );
};

Availability.propTypes = {
  className: PropTypes.string,
  catEntryId: PropTypes.string,
  hideLabel: PropTypes.bool,
  isLoading: PropTypes.bool,
  availabilityInformation: availabilityPropTypes,
  hoseAssemblyDetails: PropTypes.shape({
    hoseAssemblyIndicator: PropTypes.bool,
    hoseAvailabilityLabel: PropTypes.string
  }),
  showAdditionalInfo: PropTypes.bool
};

export default Availability;
