import PropTypes from 'prop-types';
import { CatIconShop } from '@blocks-react/components/Icons/Shop';
import { CatIconBox } from '@blocks-react/components/Icons/Box';
import { CatIconManual } from '@blocks-react/components/Icons/Manual';
import { CatIconEditBox } from '@blocks-react/components/Icons/EditBox';
import { CatIconExternalLinkBox } from '@blocks-react/components/Icons/ExternalLinkBox';
import { CatIconSwap } from '@blocks-react/components/Icons/Swap';
import { CatIconCancel } from '@blocks-react/components/Icons/Cancel';
import { CatIconCheck } from 'blocks-react/bedrock/components/Icons/Check';
import { CatIconTaskComplete } from 'blocks-react/bedrock/components/Icons/TaskComplete';
import { CatIconTools } from 'blocks-react/bedrock/components/Icons/Tools';
import { CatIconEngine } from '@blocks-react/components/Icons/Engine';
import { CatIconDashboard2 } from 'blocks-react/bedrock/components/Icons/Dashboard2';

const EquipmentActionIcon = ({ name, iconControls }) => {
  switch (name) {
    case 'SHOP_PARTS':
      return <CatIconShop {...iconControls} />;
    case 'ALP_MAINTENANCE_REPAIRS':
      return <CatIconBox {...iconControls} />;
    case 'SIS_PART_LOOKUP':
      return <CatIconManual {...iconControls} />;
    case 'CAT_PARTS_DIAGRAM':
      return <CatIconEngine {...iconControls} />;
    case 'ENGINE_WARRANTY':
      return <CatIconTaskComplete {...iconControls} />;
    case 'CHANGE_EQUIPMENT':
      return <CatIconSwap {...iconControls} />;
    case 'REQUEST_SERVICE':
      return <CatIconTools {...iconControls} />;
    case 'SIS':
      return <CatIconExternalLinkBox {...iconControls} />;
    case 'EDIT':
    case 'MA_EDIT':
      return <CatIconEditBox {...iconControls} />;
    case 'SELECT_EQUIPMENT':
      return <CatIconCheck {...iconControls} />;
    case 'CLEAR_SELECTION':
      return <CatIconCancel {...iconControls} />;
    case 'VIEW_DASHBOARD':
      return <CatIconDashboard2 {...iconControls} />;
    default:
      return <></>;
  }
};

EquipmentActionIcon.propTypes = {
  name: PropTypes.string,
  iconControls: PropTypes.object
};

export default EquipmentActionIcon;
