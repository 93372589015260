import { STATUS } from '../../constants/commonConstants';
import * as types from './constants';
import * as savedListTypes from '../account/constants';

const SavedListDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case types.ITEM_NOTES_UPDATE_BEGIN:
    case types.SAVED_LIST_IMPORT_ITEM_BEGIN:
    case types.SAVED_LIST_DETAIL_GET_BEGIN: {
      return {
        ...state,
        status: STATUS.PENDING
      };
    }
    case types.SAVED_LIST_DETAIL_GET_SUCCESS: {
      return {
        ...state,
        currentId: action.payload.orderId ?? '',
        status: STATUS.RESOLVED,
        savedListDetailsLastUpdate: action.payload?.savedListDetailsLastUpdate,
        isEditable: action.payload?.savedListInfo?.isEditable,
        currentListCreatedBy: action.payload?.savedListInfo?.createdBy,
        orderCreatedBy: action.payload?.orderCreatedBy
      };
    }
    case types.ITEM_NOTES_UPDATE_FAIL:
    case types.SAVED_LIST_IMPORT_ITEM_FAIL:
    case types.SAVED_LIST_DETAIL_GET_FAIL: {
      return {
        ...state,
        status: STATUS.REJECTED
      };
    }
    case types.SAVED_LIST_IMPORT_ITEM_SUCCESS:
    case types.ITEM_NOTES_UPDATE_SUCCESS: {
      return {
        ...state,
        status: STATUS.RESOLVED
      };
    }
    case savedListTypes.AC_ADDCART_SAVEDLIST_BEGIN: {
      // This action also hits the account reducer, so the way to differentiate is by looking at the pathname
      if (
        window.location.pathname.includes(types.SAVED_LIST_DETAIL_PATH_STRING)
      ) {
        return { ...state, status: STATUS.PENDING };
      } else {
        return { ...state };
      }
    }
    case savedListTypes.AC_ADDCART_SAVEDLIST_FAIL: {
      // This action also hits the account reducer, so the way to differentiate is by looking at the pathname
      if (
        window.location.pathname.includes(types.SAVED_LIST_DETAIL_PATH_STRING)
      ) {
        return { ...state, status: STATUS.REJECTED };
      } else {
        return { ...state };
      }
    }
    case types.SAVED_LIST_DETAIL_ADD_ITEM_BEGINS: {
      return {
        ...state,
        addItemStatus: STATUS.PENDING
      };
    }
    case types.SAVED_LIST_DETAIL_ADD_ITEM_SUCCESS: {
      return {
        ...state,
        addItemStatus: STATUS.RESOLVED
      };
    }
    case types.SAVED_LIST_DETAIL_ADD_ITEM_FAIL: {
      return {
        ...state,
        addItemStatus: STATUS.REJECTED
      };
    }
    default: {
      return state;
    }
  }
};

export default SavedListDetailReducer;
