import Proptypes from 'prop-types';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  lazy,
  Suspense
} from 'react';
import { PageLoader, ToastContext } from 'cat-ecommerce-alloy';

import { useSelector } from 'react-redux';
import {
  OPT_IN_SUCCESS_MESSAGE,
  QUALTRICS_MAX_ATTEMPT_LIMIT
} from './constants';
import {
  shouldShowModal,
  setCookieToDeclineEmailMarketingOffer,
  setUniquePageSessionFromCatCorp,
  getUniquePageDetails,
  isUniquePage,
  setCookieToSuppressEmailMarketingModal,
  setEmailMarketingShownCookie,
  getEmailMarketingShownCookie,
  isCountrySuppressed
} from './utils';
import {
  AFFILIATION_DEALER,
  ONETRUST,
  STATUS,
  USER_ROLES,
  USER_TYPE_GUEST
} from '@app/constants/commonConstants';
import { useTranslation } from 'react-i18next';
import { getFormSubmitEvent } from '@app/components/common/analytics/analyticsUtils';
import {
  STATUS_SUCCESS,
  STATUS_FAIL
} from '@app/components/common/analytics/analyticsConstants';
import {
  isConsentTypeAvailable,
  loadQualtrics,
  runQualtrics,
  replaceTokensInUrl
} from '@app/utils';
import { useTracking } from 'react-tracking';
import { useHttp } from '@app/hooks';
import endpoints from '@app/constants/endpoints';

const CatModalWrapper = lazy(() => import('./CatModalWrapper'));

const EmailMarketingOptInEffect = () => {
  const { t } = useTranslation();

  const { trackEvent } = useTracking();

  const { createSuccessToast } = useContext(ToastContext);

  const { invoke } = useHttp();

  const [suppressedCountriesList, setsuppressedCountriesList] = useState();

  const [emailmodal, setEmailmodal] = useState(false);

  const [oneTrustFunctionalCookie, setOneTrustFunctionalCookie] =
    useState(false);
  const googleAnalyticsEvent = status => {
    return getFormSubmitEvent({
      formContent: 'N/A',
      formContent2: 'N/A',
      formName: 'Guest Email Marketing Opt-in Form',
      formStatus: status
    });
  };

  const [emailMarketingModalWasClosed, setEmailMarketingModalWasClosed] =
    useState(false);

  const {
    areTranslationsLoaded,
    receiveEmail,
    optInEmailMarketingStatus,
    userAffiliation,
    isCatCSR,
    userRoles,
    getCommonVarsStatus
  } = useSelector(s => s.common);

  const isDealerUser = userAffiliation === AFFILIATION_DEALER;
  const userContryCode = useSelector(state => state.common?.userCountryCode);
  const pccEmailMarketingOptIn = useSelector(
    state => state?.featureFlag?.PCCEmailMarketingOptIn
  );
  const storeId = useSelector(state => state.common?.storeId);
  const isCatCorp = useSelector(s => s.common?.isCatCorp);
  const userType = useSelector(s => s.common?.userType);
  const isBuyOnBehalf = useSelector(s => s.common?.isBuyOnBehalf) || false;

  const optInFailed = optInEmailMarketingStatus === STATUS.REJECTED;
  const optInSucceeded = optInEmailMarketingStatus === STATUS.RESOLVED;
  const hasOptedIn = optInFailed || optInSucceeded;
  const isLoggedIn = !!userType && userType !== USER_TYPE_GUEST;

  const isUserTypeAllowed = useCallback(() => {
    if (isDealerUser || isCatCSR) {
      return false;
    } else if (userRoles) {
      const CALRoles = [
        USER_ROLES.ADMIN,
        USER_ROLES.APPROVER,
        USER_ROLES.SUBMITTER
      ];
      return !CALRoles.some(role => userRoles.includes(role));
    }
    return true;
  }, [isCatCSR, isDealerUser, userRoles]);

  const triggerQualtricsManual = useCallback(
    (timeoutId = null, attempts = QUALTRICS_MAX_ATTEMPT_LIMIT) => {
      if (window.QSI) {
        clearTimeout(timeoutId);
        loadQualtrics();
        runQualtrics();
        return;
      }
      if (attempts > 0) {
        const timeout = setTimeout(
          () => triggerQualtricsManual(timeout, attempts - 1),
          1000
        );
      }
    },
    []
  );

  const removeHiddenScroll = () => {
    document.body.style.overflow = 'unset';
  };

  useEffect(() => {
    if (invoke && storeId) {
      const getSuppressedCountryList = async () => {
        const targetEndpoint = endpoints.EMAIL_OPTIN_SUPPRESSED_COUNTRY_LIST;
        const url = replaceTokensInUrl(targetEndpoint, storeId);
        try {
          const { storeConfValue = '' } = await invoke({
            url,
            rethrowError: true
          });
          setsuppressedCountriesList(storeConfValue);
        } catch {
          setsuppressedCountriesList('');
        }
      };
      getSuppressedCountryList();
    }
  }, [invoke, storeId]);

  useEffect(() => {
    if (optInFailed && !isLoggedIn) {
      trackEvent(googleAnalyticsEvent(STATUS_FAIL)); // to add dataLayer when optIN api fails
    }
  }, [isLoggedIn, optInFailed, trackEvent]);

  useEffect(() => {
    if (emailmodal && optInSucceeded) {
      if (receiveEmail) {
        createSuccessToast(t(OPT_IN_SUCCESS_MESSAGE));
        if (!isLoggedIn) {
          trackEvent(googleAnalyticsEvent(STATUS_SUCCESS)); // to add dataLayer when optIN api success
        }
      }
      setEmailmodal(false);
      removeHiddenScroll();
    }
  }, [
    createSuccessToast,
    t,
    isLoggedIn,
    emailmodal,
    optInSucceeded,
    receiveEmail,
    trackEvent
  ]);

  useEffect(() => {
    if (isCatCorp === false) {
      const uniquePageDetails = getUniquePageDetails();
      setUniquePageSessionFromCatCorp(uniquePageDetails);
    }
  }, [isCatCorp]);

  useEffect(() => {
    if (emailMarketingModalWasClosed) {
      setEmailmodal(false);
      removeHiddenScroll();
      if (!hasOptedIn) {
        setCookieToDeclineEmailMarketingOffer(isLoggedIn);
      } else if (optInFailed) {
        setCookieToSuppressEmailMarketingModal(isLoggedIn);
      }
    }
  }, [emailMarketingModalWasClosed, hasOptedIn, optInFailed, isLoggedIn]);

  useEffect(() => {
    if (isConsentTypeAvailable(ONETRUST.FUNCTIONAL_COOKIES_ACCEPTED)) {
      setOneTrustFunctionalCookie(true);
      return;
    }
    window.addEventListener(ONETRUST.GROUP_UPDATED_EVENT_LISTENER, e => {
      const getGroup = e.detail;
      if (getGroup.includes(ONETRUST.FUNCTIONAL_COOKIES)) {
        setOneTrustFunctionalCookie(true);
      }
    });
  }, []);

  useEffect(() => {
    if (receiveEmail === false && emailmodal && optInSucceeded) {
      setEmailMarketingModalWasClosed(true);
      setEmailmodal(false);
      removeHiddenScroll();
    }
  }, [receiveEmail, emailmodal, optInSucceeded]);

  useEffect(() => {
    if (
      !oneTrustFunctionalCookie ||
      !areTranslationsLoaded ||
      !userContryCode ||
      isBuyOnBehalf ||
      receiveEmail === undefined ||
      pccEmailMarketingOptIn === undefined ||
      getCommonVarsStatus !== STATUS.RESOLVED ||
      suppressedCountriesList === undefined
    ) {
      return;
    }
    const isPageVisited = isUniquePage(getUniquePageDetails());
    if (
      isCatCorp === false &&
      !receiveEmail &&
      pccEmailMarketingOptIn &&
      isPageVisited &&
      !isCountrySuppressed(userContryCode, suppressedCountriesList) &&
      isUserTypeAllowed() &&
      shouldShowModal(isLoggedIn)
    ) {
      setEmailMarketingShownCookie(isLoggedIn);
      setEmailmodal(true);
    } else if (!getEmailMarketingShownCookie(isLoggedIn)) {
      triggerQualtricsManual();
    }
  }, [
    isBuyOnBehalf,
    receiveEmail,
    areTranslationsLoaded,
    isLoggedIn,
    pccEmailMarketingOptIn,
    isCatCorp,
    getCommonVarsStatus,
    userContryCode,
    suppressedCountriesList,
    oneTrustFunctionalCookie,
    isUserTypeAllowed,
    triggerQualtricsManual
  ]);

  return (
    emailmodal && (
      <Suspense fallback={<PageLoader />}>
        <CatModalWrapper
          isLoggedIn={isLoggedIn}
          isActive={emailmodal}
          suppressedCountriesList={suppressedCountriesList}
          setEmailMarketingModalWasClosed={setEmailMarketingModalWasClosed}
        />
      </Suspense>
    )
  );
};

EmailMarketingOptInEffect.propTypes = {
  isLoggedIn: Proptypes.bool
};

export default EmailMarketingOptInEffect;
