import PropTypes from 'prop-types';
const CheckboxField = ({ ariaLabel, as: Component, setValue, ...props }) => {
  return (
    <Component
      aria-label={ariaLabel}
      data-testid="field-checkbox"
      setChecked={setValue}
      {...props}
    />
  );
};

CheckboxField.propTypes = {
  as: PropTypes.elementType.isRequired,
  ariaLabel: PropTypes.string,
  setValue: PropTypes.func.isRequired
};

export { CheckboxField };
