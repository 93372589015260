import { STATUS } from '../../constants/commonConstants';
import {
  RETURN_REQUEST_BY_BEGIN,
  RETURN_REQUEST_BY_FAIL,
  RETURN_REQUEST_BY_SUCCESS,
  RETURN_ORDER_GET_BEGIN,
  RETURN_ORDER_GET_SUCCESS,
  RETURN_ORDER_GET_FAIL,
  RETURN_ORDER_HISTORY_GET_BEGIN,
  RETURN_ORDER_HISTORY_GET_SUCCESS,
  RETURN_ORDER_HISTORY_GET_FAIL,
  RETURN_ORDER_HISTORY_RESET_STATUS
} from './constants';

const returnRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case RETURN_REQUEST_BY_BEGIN: {
      return { ...state, status: STATUS.PENDING };
    }
    case RETURN_REQUEST_BY_SUCCESS: {
      return {
        ...state,
        status: STATUS.RESOLVED,
        ...action.payload
      };
    }
    case RETURN_REQUEST_BY_FAIL: {
      return {
        ...state,
        status: STATUS.REJECTED
      };
    }
    case RETURN_ORDER_GET_BEGIN: {
      return {
        ...state,
        orderCallStatus: STATUS.PENDING
      };
    }
    case RETURN_ORDER_GET_SUCCESS: {
      return {
        ...state,
        orderCallStatus: STATUS.RESOLVED,
        ...action.payload
      };
    }
    case RETURN_ORDER_GET_FAIL: {
      return {
        ...state,
        orderCallStatus: STATUS.REJECTED
      };
    }
    case RETURN_ORDER_HISTORY_GET_BEGIN: {
      return {
        ...state,
        returnHistoryCallStatus: STATUS.PENDING
      };
    }
    case RETURN_ORDER_HISTORY_GET_SUCCESS: {
      return {
        ...state,
        returnHistoryCallStatus: STATUS.RESOLVED,
        ...action.payload
      };
    }
    case RETURN_ORDER_HISTORY_GET_FAIL: {
      return {
        ...state,
        returnHistoryCallStatus: STATUS.REJECTED
      };
    }
    case RETURN_ORDER_HISTORY_RESET_STATUS: {
      return {
        ...state,
        returnOrderHistory: [],
        order: {
          ...state.order,
          orderStatus: null
        }
      };
    }
    default:
      return state;
  }
};

export default returnRequestReducer;
