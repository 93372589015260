import { AlloyButton } from 'cat-ecommerce-alloy';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UNKNOWN_ERROR_MESSAGE } from '../../../constants/commonConstants';
import { isEmpty, replaceTokens, replaceTokensInString } from '../../../utils';
import ExceptionFeedback from './ExceptionFeedback';

const ExceptionFeedbackBySelector = ({
  actionMap,
  children,
  className,
  errorCodeLabel,
  hideTitle,
  messageClassName = '',
  preventScroll,
  selector,
  showContactUs = false,
  skipTranslation,
  isClosed,
  showCloseButton,
  setClose,
  hideErrorCode
}) => {
  const BUTTON_TYPE_SECONDARY = 'secondary';
  const { t } = useTranslation();
  const error = useSelector(selector);
  const catalogId = useSelector(state => state?.common.catalogId);
  const storeId = useSelector(state => state?.common.storeId);
  const langId = useSelector(state => state?.common.langId);

  const {
    actions,
    code,
    message,
    showContact,
    title,
    messageParams,
    severity,
    icon
  } = error || {};

  const getTitle = () => {
    if (hideTitle) {
      return undefined;
    }
    return skipTranslation ? title : t(title);
  };

  const getFormattedCode = () => {
    if (!code || hideErrorCode) {
      return '';
    }
    return ` (${
      errorCodeLabel ? replaceTokensInString(t(errorCodeLabel), code) : code
    })`;
  };

  const getMessage = (m, params = [], shouldSkipTranslation = false) => {
    const selectedMessage = (shouldSkipTranslation ? m : t(m, m)) || m;
    const msg = m
      ? replaceTokens(selectedMessage, params)
      : t(UNKNOWN_ERROR_MESSAGE);
    return (
      <p
        key={msg}
        className={cx('exclude-global text-sm', messageClassName)}
      >{`${msg}${getFormattedCode()}`}</p>
    );
  };

  const handleContactUsClick = () => {
    if (catalogId && storeId && langId) {
      window.location.href = `CATContactUsDisplayView?catalogId=${catalogId}&langId=${langId}&storeId=${storeId}`;
    }
  };

  const getCallToAction = ({
    buttonText,
    buttonType = BUTTON_TYPE_SECONDARY,
    name
  }) => (
    <AlloyButton
      key={name}
      buttonType={buttonType}
      className="me-2"
      onClick={actionMap[name]}
    >
      {t(buttonText)}
    </AlloyButton>
  );

  return (
    ((!hideTitle && !!title) || !!message) && (
      <ExceptionFeedback
        className={className}
        icon={icon}
        preventScroll={preventScroll}
        showContact={showContact}
        isClosed={isClosed}
        setClose={setClose}
        showCloseButton={showCloseButton}
        title={getTitle()}
        type={severity}
      >
        {Array.isArray(message) && message.map(m => getMessage(m))}
        {typeof message === 'string' &&
          getMessage(message, messageParams, skipTranslation)}
        {children}
        {!isEmpty(actions) && (
          <div className="text-start my-2">{actions.map(getCallToAction)}</div>
        )}

        {showContactUs && (
          <div className="text-start">
            <AlloyButton
              key="contact-us"
              buttonType="ghost"
              className=""
              onClick={handleContactUsClick}
            >
              {t('FOOTER_CONTACT_US')}
            </AlloyButton>
          </div>
        )}
      </ExceptionFeedback>
    )
  );
};

ExceptionFeedbackBySelector.propTypes = {
  actionMap: PropTypes.shape({
    name: PropTypes.func,
    buttonText: PropTypes.string,
    buttonType: PropTypes.string
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  errorCodeLabel: PropTypes.string,
  hideTitle: PropTypes.bool,
  messageClassName: PropTypes.string,
  preventScroll: PropTypes.bool,
  selector: PropTypes.func.isRequired,
  showContactUs: PropTypes.bool,
  isClosed: PropTypes.bool,
  setClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
  skipTranslation: PropTypes.bool,
  hideErrorCode: PropTypes.bool
};

export default ExceptionFeedbackBySelector;
