import PropTypes from 'prop-types';

export const availability = PropTypes.arrayOf(
  PropTypes.shape({
    availabilityMessage: PropTypes.string,
    quantityAvailable: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    showContactDealerPopUp: PropTypes.bool
  })
);
