import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AlloyButton, ToastContext } from 'cat-ecommerce-alloy';
import styles from './styles.module.scss';
import { HEADER_GLOBAL_LOGIN } from '../../../../constants/commonConstants';

const GuestAddEquipmentSuccess = ({ message, showLoginRegistrationModal }) => {
  const { t } = useTranslation();
  const toastCtx = useContext(ToastContext);
  const openGlobalLoginModal = () => {
    toastCtx.destroyToast();
    showLoginRegistrationModal({
      widgetId: HEADER_GLOBAL_LOGIN
    });
  };
  return (
    <div className={`${styles['addequipment__guestsuccess--wrapper']}`}>
      <p className={`${styles['addequipment__guestsuccess--msg']}`}>
        {message}
      </p>
      <AlloyButton
        buttonType="transparent"
        title={t('MEQ_GUEST_ADD_TOAST2')}
        onClick={openGlobalLoginModal}
      >
        {t('MEQ_GUEST_ADD_TOAST2')}
      </AlloyButton>
    </div>
  );
};

GuestAddEquipmentSuccess.propTypes = {
  message: PropTypes.string.isRequired,
  showLoginRegistrationModal: PropTypes.func
};

export default GuestAddEquipmentSuccess;
