import * as types from './constants';
import endpoints from '../../constants/endpoints';
import { getHttpInstance } from '../../services/defaultHttpService';
import { replaceTokensInUrl } from '../../utils';

export const getStoreConfiguration = storeKey => async (dispatch, getState) => {
  const { storeId } = getState().common;
  const http = getHttpInstance();

  const url = replaceTokensInUrl(
    endpoints.GET_STORE_CONFIGURATION,
    storeId,
    storeKey
  );

  dispatch({ type: types.GET_STORE_CONFIGURATION_BEGIN });
  try {
    const { data } = await http.get(url);
    dispatch({
      type: types.GET_STORE_CONFIGURATION_SUCCESS,
      payload: { storeKey, data } ?? {}
    });
    return data;
  } catch (error) {
    dispatch({ type: types.GET_STORE_CONFIGURATION_FAIL });
  }
};
