import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  HeaderNotification,
  useDrawer,
  Portal
} from '@cat-ecom/pcc-components';
import { AFFILIATION_DEALER, USER_ROLES } from '@app/constants/commonConstants';
import { DRAWERS_ROOT } from '@app/constants/targets';
import { returnFocusToNavItem } from '@app/utils/DOMUtils';
import Conditional from '@app/components/common/Conditional';
import NotificationDrawer from '@app/components/common/UserNotification/NotificationDrawer';

const HeaderNotificationWrapper = () => {
  const { t } = useTranslation();
  const shipmentTrackingEnabled = useSelector(
    s => s.common.shipmentTrackingEnabled
  );
  const quoteEnabled = useSelector(s => s.common.quoteEnabled);
  const customerAuthorityManager = useSelector(
    s => s.common.orderApprovalEnabled
  );
  const isCSRUser = useSelector(s => s.common.isCatCSR);
  const enableNotification =
    shipmentTrackingEnabled || quoteEnabled || customerAuthorityManager;
  const showNotification = !isCSRUser && enableNotification;
  const newNotifications =
    useSelector(s => s.notificationHeader?.isUnreadNotifications) || [];
  const hasNewNotifications = newNotifications?.length > 0;
  const isNewNotification = useSelector(
    s => s.notificationHeader?.isUnreadNotifications
  );
  const userRoles = useSelector(s => s?.common?.userRoles);
  const notificationPreferncePermission = useMemo(() => {
    if (userRoles) {
      const CALRoles = [
        USER_ROLES.ADMIN,
        USER_ROLES.APPROVER,
        USER_ROLES.SUBMITTER
      ];

      return (
        showNotification || CALRoles.some(role => userRoles.includes(role))
      );
    }
    return showNotification;
  }, [showNotification, userRoles]);

  const notificationCount = useMemo(() => {
    return isNewNotification?.length || 0;
  }, [isNewNotification]);

  const { initiatorProps, drawerProps, closeDrawer, isDrawerOpen } = useDrawer({
    onClose: () => returnFocusToNavItem('#headerNotification')
  });
  const userAffiliation = useSelector(s => s.common?.userAffiliation);
  const isDealerUser = userAffiliation === AFFILIATION_DEALER;

  return (
    <Conditional test={notificationPreferncePermission && !isDealerUser}>
      <HeaderNotification
        handleDrawerProps={initiatorProps}
        hasNotifications={hasNewNotifications}
        id="headerNotification"
        isCurrent={isDrawerOpen}
        title={t('NOTIFICATIONS')}
        ariaLabel={`${notificationCount} ${t('NEW')} ${t('NOTIFICATIONS')}. ${t(
          'VIEW_ALL'
        )} ${t('NOTIFICATIONS')}`}
      />
      <Portal id={DRAWERS_ROOT}>
        <NotificationDrawer
          drawerProps={drawerProps}
          closeDrawer={closeDrawer}
        />
      </Portal>
    </Conditional>
  );
};

export default HeaderNotificationWrapper;
