import { STATUS } from '@app/constants/commonConstants';

export const categoriesInitialStore = {
  byId: {},
  allCategories: [],
  categoriesStatus: STATUS.IDLE,
  getCategoryUrlTokenStatus: STATUS.IDLE,
  getCategoryDetailsStatus: STATUS.IDLE,
  getHomepageCategoriesRecentlyPurchasedStatus: STATUS.IDLE,
  categoriesRecentlyPurchased: [],
  lucidProductCategories: {
    data: {
      categories: [],
      buyingOptions: []
    },
    status: STATUS.IDLE
  }
};
