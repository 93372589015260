import React, { useContext } from 'react';
import { AlloyButton } from 'cat-ecommerce-alloy';
import { useTranslation } from 'react-i18next';
import useRegister from '@app/components/login/hooks/useRegister';
import { useSelector } from 'react-redux';
import { getSessionStorage } from '@app/utils';
import { CONSENT_SESSION_KEY } from '@app/constants/commonConstants';
import { ConsentAcceptanceContext } from '../ConsentAcceptanceModalContext';

const RegistrationCTA = ({ type, buttonText, openDealerLocator }) => {
  const [t] = useTranslation();
  const { register } = useRegister(true);
  const userType = useSelector(s => s.common.userType);
  const isLoggedIn = userType !== null && userType !== 'G';
  const dealerName = useSelector(state => state?.dealer?.dealerName);
  const modalProps = useContext(ConsentAcceptanceContext);
  const userConsentDisplay = () => {
    const isConsentSelected =
      getSessionStorage(CONSENT_SESSION_KEY) &&
      JSON.parse(getSessionStorage(CONSENT_SESSION_KEY));
    if (dealerName && isConsentSelected == null) {
      modalProps.open();
    } else {
      register(openDealerLocator);
    }
  };
  return !isLoggedIn ? (
    <>
      <AlloyButton
        buttonType={type}
        size="small"
        type="submit"
        onClick={() => userConsentDisplay()}
      >
        {buttonText ? buttonText : t('SL_REGISTER')}
      </AlloyButton>
    </>
  ) : null;
};

export default RegistrationCTA;
