import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMyAccountLinks } from '@app/components/pages/account/hooks';
import { useReplaceTokenWithElement } from '@app/hooks';
import { getRedirectionLink, removeFullStop } from './utils';
import { ExpiredOrderDetails } from '@cat-ecom/pcc-components';
import {
  NOTIFICATION_HEADER_STATUS,
  DYNAMIC_NOTIFICATION_TRANSLATION,
  SHOW_REFERENCE_ID
} from '@app/constants/commonConstants';
import { getFormattedDate } from '@app/store/savedListDetail/actions';
import DismissNotification from './DismissNotification';
import styles from './NotificationItem.module.scss';

const CardHeader = ({
  orderId,
  formattedTotalOrderAmount,
  title,
  orderQuoteDetailLink,
  currency,
  isRead,
  statusKey,
  orderNumber,
  quoteExpireDate,
  reviewQuoteLink,
  isDismissed,
  notificationId,
  dismissNotification = true,
  dynamicClassName = '',
  hoverCardClass = ''
}) => {
  const { dynamicLinks } = useMyAccountLinks();
  const { t } = useTranslation();
  const { replaceTokenWithElement } = useReplaceTokenWithElement();

  const orderDetailLink = getRedirectionLink(
    dynamicLinks,
    orderId,
    orderNumber,
    orderQuoteDetailLink
  );

  const reviewLink =
    reviewQuoteLink && !!orderId
      ? dynamicLinks.PENDING_QUOTE_DETAIL.getUrl({ orderId: orderId })
      : null;

  const isTodayDate = useMemo(() => {
    const today = new Date();
    return getFormattedDate(today) === getFormattedDate(quoteExpireDate);
  }, [quoteExpireDate]);

  const showAmount = useMemo(() => {
    return ['W', 'NEW', 'EXP', 'E'].includes(statusKey);
  }, [statusKey]);

  const getOrderTitle = (quote = false) => {
    const quoteNumber = orderNumber || orderId;
    const orderNo = statusKey !== 'EXPS' && (orderNumber || orderId);
    return replaceTokenWithElement(
      removeFullStop(t(DYNAMIC_NOTIFICATION_TRANSLATION[statusKey])),
      styles['card_text_weight4'],
      quote ? (
        <span
          className={`u-color--Medium Blue  ${styles['card_text_header']}`}
        >{`${title} ${quote ? quoteNumber : orderNo}`}</span>
      ) : (
        <a
          href={reviewLink || orderDetailLink}
          data-testid={'link-header'}
          className={`u-color--Medium Blue  ${styles['card_text_expired']}`}
          tabIndex={0}
        >
          {`${title} ${quote ? quoteNumber : orderNo}`}
        </a>
      ),
      <span className={`${styles['card_text_weight6']} ${hoverCardClass}`}>
        {t(NOTIFICATION_HEADER_STATUS[statusKey])}
      </span>
    );
  };

  return (
    <>
      <div
        className={`${styles['notification-header']} u-fs--14 
           `}
        data-testid="header-notification"
      >
        {quoteExpireDate && statusKey !== 'EXPS' && !isTodayDate ? (
          <ExpiredOrderDetails
            {...{
              getOrderTitle
            }}
          />
        ) : (
          <span data-testid="header-notification-title">{getOrderTitle()}</span>
        )}
        {dismissNotification && (
          <DismissNotification
            isDismissed={isDismissed}
            notificationId={notificationId}
            dynamicClassName={dynamicClassName}
          />
        )}
      </div>
      {formattedTotalOrderAmount && showAmount && (
        <span
          className={`u-fs--12 ${styles['card_text_amount']}`}
          data-testid="order-amount"
        >
          {formattedTotalOrderAmount} {currency}
        </span>
      )}
      {!!orderNumber && SHOW_REFERENCE_ID.includes(statusKey) ? (
        <>
          <div className={`u-fs--12 ${styles['card_text_amount']}`}>
            <div
              className={`d-inline-block me-1`}
              data-testid="header-notification-reference-title"
            >
              {t('REFERENCE_ID')}
            </div>
            <span className="fw-normal">
              {statusKey === 'OP' ? orderId : orderNumber}
            </span>
          </div>
        </>
      ) : null}
    </>
  );
};

export default CardHeader;

CardHeader.propTypes = {
  orderId: PropTypes.number,
  formattedTotalOrderAmount: PropTypes.string,
  title: PropTypes.string,
  orderQuoteDetailLink: PropTypes.string,
  currency: PropTypes.string,
  isRead: PropTypes.bool,
  orderNumber: PropTypes.number,
  statusKey: PropTypes.string,
  quoteExpireDate: PropTypes.string,
  reviewQuoteLink: PropTypes.string,
  isDismissed: PropTypes.bool,
  notificationId: PropTypes.number,
  dismissNotification: PropTypes.bool,
  dynamicClassName: PropTypes.string,
  hoverCardClass: PropTypes.string
};
