import { memo, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { QuantityInput } from 'cat-ecommerce-alloy';
import styles from './cellStyles.module.scss';
import {
  setQuantity,
  updateLineItems,
  setQuantityValidationError,
  setItemError,
  clearItemError
} from '@app/store/orders/actions';
import { clearError } from '@app/store/exception/actions';
import { ERROR_DOMAIN, ERROR_PATH } from '@app/constants/errorConstants';
import {
  MIN_QUANTITY,
  MAX_QUANTITY,
  SERVER_ERRORS,
  MAX_QUANTITY_LENGTH
} from '../../constants';
import { ORDER_ITEMS_ERROR_DOMAIN_QUANTITY } from './errorConstants';
import { LIST_PCC_CART_PAGE } from '@app/constants/analyticsConstants';
import useAnalytics from '@app/hooks/useAnalytics/useAnalytics';
import debounce from 'lodash/debounce';
import { getAddAndRemoveCartEvents, getPackageErrorCondition } from '../utils';
import { isEmpty, replaceTokensInString } from '@app/utils';
import { ORDERS_REMOVE_PACKAGE_ERROR_PACKAGE } from '@app/store/orders/constants';

const QuantitySelector = memo(({ item }) => {
  const {
    quantity,
    isUpdateLineItemLoading,
    orderItemId,
    errors = {},
    packageError,
    packageQuantity,
    hasBundleError
  } = item;

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { hasOrderWithPrice } = useSelector(s => s.orders);
  const storeName = useSelector(s => s.store?.name);
  const { fireAddToCartEvent, fireRemoveFromCartEvent, fireProductClickEvent } =
    useAnalytics();

  const setQuantityError = useCallback(
    newError => {
      if (!isEmpty(packageError)) {
        if (getPackageErrorCondition(quantity, packageQuantity)) {
          dispatch(
            setItemError(item, {
              colId: ORDER_ITEMS_ERROR_DOMAIN_QUANTITY,
              message: t('SKULIST_INVALID_QUANTITY')
            })
          );
        } else {
          dispatch(clearItemError(item, ORDER_ITEMS_ERROR_DOMAIN_QUANTITY));
        }
        dispatch({
          type: ORDERS_REMOVE_PACKAGE_ERROR_PACKAGE
        });
      } else {
        if (getPackageErrorCondition(quantity, packageQuantity)) {
          if (isEmpty(quantity) || parseInt(quantity) === 0) {
            dispatch(
              setItemError(item, {
                colId: ORDER_ITEMS_ERROR_DOMAIN_QUANTITY,
                message: t('SKULIST_INVALID_QUANTITY')
              })
            );
          } else {
            dispatch(
              setItemError(item, {
                colId: ORDER_ITEMS_ERROR_DOMAIN_QUANTITY,
                message: replaceTokensInString(
                  t('SC_PKG_QTY_PART_ERROR4'),
                  item.packageQuantity
                )
              })
            );
          }
          dispatch(clearError(ERROR_DOMAIN.CART, ERROR_PATH.SHOPPING_CART));
        } else {
          if (isEmpty(quantity) || newError) {
            dispatch(setQuantityValidationError(item, newError));
          } else if (hasBundleError) {
            dispatch(
              setItemError(item, {
                colId: ORDER_ITEMS_ERROR_DOMAIN_QUANTITY,
                message: t('MATCH_BUNDLE_QUANTITY')
              })
            );
          } else if (
            !newError &&
            item.errors?.[ORDER_ITEMS_ERROR_DOMAIN_QUANTITY]
          ) {
            dispatch(clearItemError(item, ORDER_ITEMS_ERROR_DOMAIN_QUANTITY));
          }
        }
      }
    },
    [dispatch, hasBundleError, item, packageError, packageQuantity, quantity, t]
  );

  const trackGA = useCallback(
    updatedItems => {
      const { addToCartItems, removeFromCartItems } = getAddAndRemoveCartEvents(
        updatedItems,
        storeName
      );
      if (addToCartItems.length) {
        fireProductClickEvent({
          list: LIST_PCC_CART_PAGE,
          products: addToCartItems
        });
        fireAddToCartEvent({ products: addToCartItems });
      }

      if (removeFromCartItems.length) {
        fireProductClickEvent({
          list: LIST_PCC_CART_PAGE,
          products: removeFromCartItems
        });
        fireRemoveFromCartEvent({ products: removeFromCartItems });
      }
    },
    [
      storeName,
      fireProductClickEvent,
      fireAddToCartEvent,
      fireRemoveFromCartEvent
    ]
  );

  useEffect(() => {
    hasOrderWithPrice && setQuantityError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasOrderWithPrice, quantity, packageError, hasBundleError]);

  const debouncedUpdateQuantity = useMemo(
    () =>
      debounce(() => {
        dispatch(updateLineItems([orderItemId], t, trackGA));
      }, 1500),
    [dispatch, orderItemId, t, trackGA]
  );

  const setItemQuantity = useCallback(
    value => {
      const qty = parseInt(value);
      const ssroBundleCount = item.orderItemExtendAttribute.ssroBundle.length;
      if (value.length <= MAX_QUANTITY_LENGTH) {
        dispatch(setQuantity(item, value, t));
        if (
          isEmpty(item.packageError) &&
          !isEmpty(value) &&
          qty !== 0 &&
          !getPackageErrorCondition(value, item.packageQuantity) &&
          qty >= ssroBundleCount
        ) {
          debouncedUpdateQuantity();
        }
      }
    },
    [debouncedUpdateQuantity, dispatch, item, t]
  );

  return (
    <>
      <div
        className={`d-flex justify-md-content-center ${
          isUpdateLineItemLoading
            ? styles['quantity__container-disabled']
            : styles['quantity__container']
        }`}
        data-testid="quantity-input-component"
      >
        <QuantityInput
          value={quantity}
          setValue={setItemQuantity}
          disabled={isUpdateLineItemLoading}
          error={errors?.[ORDER_ITEMS_ERROR_DOMAIN_QUANTITY]}
          setError={setQuantityError}
          className="d-print-none"
          minValue={MIN_QUANTITY}
          maxValue={MAX_QUANTITY}
        />
        <span
          className={`d-none d-print-block w-50 text-center py-2 ${styles['item-quantity']}`}
        >
          {quantity}
        </span>
      </div>
      {item.errors?.server === SERVER_ERRORS.DELETE && (
        <div className={`px-1 ${styles['delete-error-message']}`}>
          {t('DELETE_ITEM_FAILED')}
        </div>
      )}
    </>
  );
});

QuantitySelector.displayName = 'QuantitySelector';

QuantitySelector.propTypes = {
  item: PropTypes.shape({
    packageQuantity: PropTypes.number,
    quantity: PropTypes.string,
    serverQuantity: PropTypes.string,
    isUpdateLineItemLoading: PropTypes.bool,
    orderItemId: PropTypes.string,
    errors: PropTypes.shape({
      quantity: PropTypes.string,
      server: PropTypes.string
    }),
    packageError: PropTypes.shape({
      packageQuantity: PropTypes.number
    }),
    orderItemExtendAttribute: PropTypes.shape({
      ssroBundle: PropTypes.arrayOf(
        PropTypes.shape({
          selfServiceOptionId: PropTypes.string,
          selfServiceOptionName: PropTypes.string
        })
      )
    }),
    name: PropTypes.string,
    partNumber: PropTypes.string,
    hasBundleError: PropTypes.bool
  })
};

export default QuantitySelector;
