import { STATUS } from '@app/constants/commonConstants';
import * as types from './constants';
import { storeConfigurationInitialStore } from './initialStore';

const storeConfigurationReducer = (
  state = storeConfigurationInitialStore,
  action
) => {
  switch (action.type) {
    case types.GET_STORE_CONFIGURATION_BEGIN: {
      return {
        ...state,
        storeConfigurationStatus: STATUS.PENDING
      };
    }
    case types.GET_STORE_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        [action.payload.storeKey]: { ...action.payload.data },
        storeConfigurationStatus: STATUS.RESOLVED
      };
    }
    case types.GET_STORE_CONFIGURATION_FAIL: {
      return {
        ...state,
        storeConfigurationStatus: STATUS.REJECTED
      };
    }
    default: {
      return state;
    }
  }
};

export { storeConfigurationInitialStore };
export default storeConfigurationReducer;
