import { STATUS } from '@app/constants/commonConstants';
import * as types from './constants';
import { commonInitialStore } from './initialStore';

const commonReducer = (state = {}, action) => {
  switch (action.type) {
    case types.COMMON_GET_VARS_BEGIN: {
      return {
        ...state,
        getCommonVarsStatus: STATUS.PENDING
      };
    }
    case types.COMMON_GET_VARS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        getCommonVarsStatus: STATUS.RESOLVED
      };
    }
    case types.COMMON_GET_VARS_FAIL: {
      return {
        ...state,
        getCommonVarsStatus: STATUS.REJECTED
      };
    }
    case types.COMMON_SET_USER_ID: {
      return {
        ...state,
        ...action.payload
      };
    }
    case types.COMMON_SET_TRANSLATIONS_LOADED: {
      return {
        ...state,
        areTranslationsLoaded: true
      };
    }
    case types.COMMON_CANCEL_CART_IMPORT: {
      return {
        ...state,
        showCartImportModal: false
      };
    }
    case types.COMMON_CREATE_GUEST_IDENTITY_SUCCESS: {
      const { userId } = action.payload;
      return {
        ...state,
        userId
      };
    }
    case types.SAVE_USER_INFO_SUCCESS: {
      const {
        firstName,
        lastName,
        country,
        zipCode,
        address1,
        address2,
        city,
        state: countryState, //rename this otherwise it overwrites redux 'state' variable
        phone1,
        companyName,
        industry,
        taxIdOption,
        Company,
        Individual,
        stateTaxId,
        neighborhood
      } = action.payload;
      return {
        ...state,
        firstName,
        lastName,
        companyName,
        profileAddress: {
          ...state.profileAddress,
          country,
          state: countryState,
          city,
          address1,
          address2,
          zipCode,
          phone1
        },
        dealerFields: {
          ...state.dealerFields,
          taxIdOption,
          industry,
          Company,
          Individual,
          stateTaxId,
          neighborhood
        }
      };
    }
    case types.SAVE_TAX_INFO_SUCCESS: {
      const { taxIdOption, Company, Individual, stateTaxId, neighborhood } =
        action.payload;
      return {
        ...state,
        dealerFields: {
          ...state.dealerFields,
          taxIdOption,
          Company,
          Individual,
          stateTaxId,
          neighborhood
        }
      };
    }
    case types.COMMON_OPT_IN_EMAIL_MARKETING_BEGIN:
      return {
        ...state,
        optInEmailMarketingStatus: STATUS.PENDING
      };
    case types.COMMON_OPT_IN_EMAIL_MARKETING_SUCCESS:
      return {
        ...state,
        optInEmailMarketingStatus: STATUS.RESOLVED,
        receiveEmail: true
      };
    case types.COMMON_OPT_IN_EMAIL_MARKETING_FAIL:
      return {
        ...state,
        optInEmailMarketingStatus: STATUS.REJECTED
      };
    case types.COMMON_OPT_IN_EMAIL_MARKETING_BOT:
      return {
        ...state,
        optInEmailMarketingStatus: STATUS.RESOLVED,
        receiveEmail: false
      };
    case types.GET_FEATURE_INFORMATION_BEGIN:
      return {
        ...state,
        featureInfoStatus: STATUS.PENDING
      };
    case types.GET_FEATURE_INFORMATION_SUCCESS:
      return {
        ...state,
        featureDetails: {
          data: action.payload,
          isLoading: false
        },
        featureInfoStatus: STATUS.RESOLVED
      };
    case types.GET_FEATURE_INFORMATION_FAIL:
      return {
        ...state,
        featureDetails: {
          data: null,
          isLoading: false
        },
        featureInfoStatus: STATUS.REJECTED
      };
    case types.COMMON_SET_TOAST_INFO:
      return {
        ...state,
        displayToastInfo: action.payload
      };
    case types.COMMON_SET_TOAST_TYPE:
      return {
        ...state,
        displayToastType: action.payload
      };
    case types.ADP_MODAL_STATS_TOGGLE:
      return {
        ...state,
        adpModalOpenStatus: action.payload
      };
    default: {
      return state;
    }
  }
};

export { commonInitialStore };
export default commonReducer;
