import { FooterCommonLink } from '@cat-ecom/pcc-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { replaceTokensInString } from '../../../utils';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ModalContext } from 'cat-ecommerce-alloy';
import endpoints from '../../../constants/endpoints';
import useHelpCenterLink from '@app/components/pages/csr-landing/hooks/useHelpCenterLink';
import { USER_TYPE_GUEST } from '@app/constants/commonConstants';
import {
  PICKUP_AND_DELIVERY_GUEST_ROUTE_LEGACY,
  PICKUP_AND_DELIVERY_ROUTE_LEGACY,
  SHOPPING_CART_ROUTE,
  SUMMARY_AND_PAYMENT_ROUTE_LEGACY,
  PICKUP_AND_DELIVERY_PAGE
} from '@app/constants/routes';
import { headlessLinks } from '@app/constants/links';

const SUPPORT_FOOTER_LINKS = {
  contactUs: '/CATContactUsDisplayView'
};

const FooterSupportLinks = ({ openADSDrawer, openDealerLocatorDrawer }) => {
  const { t } = useTranslation();
  const helpCenterEnabled = useSelector(
    s => s.featureFlag?.PCCCustomerSupportHelpCenter
  );
  const headlessOrderStatusInquiryEnabled = useSelector(
    s => s.featureFlag?.PCC_HeadlessOrderStatusInquiry
  );
  const esiteURL = useSelector(s => s.common?.esiteURL);
  const isCatCorp = useSelector(s => s.common?.isCatCorp);
  const storeId = useSelector(s => s.common?.storeId);
  const langId = useSelector(s => s.common?.langId);
  const seoURL = useSelector(s => s.dealer?.seoURL);

  const { getHelpCenterURL, getHelpCenterContactSupportURL } =
    useHelpCenterLink();
  const linkCatHelpCenterV1 = replaceTokensInString(
    endpoints.FOOTER_LINK_HELP_CENTER,
    seoURL
  );
  const showHelpCenter = isCatCorp || helpCenterEnabled;

  const modalCtx = useContext(ModalContext);

  const warrantyHeadlessFlag = useSelector(
    s => s.featureFlag?.PCCHeadlessWarrantyAndReturnsFeature
  );

  const userType = useSelector(s => s?.common?.userType);
  const isLoggedIn = (userType || '').toUpperCase() !== USER_TYPE_GUEST;
  const { pathname = '' } = useLocation();
  const onCheckoutPage =
    pathname.includes(PICKUP_AND_DELIVERY_PAGE) ||
    pathname.includes(SUMMARY_AND_PAYMENT_ROUTE_LEGACY) ||
    pathname.includes(PICKUP_AND_DELIVERY_GUEST_ROUTE_LEGACY) ||
    pathname.includes(PICKUP_AND_DELIVERY_ROUTE_LEGACY);

  const onShoppingCartPage = pathname.includes(SHOPPING_CART_ROUTE);

  const contactUsLink = showHelpCenter
    ? getHelpCenterContactSupportURL()
    : `${esiteURL}${SUPPORT_FOOTER_LINKS.contactUs}?storeId=${storeId}&langId=${langId}`;

  const findDealerFunction = () => {
    if (isLoggedIn) {
      modalCtx.closeAllModals();
      openADSDrawer();
    } else {
      openDealerLocatorDrawer();
    }
  };

  const orderStatusInquiryUrl = headlessOrderStatusInquiryEnabled
    ? replaceTokensInString(
        headlessLinks.HEADLESS_ORDER_STATUS_INQUIRY_LINK,
        esiteURL
      )
    : replaceTokensInString(endpoints.ORDER_STATUS_INQUIRY, seoURL);

  const warrantyAndReturnsUrl = warrantyHeadlessFlag
    ? replaceTokensInString(
        headlessLinks.HEADLESS_WARRANTY_AND_RETURNS,
        esiteURL
      )
    : replaceTokensInString(endpoints.FOOTER_LINK_WARRANTY_AND_RETURN, seoURL);

  return (
    <>
      <li>
        <FooterCommonLink
          label={t('FOOTER_CONTACT_US')}
          href={contactUsLink}
          target={!!showHelpCenter ? '_blank' : '_self'}
        />
      </li>
      {!onCheckoutPage && !onShoppingCartPage && (
        <li>
          <FooterCommonLink
            label={t('CAT_HEADER_FIND_YOUR_LOCATOR')}
            name="footer__find-your-dealer"
            onClick={findDealerFunction}
          />
        </li>
      )}
      <li>
        <FooterCommonLink
          label={t('HELP_CENTER_FOOTER')}
          href={showHelpCenter ? getHelpCenterURL() : linkCatHelpCenterV1}
          target={showHelpCenter ? '_blank' : '_self'}
        />
      </li>
      <li>
        <FooterCommonLink
          label={t('FOOTER_WARRANTY_RETURNS')}
          href={warrantyAndReturnsUrl}
          target={'_self'}
        />
      </li>
      <li>
        <FooterCommonLink
          label={t('ORDER_STATUS_INQUIRY')}
          href={orderStatusInquiryUrl}
        />
      </li>
    </>
  );
};

FooterSupportLinks.propTypes = {
  openADSDrawer: PropTypes.func,
  openDealerLocatorDrawer: PropTypes.func
};

export default FooterSupportLinks;
