import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import useLaunchModal from './useLaunchModal';

const PCCHelpCenterModals = () => {
  const history = useHistory();
  const searchParams = useLocation().search;
  const getWaffleInfoSuccess =
    useSelector(s => s.header.getWaffleInfoSuccess) || false;

  const queryParams = new URLSearchParams(searchParams);
  const openModalParam = queryParams.get('openModal') || '';
  const { showModal } = useLaunchModal(openModalParam);

  const areDependenciesLoaded = useMemo(() => {
    return openModalParam !== '' && getWaffleInfoSuccess;
  }, [openModalParam, getWaffleInfoSuccess]);

  useEffect(() => {
    if (areDependenciesLoaded) {
      queryParams.delete('openModal');
      history.replace({
        search: queryParams.toString()
      });

      showModal()();
    }
  }, [history, queryParams, areDependenciesLoaded, showModal]);

  return null;
};

export default React.memo(PCCHelpCenterModals);
