import cx from 'classnames';
import PropTypes from 'prop-types';

const DealerUserAddressLine = ({ address }) => {
  const commonParagraphClass = cx(
    'exclude-global',
    'u-color--DarkGray',
    'text-univers',
    'text-start',
    'my-0'
  );
  return address ? (
    <p data-testid="address-line" className={commonParagraphClass} data-cs-mask>
      {address}
    </p>
  ) : null;
};

export default DealerUserAddressLine;

DealerUserAddressLine.propTypes = {
  address: PropTypes.string
};
