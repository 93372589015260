import PropTypes from 'prop-types';

const MarkerLabel = ({ dealerName, name, address, city, state, phone }) => {
  const paragraph = 'text-sans-serif color-black mb-0';
  return (
    <div>
      <p className={paragraph}>{dealerName}</p>
      <div>
        <p className={paragraph}>{name}</p>
        <p className={paragraph}>{address}</p>
        <p className={paragraph}>
          {city}, {state}
        </p>
        <p className={paragraph}>{phone}</p>
      </div>
    </div>
  );
};

MarkerLabel.propTypes = {
  dealerName: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  phone: PropTypes.string
};
export default MarkerLabel;
