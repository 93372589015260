import { ERROR_DOMAIN, ERROR_PATH } from '@app/constants/errorConstants';

export const PLP_GET_CURRENT_CATEGORY = 'PLP_GET_CURRENT_CATEGORY';
export const PLP_GET_DETAILS_BEGIN = 'PLP_DETAILS_BEGIN';
export const PLP_GET_DETAILS_SUCCESS = 'PLP_DETAILS_SUCCESS';
export const PLP_GET_DETAILS_FAIL = 'PLP_DETAILS_FAIL';
export const PLP_GET_MORE_DETAILS_BEGIN = 'PLP_MORE_DETAILS_BEGIN';
export const PLP_GET_MORE_DETAILS_SUCCESS = 'PLP_MORE_DETAILS_SUCCESS';
export const PLP_GET_MORE_DETAILS_FAIL = 'PLP_MORE_DETAILS_FAIL';
export const PLP_GET_PREVIOUS_DETAILS_BEGIN = 'PLP_PREVIOUS_DETAILS_BEGIN';
export const PLP_GET_PREVIOUS_DETAILS_SUCCESS = 'PLP_PREVIOUS_DETAILS_SUCCESS';
export const PLP_GET_PREVIOUS_DETAILS_FAIL = 'PLP_PREVIOUS_DETAILS_FAIL';
export const PLP_SET_QUERY_PARAM = 'PLP_SET_QUERY_PARAM';
export const PLP_SET_EQUIPMENT_CHECKED = 'PLP_SET_EQUIPMENT_CHECKED';
export const PLP_SET_PARTS_WITH_FITMENT_DATA =
  'PLP_SET_PARTS_WITH_FITMENT_DATA';
export const PLP_SET_FACETS_LOADING = 'PLP_SET_FACETS_LOADING';
export const PLP_SET_PRODUCT_CARD_EXPANDED = 'PLP_SET_PRODUCT_CARD_EXPANDED';
export const PLP_SET_BUCKET_MODEL_ACTIVE_FACET =
  'PLP_SET_BUCKET_MODEL_ACTIVE_FACET';
export const PRICE_AVAILABILITY_ATTRIBUTE_ID = 'partNumber';
export const ERROR_INFO = {
  domain: ERROR_DOMAIN.PLP,
  path: ERROR_PATH.MAIN
};
