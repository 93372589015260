import LoginAndRegisterButtons from '@app/components/common/LoginAndRegisterButtons';
import { Conditional, HeaderCartEmptyMessage } from '@cat-ecom/pcc-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const EmptyCart = ({ closeDrawer, openDealerLocator }) => {
  const { t } = useTranslation();
  const userType = useSelector(s => s.common?.userType);
  const isLoggedIn = userType && userType !== 'G';

  return (
    <>
      <HeaderCartEmptyMessage message={t('MSC_EMPTY_SHOP_CART')} />
      <Conditional test={!isLoggedIn}>
        <LoginAndRegisterButtons
          closeDrawer={closeDrawer}
          hideDisclaimer
          openDealerLocator={openDealerLocator}
        />
      </Conditional>
    </>
  );
};

export default EmptyCart;

EmptyCart.propTypes = {
  closeDrawer: PropTypes.func,
  openDealerLocator: PropTypes.func
};
