import {
  getCookie,
  isHomepage,
  setCookie,
  isMLP,
  isPDP,
  isSearchPage,
  setSessionStorage,
  getPageGroupContent,
  getPageIdentifierContent,
  getSessionStorage,
  isDev,
  isCategoryPage
} from '@app/utils';
import {
  LOGGED_USER_DECLINE_EMAIL_MARKETING_OFFER_COOKIE,
  GUEST_USER_DECLINE_EMAIL_MARKETING_OFFER_COOKIE,
  GUEST_USER_SUPPRESS_EMAIL_MARKETING_MODAL_COOKIE,
  LOGGED_USER_SUPPRESS_EMAIL_MARKETING_MODAL_COOKIE,
  GUEST_USER_SUPPRESS_EMAIL_MARKETING_MODAL,
  LOGGED_USER_SUPPRESS_EMAIL_MARKETING_MODAL,
  LOGGED_USER_EMAIL_MARKETING_DECLINE_COUNT_COOKIE,
  GUEST_USER_EMAIL_MARKETING_DECLINE_COUNT_COOKIE,
  DECLINE_EMAIL_MARKETING_COOKIE_EXPIRATION_DAYS_1,
  DECLINE_EMAIL_MARKETING_COOKIE_EXPIRATION_DAYS_14,
  DECLINE_EMAIL_MARKETING_COOKIE_EXPIRATION_DAYS_60,
  IS_LOGGED_USER_SHOWN_EMAIL_MARKETING_COOKIE,
  IS_GUEST_USER_SHOWN_EMAIL_MARKETING_COOKIE
} from './constants';
import { getExpirationDate } from '@app/utils/cookieUtils';
import { CAT_COOKIE_DOMAIN } from '@app/constants/commonConstants';

const getSuppressOptInSessionName = isLoggedIn => {
  return isLoggedIn
    ? LOGGED_USER_SUPPRESS_EMAIL_MARKETING_MODAL
    : GUEST_USER_SUPPRESS_EMAIL_MARKETING_MODAL;
};
/**
 * Checks if the email marketing opt-in modal should display,
 * based on a the cookie to decline the offer, the fact that the user is logged in or not
 * and on the current page.
 * @param {boolean} isLoggedIn If user is logged in
 * @returns {boolean}
 */
export const shouldShowModal = isLoggedIn => {
  if (getSessionStorage(getSuppressOptInSessionName(isLoggedIn)) === 'true') {
    return false;
  }
  const hasSuppressingModalCookie =
    getCookie(
      isLoggedIn
        ? LOGGED_USER_SUPPRESS_EMAIL_MARKETING_MODAL_COOKIE
        : GUEST_USER_SUPPRESS_EMAIL_MARKETING_MODAL_COOKIE
    ) ||
    getCookie(
      isLoggedIn
        ? LOGGED_USER_DECLINE_EMAIL_MARKETING_OFFER_COOKIE
        : GUEST_USER_DECLINE_EMAIL_MARKETING_OFFER_COOKIE
    );
  const isValidPage =
    isHomepage() || isPDP() || isCategoryPage() || isSearchPage();

  return isValidPage && !hasSuppressingModalCookie;
};

const getDeclineCountCookieName = isLoggedIn => {
  return isLoggedIn
    ? LOGGED_USER_EMAIL_MARKETING_DECLINE_COUNT_COOKIE
    : GUEST_USER_EMAIL_MARKETING_DECLINE_COUNT_COOKIE;
};

export const getUniquePageDetails = () => {
  return `${getPageGroupContent()}_${getPageIdentifierContent()}`;
};

const getCookieDomain = () => {
  return !isDev() ? CAT_COOKIE_DOMAIN : null;
};

const setEmailMarketingDeclineCountCookie = (isLoggedIn, cookieValue) => {
  const expirationDate = getExpirationDate(365);
  setCookie(
    getDeclineCountCookieName(isLoggedIn),
    cookieValue,
    expirationDate,
    '/',
    getCookieDomain()
  );
};

const getDeclineCookieExpiryDate = isLoggedIn => {
  const currentDeclineCountCookieValue =
    parseInt(getCookie(getDeclineCountCookieName(isLoggedIn))) || 0;
  const newDeclineCountCookieValue = currentDeclineCountCookieValue + 1;
  setEmailMarketingDeclineCountCookie(isLoggedIn, newDeclineCountCookieValue);
  let expiresDays = DECLINE_EMAIL_MARKETING_COOKIE_EXPIRATION_DAYS_1;
  if (newDeclineCountCookieValue === 2) {
    expiresDays = DECLINE_EMAIL_MARKETING_COOKIE_EXPIRATION_DAYS_14;
  } else if (newDeclineCountCookieValue > 2) {
    expiresDays = DECLINE_EMAIL_MARKETING_COOKIE_EXPIRATION_DAYS_60;
  }
  return getExpirationDate(expiresDays);
};

/**
 * Sets the cookie to decline the marketing email offer.
 * @param {boolean} isLoggedIn If user is logged in
 */
export const setCookieToDeclineEmailMarketingOffer = isLoggedIn => {
  const expiresDate = getDeclineCookieExpiryDate(isLoggedIn);
  setCookie(
    isLoggedIn
      ? LOGGED_USER_DECLINE_EMAIL_MARKETING_OFFER_COOKIE
      : GUEST_USER_DECLINE_EMAIL_MARKETING_OFFER_COOKIE,
    true,
    expiresDate,
    '/',
    getCookieDomain()
  );
};

export const setUniquePageSessionFromCatCorp = pageDetail => {
  if (!getSessionStorage('optInModalHistory')) {
    setSessionStorage('optInModalHistory', pageDetail);
  }
};

export const isUniquePage = pageDetail => {
  const optInHistory = getSessionStorage('optInModalHistory');
  return optInHistory && optInHistory === pageDetail ? false : true;
};

/**
 * Sets the cookie to suppress the marketing email modal.
 * @param {boolean} isLoggedIn If user is logged in
 */
export const setCookieToSuppressEmailMarketingModal = isLoggedIn => {
  setCookie(
    isLoggedIn
      ? LOGGED_USER_SUPPRESS_EMAIL_MARKETING_MODAL_COOKIE
      : GUEST_USER_SUPPRESS_EMAIL_MARKETING_MODAL_COOKIE,
    true
  );
};

export const setEmailMarketingShownCookie = isLoggedIn => {
  setCookie(
    isLoggedIn
      ? IS_LOGGED_USER_SHOWN_EMAIL_MARKETING_COOKIE
      : IS_GUEST_USER_SHOWN_EMAIL_MARKETING_COOKIE,
    true
  );
};

export const getEmailMarketingShownCookie = isLoggedIn => {
  return getCookie(
    isLoggedIn
      ? IS_LOGGED_USER_SHOWN_EMAIL_MARKETING_COOKIE
      : IS_GUEST_USER_SHOWN_EMAIL_MARKETING_COOKIE
  );
};

export const isCountrySuppressed = (contryCode, suppressedCountryList) => {
  const countryList = suppressedCountryList?.split('|');
  return countryList?.includes(contryCode);
};
