import { isHomepage } from '@app/utils';
import { replaceUrl } from '@app/services/windowService';

export const redirectMlp = url => {
  if (isHomepage()) {
    setTimeout(() => {
      replaceUrl(url);
    }, 1500);
  }
};
