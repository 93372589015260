import { useSelector } from 'react-redux';
import { getPartName } from '@app/utils/commonUtils';
import { FITMENT_CHECK } from '@app/constants/commonConstants';
import { useCallback, useMemo } from 'react';
import { getProductURL } from '@app/components/pages/pdp/ProductDisplayPage/utils';

const useGridListData = () => {
  const uniqueIndex = useSelector(
    s => s.myEquipment?.equipments.selectedEquipment?.uniqueIndex
  );
  const status = useSelector(s => s.searchUI.searchUIDetailsStatus);
  const equipmentFitmentStatus = useSelector(
    s => s.searchUI.equipmentFitmentStatus
  );
  const filterByEquipment = useSelector(
    s => s.searchUI.queryParams.filterByEquipment
  );
  const priceAndAvailibiltyStatus = useSelector(
    state => state.searchUI.priceAndAvailibiltyStatus
  );
  const lowestLoadedPage = useSelector(s => s.searchUI.lowestLoadedPage);
  const catalogEntries = useSelector(s => s.searchUI.catalogEntries);
  const products = useSelector(s => s.searchUI.products || []);
  const serialNumber = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment?.serialNumber
  );
  const currentCategory = useSelector(s => s.searchUI.category);
  const productCardExpandedArr = useSelector(
    s => s.searchUI.productCardExpandedArr
  );
  const imageUrl = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment?.imageURL
  );
  const model = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment?.model
  );
  const equipmentFamily = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment?.equipmentFamily
  );
  const serialMatchPartNumbers = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment['serialMatchPartNumbers']
  );
  const facets = useSelector(s => s.searchUI.facets);
  const unitOfMeasure = useSelector(s => s.searchUI.unitOfMeasure);

  const getFitmentCheckResult = useCallback(
    partNumber => {
      const partNumberNoDash = partNumber?.replace(/-/g, '');
      if (
        serialMatchPartNumbers?.has(partNumber) ||
        serialMatchPartNumbers?.has(partNumberNoDash)
      ) {
        return FITMENT_CHECK.FULL_CHECK;
      }

      return FITMENT_CHECK.NO_CHECK;
    },
    [serialMatchPartNumbers]
  );

  const getProductsData = useCallback(() => {
    const updatedProducts = products.map(
      ({
        name,
        partNumber,
        imageURL,
        partsImages,
        identifier,
        productURL,
        ...attrs
      }) => {
        const parsedName = getPartName(partNumber, name);

        return {
          ...attrs,
          name: parsedName,
          partNumber,
          identifier,
          partsImages,
          productDisplayUrl: getProductURL(productURL)
        };
      }
    );
    return {
      uniqueIndex,
      searchUIDetailsStatus: status,
      detailsStatus: status,
      lowestLoadedPage,
      catalogEntries,
      products: updatedProducts,
      getDetailsStatus: status,
      priceAndAvailibiltyStatus,
      partsWithFitmentData: null,
      equipmentFitmentStatus,
      priceData: null,
      serialNumber,
      filterByEquipment,
      productCardExpandedArr,
      currentCategory,
      getPreviousDetailsStatus: status,
      getMoreDetailsStatus: status,
      imageUrl,
      model,
      equipmentFamily,
      facets,
      unitOfMeasure
    };
  }, [
    uniqueIndex,
    status,
    equipmentFitmentStatus,
    filterByEquipment,
    priceAndAvailibiltyStatus,
    lowestLoadedPage,
    catalogEntries,
    products,
    serialNumber,
    currentCategory,
    model,
    productCardExpandedArr,
    equipmentFamily,
    imageUrl,
    facets,
    unitOfMeasure
  ]);

  return useMemo(
    () => ({
      getFitmentCheckResult,
      getProductsData
    }),
    [getFitmentCheckResult, getProductsData]
  );
};
export default useGridListData;
