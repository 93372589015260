import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AlloyButton } from 'cat-ecommerce-alloy';
import { replaceTokensInUrl } from '@app/utils';
import links from '@app/constants/links';

const ViewCart = () => {
  const [t] = useTranslation();
  const storeId = useSelector(s => s.common.storeId);
  const catalogId = useSelector(s => s.common.catalogId);
  const langId = useSelector(s => s.common.langId);
  const viewCartBtnText = t('VIEW_CART');
  const { SHOPPING_CART_LINK } = links;
  const shoppingCartLink = replaceTokensInUrl(
    SHOPPING_CART_LINK,
    storeId,
    langId,
    catalogId
  );
  return (
    <div className="d-flex">
      <AlloyButton
        buttonType="ghost"
        size="dynamic"
        className="py-2 px-0"
        onClick={() => (window.location.href = shoppingCartLink)}
      >
        {viewCartBtnText}
      </AlloyButton>
    </div>
  );
};

export default ViewCart;
