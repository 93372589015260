import capitalize from 'lodash/capitalize';
import { replaceTokensInUrl } from '../../../utils';
import endpoints from '../../../constants/endpoints';
import { compareStoreLocations } from '../utils';

export const getStoreHours = storeHours =>
  storeHours.reduce((acc, d = {}, i) => {
    acc[d.index] = {
      ...d,
      label: capitalize(d.label),
      currentDay: i === 0
    };
    return acc;
  }, []);

export const getDrivingDirections = (lat, long) =>
  lat && long
    ? replaceTokensInUrl(endpoints.GOOGLE_MAPS_DRIVING_DIRECTIONS, lat, long)
    : undefined;

export const getDistance = d => {
  const parsed = parseFloat(d);
  if (isNaN(parsed)) {
    return undefined;
  }
  return parsed.toFixed(1);
};

// If the user is CSP then don't show associated stores for the current user’s dealer/selected eSite
export const filterAssociatedStores = (
  isCSPCustomer,
  associatedStores = [],
  dealerStoreId
) => !(isCSPCustomer && associatedStores.includes(dealerStoreId));

export const processDealerStores = (
  unprocessed = {},
  associatedStores = [],
  isCSPCustomer = false
) => {
  let dealerStores = [...new Set(unprocessed.dealerStores)];
  dealerStores = dealerStores
    .filter(store =>
      filterAssociatedStores(
        isCSPCustomer,
        associatedStores,
        store.dealerStoreId
      )
    )
    .map((item = {}, index) => ({
      ...item,
      distance: getDistance(item.distance),
      drivingDirections: getDrivingDirections(item.latitude, item.longitude),
      isClosest: index === 0,
      storeHours: getStoreHours(item.storeHours)
    }))
    .sort(compareStoreLocations);
  return {
    dealerStores,
    uom: unprocessed.distanceUnitOfMeasure,
    showInCountryToggle: unprocessed?.showInCountryToggle
  };
};

// Example Format: 1 Capitol Drive, East Peoria, Illinois, 61611-4820, USA
export const getAddress = addressArray =>
  addressArray.filter(v => v).join(', '); // remove empty values;
