import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InlineLoader } from 'cat-ecommerce-alloy';
import Availability from '@app/components/common/Availability';
import styles from './cellStyles.module.scss';
import { SERVER_ERRORS } from '../../constants';
import { sourcingDetailsPropTypes } from '@app/components/common/Availability/AvailabilityBySourceContent/AvailabilityBySourceContent.proptypes';

const AvailabilityCell = ({ item }) => {
  const {
    isUpdateLineItemLoading,
    availability,
    sourcingDetails,
    errors,
    displayAvailabityAndSourcingDetails,
    orderItemExtendAttribute,
    storeAvailability
  } = item;
  const [t] = useTranslation();
  const { hoseAssemblyIndicator, hoseAvailabilityLabel } =
    orderItemExtendAttribute;
  const hasOrderWithPrice = useSelector(s => s?.orders?.hasOrderWithPrice);

  const getErrorOrLoading = () => {
    if (isUpdateLineItemLoading) {
      return (
        <div className="d-none d-md-inline-block">
          <InlineLoader />
        </div>
      );
    }
    if (errors?.server === SERVER_ERRORS.QUANTITY || errors?.availability) {
      return (
        <p className={styles['server-error']}>{t('AVAILABILITY_LINE_ERROR')}</p>
      );
    }
    return null;
  };

  return (
    <div
      className={`${styles['order-items-availability__wrapper']}`}
      data-testid="availability-wrapper"
    >
      {getErrorOrLoading() ||
        (hasOrderWithPrice && (
          <Availability
            availabilityInformation={availability}
            sourcingDetailsInformation={sourcingDetails}
            availabilityTypeShowToggle={displayAvailabityAndSourcingDetails}
            hideLabel
            hoseAssemblyDetails={{
              hoseAvailabilityLabel,
              hoseAssemblyIndicator
            }}
            storeAvailability={storeAvailability}
          />
        ))}
    </div>
  );
};

AvailabilityCell.propTypes = {
  item: PropTypes.shape({
    errors: PropTypes.shape({
      server: PropTypes.string,
      availability: PropTypes.string
    }),
    isUpdateLineItemLoading: PropTypes.bool,
    availability: PropTypes.arrayOf(
      PropTypes.shape({
        availabilityMessage: PropTypes.string,
        quantityAvailable: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ]),
        showContactDealerPopUp: PropTypes.bool
      })
    ),
    sourcingDetails: sourcingDetailsPropTypes,
    displayAvailabityAndSourcingDetails: PropTypes.bool,
    orderItemExtendAttribute: PropTypes.shape({
      hoseAssemblyIndicator: PropTypes.bool,
      hoseAvailabilityLabel: PropTypes.string
    })
  })
};

export default AvailabilityCell;
