import { useContext, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ComponentLoader,
  ToastContext,
  ModalContext,
  SystemFeedback
} from 'cat-ecommerce-alloy';
import { Formik, Form } from 'formik';
import MyEquipmentTitle from '../MyEquipmentTitle';
import CurrentEquipmentModal from '../CurrentEquipmentModal';
import { replaceTokensInString } from '@app/utils';
import GuestAddEquipmentSuccess from '../GuestAddEquipmentSuccess';
import SerialMismatch from './SerialMismatch';
import useSystemFeedback from '@app/hooks/useSystemFeedback';
import { ERROR_DOMAIN } from '@app/constants/errorConstants';
import ExceptionFeedbackBySelector from '../../../common/Exception/ExceptionFeedbackBySelector';
import { useTracking } from 'react-tracking';
import styles from '../MyEquipment.module.scss';
import { singleEquipmentPropType } from '../propTypesDeclarations';
import { STATUS, USER_TYPE_GUEST } from '@app/constants/commonConstants';
import useLoginRegistration from '@app/components/login/hooks/useLoginRegistration';
import {
  addOrUpdateEquipmentInWishList,
  clearErrors,
  RemoveEquipmentDataDetails,
  setEquipmentSaveSuccess,
  validationSerialNumber
} from '@app/store/myequipment/actions';
import { useAnalytics } from '@app/hooks';
import { FORM_SAVE_EQUIPMENT } from '@app/constants/analyticsConstants';
import GAFormEventError from './GAFormEventError';
import { ME_MODAL_COMPONENT_TYPE } from '@app/constants/myEquipmentConstants';
import { redirectMlp } from './utils';
import { COMMON_SET_TOAST_INFO } from '@app/store/common/constants';

const EditModal = ({
  currentEquipment,
  modalTitle,
  widgetIdentifier,
  isTempEquipmentMessage
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fireFormSubmittedEvent } = useAnalytics();
  const { clearError } = useSystemFeedback();
  const { createSuccessToast } = useContext(ToastContext);
  const userType = useSelector(state => state.common.userType);
  const isGuestUser = userType === USER_TYPE_GUEST;
  const isCSPCustomer = useSelector(state => state.common.isCSPCustomer);

  const { isLoading, equipmentSaveSuccessful } = useSelector(
    state => state.myEquipment
  );
  const error = useSelector(state => state.myEquipment.error);
  const serialNumValidationStatus = useSelector(
    state => state.myEquipment.serialNumValidationStatus
  );
  const serialNumberError = useSelector(
    state => state.myEquipment.serialNumberError
  );

  const { closeAllModals, hideOverflow, addModal } = useContext(ModalContext);

  const { showLoginRegistrationModal } = useLoginRegistration();
  const { isQRCodeTempEquipment } = currentEquipment;
  const errorSelector = state => state?.errors?.myEquipment?.dealerNotification;

  const { trackEvent } = useTracking();
  const [inputValue, setInputValue] = useState(currentEquipment.serialNumber);
  const isEditing = modalTitle !== t('SAVE_MY_EQUIPMENT');

  const getToastContent = (
    model = `${currentEquipment?.model} ${currentEquipment?.equipmentFamily}`
  ) => {
    if (isEditing) {
      return replaceTokensInString(`${t('MEQ_EDIT_EQ_TOAST')}`, model);
    } else {
      if (isGuestUser) {
        return replaceTokensInString(`${t('MEQ_GUEST_ADD_TOAST1')}`, model);
      } else {
        return replaceTokensInString(`${t('MEQ_TOAST1')}`, model);
      }
    }
  };
  const toastContent = getToastContent();

  const handleModalOperationSuccess = useCallback(
    async (toastTxt, isShowToast = false) => {
      closeAllModals();
      if (isShowToast) {
        dispatch({ type: COMMON_SET_TOAST_INFO, payload: toastTxt });
        return;
      }
      createSuccessToast(toastTxt);
    },
    [dispatch, closeAllModals, createSuccessToast]
  );

  useEffect(() => {
    return () => dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    return () => clearError(ERROR_DOMAIN.MY_EQUIPMENT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (equipmentSaveSuccessful) {
      const toastTxt =
        !isEditing && isGuestUser ? (
          <GuestAddEquipmentSuccess
            message={toastContent}
            showLoginRegistrationModal={showLoginRegistrationModal}
          />
        ) : (
          toastContent
        );
      handleModalOperationSuccess(toastTxt, isEditing).then(() => {
        dispatch(setEquipmentSaveSuccess(false));
        redirectMlp(currentEquipment.seoUrl);
      });
    }
  }, [
    dispatch,
    equipmentSaveSuccessful,
    handleModalOperationSuccess,
    isEditing,
    showLoginRegistrationModal,
    toastContent,
    isGuestUser,
    currentEquipment.seoUrl
  ]);

  const handleRemoveEquipment = () => {
    const toastContents = replaceTokensInString(
      `${t('MEQ_REMOVE_EQ_TOAST')}`,
      currentEquipment.model
    );
    dispatch(
      RemoveEquipmentDataDetails(currentEquipment, toastContents, () =>
        handleModalOperationSuccess(toastContents, true)
      )
    );
  };
  const openSerialMismatch = formData => {
    hideOverflow();
    addModal(
      <SerialMismatch
        currentEquipment={currentEquipment}
        toastContent={toastContent}
        isEditing={isEditing}
        formData={formData}
        widgetIdentifier={widgetIdentifier}
      />,
      t('SAVE_EQUIP')
    );
    return false;
  };
  const [toggleSerialNumError, setToggleSerialNumError] = useState('');

  const handleValidations = (data, { setFieldError }) => {
    clearError(ERROR_DOMAIN.MY_EQUIPMENT);
    const { model, equipmentFamily, notifyDealer, serialNumber } = data;

    const formSubmittedEventPayload = {
      event: 'formSubmitted',
      pagePath: document.location.pathname,
      formName: FORM_SAVE_EQUIPMENT,
      formContent: isCSPCustomer
        ? `${serialNumber} | ${model} ${equipmentFamily}`
        : serialNumber,
      formContent2: isCSPCustomer
        ? !!notifyDealer
        : `${model}${equipmentFamily}`,
      formFieldCausingError: '',
      formStatus: '',
      formLocation: ''
    };
    if (notifyDealer && !serialNumber) {
      return setToggleSerialNumError(t('MEQ_ADD_NOTIFICATION5'));
    } else {
      setToggleSerialNumError('');
    }
    if (serialNumValidationStatus === STATUS.IDLE && inputValue !== '') {
      dispatch(
        validationSerialNumber(
          currentEquipment,
          setFieldError,
          inputValue,
          isEditing
        )
      );
    } else {
      dispatch(
        addOrUpdateEquipmentInWishList(
          data,
          setFieldError,
          trackEvent,
          openSerialMismatch,
          isQRCodeTempEquipment ? true : isEditing,
          toastContent,
          fireFormSubmittedEvent,
          formSubmittedEventPayload,
          widgetIdentifier
        )
      );
    }
  };

  return (
    <>
      <MyEquipmentTitle title={modalTitle} componentType="modal" />
      <Formik initialValues={currentEquipment} onSubmit={handleValidations}>
        {prop => (
          <Form className={styles['equipment-modal__form']}>
            <>
              {isLoading && <ComponentLoader />}
              <ExceptionFeedbackBySelector selector={errorSelector} />
              <GAFormEventError
                isCSPCustomer={isCSPCustomer}
                serialNumberError={serialNumberError}
                fireFormSubmittedEvent={fireFormSubmittedEvent}
              />
              {error && (
                <div className="mt-2">
                  <SystemFeedback
                    type="error"
                    message={replaceTokensInString(
                      t('MEQ_EDIT_TIMEOUT'),
                      error.code || '0'
                    )}
                  />
                </div>
              )}
              <div className="position-relative">
                <CurrentEquipmentModal
                  currentEquipment={currentEquipment}
                  className="edit-machine"
                  componentType={ME_MODAL_COMPONENT_TYPE.EDIT_MODAL}
                  toggleSerialNumError={toggleSerialNumError}
                  setToggleSerialNumError={setToggleSerialNumError}
                  setFieldError={prop.setFieldError}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  formikError={prop.errors}
                  widgetIdentifier={widgetIdentifier}
                  serialNumValidationStatus={serialNumValidationStatus}
                  handleRemoveEquipment={handleRemoveEquipment}
                  isEditing={isEditing}
                  openSerialMismatch={openSerialMismatch}
                  isTempEquipmentMessage={isTempEquipmentMessage}
                />
              </div>
            </>
          </Form>
        )}
      </Formik>
    </>
  );
};

EditModal.propTypes = {
  currentEquipment: singleEquipmentPropType,
  modalTitle: PropTypes.string,
  widgetIdentifier: PropTypes.string,
  isTempEquipmentMessage: PropTypes.bool
};

export default EditModal;
