import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AlloyButton } from 'cat-ecommerce-alloy';
import { useModal } from '@cat-ecom/pcc-components';
import { DealerLocatorModalWrapper } from '@app/components/dealer-locator-react';
import { useBreakpoint } from '@app/hooks';
import { replaceTokensInString } from '@app/utils';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';

const AddDealerButton = ({
  adpSubmitPromise,
  bobRedirect,
  bobUserId,
  reorderFunc
}) => {
  const [t] = useTranslation();

  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);
  const title = isMD ? t('CAT_MA_ASSOCIATEDDEALERS') : 'ADP';
  const heading = replaceTokensInString(t('GENERAL_BACK_TO'), title);
  const modal = useModal({
    isRequired: false,
    heading,
    backButton: true,
    size: 'md'
  });

  return (
    <>
      <DealerLocatorModalWrapper modal={modal} />
      <AlloyButton
        buttonType="ghost"
        className="ps-0 mb-2 mt-n3"
        onClick={modal.initiatorProps.onClick}
      >
        {t('ADD_A_DEALER')}
      </AlloyButton>
    </>
  );
};

AddDealerButton.propTypes = {
  adpSubmitPromise: PropTypes.func,
  bobRedirect: PropTypes.string,
  bobUserId: PropTypes.string,
  reorderFunc: PropTypes.func
};

export default AddDealerButton;
