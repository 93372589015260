import PropTypes from 'prop-types';
import { ListWithIcons, ListWithIconsItem } from '@cat-ecom/pcc-components';
import { v4 as uuidv4 } from 'uuid';

const ListContent = ({ items }) => {
  return (
    <ListWithIcons>
      {items.map(item => (
        <ListWithIconsItem
          className="pb-2"
          icon={item.icon}
          key={`list-with-icon-item-${uuidv4()}`}
          text={item.text}
          background={item?.background}
        />
      ))}
    </ListWithIcons>
  );
};

ListContent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      text: PropTypes.string
    })
  )
};

export default ListContent;
