import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBreakpoint } from '@app/hooks';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FooterSocialLinks from './FooterSocialLinks';
import FooterFindPartsLinks from './FooterFindPartsLinks';
import FooterSupportLinks from './FooterSupportLinks';
import FooterAccountLinks from './FooterAccountLinks';
import FooterLanguageButtons from './FooterLanguageButtons';
import { AssociatedDealerDrawer } from '../associated-dealer-block';
import { fetchLanguages, fetchSocialLinks } from '@app/store/footer/actions';
import { PAGE_BREAKPOINTS, STATUS } from '../../constants/commonConstants';
import {
  FooterLinksContainer,
  FooterColumn,
  FooterAccordion,
  FooterAccordionPanel,
  Footer,
  useDrawer
} from '@cat-ecom/pcc-components';
import FooterLegalLinksRow from './FooterLegalLinksRow';

const Container = ({ portalTarget, href, openDealerLocatorDrawer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getUserInfoStatus = useSelector(
    state => state.common.getCommonVarsStatus
  );
  const haveCommonVariables = getUserInfoStatus === STATUS.RESOLVED;
  const drawer = useDrawer({
    id: 'associated_dealer_drawer_footer',
    size: 'lg'
  });

  useEffect(() => {
    dispatch(fetchLanguages());
    dispatch(fetchSocialLinks());
  }, [dispatch]);

  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);

  const renderContent = () => {
    if (isMD) {
      return (
        <>
          <FooterLinksContainer>
            <FooterColumn name={t('FOOTER_PRODUCT')}>
              <FooterFindPartsLinks />
            </FooterColumn>
            <FooterColumn name={t('FOOTER_SUPPORT')}>
              <FooterSupportLinks
                openADSDrawer={drawer.handleOpen}
                openDealerLocatorDrawer={openDealerLocatorDrawer}
              />
            </FooterColumn>
            <FooterColumn name={t('HEADER_MY_ACCOUNT')}>
              <FooterAccountLinks />
            </FooterColumn>
            <FooterColumn name={t('Footer_CatSocial')}>
              <FooterSocialLinks />
            </FooterColumn>
          </FooterLinksContainer>
          <FooterLanguageButtons latestHref={href} />
          <FooterLegalLinksRow />
          <AssociatedDealerDrawer drawer={drawer} />
        </>
      );
    } else {
      return (
        <>
          <FooterAccordion>
            <FooterAccordionPanel title={t('FOOTER_PRODUCT')}>
              <FooterFindPartsLinks />
            </FooterAccordionPanel>
            <FooterAccordionPanel title={t('FOOTER_SUPPORT')}>
              <FooterSupportLinks
                openADSDrawer={drawer.handleOpen}
                openDealerLocatorDrawer={openDealerLocatorDrawer}
              />
            </FooterAccordionPanel>
            <FooterAccordionPanel title={t('HEADER_MY_ACCOUNT')}>
              <FooterAccountLinks />
            </FooterAccordionPanel>
            <FooterAccordionPanel title={t('Footer_CatSocial')}>
              <FooterSocialLinks />
            </FooterAccordionPanel>
            <FooterAccordionPanel title={t('LC_LANGUAGE_CURRENCY')}>
              <FooterLanguageButtons latestHref={href} />
            </FooterAccordionPanel>
          </FooterAccordion>
          <FooterLegalLinksRow />
          <AssociatedDealerDrawer drawer={drawer} />
        </>
      );
    }
  };

  return createPortal(
    <Footer data-testid="footer">
      {haveCommonVariables && renderContent()}
    </Footer>,
    portalTarget
  );
};

Container.propTypes = {
  portalTarget: PropTypes.instanceOf(Element).isRequired,
  href: PropTypes.string,
  openDealerLocatorDrawer: PropTypes.func
};

export default Container;
