import { isEmpty } from '@app/utils';
import { KitIcon } from 'cat-ecommerce-alloy';
import PropTypes from 'prop-types';
import LineAttributeItem from '../LineItemAttributes/LineAttributeItem';

const SSROLineItems = ({ item }) => {
  const { orderItemExtendAttribute: { ssroBundle } = {} } = item;
  const hasBundle = !isEmpty(ssroBundle);
  return hasBundle
    ? ssroBundle.map(({ selfServiceOptionName, selfServiceOptionId }) => (
        <LineAttributeItem
          key={selfServiceOptionId}
          icon={KitIcon}
          title={selfServiceOptionName}
        />
      ))
    : null;
};

SSROLineItems.propTypes = {
  item: PropTypes.shape({
    orderItemExtendAttribute: PropTypes.shape({
      ssroBundle: PropTypes.arrayOf(
        PropTypes.shape({
          selfServiceOptionId: PropTypes.string,
          selfServiceOptionName: PropTypes.string
        })
      )
    })
  })
};
export default SSROLineItems;
