import { SORT_TYPE } from '@app/constants/commonConstants';

export const PENDING_QUOTE_SEARCH_FIELDS = {
  QUOTE_NUMBER: 'quoteDocumentNumber',
  MO_PURCHASEORDER: 'poNumber',
  ACCOUNT: 'dcn',
  PART_NUM: 'partNumber',
  DISPLAY_QUOTE_STATUS: 'quoteStatus',
  EQUIPMENT: 'equipment',
  FILTER_BY_DATE: 'filterByDate',
  START_DATE: 'dateRange.displayStartDate',
  END_DATE: 'dateRange.displayEndDate',
  DATE_RANGE: 'dateRange',
  SERIAL_NUMBER: 'serialNumber',
  EQUIPMENT_SERIALNUMBER: 'equipmentSerialNumber',
  ASSETID: 'assetId',
  MODEL: 'model'
};
export const QUOTE_STATUS = {
  ALL: { displayKey: 'CAT_ORD_ALL', value: '' },
  PENDING: { displayKey: 'MO_OrderStatus_P', value: 'NEW' },
  PENDING_APPROVAL: { displayKey: 'ORDER_PENDING_APPROVAL', value: 'W' },
  REJECTED: { displayKey: 'CAT_ORD_REJECTED', value: 'N' },
  EXPIRED: { displayKey: 'EXPIRED_STATE', value: 'E' }
};
export const QUOTE_STATUS_ADMIN_DEALER = {
  PENDING: { displayKey: 'MO_OrderStatus_P', value: 'NEW' },
  EXPIRED: { displayKey: 'EXPIRED_STATE', value: 'E' }
};
export const QUOTE_STATUS_ADMIN_APPROVER = {
  ALL: { displayKey: 'CAT_ORD_ALL', value: '' },
  PENDING: { displayKey: 'MO_OrderStatus_P', value: 'NEW' },
  EXPIRED: { displayKey: 'EXPIRED_STATE', value: 'E' }
};
export const SORT_BY_DATE = [
  { displayKey: 'DATE_CREATED', value: 'D' },
  { displayKey: 'SUMM_PAY_CARD_EXPIRATION_DATE', value: 'E' }
];

export const PENDING_QUOTE_COLUMN_IDS = {
  QUOTE_DOCUMENT_NUMBER: 'quoteDocumentNumber',
  ACTIONS: 'action',
  PO_NUM: 'poNumber',
  QUOTE_DATE: 'quoteCreationDate',
  EXPIRY_DATE: 'quoteExpirationDate',
  EQUIPMENT: 'serialNumber',
  TOTAL_PRICE: 'formattedNetQuoteTotalAmount',
  ITEMS: 'itemsCount',
  ORDEREDBY: 'orderByName',
  SUBMISSION_DATE: 'formattedSubmitDate',
  SUBMISSION_DATE_UNFORMATTED: 'unFormattedSubmitDate'
};

export const QUOTES_AWAITING_APPROVAL_TABLE_SCHEMA = [
  {
    id: PENDING_QUOTE_COLUMN_IDS.QUOTE_DOCUMENT_NUMBER,
    label: 'MO_QUOTENUMBER',
    width: '155px',
    isFrozen: true,
    behavior: 'sortable',
    sortType: SORT_TYPE.ALPHANUMERIC,
    isDefault: true,
    isDisabled: true
  },
  {
    id: PENDING_QUOTE_COLUMN_IDS.ORDEREDBY,
    label: 'ORDERED_BY',
    width: '140px',
    isDefault: true,
    behavior: 'sortable',
    sortType: SORT_TYPE.ALPHANUMERIC
  },
  {
    id: PENDING_QUOTE_COLUMN_IDS.SUBMISSION_DATE,
    label: 'SUBMISSION_DATE_TABLE',
    width: '148px',
    isDefault: true,
    behavior: 'sortable',
    sortType: SORT_TYPE.DATE
  },
  {
    id: PENDING_QUOTE_COLUMN_IDS.PO_NUM,
    label: 'MO_PURCHASEORDER',
    width: '190px',
    behavior: 'sortable',
    isDefault: true,
    sortType: SORT_TYPE.ALPHANUMERIC
  },
  {
    id: PENDING_QUOTE_COLUMN_IDS.ITEMS,
    label: 'ITEMS_COUNT',
    behavior: 'sortable',
    isDefault: true,
    sortType: SORT_TYPE.NUMERIC,
    width: '82px'
  },
  {
    id: PENDING_QUOTE_COLUMN_IDS.TOTAL_PRICE,
    label: 'TOTAL_PRICE',
    width: '106px',
    isDefault: true,
    align: 'left',
    behavior: 'sortable',
    sortType: SORT_TYPE.CURRENCY
  }
];

export const PENDING_QUOTES_TABLE_SCHEMA = [
  {
    id: PENDING_QUOTE_COLUMN_IDS.QUOTE_DOCUMENT_NUMBER,
    label: 'MO_QUOTENUMBER',
    width: '155px',
    isFrozen: true,
    behavior: 'sortable',
    sortType: SORT_TYPE.ALPHANUMERIC,
    isDefault: true,
    isDisabled: true
  },
  {
    id: PENDING_QUOTE_COLUMN_IDS.ACTIONS,
    width: '50px',
    isFrozen: true,
    isDefault: true
  },
  {
    id: PENDING_QUOTE_COLUMN_IDS.EXPIRY_DATE,
    label: 'SUMM_PAY_CARD_EXPIRATION_DATE',
    width: '140px',
    isDefault: true,
    behavior: 'sortable',
    sortType: SORT_TYPE.DATE
  },
  {
    id: PENDING_QUOTE_COLUMN_IDS.QUOTE_DATE,
    label: 'MO_QUOTEDATE',
    width: '135px',
    isDefault: true,
    behavior: 'sortable',
    sortType: SORT_TYPE.DATE
  },
  {
    id: PENDING_QUOTE_COLUMN_IDS.ITEMS,
    label: 'ITEMS_COUNT',
    behavior: 'sortable',
    isDefault: true,
    sortType: SORT_TYPE.NUMERIC,
    width: '82px'
  },
  {
    id: PENDING_QUOTE_COLUMN_IDS.PO_NUM,
    label: 'MO_PURCHASEORDER',
    width: '190px',
    behavior: 'sortable',
    isDefault: true,
    sortType: SORT_TYPE.ALPHANUMERIC
  },
  {
    id: PENDING_QUOTE_COLUMN_IDS.EQUIPMENT,
    label: 'CAT_EQUIPMENT',
    width: '203px',
    isDefault: true,
    behavior: 'sortable',
    sortType: SORT_TYPE.EQUIPMENT
  },
  {
    id: PENDING_QUOTE_COLUMN_IDS.TOTAL_PRICE,
    label: 'TOTAL_PRICE',
    width: '106px',
    isDefault: true,
    align: 'left',
    behavior: 'sortable',
    sortType: SORT_TYPE.CURRENCY
  }
];
export const KEBAB_OPTIONS = {
  PLACE_ORDER: 'placeOrder',
  MO_EDIT: 'edit',
  VIEW_DETAILS: 'viewDetails',
  DELETE: 'delete'
};
export const VARIANT_ORDER_STATUS = {
  EXPIRED: 'EXPIRED_STATE',
  PENDING: 'MO_OrderStatus_P',
  PENDING_APPROVAL: 'ORDER_PENDING_APPROVAL',
  REJECTED: 'CAT_ORD_REJECTED'
};

export const LABEL_STATUS = {
  ERRORINFO: 'errorinfo',
  WARNING: 'warning',
  DEFAULT: 'default'
};
export const CONF_MODAL_HEADING = {
  [KEBAB_OPTIONS.EDIT]: 'WARNING_DO_YOU_WISH_TO_PROCEED',
  [KEBAB_OPTIONS.PLACE_ORDER]: 'ACTIVATE_QUOTE_MODAL_HDR',
  [KEBAB_OPTIONS.DELETE]: 'DELETE_QUOTE_MODAL_HEADER'
};

export const CONF_MODAL_MESSAGE = {
  [KEBAB_OPTIONS.EDIT]: 'EDIT_QUOTE_WARNING',
  [KEBAB_OPTIONS.PLACE_ORDER]: 'ACTIVATE_QUOTE_MODAL_BODY',
  [KEBAB_OPTIONS.DELETE]: 'DELETE_QUOTE_MODAL_BODY'
};
export const FIRST_PAGE = 1;

export const DEFAULT_INPUT_VALUES = '';
export const DCN = 'dcn';

export const QUOTE_EVENT = {
  DATE_CREATED: '1',
  EXPIRATION_DATE: '2'
};
export const EXPIRED = 'Expired';
export const PENDING = 'Pending';
export const PENDING_APPROVAL = 'Pending Approval';
export const REJECTED = 'Rejected';

export const GA_QUOTE_STATUS = [{ value: 'Pending' }, { value: 'Expired' }];
export const PENDING_QUOTES = 'pendingQuotes';
export const QUOTES_AWAITING_APPROVAL = 'quotesAwaitingApproval';
export const QUOTE_STATUS_GA_LABEL = {
  NEW: 'Pending',
  W: 'Pending Approval',
  N: 'Rejected',
  E: 'Expired'
};

export const QUOTE_ALL = 'All';
