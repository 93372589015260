/* eslint-disable import/no-named-as-default-member */
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import actions from '../store/exception/actions';

const useSystemFeedback = () => {
  const dispatch = useDispatch();
  const setError = useCallback(
    (domain, component, error) =>
      dispatch(actions.setError(domain, component, error)),
    [dispatch]
  );
  const clearError = useCallback(
    (domain, component) => dispatch(actions.clearError(domain, component)),
    [dispatch]
  );
  const clearAllErrors = useCallback(() => dispatch(actions.clearAllErrors()), [
    dispatch
  ]);

  return {
    clearAllErrors,
    clearError,
    setError
  };
};

export default useSystemFeedback;
