import PropTypes from 'prop-types';
import { getIconSizeNumber } from '../utils';

const ReturnRequestIcon = ({ size }) => {
  const sizeNumber = getIconSizeNumber(size);

  return (
    <svg
      width={sizeNumber}
      height={sizeNumber}
      viewBox={`0 0 ${sizeNumber} ${sizeNumber}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_5068)">
        <path
          d="M12.0625 9.31133V11.9363H9.4375"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.9375 6.68633V4.06133H22.5625"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.102 4.05959C21.0072 5.00259 21.56 6.22853 21.6674 7.53122C21.7749 8.83391 21.4304 10.1339 20.692 11.2124C19.9536 12.291 18.8663 13.0824 17.613 13.4535C16.3597 13.8246 15.0168 13.7527 13.8102 13.25"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8974 11.9381C10.9942 10.9946 10.4432 9.76909 10.3368 8.46733C10.2304 7.16557 10.5751 5.86685 11.3131 4.78923C12.0511 3.71162 13.1374 2.92074 14.3896 2.54941C15.6419 2.17808 16.9837 2.24891 18.1898 2.75"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M0.75 14.25H3.75C4.14782 14.25 4.52936 14.408 4.81066 14.6893C5.09196 14.9706 5.25 15.3522 5.25 15.75V21.75C5.25 22.1478 5.09196 22.5294 4.81066 22.8107C4.52936 23.092 4.14782 23.25 3.75 23.25H0.75"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 21.75C20.25 20.9544 19.9339 20.1913 19.3713 19.6287C18.8087 19.0661 18.0456 18.75 17.25 18.75H13.5C13.5 17.9544 13.1839 17.1913 12.6213 16.6287C12.0587 16.0661 11.2956 15.75 10.5 15.75H5.25V21.75H20.25Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 18.75H13.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.625 20.25C2.41789 20.25 2.25 20.0821 2.25 19.875C2.25 19.6679 2.41789 19.5 2.625 19.5"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M2.625 20.25C2.83211 20.25 3 20.0821 3 19.875C3 19.6679 2.83211 19.5 2.625 19.5"
        stroke="black"
        strokeWidth="1.5"
      />
      <defs>
        <clipPath id="clip0_1_5068">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(9 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

ReturnRequestIcon.propTypes = {
  size: PropTypes.string
};
export default ReturnRequestIcon;
