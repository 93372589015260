import {
  Input,
  SerialInput,
  Validator,
  Loader,
  CheckIcon,
  ErrorIcon
} from 'cat-ecommerce-alloy';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Field from '@app/components/common/form/Field';
import PropTypes from 'prop-types';
import { STATUS } from '@app/constants/commonConstants';
import { useEffect, useCallback } from 'react';
import styles from '../../CurrentEquipmentModal/CurrentEquipmentModal.module.scss';
import { singleEquipmentPropType } from '../../propTypesDeclarations';
import {
  removeSerialValidation,
  validationSerialNumber
} from '@app/store/myequipment/actions';
import { ME_MODAL_COMPONENT_TYPE } from '@app/constants/myEquipmentConstants';

const CurrentEquipmentElement = ({
  condition,
  inputType,
  children,
  setShowSerialNumberSuggestion,
  inputValue,
  setInputValue,
  showSerialNumberSuggestion,
  currentEquipment,
  setFieldError,
  formikError,
  serialNumValidationStatus,
  isEditing,
  openSerialMismatch
}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  let elementType = null;
  const serialNumberError = useSelector(
    state => state.myEquipment?.serialNumberError
  );

  useEffect(() => {
    dispatch(removeSerialValidation());
    if (inputValue?.length === 8) {
      handleSerialNumValidation(inputValue);
    }
  }, [dispatch, handleSerialNumValidation, inputValue]);

  const handleSerialNumValidation = useCallback(
    evt => {
      if (evt?.target?.value.length >= 8 || evt.length >= 8) {
        dispatch(
          validationSerialNumber(
            currentEquipment,
            setFieldError,
            evt?.target?.value ? evt.target.value : evt,
            isEditing,
            openSerialMismatch
          )
        );
      }
    },
    [currentEquipment, dispatch, isEditing, openSerialMismatch, setFieldError]
  );
  const serialNumValidator = useCallback(
    val => {
      return new Validator(val)
        .isAlphaNumeric(t('MEQ_SERIAL_INVALID'))
        .getError();
    },
    [t]
  );
  const serialSuggestion = e => {
    setInputValue(e.target.value.toUpperCase());
    if (serialNumValidationStatus === STATUS.REJECTED) {
      setFieldError(serialNumberError);
    }
    if (
      serialNumValidationStatus !== STATUS.IDLE &&
      e.target.value.length < 8
    ) {
      dispatch(removeSerialValidation());
    }
  };
  const serialSuggestionFunction = () => {
    setShowSerialNumberSuggestion(!showSerialNumberSuggestion);
  };
  if (inputType === 'serialNumber') {
    elementType = (
      <Field
        aria-label="serialNumber"
        data-testid="serialNumber"
        type="text"
        as={SerialInput}
        className={`EditModal--${inputType}`}
        name="serialNumber"
        id="serialNumber"
        hideLabel
        validator={serialNumValidator}
        maxLength="8"
        ceserialattr="ceSerialNumber"
        onChange={serialSuggestion}
        autoComplete="off"
        onFocus={serialSuggestionFunction}
        value={inputValue}
        error={
          formikError?.serialNumber
            ? formikError?.serialNumber
            : serialNumberError
        }
        setError={setFieldError}
      >
        <span
          className={`bottom-0 end-0 position-absolute pt-2 pe-1 pb-lg-1 pb-2 mb-1 mb-lg-0 ${styles['equipment-modal_left-content']}`}
        >
          {serialNumValidationStatus === STATUS.PENDING && (
            <Loader height="25" width="25" />
          )}
          {serialNumValidationStatus === STATUS.RESOLVED && (
            <CheckIcon className="u-fill--DarkGreen" />
          )}
          {serialNumValidationStatus === STATUS.REJECTED && (
            <ErrorIcon className="u-fill--DarkRed" />
          )}
        </span>
      </Field>
    );
  } else {
    elementType = (
      <Field
        type="text"
        as={Input}
        name={inputType}
        className={`EditModal--${inputType}`}
        maxLength="256"
      />
    );
  }

  return condition === ME_MODAL_COMPONENT_TYPE.EDIT_MODAL ? (
    elementType
  ) : (
    <span>{children}</span>
  );
};

CurrentEquipmentElement.propTypes = {
  condition: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  inputType: PropTypes.string,
  setShowSerialNumberSuggestion: PropTypes.func,
  setInputValue: PropTypes.func,
  inputValue: PropTypes.string,
  showSerialNumberSuggestion: PropTypes.bool,
  setFieldError: PropTypes.func,
  currentEquipment: singleEquipmentPropType,
  formikError: PropTypes.shape({
    serialNumber: PropTypes.string
  }),
  serialNumValidationStatus: PropTypes.string,
  isEditing: PropTypes.bool,
  openSerialMismatch: PropTypes.func
};

export default CurrentEquipmentElement;
