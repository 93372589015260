import HeaderStore from '../HeaderStoreWrapper';
import { useCurrentPage } from '@app/hooks/useCurrentPage';
import HeaderEquipmentWidget from '../HeaderEquipmentWidgetWrapper';
import styles from '../header.module.scss';
import { useSelector } from 'react-redux';
import Conditional from '@app/components/common/Conditional';
import { HeaderNavSection } from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
const HeaderMobileNavSection = () => {
  const { t } = useTranslation();
  const storeDescription = t('SELECT_STORE_DESCRIPTION');
  const equipmentDescription = t('MEQ_PARTS_FIT_CTA');
  const isCSR = useSelector(s => s.common.isCatCSR);
  const showStoreMobileItem = !isCSR;
  const { isOnPMKitsOrSSROPage, isOnOMMPage } = useCurrentPage();
  const isOnEquipmentPages = isOnPMKitsOrSSROPage || isOnOMMPage;

  return (
    <HeaderNavSection fullWidth classname={`${styles['search-action-button']}`}>
      <Conditional test={showStoreMobileItem}>
        <HeaderStore subLabel={storeDescription} isMobileSearch={true} />
      </Conditional>
      <Conditional test={!isOnEquipmentPages}>
        <HeaderEquipmentWidget
          isMobile
          showDivider={showStoreMobileItem}
          classname={`${styles['search-add-equipment']}`}
          subLabel={equipmentDescription}
          showIcon={false}
        />
      </Conditional>
    </HeaderNavSection>
  );
};

export default HeaderMobileNavSection;
