const LEGACY_LOGIN_FORM_ROUTE = '/LogonForm';
export const LEGACY_AJAX_LOGON_FORM_ROUTE = '/AjaxLogonForm';
export const LEGACY_TRACK_ORDER_STATUS_ROUTE = '/TrackOrderStatus';
export const LEGACY_USER_REGISTRATION_FORM_ROUTE = '/UserRegistrationForm';
const LEGACY_SHOPPING_PREFS_ROUTE = '/CATShoppingPreferencesView';
const LEGACY_NOTIFICATION_PREFS_ROUTE = '/CATNotificationPreferencesView';
export const LEGACY_TRACK_SHIPPING = '/OrderShipmentDetailsView';
const LEGACY_ADDRESS_BOOK_ROUTE = '/AddressBookForm';
const LEGACY_ORDER_APPROVAL_ROUTE = '/OrderApprovalView';
export const LEGACY_ORDER_APPROVAL_DETAIL_ROUTE = '/OrderApprovalDetailView';
const LEGACY_REQUISITION_LIST_ROUTE = '/RequisitionListdisplayView';
export const LEGACY_CORE_TRACKING_ROUTE = '/CATCoreTrackingView';
const LEGACY_ORGS_AND_USERS_ROUTE = '/OrganizationsAndUsersView';
const LEGACY_ORGS_AND_USERS_DETAIL_ROUTE = '/OrganizationAdminEditUserView';
const LEGACY_MY_EQUIPMENT_ROUTE = '/MyEquipment';
const LEGACY_PAYMENT_METHODS_ROUTE = '/PaymentMethods';
const LEGACY_PENDING_QUOTES_ROUTE = '/CATPendingQuotesView';
const LEGACY_PSA_ORDER_DETAIL_ROUTE = '/PSAOrderDetail';
const LEGACY_SAVED_LIST_ROUTE = '/RequisitionListDisplayView';
export const LEGACY_SAVED_LIST_DETAIL_ROUTE = '/RequisitionListDetailView';
export const LEGACY_RETURN_REQUEST_ROUTE = '/CATOrderReturnRequestView';
const MY_ACCOUNT_ROUTE = '/:lang/:dealerId/account';

/* TODO: More URLS for account? */

export const MY_ACCOUNT_SEARCH = {
  PREFERENCES: 'ShoppingPreferences',
  EQUIPMENT: 'MyEquipment',
  ADDRESS_BOOK: 'AddressBook',
  LISTS: 'RequisitionListdisplayView',
  LIST_DETAILS: 'RequisitionListDetailView',
  PAYMENT: 'PaymentMethods',
  CAT_VANTAGE_REWARDS: 'CatVantageRewards',
  ORDERS: 'OrderHistory',
  ORDERS_TO_APPROVE: 'OrdersToApprove',
  ORDER_DETAILS: 'OrderDetail',
  ORDER_STATUS: 'OrderStatus',
  ORGANIZATIONS_AND_USERS: 'OrganizationsAndUsers',
  PENDINGQUOTES: 'PendingQuotes',
  PENDING_QUOTE_DETAILS: 'PendingQuoteDetails',
  PERSONAL_INFORMATION: 'PersonalInformation',
  CONTROL_CENTER: 'ControlCenter',
  ORGANIZATIONS_AND_USERS_DETAILS: 'OrganizationsAndUsersDetails',
  NOTIFICATION_PREFERENCES: 'notificationPreferences',
  RETURN_FORM: 'OrderReturnRequest',
  MY_ACCOUNT_SUMMARY: 'MyAccountSummary',
  CORE_TRACKING: 'coreTracking',
  PENDING_ORDER_DETAILS: 'PendingOrderDetails',
  BLOCKS_LISTS: 'SavedListsBlocks',
  INVOICES: 'Invoices',
  INVOICE_DETAILS: 'InvoiceDetail',
  PENDING_QUOTES_BLOCKS: 'PendingQuotesBlocks',
  ORDERS_TO_APPROVE_BLOCKS: 'OrdersToApproveBlocks'
};

export const MY_ACCOUNT_ROUTES = [
  LEGACY_LOGIN_FORM_ROUTE,
  LEGACY_AJAX_LOGON_FORM_ROUTE,
  LEGACY_TRACK_SHIPPING,
  LEGACY_SHOPPING_PREFS_ROUTE,
  LEGACY_NOTIFICATION_PREFS_ROUTE,
  LEGACY_ADDRESS_BOOK_ROUTE,
  LEGACY_TRACK_ORDER_STATUS_ROUTE,
  LEGACY_ORDER_APPROVAL_ROUTE,
  LEGACY_ORDER_APPROVAL_DETAIL_ROUTE,
  LEGACY_REQUISITION_LIST_ROUTE,
  LEGACY_CORE_TRACKING_ROUTE,
  LEGACY_ORGS_AND_USERS_ROUTE,
  LEGACY_ORGS_AND_USERS_DETAIL_ROUTE,
  LEGACY_MY_EQUIPMENT_ROUTE,
  LEGACY_PAYMENT_METHODS_ROUTE,
  LEGACY_PENDING_QUOTES_ROUTE,
  LEGACY_PSA_ORDER_DETAIL_ROUTE,
  LEGACY_SAVED_LIST_ROUTE,
  LEGACY_SAVED_LIST_DETAIL_ROUTE,
  LEGACY_RETURN_REQUEST_ROUTE,
  MY_ACCOUNT_ROUTE
];

export const PAYMENT_METHODS_ROUTE = '/payment-methods';
export const SHOP_ALL_CATEGORIES_ROUTE = '/:lang/:dealerId/ShopAllCategories';
export const CATEGORY_ROUTE = [
  '/CategoryDisplay',
  '/:lang/:dealerId/:category',
  '/:lang/:dealerId/search/:category'
];
export const PRODUCT_ROUTE = [
  '/ProductDisplay',
  '/:lang/:dealerId/:category/:partNumber',
  '/:lang/:dealerId/:partNumber'
];
export const HOMEPAGE_ROUTE = ['/:lang/:dealerId', '/:dealerId', '/StoreView'];
export const ORDER_CONFIRMATION_ROUTE = '/OrderShippingBillingConfirmationView';
export const CSR_LANDING_PAGE_ROUTE = '/CustomerServiceLandingPageView';
export const CONTACT_US_PAGE_ROUTE = [
  '/:lang/:dealerId/CATContactUsDisplayView',
  '/CATContactUsDisplayView'
];
export const ORDER_DETAIL = '/OrderDetail';
// TODO Use for new checkout routing. Can delete SUMMARY_AND_PAYMENT_ROUTE_LEGACY and PICKUP_AND_DELIVERY_ROUTE_LEGACY after
export const CHECKOUT_ROUTE = '/:lang/:dealerId/checkout';
export const PICKUP_AND_DELIVERY_ROUTE = '/pickup-and-delivery';
export const ORDERS_CHECKOUT_VIEW_ROUTE = '/AjaxCheckoutDisplayView';
export const REST_ORDER_CALCULATE_ROUTE = '/RESTOrderCalculate';
export const REST_ORDERITEM_UPDATE_ROUTE = '/RESTOrderItemUpdate';
export const SUMMARY_AND_PAYMENT_ROUTE = '/summary-and-payment';
export const SHOPPING_CART_ROUTE = '/AjaxOrderItemDisplayView';
export const SEARCHPAGE_ROUTE = '/SearchDisplay';
export const SHOPPING_CART_ROUTES = [
  SHOPPING_CART_ROUTE,
  REST_ORDERITEM_UPDATE_ROUTE,
  REST_ORDER_CALCULATE_ROUTE,
  ORDERS_CHECKOUT_VIEW_ROUTE
];
export const PARTS_MANUAL_ROUTE = '/:lang/:dealerId/PartLookup';
///////////////////////////
export const SUMMARY_AND_PAYMENT_ROUTE_LEGACY =
  '/SingleShipmentOrderSummaryView';
export const PICKUP_AND_DELIVERY_ROUTE_LEGACY = '/OrderShippingBillingView';
export const PICKUP_AND_DELIVERY_GUEST_ROUTE_LEGACY = '/GuestOrderShippingView';
export const HELP_CENTER_ROUTE = '/:lang/:dealerId/help';
export const ABOUT_CAT_PARTS_ROUTE = '/:lang/:dealerId/about';
export const REPAIR_OPTIONS_ROUTE = '/selfServiceOptions';
export const OMM_CONTENT_ROUTE = '/OmmDetailsView';
export const ROUTE_CONTACT_US = '/CATContactUsDisplayView';
export const ROUTE_TERMS_OF_USE = '/:lang/:dealerId/legalNotices';
export const ROUTE_TERMS_AND_CONDITIONS =
  '/:lang/:dealerId/TermsConditionsPage';
export const PRIVACY_POLICE = '/:lang/:dealerId/PrivacyPolicyPage';
export const ROUTE_SITE_MAP = '/:lang/:dealerId/sitemap';
export const ROUTE_CAT_CENTRAL = '/:lang/:dealerId/catcentral';
export const ROUTE_GENERIC_STATIC_PAGE_LAYOUT_VIEW =
  '/GenericStaticContentPageLayoutView';
export const NOT_SHOW_MY_EQUIPMENT_WIDGET_ROUTES = [
  SHOPPING_CART_ROUTE,
  REPAIR_OPTIONS_ROUTE,
  LEGACY_USER_REGISTRATION_FORM_ROUTE,
  LEGACY_NOTIFICATION_PREFS_ROUTE,
  LEGACY_CORE_TRACKING_ROUTE,
  ROUTE_CONTACT_US,
  ROUTE_GENERIC_STATIC_PAGE_LAYOUT_VIEW,
  SHOP_ALL_CATEGORIES_ROUTE,
  ROUTE_TERMS_OF_USE,
  ROUTE_TERMS_AND_CONDITIONS,
  PRIVACY_POLICE,
  ROUTE_SITE_MAP,
  ROUTE_CAT_CENTRAL,
  OMM_CONTENT_ROUTE
];

export const NOT_SHOW_MY_EQUIPMENT_WIDGET_ROUTES_PARAMS = {
  pageName: [
    'CATShopAllCategoriesContentPage',
    'AboutPartCatsContentPage',
    'SiteMapPage'
  ]
};
export const LEGAL_NOTICES_ROUTE = '/:lang/:dealerId/LegalNotices';

export const WARRRANTY_RETURN_POLICY_ROUTE =
  '/:lang/:dealerId/warrantyandreturns';

export const USER_REGISTRATION_FORM_ROUTE = '/UserRegistrationForm';
export const ORDER_STATUS_INQUIRY_ROUTE = '/:lang/:dealerId/OrderStatusInquiry';
export const PICKUP_AND_DELIVERY_PAGE = 'PickupAndDeliveryView';
export const GUEST_ORDER_STATUS_ROUTE = '/CATPCCGuestOrderStatusDetails';
export const GUEST_EQUIPMENT_VIEW = '/:lang/:dealerId/GuestUserMyEquipmentView';
export const TAX_DETAILS_PAGE_ROUTE = '/UserTaxInfoView';
//Alp Maintenance video page
export const ALP_MAINTENANCE_VIDEOS_VIEW_ROUTE =
  '/:lang/:dealerId/AlpMaintenanceVideosView';

export const CHECKOUT_ROUTES = [
  `/${PICKUP_AND_DELIVERY_PAGE}`,
  PICKUP_AND_DELIVERY_GUEST_ROUTE_LEGACY,
  PICKUP_AND_DELIVERY_ROUTE,
  SUMMARY_AND_PAYMENT_ROUTE_LEGACY,
  SUMMARY_AND_PAYMENT_ROUTE
];

const routes = {
  HOMEPAGE_ROUTE,
  MY_ACCOUNT_ROUTES,
  PAYMENT_METHODS_ROUTE,
  PICKUP_AND_DELIVERY_ROUTE,
  SUMMARY_AND_PAYMENT_ROUTE,
  SHOPPING_CART_ROUTE,
  REST_ORDERITEM_UPDATE_ROUTE,
  HELP_CENTER_ROUTE,
  LEGAL_NOTICES_ROUTE,
  WARRRANTY_RETURN_POLICY_ROUTE
};

export default routes;
