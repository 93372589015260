import { ModalContext } from 'cat-ecommerce-alloy';
import { useCallback, useContext } from 'react';
import LoginRegistrationModal from '../../modals/LoginRegistrationModal';
import { setHelperCookies } from '../../utils';
const useLoginRegistration = () => {
  const { addModalWithOptions, closeAllModals } = useContext(ModalContext);
  const showLoginRegistrationModal = useCallback(
    details => {
      !!closeAllModals && closeAllModals();
      if (!!details) {
        setHelperCookies(details?.murl);
      }
      return addModalWithOptions(
        <LoginRegistrationModal
          closeAllModals={closeAllModals}
          details={details}
        />
      );
    },
    [addModalWithOptions, closeAllModals]
  );
  return { showLoginRegistrationModal };
};

export default useLoginRegistration;
