import PropTypes from 'prop-types';
// TODO: Can we reuse the BillingAddress component here somehow?
const AddressLabelContent = ({
  city = '',
  country,
  name,
  street,
  state = '',
  withoutBreaks = false,
  zipCode = '',
  upperCaseName = false,
  renderCustomElement
}) => (
  <>
    {renderCustomElement ? (
      renderCustomElement(name)
    ) : (
      <b
        data-cs-mask
        className={`text-start ${
          upperCaseName
            ? 'text-uppercase fw-bold text-univers u-fs--16 u-color--DarkGray'
            : ''
        }`}
      >
        {name}
      </b>
    )}

    {street ? (
      <span data-cs-mask data-testid="street">
        <br />
        {street}
      </span>
    ) : (
      ''
    )}
    <span data-cs-mask data-testid="state">
      {!withoutBreaks ? <br /> : ' '}
      {`${city ?? ''}${city && state ? ', ' : ''}${state ?? ''}${
        zipCode ? ` ${zipCode}` : ''
      }`}
    </span>

    {country ? (
      <span data-cs-mask data-testid="country">
        {!withoutBreaks && <br />}
        {country}
      </span>
    ) : (
      ''
    )}
  </>
);

AddressLabelContent.propTypes = {
  city: PropTypes.string,
  country: PropTypes.string,
  name: PropTypes.string,
  state: PropTypes.string,
  street: PropTypes.string,
  withoutBreaks: PropTypes.bool,
  zipCode: PropTypes.string,
  upperCaseName: PropTypes.bool
};

export default AddressLabelContent;
