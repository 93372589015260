import { selectEquipment } from '@app/store/myequipment/actions';
import EquipmentDuplicateBanner from './EquipmentDuplicateBanner';
import PropTypes from 'prop-types';
import { getEquipmentFromList } from '@app/store/myequipment/utils';
import { useDispatch, useSelector } from 'react-redux';
import { isServerError } from './utils';
import Conditional from '@app/components/common/Conditional';

const AddEquipmentDuplicateBanner = ({
  serialNumber,
  setNotifyDealer,
  resetState,
  onClose,
  isChild,
  selectedEquipment,
  formError,
  formReset
}) => {
  const dispatch = useDispatch();
  const equipmentList = useSelector(
    s => s.myEquipment?.equipments.myEquipmentList
  );
  const showBanner = isServerError(formError);
  const acceptDuplicateEquipment = () => {
    const onSuccess = () => onClose(null, isChild);
    dispatch(
      selectEquipment(
        getEquipmentFromList(equipmentList, {
          ...selectedEquipment,
          serialNumber
        }),
        null,
        onSuccess,
        null,
        selectedEquipment.seoUrl
      )
    );
  };

  const onWarningCancel = () => {
    resetState();
    formReset();
    setNotifyDealer(false);
  };

  return (
    <Conditional test={showBanner}>
      <EquipmentDuplicateBanner
        onAccept={acceptDuplicateEquipment}
        onCancel={onWarningCancel}
      />
    </Conditional>
  );
};
AddEquipmentDuplicateBanner.propTypes = {
  setNotifyDealer: PropTypes.func,
  resetState: PropTypes.func,
  serialNumber: PropTypes.string,
  onClose: PropTypes.func,
  isChild: PropTypes.bool,
  selectedEquipment: PropTypes.object,
  formError: PropTypes.object,
  formReset: PropTypes.func
};
export default AddEquipmentDuplicateBanner;
