import { SEARCH_RESULT_LIMIT } from '../../homepage/EquipmentSection/constants';
import { MyEquipmentSearchResults } from '@cat-ecom/pcc-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const AddEquipmentMultiSerialSearch = ({
  list,
  searchTerm,
  serialPrefixLabel = '',
  selectListItem
}) => {
  const isLucid = useSelector(
    state => state.featureFlag.PCCLucidSolrAddEquipmentComponentFlag
  );
  return (
    <MyEquipmentSearchResults
      data-testid="equipmentList"
      list={list}
      onSelectListItem={selectListItem}
      limit={
        isLucid
          ? SEARCH_RESULT_LIMIT.LUCID_MATCHING
          : SEARCH_RESULT_LIMIT.MATCHING
      }
      searchTerm={searchTerm}
      serialPrefixLabel={serialPrefixLabel}
    />
  );
};

AddEquipmentMultiSerialSearch.propTypes = {
  list: PropTypes.object,
  searchTerm: PropTypes.string,
  serialPrefixLabel: PropTypes.string,
  selectListItem: PropTypes.func
};
export default AddEquipmentMultiSerialSearch;
