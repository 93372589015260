import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ModalContext, SearchInput } from 'cat-ecommerce-alloy';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import EquipmentSuggestions from './EquipmentSuggestions';
import MoreSuggestions from './MoreSuggestions';
import Field from '@app/components/common/form/Field';
import SuggestionsLoader from './SuggestionsLoader';
import styles from './SearchSuggestions.module.scss';
import { validateSerialNumber } from '../../utils';
import SearchSuggestionsActions from './SearchSuggestionsActions';
import { useEquipmentSearchSuggestions } from '../hooks';
import {
  FORM_ADD_EQ_RESULT_SUBMITTED_NAME,
  STATUS_SUCCESS
} from '@app/constants/analyticsConstants';

const SearchSuggestions = ({ storeId, widgetIdentifier }) => {
  const {
    callSuggestionsSearchService,
    loading,
    error,
    setError,
    errorText,
    setErrorText,
    sendFormSubmittedEvent
  } = useEquipmentSearchSuggestions();
  const [t] = useTranslation();
  const [searchState, setSearchState] = useState({
    query: '',
    results: [],
    otherResults: [],
    success: false
  });
  const [inputValue, setInputValue] = useState('');

  const modalCtx = useContext(ModalContext);
  const [loadSuggestions, setLoadSuggestions] = useState(true);
  const [lengthError, setLengthError] = useState(true);

  const handleInputChange = ({ serialNumber }, { setFieldError }) => {
    const { isValid, key } = validateSerialNumber(serialNumber, false);

    if (!isValid) {
      setLengthError(true);
      setErrorText(t(key));
    } else {
      if (errorText) {
        setFieldError('serialNumber', errorText);
        setLengthError(true);
      }
      setLengthError(false);
      setLoadSuggestions(false);

      sendFormSubmittedEvent({
        formContent: serialNumber,
        formStatus: STATUS_SUCCESS,
        formName: FORM_ADD_EQ_RESULT_SUBMITTED_NAME
      });
    }
  };

  const updateSearchState = value => {
    setSearchState(value);
  };

  const resetState = setFieldValue => {
    setFieldValue('serialNumber', '');
    updateSearchState({
      query: '',
      results: [],
      otherResults: [],
      success: false
    });
    setLoadSuggestions(true);
    setLengthError(true);
    setError(false);
    setErrorText('');
  };

  const getSearchSuggestions = serialNumber => {
    if (inputValue.length < serialNumber.length) {
      setLoadSuggestions(true);
      setLengthError(false);
      setError(false);
      setErrorText('');
    }

    if (serialNumber !== inputValue) {
      setInputValue(serialNumber);
      const { isValid, key } = validateSerialNumber(serialNumber, true);

      if (!isValid) {
        setLengthError(true);
        key && setErrorText(t(key));
        modalCtx.hideOverflow();
      } else {
        setLengthError(false);
        modalCtx.showOverflow();

        callSuggestionsSearchService(serialNumber, updateSearchState);
      }
    }
  };

  const handleGetSearchSuggestions = e => {
    getSearchSuggestions(e.target.value);
  };

  const isSuccessfulSearch =
    searchState.success &&
    !lengthError &&
    !error &&
    (searchState.results.length > 0 || searchState.otherResults.length > 0);

  const shouldShowSuggestions = loadSuggestions && isSuccessfulSearch;
  const shouldShowMoreSuggestions = !loadSuggestions && isSuccessfulSearch;

  const getSuggestionsCommonProps = formikProps => ({
    resetState,
    formikProps,
    storeId,
    results: searchState.results,
    otherResults: searchState.otherResults,
    highlight: formikProps.values.serialNumber
  });

  return (
    <Formik initialValues={{ serialNumber: '' }} onSubmit={handleInputChange}>
      {formikProps => (
        <Form data-testid="search-suggestions-form">
          <div className={`${styles['search-form']}`}>
            <Field
              as={SearchInput}
              name="serialNumber"
              onClear={() => resetState(formikProps.setFieldValue)}
              className="AddEquipment--searchInput"
              placeholder=""
              autoComplete="off"
              hideLabel
              error={errorText}
              onChange={handleGetSearchSuggestions}
            />
          </div>

          {loading && <SuggestionsLoader />}

          {shouldShowSuggestions && (
            <EquipmentSuggestions
              {...getSuggestionsCommonProps(formikProps)}
              limit={8}
              widgetIdentifier={widgetIdentifier}
            />
          )}

          <SearchSuggestionsActions />

          {shouldShowMoreSuggestions && (
            <MoreSuggestions
              {...getSuggestionsCommonProps(formikProps)}
              limit={12}
              modalCtx={modalCtx}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SearchSuggestions;

SearchSuggestions.propTypes = {
  storeId: PropTypes.string.isRequired,
  widgetIdentifier: PropTypes.string
};
