export const NO_GET_NOTIFICATIONS_BEGIN = 'NO_GET_NOTIFICATIONS_BEGIN';
export const NO_GET_NOTIFICATIONS_SUCCESS = 'NO_GET_NOTIFICATIONS_SUCCESS';
export const NO_GET_NOTIFICATIONS_FAIL = 'NO_GET_NOTIFICATIONS_FAIL';
export const NO_SELECT_DCN = 'NO_SELECT_DCN';
export const NO_RESET_DCN = 'NO_RESET_DCN';
export const NOTIFICATION_FILTER_CHANGED = 'NOTIFICATION_FILTER_CHANGED';
export const NO_DISMISS_NOTIFICATIONS_BEGIN = 'NO_DISMISS_NOTIFICATIONS_BEGIN';
export const NO_DISMISS_NOTIFICATIONS_SUCCESS =
  'NO_DISMISS_NOTIFICATIONS_SUCCESS';
export const NO_DISMISS_NOTIFICATIONS_FAIL = 'NO_DISMISS_NOTIFICATIONS_FAIL';
