import PropTypes from 'prop-types';

const RadioField = ({
  as: Component,
  ariaLabel,
  onChange,
  setValue,
  ...props
}) => {
  const setRadioSelected = newVal => {
    setValue(newVal);
    if (onChange) {
      onChange(newVal);
    }
  };

  return (
    <Component
      aria-label={ariaLabel}
      data-testid="field-radio"
      {...props}
      setSelected={setRadioSelected}
    />
  );
};

RadioField.propTypes = {
  as: PropTypes.elementType.isRequired,
  ariaLabel: PropTypes.string,
  onChange: PropTypes.func,
  setValue: PropTypes.func.isRequired
};

export { RadioField };
