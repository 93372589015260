import { useCallback, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFitmentCheck as getFitmentCheckUtil } from '../components/common/analytics/analyticsUtils';
import {
  STATUS,
  FITMENT_CHECK,
  TIMEOUT_FORTY_SECONDS
} from '../constants/commonConstants';
import { setEquipmentFitment } from '../store/myequipment/actions';
import useHttp from './useHttp';
import endpoints from '../constants/endpoints';
import { getObjectAsQueryParams } from '../components/../utils';
import { replaceTokensInUrl } from '../components/../utils';

const useFitmentCheck = ({ namespace = '' } = {}) => {
  const dispatch = useDispatch();
  const serialMatchStoreKey = namespace
    ? `${namespace}SerialMatchPartNumbers`
    : 'serialMatchPartNumbers';
  const rangeMatchStoreKey = namespace
    ? `${namespace}RangeMatchPartNumbers`
    : 'rangeMatchPartNumbers';
  const serialMatchPartNumbers = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment[serialMatchStoreKey]
  );
  const rangeMatchPartNumbers = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment[rangeMatchStoreKey]
  );

  const serialNumber = useSelector(
    s => s.myEquipment?.equipments?.selectedEquipment?.serialNumber
  );
  const storeId = useSelector(s => s.common?.storeId);

  const [fitmentCheckStatus, setFitmentCheckStatus] = useState(STATUS.IDLE);
  const [fitmentError, setFitmentError] = useState();
  const [fitmentData, setFitmentData] = useState();

  const { invoke: callFitment } = useHttp({
    timeout: TIMEOUT_FORTY_SECONDS
  });

  const isFitmentLoading = fitmentCheckStatus === STATUS.PENDING;
  const didFitmentCallResolve =
    fitmentCheckStatus === STATUS.RESOLVED ||
    fitmentCheckStatus === STATUS.REJECTED;

  const invoke = useCallback(
    ({ partNumbers, fromPage = 'PDP' }) => {
      const config = {
        headers: { 'Cache-Control': 'no-cache' }
      };

      const queryParams = getObjectAsQueryParams({
        partNumbers: partNumbers,
        equipmentSerialNumber: serialNumber,
        fromPage
      });
      const url = replaceTokensInUrl(
        `${endpoints.PRODUCT_FITMENT_CHECK}?${queryParams}`,
        storeId
      );
      setFitmentError();
      setFitmentCheckStatus(STATUS.PENDING);
      callFitment({ url, options: { config } })
        .then(response => {
          dispatch(
            setEquipmentFitment({
              data: response,
              namespace
            })
          );
          setFitmentCheckStatus(STATUS.RESOLVED);
          setFitmentData(response);
        })
        .catch(error => {
          setFitmentCheckStatus(STATUS.REJECTED);
          setFitmentError(error);
        });
    },
    [callFitment, dispatch, namespace, serialNumber, storeId]
  );

  const getFitmentResult = useCallback(
    partNumber => {
      const partNumberNoDash = partNumber?.replace(/-/g, '');
      if (
        serialMatchPartNumbers?.has(partNumber) ||
        serialMatchPartNumbers?.has(partNumberNoDash)
      ) {
        return FITMENT_CHECK.FULL_CHECK;
      }
      if (
        rangeMatchPartNumbers?.has(partNumber) ||
        rangeMatchPartNumbers?.has(partNumberNoDash)
      ) {
        return FITMENT_CHECK.PARTIAL_CHECK;
      }
      return FITMENT_CHECK.NO_CHECK;
    },
    [rangeMatchPartNumbers, serialMatchPartNumbers]
  );

  const getFitmentCheck = useCallback(
    partNumber =>
      serialNumber
        ? getFitmentCheckUtil(partNumber, serialMatchPartNumbers)
        : '',
    [serialMatchPartNumbers, serialNumber]
  );

  return useMemo(
    () => ({
      fitmentCheckStatus,
      didFitmentCallResolve,
      getFitmentResult,
      getFitmentCheck,
      fitmentData,
      fitmentError,
      isFitmentLoading,
      invoke
    }),
    [
      didFitmentCallResolve,
      fitmentCheckStatus,
      fitmentData,
      fitmentError,
      getFitmentCheck,
      getFitmentResult,
      invoke,
      isFitmentLoading
    ]
  );
};

export default useFitmentCheck;
