import Dexie from 'dexie';
import { clearDealerCustomerInfo } from './dealerCustomerInfo';
import { clearPriceAndAvailabilityCacheData } from './priceAndAvailability';

export const DB_NAME = 'pcc_app_db';

export const db = new Dexie(DB_NAME);

db.version(3).stores({
  priceAndAvailability: 'key, expireTime',
  dealerCustomerInfo: 'key, expireTime',
  dealerStoreInfo: 'key, expireTime'
});

export function setUpIndexedDB() {
  clearDealerCustomerInfo();
  clearPriceAndAvailabilityCacheData();
}
