import { format, getDay } from 'date-fns';
import { isEmpty } from '@app/utils';
import {
  FULFILLMENT_TYPE_SHIPTO,
  FULFILLMENT_TYPE_DROPBOX,
  IS_FULFILLMENT_TYPE_DROPBOX_PASSED,
  IS_FULFILLMENT_TYPE_PICKUP_PASSED,
  IS_FULFILLMENT_TYPE_SHIPTO_PASSED
} from '../../account/shopping-preferences/constants';
import { DELIVERY } from './constants';

export const getIsOpenDay = (date, storeHours) => {
  const day = getDay(date);
  const findCurrentStoreDay = storeHours.find(
    storeHour => Number(storeHour.day) === day
  );
  return findCurrentStoreDay && !isEmpty(findCurrentStoreDay.value);
};
export const getFulfillmentTypeFlag = fulfillmentType => {
  let cutOffType = IS_FULFILLMENT_TYPE_PICKUP_PASSED;
  if (
    fulfillmentType === FULFILLMENT_TYPE_SHIPTO ||
    fulfillmentType === DELIVERY
  ) {
    cutOffType = IS_FULFILLMENT_TYPE_SHIPTO_PASSED;
  } else if (fulfillmentType === FULFILLMENT_TYPE_DROPBOX) {
    cutOffType = IS_FULFILLMENT_TYPE_DROPBOX_PASSED;
  }
  return cutOffType;
};
