import { AssociatedDealerCheckbox } from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import { SKIP_THIS_STEP_CHECKBOX_NAME } from '../../constants';

export const AssociatedDealerSkipThisStepCheckbox = ({
  showSkipThisStep = false
}) => {
  const [t] = useTranslation();

  return (
    <AssociatedDealerCheckbox
      name={SKIP_THIS_STEP_CHECKBOX_NAME}
      label={t('SHOP_PREF_SKIP_ADP')}
      fieldNote={t('SHOP_PREF_SKIP_ADP2')}
      checked={false}
      required={false}
      showCheckbox={showSkipThisStep}
    />
  );
};
