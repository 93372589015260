export const ORDER_STATUS_LIST = {
  SHIPPED_STATUS_RP: {
    label: 'SHIPPED_STATUS_RP',
    title: 'CAT_SHOPPING_PREFERENCES_PICKUP'
  },
  SHIPPED_STATUS_PU: {
    label: 'SHIPPED_STATUS_PU',
    title: 'CAT_SHOPPING_PREFERENCES_PICKUP'
  },
  PARTIALLY_READY_PICKUP: {
    label: 'PARTIALLY_READY_PICKUP',
    title: 'CAT_SHOPPING_PREFERENCES_PICKUP'
  },
  ORDER_APPROVED: {
    label: 'ORDER_APPROVED',
    title: 'NOTIFICATION_PREF_ORDER_APPROVAL_SUBHEADER',
    value: 'APR'
  },
  ORG_ORDER_REJECTED: {
    label: 'ORG_ORDER_REJECTED',
    title: 'NOTIFICATION_PREF_ORDER_APPROVAL_SUBHEADER',
    value: 'N'
  },
  CAT_ORD_STS_DELETED: {
    label: 'CAT_ORD_STS_DELETED',
    title: 'NOTIFICATION_PREF_ORDER_APPROVAL_SUBHEADER',
    value: 'AD'
  },
  MO_OrderStatus_V: {
    label: 'MO_OrderStatus_V',
    title: 'DELIVERY',
    value: 'PS'
  },
  SHIPPED_STATUS_FS: {
    label: 'SHIPPED_STATUS_FS',
    title: 'DELIVERY',
    value: 'FS'
  },
  SHIPPED_STATUS_DL: {
    label: 'SHIPPED_STATUS_DL',
    title: 'DELIVERY',
    value: 'DL'
  },
  QUEUED_FOR_PROCESSING: {
    label: 'QUEUED_FOR_PROCESSING',
    title: 'SHIPPED_STATUS_ON',
    value: 'H'
  },
  QUEUED_ORDER_NOT_PROCESSED_HEADING: {
    label: 'QUEUED_ORDER_NOT_PROCESSED_HEADING',
    title: 'SHIPPED_STATUS_ON',
    value: 'QX'
  },
  QUEUED_ORDER_PROCESSING_HEADING: {
    label: 'QUEUED_ORDER_PROCESSING_HEADING',
    title: 'SHIPPED_STATUS_ON',
    value: 'OR'
  },
  CANCELLED_STATE: {
    label: 'CANCELLED_STATE',
    title: 'SHIPPED_STATUS_ON',
    value: 'OC'
  },
  SHIPPED_STATUS_OH: {
    label: 'SHIPPED_STATUS_OH',
    title: 'SHIPPED_STATUS_ON',
    value: 'OH'
  },
  ORDER_PROBLEM: {
    label: 'ORDER_PROBLEM',
    title: 'SHIPPED_STATUS_ON',
    value: 'EX'
  },
  SHIPPED_STATUS_ON: {
    label: 'SHIPPED_STATUS_ON',
    title: 'SHIPPED_STATUS_ON',
    value: 'OR'
  },
  NOTIF_PREF_EXPIRED_APPROVAL_TITLE: {
    title: 'NOTIFICATION_PREF_ORDER_APPROVAL_SUBHEADER',
    label: 'NOTIF_PREF_EXPIRED_APPROVAL_TITLE',
    value: 'E'
  },
  SUBMITED_APPROVAL: {
    title: 'NOTIFICATION_PREF_ORDER_APPROVAL_SUBHEADER',
    label: 'SUBMITTED_FOR_APPROVAL',
    value: 'W'
  },
  SHIPPED_OP: {
    label: 'SHIPPED_STATUS_OP',
    title: 'NOTIFICATION_PREF_ORDER_APPROVAL_SUBHEADER',
    value: 'OP',
    orderPlaced: true
  },
  TITLE_QUOTE_CONFIRMATION: {
    label: 'TITLE_QUOTE_CONFIRMATION',
    title: 'NOTIFICATION_PREF_SUBHEADER'
  },
  NOTIF_PREF_EXPIRING_SOON_TITLE: {
    label: 'NOTIF_PREF_EXPIRING_SOON_TITLE',
    title: 'NOTIFICATION_PREF_SUBHEADER'
  },
  EXPIRING_TODAY_QUOTE: {
    title: 'NOTIFICATION_PREF_SUBHEADER',
    label: 'EXPIRING_TODAY_QUOTE',
    value: 'NEW',
    isToday: true
  },
  EXPIRED_QUOTE: {
    title: 'NOTIFICATION_PREF_SUBHEADER',
    label: 'EXPIRED_QUOTE',
    value: 'EXP'
  }
};

export const ORDER_STATUS_HEADING_SORTING_ORDER = {
  CAT_SHOPPING_PREFERENCES_PICKUP: 0,
  DELIVERY: 1,
  SHIPPED_STATUS_ON: 2,
  NOTIFICATION_PREF_ORDER_APPROVAL_SUBHEADER: 3,
  NOTIFICATION_PREF_SUBHEADER: 4
};

export const NOTIFICATION_ORDER_STATUS = {
  PICKED_UP: 'PU',
  READY_FOR_PICKUP: 'RP',
  PARTIALLY_READY_FOR_PICKUP: 'PA',
  PARTIALLY_SHIPPED: 'PS',
  FULLY_SHIPPED: 'FS',
  FULLY_DELIVERD: 'DL',
  ORDER_PROCESSING: 'OR',
  CANCELLED: 'OC',
  ORDER_PROBLEM: 'EX',
  ON_HOLD: 'OH',
  QUEUED_ORDER_NOT_PROCESSING: 'QX',
  QUEUED_FOR_PROCESSING: 'H',
  EXPIRED_TODAY: 'NEW',
  EXPIRED_QUOTE: 'EXP',
  EXPIRED_APPROVAL: 'E',
  SUBMIT_FOR_APPROVAL: 'W',
  ORDER_PLACED: 'OP',
  ORDER_APPROVED: 'APR',
  ORDER_REJECTED: 'N'
};
