import { isEmpty } from '@app/utils';
import sortBy from 'lodash/sortBy';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { FLUID_PART_QUANTITY } from '../../components/pages/RepairOptions/constants';
import { REMAN } from '../../constants/productAssociation';
import { normalizeError } from '../exception/utils';

const getPriceItem = (reactKeys, curr, priceAndAvailability) =>
  (isEmpty(reactKeys) ||
    (Array.isArray(reactKeys) && reactKeys.includes(curr.reactKey))) &&
  !curr.isConfigurationDropdown &&
  priceAndAvailability?.find(item => {
    const singleDealerPartNumber =
      curr.dealerParts?.length === 1 && curr.dealerParts[0].partNumber;
    if (
      item?.partNumber === singleDealerPartNumber ||
      item?.partNumber === curr.selectedDealerPart?.partNumber ||
      item?.partNumber === curr.partNumber
    ) {
      return item;
    }
  });

export const mergePriceAndAvailability = ({
  reactKeys,
  partsList = [],
  priceAndAvailability = []
}) =>
  partsList.reduce((acc, curr) => {
    const priceItem = getPriceItem(reactKeys, curr, priceAndAvailability);

    /**
     * If a partsList item did not have a result in the price and availability response
     * then we return the partsList item unaltered.
     */
    if (!priceItem) {
      return acc.concat({
        ...curr,
        isLineItemLoading: false
      });
    }

    /**
     * If the price and availability response has replacement parts for
     * the partsList item, then we replace the topLevel part with all of its
     * replacement parts.
     */
    if ((priceItem.replacementParts || []).length > 0) {
      return acc.concat(
        priceItem.replacementParts.map(replacement => ({
          ...replacement,
          taskDescription: curr.taskDescription ? curr.taskDescription : null,
          reactKey: uuidv4(),
          selectedQty: replacement.quantity,
          isLineItemLoading: false
        }))
      );
    } else {
      /**
       * If the server has used an items replacement parts to increment another top-level
       * partsList item, and there are no replacement parts remaining, the back-end
       * will use the `removeFromPartsList` flag to let us know that we should not be
       * including this part in the list anymore.
       */
      if (priceItem.removeFromPartsList) {
        return acc;
      }

      return acc.concat({
        ...curr,
        ...priceItem,
        quantity: curr.quantity,
        selectedQty: priceItem.quantity || curr.selectedQty,
        isLineItemLoading: false
      });
    }
  }, []);

export const mergeOrders = (state, orderItems = []) => {
  const {
    lastOrders,
    selectedPartsList,
    selectedRecommendedTools,
    selectedOptionalParts
  } = state;
  const mergedPartsArr = [
    ...selectedPartsList,
    ...selectedRecommendedTools,
    ...selectedOptionalParts
  ];
  return lastOrders?.map(item => {
    const orderItem = orderItems?.find(
      order => order?.orderItemId === item?.orderItemId
    );
    const parts = mergedPartsArr?.find(part => {
      if (part.isGenericPart) {
        if (
          part.isDealerPartSelected &&
          part?.selectedDealerPart?.partNumber === orderItem?.comments
        ) {
          return part;
        } else if (part?.partNumber === orderItem?.comments) {
          return part;
        }
      } else if (
        part?.partNumber === orderItem?.partNumber &&
        !part.isConfigurationDropdown
      ) {
        return part;
      }
    });

    return { ...parts, cartQuantity: parseInt(parts?.selectedQty) };
  });
};

export const populateHeaderSelections = (
  partHeaders,
  groupNumber = '',
  isPMKit = false,
  isMixedResponse = false
) => {
  let selectedPartsImages = [];
  let selectedPartsList = [];
  let selectedRecommendedTools = [];
  let selectedOptionalParts = [];
  let selectedGroupNumber = '';

  const keys = Object.keys(partHeaders);
  if (isPMKit) {
    // PM Kit bundle data
    keys.forEach(key => {
      const {
        partsList,
        partsImages,
        recommendedTools,
        optionalParts,
        taskDescription,
        partRangeIdentifiers
      } = partHeaders[key];
      const stepId = uuidv4();

      const isConfigurationStep = partRangeIdentifiers?.length > 1;
      const configurationDropdownProps = {
        customCellClassNames: {
          image: 'd-none',
          name: 'd-none',
          mobileNoPriceCell: 'd-none',
          mobilePriceCell: 'd-none'
        },
        customCellProperties: {
          quantity: {
            colSpan: 3
          },
          imageMobile: {
            colSpan: 2
          }
        }
      };

      if (partsList.length) {
        let firstPartRangeIdentifiers = [];
        const arrayPartRangeIdentifiers = isConfigurationStep
          ? partsList.map(part => {
              return part.partRangeIdentifier;
            })
          : null;
        const lastPartRangeIdentifierIndexes = isConfigurationStep
          ? partRangeIdentifiers.map(name => {
              return arrayPartRangeIdentifiers.lastIndexOf(name);
            })
          : null;

        partsList.map((part, index) => {
          let isFirstPartInConfiguration = true;
          if (
            part?.partRangeIdentifier &&
            firstPartRangeIdentifiers.includes(part?.partRangeIdentifier)
          ) {
            isFirstPartInConfiguration = false;
          } else {
            firstPartRangeIdentifiers.push(part?.partRangeIdentifier);
          }

          const isLastPartInConfiguration =
            lastPartRangeIdentifierIndexes?.includes(index);

          if (index === 0) {
            if (isConfigurationStep) {
              selectedPartsList.push({
                reactKey: uuidv4(),
                selectedQty: part.fluid ? FLUID_PART_QUANTITY : part.quantity,
                taskDescription,
                isDealerPartSelected: false,
                selectedDealerPart: {},
                isPartsListEmpty: false,
                optionalParts,
                partRangeIdentifiers,
                isFirstPartInConfiguration,
                stepId,
                ...configurationDropdownProps,
                isConfigurationDropdown: true,
                partNumber: part.partNumber
              });
            }
            selectedPartsList.push({
              ...part,
              reactKey: uuidv4(),
              selectedQty: part.fluid ? FLUID_PART_QUANTITY : part.quantity,
              taskDescription:
                partRangeIdentifiers?.length > 1 ? '' : taskDescription,
              isDealerPartSelected: false,
              selectedDealerPart: {},
              isPartsListEmpty: false,
              optionalParts,
              partRangeIdentifiers,
              stepId,
              isFirstPartInConfiguration,
              isLastPartInConfiguration,
              customCellClassNames: {
                indentation: isConfigurationStep && 'border-0',
                image: isConfigurationStep && 'd-none',
                name: isConfigurationStep && 'd-none',
                checkPrice: isConfigurationStep && 'd-none',
                cartBadge: isConfigurationStep && 'd-none'
              },
              customCellProperties: {
                quantity: {
                  colSpan: isConfigurationStep ? 12 : 1
                },
                imageMobile: {
                  colSpan: isConfigurationStep ? 2 : 1
                }
              }
            });
          } else if (index === partsList.length - 1) {
            selectedPartsList.push({
              ...part,
              reactKey: uuidv4(),
              selectedQty: part.fluid ? FLUID_PART_QUANTITY : part.quantity,
              isDealerPartSelected: false,
              selectedDealerPart: {},
              isPartsListEmpty: false,
              isFirstPartInConfiguration,
              isLastPartInConfiguration,
              optionalParts,
              stepId,
              partRangeIdentifiers,
              customCellClassNames: isConfigurationStep
                ? {
                    indentation: 'border-0',
                    image: 'd-none',
                    imageMobile: 'd-none',
                    name: 'd-none',
                    checkPrice: 'd-none',
                    cartBadge: 'd-none',
                    mobileNoPriceCell: 'd-none',
                    mobilePriceCell: 'd-none',
                    quantity: 'p-2 pe-0'
                  }
                : null,
              customCellProperties: {
                quantity: {
                  colSpan: isConfigurationStep ? 12 : 1
                },
                imageMobile: {
                  colSpan: isConfigurationStep ? 2 : 1
                }
              }
            });
          } else {
            selectedPartsList.push({
              ...part,
              reactKey: uuidv4(),
              selectedQty: part.fluid ? FLUID_PART_QUANTITY : part.quantity,
              isDealerPartSelected: false,
              selectedDealerPart: {},
              isPartsListEmpty: false,
              isFirstPartInConfiguration,
              isLastPartInConfiguration,
              optionalParts,
              partRangeIdentifiers,
              stepId,
              customCellClassNames: isConfigurationStep
                ? {
                    indentation: 'border-0',
                    image: 'd-none',
                    imageMobile: 'd-none',
                    name: 'd-none',
                    checkPrice: 'd-none',
                    cartBadge: 'd-none',
                    mobileNoPriceCell: 'd-none',
                    mobilePriceCell: 'd-none',
                    quantity: 'pe-0'
                  }
                : null,
              customCellProperties: {
                quantity: {
                  colSpan: isConfigurationStep ? 12 : 1
                },
                imageMobile: {
                  colSpan: isConfigurationStep ? 2 : 1
                }
              }
            });
          }
        });
      } else {
        let additionalConfigurationProps = {};
        if (optionalParts.length && isConfigurationStep) {
          additionalConfigurationProps = {
            ...configurationDropdownProps,
            isConfigurationDropdown: true
          };
        }
        selectedPartsList.push({
          name: '',
          reactKey: uuidv4(),
          taskDescription,
          isPartsListEmpty: true,
          isDealerPartSelected: false,
          optionalParts,
          partRangeIdentifiers,
          ...additionalConfigurationProps
        });
      }

      const pmkitRemanParts = getRemanParts(selectedPartsList);
      optionalParts.forEach(part => {
        if (isConfigurationStep === false) {
          selectedOptionalParts.push(
            {
              ...part,
              selectedQty: part.quantity
            },
            ...pmkitRemanParts
          );
        }
      });

      recommendedTools.map(part =>
        selectedRecommendedTools.push({
          ...part,
          selectedQty: part.quantity
        })
      );

      partsImages.map(part => selectedPartsImages.push(part));
    });
    if (isMixedResponse) {
      selectedPartsList = sortBy(selectedPartsList, 'partId');
    }
  } else {
    // SSRO Bundle data
    // Automatically select list if it's the only option
    if (keys.length === 1) {
      selectedGroupNumber = keys[0];
    } else {
      selectedGroupNumber = groupNumber;
    }

    if (selectedGroupNumber) {
      const { partsImages, partsList, recommendedTools, optionalParts } =
        partHeaders[selectedGroupNumber];

      selectedPartsList = partsList.map(part => ({
        ...part,
        selectedQty: part.quantity
      }));
      const ssroRemanParts = getRemanParts(selectedPartsList);
      selectedOptionalParts = optionalParts.map(part => ({
        ...part,
        selectedQty: part.quantity
      }));
      selectedOptionalParts.push(...ssroRemanParts);
      selectedRecommendedTools = recommendedTools.map(part => ({
        ...part,
        selectedQty: part.quantity
      }));

      selectedPartsImages = [...partsImages];
    }
  }

  return {
    selectedPartsImages,
    selectedPartsList,
    selectedRecommendedTools,
    selectedOptionalParts,
    selectedGroupNumber
  };
};
export const getRemanParts = list => {
  const merchandisingAssociationList = list
    ?.filter(part => part.merchandisingAssociation)
    .map(item => item.merchandisingAssociation)
    .flat();
  const remanParts = merchandisingAssociationList
    .filter(item => item.associationType === REMAN)
    .map(item => {
      return {
        ...item,
        selectedQty: 1,
        partNumber: item.itemPartNumber
      };
    });
  return remanParts;
};
export const generateSelectedDealerPart = ({
  partsList,
  reactKey,
  selectedDealerPart = {}
}) => {
  if (isEmpty(selectedDealerPart)) {
    return partsList.map(part => {
      return part.reactKey === reactKey
        ? {
            ...part,
            selectedDealerPart,
            enableAddToCart: false,
            availability: null,
            unformattedTotalPrice: null,
            isDealerPartSelected: false
          }
        : part;
    });
  }

  return partsList.map(part => {
    const selectedDealerPartLen = Object.keys(selectedDealerPart).length;
    return part.reactKey === reactKey
      ? {
          ...part,
          selectedDealerPart,
          isDealerPartSelected: selectedDealerPartLen ? true : false,
          enableAddToCart: selectedDealerPartLen ? part.enableAddToCart : false,
          availability: selectedDealerPartLen ? part.availability : []
        }
      : part;
  });
};

export const generateSelectedConfigurationPart = (
  partsList,
  selectedConfiguration = '',
  reactKey,
  stepId,
  isLineItemLoading = false
) => {
  let selectedPartsList = [];
  partsList.map(part => {
    if (part.stepId === stepId && part?.partRangeIdentifiers?.length > 1) {
      const showBorder = selectedConfiguration ? 'border-0' : '';
      if (part.reactKey === reactKey) {
        selectedPartsList.push({
          ...part,
          customCellClassNames: {
            image: 'd-none',
            name: 'd-none',
            mobileNoPriceCell: 'd-none',
            mobilePriceCell: 'd-none',
            imageMobile:
              part?.isFirstPartInConfiguration && selectedConfiguration
                ? 'border-0'
                : null,
            indentation: showBorder,
            quantity: showBorder,
            checkPrice: showBorder,
            cartBadge: showBorder,
            availability: showBorder,
            totalPrice: showBorder,
            addToCart: showBorder
          },
          customCellProperties: {
            quantity: {
              colSpan: 3
            },
            imageMobile: {
              colSpan: 2
            }
          },
          selectedConfiguration,
          isConfigurationSelected: true
        });
      } else if (part.partRangeIdentifier === selectedConfiguration) {
        selectedPartsList.push({
          ...part,
          isLineItemLoading,
          rowProperties: {},
          isConfigurationSelected: true,
          customCellClassNames: {
            indentation: part?.isLastPartInConfiguration ? null : 'border-0',
            quantity: part?.isLastPartInConfiguration ? null : 'border-0',
            imageMobile: part?.isLastPartInConfiguration ? null : 'border-0',
            image: 'd-none',
            name: 'd-none',
            checkPrice: 'd-none',
            cartBadge: 'd-none',
            mobileNoPriceCell: 'd-none',
            mobilePriceCell: 'd-none',
            availability: 'd-none',
            totalPrice: 'd-none',
            addToCart: 'd-none'
          }
        });
      } else {
        selectedPartsList.push({
          ...part,
          rowProperties: {},
          isConfigurationSelected: false,
          customCellClassNames: {
            indentation: 'border-0'
          }
        });
      }
    } else {
      selectedPartsList.push({
        ...part
      });
    }
  });
  return selectedPartsList;
};

export const enrichPartsLists = lists => {
  const keys = Object.keys(lists);
  return keys.reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: {
        ...lists[cur],
        partsImages: lists[cur].partsImages.map(img => ({
          ...img,
          reactKey: uuidv4()
        })),
        partsList: lists[cur].partsList.map(part => ({
          ...part,
          reactKey: uuidv4()
        })),
        recommendedTools: lists[cur].recommendedTools.map(tool => ({
          ...tool,
          reactKey: uuidv4()
        })),
        optionalParts: lists[cur].optionalParts
          ? lists[cur].optionalParts.map(part => ({
              ...part,
              reactKey: uuidv4()
            }))
          : []
      }
    }),
    {}
  );
};

export const fetchCartQuantity = (selectedPartsList, orderItems) => {
  const { ssId } = queryString.parse(window.location.href);
  return selectedPartsList.map(part => {
    const orders = orderItems?.find(
      order =>
        (order.partNumber === part.partNumber ||
          order.comments === part.partNumber) &&
        order?.orderItemExtendAttribute?.find(
          attribute =>
            attribute.attributeName === 'selfServiceOptionId' &&
            attribute.attributeValue === ssId
        )
    );
    return { ...part, qtyInCart: parseInt(orders?.quantity) || 0 };
  });
};

export const normalizeServerError = payload => {
  const err = normalizeError(payload);
  const errorObj = Array.isArray(payload.errors)
    ? payload.errors[0]
    : payload.errors;
  err.code = errorObj?.errorCode;
  return err;
};

export const mergeSOSCodeForParts = (partsList, sosData, isPMKit) => {
  const finalPartList = partsList.map(item => {
    const sosDataKeys = Object.keys(sosData);
    let sosCode;
    if (isPMKit) {
      sosCode = item.sosCode;
    }
    sosDataKeys.map(data => {
      if (
        data === item.partNumber ||
        (item.isDealerPartSelected &&
          data === item.selectedDealerPart.partNumber) ||
        (!item.isDealerPartSelected &&
          item.dealerParts?.length > 0 &&
          data === item.dealerParts[0]?.partNumber)
      ) {
        sosCode = sosData[data]?.sosResponseVoList[0]?.sourceOfSupply;
      }
    });
    return {
      ...item,
      sosCode: sosCode
    };
  });
  return finalPartList;
};

export const displayContactDealer = (partsList, selectedPartList) => {
  const updatedPartList = partsList.map(item => {
    if (item.reactKey !== selectedPartList.reactKey) {
      return item;
    }
    const selectedPartsNumber = Object.keys(selectedPartList);
    let enableAddToCart;
    selectedPartsNumber.forEach(data => {
      if (
        data === item.partNumber ||
        (item.isDealerPartSelected &&
          data === item.selectedDealerPart.partNumber) ||
        (!item.isDealerPartSelected &&
          item.dealerParts?.length > 0 &&
          data === item.dealerParts[0]?.partNumber)
      ) {
        enableAddToCart = false;
      }
    });
    return {
      ...item,
      enableAddToCart: enableAddToCart,
      availability: [
        {
          availabilityMessage: '',
          quantityAvailable: '',
          showContactDealerPopUp: false
        }
      ]
    };
  });
  return updatedPartList;
};

export const refreshCartHeader = () => {
  // eslint-disable-next-line no-undef
  wc.render.updateContext('MiniShoppingCartContext', {
    status: 'load',
    cartRefresh: true
  });
};

export const getSelectedLines = (partNumber, selectedPartsList, reactKey) => {
  return selectedPartsList.map(item => {
    if (item.partNumber === partNumber && item.reactKey === reactKey) {
      item.isLineItemLoading = true;
      item.isLineItemError = false;
    }

    return item;
  });
};
