import PropTypes from 'prop-types';
import { Popup, WarningOutlineIcon } from 'cat-ecommerce-alloy';
import styles from './WarningPopup.module.scss';
import { Fragment } from 'react';

const WarningPopup = ({ warnings, initiator, popperOptions }) => {
  const warningSection = (title, message) => {
    return (
      <section className="d-flex">
        <WarningOutlineIcon
          className={`me-3 ${styles['warning-button-icon']}`}
        />

        <p className={`m-0 ${styles['popup-text']}`}>
          <strong className="text-uppercase text-xs u-color--ExtraDarkGray me-1">
            {title}:
          </strong>
          <span className="text-xs u-color--ExtraDarkGray">{message}</span>
        </p>
      </section>
    );
  };
  const processMessage = message => {
    const linkText = 'www.P65Warnings.ca.gov';
    if (message.includes(linkText)) {
      const link = (
        <a
          className="text-nowrap"
          href={`//${linkText}`}
          target="_blank"
          rel="noreferrer"
        >
          {linkText}
        </a>
      );
      let processedString = message;
      const endsWithPeriod = message.endsWith('.');
      if (endsWithPeriod) {
        processedString = message.replace(`${linkText}.`, '');
      } else {
        processedString = message.replace(linkText, '');
      }
      return (
        <>
          <span className="text-xs u-color--ExtraDarkGray">
            {processedString}
          </span>
          {link}
          {endsWithPeriod && (
            <span className="text-xs u-color--ExtraDarkGray">.</span>
          )}
        </>
      );
    }
    return message;
  };

  const productWarningContent = () => (
    <div>
      {warnings.map((w, i) => (
        <Fragment key={w.message}>
          {i > 0 && <hr />}
          {warningSection(w.title, processMessage(w.message))}
        </Fragment>
      ))}
    </div>
  );

  return (
    <Popup
      contents={productWarningContent()}
      initiator={initiator}
      popperOptions={popperOptions}
      hideCloseButton
    />
  );
};

WarningPopup.propTypes = {
  warnings: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string
    })
  ),
  initiator: PropTypes.element,
  popperOptions: PropTypes.shape({
    placement: PropTypes.string,
    modifiers: PropTypes.arrayOf(PropTypes.object)
  })
};
export default WarningPopup;
