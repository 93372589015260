import { AssociatedDealerOrderType } from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import Conditional from '@app/components/common/Conditional';
import { StoreLocation } from '../../types';
import { ORDER_TYPE_RADIO_NAME } from '../../constants';
import PropTypes from 'prop-types';

export const AssociatedDealerOrderTypeWrapper = ({
  selectedStoreLocation,
  selectedValue,
  onChange
}) => {
  const [t] = useTranslation();
  const orderTypeLabel = t('CAT_SHOPPING_PREFERENCES_ORDER_TYPE');
  const stock = t('CAT_MA_STOCK');
  const standard = t('CAT_MA_STANDARD');

  const ORDER_TYPE_STOCK = 'STK';
  const ORDER_TYPE_STANDARD = 'STD';

  const orderTypeList = selectedStoreLocation?.orderType || [];
  let hasDefaultType = false;
  const getOrderType = (orderType, label) => {
    const defaultObj = { default: false, value: orderType, label };
    let orderTypeObj =
      orderTypeList?.find(data => data.value === orderType) || defaultObj;
    if (orderTypeObj.default) {
      hasDefaultType = true;
    }

    return {
      ...orderTypeObj,
      default: selectedValue === orderType ? true : orderTypeObj.default,
      label: orderTypeObj.label !== '' ? orderTypeObj.label : label
    };
  };

  const stockOrderType = getOrderType(ORDER_TYPE_STOCK, stock);
  let standardOrderType = getOrderType(ORDER_TYPE_STANDARD, standard);
  // if the order type doesn't come with any default value from the service,
  // assign standard order type as default.
  if (!selectedValue && !hasDefaultType) {
    standardOrderType.default = true;
  }

  return (
    <Conditional test={orderTypeList?.length > 1}>
      <AssociatedDealerOrderType
        name={ORDER_TYPE_RADIO_NAME}
        selectedStoreLocation={selectedStoreLocation}
        stock={stockOrderType}
        standard={standardOrderType}
        label={orderTypeLabel}
        onChange={onChange}
      />
    </Conditional>
  );
};

AssociatedDealerOrderTypeWrapper.propTypes = {
  selectedStoreLocation: StoreLocation,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func
};
