import PropTypes from 'prop-types';
import { getIconSizeNumber } from '../utils';

const ReorderPartsIcon = ({ size }) => {
  const sizeNumber = getIconSizeNumber(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={sizeNumber}
      height={sizeNumber}
      viewBox={`0 0 ${sizeNumber} ${sizeNumber}`}
      fill="none"
    >
      <g clipPath="url(#clip0_1_5054)">
        <rect
          x="0.75"
          y="0.75"
          width="22.5"
          height="15.5"
          rx="2.25"
          stroke="black"
          strokeWidth="1.5"
        />
        <line
          x1="12.75"
          y1="17"
          x2="12.75"
          y2="20"
          stroke="black"
          strokeWidth="1.5"
        />
        <mask id="path-3-inside-1_1_5054" fill="white">
          <rect x="3" y="4" width="11" height="4" rx="1" />
        </mask>
        <rect
          x="3"
          y="4"
          width="11"
          height="4"
          rx="1"
          stroke="black"
          strokeWidth="3"
          mask="url(#path-3-inside-1_1_5054)"
        />
        <mask id="path-4-inside-2_1_5054" fill="white">
          <rect x="5" y="9" width="11" height="4" rx="1" />
        </mask>
        <rect
          x="5"
          y="9"
          width="11"
          height="4"
          rx="1"
          stroke="black"
          strokeWidth="3"
          mask="url(#path-4-inside-2_1_5054)"
        />
        <path
          d="M17 20H8"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M19 5H16"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M20 8H17"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M21 11H18"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_5054">
          <rect width="24" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

ReorderPartsIcon.propTypes = {
  size: PropTypes.string
};

export default ReorderPartsIcon;
