import PropTypes from 'prop-types';
import {
  AlloyButton,
  NonReturnableIcon,
  RemanufacturedIcon,
  WarningOutlineIcon
} from 'cat-ecommerce-alloy';
import { useTranslation } from 'react-i18next';
import LineAttributeItem from './LineAttributeItem';
import styles from './LineItemAttributes.module.scss';
import WarningPopup from '../../../../../../common/WarningPopup';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import SSROLineItems from '../SSROLineItems';

const LineItemAttributes = ({ item }) => {
  const [t] = useTranslation();
  const {
    orderItemExtendAttribute: {
      hoseWarning,
      prop65,
      nonReturnablePartNumber,
      remanPartNumber
    } = {}
  } = item;
  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);
  const placement = !isMD ? 'bottom' : 'right';
  const popperOptions = {
    placement: placement,
    modifiers: [
      {
        name: 'flip',
        options: {
          rootBoundary: 'document',
          fallbackPlacements: ['bottom']
        }
      },
      {
        name: 'offset',
        options: {
          offset: [10, 10]
        }
      }
    ]
  };
  const productWarningInitiator = (
    <AlloyButton
      buttonType="ghost"
      size="small"
      className={`p-0 my-1 d-flex align-items-center text-uppercase text-sans-serif text-start d-print-none ${styles['description-decorator-button']}`}
    >
      <WarningOutlineIcon className="me-2" />
      {t('PRODUCT_WARNINGS')}
    </AlloyButton>
  );
  const getWarnings = () => {
    let warnings = [];
    if (prop65) {
      warnings.push({
        title: t('INACTIVITY_WARNING_DIALOG_WARNING_ICON'),
        message: prop65
      });
    }
    if (hoseWarning) {
      warnings.push({
        title: t('CAT_HOSE_WARNING_HEADER'),
        message: hoseWarning
      });
    }
    return warnings;
  };

  return (
    <>
      {nonReturnablePartNumber && (
        <LineAttributeItem
          icon={NonReturnableIcon}
          title={t('NON_RETURNABLE_PART')}
        />
      )}
      {remanPartNumber && (
        <LineAttributeItem
          icon={RemanufacturedIcon}
          title={t('REMANUFACTURED_PART')}
        />
      )}
      <SSROLineItems item={item} />
      {(prop65 || hoseWarning) && (
        <div>
          <WarningPopup
            warnings={getWarnings()}
            initiator={productWarningInitiator}
            popperOptions={popperOptions}
          />
          <span className={`${styles['attribute-text']} d-none d-print-block`}>
            <LineAttributeItem
              icon={WarningOutlineIcon}
              title={t('PRODUCT_WARNINGS')}
            />
          </span>
        </div>
      )}
    </>
  );
};
LineItemAttributes.propTypes = {
  item: PropTypes.shape({
    orderItemExtendAttribute: PropTypes.shape({
      hoseWarning: PropTypes.string,
      prop65: PropTypes.string,
      unitWeight: PropTypes.string
    })
  })
};

export default LineItemAttributes;
