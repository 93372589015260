import {
  CLEAR_ALL_ERRORS,
  CLEAR_DOMAIN,
  CLEAR_ERROR,
  SET_ERROR
} from './constants';
import initialStore from '../initialStore';

const errorReducer = (state = initialStore.errors, action) => {
  const { type, payload } = action;
  const { domain, component, error } = payload || {};

  switch (type) {
    case CLEAR_ALL_ERRORS: {
      return {};
    }
    case CLEAR_ERROR: {
      return {
        ...state,
        [domain]: {
          ...state[domain],
          [component]: null
        }
      };
    }
    case CLEAR_DOMAIN: {
      return {
        ...state,
        [domain]: {}
      };
    }
    case SET_ERROR: {
      return {
        ...state,
        [domain]: {
          ...state[domain],
          [component]: {
            ...error
          }
        }
      };
    }
    default:
      return state;
  }
};

export default errorReducer;
