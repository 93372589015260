import { STATUS } from '@app/constants/commonConstants';
import { getTaxDetail } from '@app/store/taxDetail/actions';
import queryString from 'query-string';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useGetTaxDetail = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const taxDetail = useSelector(s => s?.taxDetail);
  const {
    taxDetalStatus,
    dealerFields,
    address,
    logonId,
    firstName,
    lastName,
    companyName,
    universalCustomerId,
    isNotAuthorizedUser
  } = taxDetail ?? {};
  const isLoading = STATUS.PENDING === taxDetalStatus;
  const isError = STATUS.REJECTED === taxDetalStatus;
  const searchParams = useLocation().search;
  const { userId, storeId } = queryString.parse(searchParams);
  const areTranslationsLoaded = useSelector(
    state => state.common?.areTranslationsLoaded
  );

  useEffect(() => {
    if (userId && !!areTranslationsLoaded) {
      dispatch(getTaxDetail(userId, storeId));
    }
  }, [areTranslationsLoaded]);

  const checkValue = value => (!!value ? value : t('NOT_PROVIDED'));
  const constructUserName = () => {
    let userName = '';
    if (!!firstName) {
      userName += ' ' + firstName;
    }
    if (!!lastName) {
      userName += ' ' + lastName;
    }
    if (!userName) {
      return t('NOT_PROVIDED');
    }
    return userName;
  };

  const constructAddress = () => {
    const tempAddress =
      !!address &&
      [
        'line1',
        'line2',
        'line3',
        'city',
        'state',
        'country',
        'zipCode'
      ]?.reduce(
        (currentValue, key) =>
          !!address[key] ? `${currentValue} ${address[key]}` : currentValue,
        ''
      );

    if (!tempAddress) {
      return t('NOT_PROVIDED');
    }
    return tempAddress;
  };

  const TaxDetails = useMemo(
    () => [
      {
        id: 1,
        label: t('TAX_DETAILS_FEDERAL_TAX_TYPE'),
        value: checkValue(dealerFields?.taxIdOption)
      },
      {
        id: 2,
        label: t('TAX_DETAILS_TAX_NUMBER'),
        value: checkValue(dealerFields?.taxId)
      },
      {
        id: 3,
        label: t('STATE_TAX_ID'),
        value: checkValue(dealerFields?.stateTaxId)
      },
      {
        id: 4,
        label: t('NEIGHBORHOD'),
        value: checkValue(address?.neighborhood)
      }
    ],
    [dealerFields, address]
  );
  const userInformation = useMemo(
    () => [
      { id: 1, label: t('LOGON_ID'), value: checkValue(logonId) },
      {
        id: 2,
        label: t('MA_NAME'),
        value: constructUserName()
      }
    ],
    [logonId, firstName, lastName]
  );
  const companyInformation = useMemo(
    () => [
      {
        id: 1,
        label: t('CAT_PI_DEALER_REGISTRATION_COMPANY'),
        value: checkValue(companyName)
      },
      { id: 2, label: t('CCID'), value: checkValue(universalCustomerId) }
    ],
    [companyName]
  );
  const contactInformation = useMemo(
    () => [
      {
        id: 1,
        label: t('CAT_EMAIL_EMAIL1'),
        value: checkValue(address?.email)
      },
      {
        id: 2,
        label: t('CAT_EMAIL_PHONE1'),
        value: checkValue(address?.phone)
      },
      {
        id: 3,
        label: t('BUY_APP_DETAIL_SECTION_ADDRESS'),
        isAddress: true,
        value: constructAddress()
      }
    ],
    [address]
  );

  return {
    TaxDetails,
    userInformation,
    companyInformation,
    contactInformation,
    isLoading,
    isError,
    userName: logonId ?? '',
    isNotAuthorizedUser
  };
};
export default useGetTaxDetail;
