import PropTypes from 'prop-types';

import ControlCenterOrOrderHistoryWrapper from '../ControlCenterOrOrderHistoryWrapper';
import HelpCenter from '../HelpCenter';
import MyEquipmentWrapper from '../MyEquipmentWrapper';

const SubheaderUtilityNavLinks = ({ displayMobileNavLink }) => {
  return (
    <>
      <MyEquipmentWrapper displayMobileNavLink={displayMobileNavLink} />
      <ControlCenterOrOrderHistoryWrapper
        displayMobileNavLink={displayMobileNavLink}
      />
      <HelpCenter displayMobileNavLink={displayMobileNavLink} />
    </>
  );
};

SubheaderUtilityNavLinks.propTypes = {
  displayMobileNavLink: PropTypes.bool
};

export default SubheaderUtilityNavLinks;
