import { useCallback, useEffect, useRef } from 'react';

const useCountDown = (
  total,
  updateTimeHandler,
  isRestrictCountdown = false
) => {
  let timer = useRef();
  const updateTimer = useCallback(() => {
    const currentTimer = total - new Date().getTime();
    const hours = Math.floor(currentTimer / 36e5);
    const minutes = Math.floor((currentTimer % 36e5) / 6e4);
    if (hours + minutes <= 0) {
      clearInterval(timer.current);
    }
    updateTimeHandler(hours, minutes);
  }, [updateTimeHandler, total]);

  useEffect(() => {
    if (!isRestrictCountdown) {
      timer.current = setInterval(() => {
        updateTimer();
      }, 1000);
    }
    return () => clearInterval(timer.current);
  }, [isRestrictCountdown, total, updateTimer]);
};

export default useCountDown;
