import { MESSAGE_STATUS } from '@app/components/pages/order-details/StepperCSR/messages';

export const idleTimer = ({ timeout, onTimeout }) => {
  let interval = null;
  let timeoutTracker = localStorage.setItem(
    '_expiredTime',
    Date.now() + timeout * 1000
  );

  const updateExpiredTime = () => {
    if (timeoutTracker) {
      clearTimeout(timeoutTracker);
    }
    timeoutTracker = setTimeout(() => {
      localStorage.setItem('_expiredTime', Date.now() + timeout * 1000);
    }, 30);
  };

  const cleanUp = () => {
    localStorage.removeItem('_expiredTime');
    clearInterval(interval);
    window.removeEventListener('mousemove', updateExpiredTime);
    window.removeEventListener('scroll', updateExpiredTime);
    window.removeEventListener('keydown', updateExpiredTime);
  };

  const startInterval = () => {
    updateExpiredTime();

    interval = setInterval(() => {
      const expiredTime = parseInt(
        localStorage.getItem('_expiredTime') || 0,
        10
      );
      if (expiredTime < Date.now()) {
        if (onTimeout) {
          sessionStorage.setItem('_userInactive', true);
          onTimeout();
          cleanUp();
        }
      }
    }, 1000);
  };

  const tracker = () => {
    window.addEventListener('mousemove', updateExpiredTime);
    window.addEventListener('scroll', updateExpiredTime);
    window.addEventListener('keydown', updateExpiredTime);
  };

  tracker();
  startInterval();

  return {
    cleanUp
  };
};

export const STEPPER_STATUS = {
  OP: { step: 1, message: MESSAGE_STATUS.orderPlaced, linkMessage: '' },
  F: { step: 1, message: MESSAGE_STATUS.orderPlaced, linkMessage: '' },
  OH: { step: 1, message: MESSAGE_STATUS.orderHold, linkMessage: '' },
  W: {
    step: 0,
    message: MESSAGE_STATUS.orderHoldPendingCAL,
    linkMessage: ''
  },
  OC: {
    step: 0,
    message: MESSAGE_STATUS.orderCancelled,
    linkMessage: ''
  },
  OA: {
    step: 1,
    message: MESSAGE_STATUS.orderApproved,
    linkMessage: ''
  },
  OR: {
    step: 2,
    message: MESSAGE_STATUS.orderReleased,
    linkMessage: 'processed'
  },
  EX: {
    step: 3,
    message: MESSAGE_STATUS.exceptionMsg,
    linkMessage: 'exception'
  },
  FS: {
    step: 3,
    message: MESSAGE_STATUS.fullyShipped,
    linkMessage: 'fully shipped'
  },
  PS: {
    step: 3,
    message: MESSAGE_STATUS.partialShipped,
    linkMessage: 'partially shipped'
  },
  PA: {
    step: 3,
    message: MESSAGE_STATUS.partialPickUp,
    linkMessage: 'partial pick-up'
  },
  DL: {
    step: 4,
    message: MESSAGE_STATUS.orderDelivered,
    linkMessage: 'delivered'
  },
  RP: {
    step: 3,
    message: MESSAGE_STATUS.readyForPickUp,
    linkMessage: 'pick-up'
  },
  PU: { step: 4, message: MESSAGE_STATUS.pickedUp, linkMessage: 'picked up' }
};
