import * as types from './constants';
import * as commonTypes from '../common/constants';
import {
  replaceTokensInUrl,
  getObjectAsQueryParams,
  isEmpty,
  replaceTokensInString,
  getBFFUrl
} from '@app/utils';
import { getHttpInstance } from '@app/services/defaultHttpService';
import {
  getMultipleEquipParams,
  setPendingToastAndRedirect,
  setPendingToastAndReload,
  setPendingToastforMLPRedirect,
  emailNotificationAndDispatchSuccess,
  onSuccessFailureOfAutoSave,
  getEquipmentFromList,
  tranformFitmentObjToSerialObj,
  getOmmLocale,
  getOmmImageUrl,
  loadFilteredData,
  getYoutubeIframeSrc,
  getIsSameModel,
  getServiceData
} from './utils';
import { v4 } from 'uuid';
import queryString from 'query-string';
import axiosRetry from 'axios-retry';
import { getFormSubmitEvent } from '@app/components/common/analytics/analyticsUtils';
import { setError as setExceptionError } from '../exception/actions';
import endpoints from '@app/constants/endpoints';
import {
  ALP_HOW_TO_VIDEOS,
  GENERIC_USER_ID,
  OFFER_STATUS_CODE,
  REQUEST_ID_HEADER,
  STATUS,
  TIMEOUT_DEFAULT,
  TIMEOUT_DOUBLED,
  TOAST_TYPE,
  USER_TYPE_GUEST
} from '@app/constants/commonConstants';
import { normalizeError } from '../exception/utils';
import { STATUS_FAIL, STATUS_SUCCESS } from '@app/constants/analyticsConstants';
import { createGuestIdentity } from '@app/store/common/actions';
import { MULTI_EQ_ROUTE } from '@app/components/pages/homepage/constants';
import { navigateToUrl } from '@app/services/windowService';
import { getHeliosMappedLanguage } from '../requestService/actions';
import { replaceFromString } from '@app/utils/stringUtils';

const DEFAULT_RETRY_DELAY = axiosRetry.exponentialDelay;

export const loadEquipmentData = equipments => ({
  type: types.ME_LOAD_EQUIPMENTDATA_SUCCESS,
  equipments
});

export const beginLoading = () => ({ type: types.ME_BEGIN_LOADING });

export const endLoading = () => ({ type: types.ME_END_LOADING });

export const setEquipmentSaveSuccess = payload => ({
  type: types.ME_SET_EQUIPMENT_SAVE_SUCCESS,
  payload
});

export const setEquipmentToast = payload => ({
  type: types.ME_TOAST,
  payload
});

export const removeCurrentEquipment = (wishListItemId, emptyEquipmentData) => ({
  type: types.ME_DELETE_EQUIPMENTDATA_SUCCESS,
  wishListItemId,
  emptyEquipmentData
});
export const updateCurrentEquipment = currentEquipment => ({
  type: types.ME_UPDATE_EQUIPMENTDATA_SUCCESS,
  currentEquipment
});
export const addCurrentEquipment = currentEquipment => ({
  type: types.ME_ADD_EQUIPMENTDATA_SUCCESS,
  currentEquipment
});
export const loadSelectedEquipment = (selectedEquipment, redirectMLPURL) => ({
  type: types.ME_LOAD_SELECTEDEQUIPMENT_SUCCESS,
  selectedEquipment,
  redirectMLPURL
});
export const loadDeSelectedEquipment = selectedEquipment => ({
  type: types.ME_LOAD_DESELECTEDEQUIPMENT_SUCCESS,
  selectedEquipment
});

export const filterEquipmentResults = (filters = {}) => ({
  type: types.ME_SET_EQUIPMENT_FILTER_SUCCESS,
  filters
});

export const setError = (error = {}) => ({
  type: types.ME_SET_ERROR,
  payload: { ...error, code: error?.response?.status }
});

export const equipmentSelectDeselect = payload => ({
  type: types.EQP_SEL_DESEL_TOAST,
  payload: { ...payload }
});

export const clearErrors = () => ({ type: types.ME_CLEAR_ERROR });

const fetchEquipment = (
  dispatch,
  storeInfo,
  extraParams = {},
  isLucid = false
) => {
  const { storeId, langId, userId, isBuyOnBehalf, selectedCustomerNumber } =
    storeInfo;
  const http = getHttpInstance({ timeout: TIMEOUT_DOUBLED });
  const config = {
    headers: { 'Cache-Control': 'no-cache' },
    params: {
      ...extraParams
    }
  };

  let url = replaceTokensInString(endpoints.GET_MY_EQUIPMENT, storeId, langId);
  url += isBuyOnBehalf && userId ? `&forUserId=${userId}` : '';

  const emptyEquipmentResult = {
    mccResponse: true,
    myEquipmentList: [],
    selectedEquipment: {},
    isHelios: false
  };
  dispatch({ type: types.ME_BEGIN_LOADING });
  return http
    .get(url, config)
    .then(response => {
      const data = response.data;
      const equipments =
        Object.keys(data).length > 0 &&
        Object.keys(data.myEquipmentList).length > 0
          ? data
          : emptyEquipmentResult;
      let equipmentList = equipments;
      if (equipments.isHelios) {
        const { myEquipmentList } = loadFilteredData(
          equipments,
          selectedCustomerNumber,
          isLucid
        );
        const { model, name, equipmentFamily } = equipments.selectedEquipment;
        const selectedEquipment = isLucid
          ? {
              ...equipments.selectedEquipment,
              equipmentFamilyInEnglish: equipmentFamily,
              equipmentFamily: replaceFromString(model, name)
            }
          : equipments.selectedEquipment;
        equipmentList = { ...equipments, myEquipmentList, selectedEquipment };
      }
      dispatch({ type: types.ME_LOAD_MCCRESPONSE_SUCCESS });
      dispatch(loadEquipmentData(equipmentList));
    })
    .catch(() => {
      dispatch({ type: types.ME_LOAD_MCCRESPONSE_FAIL });
    });
};

export const loadEquipmentDataDetails =
  (extraParams = {}) =>
  (dispatch, getState) => {
    const storeInfo = getState().common;
    const { isLoading } = getState().myEquipment;
    const isLucid = getState().featureFlag.PCCEquipmentComponentFlag;

    return isLoading
      ? null
      : fetchEquipment(dispatch, storeInfo, extraParams, isLucid);
  };

export const RemoveEquipmentDataDetails =
  (currentEquipment, toastContents, successCb) => (dispatch, getState) => {
    const http = getHttpInstance();
    const {
      common,
      featureFlag: { PCCLucidSolrAddEquipmentComponentFlag }
    } = getState();
    const { storeId } = common;
    const {
      refreshPageAfterSelectEquipment,
      equipments: { myEquipmentList }
    } = getState().myEquipment;
    const isLucid = getState().featureFlag.PCCEquipmentComponentFlag;
    const wishListId = currentEquipment.unValidatedEquipmentId;
    const wishListItemId = currentEquipment.unValidatedItemListId;
    const emptyEquipmentData = {
      unValidatedItemListId: '',
      unValidatedEquipmentId: '',
      model: '',
      assetId: '',
      equipmentFamily: '',
      uniqueIndex: '',
      serialNumber: '',
      validatedAsset: false,
      imageURL: ''
    };

    const { serials, assetIds } = getMultipleEquipParams(
      currentEquipment,
      myEquipmentList
    );
    const equipmentDetail =
      PCCLucidSolrAddEquipmentComponentFlag && currentEquipment.partNumber
        ? { partNumber: currentEquipment.partNumber }
        : { productId: currentEquipment.productId };
    const requestParams = {
      action: 'delete',
      item: [
        {
          ...equipmentDetail,
          quantityRequested: '1',
          giftListItemID: wishListItemId,
          xitem_field3: serials,
          xitem_field4: assetIds,
          uniqueIndex: currentEquipment.uniqueIndex,
          equipmentUniqueId: currentEquipment.equipmentUniqueId
        }
      ]
    };
    const config = {
      headers: { 'Cache-Control': 'no-cache' }
    };
    const url = `/wcs/resources/store/${storeId}/catcommercewishlist/${wishListId}`;
    dispatch({ type: types.ME_BEGIN_LOADING });
    http
      .put(url, requestParams, config)
      .then(response => {
        if (response.data && response.data.uniqueID) {
          // Refreshes page if on a page where selected equipment affects results
          if (refreshPageAfterSelectEquipment) {
            setPendingToastAndReload(toastContents);
          } else {
            dispatch({ type: types.ME_LOAD_MCCRESPONSE_SUCCESS });
            // Fetches equipment when there are multiples of that equipment model b/c uniqueIndex changes
            if (currentEquipment.multiple) {
              fetchEquipment(dispatch, common, {}, isLucid);
            } else {
              dispatch(
                removeCurrentEquipment(wishListItemId, emptyEquipmentData)
              );
            }
            if (successCb) {
              successCb();
            }
          }
        }
      })
      .catch(error => {
        dispatch(setError(error));
      });
  };

export const emailNotification = (
  storeId,
  langId,
  customerNumber,
  emailBody,
  salesModel,
  serialNumber,
  nickName,
  action = 'update'
) => {
  const http = getHttpInstance();
  const url = replaceTokensInString(
    endpoints.EMAIL_NOTIFICATION,
    storeId,
    langId
  );
  const body = {
    action,
    emailBody,
    salesModel,
    serialNumber,
    nickName,
    customerNumber
  };
  return http.post(url, body);
};

export const updateEquipmentDataDetails =
  (currentEquipment, toastContents, setUpdateEqpSuccess = () => {}) =>
  (dispatch, getState) => {
    const http = getHttpInstance({ timeout: TIMEOUT_DOUBLED });
    const {
      common,
      featureFlag: { PCCLucidSolrAddEquipmentComponentFlag }
    } = getState();
    const { storeId } = common;
    const {
      refreshPageAfterSelectEquipment,
      equipments: { myEquipmentList }
    } = getState().myEquipment;
    const isLucid = getState().featureFlag.PCCEquipmentComponentFlag;
    const wishListId = currentEquipment.unValidatedEquipmentId;
    const { serials, assetIds } = getMultipleEquipParams(
      currentEquipment,
      myEquipmentList
    );
    const equipmentDetail =
      PCCLucidSolrAddEquipmentComponentFlag && currentEquipment.partNumber
        ? { partNumber: currentEquipment.partNumber }
        : { productId: currentEquipment.productId };
    const requestParams = {
      action: 'update',
      item: [
        {
          ...equipmentDetail,
          quantityRequested: '1',
          giftListItemID: currentEquipment.unValidatedItemListId,
          xitem_field3: serials,
          xitem_field4: assetIds,
          uniqueIndex: currentEquipment.uniqueIndex,
          equipmentUniqueId: currentEquipment.equipmentUniqueId
        }
      ]
    };
    const config = {
      headers: { 'Cache-Control': 'no-cache' }
    };
    const url = `/wcs/resources/store/${storeId}/catcommercewishlist/${wishListId}`;
    dispatch({ type: types.ME_BEGIN_LOADING });

    return http
      .put(url, requestParams, config)
      .then(response => {
        if (response.data && response.data.uniqueID) {
          // Refreshes page if on a page where selected equipment affects results
          if (refreshPageAfterSelectEquipment) {
            setPendingToastAndReload(toastContents);
          } else {
            // Fetches equipment when there are multiples of that equipment model b/c uniqueIndex changes
            if (currentEquipment.multiple) {
              fetchEquipment(dispatch, common, {}, isLucid);
            } else {
              // Serial number is cast to upper in server. We want to reflect this in UI
              // Without having to do a fetch just for that.
              const currentEquipUppercaseSerial = {
                ...currentEquipment,
                serialNumber: currentEquipment.serialNumber.toUpperCase()
              };
              dispatch(loadSelectedEquipment(currentEquipUppercaseSerial));
              dispatch({ type: types.ME_LOAD_MCCRESPONSE_SUCCESS });
              dispatch(updateCurrentEquipment(currentEquipUppercaseSerial));
            }
            setUpdateEqpSuccess(response.data);
            dispatch(setEquipmentSaveSuccess(true));
            dispatch(
              setEquipmentToast({
                method: types.EQUIPMENT_METHOD.UPDATE,
                message: toastContents
              })
            );
          }
        }
        if (response.data && response.data.errors) {
          setUpdateEqpSuccess(response.data);
          dispatch(setError());
        }
      })
      .catch(error => {
        dispatch(setError(error));
      });
  };

export const AddEquipmentDataDetails =
  (
    currentEquipment,
    toastContent,
    model,
    fireFormSubmittedEvent,
    formSubmittedEventPayload,
    isAlpVideoEnable = false,
    redirect = false,
    customerAccountCB = null
  ) =>
  (dispatch, getState) => {
    const http = getHttpInstance({ timeout: TIMEOUT_DOUBLED });

    const {
      common,
      myEquipment: { refreshPageAfterSelectEquipment },
      dealer: { seoURL },
      featureFlag: { PCCLucidSolrAddEquipmentComponentFlag }
    } = getState();
    const { storeId, langId, selectedCustomerNumber } = common;
    const equipmentDetail =
      PCCLucidSolrAddEquipmentComponentFlag &&
      (currentEquipment.partNumber || currentEquipment.equipmentUniqueId)
        ? {
            partNumber:
              currentEquipment.partNumber ?? currentEquipment.equipmentUniqueId
          }
        : { productId: currentEquipment.productId };

    const requestParams = {
      description: 'Wish List',
      descriptionName: 'Wish List',
      registry: 'false',

      item: [
        {
          ...equipmentDetail,
          quantityRequested: '1',
          xitem_field3: currentEquipment.serialNumber
            ? currentEquipment.serialNumber
            : `IMPLICIT${currentEquipment.model}`,
          xitem_field4: currentEquipment.assetId,
          equipmentUniqueId: currentEquipment.equipmentUniqueId
        }
      ]
    };
    const config = {
      headers: { 'Cache-Control': 'no-cache' }
    };

    const url = `/wcs/resources/store/${storeId}/catcommercewishlist`;

    const handleSubmitEquipmentSuccess = isGuest => {
      const current = getState().products?.current;
      const byId = getState().products?.byId;
      const selectedProduct = byId[current] || {};
      const mlpPage = selectedProduct.isMLP;
      const redirectMLPURL = getState().myEquipment.equipments.redirectMLPURL;
      const isSameModel = getIsSameModel(redirectMLPURL);
      const shouldRedirect = mlpPage && !isSameModel;
      const setToasts = (notificationToastStatus = true) => {
        if (shouldRedirect || redirect) {
          const toastMessages = notificationToastStatus
            ? toastContent
            : [toastContent, 'MEQ_ADD_NOTIFICATION8'];
          const toastMethods = notificationToastStatus
            ? 'createAddToast'
            : ['createAddToast', 'createErrorToast'];
          setPendingToastforMLPRedirect(toastMessages, toastMethods, isGuest);
          redirect && navigateToUrl(redirectMLPURL + '#pmKits');
        } else {
          dispatch(
            setEquipmentToast({
              method: types.EQUIPMENT_METHOD.CREATE,
              message: toastContent
            })
          );
        }
        dispatch(setEquipmentSaveSuccess(true));
      };

      if (currentEquipment.notifyDealer) {
        dispatch(beginLoading());
        emailNotificationAndDispatchSuccess({
          storeId,
          langId,
          customerNumber: selectedCustomerNumber,
          emailBody: '',
          salesModel: model,
          serialNumber: currentEquipment.serialNumber,
          nickName: currentEquipment.assetId,
          dispatch,
          setEquipmentSaveSuccess,
          endLoading,
          setExceptionError,
          emailNotification,
          action: 'add',
          fireFormSubmittedEvent,
          formSubmittedEventPayload,
          shouldRedirect,
          setToasts
        });
      } else {
        setToasts();

        if (fireFormSubmittedEvent) {
          fireFormSubmittedEvent({
            ...formSubmittedEventPayload,
            formStatus: STATUS_SUCCESS
          });
        }
        dispatch(endLoading());
      }
    };

    const handleAddEquipmentDataDetailsError = error => {
      if (fireFormSubmittedEvent) {
        fireFormSubmittedEvent({
          ...formSubmittedEventPayload,
          formFieldCausingError: error.message,
          formStatus: STATUS_FAIL
        });
      }
      customerAccountCB && customerAccountCB(true);
      dispatch(setError(error));
    };

    const submitEquipment = isGuest => {
      return http
        .post(url, requestParams, config)
        .then(response => {
          if (response.data && response.data.uniqueID) {
            if (refreshPageAfterSelectEquipment) {
              setPendingToastAndReload(
                toastContent,
                'createSuccessToast',
                isGuest
              );
            } else {
              const newEquip = {
                ...currentEquipment,
                serialNumber: currentEquipment.serialNumber.toUpperCase(),
                unValidatedItemListId: response.data.item[0].giftListItemID,
                unValidatedEquipmentId: response.data.uniqueID,
                uniqueIndex: response.data.uniqueIndex,
                multiple: response.data.multiple,
                seoUrl: currentEquipment.seoUrl,
                equipmentUniqueId: response.data.equipmentUniqueId
              };
              const partNumber = currentEquipment?.seoUrl?.split('/')?.pop();
              const url = replaceTokensInString(
                ALP_HOW_TO_VIDEOS.alp_Videos_path,
                seoURL,
                partNumber,
                storeId
              );
              dispatch(
                loadSelectedEquipment(
                  newEquip,
                  isAlpVideoEnable ? url : currentEquipment.seoUrl
                )
              );
              dispatch({ type: types.ME_LOAD_MCCRESPONSE_SUCCESS });
              dispatch(addCurrentEquipment(newEquip));
              handleSubmitEquipmentSuccess(isGuest);
            }
          }
          if (response.data && response.data.errors) {
            dispatch(setError());
          }
        })
        .catch(error => {
          handleAddEquipmentDataDetailsError(error);
        });
    };

    // TODO
    // this call for userInfo is added as a hot fix and needs to be removed when the userId is available in the store
    return http
      .get(`/wcs/resources/store/${storeId}/getUserInfo`)
      .then(async ({ data = {} }) => {
        dispatch({ type: types.ME_BEGIN_LOADING });
        const isGuest = (data.userType || '').toLowerCase() === 'g';
        // If you are a generic guest
        if (data.userId === GENERIC_USER_ID) {
          await dispatch(createGuestIdentity(true, true))
            .then(() => submitEquipment(isGuest))
            .catch(error => {
              handleAddEquipmentDataDetailsError(error);
            });
        } else {
          submitEquipment(isGuest);
        }
      })
      .catch(error => {
        handleAddEquipmentDataDetailsError(error);
      });
  };
const handleMccRedirect = (
  mccRedirect,
  fbeSearchTerm,
  selectedEquipment,
  toastContents,
  preventMccRedirect
) => {
  if (mccRedirect && fbeSearchTerm !== selectedEquipment.serialNumber) {
    setPendingToastAndRedirect(
      toastContents,
      fbeSearchTerm,
      selectedEquipment.serialNumber,
      preventMccRedirect
    );
  } else {
    setPendingToastAndReload(toastContents);
  }
};

export const selectEquipment =
  (
    selectedEquipment,
    toastContents,
    successCb,
    preventMccRedirect,
    redirectMLPURL,
    selectedEquipmentRedirect = false
  ) =>
  (dispatch, getState) => {
    const http = getHttpInstance();

    const {
      common: { storeId, userType },
      myEquipment: { refreshPageAfterSelectEquipment }
    } = getState();
    const isGuest = userType === USER_TYPE_GUEST;
    const url = `/wcs/resources/store/${storeId}/fleetmgmt`;
    const config = { headers: { 'Cache-Control': 'no-cache' } };
    dispatch({ type: types.ME_BEGIN_LOADING });
    dispatch({ type: types.ME_SELECT_EQP_SAVE_BEGIN });
    http
      .post(
        url,
        null,
        {
          params: {
            selectedEquipment: JSON.stringify({ ...selectedEquipment })
          }
        },
        config
      )
      .then(response => {
        if (refreshPageAfterSelectEquipment) {
          let { mccRedirect, fbeSearchTerm } = queryString.parse(
            window.location.href
          );

          handleMccRedirect(
            mccRedirect,
            fbeSearchTerm,
            selectedEquipment,
            toastContents,
            preventMccRedirect
          );
        } else {
          const selectedequipmentresponse = response.data;
          sessionStorage.setItem(
            'pendingToast',
            JSON.stringify({
              message: toastContents
            })
          );
          dispatch(
            loadSelectedEquipment(selectedequipmentresponse, redirectMLPURL)
          );
          if (selectedEquipmentRedirect && redirectMLPURL) {
            navigateToUrl(redirectMLPURL);
          }
          dispatch({ type: types.ME_SELECT_EQP_SAVE_SUCCESS });
          const current = getState().products?.current;
          const byId = getState().products?.byId;
          const selectedProduct = byId[current] || {};
          const mlpcheck = selectedProduct.isMLP;
          const isSameModel = getIsSameModel(redirectMLPURL);
          if (mlpcheck && !isSameModel) {
            sessionStorage.removeItem('pendingToast');
            setPendingToastforMLPRedirect(
              toastContents,
              'createAddToast',
              isGuest
            );
          } else if (successCb) {
            successCb(selectedEquipment);
          }
        }
      })
      .catch(error => {
        dispatch(equipmentSelectDeselect({ type: 'error' }));
        dispatch(setError(error));
        dispatch({
          type: types.ME_SELECT_EQP_SAVE_FAIL,
          payload: { ...error, code: error?.response?.status }
        });
      });
  };

export const deselectEquipment =
  (selectedEquipment, successCb) => (dispatch, getState) => {
    const http = getHttpInstance();
    const {
      common: { storeId },
      myEquipment: { refreshPageAfterSelectEquipment }
    } = getState();
    const url = `/wcs/resources/store/${storeId}/fleetmgmt`;
    const config = { headers: { 'Cache-Control': 'no-cache' } };
    http
      .post(url, null, config)
      .then(response => {
        if (response?.status === 200) {
          dispatch(
            equipmentSelectDeselect({
              type: 'success',
              model: selectedEquipment?.model,
              productFamily: selectedEquipment?.equipmentFamily
            })
          );
        }
        if (refreshPageAfterSelectEquipment) {
          window.location.reload();
        } else {
          dispatch(
            loadDeSelectedEquipment({
              unValidatedItemListId: '',
              unValidatedEquipmentId: '',
              model: '',
              assetId: '',
              equipmentFamily: '',
              uniqueIndex: '',
              serialNumber: '',
              validatedAsset: false,
              imageURL: '',
              equipmentUniqueId: ''
            })
          );
          if (successCb) {
            successCb();
          }
        }
      })
      .catch(error => {
        dispatch(
          equipmentSelectDeselect({
            type: 'error'
          })
        );
      });
  };
export const validationSerialNumber =
  (
    currentEquipment,
    setFieldError,
    searchTerm = '',
    isEditing,
    openSerialMismatch,
    handleUpdateEquipment,
    fireGAEvent,
    widgetIdentifier,
    fireFormSubmittedEvent,
    formSubmittedEventPayload,
    isSolrSkip = false
  ) =>
  (dispatch, getState) => {
    const { storeId } = getState().common;
    const http = getHttpInstance({ timeout: TIMEOUT_DOUBLED });
    const config = {
      headers: { 'Cache-Control': 'no-cache' }
    };
    const nickName = encodeURIComponent(currentEquipment.assetId);
    const model = currentEquipment.model;
    const equipmentFamily = currentEquipment.equipmentFamily;
    const uniqueIndex = currentEquipment.uniqueIndex || '';
    const url = replaceTokensInUrl(
      endpoints.SERIAL_VALIDATION_URL,
      searchTerm,
      nickName,
      model,
      storeId,
      equipmentFamily,
      uniqueIndex,
      isSolrSkip
    );
    searchTerm.length &&
      dispatch({
        type: types.ME_SERIAL_NUM_VALIDATION_BEGIN
      });

    return http
      .get(url, config)
      .then(response => {
        const { errors, result } = response.data;
        if (errors) {
          fireGAEvent && fireGAEvent('fail', widgetIdentifier);
          Array.isArray(errors) &&
            errors.forEach(error => {
              setFieldError(
                error.errorCode === '_ERR_SERIAL_NUMBR'
                  ? 'serialNumber'
                  : 'assetId',
                error.errorMessage
              );
              if (!isEditing) {
                error.errorReason === 'SERIAL_MISMATCH' &&
                  openSerialMismatch &&
                  openSerialMismatch({
                    ...currentEquipment,
                    serialNumber: searchTerm
                  });
              }
              dispatch(endLoading());
            });
          dispatch({
            type: types.ME_SERIAL_NUM_VALIDATION_FAIL,
            payload: errors[0].errorMessage
          });
        } else if (result) {
          searchTerm.length &&
            dispatch({
              type: types.ME_SERIAL_NUM_VALIDATION_SUCCESS
            });
          if (fireGAEvent) {
            fireGAEvent('success', widgetIdentifier);
            handleUpdateEquipment(currentEquipment, currentEquipment.model);
          }
        }
      })
      .catch(err => {
        if (fireFormSubmittedEvent) {
          fireFormSubmittedEvent({
            ...formSubmittedEventPayload,
            formFieldCausingError: err.message,
            formStatus: STATUS_FAIL
          });
        }
        dispatch({
          type: types.ME_SERIAL_NUM_VALIDATION_FAIL
        });
        if (fireGAEvent) {
          fireGAEvent('fail', widgetIdentifier);
          dispatch(setError(err));
        }
      });
  };
export const addOrUpdateEquipmentInWishList =
  (
    currentEquipment,
    setFieldError,
    trackEvent,
    openSerialMismatch,
    isEditing,
    toastContent,
    fireFormSubmittedEvent,
    formSubmittedEventPayload,
    widgetIdentifier = '',
    isAlpVideoEnable = false,
    redirect = false,
    customerAccountCB = null,
    isSolrSkip = false
  ) =>
  dispatch => {
    const fireGAEvent = (formStatus, formLocation) => {
      trackEvent &&
        trackEvent(
          getFormSubmitEvent({
            formName: 'Save Equipment',
            formContent: currentEquipment.serialNumber,
            formContent2: currentEquipment.equipmentFamily
              ? currentEquipment.model + currentEquipment.equipmentFamily
              : currentEquipment.model,
            formStatus,
            pagePath: document.location.pathname,
            formLocation
          })
        );
    };

    const handleUpdateEquipment = (data, model = currentEquipment.model) => {
      if (isEditing) {
        dispatch(updateEquipmentDataDetails(data, toastContent));
      } else {
        dispatch(
          AddEquipmentDataDetails(
            data,
            toastContent,
            model,
            fireFormSubmittedEvent,
            formSubmittedEventPayload,
            isAlpVideoEnable,
            redirect,
            customerAccountCB
          )
        );
      }
    };

    dispatch(beginLoading());
    return dispatch(
      validationSerialNumber(
        currentEquipment,
        setFieldError,
        currentEquipment.serialNumber,
        isEditing,
        openSerialMismatch,
        handleUpdateEquipment,
        fireGAEvent,
        widgetIdentifier,
        fireFormSubmittedEvent,
        formSubmittedEventPayload,
        isSolrSkip
      )
    );
  };

export const removeSerialValidation = () => dispatch => {
  dispatch({
    type: types.ME_SERIAL_NUM_REMOVE_VALIDATION
  });
};

export const setEquipmentFitment =
  ({ data, namespace }) =>
  dispatch => {
    dispatch({
      type: types.ME_CHECK_FITMENT_SUCCESS,
      payload: {
        fitmentResult: data,
        namespace
      }
    });
  };

export const getEquipmentFitment =
  ({ partNumbers, namespace, fromPage, statusActionPrefix, errorInfo = {} }) =>
  async (dispatch, getState) => {
    const http = getHttpInstance(40000);
    const { common, myEquipment } = getState();
    const { storeId } = common;
    const { serialNumber } = myEquipment.equipments?.selectedEquipment ?? {};
    const config = {
      headers: { 'Cache-Control': 'no-cache' }
    };
    const queryParams = getObjectAsQueryParams({
      partNumbers,
      equipmentSerialNumber: serialNumber,
      fromPage
    });
    const url = replaceTokensInUrl(
      `${endpoints.PRODUCT_FITMENT_CHECK}?${queryParams}`,
      storeId
    );
    const statusType = `${
      statusActionPrefix ? `${statusActionPrefix}_` : ''
    }GET_EQUIPMENT_FITMENT_SET_STATUS`;
    try {
      dispatch({
        type: types[statusType],
        payload: STATUS.PENDING
      });
      const { data } = await http.get(url, config);
      dispatch({
        type: types.ME_CHECK_FITMENT_SUCCESS,
        payload: {
          fitmentResult: data,
          namespace
        }
      });
      dispatch({
        type: types[statusType],
        payload: STATUS.RESOLVED
      });
      return data;
    } catch (error) {
      dispatch({
        type: types[statusType],
        payload: STATUS.REJECTED
      });
      const { domain, path } = errorInfo;
      if (domain && path) {
        const err = normalizeError(error);
        dispatch(setExceptionError(domain, path, err));
      }
    }
  };

export const autoSaveEquipment =
  (
    selectedEquipment,
    searchTerm,
    filter,
    fireFormSubmittedEvent,
    preventRedirectToALP = false
  ) =>
  async (dispatch, getState) => {
    const { storeId, userId, selectedCustomerNumber, langId } =
      getState().common;
    const { myEquipmentList = [] } = getState().myEquipment.equipments;
    if (userId === GENERIC_USER_ID) {
      await dispatch(createGuestIdentity());
    }
    const http = getHttpInstance();
    const {
      serialNumber,
      seoUrl,
      model,
      equipmentFamily,
      productId,
      partNumber
    } = selectedEquipment;
    const formSubmittedEventPayload = {
      event: 'formSubmitted',
      pagePath: document.location.pathname,
      formName:
        filter === types.SERIAL_NUMBER
          ? types.GA_FORM_NAME_BY_SERIAL
          : types.GA_FORM_NAME_BY_MODEL,
      formContent: serialNumber,
      formContent2: `${model} ${equipmentFamily}`,
      formFieldCausingError: '',
      formLocation:
        myEquipmentList > 0
          ? types.GA_LOCATION_FOR_ADD_EQ
          : types.GA_LOCATION_FOR_FIRST_ADD_EQ
    };
    const { description, descriptionName, registry, quantityRequested } =
      types.AUTO_SAVE_DEFAULT_PARAMS;
    const requestParams = {
      searchTerm: searchTerm,
      salesModel: selectedEquipment.model,
      description,
      descriptionName,
      registry,
      filter,
      item: [
        {
          partNumber: partNumber,
          productId: productId,
          quantityRequested,
          xitem_field3: serialNumber,
          xitem_field4: '',
          equipmentSize: myEquipmentList?.length
        }
      ]
    };
    const url = replaceTokensInString(endpoints.MEQ_AUTO_SAVE, storeId);
    const onEmailNotificationSuccessFailure = () => {
      dispatch({ type: types.ME_AUTO_SAVE_SUCCESS });
      onSuccessFailureOfAutoSave(TOAST_TYPE.SUCCESS, seoUrl);
    };
    dispatch({ type: types.ME_AUTO_SAVE_BEGIN });
    http
      .post(url, requestParams)
      .then(() => {
        if (selectedEquipment.notifyDealer) {
          emailNotificationAndDispatchSuccess({
            storeId,
            langId,
            customerNumber: selectedCustomerNumber,
            emailBody: '',
            salesModel: model,
            serialNumber: serialNumber,
            nickName: selectedEquipment?.assetId,
            dispatch,
            setEquipmentSaveSuccess: null,
            endLoading: null,
            setExceptionError,
            emailNotification,
            action: 'add',
            fireFormSubmittedEvent,
            formSubmittedEventPayload,
            onEmailNotificationSuccessFailure
          });
        } else {
          dispatch({ type: types.ME_AUTO_SAVE_SUCCESS });
          fireFormSubmittedEvent({
            ...formSubmittedEventPayload,
            formStatus: STATUS_SUCCESS
          });
          onSuccessFailureOfAutoSave(
            TOAST_TYPE.SUCCESS,
            seoUrl,
            preventRedirectToALP
          );
        }
      })
      .catch(error => {
        const errorKey = error?.response?.data?.errors[0].errorKey;
        fireFormSubmittedEvent({
          ...formSubmittedEventPayload,
          formFieldCausingError: errorKey,
          formStatus: STATUS_FAIL
        });
        if (errorKey === 'SERIAL_EXISTS') {
          const { route } = queryString.parse(
            window.location.href?.split('?')?.pop()
          );
          const onSuccessCallback = () => {
            dispatch({ type: types.ME_AUTO_SAVE_FAIL });
            onSuccessFailureOfAutoSave(null, seoUrl, preventRedirectToALP);
          };
          dispatch(
            selectEquipment(
              getEquipmentFromList(myEquipmentList, selectedEquipment),
              null,
              onSuccessCallback,
              null,
              null
            )
          );
          if (route === MULTI_EQ_ROUTE) {
            onSuccessFailureOfAutoSave(TOAST_TYPE.FAIL, seoUrl);
          }
        } else {
          dispatch({ type: types.ME_AUTO_SAVE_FAIL });
          onSuccessFailureOfAutoSave(
            TOAST_TYPE.FAIL,
            seoUrl,
            preventRedirectToALP
          );
        }
      });
  };

export const getModernizedEquipmentFitment =
  ({ partNumbers, type, errorInfo }) =>
  async (dispatch, getState) => {
    const http = getHttpInstance(40000);
    const { myEquipment } = getState();
    const { serialNumber } = myEquipment.equipments?.selectedEquipment ?? {};
    const queryParams = getObjectAsQueryParams({
      partNumbers,
      serialNumber
    });
    const url = replaceTokensInUrl(`${endpoints.FITMENT_CHECK}?${queryParams}`);
    dispatch({
      type,
      payload: STATUS.PENDING
    });
    try {
      const { data = {} } = await http.get(url, {
        headers: {
          [REQUEST_ID_HEADER]: v4()
        }
      });
      const serialMatchPartNumbers = tranformFitmentObjToSerialObj(data);
      dispatch({
        type: types.ME_CHECK_FITMENT_SUCCESS,
        payload: {
          fitmentResult: serialMatchPartNumbers
        }
      });
      dispatch({
        type,
        payload: STATUS.RESOLVED
      });
    } catch (error) {
      dispatch({
        type,
        payload: STATUS.REJECTED
      });
      const { domain, path } = errorInfo;
      if (domain && path) {
        const err = normalizeError(error);
        dispatch(setExceptionError(domain, path, err));
      }
    }
  };

export const autoSaveQREquipment = currentEquipment => async dispatch => {
  dispatch({ type: types.ME_SAVE_QR_SCANNED_EQP_BEGIN });
  const setUpdateEqpSuccess = response => {
    if (response.errors) {
      onSuccessFailureOfAutoSave(TOAST_TYPE.FAIL);
      dispatch({ type: types.ME_SAVE_QR_SCANNED_EQP_FAIL });
    } else {
      onSuccessFailureOfAutoSave(TOAST_TYPE.SUCCESS);
      dispatch({ type: types.ME_SAVE_QR_SCANNED_EQP_SUCCESS });
    }
  };
  try {
    await dispatch(
      updateEquipmentDataDetails(currentEquipment, null, setUpdateEqpSuccess)
    );
  } catch (errorMsg) {
    onSuccessFailureOfAutoSave(TOAST_TYPE.FAIL);
    dispatch({ type: types.ME_SAVE_QR_SCANNED_EQP_FAIL });
  }
};

export const getOMMCatalog = serialNumber => (dispatch, getState) => {
  const locale = getState().common.locale;
  const languages = getState().footer.supportedLanguages;
  const featureValues = getState().common.featureDetails.data ?? {};
  const lang = getOmmLocale(
    locale,
    languages,
    featureValues.PCC_FEATURE_OMM_ALLOWED_LANGUAGES
  );
  const http = getHttpInstance();
  dispatch({
    type: types.ME_OMM_CATALOG_BEGIN
  });
  const urlParams = { serialNumber };
  if (lang) {
    urlParams.lang = lang;
  }
  const url = getBFFUrl(endpoints.GET_OMM_CATALOG);
  return http
    .get(url, { params: urlParams })
    .then(response => {
      const data = response.data ?? [];
      const catalogs = data.map(omm => {
        return {
          title: omm.shortDescription,
          uniqueId: omm.uri,
          imageUrl: getOmmImageUrl(omm.metadata)
        };
      });
      dispatch({
        type: types.ME_OMM_CATALOG_SUCCESS,
        payload: catalogs
      });
    })
    .catch(error => {
      if (error.response?.status === 404) {
        dispatch({
          type: types.ME_OMM_CATALOG_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: types.ME_OMM_CATALOG_FAIL
        });
      }
    });
};
export const getOMMContent = uri => dispatch => {
  const http = getHttpInstance();
  dispatch({
    type: types.ME_OMM_CONTENT_BEGIN
  });
  const urlParams = { uri };
  const url = getBFFUrl(endpoints.GET_OMM_CONTENT);
  return http
    .get(url, { params: urlParams })
    .then(response => {
      const ommContent = response.data ?? [];
      dispatch({
        type: types.ME_OMM_CONTENT_SUCCESS,
        payload: ommContent
      });
    })
    .catch(() => {
      dispatch({
        type: types.ME_OMM_CONTENT_FAIL
      });
    });
};

const getErrorResponse = (fetchData, dispatchSuccess, apiUrl) =>
  new Promise((resolve, reject) => {
    fetchData(apiUrl)
      .then(result => {
        resolve(result.data);
      })
      .catch(e => {
        if (e.response?.status === 403) {
          dispatchSuccess([]);
        } else {
          reject(e);
        }
      });
  });

export const getDIYMaintenanceVideos = videoUrls => async dispatch => {
  const http = getHttpInstance(TIMEOUT_DEFAULT, 0, DEFAULT_RETRY_DELAY, true);
  dispatch({
    type: types.MAINTENANCE_VIDEOS_BEGIN
  });
  const dispatchSuccess = payload => {
    dispatch({
      type: types.MAINTENANCE_VIDEOS_SUCCESS,
      payload
    });
  };
  const dispatchFailure = () => {
    dispatch({
      type: types.MAINTENANCE_VIDEOS_FAIL
    });
  };
  if (videoUrls.length === 0) {
    dispatchSuccess([]);
    return;
  }
  const getYoutubeMeta = url => {
    const fetchData = async url => {
      return await http.get(url);
    };
    const apiUrl = replaceTokensInUrl(
      endpoints.GET_MAINTENANCE_YOUTUBE_VIDEOS_META,
      url
    );
    return getErrorResponse(fetchData, dispatchSuccess, apiUrl);
  };
  return await Promise.allSettled(videoUrls.map(item => getYoutubeMeta(item)))
    .then(videos => {
      try {
        const fulfilledVideos = [];
        videos.forEach(({ status, value }) => {
          if (status === types.VIDEO_STATE.FULFILLED) {
            const videoSrc = getYoutubeIframeSrc(value['html']);
            if (videoSrc) {
              const videoUrl = `${videoSrc.split('?')[0]}?enablejsapi=1`;
              fulfilledVideos.push({ ...value, video_url: videoUrl });
            }
          }
        });
        fulfilledVideos.length
          ? dispatchSuccess(fulfilledVideos)
          : dispatchFailure();
      } catch {
        dispatchFailure();
      }
    })
    .catch(() => {
      dispatchFailure();
    });
};
// service recommendation instances bff api call written below have to update when actual api is available
export const getServiceRecommendations = () => async (dispatch, getState) => {
  const { langId, storeId } = getState().common;

  const isPccInstanceApiServiceInsightFlag =
    getState().featureFlag.PCC_InstanceAPI_ServiceInsightFlag ?? false;

  const http = getHttpInstance();
  dispatch({
    type: types.GET_SERVICE_RECOMMENDATION_BEGIN
  });

  const url = replaceTokensInString(
    isPccInstanceApiServiceInsightFlag
      ? endpoints.SERVICE_RECOMMENDATION_INSTANCE_SEARCH
      : endpoints.SERVICE_RECOMMENDATION_OFFERS_SEARCH,
    langId,
    storeId
  );

  try {
    const data = await http.post(
      url,
      isPccInstanceApiServiceInsightFlag
        ? {}
        : {
            offerStatusCode: OFFER_STATUS_CODE
          }
    );

    if (
      (isPccInstanceApiServiceInsightFlag && data?.data?.['instances']) ||
      data?.data?.['offersSearchResponse']
    ) {
      dispatch({
        type: types.GET_SERVICE_RECOMMENDATION_SUCCESS,
        payload: getServiceData(data, isPccInstanceApiServiceInsightFlag)
      });
    } else {
      dispatch({
        type: types.GET_SERVICE_RECOMMENDATION_FAIL,
        payload: {}
      });
    }
  } catch (error) {
    dispatch({
      type: types.GET_SERVICE_RECOMMENDATION_FAIL,
      payload: error
    });
  }
};

export const subTotal = requestPayload => async (dispatch, getState) => {
  const http = getHttpInstance();
  dispatch({ type: types.SERVICE_RECOMMENDATION_SUBTOTAL_BEGIN });
  const { storeId, locale } = getState().common;
  const url = replaceTokensInUrl(endpoints.SUB_TOTAL, locale, storeId);

  try {
    const data = await http.post(url, requestPayload);
    dispatch({
      type: types.SERVICE_RECOMMENDATION_SUBTOTAL_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: types.SERVICE_RECOMMENDATION_SUBTOTAL_FAIL });
  }
};

const replacementParts = (partsPrice, replacementSummaryItems, partList) => {
  const updatedReplacementItems = {
    items: [],
    currency: partsPrice?.currency
  };

  replacementSummaryItems.forEach((row, index) => {
    const priceDetails = partsPrice?.priceAndAvailability?.find(
      priceItem => row?.partNumber === priceItem?.partNumber
    );

    updatedReplacementItems.items.push({
      quantity: partList[index]?.quantity,
      partNumber: row?.partNumber,
      priceDetails: priceDetails || {},
      partDetails: {
        partsTitle: row?.name,
        imageURL: row?.imageURL,
        partNumber: row?.partNumber
      }
    });
  });

  return updatedReplacementItems;
};

export const SRPriceAvailability =
  (requestPayload, replacementSummaryItems) => async (dispatch, getState) => {
    const http = getHttpInstance();
    dispatch({ type: types.SERVICE_RECOMMENDATION_PRICE_BEGIN });
    const { storeId, langId } = getState().common;
    const url = replaceTokensInUrl(
      endpoints.PRICE_AVAILABILITY_URL,
      storeId,
      langId
    );

    const partsList = replacementSummaryItems?.map((item, index) => {
      return {
        partNumber: item?.partNumber,
        quantity: requestPayload.partsList?.[index]?.quantity || 1
      };
    });

    try {
      const data = await http.post(url, {
        partsList: partsList
      });

      dispatch({
        type: types.SERVICE_RECOMMENDATION_PRICE_SUCCESS,
        payload: replacementParts(
          data?.data,
          replacementSummaryItems,
          requestPayload.partsList
        )
      });

      const subTotalRequest = {
        currency: data?.data?.currency,
        lineItemsPrices: []
      };

      data?.data?.priceAndAvailability.forEach(item => {
        subTotalRequest.lineItemsPrices.push(item.unformattedTotalPrice);
      });

      dispatch(subTotal(subTotalRequest));
    } catch (error) {
      dispatch({
        type: types.SERVICE_RECOMMENDATION_PRICE_FAIL
      });
    }
  };

export const getCvaDetails = serialNumber => async (dispatch, getState) => {
  const http = getHttpInstance();
  const {
    common: { storeId }
  } = getState();

  const locale = await dispatch(getHeliosMappedLanguage());

  dispatch({ type: types.LOAD_CVA_DETAILS_BEGIN });
  const url = replaceTokensInString(
    endpoints.GET_CVA_DETAILS_URL,
    storeId,
    serialNumber,
    locale
  );
  return http
    .get(url)
    .then(response => {
      const data = response.data;
      if (data.assetCvaInfo?.agreements?.length > 0) {
        dispatch({
          type: types.LOAD_CVA_DETAILS_SUCCESS,
          payload: data.assetCvaInfo.agreements
        });
      } else {
        dispatch({
          type: types.LOAD_CVA_DETAILS_FAIL,
          payload: []
        });
      }
    })
    .catch(error => {
      dispatch({
        type: types.LOAD_CVA_DETAILS_FAIL,
        payload: error
      });
    });
};
