export const getImageReqObj = simpleImgObj => {
  const { displayOnScreen, displayOnPrint } = simpleImgObj;
  return {
    imageSetting: {
      displayOnScreen: displayOnScreen ? 'on' : 'off',
      displayOnPrint: displayOnPrint ? 'on' : 'off'
    }
  };
};

export const isLineItemNotesAvailable = item => {
  const {
    orderItemExtendAttribute: { lineItemNotes = {} }
  } = item;
  const {
    customerPartNumber,
    customerSerialNumber,
    lineItemNo,
    partNote
  } = lineItemNotes;
  return (
    !!customerPartNumber || !!customerSerialNumber || !!lineItemNo || !!partNote
  );
};

export const getInitialImgSettings = imageSettings => {
  const initialImgSettings = {
    displayOnScreen: true,
    displayOnPrint: true
  };

  imageSettings.forEach(item => {
    if (item.value === 'displayOnScreen') {
      initialImgSettings.displayOnScreen = item.default;
    } else if (item.value === 'displayOnPrint') {
      initialImgSettings.displayOnPrint = item.default;
    }
  });

  return initialImgSettings;
};
