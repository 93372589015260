export const normalizeCustomers = customersArray => {
  if (customersArray === null) {
    return [];
  }
  const arrCopy = [...customersArray];
  const normalizedList = {};
  arrCopy.forEach((customer, index) => {
    normalizedList[customer.userId] = {
      ...customer,
      index
    };
  });

  return normalizedList;
};
