import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { PCC_MINI_CART_LIST } from '@app/constants/analyticsConstants';
import useAnalytics from '@app/hooks/useAnalytics';
import styles from './ProductListItem.module.scss';

const ProductListItem = ({ item = {}, getProductDetails }) => {
  const [t] = useTranslation();
  const { fireProductClickEvent } = useAnalytics();

  const handleOnClick = () => {
    const product = getProductDetails(item);
    fireProductClickEvent({ list: PCC_MINI_CART_LIST, products: [product] });
  };

  const itemContent = (
    <>
      <img
        src={`${item.imageURL}?wid=48&hei=48&fmt=png-alpha`}
        height="48px"
        width="48px"
        loading="lazy"
        alt={item.name}
        className="me-3"
      />
      <div>
        <p
          className={cx(
            'exclude-global',
            'text-md',
            'text-univers',
            'mb-0',
            'text-break',
            {
              [styles['product-list-item_name']]: !!item.seoURL,
              'u-color--ExtraDarkGray': !item.seoURL
            }
          )}
        >
          {item.name}
        </p>
        <p className="exclude-global text-sm mb-0 u-color--DarkGray">{`${t(
          'QUANTITY1'
        )}: ${item.quantity}`}</p>
      </div>
    </>
  );

  const commonClassName = `d-flex p-3 text-decoration-none align-items-center exclude-global ${styles['product-list-item__anchor']}`;

  return (
    <li className={`list-group-item p-0 ${styles['product-list-item']}`}>
      {item.seoURL ? (
        <a
          className={commonClassName}
          href={item.seoURL}
          onClick={handleOnClick}
        >
          {itemContent}
        </a>
      ) : (
        <div className={commonClassName}>{itemContent}</div>
      )}
    </li>
  );
};

ProductListItem.propTypes = {
  getProductDetails: PropTypes.func,
  item: PropTypes.shape({
    imageURL: PropTypes.string,
    name: PropTypes.string,
    quantity: PropTypes.string,
    seoURL: PropTypes.string
  })
};

export default ProductListItem;
