import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Dropdown, Validator } from 'cat-ecommerce-alloy';
import { replaceTokensInString } from '@app/utils';
import Field from '@app/components/common/form/Field';

const EndUseCodeDropdown = ({ endUseCodes = [] }) => {
  const [t] = useTranslation();
  const { setFieldValue, values } = useFormikContext();
  const labelText = t('CAT_SHOPPING_PREFERENCES_END_USE_CODE');
  const placeholderText = t('CAT_SHOPPING_PREFERENCES_SEL_END_USE_CODE');
  const placeholderEndUseCode = useMemo(
    () => ({ label: placeholderText, value: '' }),
    [placeholderText]
  );
  const placeholder =
    endUseCodes.length === 1 ? placeholderText : placeholderEndUseCode;
  const endUseCode = values.endUseCode;
  const sortedEndUseCodes = endUseCodes.sort((a, b) =>
    a?.label?.localeCompare(b?.label)
  );

  const defaultCode = endUseCodes.find(({ default: isDefault }) => !!isDefault);
  useEffect(() => {
    if (!endUseCode) {
      setFieldValue('endUseCode', defaultCode ?? placeholderEndUseCode);
    }
  }, [defaultCode, endUseCode, placeholderEndUseCode, setFieldValue]);

  const FIELD_REQUIRED_MSG = t('1626_FIELD_REQUIRED_ERROR');
  const validator = opt =>
    new Validator(opt?.value)
      .required(replaceTokensInString(FIELD_REQUIRED_MSG, labelText))
      .getError();

  return (
    <Field
      as={Dropdown}
      className={`w-100`}
      id="associated-dealer_end-use-code"
      items={sortedEndUseCodes}
      itemToString={({ label }) => label}
      keyToString={({ value }) => value}
      label={labelText}
      menuSize="small"
      name="endUseCode"
      placeholder={placeholder}
      showRequired
      type="dropdown"
      validator={validator}
    />
  );
};

EndUseCodeDropdown.propTypes = {
  endUseCodes: PropTypes.arrayOf(
    PropTypes.shape({
      default: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.string
    })
  )
};

export default EndUseCodeDropdown;
