import { useEffect, useState, useRef } from 'react';
import { useTracking } from 'react-tracking';
import { useSelector } from 'react-redux';
import { getFormStatus } from './utils';
import { isEmpty, metaValueExists, isShoppingCartPage } from '../../utils';
import PropTypes from 'prop-types';

export const usePopulateDataLayer = (shouldRun = true) => {
  const cartImportData = useRef(null);
  const formSearchEvent = useRef(null);
  const { trackEvent } = useTracking();
  const [hasDataLayerBeenTracked, setHasDataLayerBeenTracked] = useState(false);

  const {
    common,
    orders,
    pageLoad: { serviceCallsPendingToResolve } = {}
  } = useSelector(state => state);

  const { userType = '' } = common ?? {};

  const { shoppingCartImportModel = {} } = orders ?? {};

  const { totalItemsAdded = 0, totalItemsRequested = 0 } =
    shoppingCartImportModel ?? {};
  const cartImportStatus = getFormStatus(totalItemsAdded, totalItemsRequested);
  const submitSearchFormEvent = metaValueExists('pageGroup', 'Search');
  const searchCount = document.getElementById(
    'originalTotalSearchCount'
  )?.value;

  useEffect(() => {
    if (submitSearchFormEvent) {
      formSearchEvent.current = {
        event: 'formSubmitted',
        pagePath: '/searchDisplay',
        formName: 'search results',
        formContent: document.querySelector('meta[name="pageIdentifier"]')
          ?.content,
        formContent2: `parts: ${searchCount} machine: 0`,
        formStatus: 'success',
        formLocation: ''
      };
    }
  });

  useEffect(() => {
    const areThereServicesPendingToResolve = () =>
      serviceCallsPendingToResolve && !!serviceCallsPendingToResolve.length;

    if (
      !areThereServicesPendingToResolve() &&
      !hasDataLayerBeenTracked &&
      shouldRun
    ) {
      const pageIdentifier = document.querySelector(
        'meta[name="pageIdentifier"]'
      )?.content;

      !!formSearchEvent.current &&
        pageIdentifier &&
        trackEvent(formSearchEvent.current);

      if (isShoppingCartPage() && !isEmpty(shoppingCartImportModel)) {
        cartImportData.current = {
          event: 'formSubmitted',
          pagePath: '/AjaxOrderItemDisplayView',
          formName: 'Cart Import',
          formContent: `${totalItemsRequested} passed`,
          formContent2: `${totalItemsRequested - totalItemsAdded} failed`,
          formStatus: cartImportStatus,
          formFieldCausingError: '',
          formLocation: ''
        };
        trackEvent(cartImportData.current);
      }

      setHasDataLayerBeenTracked(true);
    }
  }, [
    hasDataLayerBeenTracked,
    serviceCallsPendingToResolve,
    shouldRun,
    trackEvent,
    userType
  ]);
  return null;
};

export default usePopulateDataLayer;

usePopulateDataLayer.propTypes = {
  shouldRun: PropTypes.bool,
  templateName: PropTypes.string
};
