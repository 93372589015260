import PropTypes from 'prop-types';

const InputDropdownField = ({
  as: Component,
  ariaLabel,
  onChange,
  returnOnchange,
  setValue,
  setTouched,
  value,
  ...props
}) => {
  const handleInputDropdownChange = newVal => {
    if (returnOnchange) {
      onChange(newVal);
    } else {
      if (onChange) {
        onChange(newVal);
      }
      setValue(newVal);
    }
  };
  return (
    <Component
      aria-label={ariaLabel}
      onChange={handleInputDropdownChange}
      onClick={() => setTouched(true)}
      selected={value}
      {...props}
    />
  );
};

InputDropdownField.propTypes = {
  as: PropTypes.elementType.isRequired,
  ariaLabel: PropTypes.string,
  onChange: PropTypes.func,
  setTouched: PropTypes.func.isRequired,
  returnOnchange: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export { InputDropdownField };
