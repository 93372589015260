import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PrimaryAvailability from './PrimaryAvailability';
import SecondaryAvailability from './SecondaryAvailability';
import AvailabilityBySourceContent from '../AvailabilityBySourceContent';
import { useGetShoppingPreferenceDataLoadStatus } from '@app/hooks/useGetShoppingPreferenceDataLoadStatus';
import { getSessionStorage } from '../../../../utils';
import {
  USER_TYPE_GUEST,
  AVAIL_BY_DATE,
  AVAIL_BY_SOURCE
} from '@app/constants/commonConstants';
import { availability as availabilityPropTypes } from '../Availability.proptypes';
import { availability as storeAvailabilityPropTypes } from '../StoreAvailability/StoreAvailability.proptypes';

import { sourcingDetailsPropTypes } from '../AvailabilityBySourceContent/AvailabilityBySourceContent.proptypes';
import StoreAvailability from '../StoreAvailability/StoreAvailability';
import AvailabilityNoIntermStock from '../StoreAvailability/AvailabilityNoIntermStock';

const defaultValuesHoseAssembly = {
  hoseAssemblyIndicator: false,
  hoseAvailabilityLabel: null
};
const Container = props => {
  const {
    availabilityInformation = [],
    sourcingDetailsInformation,
    hideLabel,
    isLoading,
    hoseAssemblyDetails = defaultValuesHoseAssembly,
    toggleAvailability = true, // Toggle availability is set to true by default assuming container has toggle functionality.
    hideHeaderAndDeliveryOptions = false,
    hideDeliveryCheckoutMessage = false,
    storeAvailability,
    isPDPPage = false
  } = props;
  const availRemoveInterimStockFlag = useSelector(
    s => s.featureFlag?.PCC_availRemoveInterimStockFlag
  );

  const current = useSelector(s => s.products?.current);
  const partNumber = useSelector(s => s.products?.byId?.[current]?.partNumber);

  const catEntryId = useSelector(
    s => s.products?.priceAndAvailability?.[partNumber]?.itemCatEntryId
  );

  const showAvailabilityBySource = useSelector(
    state =>
      !!state?.shoppingPreferences?.shoppingPreferencesData
        ?.showSourcingDetailToggle
  );
  const userType = useSelector(s => s.common?.userType);
  const isGuest = userType === USER_TYPE_GUEST;

  const { t } = useTranslation();
  const [isSourcingDetailsButtonShow, setIsSourcingDetailsButtonShow] =
    useState(true);

  useEffect(() => {
    const isSourcingDetailsShow = () => {
      const contactDealerArray = availabilityInformation?.filter(
        val => val.availabilityMessage === t('CONTACT_YOUR_DEALER')
      );
      const inStockArray = availabilityInformation?.filter(
        val => val.availabilityMessage === t('MO_SHIP_IN_STOCK')
      );

      if (hoseAssemblyDetails.hoseAssemblyIndicator) {
        return false;
      }
      if (
        (contactDealerArray?.length > 0 &&
          contactDealerArray.length === availabilityInformation.length) ||
        (inStockArray?.length > 0 &&
          inStockArray.length === availabilityInformation.length)
      ) {
        return false;
      }
      return !!(
        sourcingDetailsInformation || sourcingDetailsInformation?.length
      );
    };

    setIsSourcingDetailsButtonShow(isSourcingDetailsShow());
  }, [
    availabilityInformation,
    sourcingDetailsInformation,
    t,
    hoseAssemblyDetails.hoseAssemblyIndicator
  ]);
  const key = showAvailabilityBySource ? AVAIL_BY_DATE : AVAIL_BY_SOURCE;
  const availabilityTypeShowToggle = getSessionStorage(key) === 'true';

  const { isPreferencesDataLoading } = useGetShoppingPreferenceDataLoadStatus(
    !isGuest,
    true
  );

  const showBySourceInTable = () => {
    return (
      hideLabel &&
      sourcingDetailsInformation?.length > 0 &&
      !hoseAssemblyDetails.hoseAssemblyIndicator &&
      ((availabilityTypeShowToggle && !showAvailabilityBySource) ||
        (!availabilityTypeShowToggle && showAvailabilityBySource))
    );
  };
  return (
    <>
      {showBySourceInTable() ? (
        <AvailabilityBySourceContent
          sourcingDetailsInformation={sourcingDetailsInformation}
          {...props}
        />
      ) : (
        <>
          {storeAvailability && availRemoveInterimStockFlag ? (
            <>
              {!!isPDPPage && (
                <StoreAvailability
                  isLoading={isLoading}
                  availabilityInformation={storeAvailability}
                  catEntryId={catEntryId}
                  className={hideHeaderAndDeliveryOptions && 'mt-3'}
                  showAdditionalInfo
                  hideHeaderAndDeliveryOptions={hideHeaderAndDeliveryOptions}
                  hideDeliveryCheckoutMessage={hideDeliveryCheckoutMessage}
                  hoseAssemblyDetails={hoseAssemblyDetails}
                  isPDPPage={isPDPPage}
                />
              )}
              {!isPDPPage && (
                <AvailabilityNoIntermStock
                  hideLabel={hideLabel}
                  showAdditionalInfo
                  className={hideHeaderAndDeliveryOptions && 'mt-3'}
                  availabilityInformation={storeAvailability}
                  hoseAssemblyDetails={hoseAssemblyDetails}
                  isLoading={isLoading}
                  catEntryId={catEntryId}
                  hideHeaderAndDeliveryOptions={hideHeaderAndDeliveryOptions}
                  hideDeliveryCheckoutMessage={hideDeliveryCheckoutMessage}
                />
              )}
            </>
          ) : (
            <PrimaryAvailability
              {...props}
              availabilityTypeShowToggle={
                toggleAvailability ? availabilityTypeShowToggle : false
              }
              isLoading={isPreferencesDataLoading || isLoading}
              hoseAssemblyDetails={hoseAssemblyDetails}
            />
          )}
          {isSourcingDetailsButtonShow &&
            sourcingDetailsInformation?.length > 0 &&
            !hideLabel &&
            !isLoading && <SecondaryAvailability {...props} />}
        </>
      )}
    </>
  );
};

Container.propTypes = {
  availabilityInformation: availabilityPropTypes,
  sourcingDetailsInformation: sourcingDetailsPropTypes,
  isAvailabilityShow: PropTypes.bool,
  hideLabel: PropTypes.bool,
  hideHeaderAndDeliveryOptions: PropTypes.bool,
  hideDeliveryCheckoutMessage: PropTypes.bool,
  storeAvailability: storeAvailabilityPropTypes,
  isPDPPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  hoseAssemblyDetails: PropTypes.shape({
    hoseAssemblyIndicator: PropTypes.bool,
    hoseAvailabilityLabel: PropTypes.string
  }),
  toggleAvailability: PropTypes.bool
};

export default Container;
