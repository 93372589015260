import { useContext } from 'react';
import { AlloyButton, FilterIcon, ModalContext } from 'cat-ecommerce-alloy';
import cx from 'classnames';
import DealerLocatorFilterModal from '../DealerLocatorFilterModal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { dealerlocatorResultsPropTypes } from '../declarations.proptypes';
import { useSystemFeedback } from '@app/hooks';
import { ERROR_PATH, ERROR_DOMAIN } from '../../../constants/errorConstants';

const DealerLocatorFilterButton = ({
  className,
  dealerStores,
  filters,
  setFilters,
  ...props
}) => {
  const [t] = useTranslation();
  const { addModalWithOptions } = useContext(ModalContext);
  const { clearError } = useSystemFeedback();

  const handleOnClick = () => {
    addModalWithOptions(
      <DealerLocatorFilterModal
        dealerStores={dealerStores}
        filters={filters}
        setFilters={setFilters}
      />,
      {
        title: t('Filter_And_Sort'),
        onClose: () =>
          clearError(ERROR_DOMAIN.DEALER_LOCATOR, ERROR_PATH.FILTERS)
      }
    );
  };
  return (
    <AlloyButton
      buttonType="ghost"
      className={cx(className)}
      size="small"
      onClick={handleOnClick}
      {...props}
    >
      <FilterIcon />
    </AlloyButton>
  );
};

export default DealerLocatorFilterButton;

DealerLocatorFilterButton.propTypes = {
  className: PropTypes.string,
  dealerStores: PropTypes.arrayOf(dealerlocatorResultsPropTypes),
  filters: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ),
  setFilters: PropTypes.func.isRequired
};
