export const addRegisteredServices = (serviceToRegister, state) => ({
  ...state,
  serviceCallsPendingToResolve: [
    ...state.serviceCallsPendingToResolve,
    serviceToRegister
  ]
});

export const removeRegisteredService = (serviceToUnregister, state) => {
  const serviceCallsPendingToResolve =
    state?.serviceCallsPendingToResolve?.filter(
      service => service !== serviceToUnregister
    );
  return {
    ...state,
    serviceCallsPendingToResolve
  };
};
