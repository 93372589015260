import PropTypes from 'prop-types';

export const errorPropTypes = PropTypes.shape({
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  title: PropTypes.string
});

export const storeHoursPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    currentDay: PropTypes.bool,
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })
);

export const dealerLocatorStorePropTypes = {
  address: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  dealerName: PropTypes.string,
  distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  drivingDirections: PropTypes.string,
  email: PropTypes.string,
  isClosest: PropTypes.bool,
  name: PropTypes.string,
  phone: PropTypes.string,
  serviceOffered: PropTypes.arrayOf(PropTypes.string),
  state: PropTypes.string,
  storeHours: storeHoursPropTypes,
  uniqueID: PropTypes.string,
  website: PropTypes.string,
  zipcode: PropTypes.string
};

export const dealerlocatorResultsPropTypes = PropTypes.shape(
  dealerLocatorStorePropTypes
);
