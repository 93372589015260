import { isEmpty } from '../../utils';
import {
  SERVER_ERRORS,
  AVAILABILITY_ERROR
} from '../../components/pages/checkout/shopping-cart/OrderItems/constants';
import { STATUS, USER_TYPE_GUEST } from '../../constants/commonConstants';
import * as types from './constants';
import * as savedListDetailTypes from '../savedListDetail/constants';
import { ordersInitialStore } from './initialStore';
import {
  formatQuantity,
  setError,
  clearLineItemError,
  getLineItemLoadingStatus,
  updateOrderItemNotes,
  setOrClearBundleErrors
} from './utils';
const ordersReducer = (state = {}, action) => {
  switch (action.type) {
    case types.ORDER_GET_IDLE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload]: {
            orderCallStatus: STATUS.IDLE
          }
        }
      };
    case types.ORDER_GET_BEGIN:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload]: {
            orderCallStatus: STATUS.PENDING
          }
        }
      };
    case types.ORDER_GET_FAIL:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload]: {
            orderCallStatus: STATUS.REJECTED
          }
        }
      };
    case types.ORDER_GET_ERP_DOWN:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            orderCallStatus: STATUS.REJECTED,
            orderCallStatusErrorMessage: action.payload.errorKey
          }
        },
        isERPDown: true
      };
    case types.ORDER_GET_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.order.orderId]: {
            orderCallStatus: STATUS.RESOLVED,
            isOffLineExportError: false,
            isERPDown: false,
            orderCallStatusErrorMessage: '',
            ...action.payload.order
          }
        },
        dropboxLabel: action.payload.dropboxLabel,
        currentGuestOrder:
          action.payload.userType === USER_TYPE_GUEST
            ? action.payload.order.orderId
            : state.currentGuestOrder
      };
    case types.ORDERS_GET_CURRENT_BEGIN: {
      return {
        ...state,
        getCurrentOrderLoading: true,
        getCurrentOrderSuccess: false,
        // Reason for wrapping flag under a condition: Refer [US-686667] - When cutOffDateTime lapses in S&P page
        // the RBD should be updated to accomodate new availability date. To restrict the reloading bahaviour of the components, wrapped in a condition.
        getCurrentOrderStatus: action.payload.isCallingFromCounter
          ? STATUS.RESOLVED
          : STATUS.PENDING
      };
    }
    case types.ORDERS_GET_CURRENT_SUCCESS: {
      const { byId, ...rest } = action.payload;
      const proceedToCheckoutButtonDisabled = action.payload.byId[
        action.payload.current
      ]?.ascendingOrderItems.some(item => item.packageError);
      return {
        ...state,
        hasOrderWithPrice: state.hasPriceData,
        getCurrentOrderLoading: false,
        getCurrentOrderSuccess: true,
        getCurrentOrderStatus: STATUS.RESOLVED,
        hasOrderData: true,
        isProceedToCheckoutBtnDisabled: !!proceedToCheckoutButtonDisabled,
        isUpdateCartQuantitiesDisabled: !!proceedToCheckoutButtonDisabled,
        isOrderPriceAvailabilityLoading: state?.isPriceLoadComplete
          ? false
          : state.isOrderPriceAvailabilityLoading,
        byId: { ...state.byId, ...byId },
        taxDisclaimerMessage: action.payload,
        taxDisclaimerMessageFooter: action.payload,
        ...rest
      };
    }
    // This action should be replaced with the generic action that will be implemented with the XORDER_BY_ID endpoint when available
    case savedListDetailTypes.SAVED_LIST_DETAIL_GET_SUCCESS: {
      const { orderInformation, orderId } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [orderId]: {
            orderInformation: {
              orderTotals: orderInformation.orderTotals ?? {},
              orderItem: orderInformation.orderItem ?? [],
              orderStatus: orderInformation.orderStatus ?? '',
              orderExtendAttribute: orderInformation.orderExtendAttribute ?? {}
            }
          }
        }
      };
    }
    case types.ORDERS_GET_CURRENT_FAIL: {
      return {
        ...state,
        getCurrentOrderLoading: false,
        getCurrentOrderSuccess: false,
        getCurrentOrderStatus: STATUS.REJECTED,
        hasOrderData: true,
        isProceedToCheckoutBtnDisabled: state.isPriceLoadComplete
          ? true
          : state.isProceedToCheckoutBtnDisabled,
        isOrderPriceAvailabilityLoading: state?.isPriceLoadComplete
          ? false
          : state.isOrderPriceAvailabilityLoading,
        hasCartError: true,
        isOrderSubtotalFailure: state.hasPriceData
      };
    }
    case types.ORDERS_UPDATE_ORDERED_BY_BEGIN:
      return { ...state };
    case types.ORDERS_UPDATE_ORDERED_BY_FAIL:
      return { ...state };
    case types.ORDERS_UPDATE_ORDERED_BY_SUCCESS:
      return {
        ...state,
        byId: {
          [state.current]: {
            ...state.byId[state.current],
            orderExtendAttribute: {
              ...state.byId[state.current].orderExtendAttribute,
              orderByName: action.payload.name,
              orderByEmail: action.payload.email,
              orderByPhone: action.payload.phone
            }
          }
        }
      };
    case types.RBD_UPDATE_ORDERED_BY_SUCCESS:
      return {
        ...state,
        requestByDate: {
          ...state.requestByDate,
          value: action.payload.formattedDate
        }
      };
    //TODO can add flags for loading states here in future enhance shopping cart stories
    case types.PRICE_AND_AVAILABILITY_BEGIN:
      return {
        ...state,
        isOrderPriceAvailabilityLoading: true,
        isPriceLoadComplete: false
      };
    case types.PRICE_AND_AVAILABILITY_FAIL:
      return {
        ...state,
        hasPriceData: false,
        isOrderPriceAvailabilityLoading: false,
        isPriceLoadComplete: false,
        isProceedToCheckoutBtnDisabled: true,
        hasCartError: true,
        isOrderSubtotalFailure: true
      };
    case types.PRICE_AND_AVAILABILITY_SUCCESS:
      return {
        ...state,
        hasPriceData: action.payload?.success ? true : false,
        isOrderPriceAvailabilityLoading: action.payload?.success
          ? state.isOrderPriceAvailabilityLoading
          : false,
        isPriceLoadComplete: true,
        replacedPartsInfo: action.payload.replacedPartsInfo,
        replacedParts: action.payload.replacedParts
      };
    case types.SET_TOTAL_ITEMS_ADDED_TO_CART:
      return {
        ...state,
        totalItemsAddedToCart: action.payload || 0
      };
    case types.RESET_REPLACED_PARTS_INFO:
      return {
        ...state,
        isOrderPriceAvailabilityLoading: false,
        isPriceLoadComplete: false,
        replacedPartsInfo: {},
        replacedParts: []
      };
    case types.RESET_REPLACED_PARTS_INFO_STATUS:
      return {
        ...state,
        isOrderPriceAvailabilityLoading: false,
        isPriceLoadComplete: false
      };
    case types.DECREMENT_ORDER_QUANTITY:
    case types.INCREMENT_ORDER_QUANTITY: {
      const selectedItems = state?.byId[state?.current].ascendingOrderItems.map(
        item =>
          item.orderItemId === action.payload.orderItemId
            ? action.payload
            : item
      );

      return {
        ...state,
        byId: {
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: selectedItems
          }
        }
      };
    }

    case types.SET_QUANTITY: {
      const selectedItems = state?.byId[
        state?.current
      ].ascendingOrderItems?.map(item =>
        item.orderItemId === action.payload.orderItemId
          ? {
              ...item,
              quantity: action.payload.quantity,
              hasBundleError: action.payload.hasBundleError
            }
          : item
      );
      return {
        ...state,
        isProceedToCheckoutBtnDisabled: true,
        byId: {
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: selectedItems
          }
        }
      };
    }

    case types.ORDERS_UPDATE_LINE_ITEM_BEGIN: {
      let isPackageError = false;
      const selectedItems = state?.byId[state?.current].ascendingOrderItems.map(
        item => {
          if (action.payload.indexOf(item.orderItemId) !== -1) {
            if (!isPackageError) {
              isPackageError = !!item.packageError;
            }
            return {
              ...item,
              isUpdateLineItemLoading: !item.packageError,
              errors: {}
            };
          } else {
            return item;
          }
        }
      );

      return {
        ...state,
        byId: {
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: selectedItems
          }
        },
        updatingStatus: STATUS.PENDING,
        isProceedToCheckoutBtnDisabled: true,
        isPackageTableLoading: isPackageError,
        isOrderSubtotalLoading: true,
        hasCartError: false
      };
    }

    case types.ORDERS_UPDATE_LINE_ITEM_FAIL: {
      const selectedItems = state?.byId[state.current].ascendingOrderItems.map(
        item =>
          action.payload.indexOf(item.orderItemId) !== -1
            ? {
                ...item,
                isUpdateLineItemLoading: false,
                errors: { availability: AVAILABILITY_ERROR }
              }
            : item
      );
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: selectedItems
          }
        },
        isProceedToCheckoutBtnDisabled: true,
        isOrderSubtotalLoading: false,
        isOrderSubtotalFailure: true,
        hasCartError: true,
        isPackageTableLoading: false,
        updatingStatus: STATUS.REJECTED
      };
    }
    case types.ORDERS_UPDATE_LINE_ITEM_SUCCESS: {
      const orderItems = state.byId[state.current].ascendingOrderItems;
      const selectedItems = orderItems.map(item => {
        const updatedItem = action.payload.find(
          newItem => newItem.orderItemId === item.orderItemId
        );
        return updatedItem
          ? {
              ...item,
              ...updatedItem,
              quantity: formatQuantity(updatedItem.quantity),
              serverQuantity: formatQuantity(updatedItem.quantity),
              isUpdateLineItemLoading: false,
              hasItemHadServerError: false,
              packageQuantity:
                updatedItem.packageQuantity ?? item.packageQuantity
            }
          : item;
      }, []);
      return {
        ...state,
        byId: {
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: selectedItems
          }
        },
        isPackageTableLoading: false
      };
    }

    case types.ORDERS_REMOVE_PACKAGE_ERROR: {
      const orderItems = state.byId[state.current].ascendingOrderItems;
      const selectedItems = orderItems.map(item => {
        const updatedItem = action.payload.find(
          orderItemId => orderItemId === item.orderItemId
        );

        return updatedItem
          ? {
              ...item,
              packageError: null
            }
          : item;
      }, []);
      return {
        ...state,
        byId: {
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: selectedItems
          }
        },
        isPackageTableLoading: false
      };
    }

    case types.ORDERS_REMOVE_PACKAGE_ERROR_PACKAGE: {
      const currentItems = state.byId[state.current].ascendingOrderItems;
      let isUpdateCartQuantitiesDisabled = true;
      currentItems.forEach(item => {
        if (!isEmpty(item.packageError)) {
          if (
            parseInt(item.quantity) !== 0 &&
            parseInt(item.quantity) % item.packageQuantity === 0
          ) {
            isUpdateCartQuantitiesDisabled = false;
          }
        }
      });

      return {
        ...state,
        isUpdateCartQuantitiesDisabled
      };
    }
    //TODO use these actions for loader
    case types.ORDERS_UPDATE_TOTAL_BEGIN:
      return {
        ...state,
        isOrderSubtotalLoading: true
      };

    case types.ORDERS_UPDATE_TOTAL_FAIL:
      const currentItems = state.byId[state.current].ascendingOrderItems;
      const selectedPackageErrorItems = currentItems.map(item => {
        const updatedItem = action.payload?.find(
          updatedItem => updatedItem.orderItemId === item.orderItemId
        );
        return updatedItem
          ? {
              ...item,
              packageQuantity:
                updatedItem.packageQuantity ?? item.packageQuantity
            }
          : item;
      }, []);
      return {
        ...state,
        byId: {
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: selectedPackageErrorItems
          }
        },
        isProceedToCheckoutBtnDisabled: true,
        isOrderSubtotalLoading: false,
        isOrderSubtotalFailure: true,
        updatingStatus: STATUS.REJECTED
      };

    case types.ORDERS_UPDATE_TOTAL_SUCCESS:
      const hasErrorsOrLoading = state.byId[
        state.current
      ].ascendingOrderItems.some(
        item =>
          !isEmpty(item.errors) ||
          item.isUpdateLineItemLoading ||
          !isEmpty(item.packageError)
      );
      return {
        ...state,
        byId: {
          [state.current]: {
            ...state.byId[state.current],
            orderTotals: {
              ...state.byId[state.current].orderTotals,
              orderSubTotal: action.payload.orderSubTotal,
              orderSubTotalWithGST: action.payload.orderSubTotalWithGST
            }
          }
        },
        isProceedToCheckoutBtnDisabled: hasErrorsOrLoading,
        updatingStatus: STATUS.RESOLVED,
        isOrderSubtotalFailure: hasErrorsOrLoading
          ? state.isOrderSubtotalFailure
          : false,
        isOrderSubtotalLoading: getLineItemLoadingStatus(
          state.byId[state.current].ascendingOrderItems
        )
      };

    case types.DELETE_CART_BEGIN: {
      return {
        ...state,
        emptyingCartStatus: STATUS.PENDING
      };
    }
    case types.DELETE_CART_FAIL: {
      return {
        ...state,
        emptyingCartStatus: STATUS.REJECTED
      };
    }

    case types.DELETE_CART_SUCCESS: {
      return {
        ...state,
        byId: {
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: [],
            orderTotals: {
              ...state.byId[state.current].orderTotals,
              orderSubTotal: '0.00'
            }
          }
        },
        emptyingCartStatus: STATUS.RESOLVED
      };
    }

    case types.ORDERS_SET_LINE_ITEM_ERROR:
      const orderItem = setError(
        state.byId[state.current].ascendingOrderItems,
        action.payload
      );
      let { isOrderSubtotalFailure, isOrderSubtotalLoading } = state;
      if (action.payload.error.colId === 'server') {
        isOrderSubtotalFailure = true;
        isOrderSubtotalLoading = false;
      }
      return {
        ...state,
        byId: {
          [state.current]: {
            ...state.byId[state.current],
            orderItem,
            ascendingOrderItems: orderItem
          }
        },
        isProceedToCheckoutBtnDisabled: true,
        isOrderSubtotalFailure,
        isOrderSubtotalLoading,
        updatingStatus: STATUS.REJECTED
      };
    case types.ORDERS_CLEAR_LINE_ITEM_ERROR:
      const updatedOrderItem = clearLineItemError(
        state.byId[state.current].ascendingOrderItems,
        action.payload
      );

      const hasOtherErrors = state.byId[state.current].ascendingOrderItems.some(
        item =>
          (item.orderItemId !== action.payload.orderItemId &&
            !isEmpty(item.errors)) ||
          !isEmpty(item.packageError)
      );
      let isProceedToCheckoutBtnDisabled = state.isProceedToCheckoutBtnDisabled;
      if (
        isEmpty(updatedOrderItem.errors) &&
        !hasOtherErrors &&
        !state.hasCartError
      ) {
        isProceedToCheckoutBtnDisabled = false;
      }
      return {
        ...state,
        byId: {
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: updatedOrderItem
          }
        },
        isProceedToCheckoutBtnDisabled
      };

    case types.PROCEED_TO_CHECKOUT_BEGIN: {
      return { ...state, isProceedToCheckoutLoading: true };
    }
    case types.PROCEED_TO_CHECKOUT_SUCCESS:
      return {
        ...state,
        isProceedToCheckoutLoading: false,
        checkoutType: action.payload
      };

    case types.PROCEED_TO_CHECKOUT_FAIL:
      return {
        ...state,
        isProceedToCheckoutLoading: false,
        hasCartError: action.payload.showRefreshButton
      };

    case types.DELETE_LINE_ITEM_BEGIN: {
      const selectedItems = state?.byId[state?.current].ascendingOrderItems.map(
        item =>
          item.orderItemId === action.payload.orderItemId
            ? {
                ...item,
                isDeleted: true,
                isUpdateLineItemLoading: true
              }
            : item
      );
      return {
        ...state,
        byId: {
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: selectedItems
          }
        },
        isProceedToCheckoutBtnDisabled: true,
        updatingStatus: STATUS.PENDING,
        isOrderSubtotalLoading: true
      };
    }
    case types.DELETE_LINE_ITEM_SUCCESS:
      const selectedItems = state.byId[
        state.current
      ].ascendingOrderItems.filter(
        item => item.orderItemId !== action.payload.orderItemId
      );
      return {
        ...state,
        byId: {
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: selectedItems
          }
        }
      };
    case types.DELETE_LINE_ITEM_FAIL: {
      const selectedItems = state?.byId[state?.current].ascendingOrderItems.map(
        item =>
          item.orderItemId === action.payload.orderItemId
            ? {
                ...item,
                isDeleted: false,
                isUpdateLineItemLoading: false,
                errors: { server: SERVER_ERRORS.DELETE },
                hasItemHadServerError: true
              }
            : item
      );
      return {
        ...state,
        byId: {
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: selectedItems
          }
        },
        updatingStatus: STATUS.REJECTED,
        isOrderSubtotalLoading: false,
        isOrderSubtotalFailure: true
      };
    }

    case types.ORDERS_SET_TO_IDLE:
      return {
        ...state,
        updatingStatus: STATUS.IDLE
      };

    case types.ORDERS_UPDATE_LINE_ITEM_NOTES_SUCCESS:
      const orderItems = state.byId[state.current].ascendingOrderItems;
      const updatedOrderItemNotes = updateOrderItemNotes(
        orderItems,
        action.payload
      );
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: updatedOrderItemNotes
          }
        },
        focusedInputNoteId: action.payload.focusedInputNoteId
      };

    case types.ORDERS_EMAIL_CART_BEGIN:
      return { ...state, isEmailCartLoading: true };

    case types.ORDERS_EMAIL_CART_SUCCESS:
      return { ...state, isEmailCartLoading: false };

    case types.ORDERS_EMAIL_CART_FAIL:
      return { ...state, hasCartError: true, isEmailCartLoading: false };

    case types.ORDERS_UPDATE_PARTIAL_REPLACEMENT_PARTS:
      return {
        ...state,
        replacedPartsInfo: { ...action.payload.replacementPartsInfo }
      };
    case types.ORDERS_DISABLE_PROCEED_TO_CHECKOUT_BUTTON:
      return {
        ...state,
        isProceedToCheckoutBtnDisabled: true
      };
    case types.ORDERS_REMOVE_ZERO_PRICE_LINE_ITEM:
      const originalOrderItems = state.byId[state.current].ascendingOrderItems;
      const orderItemsWithNoZeroPrice = originalOrderItems.filter(
        item => item.orderItemId !== action.payload
      );
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: orderItemsWithNoZeroPrice
          }
        }
      };

    case types.ORDERS_SAVE_PO_SUCCESS:
      const { orderId, poNumber, poAttachments } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [orderId]: {
            ...state.byId[orderId],
            purchaseOrderInfo: {
              ...state.byId[orderId].purchaseOrderInfo,
              poNumber,
              poAttachments: poAttachments.map(
                ({ fileName, fileSize, fileSizeUOM }) => ({
                  fileName,
                  fileSize,
                  fileSizeUOM
                })
              )
            }
          }
        }
      };
    case types.ORDERS_CLEAR_LINE_ITEM_NOTES_FOCUS:
      return {
        ...state,
        focusedInputNoteId: ''
      };

    case types.ORDERS_UPDATE_STATUS_BEGIN:
      return {
        ...state,
        updatingStatus: STATUS.PENDING
      };
    case types.ORDERS_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderInformation: {
              ...state.byId[action.payload.orderId].orderInformation,
              orderStatus: action.payload.orderStatus
            }
          }
        },
        updatingStatus: STATUS.RESOLVED
      };
    case types.ORDERS_UPDATE_STATUS_FAIL:
      return {
        ...state,
        updatingStatus: STATUS.REJECTED
      };
    case types.SAVED_LIST_DETAIL_UPDATE_QUANTITY_BEGIN: {
      const selectedItems = state?.byId[
        action.payload.currentId
      ].orderInformation.orderItem.map(item =>
        item.orderItemId === action.payload.orderItemId
          ? {
              ...item,
              quantity: action.payload.latestQuantityValue,
              oldQuantityValue: item.quantity,
              isItemLoading: true
            }
          : item
      );
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.currentId]: {
            ...state.byId[action.payload.currentId],
            orderInformation: {
              ...state.byId[action.payload.currentId].orderInformation,
              orderItem: selectedItems
            }
          }
        }
      };
    }
    case types.SAVED_LIST_UPDATE_QUANTITY: {
      const selectedItems = state?.byId[
        action.payload.currentId
      ].orderInformation.orderItem?.map(item =>
        item.orderItemId === action.payload.orderItemId
          ? {
              ...item,
              quantity: action.payload.quantity,
              oldQuantityValue: '',
              hasError: false,
              isItemLoading: false
            }
          : item
      );
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.currentId]: {
            ...state.byId[action.payload.currentId],
            orderInformation: {
              ...state.byId[action.payload.currentId].orderInformation,
              orderItem: selectedItems
            }
          }
        }
      };
    }
    case types.SAVED_LIST_UPDATE_QUANTITY_FAIL: {
      const selectedItems = state?.byId[
        action.payload.currentId
      ].orderInformation.orderItem.map(item =>
        item.orderItemId === action.payload.orderItemId
          ? {
              ...item,
              quantity: item.oldQuantityValue,
              oldQuantityValue: '',
              hasError: true,
              isItemLoading: false
            }
          : item
      );
      return {
        ...state,
        status: STATUS.REJECTED,
        byId: {
          [action.payload.currentId]: {
            ...state.byId[action.payload.currentId],
            orderInformation: {
              ...state.byId[action.payload.currentId].orderInformation,
              orderItem: selectedItems
            }
          }
        }
      };
    }
    case types.SAVED_LIST_DELETE_LINE_ITEM_BEGIN: {
      const selectedItems = state?.byId[
        action.payload.currentId
      ].orderInformation.orderItem.map(item =>
        item.orderItemId === action.payload.orderItemId
          ? {
              ...item,
              isDeleted: true //this key will make the delete animation on the alloy table
            }
          : item
      );
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.currentId]: {
            ...state.byId[action.payload.currentId],
            orderInformation: {
              ...state.byId[action.payload.currentId].orderInformation,
              orderItem: selectedItems
            }
          }
        }
      };
    }
    case types.SAVED_LIST_DELETE_LINE_ITEM_SUCCESS: {
      const selectedItems = state.byId[
        action.payload.currentId
      ].orderInformation.orderItem.filter(
        item => item.orderItemId !== action.payload.orderItemId
      );
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.currentId]: {
            ...state.byId[action.payload.currentId],
            orderInformation: {
              ...state.byId[action.payload.currentId].orderInformation,
              orderItem: selectedItems
            }
          }
        }
      };
    }
    case types.SAVED_LIST_DELETE_LINE_ITEM_FAIL: {
      const selectedItems = state?.byId[
        action.payload.currentId
      ].orderInformation.orderItem.map(item =>
        item.orderItemId === action.payload.orderItemId
          ? {
              ...item,
              quantity: item.oldQuantityValue,
              isDeleted: false,
              hasError: true,
              isItemLoading: false
            }
          : item
      );
      return {
        ...state,
        byId: {
          [action.payload.currentId]: {
            ...state.byId[action.payload.currentId],
            orderInformation: {
              ...state.byId[action.payload.currentId].orderInformation,
              orderItem: selectedItems
            }
          }
        }
      };
    }
    case types.ORDERS_CLEAR_BUNDLE_ERROR: {
      const orderItem = setOrClearBundleErrors(state, action.payload);
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: orderItem
          }
        }
      };
    }
    case types.ORDERS_DELETE_LINE_ITEM_BUNDLE_BEGIN:
    case types.ORDERS_REMOVE_BUNDLE_MODAL_ERROR:
      return { ...state, isLineItemDeleteFailed: false };

    case types.ORDERS_DELETE_LINE_ITEM_BUNDLE_SUCCESS:
      const { orderItemId, ssroIdList } = action.payload;
      const selectedBundleItems = state?.byId[
        state?.current
      ].ascendingOrderItems.map(item => {
        if (orderItemId.indexOf(item.orderItemId) !== -1) {
          const newSsroBundles =
            item.orderItemExtendAttribute.ssroBundle.filter(
              bundle => !ssroIdList.includes(bundle.selfServiceOptionId)
            );

          const ssroKitIds =
            item.orderItemExtendAttribute.googleAnalyticsAttributes?.kitId.split(
              ','
            );

          ssroKitIds.forEach(b =>
            ssroIdList.forEach(a => !b.includes(a) && ssroKitIds.pop(b))
          );

          return {
            ...item,
            orderItemExtendAttribute: {
              ...item.orderItemExtendAttribute,
              ssroBundle: newSsroBundles,
              googleAnalyticsAttributes: {
                ...item.orderItemExtendAttribute.googleAnalyticsAttributes,
                kitId: ssroKitIds.join(',')
              }
            }
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.current]: {
            ...state.byId[state.current],
            ascendingOrderItems: selectedBundleItems
          }
        },
        isLineItemDeleteFailed: false
      };

    case types.ORDERS_DELETE_LINE_ITEM_BUNDLE_FAIL: {
      return {
        ...state,
        isLineItemDeleteFailed: true
      };
    }

    case types.SAVED_LIST_UPDATE_LINE_ITEM_NOTE: {
      const orderItem =
        state?.byId[action.payload.orderId].orderInformation.orderItem;
      const updatedOrderItem = updateOrderItemNotes(orderItem, action.payload);
      return {
        ...state,
        byId: {
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderInformation: {
              ...state.byId[action.payload.orderId].orderInformation,
              orderItem: updatedOrderItem
            }
          }
        },
        focusedInputNoteId: action.payload.focusedInputNoteId
      };
    }
    case types.SHIPMENTS_BY_ORDER_ID_BEGIN: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            shipments: []
          }
        },
        shipmentsStatus: STATUS.PENDING
      };
    }

    case types.SHIPMENTS_BY_ORDER_ID_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            ...action.payload
          }
        },
        shipmentsStatus: STATUS.RESOLVED
      };
    }

    case types.SHIPMENTS_BY_ORDER_ID_FAIL: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            shipments: action.payload.shipments
          }
        },
        shipmentsStatus: STATUS.REJECTED
      };
    }
    case types.OFFLINE_ORDER_DETAIL_EXPORT_SUCCESS_FAIL: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            isOffLineExportError: action.payload.isError,

            orderCallStatusErrorMessage: action.payload.isError
              ? 'UNKNOWN_ERROR_MESSAGE'
              : ''
          }
        },
        isERPDown: false
      };
    }

    case types.OFFLINE_ORDER_HISTORY_EXPORT_SUCCESS_FAIL: {
      return {
        ...state,
        isOfflineListExportError: action.payload.isError
      };
    }

    case types.ORDER_FULFILLMENT_INFO_BEGIN: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            pickAndDeliveryStatus: STATUS.PENDING,
            fulfillment: {}
          }
        }
      };
    }

    case types.ORDER_FULFILLMENT_INFO_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            fulfillment: action.payload.fulfillment,
            pickAndDeliveryStatus: STATUS.RESOLVED
          }
        }
      };
    }

    case types.ORDER_FULFILLMENT_INFO_FAIL: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            fulfillment: {},
            pickAndDeliveryStatus: STATUS.REJECTED
          }
        }
      };
    }

    case types.ORDER_HEADER_INFO_BEGIN: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderHeaderInfoStatus: STATUS.PENDING
          }
        }
      };
    }

    case types.ORDER_HEADER_INFO_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderHeaderInfo: action.payload.orderHeaderInfo,
            orderHeaderInfoStatus: STATUS.RESOLVED
          }
        }
      };
    }

    case types.ORDER_HEADER_INFO_FAIL: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderHeaderInfoStatus: STATUS.REJECTED
          }
        }
      };
    }

    case types.ORDER_INFORMATION_GET_BEGIN:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderInformationStatus: STATUS.PENDING
          }
        }
      };

    case types.ORDER_INFORMATION_GET_FAIL:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderInformationStatus: STATUS.REJECTED
          }
        }
      };

    case types.ORDER_INFORMATION_GET_SUCCESS: {
      const { orderAttributes, orderId, dropboxLabel } = action.payload;
      const { purchaseOrderInfo } = orderAttributes;
      return {
        ...state,
        dropboxLabel,
        byId: {
          ...state.byId,
          [orderId]: {
            ...state.byId[orderId],
            ...orderAttributes,
            //TODO: We need delete the next mock and hard coded values because they should come from backend
            purchaseOrderInfo: {
              ...purchaseOrderInfo,
              poAttachments: purchaseOrderInfo?.poAttachments.map(
                ({ fileName, fileSize, fileSizeUOM }) => ({
                  fileName,
                  fileSize,
                  fileSizeUOM
                })
              )
            },
            orderId,
            orderInformationStatus: STATUS.RESOLVED
          }
        }
      };
    }

    case types.ORDER_CAL_INFO_GET_BEGIN:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderCalInfoStatus: STATUS.PENDING
          }
        }
      };

    case types.ORDER_CAL_INFO_GET_FAIL:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderCalInfoStatus: STATUS.REJECTED
          }
        }
      };

    case types.ORDER_CAL_INFO_GET_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            orderId: action.payload.orderId,
            ...state.byId[action.payload.orderId],
            ...action.payload.orderCalInfo,
            orderCalInfoStatus: STATUS.RESOLVED
          }
        }
      };

    case types.ORDER_BILLING_INFO_BEGIN: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            billingAndPaymentStatus: STATUS.PENDING,
            billingAndPayment: {}
          }
        }
      };
    }

    case types.ORDER_BILLING_INFO_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            billingAndPaymentStatus: STATUS.RESOLVED,
            billingAndPayment: action.payload.billingAndPayment
          }
        }
      };
    }

    case types.ORDER_BILLING_INFO_FAIL: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            billingAndPaymentStatus: STATUS.REJECTED,
            billingAndPayment: {}
          }
        }
      };
    }

    case types.ORDER_TOTAL_BEGIN: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderTotals: {},
            orderTotalStatus: STATUS.PENDING
          }
        }
      };
    }

    case types.ORDER_TOTAL_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderTotals: action.payload.orderTotals,
            orderTotalStatus: STATUS.RESOLVED
          }
        }
      };
    }

    case types.ORDER_TOTAL_FAIL: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderTotals: {},
            orderTotalStatus: STATUS.REJECTED
          }
        }
      };
    }

    case types.GET_ORDER_ITEM_INFO_BEGIN: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderItemInfoStatus: STATUS.PENDING
          }
        }
      };
    }

    case types.GET_ORDER_ITEM_INFO_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            ...action.payload.orderItemInfo,
            orderId: action.payload.orderId,
            orderItemInfoStatus: STATUS.RESOLVED
          }
        }
      };
    }

    case types.GET_ORDER_ITEM_INFO_FAIL: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderItemInfoStatus: STATUS.REJECTED
          }
        }
      };
    }

    case types.PRODUCT_DETAILS_BEGIN: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            productDetailsInfoStatus: STATUS.PENDING
          }
        }
      };
    }

    case types.PRODUCT_DETAILS_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            productDetails: action.payload.productDetails,
            productDetailsInfoStatus: STATUS.RESOLVED
          }
        }
      };
    }

    case types.PRODUCT_DETAILS_FAIL: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            productDetailsInfoStatus: STATUS.REJECTED
          }
        }
      };
    }

    case types.ORDER_PRICE_AVAILABILITY_TOTALS_BEGIN: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderPriceAvailabilityTotalsStatus: STATUS.PENDING
          }
        }
      };
    }

    case types.ORDER_PRICE_AVAILABILITY_TOTALS_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            ...action.payload.data,
            orderPriceAvailabilityTotalsStatus: STATUS.RESOLVED
          }
        }
      };
    }

    case types.ORDER_PRICE_AVAILABILITY_TOTALS_FAIL: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.orderId]: {
            ...state.byId[action.payload.orderId],
            orderPriceAvailabilityTotalsStatus: STATUS.REJECTED
          }
        }
      };
    }

    case types.UPDATE_ORDER_PO_ATTACHMENT_BEGIN: {
      const { orderId } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [orderId]: {
            ...state.byId[orderId],
            poAttachmentUpdateStatus: STATUS.PENDING
          }
        }
      };
    }
    case types.UPDATE_ORDER_PO_ATTACHMENT_FAIL: {
      const { orderId } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [orderId]: {
            ...state.byId[orderId],
            poAttachmentUpdateStatus: STATUS.REJECTED
          }
        }
      };
    }

    case types.UPDATE_ORDER_PO_ATTACHMENT_RESET: {
      const { orderId } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [orderId]: {
            ...state.byId[orderId],
            poAttachmentUpdateStatus: STATUS.IDLE
          }
        }
      };
    }

    case types.UPDATE_ORDER_PO_ATTACHMENT_SUCCESS: {
      const { poAttachments, orderId } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [orderId]: {
            ...state.byId[orderId],
            poAttachmentUpdateStatus: STATUS.RESOLVED,
            purchaseOrderInfo: {
              ...state.byId[orderId].purchaseOrderInfo,
              poAttachments: poAttachments?.map(
                ({ fileName, fileSize, fileSizeUOM }) => ({
                  fileName,
                  fileSize,
                  fileSizeUOM
                })
              )
            }
          }
        }
      };
    }

    case types.UPDATE_ORDER_REQUEST_BY_DATE: {
      const { requestByDate } = action.payload;
      return {
        ...state,
        requestByDate: requestByDate
      };
    }

    default: {
      return state;
    }
  }
};

export { ordersInitialStore };
export default ordersReducer;
