/* global google */
import { Map, Marker, MarkerBounds } from 'cat-ecommerce-alloy';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { useSelector } from 'react-redux';
import { DEFAULT_NUM_MAP_RESULTS, INACTIVE_MARKER_ICON } from '../../constants';
import { dealerlocatorResultsPropTypes } from '../../declarations.proptypes';
import { getLatLng } from '../../utils';
import DealerLocation from '../DealerLocationList/DealerLocation';
import MarkerControl from './MarkerControl';
import MarkerLabel from './MarkerLabel';
import SearchThisAreaButton from './SearchThisAreaButton';
import styles from './styles.module.scss';
import cx from 'classnames';

const DealerLocationMap = ({ results = [], setSelectedSuggestion, uom }) => {
  // Set initial store selected
  const { values, setFieldValue } = useFormikContext();
  const { storeLocation } = values;
  const { id } = results[0] ?? {};
  const selectedStore = results.find(store => storeLocation === store.id);

  useEffect(() => {
    if (!storeLocation || !selectedStore) {
      setFieldValue('storeLocation', id);
    }
  }, [id, storeLocation, selectedStore, setFieldValue]);

  const infoWindowRef = useRef();
  const handleMapClick = useCallback(() => infoWindowRef.current?.close(), []);

  const isInfoWindowOpen = infoWindow => {
    const infoMap = infoWindow.current?.getMap();
    return infoMap !== null && typeof infoMap !== 'undefined';
  };

  const selectedMarkerRef = useRef();

  const handleMarkerClick = useCallback(
    (map, marker) => {
      const activeMarker = results.find(store => store.id === marker.id);
      setFieldValue('storeLocation', activeMarker.id);

      const root = <MarkerLabel {...activeMarker} />;
      const contentString = renderToString(root);

      // set content via google infoWindow
      if (infoWindowRef.current) {
        infoWindowRef.current.setContent(contentString);
      } else {
        infoWindowRef.current = new google.maps.InfoWindow({
          content: contentString,
          disableAutoPan: true
        });
      }
      if (
        isInfoWindowOpen(infoWindowRef) &&
        selectedMarkerRef.current &&
        activeMarker.id === selectedMarkerRef.current
      ) {
        infoWindowRef.current.close();
      } else {
        infoWindowRef.current.open(map, marker);
      }
      selectedMarkerRef.current = activeMarker.id;
    },
    [results, setFieldValue]
  );

  const selectedStoreIndex = results.findIndex(
    store => storeLocation === store.id
  );
  const displayedResults =
    selectedStoreIndex >= DEFAULT_NUM_MAP_RESULTS
      ? [...results.slice(0, DEFAULT_NUM_MAP_RESULTS - 1), selectedStore]
      : results.slice(0, DEFAULT_NUM_MAP_RESULTS);

  const feedbackError = useSelector(
    state => state?.errors?.dealerLocator?.locations
  );

  return !feedbackError ? (
    <div
      name="dealer-locator__map-view-container"
      data-testid="map-view-container"
      className={cx(
        'position-relative',
        styles['dealer-locator__map-view-container']
      )}
    >
      <Map
        id="dealerLocatorMap"
        className="height-400px"
        onClick={handleMapClick}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
      >
        <MarkerBounds />
        {displayedResults.map(store => (
          <Marker
            key={store.uniqueID}
            id={store.id}
            position={getLatLng(store.latitude, store.longitude)}
            onClick={handleMarkerClick}
            icon={INACTIVE_MARKER_ICON}
            name="dealerLocator__map-marker-button"
          />
        ))}
        <MarkerControl
          stores={displayedResults}
          selectedMarkerId={storeLocation}
        />
        <SearchThisAreaButton
          results={displayedResults}
          setSelectedSuggestion={setSelectedSuggestion}
        />
      </Map>
      {selectedStore && (
        <ul className="list-group mt-2 ps-0">
          <DealerLocation
            key={selectedStore.uniqueID}
            {...selectedStore}
            isMapView={true}
            uom={uom}
          />
        </ul>
      )}
    </div>
  ) : null;
};

DealerLocationMap.propTypes = {
  results: PropTypes.arrayOf(dealerlocatorResultsPropTypes),
  setSelectedSuggestion: PropTypes.func,
  uom: PropTypes.string
};

export default DealerLocationMap;
