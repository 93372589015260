import { STATUS } from '@app/constants/commonConstants';
import * as types from './constants';
import { repairOptionsInitialStore } from './initialStore';
import {
  displayContactDealer,
  enrichPartsLists,
  fetchCartQuantity,
  generateSelectedConfigurationPart,
  generateSelectedDealerPart,
  getSelectedLines,
  mergePriceAndAvailability,
  mergeSOSCodeForParts,
  populateHeaderSelections
} from './utils';

const repairOptionsReducer = (state = repairOptionsInitialStore, action) => {
  switch (action.type) {
    case types.RO_GET_INFO_BEGIN: {
      return {
        ...state,
        isPageLoading: true
      };
    }
    case types.RO_GET_INFO_SUCCESS: {
      const {
        partHeaderAttributes = [],
        partHeaders = {},
        breadCrumb = [],
        isPMKit = false,
        isCATKit = false,
        isMixedResponse = false,
        ...rest
      } = action.payload;
      const allPartsLists = enrichPartsLists(partHeaders);

      const {
        selectedPartsImages,
        selectedPartsList,
        selectedRecommendedTools,
        selectedOptionalParts,
        selectedGroupNumber
      } = populateHeaderSelections(allPartsLists, '', isPMKit, isMixedResponse);
      return {
        ...state,
        isPMKit,
        isPageLoading: false,
        isCATKit,
        breadCrumb,
        partsListOptions: [...partHeaderAttributes],
        allPartsLists,
        bundleInfo: { ...rest },
        selectedGroupNumber,
        selectedPartsImages,
        selectedPartsList,
        selectedRecommendedTools,
        selectedOptionalParts,
        isHeaderSelected: partHeaderAttributes.length > 1 ? false : true
      };
    }
    case types.RO_SELECTED_DEALER_SUCCESS: {
      const { selectedDealerPart = {}, reactKey } = action.payload;
      const selectedPartsList = generateSelectedDealerPart({
        partsList: state.selectedPartsList,
        reactKey,
        selectedDealerPart
      });
      return {
        ...state,
        selectedPartsList
      };
    }
    case types.RO_SELECTED_CONFIGURATION_SUCCESS: {
      const {
        reactKey,
        stepId,
        selectedOptionalParts,
        isLineItemLoading,
        selectedConfiguration = ''
      } = action.payload;
      const selectedPartsList = generateSelectedConfigurationPart(
        state.selectedPartsList,
        selectedConfiguration,
        reactKey,
        stepId,
        isLineItemLoading
      );

      return {
        ...state,
        selectedPartsList,
        selectedOptionalParts
      };
    }
    case types.RO_PRICE_LOAD_PARTS_LIST_BEGIN:
    case types.RO_GET_SOS_CODE_PARTS_LIST_BEGIN: {
      return {
        ...state,
        isRequiredPriceAndAvailabilityLoading: true
      };
    }
    case types.RO_GET_SOS_CODE_RELATED_BEGIN:
    case types.RO_PRICE_LOAD_RELATED_BEGIN: {
      return {
        ...state,
        isRelatedPriceAndAvailabilityLoading: true
      };
    }
    case types.RO_GET_SOS_CODE_RECOMMENDED_BEGIN:
    case types.RO_PRICE_LOAD_RECOMMENDED_BEGIN: {
      return {
        ...state,
        isRecommendedPriceAndAvailabilityLoading: true
      };
    }
    case types.RO_ADD_TO_CART_BEGIN: {
      return {
        ...state,
        isAddToCartLoading: true,
        addToCartInitializationPoint:
          action.payload?.addToCartInitializationPoint
      };
    }
    case types.RO_GET_SOS_CODE_PARTS_LIST_FAIL:
    case types.RO_PRICE_LOAD_PARTS_LIST_FAIL: {
      return {
        ...state,
        isRequiredPriceAndAvailabilityLoading: false
      };
    }
    case types.RO_GET_SOS_CODE_RELATED_FAIL:
    case types.RO_PRICE_LOAD_RELATED_FAIL: {
      return {
        ...state,
        isRelatedPriceAndAvailabilityLoading: false
      };
    }
    case types.RO_GET_SOS_CODE_RECOMMENDED_FAIL:
    case types.RO_PRICE_LOAD_RECOMMENDED_FAIL: {
      return {
        ...state,
        isRecommendedPriceAndAvailabilityLoading: false
      };
    }
    case types.RO_ADD_TO_CART_FAIL: {
      return {
        ...state,
        isAddToCartLoading: false
      };
    }
    case types.RO_GET_INFO_FAIL: {
      return {
        ...state,
        isPageLoading: false
      };
    }

    case types.RO_PRICE_LOAD_PARTS_LIST_SUCCESS: {
      const { priceAndAvailability, ...rest } = action.payload;
      return {
        ...state,
        showPriceAndAvailabilityFlag: true,
        isRequiredPriceAndAvailabilityLoading: false,
        hasPriceData: true,
        selectedPartsList: mergePriceAndAvailability({
          partsList: state.selectedPartsList,
          priceAndAvailability
        }),
        ...rest
      };
    }

    case types.RO_PRICE_LOAD_PARTS_LIST_CACHED: {
      const { priceAndAvailability, ...rest } = action.payload;
      const selectedPartsList = mergePriceAndAvailability({
        partsList: state.selectedPartsList,
        priceAndAvailability
      });
      const enableAddAll = selectedPartsList.some(item => item.enableAddToCart);
      return {
        ...state,
        showPriceAndAvailabilityFlag: true,
        hasPriceData: true,
        isRequiredPriceAndAvailabilityLoading: false,
        selectedPartsList,
        enableAddAllToCartCached: enableAddAll,
        ...rest
      };
    }
    case types.RO_PRICE_LOAD_RELATED_SUCCESS: {
      const { priceAndAvailability, bundleTotal, ...rest } = action.payload;
      return {
        ...state,
        isRelatedPriceAndAvailabilityLoading: false,
        hasPriceData: true,
        selectedOptionalParts: mergePriceAndAvailability({
          partsList: state.selectedOptionalParts,
          priceAndAvailability
        }),
        ...rest
      };
    }
    case types.RO_PRICE_LOAD_RELATED_CACHED: {
      const { priceAndAvailability } = action.payload;
      return {
        ...state,
        isRelatedPriceAndAvailabilityLoading: false,
        hasPriceData: true,
        selectedOptionalParts: mergePriceAndAvailability({
          partsList: state.selectedOptionalParts,
          priceAndAvailability
        })
      };
    }
    case types.RO_PRICE_LOAD_RECOMMENDED_SUCCESS: {
      const { priceAndAvailability, bundleTotal, ...rest } = action.payload;
      return {
        ...state,
        isRecommendedPriceAndAvailabilityLoading: false,
        hasPriceData: true,
        selectedRecommendedTools: mergePriceAndAvailability({
          partsList: state.selectedRecommendedTools,
          priceAndAvailability
        }),
        ...rest
      };
    }
    case types.RO_PRICE_LOAD_RECOMMENDED_CACHED: {
      const { priceAndAvailability } = action.payload;
      return {
        ...state,
        isRecommendedPriceAndAvailabilityLoading: false,
        hasPriceData: true,
        selectedRecommendedTools: mergePriceAndAvailability({
          partsList: state.selectedRecommendedTools,
          priceAndAvailability
        })
      };
    }
    case types.RO_SET_PART_QUANTITY: {
      const selectedPartsList = state.selectedPartsList.map(part =>
        part.reactKey === action.payload.reactKey ? action.payload : part
      );
      return {
        ...state,
        selectedPartsList
      };
    }
    case types.RO_RESET_PART_QUANTITIES: {
      const selectedPartsList = state.selectedPartsList.map(part => ({
        ...part,
        selectedQty: part.quantity
      }));
      return {
        ...state,
        selectedPartsList
      };
    }

    case types.RO_GET_CART_ITEMS_SUCCESS: {
      return {
        ...state,
        selectedPartsList: fetchCartQuantity(
          state.selectedPartsList,
          action.payload.orderItem
        ),
        isAddToCartLoading: false
      };
    }

    case types.RO_SELECT_PART_HEADER: {
      const {
        selectedPartsImages,
        selectedPartsList,
        selectedRecommendedTools,
        selectedOptionalParts,
        selectedGroupNumber
      } = populateHeaderSelections(state.allPartsLists, action.payload);

      return {
        ...state,
        selectedGroupNumber,
        selectedPartsImages,
        selectedPartsList,
        selectedRecommendedTools,
        selectedOptionalParts,
        isHeaderSelected: true
      };
    }

    case types.RO_WRITE_READY_EVENTS: {
      return {
        ...state,
        writeReadyEvents: action.payload.write
      };
    }
    case types.RO_GET_SOS_CODE_PARTS_LIST_SUCCESS: {
      const { sosData } = action.payload.sosResponse;
      return {
        ...state,
        isRequiredPriceAndAvailabilityLoading: false,
        selectedPartsList: mergeSOSCodeForParts(
          state.selectedPartsList,
          sosData,
          state.isPMKit
        )
      };
    }
    case types.RO_GET_SOS_CODE_RELATED_SUCCESS: {
      const { sosData } = action.payload.sosResponse;
      return {
        ...state,
        isRelatedPriceAndAvailabilityLoading: true,
        selectedOptionalParts: mergeSOSCodeForParts(
          state.selectedOptionalParts,
          sosData,
          state.isPMKit
        )
      };
    }
    case types.RO_GET_SOS_CODE_RECOMMENDED_SUCCESS: {
      const { sosData } = action.payload.sosResponse;
      return {
        ...state,
        isRecommendedPriceAndAvailabilityLoading: true,
        selectedRecommendedTools: mergeSOSCodeForParts(
          state.selectedRecommendedTools,
          sosData,
          state.isPMKit
        )
      };
    }
    case types.RO_UPDATE_LINE_ITEM_PRICE_LOAD_BEGIN: {
      const identifiers = action.payload;
      let selectedLineItems = [];
      identifiers.forEach(({ reactKey, partNumber }) => {
        const newSelectedLineItems = getSelectedLines(
          partNumber,
          state.selectedPartsList,
          reactKey
        );
        selectedLineItems = [
          ...new Set([...selectedLineItems, ...newSelectedLineItems])
        ];
      });
      return {
        ...state,
        selectedPartsList: selectedLineItems,
        totalPriceStatus: STATUS.PENDING
      };
    }
    case types.RO_UPDATE_TOTAL_PRICE_LOAD_BEGIN: {
      return {
        ...state,
        totalPriceStatus: STATUS.PENDING
      };
    }
    case types.RO_UPDATE_TOTAL_PRICE_LOAD_FAIL: {
      return {
        ...state,
        totalPriceStatus: STATUS.REJECTED
      };
    }
    case types.RO_UPDATE_LINE_ITEM_PRICE_LOAD_FAIL: {
      const identifiers = action.payload;
      let selectedLineItems = [];

      identifiers.forEach(({ reactKey, partNumber }) => {
        const newSelectedLineItems = state.selectedPartsList.map(item => {
          if (item.partNumber === partNumber && item.reactKey === reactKey) {
            item.isLineItemLoading = false;
            item.isLineItemError = true;
          }
          return item;
        });

        selectedLineItems = [
          ...new Set([...selectedLineItems, ...newSelectedLineItems])
        ];
      });

      return {
        ...state,
        selectedPartsList: selectedLineItems,
        totalPriceStatus: STATUS.REJECTED
      };
    }
    case types.RO_UPDATE_LINE_ITEM_PRICE_LOAD_SUCCESS: {
      const {
        priceAndAvailability,
        bundleTotal,
        enableAddAllToCart,
        reactKeys,
        ...rest
      } = action.payload;
      const selectedPartsList = mergePriceAndAvailability({
        reactKeys,
        partsList: state.selectedPartsList,
        priceAndAvailability
      });
      const enableAddAll = selectedPartsList.some(item => item.enableAddToCart);
      return {
        ...state,
        hasPriceData: true,
        selectedPartsList,
        enableAddAllToCart: enableAddAll,
        ...rest
      };
    }
    case types.RO_UPDATE_LINE_ITEM_PRICE_LOAD_CACHED: {
      const { priceAndAvailability, reactKeys } = action.payload;
      const selectedPartsList = mergePriceAndAvailability({
        reactKeys,
        partsList: state.selectedPartsList,
        priceAndAvailability
      });
      const enableAddAll = selectedPartsList.some(item => item.enableAddToCart);
      return {
        ...state,
        hasPriceData: true,
        selectedPartsList,
        enableAddAllToCartCached: enableAddAll
      };
    }
    case types.RO_UPDATE_TOTAL_PRICE_LOAD_SUCCESS: {
      const { formattedTotal } = action.payload;
      const items = state.selectedPartsList;
      const areLineItemsLoading = items.some(item => item.isLineItemLoading);
      return {
        ...state,
        bundleTotal: formattedTotal,
        totalPriceStatus: areLineItemsLoading ? STATUS.PENDING : STATUS.RESOLVED
      };
    }
    case types.RO_CLEAR_TOTAL_PRICE: {
      return {
        ...state,
        ...action.payload
      };
    }
    case types.RO_CLEAR_PRICE_LOAD: {
      const selectedPartNumber = action.payload;
      return {
        ...state,
        selectedPartsList: displayContactDealer(
          state.selectedPartsList,
          selectedPartNumber
        )
      };
    }
    case types.RO_UPDATE_OPTIONAL_PARTS: {
      return {
        ...state,
        selectedOptionalParts: action.payload
      };
    }
    case types.LOADING_RECOMMENDED_TOOLS_PRICE_BEGIN: {
      return {
        ...state,
        recommendedToolsPriceLoading: true
      };
    }
    case types.LOADING_RECOMMENDED_TOOLS_PRICE_SUCCESS:
    case types.LOADING_RECOMMENDED_TOOLS_PRICE_FAIL: {
      return {
        ...state,
        recommendedToolsPriceLoading: false
      };
    }
    case types.LOADING_PARTS_PRICE_BEGIN: {
      return {
        ...state,
        partsTablePriceLoading: true
      };
    }
    case types.LOADING_PARTS_PRICE_SUCCESS:
    case types.LOADING_PARTS_PRICE_FAIL: {
      return {
        ...state,
        partsTablePriceLoading: false
      };
    }
    case types.LOADING_RELATED_PARTS_PRICE_BEGIN: {
      return {
        ...state,
        relatedPartsPriceLoading: true
      };
    }
    case types.LOADING_RELATED_PARTS_PRICE_SUCCESS:
    case types.LOADING_RELATED_PARTS_PRICE_FAIL: {
      return {
        ...state,
        relatedPartsPriceLoading: false
      };
    }
    default:
      return state;
  }
};

export { repairOptionsInitialStore };
export default repairOptionsReducer;
