import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { STATUS, TIMEOUT_FORTY_SECONDS } from '@app/constants/commonConstants';
import { replaceTokensInUrl } from '@app/utils';
import { getHttpInstance } from '@app/services/defaultHttpService';
import endpoints from '@app/constants/endpoints';

const useGetDealerCustomerStores = () => {
  const controllerRef = useRef(new AbortController());
  const langId = useSelector(state => state.common?.langId);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(STATUS.IDLE);

  const abort = () => {
    controllerRef.current.abort();
    controllerRef.current = new AbortController();
  };

  const reset = () => {
    setData(null);
    setError(null);
    setStatus(STATUS.IDLE);
  };

  const invoke = ({ dealer, customerNumber }) => {
    setData(null);
    setError(null);
    setStatus(STATUS.PENDING);
    const http = getHttpInstance(TIMEOUT_FORTY_SECONDS);
    const url = replaceTokensInUrl(
      endpoints.DEALER_CUSTOMER_SERVICE,
      dealer,
      customerNumber,
      langId
    );
    http
      .request({
        method: 'get',
        url: encodeURI(url),
        signal: controllerRef.current.signal
      })
      .then(({ data }) => {
        setData(data);
        setStatus(STATUS.RESOLVED);
        setError(null);
      })
      .catch(err => {
        setError(err);
        setStatus(STATUS.REJECTED);
        throw err;
      });
  };

  return {
    data,
    error,
    status,
    abort,
    invoke,
    reset
  };
};

export default useGetDealerCustomerStores;
