import PropTypes from 'prop-types';
import Conditional from '@app/components/common/Conditional';
import { GridSystemItem, SkeletonLoader } from 'cat-ecommerce-alloy';
import styles from './SkeletonCategoryCard.module.scss';
import cx from 'classnames';

const SkeletonCategoryCard = ({ isSkeletonCardLarge = false }) => {
  return (
    <GridSystemItem
      className={cx(styles['skeleton-category-card'])}
      variant="divider"
    >
      <GridSystemItem.Element>
        <SkeletonLoader
          className={cx(
            'mb-2',
            styles['skeleton-category-card__content-height'],
            {
              [styles['skeleton-category-card__content-height-large']]:
                isSkeletonCardLarge
            }
          )}
        />
        <Conditional test={!isSkeletonCardLarge}>
          <SkeletonLoader
            className={styles['skeleton-category-card__label-height']}
          />
        </Conditional>
      </GridSystemItem.Element>
    </GridSystemItem>
  );
};

SkeletonCategoryCard.propTypes = {
  isSkeletonCardLarge: PropTypes.bool
};

export default SkeletonCategoryCard;
