import { STATUS } from '../../constants/commonConstants';
import * as commonTypes from '../common/constants';
import * as dealerTypes from '../dealer/constants';

const PersonalInformationReducer = (state = {}, action) => {
  switch (action.type) {
    case commonTypes.COMMON_GET_VARS_BEGIN: {
      return {
        ...state,
        commonVarsStatus: STATUS.PENDING
      };
    }
    case commonTypes.COMMON_GET_VARS_SUCCESS: {
      return {
        ...state,
        commonVarsStatus: STATUS.RESOLVED
      };
    }
    case commonTypes.COMMON_GET_VARS_FAIL: {
      return {
        ...state,
        commonVarsStatus: STATUS.REJECTED
      };
    }
    case dealerTypes.DEALER_GET_INFO_BEGIN: {
      return {
        ...state,
        dealerVarsStatus: STATUS.PENDING
      };
    }
    case dealerTypes.DEALER_GET_INFO_SUCCESS: {
      return {
        ...state,
        dealerVarsStatus: STATUS.RESOLVED
      };
    }
    case dealerTypes.DEALER_GET_INFO_FAIL: {
      return {
        ...state,
        dealerVarsStatus: STATUS.REJECTED
      };
    }
    case commonTypes.SAVE_USER_INFO_BEGIN: {
      return {
        ...state,
        status: STATUS.PENDING
      };
    }
    case commonTypes.SAVE_USER_INFO_SUCCESS: {
      return {
        ...state, //TODO: implement save logic.
        status: STATUS.RESOLVED
      };
    }
    case commonTypes.SAVE_TAX_INFO_SUCCESS: {
      return {
        ...state,
        status: STATUS.RESOLVED
      };
    }
    case commonTypes.SAVE_USER_INFO_FAIL: {
      return {
        ...state,
        status: STATUS.REJECTED
      };
    }
    default: {
      return state;
    }
  }
};

export { personalInformationInitialStore } from './initialStore';
export default PersonalInformationReducer;
