export const HEADER = 'react-root-header';
export const HEADER_WRAPPER = 'headerWrapper';
export const MARKETING_RIBBON = 'react-root-marketing-ribbon';
export const WRAPPER = 'wrapper';
export const HEADER_WIDGET = 'headerWidget';
export const DRAWERS_ROOT = 'react-root-modals';
export const SUBMENU_ROOT = 'react-root-subheader';
export const SUB_CAT_ADD_EQUIPMENT = 'react-root-subcat-addequipment';
export const SUB_CAT_DISPLAY_EQUIPMENT = 'react-root-subcat-displayequipment';
export const SUB_CAT_SELECT_EQUIPMENT = 'react-root-subcat-selectequipment';
export const FITMENT_ADD_EQUIPMENT = 'react-root-fitment-addequipment';
export const FITMENT_SELECT_EQUIPMENT = 'react-root-fitment-selectequipment';
export const FITMENT_VIEW_EDIT_EQUIPMENT =
  'react-root-fitment-vieweditequipment';
export const ACCOUNT_PERSONAL_INFO = 'react-root-personal-information';
export const HOMEPAGE_SHOP_BY = 'react-root-homepage-shop-by';
export const HOMEPAGE_RECENTLY_VIEWED = 'react-root-homepage-recently-viewed';
export const HOMEPAGE_TOPPURCHASED = 'react-root-homepage-top-purchased';
export const HOMEPAGE_FEATURED_PRODUCTS =
  'react-root-homepage-featured-products';
export const HOMEPAGE_BEST_SELLERS = 'react-root-homepage-best-sellers';
export const CURRENT_PAGE = 'react-root-current-page';
export const FOOTER_ROOT = 'react-root-footer';
export const FOOTER_CHECKOUT_ROOT = 'react-root-checkout-footer';
export const MODALS_ROOT = 'react-root-modals';
// TODO remove after PU&D is complete
export const LEGACY_CHECKOUT_TARGET = 'react-root-checkout-summary-payment';
export const HELP_CENTER_PAGE = 'react-root-help-center';
export const ABOUT_CAT_PARTS = 'react-root-about-us';
export const REPAIR_OPTIONS_TARGET = 'react-root-repair-options';
export const PDP_PAGE = 'react-root-product';
export const MACHINELANDINGPAGE = 'react-root-mlp';
export const CMC_MARKETING_TARGET = 'react-root-marketing';
export const CART_ORDER_ITEMS = 'react-root-shopping-cart-items';
export const NOTIFICATION_ROOT = 'react-root-notification';
export const SHOPPING_CART_PAGE = 'react-root-cart';
export const CART_FLOATING_CART = 'react-root-floating-cart';

export const REACT_ROOT_MAIN = 'react-root-main';
export const CSR_LANDING_PAGE = 'react-root-csr-landing-page';
export const ORDER_DETAIL_ROOT = 'react-root-order-display-page';
export const APP_BANNERS = 'react-root-app-banners';
export const APP_BANNER_ROOT = 'react-root-app-banner';
export const ORDER_DETAILS_WARRANTY_RETURN_POLICY_VIEW =
  'react-root-order-details-warranty-return-policy';
export const REQUEST_RETURN_HISTORY_VIEW = 'react-root-request-return-history';
//AZURE#283129 - Added for QO Phase1 feature flag
export const LEGACY_SHOPPING_CART_PAGE = 'react-root-shopping-cart';
export const ACCOUNT_NAV_BOTTOM = 'react-root-account-nav-bottom';
export const LEGAL_NOTICE = 'react-root-legal-notices';
export const WARRANTY_RETURN_POLICY = 'react-root-warranty-and-returns';
export const TERMS_AND_CONDITIONS = 'react-root-terms-and-conditions';
export const SEARCH_PAGE = 'react-root-espot';
export const SEARCH_RESULTS = 'react-root-search-results-mlp';

// React 404 page root
export const ERROR_PAGE = 'react-root-generic-error';
export const TEMP_ORDER_DETAIL_BREADCRUMB =
  'react-root-breadcrumb-order-display-page';
export const DEALERY_PRIVACY_POLICY = 'react-root-privacy-policy-page';
export const CHECKOUT_DISPLAY_CREDIT =
  'react-root-cat-credit--displayCertificates';
export const CHECKOUT_APPLY_CREDIT = 'react-root-cat-credit--applyCertificate';
export const ORDER_STATUS_INQUIRY = 'react-root-order-status-inquiry';
export const ORDER_STATUS_DETAILS = 'react-root-order-status-details';
export const REACT_ROOT = 'react-root';
export const TAX_DETAILS_PAGE = 'react-root-tax-details-page';
