import { AFFILIATION_DEALER, USER_ROLES } from '@app/constants/commonConstants';

const isDealerUser = userAffiliation => userAffiliation === AFFILIATION_DEALER;

const isCALRole = userRoles => {
  const CALRoles = [
    USER_ROLES.ADMIN,
    USER_ROLES.APPROVER,
    USER_ROLES.SUBMITTER
  ];

  return CALRoles.some(role => userRoles.includes(role));
};

const isCSPWithCAL = (isCSP, customerAuthorityManager) =>
  isCSP && customerAuthorityManager;

const isCSPOrDealerUser = (isCSP, userAffiliation) =>
  isCSP || isDealerUser(userAffiliation);

const showPendingQuoteFeature = (isCSP, userAffiliation, quoteEnabled) =>
  isCSPOrDealerUser(isCSP, userAffiliation) && quoteEnabled;

export {
  isCSPWithCAL,
  isCSPOrDealerUser,
  isDealerUser,
  isCALRole,
  showPendingQuoteFeature
};
