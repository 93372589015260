import { STATUS } from '../../constants/commonConstants';
import * as types from './constants';
import { organizationsAndUsersInitialStore } from './initialStore';

const organizationsAndUsersReducer = (
  state = organizationsAndUsersInitialStore,
  action = {}
) => {
  switch (action.type) {
    case types.ORGS_AND_USERS_GET_BEGIN: {
      return {
        ...state,
        dealerCustomerNumberStatus: STATUS.PENDING
      };
    }
    case types.ORGS_AND_USERS_GET_SUCCESS: {
      return {
        ...state,
        dealerCustomerNumberStatus: STATUS.RESOLVED,
        organizations: action.payload
      };
    }
    case types.ORGS_AND_USERS_GET_FAIL: {
      return {
        ...state,
        dealerCustomerNumberStatus: STATUS.REJECTED
      };
    }
    case types.USERS_GET_BEGIN: {
      return {
        ...state,
        searchStatus: STATUS.PENDING
      };
    }
    case types.USERS_GET_SUCCESS: {
      return {
        ...state,
        searchStatus: STATUS.RESOLVED,
        users: action.payload
      };
    }
    case types.USERS_GET_FAIL: {
      return {
        ...state,
        users: {},
        searchStatus: STATUS.REJECTED
      };
    }
    case types.SEARCH_FORM_SET: {
      return {
        ...state,
        searchFormValues: {
          ...organizationsAndUsersInitialStore.searchFormValues,
          ...action.payload
        }
      };
    }
    case types.USERS_STATUS_CHANGE_BEGIN: {
      return {
        ...state,
        setUserStateStatus: STATUS.PENDING
      };
    }
    case types.USERS_STATUS_CHANGE_SUCCESS: {
      const users = state.users;
      const currentUser = users[action.payload.userId];
      return {
        ...state,
        setUserStateStatus: STATUS.RESOLVED,
        users: {
          ...users,
          [action.payload.userId]: {
            ...currentUser,
            userRegistry: {
              ...currentUser.userRegistry,
              status: action.payload.state
            }
          }
        }
      };
    }
    case types.USERS_STATUS_CHANGE_FAIL: {
      return {
        ...state,
        setUserStateStatus: STATUS.REJECTED
      };
    }
    case types.GET_USER_DETAILS_BEGIN: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          getUserDetailsStatus: STATUS.PENDING
        }
      };
    }
    case types.GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
          getUserDetailsStatus: STATUS.RESOLVED
        }
      };
    }
    case types.GET_USER_DETAILS_FAIL: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          getUserDetailsStatus: STATUS.REJECTED
        }
      };
    }
    case types.ORGANIZATIONS_USERS_INFO_BEGIN: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          setOrganizationUsersInfo: STATUS.PENDING
        }
      };
    }
    case types.ORGANIZATIONS_USERS_INFO_SUCCESS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          organizations: action.payload,
          setOrganizationUsersInfo: STATUS.RESOLVED
        }
      };
    }
    case types.ORGANIZATIONS_USERS_INFO_FAIL: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          setOrganizationUsersInfo: STATUS.REJECTED
        }
      };
    }
    case types.ORGANIZATIONS_USERS_ROLES_BEGIN: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          organizationRolesStatus: STATUS.PENDING
        }
      };
    }
    case types.ORGANIZATIONS_USERS_ROLES_SUCCESS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          organizationsRoles: action.payload,
          organizationRolesStatus: STATUS.RESOLVED
        }
      };
    }
    case types.ORGANIZATIONS_USERS_ROLES_FAIL: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          organizationRolesStatus: STATUS.REJECTED
        }
      };
    }
    case types.USER_APPROVAL_AMOUNT_BEGIN: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          userApprovalAmountStatus: STATUS.PENDING
        }
      };
    }
    case types.USER_APPROVAL_AMOUNT_SUCCESS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          currency: action.payload.currency,
          approvalAmount: +action.payload.approvalAmount,
          userApprovalAmountStatus: STATUS.RESOLVED
        }
      };
    }
    case types.USER_APPROVAL_AMOUNT_FAIL: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          currency: undefined,
          approvalAmount: undefined,
          userApprovalAmountStatus: STATUS.REJECTED
        }
      };
    }
    case types.ORGANIZATIONS_USERS_ROL_ASSIGN_BEGIN: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          organizationRolesAssignStatus: STATUS.PENDING
        }
      };
    }
    case types.ORGANIZATIONS_USERS_ROL_ASSIGN_SUCCESS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          organizationRolesAssignStatus: STATUS.RESOLVED
        }
      };
    }
    case types.ORGANIZATIONS_USERS_ROL_ASSIGN_FAIL: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          organizationRolesAssignStatus: STATUS.REJECTED
        }
      };
    }

    case types.ORGANIZATION_USERS_EDIT_SET_FUTURE_DCN: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          futureDCNSelected: action.payload
        }
      };
    }
    case types.ORGANIZATION_USERS_RESET_SET_FUTURE_DCN: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          futureDCNSelected: null
        }
      };
    }

    case types.SET_USER_APPROVAL_AMOUNT_BEGIN: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          setUserApprovalAmountStatus: STATUS.PENDING
        }
      };
    }
    case types.SET_USER_APPROVAL_AMOUNT_SUCCESS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          setUserApprovalAmountStatus: STATUS.RESOLVED
        }
      };
    }
    case types.SET_USER_APPROVAL_AMOUNT_FAIL: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          setUserApprovalAmountStatus: STATUS.REJECTED
        }
      };
    }

    case types.RESET_USER_DETAILS_STATUS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          setOrganizationUsersInfo: STATUS.IDLE,
          organizationRolesStatus: STATUS.IDLE,
          userApprovalAmountStatus: STATUS.IDLE,
          organizationRolesAssignStatus: STATUS.IDLE,
          organizations: [],
          organizationsRoles: {},
          currency: undefined,
          approvalAmount: undefined,
          futureDCNSelected: {}
        }
      };
    }

    case types.RESET_USER_DETAILS_APPROVAL_AMOUNT_STATUS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          currency: undefined,
          approvalAmount: undefined,
          userApprovalAmountStatus: STATUS.IDLE
        }
      };
    }

    default:
      return state;
  }
};
export { organizationsAndUsersInitialStore };
export default organizationsAndUsersReducer;
