import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InlineLoader, AlloyButton, InfoIcon } from 'cat-ecommerce-alloy';
import {
  priceDisplayFormat,
  shouldAddCurrencyInPrice
} from '@app/utils/paymentUtils';
import { SERVER_ERRORS } from '../../constants';
import CoreDepositPopup from '@app/components/common/CoreDepositPopup';
import styles from './cellStyles.module.scss';

const TotalPriceCell = ({ item, isMobile }) => {
  const {
    isUpdateLineItemLoading,
    totalPrice,
    unitPrice,
    coreDeposit,
    errors,
    undiscountedPrice,
    strikeThrough,
    totalPriceWithGST,
    unitPriceWithGST,
    undiscountedPriceWithGST
  } = item;
  const {
    currencyNotation = '',
    taxDisclaimerMessage,
    includeGST,
    showGST,
    hasOrderWithPrice
  } = useSelector(s => s.orders);

  const isShoppingCartPage = () =>
    (window.location?.pathname || '').includes('/AjaxOrderItemDisplayView') ||
    (window.location?.pathname || '').includes('/AjaxCheckoutDisplayView');
  const checkGst = isShoppingCartPage() && includeGST && showGST ? true : false;
  const finaltotalPrice = checkGst ? totalPriceWithGST : totalPrice;
  const finalUnitPrice = checkGst ? unitPriceWithGST : unitPrice;
  const finalundiscountedPrice = checkGst
    ? undiscountedPriceWithGST
    : undiscountedPrice;

  const { isQuantityMergeEnabled = true } =
    useSelector(s => s.orders.byId[s.orders.current]?.orderExtendAttribute) ||
    {};

  const { emailCart } = useSelector(s => s.common);
  const [t] = useTranslation();
  const popperOptions = {
    placement: 'left',
    modifiers: [
      {
        name: 'flip',
        options: {
          rootBoundary: 'document',
          fallbackPlacements: ['bottom']
        }
      }
    ]
  };

  const coreDepositInitiator = (
    <AlloyButton
      className={`p-0 me-0 d-block text-nowrap ${styles['order-items-table__core-deposit-button']}`}
    >
      <InfoIcon
        className={`me-1 d-print-none ${styles['order-items-table__info-button-icon']}`}
      />
      <span className={styles['order-items-table__description-decorator-text']}>
        {t('CAT_CORE_DEPOSIT_LABEL')}
      </span>
    </AlloyButton>
  );

  const getTotalPrice = () => {
    return errors?.server !== SERVER_ERRORS.QUANTITY &&
      !errors?.availability &&
      hasOrderWithPrice ? (
      <div className={`pt-1 ${styles['print__order-price']}`}>
        <span
          className={`fw-bold ${styles['order-items-table__total-price-display']}`}
        >
          {priceDisplayFormat(finaltotalPrice, currencyNotation, null, false)}
          {isMobile && shouldAddCurrencyInPrice(currencyNotation) && (
            <span className={styles['order-items-table__total-price-display']}>
              {`(${currencyNotation})`}
            </span>
          )}
        </span>
        {strikeThrough && (
          <div className="d-none d-md-block">
            <span className={styles['order-items-table__strikethrough-amount']}>
              {`${priceDisplayFormat(
                finalundiscountedPrice,
                currencyNotation,
                null,
                false
              )} ${t('ITEM_EA_ABB')}`}
            </span>
          </div>
        )}
        <div>
          <span className={styles['order-items-table__core-deposit-amount']}>
            {`${priceDisplayFormat(
              finalUnitPrice,
              currencyNotation,
              null,
              false
            )} ${t('ITEM_EA_ABB')}`}
          </span>
          {strikeThrough && (
            <span
              className={`ps-1 d-md-none d-inline-block ${styles['order-items-table__strikethrough-amount']}`}
            >
              {`${priceDisplayFormat(
                finalundiscountedPrice,
                currencyNotation,
                null,
                false
              )} ${t('ITEM_EA_ABB')}`}
            </span>
          )}
        </div>

        {isMobile && !!taxDisclaimerMessage && (
          <div>
            <span
              className={`text-transform-none ${styles['order-items-table__core-deposit-amount']}`}
            >
              {taxDisclaimerMessage}
              {!isQuantityMergeEnabled ? (
                <sup>&dagger;&dagger;</sup>
              ) : (
                <sup>&dagger;</sup>
              )}
            </span>
          </div>
        )}

        {coreDeposit && (
          <div
            className={`d-flex flex-md-column align-items-md-end  ${styles['align-core-deposite']}`}
          >
            <CoreDepositPopup
              initiator={coreDepositInitiator}
              popperOptions={popperOptions}
            />

            <div
              className={`ps-2 ${styles['order-items-table__core-deposit-amount']}`}
            >
              {coreDeposit}
            </div>
          </div>
        )}
      </div>
    ) : null;
  };

  const getNoPrice = () => {
    return (
      <div>
        <div className={`${styles['order-items-table__no-price-text']}`}>
          {t('UNABLE_TO_RETRIEVE_PRICE')}
        </div>
      </div>
    );
  };
  const getTotalPriceCell = () => {
    return emailCart ? getNoPrice() : getTotalPrice();
  };

  return (
    <div>
      {!isUpdateLineItemLoading ? getTotalPriceCell() : <InlineLoader />}
    </div>
  );
};

TotalPriceCell.propTypes = {
  item: PropTypes.shape({
    coreDeposit: PropTypes.string,
    isMobile: PropTypes.bool,
    isUpdateLineItemLoading: PropTypes.bool,
    errors: PropTypes.shape({
      server: PropTypes.string,
      availability: PropTypes.string
    }),
    strikeThrough: PropTypes.bool,
    totalPrice: PropTypes.string,
    totalPriceWithGST: PropTypes.string,
    undiscountedPrice: PropTypes.string,
    undiscountedPriceWithGST: PropTypes.string,
    unitPrice: PropTypes.string,
    unitPriceWithGST: PropTypes.string
  }),
  isMobile: PropTypes.bool
};

export default TotalPriceCell;
