import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEspot, useReplaceTokenWithElement } from '@app/hooks';
import Espot from '@app/components/common/Espot/Espot';
import {
  ADS_TERMS_AND_CONDITIONS_ESPOTS,
  DEALER_DROPDOWN_NAME
} from '../../constants';
import {
  Conditional,
  Drawer,
  DrawerBackHeader,
  DrawerBody,
  Modal,
  ModalBody
} from '@cat-ecom/pcc-components';
import styles from '../../styles.module.scss';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

export const AssociatedDealerTermsAndConditions = ({
  modal,
  drawer,
  formMethods,
  dealer,
  scrollToPrivacyNotice = false,
  showTermsAndConditions,
  isModal = false
}) => {
  const dealerList = useSelector(s => s.dealerAssociation?.dealerAssociations);
  const currentDealerValue = formMethods.watch(DEALER_DROPDOWN_NAME);
  const selectedDealer =
    dealer ??
    dealerList?.find(dealerItem => dealerItem?.label === currentDealerValue);
  const { getEspotContent } = useEspot(
    ADS_TERMS_AND_CONDITIONS_ESPOTS.espotNames,
    '',
    selectedDealer?.value ?? ''
  );
  const catComplianceInfo =
    getEspotContent(ADS_TERMS_AND_CONDITIONS_ESPOTS.legalCookie) || '';
  const dealerPrivacyPolicy =
    getEspotContent(ADS_TERMS_AND_CONDITIONS_ESPOTS.privacyPolicy) || '';
  const dealerTermsOfUse =
    getEspotContent(ADS_TERMS_AND_CONDITIONS_ESPOTS.termsAndConditions) || '';

  // * Validate which espot will show
  let displayEspot;
  const shouldDisplayTermsAndConditions = showTermsAndConditions => {
    if (showTermsAndConditions === 'dealerTermsOfUse') {
      displayEspot = <Espot html={dealerTermsOfUse} />;
    } else if (showTermsAndConditions === 'dealerPrivacyPolicy') {
      displayEspot = <Espot html={dealerPrivacyPolicy} />;
    } else {
      displayEspot = <Espot html={catComplianceInfo} />;
    }

    return displayEspot;
  };

  const ref = useRef();
  const [t] = useTranslation();
  const { replaceTokenWithElement } = useReplaceTokenWithElement();
  const backButtonMessage = replaceTokenWithElement(
    t('GENERAL_BACK_TO'),
    'm-0',
    t('CAT_MA_ASSOCIATEDDEALERS')
  );

  useEffect(() => {
    if (
      catComplianceInfo &&
      (drawer.drawerProps.isActive || modal.modalProps.isActive) &&
      scrollToPrivacyNotice
    ) {
      // * scrollIntoView doesn't work if called immediately
      setTimeout(() => {
        const privacyNoticeElement = document.getElementById(
          ADS_TERMS_AND_CONDITIONS_ESPOTS.privacyNoticeId
        );

        if (privacyNoticeElement) {
          privacyNoticeElement.scrollIntoView();
        }
      }, 50);
    }
  }, [
    ref,
    catComplianceInfo,
    drawer.drawerProps.isActive,
    modal.modalProps.isActive,
    scrollToPrivacyNotice,
    isModal
  ]);

  return (
    <>
      <Conditional test={!isModal}>
        <Drawer className={styles['drawer']} ref={ref} {...drawer.drawerProps}>
          <Conditional test={drawer.drawerProps.isActive}>
            <DrawerBackHeader handleClose={drawer.handleClose}>
              <h4 className="cat-u-theme-typography-title m-0">
                {backButtonMessage}
              </h4>
            </DrawerBackHeader>
            <DrawerBody className={styles['drawer-body']}>
              <Conditional
                test={shouldDisplayTermsAndConditions(showTermsAndConditions)}
              >
                {displayEspot}
              </Conditional>
            </DrawerBody>
          </Conditional>
        </Drawer>
      </Conditional>
      <Conditional test={isModal}>
        <Modal className={styles['modal']} ref={ref} {...modal.modalProps}>
          <Conditional test={modal.modalProps.isActive}>
            <DrawerBackHeader handleClose={modal.modalProps.onBlModalClose}>
              <h4 className="cat-u-theme-typography-title m-0">
                {backButtonMessage}
              </h4>
            </DrawerBackHeader>
            <ModalBody>
              <Conditional
                test={shouldDisplayTermsAndConditions(showTermsAndConditions)}
              >
                {displayEspot}
              </Conditional>
            </ModalBody>
          </Conditional>
        </Modal>
      </Conditional>
    </>
  );
};

AssociatedDealerTermsAndConditions.propTypes = {
  drawer: PropTypes.shape({
    id: PropTypes.string,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    handleClose: PropTypes.func,
    drawerProps: PropTypes.any
  }),
  modal: PropTypes.shape({
    modalProps: PropTypes.any
  }),

  scrollToPrivacyNotice: PropTypes.bool
};
