import { navigateToUrl } from '@app/services/windowService';
import {
  isEmpty,
  pccSessionId,
  removeCookie,
  removeSessionStorage
} from '@app/utils';
import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { LOGOUT_EVENT } from '../../../../constants/events';
import { LOGOUT_CONSTANT } from './constant';
import { IS_MANUAL_APPROVAL_MESSAGE_VIEWED } from '@app/components/global-banners/constants';
import {
  CONSENT_SESSION_KEY,
  FULFILMENT_DATE_CHANGED,
  LAST_FULFILLMENT_DATE_CHANGED
} from '@app/constants/commonConstants';
import { removeLocalStorage } from '@app/utils/localStorageUtils';
const EQUIPMENT_VALUE = 'equipmentValue';
const REQUEST_BY_DATE = 'requestByDate';

const useLogout = () => {
  const storeId = useSelector(state => state.common.storeId);
  const logoffURL = useSelector(state => state.common?.logoffURL);

  const removeCustomCookies = () => {
    const rclist = document.getElementById(`${LOGOUT_CONSTANT.RCLIST}`);
    const rclistArray = rclist?.value?.split(',');
    if (!isEmpty(rclistArray)) {
      for (let index = 0; index < rclistArray.length; index++) {
        removeCookie(rclistArray[index]);
      }
    }
  };

  const deleteUserLogonIdCookie = () => {
    const logonUserIdCookie = `${LOGOUT_CONSTANT.WCLOGONUSERID}${storeId}`;
    removeCustomCookies();
    removeCookie(logonUserIdCookie);
    removeSessionStorage(LOGOUT_CONSTANT.PARTSSEARCH);
    removeSessionStorage(LOGOUT_CONSTANT.PARTSMANUAL);
    removeSessionStorage(IS_MANUAL_APPROVAL_MESSAGE_VIEWED);
    removeSessionStorage(EQUIPMENT_VALUE);
    removeSessionStorage(REQUEST_BY_DATE);
    removeSessionStorage(CONSENT_SESSION_KEY);
    removeLocalStorage(LAST_FULFILLMENT_DATE_CHANGED);
    removeLocalStorage(FULFILMENT_DATE_CHANGED);
    pccSessionId.createNewId();
  };

  const logoutRedirect = logoutUrl => {
    removeCookie(`${LOGOUT_CONSTANT.WCDELETECARTCOOKIE}${storeId}`);
    navigateToUrl(logoutUrl);
  };

  /**
   * Log the user out
   * @param {object} detailEvent
   * detailEvent.detail : detail would be of type object when triggered as custom event from leagacy jsp.
   * detailEvent.detail : detail would be off type number when triggered as part of an React Event.
   */
  const logout = useCallback(
    detailEvent => {
      const logoutRedirectUrl =
        typeof detailEvent?.detail === 'object'
          ? detailEvent.detail?.logoffURL
          : logoffURL;
      deleteUserLogonIdCookie();
      if (logoutRedirectUrl) {
        logoutRedirect(logoutRedirectUrl);
      }
    },
    [storeId, deleteUserLogonIdCookie, logoutRedirect]
  );

  useEffect(() => {
    document.addEventListener(LOGOUT_EVENT, logout);
    return () => document.removeEventListener(LOGOUT_EVENT, logout);
  }, [logout]);

  return { logout };
};
export default useLogout;
