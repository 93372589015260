export const INQUIRY_DROPDOWN = {
  errorMsg: 'Please select a value in dropdown box.',
  pcc: 'PCC',
  catCentral: 'Cat Central',
  controlCenter: 'Control Center',
  placeholder: 'Select an option'
};

export const MX_COUNTRY_CODE = 'MXN';
export const PER_COUNTRY_CODE = 'PER';
export const COL_COUNTRY_CODE = 'COL';
export const ALTERNATIVE_PAYMENT_VALUE = 'BankTransfer';
export const ALTERNATIVE_PAYMENT_DROPDOWN_OPTIONS = [
  { displayName: '7-Eleven', value: 'SEVEN_ELEVEN' },
  { displayName: 'OXXO', value: 'OXXO' },
  { displayName: 'BBVA', value: 'BANK_REFERENCED' }
];
export const BANK_LIST_DROPDOWN_OPTIONS = bankList =>
  bankList?.map(bank => ({
    displayName: bank.description,
    value: bank.pseCode
  }));
