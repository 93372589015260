/* global google */
import { useState, useEffect, useContext, useRef } from 'react';
import { AlloyButton, MapContext } from 'cat-ecommerce-alloy';
import styles from './SearchThisAreaButton.module.scss';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { dealerlocatorResultsPropTypes } from '../../../declarations.proptypes';
import { roundCompare } from '../../../utils';

const SearchThisAreaButton = ({ results, setSelectedSuggestion }) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [t] = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const { storeLocation } = values;
  const [showSearchThisAreaButton, setShowSearchThisAreaButton] = useState(
    false
  );
  const context = useContext(MapContext);
  const map = context.map;
  const refreshSearchResults = () => {
    if (map) {
      setShowSearchThisAreaButton(false);
      const center = map.getCenter();
      const selectedStore = results.find(store => store.id === storeLocation);
      const centeredStore =
        roundCompare(selectedStore.latitude, center.lat(), 7) &&
        roundCompare(selectedStore.longitude, center.lng(), 7);
      if (centeredStore) {
        const location = `${selectedStore.address} ${selectedStore.city}, ${selectedStore.state}`;
        setFieldValue('storeLocation', '');
        setSelectedSuggestion(location);
      } else {
        const location = `${center.lat()}, ${center.lng()}`;
        setFieldValue('storeLocation', '');
        setSelectedSuggestion(location);
      }
    }
  };

  const initialCenter = useRef();
  // do not add search this area button on initial load
  useEffect(() => {
    if (map) {
      const listener = google.maps.event.addListener(map, 'idle', () => {
        setIsMapLoaded(true);
        const center = map.getCenter();
        initialCenter.current = { lat: center.lat(), lng: center.lng() };
      });

      return () => google.maps.event.removeListener(listener);
    }
  }, [map]);

  useEffect(() => {
    if (map && isMapLoaded) {
      const listener = google.maps.event.addListener(
        map,
        'center_changed',
        () => {
          const center = map.getCenter();
          const isInitialCenter =
            center.lat() === initialCenter?.current.lat &&
            center.lng() === initialCenter?.current.lng;
          if (!showSearchThisAreaButton && !isInitialCenter) {
            setShowSearchThisAreaButton(true);
          }
        }
      );

      return () => google.maps.event.removeListener(listener);
    }
  }, [isMapLoaded, map, showSearchThisAreaButton]);

  return showSearchThisAreaButton ? (
    <AlloyButton
      className={styles['search-this-area__button']}
      buttonType="tertiary"
      onClick={refreshSearchResults}
      name="dealer-locator__map-search-area-button"
    >
      {t('FYD_SEARCH_THIS_AREA')}
    </AlloyButton>
  ) : null;
};

SearchThisAreaButton.propTypes = {
  results: PropTypes.arrayOf(dealerlocatorResultsPropTypes),
  setSelectedSuggestion: PropTypes.func
};
export default SearchThisAreaButton;
