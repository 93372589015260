import { useEffect, useState } from 'react';

export const options = { LOCAL: 'local', SESSION: 'session' };

// This code is taken from https://www.robinwieruch.de/local-storage-react#local-storage-in-react
const useWebStorage = (storageKey, storageType = options.LOCAL) => {
  const resolvedWebStorage =
    storageType === options.SESSION ? sessionStorage : localStorage;
  const [value, setValue] = useState(
    JSON.parse(resolvedWebStorage.getItem(storageKey)) || undefined
  );

  useEffect(() => {
    if (value === undefined) {
      resolvedWebStorage.removeItem(storageKey);
    } else {
      resolvedWebStorage.setItem(storageKey, JSON.stringify(value));
    }
  }, [resolvedWebStorage, storageKey, value]);

  return [value, setValue];
};

export default useWebStorage;
