import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Conditional } from '@cat-ecom/pcc-components';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import { CatSkeleton } from 'blocks-react/bedrock/components/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import AvailabilityNoIntermStock from '../../Availability/StoreAvailability/AvailabilityNoIntermStock';
import InlineNotification from '../../Blocks/InlineNotification';
import { getOtherStoresPriceAndAvail } from '@app/store/products/actions';
import { storeHoursPropTypes } from '@app/components/dealer-locator/declarations.proptypes';

const DealerOtherStoreAvailability = ({ store }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const uniqueId = useSelector(s => s.products?.current);
  const partNumber = useSelector(s => s.products?.byId?.[uniqueId]?.partNumber);
  const quantity = useSelector(
    s => s?.products?.priceAndAvailability?.[partNumber]?.quantity
  );
  const locationCode = store?.id?.substring(store?.storeId?.length);
  const partNumQuantity = `${partNumber}_${quantity}`;
  const isCheckAvailOpen = useSelector(
    s =>
      s?.products?.otherStores?.[partNumQuantity]?.[locationCode]
        ?.isCheckAvailOpen
  );
  const storeAvailability = useSelector(
    s =>
      s?.products?.otherStores?.[partNumQuantity]?.[locationCode]
        ?.storeAvailability
  );
  const isCheckAvailOpenFail = useSelector(
    s =>
      s?.products?.otherStores?.[partNumQuantity]?.[locationCode]
        ?.isCheckAvailOpenFail
  );
  useEffect(() => {
    onAvailabilityHandler(locationCode, partNumQuantity, true);
  }, [locationCode, partNumQuantity]);
  const onAvailabilityHandler = (
    locationCode,
    partNumQuantity,
    onPageLanding
  ) => {
    const partsList = {
      partsList: [
        {
          partNumber,
          quantity,
          uniqueId
        }
      ]
    };
    dispatch(
      getOtherStoresPriceAndAvail({
        partsList,
        locationCode,
        partNumQuantity,
        onPageLanding
      })
    );
  };
  return (
    <div className="mt-2">
      {!isCheckAvailOpen ? (
        <CatButton
          type="button"
          variant="default"
          onClick={() => onAvailabilityHandler(locationCode, partNumQuantity)}
          size="sm"
        >
          {t('AVAILABLE_CHECK_AVAILABILITY')}
        </CatButton>
      ) : (
        <Conditional
          fallback={
            isCheckAvailOpenFail ? (
              <InlineNotification title={t('GENERIC_ERROR_2')} />
            ) : (
              <CatSkeleton data-testid="CatSkeleton" />
            )
          }
          test={!!storeAvailability}
        >
          {!!storeAvailability && (
            <AvailabilityNoIntermStock
              availabilityInformation={storeAvailability}
              hideLabel={true}
              isFromOtherBranchDrawer={true}
            />
          )}
        </Conditional>
      )}
    </div>
  );
};
DealerOtherStoreAvailability.propTypes = {
  store: PropTypes.shape({
    geo: PropTypes.shape({
      distance: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      isClosest: PropTypes.bool
    }),
    website: PropTypes.string,
    dealerName: PropTypes.string,
    address: PropTypes.shape({
      zipCode: PropTypes.string,
      country: PropTypes.string,
      city: PropTypes.string,
      phone: PropTypes.string,
      state: PropTypes.string,
      line3: PropTypes.string,
      line2: PropTypes.string,
      line1: PropTypes.string,
      email: PropTypes.string,
      seoURL: PropTypes.string
    }),
    storeHours: storeHoursPropTypes,
    name: PropTypes.string,
    storeLocationCode: PropTypes.string,
    id: PropTypes.string,
    services: PropTypes.arrayOf(PropTypes.string),
    storeId: PropTypes.string
  })
};
export default DealerOtherStoreAvailability;
