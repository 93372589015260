import { STATUS } from '@app/constants/commonConstants';
import * as types from './constants';
import { taxDetailInitialStore } from './initialStore';

const taxDetailReducer = (state = taxDetailInitialStore, action) => {
  switch (action.type) {
    case types.GET_TAX_DETAIL_BEGIN: {
      return {
        ...state,
        taxDetalStatus: STATUS.PENDING
      };
    }
    case types.GET_TAX_DETAIL_SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
        taxDetalStatus: STATUS.RESOLVED
      };
    }
    case types.GET_TAX_DETAIL_FAIL: {
      return {
        ...state,
        ...action.payload,
        taxDetalStatus: STATUS.REJECTED
      };
    }
    default: {
      return state;
    }
  }
};

export { taxDetailInitialStore };
export default taxDetailReducer;
