import { replaceTokensInString } from '../../utils';
import { DISCLAIMER_MESSAGE_HIDE } from '../../components/pages/checkout/shopping-cart/OrderItems/constants';
import { QTY_FOR_SAVED_LIST_ID } from './constants';

export const formatQuantity = quantity => parseInt(quantity).toString();

export const enrichOrderItems = (orders, orderItems) => {
  const orderItemById = orders.byId[orders.current]?.ascendingOrderItems;
  return orderItems?.reduce((acc, cur) => {
    const updatedOrderItem = orderItemById?.find(
      el => el.orderItemId === cur.orderItemId
    );

    const errors = updatedOrderItem?.errors || {};
    return [
      ...acc,
      {
        ...cur,
        errors: cur.errors ? { ...cur.errors } : { ...errors },
        reactKey: cur.orderItemId,
        serverQuantity: cur.quantity,
        packageQuantity: cur.packageError?.packageQuantity
      }
    ];
  }, []);
};

export const setError = (orderItems, payload) => {
  const { item, error, errorCode } = payload;

  return orderItems.reduce((acc, cur) => {
    const errors =
      cur.orderItemId === item.orderItemId
        ? { [error.colId]: error.message }
        : {};

    const hasItemHadServerError =
      cur.orderItemId === item.orderItemId && error.colId === 'server'
        ? true
        : cur.hasItemHadServerError;

    return [
      ...acc,
      {
        ...cur,
        errors: { ...cur.errors, ...errors },
        errorCode,
        hasItemHadServerError,
        isUpdateLineItemLoading: false
      }
    ];
  }, []);
};

export const clearLineItemError = (orderItems, payload) => {
  const { item, errorDomain } = payload;
  return orderItems.reduce((acc, cur) => {
    const errors = Object.keys(cur.errors).reduce((errorObj, errorKey) => {
      if (errorKey !== errorDomain) {
        errorObj[errorKey] = cur.errors[errorKey];
      }
      return errorObj;
    }, {});

    const actualErrors =
      cur.orderItemId === item.orderItemId ? errors : cur.errors;

    return [
      ...acc,
      {
        ...cur,
        errors: { ...actualErrors }
      }
    ];
  }, []);
};

export const getLineItemLoadingStatus = orderItems =>
  orderItems.some(item => item.isUpdateLineItemLoading);

export const getItemErrors = orderItems =>
  orderItems.filter(item => item.errors?.server);

export const updateOrderItemNotes = (orderItems, payload) =>
  orderItems?.reduce((acc, cur) => {
    let lineItemNotes;
    switch (payload.type) {
      case 'customerItemNumber':
        lineItemNotes = {
          ...cur.orderItemExtendAttribute.lineItemNotes,
          lineItemNo: payload.input
        };
        break;
      case 'serialNumber':
        lineItemNotes = {
          ...cur.orderItemExtendAttribute.lineItemNotes,
          customerSerialNumber: payload.input
        };
        break;
      case 'customerPartNumber':
        lineItemNotes = {
          ...cur.orderItemExtendAttribute.lineItemNotes,
          customerPartNumber: payload.input
        };
        break;
      case 'lineItemNote':
        lineItemNotes = {
          ...cur.orderItemExtendAttribute.lineItemNotes,
          partNote: payload.input
        };
        break;
      default:
        lineItemNotes = {};
        break;
    }

    const updatedItemAttribute =
      cur.orderItemId === payload.orderItemId
        ? {
            ...cur.orderItemExtendAttribute,
            lineItemNotes
          }
        : cur.orderItemExtendAttribute;
    return [
      ...acc,
      {
        ...cur,
        orderItemExtendAttribute: updatedItemAttribute
      }
    ];
  }, []);

export const updateSavedListHiddenFields = items => {
  items.forEach(item => {
    const hiddenInputField = document.getElementById(
      replaceTokensInString(QTY_FOR_SAVED_LIST_ID, item.orderItemId)
    );
    if (hiddenInputField) {
      hiddenInputField.value = item.quantity;
    }
  });
};

export const deleteAvailabilityMessage = () => {
  const displayMessages = document.querySelectorAll(
    `.${DISCLAIMER_MESSAGE_HIDE}`
  );
  if (displayMessages.length > 0) {
    displayMessages.forEach(msg => msg.classList.add('d-none'));
  }
};

export const setOrClearBundleErrors = (
  orders,
  orderItemId,
  setErrorFlag = false
) => {
  const orderItems = orders.byId[orders.current]?.ascendingOrderItems;
  return orderItems?.reduce((acc, cur) => {
    return [
      ...acc,
      {
        ...cur,
        hasBundleError:
          cur.orderItemId === orderItemId ? setErrorFlag : cur.hasBundleError
      }
    ];
  }, []);
};

export const getLineItemStatusData = shipments =>
  shipments.map(
    ({ itemsInThisShipment = [], shippedBy, trackingId, trackingUrl }) => {
      return {
        itemsInThisShipment: itemsInThisShipment?.map(item => {
          return {
            ...item,
            shippedBy,
            trackingUrl,
            trackingId
          };
        })
      };
    }
  );

export const updatePageValue = items => {
  const matchingItems = {};
  for (let i = items.length - 1; i >= 0; i--) {
    const currentItem = items[i];
    if (!matchingItems[currentItem.partNumber]) {
      matchingItems[currentItem.partNumber] = currentItem.page;
    }
  }
  for (const currentItem of items) {
    if (matchingItems[currentItem.partNumber]) {
      currentItem.page = matchingItems[currentItem.partNumber];
    }
  }
  return items;
};
