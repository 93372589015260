import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StoreServicesOffered } from '@cat-ecom/pcc-components';

const StoreServiceOfferedWrapper = () => {
  const [t] = useTranslation();
  const serviceOffered = useSelector(s => s.store?.serviceOffered) ?? [];

  return (
    <StoreServicesOffered
      title={`${t('SERVICES_OFFERED')}:`}
      serviceOffered={serviceOffered}
    />
  );
};

export default StoreServiceOfferedWrapper;
