import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AlloyButton } from 'cat-ecommerce-alloy';
import { dealerlocatorResultsPropTypes } from '../../declarations.proptypes';
import { DEFAULT_NUM_RESULTS } from '../../constants';
import DealerLocation from './DealerLocation';
import { useFormikContext } from 'formik';

const DealerLocationList = ({
  isMapView,
  numDisplayed,
  filteredAndOrderedVisibleDealerStores = [],
  allFilteredDealerStoresByDistance = [],
  setNumDisplayed,
  signInRef,
  uom
}) => {
  const [t] = useTranslation();
  const resultsAvailable = allFilteredDealerStoresByDistance.length > 0;
  const moreResultsAvailable =
    allFilteredDealerStoresByDistance.length > numDisplayed;

  const { values } = useFormikContext();
  const { storeLocation } = values;
  const selectedStoreIndex = allFilteredDealerStoresByDistance.findIndex(
    store => storeLocation === store.id
  );

  useEffect(() => {
    if (!!signInRef && !!signInRef?.current?.lastElementChild) {
      signInRef.current.lastElementChild.scrollIntoView({ behavior: 'smooth' });
    }
  }, [resultsAvailable]);

  useEffect(() => {
    const withinDisplayed = selectedStoreIndex < numDisplayed;
    if (selectedStoreIndex && !isMapView && !withinDisplayed) {
      setNumDisplayed(selectedStoreIndex + 1);
    }
  }, [selectedStoreIndex, isMapView, numDisplayed, setNumDisplayed]);

  const viewMore = () =>
    setNumDisplayed(currentNum => currentNum + DEFAULT_NUM_RESULTS);

  return (
    <ul className="list-group mt-2 ps-0">
      {resultsAvailable &&
        filteredAndOrderedVisibleDealerStores
          .slice(0, numDisplayed)
          .map(dealerStore => (
            <DealerLocation
              isMapView={isMapView}
              key={dealerStore.uniqueID}
              {...dealerStore}
              uom={uom}
            />
          ))}
      {moreResultsAvailable && (
        <li className="list-group-item mb-0 border-start-0 border-end-0 px-0">
          <AlloyButton
            buttonType="ghost"
            className="w-100"
            onClick={() => viewMore()}
            size="large"
            name="dealer-locator__view-more-button"
          >
            {t('VIEW_MORE_PRODUCTS')}
          </AlloyButton>
        </li>
      )}
    </ul>
  );
};

export default DealerLocationList;

DealerLocationList.propTypes = {
  filteredAndOrderedVisibleDealerStores: PropTypes.arrayOf(
    dealerlocatorResultsPropTypes
  ),
  allFilteredDealerStoresByDistance: PropTypes.arrayOf(
    dealerlocatorResultsPropTypes
  ),
  isMapView: PropTypes.bool.isRequired,
  numDisplayed: PropTypes.number.isRequired,
  setNumDisplayed: PropTypes.func.isRequired,
  uom: PropTypes.string,
  signInRef: PropTypes.object
};
