const SubheaderLoading = () => {
  return (
    <div
      data-testid="subheader-loader"
      className="w-100 bg-black"
      style={{ height: '57px' }}
    ></div>
  );
};

export default SubheaderLoading;
