import { useState, useEffect } from 'react';
import { CatToast } from '@blocks-react/components/Toast';
import { CatHeading } from '@blocks-react/components/Heading';
import { CatIconWarningTriangle } from '@blocks-react/components/Icons/WarningTriangle';
import { CSR_TEXTS } from '@app/components/pages/csr-landing/texts';

function CSRInactiveLogOut() {
  const [showCSRLogout, setShowCSRLogout] = useState(false);

  useEffect(() => {
    const isUserActive = sessionStorage.getItem('_userInactive') || false;
    if (isUserActive) {
      sessionStorage.removeItem('_userInactive');
      setShowCSRLogout(true);
    }
  }, []);

  if (showCSRLogout) {
    return (
      <CatToast
        variant="warning"
        delay={5}
        data-testid="CSR-inactve-logout-toast"
        style={{ maxWidth: '300px' }}
      >
        <CatIconWarningTriangle slot="before" />
        <CatHeading variant="label-sm" style={{ whiteSpace: 'pre-line' }}>
          {CSR_TEXTS.SUCCESS_INACTIVE_LOG_OUT}
        </CatHeading>
      </CatToast>
    );
  }
}

export default CSRInactiveLogOut;
