import PropTypes from 'prop-types';
import {
  AssociatedDealerForm,
  AssociatedDealerSearchResultList
} from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import {
  CUSTOMER_NUMBER_RADIO_NAME,
  SEARCH_REUSLTS_FORM_ID
} from '../../constants';
import { useMemo, useState } from 'react';
import { DEFAULT_NUM_RESULTS } from '@app/components/dealer-locator/constants';
import { AssociatedDealerViewMoreButton } from '../AssociatedDealerViewMoreButton';
import styles from '../../styles.module.scss';

export const AssociatedDealerSearchResultsForm = ({
  formMethods,
  onSubmit,
  searchResults,
  loading,
  error
}) => {
  const id = SEARCH_REUSLTS_FORM_ID;
  const [t] = useTranslation();

  const [numDisplayed, setNumDisplayed] = useState(DEFAULT_NUM_RESULTS);
  const visibleSearchResults = useMemo(
    () => searchResults?.slice(0, numDisplayed),
    [searchResults, numDisplayed]
  );

  const handleViewMore = () =>
    setNumDisplayed(currentNum => currentNum + DEFAULT_NUM_RESULTS);

  const moreResultsAvailable = searchResults?.length > numDisplayed;
  const subtitle = t('TITLE_SEARCH_RESULTS');
  const resultsCount = `${searchResults?.length} ${t('RESULTS')}`;

  return (
    <AssociatedDealerForm
      id={id}
      className={styles['drawer-form']}
      formMethods={formMethods}
      onSubmit={onSubmit}
    >
      <h4 className="cat-u-theme-typography-title-sm m-0">{subtitle}</h4>
      <p className="cat-u-theme-typography-body-sm m-0">{resultsCount}</p>
      <AssociatedDealerSearchResultList
        name={CUSTOMER_NUMBER_RADIO_NAME}
        dealerCustomerStores={visibleSearchResults}
        disabled={loading}
      />
      <AssociatedDealerViewMoreButton
        showButton={moreResultsAvailable}
        onClick={handleViewMore}
        disabled={loading}
      >
        {t('VIEW_MORE_PRODUCTS')}
      </AssociatedDealerViewMoreButton>
    </AssociatedDealerForm>
  );
};

AssociatedDealerSearchResultsForm.propTypes = {
  formMethods: PropTypes.any,
  onSubmit: PropTypes.func,
  searchResults: PropTypes.arrayOf(),
  loading: PropTypes.bool,
  error: PropTypes.string
};
