export const INVOICES_GET_INFO_BEGIN = 'INVOICES_GET_INFO_BEGIN';
export const INVOICES_GET_INFO_SUCCESS = 'INVOICES_GET_INFO_SUCCESS';
export const INVOICES_GET_INFO_FAIL = 'INVOICES_GET_INFO_FAIL';
export const INVOICE_DETAIL_GET_INFO_BEGIN = 'INVOICE_DETAIL_GET_INFO_BEGIN';
export const INVOICE_DETAIL_GET_INFO_SUCCESS =
  'INVOICE_DETAIL_GET_INFO_SUCCESS';
export const INVOICE_DETAIL_GET_INFO_FAIL = 'INVOICE_DETAIL_GET_INFO_FAIL';
export const CLEAR_INVOICES_LIST = 'CLEAR_INVOICES_LIST';
export const SET_INVOICES_CLIENT_FILTER_DATA =
  'SET_INVOICES_CLIENT_FILTER_DATA';
export const INVOICE_DETAIL_EXPORT_FAIL = 'INVOICE_DETAIL_EXPORT_FAIL';
export const INVOICE_DETAIL_EXPORT_SUCCESS = 'INVOICE_DETAIL_EXPORT_SUCCESS';
