import { AlloyButton } from 'cat-ecommerce-alloy';
import { useTranslation } from 'react-i18next';
import useLogin from '../../login/hooks/useLogin';

const RegistrationDescription = () => {
  const { t } = useTranslation();
  const textClassName = 'exclude-global color-dark-gray mb-3';
  const { login } = useLogin();
  const signinLink = (
    <AlloyButton
      buttonType="link"
      size="large"
      onClick={() => login()}
      className="p-0"
    >
      {t('SIGN_IN')}
    </AlloyButton>
  );
  const replaceSignInLink = msg => {
    const split = msg.split('{0}');
    return split.length > 1 ? (
      <>
        {split[0]}
        {signinLink}
        {split[1]}
      </>
    ) : (
      <>
        {signinLink}
        <p>{split[0]}</p>
      </>
    );
  };
  return (
    <>
      <p className={`${textClassName} fw-bold`}>{`${t(
        'REGISTER_MODAL_STEPS_SUBTITLE'
      )}`}</p>
      <p className={textClassName}>{`1) ${t(
        'REGISTER_MODAL_1_SELECT_LOCAL_STORE'
      )}`}</p>
      <p className={textClassName}>{`2) ${t(
        'REGISTER_MODAL_2_CREATE_LOCAL_PROFILE'
      )}`}</p>
      <p className={textClassName}>{`3) ${t(
        'REGISTER_MODAL_3_VERIFY_EMAIL_ADDRESS'
      )}`}</p>
      <p className={textClassName}>{`4) ${t(
        'REGISTER_MODAL_4_CREATE_CAT_ACCOUNT'
      )}`}</p>
      <p className={`${textClassName} pt-2`}>
        {replaceSignInLink(t('REGISTER_MODAL_SIGN_IN_PROMPT'))}
      </p>
    </>
  );
};

export default RegistrationDescription;
