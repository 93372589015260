import { INDIVIDUAL, REGEX_ALPHA_NUMERIC } from './constants';

const getEntityByCode = (entities, entityCode, fallback) =>
  entities.find(({ code }) => code === entityCode) || fallback;

export const getCountryByCode = (countries, countryCode, fallback) =>
  getEntityByCode(countries, countryCode, fallback);

export const getCountryDisplayName = (countries, countryCode) => {
  const { displayName = '' } = getCountryByCode(countries, countryCode, {});
  return displayName;
};

export const getStateByCode = (countries, countryCode, fallback) =>
  getEntityByCode(countries, countryCode, fallback);

export const getAddressForDisplay = ({ address, countries }) => {
  const {
    country: countryCode = '',
    state = '',
    ...selectedAddressDetails
  } = address || {};

  // Attempt to find a country's displayName, or default to whatever the country code is if one is not found
  const { displayName: countryDisplayName, states = [] } = getCountryByCode(
    countries,
    countryCode,
    { displayName: countryCode }
  );

  // Attempt to find a state's displayName, or default to whatever the state abbreviation is if one is not found
  const { displayName: stateDisplayName } = getStateByCode(states, state, {
    displayName: state
  });

  // adding blanks to ensure the necessary field values are all in the object otherwise Formik
  // has issues tracking what's changed on submit
  return {
    nickName: '',
    firstName: '',
    lastName: '',
    zipCode: '',
    address1: '',
    address2: '',
    city: '',
    phone: '',
    email: '',
    ...selectedAddressDetails,
    country: countryDisplayName,
    state: stateDisplayName
  };
};

export const getCountryObjectFromCallingCode = ({
  countryData,
  propCallingCode,
  userCountry
}) => {
  if (countryData) {
    const matchingCountries =
      countryData && propCallingCode
        ? countryData.filter(
            ({ callingCode }) => callingCode === propCallingCode
          )
        : [];
    if (matchingCountries?.length === 1) {
      //if we find a single matching country code then:
      return matchingCountries[0];
    } else {
      //if we find multiple matching country codes, or we can't identify a country code from the number:
      return countryData.find(({ displayName }) => displayName === userCountry);
    }
  } else {
    return null;
  }
};

export const validPhoneInputCheck = (
  phoneNumber,
  phoneFieldName,
  setterFunction,
  errorMessage
) => {
  if (!phoneNumber) {
    //no number was input
    setterFunction(phoneFieldName, '', false);
    return null;
  } else if (phoneNumber.length === 1) {
    //only found a single number which is not a valid phone number
    setterFunction(phoneFieldName, phoneNumber, false);
    return errorMessage;
  }
  return true;
};

export const formatToCPF = (e, inputRef, setFieldValue) => {
  const input = e.target.value.replace(REGEX_ALPHA_NUMERIC, '');
  inputRef.current.value = input;
  const firstSplit = input.substring(0, 3);
  const secondSplit = input.substring(3, 6);
  const thirdSplit = input.substring(6, 9);
  const fourthSplit = input.substring(9, 11);
  if (input.length > 9) {
    inputRef.current.value = `${firstSplit}.${secondSplit}.${thirdSplit}-${fourthSplit}`;
  } else if (input.length > 6) {
    inputRef.current.value = `${firstSplit}.${secondSplit}.${thirdSplit}`;
  } else if (input.length > 3) {
    inputRef.current.value = `${firstSplit}.${secondSplit}`;
  }
  setFieldValue(INDIVIDUAL, inputRef.current.value);
};
