import { Popup } from 'cat-ecommerce-alloy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './styles.modules.scss';

const CoreDepositPopup = ({ initiator, popperOptions = {} }) => {
  const [t] = useTranslation();
  return (
    <Popup
      initiator={initiator}
      className={`${styles['core-deposit__popup']}`}
      popperOptions={popperOptions}
      contents={
        <div className={`text-start`}>
          <h3
            className={`text-sans-serif text-xs fw-bold pb-3 mb-0 u-color--ExtraDarkGray`}
          >
            {t('CAT_CORE_DEPOSIT_TITLE')}
          </h3>
          <p className={`m-0 text-xs  u-color--DarkGray `}>
            {t('CAT_CORE_UNIT_SELL')}
          </p>
        </div>
      }
    />
  );
};

export default CoreDepositPopup;

CoreDepositPopup.propTypes = {
  initiator: PropTypes.node,
  popperOptions: PropTypes.shape({
    placement: PropTypes.string,
    modifiers: PropTypes.arrayOf(PropTypes.object)
  })
};
