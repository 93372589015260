import PropTypes from 'prop-types';
import { DealerLocatorTermsCheckbox } from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import { useEspot, useReplaceTokenWithElement } from '@app/hooks';
import { CatTextLink } from 'blocks-react/bedrock/components/TextLink';
import {
  LEGAL_COOKIE_PRIVACY_ESPOT,
  PRIVACY_POLICY_ESPOT,
  TERMS_AND_CONDITIONS_ESPOT,
  PRIVACY_NOTICE_SECTION_ID,
  CAT_TERMS_DEALER_PRIVACY_POLICY_ESPOT,
  PCC_ESPOT_DEALER_PRIVACY_POLICY_SNP
} from '@app/components/associated-dealer/AssociatedDealerModal/AssociatedDealerForm/LegalAcceptance/ChildModalESpot/constants';
import { useSelector } from 'react-redux';
import {
  dealersHeadlessTermsConditionsURL,
  dealersHeadlessPrivacyPolicy,
  getConsentTemplate,
  privacyNoticeHeadlessURL,
  termsOfUseHeadlessURL
} from '@app/utils/headlessUtils';
import {
  TERMS_CONDITIONS_FEATURE_FLAG,
  PRIVACY_POLICY_FLAG
} from '@app/constants/featureFlags';

const TermsWrapper = ({
  dealerName,
  isDisabled,
  onTermsClick,
  dealerURL,
  dealerStoreId,
  displayDealerTermsAndCond
}) => {
  const [t] = useTranslation();
  const { replaceTokenWithElement } = useReplaceTokenWithElement();
  const enableTermsConditionFlag = useSelector(
    s => s.featureFlag?.[TERMS_CONDITIONS_FEATURE_FLAG]
  );
  const catTextLinkProps = enableTermsConditionFlag
    ? { href: dealersHeadlessTermsConditionsURL(dealerURL), target: '_blank' }
    : { onClick: () => onTermsClick(TERMS_AND_CONDITIONS_ESPOT) };

  const privacyPolicyFlagValue = useSelector(
    s => s.featureFlag?.[PRIVACY_POLICY_FLAG]
  );
  const url = dealersHeadlessPrivacyPolicy(dealerURL);
  const privacyPolicyLink = privacyPolicyFlagValue
    ? { href: url, target: '_blank' }
    : { onClick: () => onTermsClick(PRIVACY_POLICY_ESPOT) };
  const privacyNoticeUrl = privacyNoticeHeadlessURL(dealerURL);

  const privacyNoticeLink = privacyPolicyFlagValue
    ? { href: privacyNoticeUrl, target: '_blank' }
    : {
        onClick: () =>
          onTermsClick(LEGAL_COOKIE_PRIVACY_ESPOT, PRIVACY_NOTICE_SECTION_ID)
      };

  const termsOfUseUrl = termsOfUseHeadlessURL(dealerURL);
  const termsOfUseLink = privacyPolicyFlagValue
    ? { href: termsOfUseUrl, target: '_blank' }
    : { onClick: () => onTermsClick(LEGAL_COOKIE_PRIVACY_ESPOT) };

  /**
   * Returns clickable view of cat terms of use.
   * @returns
   */
  const getCatTermsOfUse = () => (
    <CatTextLink {...termsOfUseLink} size="sm" data-testid="terms">
      {t('UR_PARTS_TERMS_OF_USE_HREF')}
    </CatTextLink>
  );

  /**
   * Returns clickable view of cat privacy notice.
   * @returns
   */
  const getCatPrivacyNotice = () => (
    <CatTextLink {...privacyNoticeLink} size="sm">
      {t('UR_CAT_PRIVACY_NOTICE_HREF')}
    </CatTextLink>
  );

  /**
   * Returns clickable view of dealer specific Terms and condition.
   * @returns
   */
  const getDealerTermsAndConditions = () => (
    <CatTextLink {...catTextLinkProps} size="sm">
      <span>
        {replaceTokenWithElement(
          t('DEALERS_TERMS_OF_USE'),
          '',
          <span data-testid="dealer">{dealerName}</span>
        )}
      </span>
    </CatTextLink>
  );

  /**
   * Returns clickable view of dealer specific Privacy notice.
   * @returns
   */
  const getDealerPrivacyNotice = () => (
    <CatTextLink {...privacyPolicyLink} size="sm">
      <span>
        {replaceTokenWithElement(
          displayDealerTermsAndCond
            ? t('UR_DEALER_PRIVACY_NOTICE_HREF')
            : t('UR_CAT_DEALER_PRIVACY_NOTICE_HREF'),
          '',
          <span data-testid="dealer">{dealerName}</span>
        )}
      </span>
    </CatTextLink>
  );
  const eSpotNames = [
    CAT_TERMS_DEALER_PRIVACY_POLICY_ESPOT,
    PCC_ESPOT_DEALER_PRIVACY_POLICY_SNP
  ].join(',');
  const { getEspotContent } = useEspot(eSpotNames, '', dealerStoreId);

  const espotDealerPrivacyPolicySNP =
    getEspotContent(PCC_ESPOT_DEALER_PRIVACY_POLICY_SNP) || '';
  const espotHtml =
    espotDealerPrivacyPolicySNP?.contents?.length > 0
      ? espotDealerPrivacyPolicySNP?.contents[0]?.html
      : '';
  return (
    <DealerLocatorTermsCheckbox
      title={t('YES_I_ACCEPT_TEXT')}
      label={` (${t('REQUIRED').toLowerCase()})`}
      isDisabled={isDisabled}
    >
      {replaceTokenWithElement(
        getConsentTemplate(
          displayDealerTermsAndCond,
          getEspotContent,
          t,
          CAT_TERMS_DEALER_PRIVACY_POLICY_ESPOT
        ),
        '',
        getCatTermsOfUse(),
        getCatPrivacyNotice(),
        ...(!displayDealerTermsAndCond
          ? [getDealerTermsAndConditions(), getDealerPrivacyNotice()]
          : [replaceTokenWithElement(espotHtml, '', getDealerPrivacyNotice())])
      )}
    </DealerLocatorTermsCheckbox>
  );
};

TermsWrapper.propTypes = {
  isDisabled: PropTypes.bool,
  dealerName: PropTypes.string,
  onTermsClick: PropTypes.func,
  dealerURL: PropTypes.string,
  dealerStoreId: PropTypes.string,
  displayDealerTermsAndCond: PropTypes.bool
};

export { TermsWrapper };
