import { useSelector, useDispatch } from 'react-redux';
import { AlloyButton, AlloyIcon } from 'cat-ecommerce-alloy';
import PropTypes from 'prop-types';
import { deleteLineItem } from '@app/store/orders/actions';
import { getUpdateGAItem } from '../utils';
import useAnalytics from '@app/hooks/useAnalytics/useAnalytics';
import { LIST_PCC_CART_PAGE } from '@app/constants/analyticsConstants';
import styles from './cellStyles.module.scss';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';

const DeleteCell = ({ item }) => {
  const dispatch = useDispatch();
  const { fireRemoveFromCartEvent, fireProductClickEvent } = useAnalytics();
  const isMd = useBreakpoint(PAGE_BREAKPOINTS.MD);
  const iconSize = isMd ? 16 : 24;

  const storeName = useSelector(s => s.store?.name) || '';
  const { emailCart } = useSelector(s => s.common);

  const trackGA = items => {
    const gaItems = items.map(item =>
      getUpdateGAItem(item, item.quantity, storeName, emailCart)
    );
    fireProductClickEvent({ list: LIST_PCC_CART_PAGE, products: gaItems });
    fireRemoveFromCartEvent({ products: gaItems });
  };

  const deleteItem = () => dispatch(deleteLineItem(item.orderItemId, trackGA));

  return (
    <div
      className={`d-flex align-items-start justify-content-center py-2 ${styles['quantity__container-delete-icon']} d-print-none`}
    >
      <AlloyButton
        className="p-0"
        data-testid="deleteLineItemButton"
        buttonType="ghost"
        onClick={deleteItem}
      >
        <AlloyIcon
          icon="icon-delete"
          width={iconSize}
          height={iconSize}
          className={`${styles['quantity__container-trash-icon']}`}
        />
      </AlloyButton>
    </div>
  );
};

DeleteCell.propTypes = {
  item: PropTypes.shape({
    quantity: PropTypes.string,
    isUpdateLineItemLoading: PropTypes.bool,
    orderItemId: PropTypes.string,
    errors: PropTypes.shape({
      quantity: PropTypes.string,
      message: PropTypes.string
    })
  })
};
export default DeleteCell;
