import { ORDER_TYPES } from '@app/constants/commonConstants';

export const STORE_TYPE_PSA = ORDER_TYPES.PART_STORE;
export const STATUS_H = 'H';
export const STATUS_W = 'W';
export const STATUS_QX = 'QX';
export const STATUS_EXPIRED = 'E';
export const REORDER = 'Reorder';
export const APPROVED_ORDER = 'approved';
export const PENDING_ORDER = 'pending';
export const buttons = [
  {
    by: 'number',
    type: 'ALPHANUMERIC',
    text: 'ORDHISTORY_ORD_ORDER_NUMBER',
    serverkey: 'orderNumber'
  },
  {
    by: 'date',
    type: 'DATE',
    text: 'MA_ORDER_DATE',
    serverkey: 'orderDate'
  },
  {
    by: 'account',
    type: 'ALPHANUMERIC',
    text: 'FOOTER_ACCOUNT',
    serverkey: 'accountNumber'
  },
  {
    by: 'status',
    type: 'ALPHANUMERIC',
    text: 'SI_ORDERSTATUS',
    serverkey: 'orderStatus'
  },
  {
    by: 'serialNumber',
    type: 'ALPHANUMERIC',
    text: 'CAT_EQUIPMENT',
    serverkey: 'equipment'
  },
  {
    by: 'purchaseOrder',
    type: 'ALPHANUMERIC',
    text: 'BILL_PO_METHOD_COLON',
    serverkey: 'poNumber'
  },
  {
    by: 'shipPickupLocation',
    type: 'ALPHANUMERIC',
    text: 'SHIP_PICKUP_LOCATION_ADDRESS',
    serverkey: 'pickupOrShipLocation'
  },
  {
    by: 'price',
    type: 'CURRENCY',
    text: 'TOTAL_PRICE',
    serverkey: 'orderTotal'
  }
];

export const getPopperOptions = isLG => {
  return {
    placement: isLG ? 'top' : 'bottom-end'
  };
};
