export const FEEDBACK_TYPES = {
  ERROR: 'error',
  WARNING: 'warning'
};

export const ERROR_CREDIT_CARD_FAILURE = {
  title: 'CREDIT_CARD_FAILURE_HEADER',
  message: 'CREDIT_CARD_FAILURE_BODY'
};
export const ERROR_CREDIT_CARD_SESSION_EXPIRE = {
  title: 'CREDIT_CARD_SESSION_EXPIRED_HEADER',
  message: 'CREDIT_CARD_SESSION_EXPIRED_BODY'
};
export const ERROR_DBS_DOWN = {
  title: 'ORDER_PLACEMENT_UNAVAILABLE_HEADER',
  message: 'ORDER_PLACEMENT_UNAVAILABLE_BODY'
};
export const ERROR_PLACE_ORDER_FAILURE = {
  title: 'UNKNOWN_ERROR_TITLE',
  message: 'ORDER_PLACEMENT_UNAVAILABLE_BODY'
};
export const ERROR_PLACE_ORDER_LOCKED_BY_CSR = {
  title: 'UNKNOWN_ERROR_TITLE',
  message: 'USER_ADDING_ITEM_ERROR_MESSAGE'
};

export const ERROR_DOMAIN = {
  ADDRESS_BOOK: 'addressBook',
  ADP: 'ADP',
  CART: 'cart',
  CATEGORY: 'category',
  CHECKOUT: 'checkout',
  CONFLICT_MODAL: 'conflictModal',
  CONTROL_CENTER: 'controlCenter',
  CORE_TRACKING: 'coreTracking',
  CSR_LANDING_PAGE: 'csrLandingPage',
  DEALER_LOCATOR: 'dealerLocator',
  DEALER_USER_SEARCH: 'dealerUserSearch',
  DEALER_CUSTOMER_DCN: 'dealerCustomerDcn',
  GLOBAL: 'global',
  HEADER: 'header',
  HELP_CENTER: 'helpCenter',
  MARKETING: 'marketing',
  MLP: 'mlp',
  MY_EQUIPMENT: 'myEquipment',
  NOTIFICATION_PREFERENCES: 'notificationPreferences',
  ORDERS_TO_APPROVE: 'ordersToApprove',
  ORDER_CONFIRMATION: 'orderConfirmation',
  ORDER_STATUS: 'orderStatus',
  ORGANIZATIONS_AND_USERS: 'organizationsAndUsers',
  USER_DETAILS_PAGE: 'userDetailsPage',
  ORDER_DETAILS_PAGE: 'orderDetailsPage',
  PAYMENTMETHODS: 'paymentmethods',
  PERSONAL_INFORMATION: 'personalInformation',
  PDP: 'pdp',
  PLP: 'plp',
  SEARCH_UI: 'SEARCH_UI',
  PENDING_QUOTES: 'pendingQuotes',
  PENDING_QUOTE_DETAILS: 'pendingQuoteDetails',
  PENDING_ORDER_DETAILS: 'pendingOrderDetails',
  PREFERENCES: 'preferences',
  REPAIR_OPTIONS: 'repairOptions',
  REGISTRATION: 'registration',
  QUICK_ORDER: 'quickOrder',
  RECENT_ORDERS_HISTORY: 'recentOrdersHistory',
  SAVED_LIST: 'savedList',
  SHOPPING_LIST: 'shoppingList',
  ITEMS_IN_ORDER: 'itemsInOrder',
  INVOICES_PAGE: 'invoicesPage',
  INVOICE_DETAIL: 'invoiceDetail'
};

export const ERROR_PATH = {
  ADD_TO_CART: 'addToCart',
  ADD_PRODUCT_TO_LIST: 'addProductToList',
  BUNDLE_INFO: 'bundleInfo',
  CAT_CARD:'catCard',
  CAT_CREDIT: 'catCredit',
  CAT_CREDIT_INTERNATIONAL: 'catCreditInternational',
  CONFIRMMODAL: 'confirmModal',
  CONFLICT_RESOLUTION_MODAL: 'resolutionModal',
  CONVERSION_ERROR: 'conversionError',
  CREATE_LIST_MODAL: 'createNewListModal',
  CREATE_SHOPPING_LIST_FORM: 'createShoppingListForm',
  CSR_CONNECTION_FAIL: 'csrConnectionFail',
  CURRENT_LOCATION_POPUP: 'currentLocationPopup',
  DEALER_INFO: 'dealerInfo',
  DEALER_NOTIFICATION: 'dealerNotification',
  DEALER_NOTIFICATION_REQUEST_UPDATE: 'dealerNotificationRequestUpdate',
  EDIT_EQUIPMENT: 'editEquipment',
  EDIT_ORDER_INFORMATION: 'editOrderInformation',
  EDIT_USER_DETAILS: 'editUserDetailsPage',
  EQUIPMENT_DETAILS: 'equipmentDetails',
  EMPTY_CART_EMPTY_MODAL: 'emptyCartEmptyModal',
  EXPORT_FILE: 'exportFile',
  FILTERS: 'filters',
  FREIGHT_AND_TAX: 'freightAndTax',
  LOCATIONS: 'locations',
  MAIN: 'main',
  MODAL: 'modal',
  NAVIGATION_BAR_GLOBAL_MODAL: 'navigationBarGlobalModal',
  NOTIFICATION_WIDGET: 'notificationWidget',
  NOTIFICATION_MODAL: 'notificationModal',
  NOTIFICATION_MODAL_FILTER: 'notificationModalFilter',
  PARTS_BY_CATEGORY: 'partsByCategory',
  PAGE_LAYOUT: 'pageLayout',
  PAYMENT_FINALIZATION: 'paymentFinalization',
  PAYMENT_INFO: 'paymentInfo',
  PAYMENTMETHODS: 'paymentMethods',
  PENDING_ORDER_PO: 'pendingOrderPO',
  PENDING_QUOTES_WIDGET: 'pendingQuotesWidget',
  PENDING_QUOTES_WIDGET_ACTIONS: 'pendingQuotesWidgetActions',
  PENDING_QUOTE_SEARCH_PAGE_ACTIONS: 'pendingQuotePageActions',
  PENDING_QUOTE_SEARCH_PAGE_ACTIONS_PLACE_ORDER:
    'pendingQuotePageActionsPlaceOrder',
  PENDING_QUOTE_APPROVER_ACTIONS: 'pendingQuoteApproverActions',
  PICKUP_DELIVERY_MAIN: 'pickupAndDeliveryMain',
  PICKUP_DELIVERY_LOAD: 'pickupAndDeliveryLoad',
  PICKUP_DELIVERY_CONTACT_INFO: 'pickupAndDeliveryContactInfo',
  PUD_DROPBOX_SERVICE: 'pudDropboxService',
  PARTS_PRICE_AND_AVAILABILITY: 'partsPriceAndAvailability',
  PROMOCODE: 'promoCode',
  PURCHASEORDER: 'purchaseOrder',
  SIS_PARTS_ERROR: 'sisPartsError',
  ROOT: 'root',
  RECENT_ORDERS_WIDGET: 'recentOrderWidget',
  SEARCH_RESULTS: 'searchResults',
  SELF_SERVICE_REPAIR_OPTIONS: 'selfServiceRepairOptions',
  SERIAL_MISMATCH_MODAL: 'serialMismatchModal',
  SCHEMATICS: 'schematics',
  SUPPORT_OPTIONS: 'supportOptions',
  PARTS_SOS_CODE: 'partsSosCode',
  STORES: 'stores',
  STORE_INFO: 'storeInfo',
  TABLE: 'table',
  TOP_SELLING_PARTS: 'topSellingParts',
  WAFFLE_POPUP: 'wafflePopup',
  EQUIPMENT_LIST: 'equipmentList',
  SERVICE_OPTIONS_LIST: 'serviceOptionsList',
  DELETE: 'delete',
  PLACE_ORDER: 'placeOrder',
  SHOPPING_CART: 'shoppingCart',
  DISABLED_USER: 'pageLoad',
  ORGS_USER_SEARCH_PAGE: 'userSearchPage',
  ORDERS_TO_APPROVE_WIDGET: 'ordersToApproveWidget',
  ORDERS_TO_APPROVE_WIDGET_TABLE: 'ordersToApproveWidgetTable',
  ORDERS_TO_APPROVE_SEARCH_PAGE: 'ordersToApproveSearchPage',
  ORDERS_TO_APPROVE_SEARCH_TABLE: 'ordersToApproveSearchTable',
  ORDER_DETAILS_PAGE: 'orderDetailsPage',
  ORDERS_TO_APPROVE_SEARCH_ATTACHMENT: 'ordersToApproveSearchAttachment',
  ORDERS_TO_APPROVE_WIDGET_ATTACHMENT: 'ordersToApproveWidgetAttchment',
  ORDER_CONFIRMATION_BILLING: 'orderConfirmationBilling',
  ORDER_CONFIRMATION_FULFILLMENT: 'orderConfirmationFulfillment',
  ORDER_CONFIRMATION_HEADER: 'orderConfirmationHeader',
  ORDER_CONFIRMATION_INFORMATION: 'orderConfirmationInformation',
  ORDER_ITEM_INFO: 'orderItemInfo',
  ORGS_USER_DETAILS_PAGE: 'orgsAndUserDetailsPage',
  ORDER_CONFIRMATION_TOTAL: 'orderConfirmationTotal',
  PRODUCT_DETAILS: 'productDetails',
  UNCONTROLLED: 'uncontrolled',
  LINE_ITEMS_TOTAL_PRICE: 'lineItemsTotalPrice',
  RELATED_PRICE_AND_AVAILABILITY: 'relatedPriceAndAvailability',
  RECOMMENDED_PRICE_AND_AVAILABILITY: 'recommendedPriceAndAvailability',
  RELATED_SOS_CODE: 'relatedSosCode',
  RECOMMENDED_SOS_CODE: 'recommendedSosCode',
  LINE_ITEMS_PRICE_AND_AVAILABILITY: 'lineItemPriceAndAvailability',
  LINE_ITEMS_SOS_CODE: 'lineItemSosCode',
  SAVED_LIST: 'savedList',
  CURRENT_CART_WIDGET: 'currentCartWidget',
  RECENTLY_VIEWED_WIDGET: 'recentlyViewedWidget',
  DISABLE_PLACE_ORDER: 'disablePlaceOrder',
  INVOICES: 'invoices',
  INVOICE: 'invoice',
  DEALER_SYSTEM_DOWN: 'dealerSystemDown',
  PIX_PAYMENT_MODAL: 'pixPaymentModal',
  FBT: 'fbt',
  SHARE_CART_MODAL: 'shareCartModal',
  CSR_SEARCH_RESULTS_TIMEOUT: 'csrSearchResultsTimeout',
  CSR_UNAUTHORIZED_ACTION: 'csrUnauthorizedAction',
  JOBS_RECOMMENDATIONS: 'jobsRecommendations',
  SHOPPING_CART_CRITICAL_ERROR: 'shoppingCartCriticalError',
  SIS_TO_CART_IMPORT: 'sisToCartImport'
};

export const CSR_SEARCH_ERROR = {
  title: 'UNKNOWN_ERROR_TITLE',
  message: `We're sorry. an unknown error has occurred. Please try again or modify the search criteria. If issues persist, please contact your supervisor or the support team.`
};

export const ORDER_DETAILS_SEARCH_ERROR = {
  title: 'UNKNOWN_ERROR_TITLE',
  message: `We're sorry. an unknown error has occurred. Please try again or modify the search criteria. If issues persist, please contact your supervisor or the support team.`
};

export const CSR_CUSTOMER_SELECT_ERROR = {
  title: 'UNKNOWN_ERROR_TITLE',
  message: `We're sorry. an unknown error has occurred. Please try again. If issues persist, please contact your supervisor or the support team.`
};

export const ERROR_MESSAGE = {
  GENERIC: 'Error'
};

export const ERROR_ONLINE_PAYMENT_FAILURE = {
  title: 'ONLINE_PAYMENT_FAILURE_HEADER',
  message: 'ONLINE_PAYMENT_FAILURE_BODY'
};
export const ERROR_ONLINE_PAYMENT_SESSION_EXPIRE = {
  title: 'ONLINE_PAYMENT_SESSION_EXPIRED_HEADER',
  message: 'ONLINE_PAYMENT_SESSION_EXPIRED_BODY'
};

export const MLP_ERROR_MESSAGE = {
  message: 'MLP_ERROR3'
};

export const PMKIT_ERROR_MESSAGE = {
  message: 'PM_KIT_SERVICE_ERR'
};

export const EMPTY_CART_ERROR_MESSAGE = {
  message: 'EMPTY_CART_ERROR'
};

export const CUT_OFF_TIME_CROSSED_ERROR =
  'REQUESTED_SHIPPING_DATE_AFTER_CUTOFF';

export const PRICING_PARTS_ERROR = 'SHOPPING_CART_PRICING_ERROR';

export const UNKNOWN_ERROR_MESSAGE = 'UNKNOWN_ERROR_MESSAGE';
export const UNKNOWN_ERROR_CANCEL_IMPORT = 'UNKNOWN_ERROR_CANCEL_IMPORT';

export const UNKNOWNERROR = {
  title: 'UNKNOWN_ERROR_TITLE',
  message: UNKNOWN_ERROR_MESSAGE,
  severity: 'error'
};

export const ERROR_DROPBOX_API_FAILURE = {
  message: 'SYSTEM_FEEDBACK_ERROR_DROPBOX_DATA_FAILED'
};

export const CERT_ERROR_MESSAGE = 'CERT_ERROR_MESSAGE';
export const CERT_MISMATCH = 'CERT_MISMATCH';
export const NO_FUNDS_AVAILABLE = 'NO_FUNDS_AVAILABLE';
export const CAT_CREDIT_EXPIRED_HEADER = 'CAT_CREDIT_EXPIRED_HEADER';
export const CAT_CREDIT_APPLIED_HEADER = 'CAT_CREDIT_APPLIED_HEADER';
export const VOID_FAILURE_CAT_CREDIT_HEADER = 'VOID_FAILURE_CAT_CREDIT_HEADER';
export const CVR_REWARDS_CERT_ERROR_MESSAGE = 'CVR_REWARDS_CERT_ERROR_MESSAGE';
export const CERT_MISMATCH_ERROR = {
  title: 'CAT_CREDIT_MISMATCH_HEADER',
  message: 'CAT_CREDIT_MISMATCH_BODY'
};

export const CERT_NO_FUNDS_AVAILABLE = {
  title: 'CAT_CREDIT_NO_FUNDING_HEADER',
  message: 'CAT_CREDIT_NO_FUNDING_BODY',
  fieldError: ['cardNumber', 'CAT_CREDIT_NO_FUNDING_ENTRY']
};
export const CERT_EXPIRED_ERROR = {
  title: 'CAT_CREDIT_EXPIRED_HEADER',
  message: 'CAT_CREDIT_EXPIRED_BODY',
  fieldError: ['expDate', 'CAT_CREDIT_EXPIRED_EXPDATE']
};
export const CERT_SAMECARD_ERROR = {
  title: 'CAT_CREDIT_APPLIED_HEADER',
  message: 'CAT_CREDIT_APPLIED_BODY'
};
export const CAT_CREDIT_VOID_FAILURE_ERROR = {
  title: 'VOID_FAILURE_CAT_CREDIT_HEADER',
  message: 'VOID_FAILURE_CAT_CREDIT_BODY'
};

export const CVR_REWARDS_CERT_ERROR_MESSAGE_ERROR = {
  message: 'CAT_CREDITS_E_CERTIFICATE_ID_MSG',
  fieldError: ['cardNumber'],
  severity: 'success'
};
export const ERR_GTS_COMPLIANCE = '_ERR_GTS_COMPLIANCE';

export const INFO_SHOPPING_CART_CRITICAL_ERROR = {
  title: 'WE_ARE_UNABLE_TECHNICAL_ISSUE',
  message: 'TRY_TO_REFRESH_YOUR_PAGE'
};

export const NOT_AUTHORIZED_FOR_QUERY = 'NOT_AUTHORIZED_FOR_QUERY';
