export const PAGE_LISTS = [
  'UserRegistrationForm',
  'AjaxLogonForm',
  'Logon',
  'AjaxOrderItemDisplayView',
  'OrderShippingBillingView',
  'SingleShipmentOrderSummaryView',
  'OrderShippingBillingConfirmationView',
  'commonPartsView',
  'GetAssociatedDealersView',
  'OrderDetail',
  'CATNotificationPreferencesView',
  'DealerQuoteViewOnlineCmd'
];

export const FROM_PSW_PREF = 'fromPSWPref';
export const PORTAL_REQUEST = 'portalRequest';
export const PRSN = 'prSn';
export const CAT_PREFS_CHECK = 'catPrefsCheck';
export const DEALER_SYSTEM_DOWN = 'dealerSystemDown';
export const ACCT_PR_NEWFLOW_SHOWNFLAG = 'acctPrNewFlowShownFlag';
export const DEEPLINK_FLOW = 'deeplinkFlow';
export const HEADER_DATA = 'headerData';
export const CAT_URI_FRAGMENT = 'catURIFragment';
export const PROCESS_ERROR = 'processError';
export const SIS_PARTS_LIST = 'sisPartsList';
export const PCC_LANDING_PAGE_VIEW = 'PCCLandingPageView';
export const GUEST_SIS_HIT = 'guestSisHit';
export const GUEST_SAVED_LIST_HIT = 'guestSavedListHit';
export const GUEST_MY_EQUIPMENT = 'guestMyEquipment';
