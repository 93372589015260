import PropTypes from 'prop-types';
import { ForwardIcon } from 'cat-ecommerce-alloy';

const CategoryCardTitle = ({ name }) => {
  return (
    <>
      <h2
        className="h3 u-fs--20 mb-0 u-color--ExtraDarkGray d-inline"
        aria-label={name}
        title={name}
      >
        {name}
      </h2>
      <ForwardIcon
        height={20}
        width={20}
        style={{ fill: '#3F3F3F' }}
        className="mt-n1 ms-1"
      />
    </>
  );
};

CategoryCardTitle.propTypes = {
  name: PropTypes.string
};

export default CategoryCardTitle;
