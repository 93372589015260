import { CatInputField } from 'blocks-react/bedrock/components/InputField';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEspot } from '@app/hooks';
import {
  DEALER_LOCAL_COMPLIANCE_Details_espot,
  DEALER_MARKETING_OPTIN_INFO_CLAUSE_espot
} from '@app/components/pages/registration/constants';
import Espot from '@app/components/common/Espot/Espot';
import { CatCheckboxField } from 'blocks-react/bedrock/components/CheckboxField';
import { CatHeading } from 'blocks-react/bedrock/components/Heading';
import { replaceTokensInString } from '@app/utils';
import { NAME_ERROR } from '@app/components/EmailMarketingOptInEffect/constants';
import {
  INVALID_TAX_FIELD_CHARS_REGEX,
  INVALID_NUM_TAX_FIELD_CHARS_REGEX,
  ONLY_DIGIT_TAX_FIELD_CHARS_REGEX
} from '../../constants';

const TaxInfoModalForm = () => {
  const [t] = useTranslation();
  const {
    watch,
    register,
    setValue,
    formState: { errors }
  } = useFormContext();
  const individualLabel = useSelector(
    state => state?.dealer?.individualTaxIdLabel
  );
  const companyLabel = useSelector(state => state?.dealer?.companyTaxIdLabel);
  const stateTaxIdLabel = useSelector(state => state?.dealer?.stateTaxIdLabel);

  const isStateTaxIdRequired = useSelector(
    state => state?.dealer?.isStateTaxIdRequired
  );

  const isStateTaxIdEnabled = useSelector(
    state => state?.dealer?.isStateTaxIdEnabled
  );

  const neighborhoodLabel = useSelector(
    state => state?.dealer?.neighborhoodLabel
  );

  const isNeighborhoodRequired = useSelector(
    state => state?.dealer?.isNeighborhoodRequired
  );

  const isNeighborhoodEnabled = useSelector(
    state => state?.dealer?.isNeighborhoodEnabled
  );

  const dealerMarketingOptInFlag = useSelector(
    state => state?.dealer?.dealerMarketingOptInFlag
  );

  const eSpotNames = [
    DEALER_MARKETING_OPTIN_INFO_CLAUSE_espot,
    DEALER_LOCAL_COMPLIANCE_Details_espot
  ].join(',');
  const { getEspotContent } = useEspot(eSpotNames);
  const marketingOptInfoClause =
    getEspotContent(DEALER_MARKETING_OPTIN_INFO_CLAUSE_espot) || '';
  const dealerComplianceDetails =
    getEspotContent(DEALER_LOCAL_COMPLIANCE_Details_espot) || '';
  const DEFAULT_LENGTH_VALUE = '255';

  //Type number of input still allows some non-numeric characters that we are avoiding here
  const handleKeyDown = e => {
    if (INVALID_NUM_TAX_FIELD_CHARS_REGEX.test(e.key)) {
      e.preventDefault();
    }
  };

  //Copy pasting on numeric input still allows some special chars, this is to disable them
  const handlePaste = e => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    const sanitizedData = pastedData.replace(
      ONLY_DIGIT_TAX_FIELD_CHARS_REGEX,
      ''
    );
    setValue('stateTaxId', watch('stateTaxId') + sanitizedData);
  };

  return (
    <>
      {!!watch('taxIdOption') &&
      watch('taxIdOption') === t('UR_CAT_COMPANY') ? (
        <div className="d-flex flex-column gap-3">
          <CatInputField
            fieldId="Company"
            name="Company"
            required
            label={companyLabel}
            placeholder=" "
            maxlength={DEFAULT_LENGTH_VALUE}
            type="text"
            hideStepper
            hideWordCount
            data-testid="taxIdCompany"
            status={errors?.Company ? 'error' : undefined}
            fieldNote={errors?.Company?.message}
            {...register('Company', {
              required: {
                value: true,
                message: t('ERROR_CAT_TAX_ID_COMPANY_REQUIRED')
              },
              pattern: {
                value: INVALID_TAX_FIELD_CHARS_REGEX,
                message: replaceTokensInString(
                  t('ADD_INV_CHAR_NEW_FIELD_WARNING'),
                  undefined,
                  NAME_ERROR
                )
              }
            })}
          />

          {isStateTaxIdEnabled && (
            <CatInputField
              fieldId="stateTaxId"
              name="stateTaxId"
              type="number"
              hideStepper
              required={isStateTaxIdRequired}
              label={stateTaxIdLabel}
              placeholder=" "
              data-testid="stateTaxId"
              hideWordCount
              maxLength={DEFAULT_LENGTH_VALUE}
              onkeydown={handleKeyDown}
              onPaste={handlePaste}
              status={errors?.stateTaxId ? 'error' : undefined}
              fieldNote={errors?.stateTaxId?.message}
              {...register('stateTaxId', {
                required: {
                  value: isStateTaxIdRequired,
                  message: t('ERROR_STATE_TAX_ID_REQUIRED')
                }
              })}
            />
          )}

          {isNeighborhoodEnabled && (
            <CatInputField
              fieldId="neighborhood"
              name="neighborhood"
              hideStepper
              required={isNeighborhoodRequired}
              label={neighborhoodLabel}
              placeholder=" "
              data-testid="neighborhood"
              type="text"
              hideWordCount
              maxLength={40}
              status={errors?.neighborhood ? 'error' : undefined}
              fieldNote={errors?.neighborhood?.message}
              {...register('neighborhood', {
                required: {
                  value: isNeighborhoodRequired,
                  message: t('ERROR_Neighborhood')
                },
                pattern: {
                  value: INVALID_TAX_FIELD_CHARS_REGEX,
                  message: replaceTokensInString(
                    t('ADD_INV_CHAR_NEW_FIELD_WARNING'),
                    undefined,
                    NAME_ERROR
                  )
                }
              })}
            />
          )}
        </div>
      ) : null}

      {!!watch('taxIdOption') &&
      watch('taxIdOption') === t('UR_CAT_INDIVIDUAL') ? (
        <CatInputField
          fieldId="Individual"
          name="Individual"
          required
          label={individualLabel}
          placeholder=" "
          data-testid="taxIdIndividual"
          maxlength={DEFAULT_LENGTH_VALUE}
          hideStepper
          hideWordCount
          type="text"
          status={errors?.Individual ? 'error' : undefined}
          fieldNote={errors?.Individual?.message}
          {...register('Individual', {
            required: {
              value: true,
              message: t('ERROR_CAT_TAX_ID_INDIVIDUAL_REQUIRED')
            },
            pattern: {
              value: INVALID_TAX_FIELD_CHARS_REGEX,
              message: replaceTokensInString(
                t('ADD_INV_CHAR_NEW_FIELD_WARNING'),
                undefined,
                NAME_ERROR
              )
            }
          })}
        />
      ) : null}

      {dealerMarketingOptInFlag && (
        <>
          <CatCheckboxField
            name="dealerReceiveEmail"
            id="dealerReceiveEmail"
            ariaLabel="Dealer-compliance"
            {...register('dealerReceiveEmail')}
          >
            <span className="u-fs--14 u-color--ExtraDarkGray">
              {t('YES_SIGN_ME_UP')}
            </span>
          </CatCheckboxField>
          <CatHeading variant="footnote">
            <div className="mt-2">
              <Espot html={marketingOptInfoClause} />
              <Espot
                className={marketingOptInfoClause ? 'mt-3' : ''}
                html={dealerComplianceDetails}
              />
            </div>
          </CatHeading>
        </>
      )}
    </>
  );
};
export default TaxInfoModalForm;
