import { STATUS } from '../../constants/commonConstants';
import * as types from './constants';
import { searchUIInitialStore } from './initialStore';
import * as productTypes from '../products/constants';
import * as myEquipmentTypes from '../myequipment/constants';

const searchUIReducer = (state = searchUIInitialStore, action) => {
  switch (action.type) {
    case types.SEARCH_UI_GET_DETAILS_BEGIN: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        searchUIDetailsStatus: STATUS.PENDING,
        searchUIDetailsError: undefined
      };
    }
    case types.SEARCH_UI_GET_DETAILS_SUCCESS: {
      const { payload } = action;
      const { sortBy, ...rest } = payload;
      return {
        ...state,
        ...rest,
        queryParams: {
          ...state.queryParams,
          sortBy
        },
        searchUIDetailsStatus: STATUS.RESOLVED
      };
    }
    case types.SEARCH_UI_GET_DETAILS_FAIL: {
      const { payload } = action;
      return {
        ...state,
        searchUIDetailsStatus: STATUS.REJECTED,
        searchUIDetailsError: payload
      };
    }
    case types.SEARCH_UI_SET_PRODUCT_CARD_EXPANDED: {
      const { payload } = action;
      return {
        ...state,
        productCardExpandedArr: payload
      };
    }
    case types.SEARCH_UI_SET_EQUIPMENT_CHECKED: {
      const { payload } = action;
      return {
        ...state,
        filterByEquipment: payload
      };
    }
    case types.SEARCH_UI_SET_QUERY_PARAM: {
      const { payload } = action;
      const { sortBy, categoryId, ...rest } = payload;
      return {
        ...state,
        ...rest,
        queryParams: {
          ...state.queryParams,
          sortBy,
          categoryId
        }
      };
    }
    case types.SEARCH_UI_SET_ACTIVE_FACETS: {
      const {
        payload: { unitOfMeasure, activeFacets }
      } = action;
      return {
        ...state,
        unitOfMeasure,
        activeFacets
      };
    }
    case types.SEARCH_UI_SET_FACETS_UOM_FILTERED: {
      return {
        ...state,
        ...action.payload
      };
    }
    case types.SEARCH_UI_SET_SEARCH_TERM: {
      const { payload } = action;
      return {
        ...state,
        spellingSuggestions: [],
        searchUIDetailsStatus: STATUS.IDLE,
        queryParams: {
          ...state.queryParams,
          searchTerm: payload
        }
      };
    }
    case types.SEARCH_UI_GET_PREVIOUS_DETAILS_BEGIN: {
      return {
        ...state,
        getPrevSearchUIDetailsStatus: STATUS.PENDING,
        getPrevSearchUIDetailsError: undefined
      };
    }
    case types.SEARCH_UI_GET_PREVIOUS_DETAILS_SUCCESS: {
      const { lowestLoadedPage, catalogEntries, products } = action.payload;
      return {
        ...state,
        products: [...products, ...state.products],
        catalogEntries: [...state.catalogEntries, ...catalogEntries],
        getPrevSearchUIDetailsStatus: STATUS.RESOLVED,
        lowestLoadedPage
      };
    }
    case types.SEARCH_UI_GET_PREVIOUS_DETAILS_FAIL: {
      const { payload } = action;
      return {
        ...state,
        getPrevSearchUIDetailsStatus: STATUS.REJECTED,
        getPrevSearchUIDetailsError: payload
      };
    }

    case types.SEARCH_UI_GET_MORE_DETAILS_FAIL: {
      const { payload } = action;
      return {
        ...state,
        getMoreSearchUIDetailsStatus: STATUS.REJECTED,
        getMoreSearchUIDetailsError: payload
      };
    }
    case types.SEARCH_UI_GET_MORE_DETAILS_BEGIN: {
      return {
        ...state,
        getMoreSearchUIDetailsStatus: STATUS.PENDING,
        getMoreSearchUIDetailsError: undefined
      };
    }
    case types.SEARCH_UI_GET_MORE_DETAILS_SUCCESS: {
      const { products, catalogEntries } = action.payload;
      return {
        ...state,
        products: [...state.products, ...products],
        catalogEntries: [...state.catalogEntries, ...catalogEntries],
        getMoreSearchUIDetailsStatus: STATUS.RESOLVED
      };
    }
    case types.SEARCH_UI_EQUIPMENT_CHECKED: {
      const { payload } = action;
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filterByEquipment: payload
        }
      };
    }

    case productTypes.SEARCH_UI_PRODUCTS_PRICE_SET_STATUS: {
      const { status } = action.payload;
      return {
        ...state,
        priceAndAvailibiltyStatus: status
      };
    }
    case myEquipmentTypes.SEARCH_UI_GET_EQUIPMENT_FITMENT_SET_STATUS: {
      return {
        ...state,
        equipmentFitmentStatus: action.payload
      };
    }
    case types.SORT_PRODUCTS_BY_BEGIN: {
      return {
        ...state,
        sortByProductsStatus: STATUS.PENDING
      };
    }
    case types.SORT_PRODUCTS_BY_SUCCESS: {
      return {
        ...state,
        sortByProductsStatus: STATUS.RESOLVED
      };
    }
    case types.SORT_PRODUCTS_BY_FAIL: {
      return {
        ...state,
        sortByProductsStatus: STATUS.REJECTED
      };
    }
    default:
      return state;
  }
};

export { searchUIInitialStore };
export default searchUIReducer;
