import PropTypes from 'prop-types';
import Page from '@app/components/common/Page';
import CategoriesNav from '@app/components/common/CategoriesNav';
import { SkeletonLoader } from 'cat-ecommerce-alloy';
import ShopAllCategoriesGrid from '../../shop-all-categories/ShopAllCategoriesGrid';
import TopCategoryGrid from '../../top-category/TopCategoryGrid';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import { useBreakpoint } from '@app/hooks';
import styles from './CategorySkeletonLoader.module.scss';

const CategorySkeletonLoader = ({
  showNavAsMenu = false,
  skeletonSize,
  isShopAllCategoryPage = false
}) => {
  const isLG = useBreakpoint(PAGE_BREAKPOINTS.LG);
  const isMD = useBreakpoint(PAGE_BREAKPOINTS.MD);
  return (
    <Page dataTestId="category-skeleton-loader">
      <SkeletonLoader
        className={`my-2 ${styles['skeleton-max-width-300px']} height-30px`}
      />
      <SkeletonLoader className={`height-${isLG ? '60' : '100'}px mb-2`} />
      <Page.Header>
        <h1 className="h1 display-1">
          <SkeletonLoader
            className={`height-50px ${styles['skeleton-max-width-400px']} mb-1`}
          />
        </h1>
      </Page.Header>
      <Page.Layout>
        <Page.Layout.SideBar>
          {showNavAsMenu ? (
            <CategoriesNav isLoading skeletonSize={skeletonSize} />
          ) : (
            <SkeletonLoader
              className={`height-${isMD ? '500' : '50'}px mb-1`}
            />
          )}
        </Page.Layout.SideBar>
        <Page.Layout.Content>
          {isShopAllCategoryPage ? (
            <ShopAllCategoriesGrid
              isLoading
              isEspotLoading
              skeletonSize={skeletonSize}
            />
          ) : (
            <>
              <SkeletonLoader className="mb-2 height-100px" />
              <TopCategoryGrid isLoading isEspotLoading />
              <SkeletonLoader
                className={`${styles['skeleton-height-500px']} mb-2`}
              />
            </>
          )}
        </Page.Layout.Content>
      </Page.Layout>
      <SkeletonLoader className={`height-${isLG ? '200' : '100'}px mb-2`} />
    </Page>
  );
};

CategorySkeletonLoader.propTypes = {
  showNavAsMenu: PropTypes.bool,
  isShopAllCategoryPage: PropTypes.bool,
  skeletonSize: PropTypes.number
};

export default CategorySkeletonLoader;
