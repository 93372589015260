import { notificationHeaderInitialStore } from './initialStore';
import * as types from './constants';
import { STATUS } from '@app/constants/commonConstants';
import {
  isUnRearNotificationCount,
  isALLNotificationDismissed,
  sortNotifications
} from './utils';

const filterNotificationData = (notificationData, filterData, mode) => {
  return notificationData?.map(item => {
    const findNotificationIndex = filterData?.findIndex(
      row => row?.notificationId === item?.notificationId
    );
    if (mode === 'isDismissed' && findNotificationIndex !== -1) {
      return -1;
    }
    return findNotificationIndex !== -1 ? { ...item, [mode]: true } : item;
  });
};

const notificationHeaderReducer = (
  state = notificationHeaderInitialStore,
  action
) => {
  switch (action.type) {
    case types.GET_HEADER_NOTIFICATION_BEGIN:
      return {
        ...state,
        notificationStatus: STATUS.PENDING,
        filteredNotifications: state.data?.filteredNotifications
          ? state.data?.filteredNotifications
          : [],
        isLoading: true
      };
    case types.GET_HEADER_NOTIFICATION_SUCCESS:
      const sortedNotifications = sortNotifications(
        action.payload.notifications
      );
      return {
        ...state,
        notificationStatus: STATUS.RESOLVED,
        data: { ...action.payload, notifications: sortedNotifications },
        isLoading: false,
        isUnreadNotifications: isUnRearNotificationCount(sortedNotifications),
        isActiveNotification: isALLNotificationDismissed(sortedNotifications)
      };
    case types.GET_HEADER_NOTIFICATION_FAIL:
      return {
        ...state,
        notificationStatus: STATUS.REJECTED,
        isLoading: false
      };
    case types.READ_NOTIFICATION_SUCCESS:
      const updatedNotificationData = filterNotificationData(
        state.data?.notifications,
        action.payload?.notifications,
        'isRead'
      );
      const sortedNotificationsData = sortNotifications(
        updatedNotificationData
      );

      return {
        ...state,
        data: { ...state.data, notifications: sortedNotificationsData },
        isUnreadNotifications: isUnRearNotificationCount(
          sortedNotificationsData
        ),
        isActiveNotification: isALLNotificationDismissed(sortedNotifications)
      };
    case types.DISMISS_NOTIFICATION_BEGIN: {
      return {
        ...state,
        isLoading: true
      };
    }
    case types.DISMISS_NOTIFICATION_SUCCESS: {
      const updatedNotificationData = filterNotificationData(
        state.data?.notifications,
        action.payload?.notifications,
        'isDismissed'
      );

      return {
        ...state,
        data: {
          ...state.data,
          recordSetTotal:
            state.data?.recordSetTotal - action.payload?.notifications?.length,
          notifications: updatedNotificationData.filter(item => item !== -1)
        },
        isLoading: false,
        isUnreadNotifications: isUnRearNotificationCount(
          updatedNotificationData
        ),
        isActiveNotification: isALLNotificationDismissed(
          updatedNotificationData
        )
      };
    }
    case types.DISMISS_NOTIFICATION_SUCCESS_BANNER: {
      return {
        ...state,
        isDismissedSuccessBanner: action.payload
      };
    }

    case types.INCLUDE_DISMISSED_NOTIFICATIONS: {
      return {
        ...state,
        includeDismissedNotifications: action.payload
      };
    }

    case types.NOTIFICATION_FILTER: {
      return {
        ...state,
        notificationFilter: action.payload
      };
    }

    case types.NOTIFICATION_FILTER_BEGIN:
    case types.NOTIFICATION_FILTER_SUCCESS:
    case types.NOTIFICATION_FILTER_ERROR: {
      return {
        ...state,
        isLoading: false
      };
    }
    case types.NOTIFICATION_GET_DEALER_ACCOUNTS_SUCCESS: {
      const { preferencesData } = action.payload || {};
      return {
        ...state,
        notificationPreferencesStatus: preferencesData
      };
    }

    default:
      return state;
  }
};
export { notificationHeaderInitialStore };
export default notificationHeaderReducer;
