import { useContext } from 'react';
import Field from '@app/components/common/form/Field';
import { Checkbox, AlloyButton, ModalContext } from 'cat-ecommerce-alloy';
import { useTranslation } from 'react-i18next';
import { useReplaceTokenWithElement } from '@app/hooks';
import { useFormikContext } from 'formik';
import ChildModalESpot from './ChildModalESpot';
import {
  LEGAL_COOKIE_PRIVACY_ESPOT,
  PRIVACY_NOTICE_SECTION_ID,
  PRIVACY_POLICY_ESPOT,
  TERMS_AND_CONDITIONS_ESPOT
} from './ChildModalESpot/constants';

const LegalAcceptance = () => {
  const [t] = useTranslation();
  const { replaceTokenWithElement } = useReplaceTokenWithElement();
  const { values: { dealer } = {} } = useFormikContext();
  const modalCtx = useContext(ModalContext);

  const buttonProps = {
    className: 'p-0',
    buttonType: 'link'
  };

  const showEspotModal = (espotName, scrollIntoViewId = '') => {
    if (espotName) {
      modalCtx.addModalWithOptions(
        <ChildModalESpot
          espotName={espotName}
          scrollIntoViewId={scrollIntoViewId}
          storeId={dealer.value}
        />,
        {
          focusSelector: scrollIntoViewId
        }
      );
    }
  };

  const legalAcceptance = t('TERMS_CONDITIONS_PARTSCAT_ADP');
  const dealerTermsAndConditions = replaceTokenWithElement(
    t('DEALERS_TERMS_OF_USE'),
    'btn-link',
    dealer?.label
  );
  const dealerPrivacyPolicy = replaceTokenWithElement(
    t('UR_CAT_DEALER_PRIVACY_NOTICE_HREF'),
    'btn-link',
    dealer?.label
  );
  const helpText = replaceTokenWithElement(
    legalAcceptance,
    '',
    <>
      <AlloyButton
        {...buttonProps}
        onClick={() => showEspotModal(LEGAL_COOKIE_PRIVACY_ESPOT)}
      >
        {t('UR_CAT_TERMS_OF_USE_HREF')}
      </AlloyButton>
    </>,
    <AlloyButton
      {...buttonProps}
      onClick={() =>
        showEspotModal(LEGAL_COOKIE_PRIVACY_ESPOT, PRIVACY_NOTICE_SECTION_ID)
      }
    >
      {`${t('UR_CAT_PRIVACY_NOTICE_HREF')} `}
    </AlloyButton>,
    <AlloyButton
      {...buttonProps}
      onClick={() => showEspotModal(TERMS_AND_CONDITIONS_ESPOT)}
    >
      {dealerTermsAndConditions}
    </AlloyButton>,
    <AlloyButton
      {...buttonProps}
      onClick={() => showEspotModal(PRIVACY_POLICY_ESPOT)}
    >
      {dealerPrivacyPolicy}
    </AlloyButton>
  );
  return (
    <Field
      type="checkbox"
      as={Checkbox}
      id="legalAcceptance"
      label={t('1628_ACCEPT_TERMS_AND_CONDITIONS_2')}
      name="legalAcceptance"
      showRequired
      labelClassName="fw-bold text-uppercase"
      helpText={helpText}
      helpTextClassName="mb-3"
    />
  );
};

export default LegalAcceptance;
