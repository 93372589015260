import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef, useCallback } from 'react';
import Hammer from 'hammerjs';
import MyEquipmentTitle from '../../MyEquipmentTitle';
import styles from './findMySerial.module.scss';
import FindMySerialItem from './FindMySerialItem';
import { FIND_MY_SERIAL_DATA } from './constants';
const FindMySerial = () => {
  const { t } = useTranslation();
  const [pageIndex, setPageIndex] = useState(0);
  const swipeTarget = useRef();

  const incrementPage = useCallback(() => {
    const page =
      pageIndex >= FIND_MY_SERIAL_DATA.length - 1 ? 0 : pageIndex + 1;
    setPageIndex(page);
  }, [pageIndex]);

  const decrementPage = useCallback(() => {
    const page = pageIndex <= 0 ? 0 : pageIndex - 1;
    setPageIndex(page);
  }, [pageIndex]);

  useEffect(() => {
    const hammer = new Hammer(swipeTarget?.current);
    hammer.on('swipeleft', () => incrementPage());
    hammer.on('swiperight', () => decrementPage());

    return () => {
      hammer.off('swipeleft', () => incrementPage());
      hammer.off('swiperight', () => decrementPage());
    };
  }, [incrementPage, decrementPage]);

  return (
    <>
      <div>
        <MyEquipmentTitle
          title={t('ADD_EQUIP_FIND_SERIAL')}
          componentType="modal"
        />
      </div>
      <div
        className={`w-100 modal-open position-relative h-auto ${styles['findmyserial__container']}`}
        ref={swipeTarget}
      >
        <div>
          {FIND_MY_SERIAL_DATA.map(item => (
            <FindMySerialItem
              pageIndex={pageIndex}
              item={item}
              key={item.id}
              setPageIndex={setPageIndex}
            >
              {item.description.map((desc, i) => {
                return (
                  <div key={item.id}>
                    <p className="mt-2 fw-normal text-md u-color--DarkGray text-sans-serif mb-3">
                      {t(`${desc?.text}`)}{' '}
                      {desc?.strongText && <b>{t(`${desc?.strongText}`)}</b>}
                    </p>
                  </div>
                );
              })}

              {item.subDescription &&
                item.subDescription.map((subDesc, i) => {
                  return (
                    <div key={i}>
                      <p className="mt-2 fw-normal text-md u-color--DarkGray text-sans-serif mb-3">
                        <b>{t(`${subDesc?.title}`)}</b>{' '}
                        {t(`${subDesc?.description}`)}
                      </p>
                    </div>
                  );
                })}
            </FindMySerialItem>
          ))}
        </div>
      </div>
    </>
  );
};
export default FindMySerial;
