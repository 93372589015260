import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Dropdown, Validator } from 'cat-ecommerce-alloy';
import Field from '@app/components/common/form/Field';
import { replaceTokensInString } from '@app/utils';

const CustomerNumberDropdown = ({
  customerNumbers = [],
  idPrefix = '',
  itemDisplayFormat = ({ label }) => label,
  labelText,
  disabled = false
}) => {
  const [t] = useTranslation();
  const { setFieldValue, setErrors } = useFormikContext();

  const placeholderText = t('CAT_MA_SELECTUCID');
  const placeholder =
    customerNumbers.length === 1
      ? placeholderText
      : { label: placeholderText, value: '' };

  // blank out the values of dependent form fields when the value changes
  const handleOnChange = () => {
    setFieldValue('storeLocation', '');
    setFieldValue('orderType', '');
    setFieldValue('endUseCode', '');
    setErrors({});
  };
  const FIELD_REQUIRED_MSG = t('1626_FIELD_REQUIRED_ERROR');
  const validator = val =>
    new Validator(val)
      .required(replaceTokensInString(FIELD_REQUIRED_MSG, labelText))
      .getError();

  return (
    <Field
      as={Dropdown}
      className={`w-100 p-0`}
      id={`${idPrefix}_customer-number`}
      items={customerNumbers}
      itemToString={itemDisplayFormat}
      keyToString={({ value }) => value}
      label={labelText}
      menuSize="small"
      name="customerNumber"
      onChange={handleOnChange}
      placeholder={placeholder}
      showRequired
      type="dropdown"
      validator={validator}
      data-cs-mask
      disabled={disabled}
    />
  );
};

CustomerNumberDropdown.propTypes = {
  customerNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      default: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  idPrefix: PropTypes.string,
  itemDisplayFormat: PropTypes.func,
  labelText: PropTypes.string
};

export default CustomerNumberDropdown;
