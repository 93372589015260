import PropTypes from 'prop-types';
import { isEmpty } from '@app/utils';
import EditEquipmentDrawer from './EditEquipmentDrawer';
import { Portal } from '@cat-ecom/pcc-components';
import { DRAWERS_ROOT } from '@app/constants/targets';
const Container = ({
  drawerProps,
  currentEquipment,
  onDrawerClose,
  isChild
}) => {
  return !isEmpty(currentEquipment) ? (
    <Portal id={DRAWERS_ROOT}>
      <EditEquipmentDrawer
        drawerProps={drawerProps}
        currentEquipment={currentEquipment}
        onDrawerClose={onDrawerClose}
        isChild={isChild}
      />
    </Portal>
  ) : null;
};

Container.propTypes = {
  drawerProps: PropTypes.object,
  currentEquipment: PropTypes.object,
  onDrawerClose: PropTypes.func,
  isChild: PropTypes.bool
};

export default Container;
