import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { storeHoursPropTypes } from '../../../../declarations.proptypes';
import { PropTypes } from 'prop-types';

const DealerLocationStoreHours = ({ storeHours = [], alwaysOpen = false }) => {
  const [t] = useTranslation();
  return (
    <section className="col">
      <header>
        <h3 className="h6 mb-1 text-start">{t('STORE_HOURS')}:</h3>
      </header>
      <table>
        <tbody>
          {alwaysOpen
            ? t('OPEN_24_HOURS_7_DAYS_A_WEEK')
            : storeHours.map(({ label, value, currentDay }) => {
                const tdClassName = cx('p-0 pe-1 text-start', {
                  'fw-bold': currentDay
                });
                return (
                  <tr
                    key={label}
                    className={'text-md text-sans-serif line-height-1'}
                  >
                    <td className={tdClassName}>{label}</td>
                    <td className={tdClassName}>{value}</td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    </section>
  );
};

export default DealerLocationStoreHours;

DealerLocationStoreHours.propTypes = {
  storeHours: storeHoursPropTypes,
  alwaysOpen: PropTypes.bool
};
