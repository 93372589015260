import { LoginDisclaimer } from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';
import Conditional from '../Conditional/Conditional';
import RegisterButton from './RegisterButton/RegisterButton';
import SignInButton from './SignInButton/SignInButton';
import { PLACEMENT } from './constants';
import PropTypes from 'prop-types';

const LoginAndRegisterButtons = ({
  openDealerLocator,
  closeDrawer,
  hideDisclaimer = false,
  disclaimerPlacement = PLACEMENT.TOP
}) => {
  const { t } = useTranslation();

  const showDisclaimerTop =
    !hideDisclaimer && disclaimerPlacement === PLACEMENT.TOP;
  const showDisclaimerBottom =
    !hideDisclaimer && disclaimerPlacement === PLACEMENT.BOTTOM;

  return (
    <div className="d-flex flex-column mb-4" style={{ gap: 16 }}>
      <Conditional test={showDisclaimerTop}>
        <LoginDisclaimer text={t('SIGN_IN_FEATURES_LABEL')} />
      </Conditional>
      <SignInButton />
      <RegisterButton
        closeDrawer={closeDrawer}
        openDealerLocator={openDealerLocator}
      />
      <Conditional test={showDisclaimerBottom}>
        <LoginDisclaimer text={t('SIGN_IN_FEATURES_LABEL')} />
      </Conditional>
    </div>
  );
};

LoginAndRegisterButtons.propTypes = {
  openDealerLocator: PropTypes.func,
  closeDrawer: PropTypes.func,
  hideDisclaimer: PropTypes.bool,
  disclaimerPlacement: PropTypes.string
};

export default LoginAndRegisterButtons;
