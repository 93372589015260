export const ADD_QO_TO_CART_BEGIN = 'ADD_QO_TO_CART_BEGIN';
export const ADD_QO_TO_CART_SUCCESS = 'ADD_QO_TO_CART_SUCCESS';
export const ADD_QO_TO_CART_FAIL = 'ADD_QO_TO_CART_FAIL';

export const ADD_TO_CART_SET_PHASE = 'ADD_TO_CART_SET_PHASE';
export const ADD_TO_CART_RESET_PHASE = 'ADD_TO_CART_RESET_PHASE';
export const ADD_TO_CART_RESET_PHASE_AND_FLAGS =
  'ADD_TO_CART_RESET_PHASE_AND_FLAGS';
export const SET_IS_ADD_TO_CART_AND_CHECKOUT_FLOW =
  'SET_IS_ADD_TO_CART_AND_CHECKOUT_FLOW';
export const CHECKOUT_GET_CAT_CARD_PROMOS_BEGIN =
  'CHECKOUT_GET_CAT_CARD_PROMOS_BEGIN';
export const CHECKOUT_GET_CAT_CARD_PROMOS_SUCCESS =
  'CHECKOUT_GET_CAT_CARD_PROMOS_SUCCESS';
export const CHECKOUT_GET_CAT_CARD_PROMOS_FAIL =
  'CHECKOUT_GET_CAT_CARD_PROMOS_FAIL';
export const CHECKOUT_SELECT_CAT_CARD_PROMOTION =
  'CHECKOUT_SELECT_CAT_CARD_PROMOTION';
export const CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_BEGIN =
  'CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_BEGIN';
export const CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_SUCCESS =
  'CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_SUCCESS';
export const CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_FAIL =
  'CHECKOUT_GET_CAT_CARD_GLOBAL_ORDER_TOTAL_FAIL';
export const CHECKOUT_CLEAR_PROMOTIONS = 'CHECKOUT_CLEAR_PROMOTIONS';
export const CHECKOUT_APPLY_PROMO_BEGIN = 'CHECKOUT_APPLY_PROMO_BEGIN';
export const CHECKOUT_APPLY_PROMO_FAIL = 'CHECKOUT_APPLY_PROMO_FAIL';
export const CHECKOUT_APPLY_PROMO_SUCCESS = 'CHECKOUT_APPLY_PROMO_SUCCESS';

export const CHECKOUT_LOAD_SUMMARY_BEGIN = 'CHECKOUT_LOAD_SUMMARY_BEGIN';
export const CHECKOUT_LOAD_SUMMARY_FAIL = 'CHECKOUT_LOAD_SUMMARY_FAIL';
export const CHECKOUT_LOAD_SUMMARY_SUCCESS = 'CHECKOUT_LOAD_SUMMARY_SUCCESS';

export const CHECKOUT_LOAD_REQUIRED_FIELDS_BEGIN =
  'CHECKOUT_LOAD_REQUIRED_FIELDS_BEGIN';
export const CHECKOUT_LOAD_REQUIRED_FIELDS_FAIL =
  'CHECKOUT_LOAD_REQUIRED_FIELDS_FAIL';
export const CHECKOUT_LOAD_REQUIRED_FIELDS_SUCCESS =
  'CHECKOUT_LOAD_REQUIRED_FIELDS_SUCCESS';

export const CHECKOUT_LOAD_PICKUP_DELIVERY_BEGIN =
  'CHECKOUT_LOAD_PICKUP_DELIVERY_BEGIN';
export const CHECKOUT_LOAD_PICKUP_DELIVERY_FAIL =
  'CHECKOUT_LOAD_PICKUP_DELIVERY_FAIL';
export const CHECKOUT_LOAD_PICKUP_DELIVERY_SUCCESS =
  'CHECKOUT_LOAD_PICKUP_DELIVERY_SUCCESS';

export const PICKUP_DELIVERY_SAVE_ADDRESS_BEGIN =
  'PICKUP_DELIVERY_SAVE_ADDRESS_BEGIN';
export const PICKUP_DELIVERY_SAVE_ADDRESS_SUCCESS =
  'PICKUP_DELIVERY_SAVE_ADDRESS_SUCCESS';
export const PICKUP_DELIVERY_SAVE_ADDRESS_FAILS =
  'PICKUP_DELIVERY_SAVE_ADDRESS_FAILS';

export const PICKUP_DELIVERY_SUBMIT_BEGIN = 'PICKUP_DELIVERY_SUBMIT_BEGIN';
export const PICKUP_DELIVERY_SUBMIT_SUCCESS = 'PICKUP_DELIVERY_SUBMIT_SUCCESS';
export const PICKUP_DELIVERY_SUBMIT_FAILS = 'PICKUP_DELIVERY_SUBMIT_FAILS';

export const PICKUP_DELIVERY_EXPORT_COMPLIANCE_SUCCESS =
  'PICKUP_DELIVERY_EXPORT_COMPLIANCE_SUCCESS';
export const PICKUP_DELIVERY_EXPORT_COMPLIANCE_FAILS =
  'PICKUP_DELIVERY_EXPORT_COMPLIANCE_FAILS';
export const PICKUP_DELIVERY_EXPORT_RESET = 'PICKUP_DELIVERY_EXPORT_RESET';

export const PICKUP_DELIVERY_REQUEST_BY_DATE_FAILS =
  'PICKUP_DELIVERY_REQUEST_BY_DATE_FAILS';

export const CHECKOUT_CALC_FREIGHT_BEGIN = 'CHECKOUT_CALC_FREIGHT_BEGIN';
export const CHECKOUT_CALC_FREIGHT_CANCEL = 'CHECKOUT_CALC_FREIGHT_CANCEL';
export const CHECKOUT_CALC_FREIGHT_FAIL = 'CHECKOUT_CALC_FREIGHT_FAIL';
export const CHECKOUT_CALC_FREIGHT_SUCCESS = 'CHECKOUT_CALC_FREIGHT_SUCCESS';
export const CHECKOUT_CALC_FREIGHT_RESET = 'CHECKOUT_CALC_FREIGHT_RESET';

export const CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_BEGIN =
  'CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_BEGIN';
export const CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_CANCEL =
  'CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_CANCEL';
export const CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_FAIL =
  'CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_FAIL';
export const CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_SUCCESS =
  'CHECKOUT_MIDTRANS_FINALIZE_TRANSACTION_SUCCESS';

export const CHECKOUT_MIDTRANS_GET_TOKEN_BEGIN =
  'CHECKOUT_MIDTRANS_GET_TOKEN_BEGIN';
export const CHECKOUT_MIDTRANS_GET_TOKEN_FAIL =
  'CHECKOUT_MIDTRANS_GET_TOKEN_FAIL';
export const CHECKOUT_MIDTRANS_GET_TOKEN_SUCCESS =
  'CHECKOUT_MIDTRANS_GET_TOKEN_SUCCESS';

export const CHECKOUT_PLACE_ORDER_BEGIN = 'CHECKOUT_PLACE_ORDER_BEGIN';
export const CHECKOUT_PLACE_ORDER_SUCCESS = 'CHECKOUT_PLACE_ORDER_SUCCESS';
export const CHECKOUT_PLACE_ORDER_FAIL = 'CHECKOUT_PLACE_ORDER_FAIL';
export const CHECKOUT_PLACE_ORDER_END = 'CHECKOUT_PLACE_ORDER_END';

export const CHECKOUT_REMOVE_PROMO_BEGIN = 'CHECKOUT_REMOVE_PROMO_BEGIN';
export const CHECKOUT_REMOVE_PROMO_SUCCESS = 'CHECKOUT_REMOVE_PROMO_SUCCESS';
export const CHECKOUT_REMOVE_PROMO_FAIL = 'CHECKOUT_REMOVE_PROMO_FAIL';

export const CHECKOUT_SAVE_ADDRESS_BEGIN = 'CHECKOUT_SAVE_ADDRESS_BEGIN';
export const CHECKOUT_SAVE_ADDRESS_FAIL = 'CHECKOUT_SAVE_ADDRESS_FAIL';
export const CHECKOUT_SAVE_ADDRESS_SUCCESS = 'CHECKOUT_SAVE_ADDRESS_SUCCESS';

export const CHECKOUT_SAVE_PAYMENT_DETAILS_BEGIN =
  'CHECKOUT_SAVE_PAYMENT_DETAILS_BEGIN';
export const CHECKOUT_SAVE_PAYMENT_DETAILS_FAIL =
  'CHECKOUT_SAVE_PAYMENT_DETAILS_FAIL';
export const CHECKOUT_SAVE_PAYMENT_DETAILS_SUCCESS =
  'CHECKOUT_SAVE_PAYMENT_DETAILS_SUCCESS';

export const CHECKOUT_SAVE_PONUMBER_BEGIN = 'CHECKOUT_SAVE_PONUMBER_BEGIN';
export const CHECKOUT_SAVE_PONUMBER_SUCCESS = 'CHECKOUT_SAVE_PONUMBER_SUCCESS';

export const CHECKOUT_SAVE_PONUMBER_FAIL = 'CHECKOUT_SAVE_PONUMBER_FAIL';
export const CHECKOUT_APPLY_PREPAID_CERTIFICATE_SUCCESS =
  'CHECKOUT_APPLY_PREPAID_CERTIFICATE_SUCCESS';
export const CHECKOUT_VERIFY_ADDRESS_BEGIN = 'CHECKOUT_VERIFY_ADDRESS_BEGIN';
export const CHECKOUT_VERIFY_ADDRESS_SUCCESS =
  'CHECKOUT_VERIFY_ADDRESS_SUCCESS';
export const CHECKOUT_VERIFY_ADDRESS_FAIL = 'CHECKOUT_VERIFY_ADDRESS_FAIL';

export const CHECKOUT_RESET = 'CHECKOUT_RESET';
export const CHECKOUT_LOAD_CART_BEGIN = 'CHECKOUT_LOAD_CART_BEGIN';
export const CHECKOUT_LOAD_CART_SUCCESS = 'CHECKOUT_LOAD_CART_SUCCESS';
export const CHECKOUT_LOAD_CART_FAIL = 'CHECKOUT_LOAD_CART_FAIL';
export const CHECKOUT_SUMMARY_SET_BILLING_ADDRESS =
  'CHECKOUT_SUMMARY_SET_BILLING_ADDRESS';
export const CHECKOUT_SUMMARY_SET_BILLING_METHOD =
  'CHECKOUT_SUMMARY_SET_BILLING_METHOD';
export const CHECKOUT_SUMMARY_SET_ADDITIONAL_BILLING_METHOD =
  'CHECKOUT_SUMMARY_SET_ADDITIONAL_BILLING_METHOD';
export const CHECKOUT_SUMMARY_SET_ALTERNATIVE_PAYMENT =
  'CHECKOUT_SUMMARY_SET_ALTERNATIVE_PAYMENT';
export const GET_DEALER_DCN_CUSTOMER_BEGIN = 'GET_DEALER_DCN_CUSTOMER_BEGIN';
export const GET_DEALER_DCN_CUSTOMER_SUCCESS =
  'GET_DEALER_DCN_CUSTOMER_SUCCESS';
export const GET_DEALER_DCN_CUSTOMER_FAIL = 'GET_DEALER_DCN_CUSTOMER_FAIL';

export const CHECKOUT_SET_ORDER_INFORMATION_BEGIN =
  'CHECKOUT_SET_ORDER_INFORMATION_BEGIN';
export const CHECKOUT_SET_ORDER_INFORMATION_SUCCESS =
  'CHECKOUT_SET_ORDER_INFORMATION_SUCCESS';
export const CHECKOUT_SET_ORDER_INFORMATION_FAIL =
  'CHECKOUT_SET_ORDER_INFORMATION_FAIL';
export const CHECKOUT_SET_ORDER_INFORMATION_IDLE =
  'CHECKOUT_SET_ORDER_INFORMATION_IDLE';
export const CHECKOUT_CART_FORM_SUBMIT_BEGIN =
  'CHECKOUT_CART_FORM_SUBMIT_BEGIN';
export const CHECKOUT_CART_FORM_SUBMIT_SUCCESS =
  'CHECKOUT_CART_FORM_SUBMIT_SUCCESS';
export const CHECKOUT_CART_FORM_SUBMIT_FAIL = 'CHECKOUT_CART_FORM_SUBMIT_FAIL';
export const CHECKOUT_CART_FORM_SUBMIT_IDLE = 'CHECKOUT_CART_FORM_SUBMIT_IDLE';
export const CHECKOUT_SIS_TO_CART_FAIL = 'CHECKOUT_SIS_TO_CART_FAIL';
export const CHECKOUT_APPLY_PREPAID_CERTIFICATE_BEGIN =
  'CHECKOUT_APPLY_PREPAID_CERTIFICATE_BEGIN';

export const CHECKOUT_APPLY_PREPAID_CERTIFICATE_FAIL =
  'CHECKOUT_APPLY_PREPAID_CERTIFICATE_FAIL';

export const CHECKOUT_DELETE_PREPAID_CERTIFICATE_BEGIN =
  'CHECKOUT_DELETE_PREPAID_CERTIFICATE_BEGIN';
export const CHECKOUT_DELETE_PREPAID_CERTIFICATE_SUCCESS =
  'CHECKOUT_DELETE_PREPAID_CERTIFICATE_SUCCESS';
export const CHECKOUT_DELETE_PREPAID_CERTIFICATE_FAIL =
  'CHECKOUT_DELETE_PREPAID_CERTIFICATE_FAIL';
export const GET_SOS_DETAILS_BEGIN = 'GET_SOS_DETAILS_BEGIN';
export const GET_SOS_DETAILS_SUCCESS = 'GET_SOS_DETAILS_SUCCESS';
export const GET_SOS_DETAILS_FAIL = 'GET_SOS_DETAILS_FAIL';
export const ADD_TO_CART_MODIFICATIONS_RESET =
  'ADD_TO_CART_MODIFICATIONS_RESET';

export const GET_DEALER_PRICE_AND_AVAILABILITY_BEGIN =
  'GET_DEALER_PRICE_AND_AVAILABILITY_BEGIN';
export const GET_DEALER_PRICE_AND_AVAILABILITY_SUCCESS =
  'GET_DEALER_PRICE_AND_AVAILABILITY_SUCCESS';
export const GET_DEALER_PRICE_AND_AVAILABILITY_FAIL =
  'GET_DEALER_PRICE_AND_AVAILABILITY_FAIL';
export const GET_DEALER_PRICE_AND_AVAILABILITY_RESET =
  'GET_DEALER_PRICE_AND_AVAILABILITY_RESET';

export const CHECKOUT_ADD_TO_CART_BEGIN = 'CHECKOUT_ADD_TO_CART_BEGIN';
export const CHECKOUT_ADD_TO_CART_SUCCESS = 'CHECKOUT_ADD_TO_CART_SUCCESS';
export const CHECKOUT_ADD_TO_CART_FAIL = 'CHECKOUT_ADD_TO_CART_FAIL';

export const CHECKOUT_CAT_CREDIT_TOOLTIP = 'CHECKOUT_CAT_CREDIT_TOOLTIP';

export const UPDATE_PARTS = 'UPDATE_PARTS';
export const QUICK_ORDER_CHECKOUT = 'QUICK_ORDER_CHECKOUT';
export const QUANTITY_ERROR_MSG = '_ERR_THRESHOLD_SHOPPING_CART_QUANTITY';
export const PIX_PAYMENT_STATUS_BEGIN = 'PIX_PAYMENT_STATUS_BEGIN';
export const PIX_PAYMENT_STATUS_SUCCESS = 'PIX_PAYMENT_STATUS_SUCCESS';
export const PIX_PAYMENT_STATUS_FAIL = 'PIX_PAYMENT_STATUS_FAIL';

export const RESET_CURRENT_MODAL_PHASE = 'RESET_CURRENT_MODAL_PHASE';
export const SET_CURRENT_MODAL_PHASE = 'SET_CURRENT_MODAL_PHASE';

export const BUILD_SHARE_CART_LINK_BEGIN = 'BUILD_SHARE_CART_LINK_BEGIN';
export const BUILD_SHARE_CART_LINK_SUCCESS = 'BUILD_SHARE_CART_LINK_SUCCESS';
export const BUILD_SHARE_CART_LINK_FAIL = 'BUILD_SHARE_CART_LINK_FAIL';

export const CHECK_SALESFORCE_ACCOUNT_BALANCE_BEGIN =
  'CHECK_SALESFORCE_ACCOUNT_BALANCE_BEGIN';
export const CHECK_SALESFORCE_ACCOUNT_BALANCE_SUCCESS =
  'CHECK_SALESFORCE_ACCOUNT_BALANCE_SUCCESS';
export const CHECK_SALESFORCE_ACCOUNT_BALANCE_FAIL =
  'CHECK_SALESFORCE_ACCOUNT_BALANCE_FAIL';
export const CHECK_SALESFORCE_ACCOUNT_BALANCE_CLEAR =
  'CHECK_SALESFORCE_ACCOUNT_BALANCE_CLEAR';

export const CAT_CREDITS_DELETE_ACCOUNT_BEGIN =
  'CAT_CREDITS_DELETE_ACCOUNT_BEGIN';
export const CAT_CREDITS_DELETE_ACCOUNT_SUCCESS =
  'CAT_CREDITS_DELETE_ACCOUNT_SUCCESS';
export const CAT_CREDITS_DELETE_ACCOUNT_FAIL =
  'CAT_CREDITS_DELETE_ACCOUNT_FAIL';

export const CHECKOUT_APPLY_CAT_CREDITS_BEGIN =
  'CHECKOUT_APPLY_CAT_CREDITS_BEGIN';
export const CHECKOUT_APPLY_CAT_CREDITS_SUCCESS =
  'CHECKOUT_APPLY_CAT_CREDITS_SUCCESS';
export const CHECKOUT_APPLY_CAT_CREDITS_FAIL =
  'CHECKOUT_APPLY_CAT_CREDITS_FAIL';
export const CHECKOUT_APPLY_CAT_CREDITS_PLACE_ORDER =
  'CHECKOUT_APPLY_CAT_CREDITS_PLACE_ORDER';

export const SAVE_CAT_CREDITS_ACCOUNT_GLOBAL_EXPANSION_CHECKBOX =
  'SAVE_CAT_CREDITS_ACCOUNT_GLOBAL_EXPANSION_CHECKBOX';
export const SHARE_CONFIRMATION_EMAIL_BEGIN = 'SHARE_CONFIRMATION_EMAIL_BEGIN';
export const SHARE_CONFIRMATION_EMAIL_SUCCESS =
  'SHARE_CONFIRMATION_EMAIL_SUCCESS';
export const SHARE_CONFIRMATION_EMAIL_FAIL = 'SHARE_CONFIRMATION_EMAIL_FAIL';
export const SHARE_CONFIRMATION_EMAIL_IDLE = 'SHARE_CONFIRMATION_EMAIL_IDLE';

export const DATE_UPDATED = 'dateUpdated';
export const INITIAL_RENDER = 'initialRender';
export const DEFAULT_QUERY_PARAMETER =
  'orderId={0}&storeId={1}&locale={2}&poNumber={3}&isSkipPOAttachmentValidation={4}';
export const QUERY_PARAMETER_WITH_FILE_NAME =
  'orderId={0}&storeId={1}&locale={2}&poNumber={3}&fileName={4}&isSkipPOAttachmentValidation={5}';
export const APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_FAIL =
  'APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_FAIL';
export const APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_BEGIN =
  'APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_BEGIN';
export const APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_SUCCESS =
  'APPLY_CAT_VANTAGE_REWARDS_TO_ORDER_SUCCESS';
export const INITIAL_CART_ITEMS = 'initialCartItems';

export const SHOPPING_CART = 'ShoppingCart';
export const IMPORT_MODAL_VISIBLE_CHECKOUT = 'importModalCheckout';

export const RESET_ADDITIONAL_BILLING_METHOD =
  'RESET_ADDITIONAL_BILLING_METHOD';
