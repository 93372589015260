export const ORGS_AND_USERS_GET_BEGIN = 'ORGS_AND_USERS_GET_BEGIN';
export const ORGS_AND_USERS_GET_SUCCESS = 'ORGS_AND_USERS_GET_SUCCESS';
export const ORGS_AND_USERS_GET_FAIL = 'ORGS_AND_USERS_GET_FAIL';

export const USERS_GET_BEGIN = 'USERS_GET_BEGIN';
export const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS';
export const USERS_GET_FAIL = 'USERS_GET_FAIL';

export const ORGANIZATIONS_USERS_INFO_BEGIN = 'ORGANIZATIONS_USERS_INFO_BEGIN';
export const ORGANIZATIONS_USERS_INFO_SUCCESS =
  'ORGANIZATIONS_USERS_INFO_SUCCESS';
export const ORGANIZATIONS_USERS_INFO_FAIL = 'ORGANIZATIONS_USERS_INFO_FAIL';

export const ORGANIZATIONS_USERS_SAVE_DETAILS_BEGIN =
  'ORGANIZATIONS_USERS_SAVE_DETAILS_BEGIN';
export const ORGANIZATIONS_USERS_SAVE_DETAILS_SUCCESS =
  'ORGANIZATIONS_USERS_SAVE_DETAILS_SUCCESS';
export const ORGANIZATIONS_USERS_SAVE_DETAILS_FAIL =
  'ORGANIZATIONS_USERS_SAVE_DETAILS_FAIL';

export const ORGANIZATIONS_USERS_ROLES_BEGIN =
  'ORGANIZATIONS_USERS_ROLES_BEGIN';

export const ORGANIZATIONS_USERS_ROLES_SUCCESS =
  'ORGANIZATIONS_USERS_ROLES_SUCCESS';

export const ORGANIZATIONS_USERS_ROLES_FAIL = 'ORGANIZATIONS_USERS_ROLES_FAIL';

export const ORGANIZATIONS_USERS_ROL_ASSIGN_BEGIN =
  'ORGANIZATIONS_USERS_ROL_ASSIGN_BEGIN';

export const ORGANIZATIONS_USERS_ROL_ASSIGN_SUCCESS =
  'ORGANIZATIONS_USERS_ROL_ASSIGN_SUCCESS';

export const ORGANIZATIONS_USERS_ROL_ASSIGN_FAIL =
  'ORGANIZATIONS_USERS_ROL_ASSIGN_FAIL';

export const ORGANIZATION_USERS_EDIT_SET_FUTURE_DCN =
  'ORGANIZATION_USERS_EDIT_SET_FUTURE_DCN';

export const ORGANIZATION_USERS_RESET_SET_FUTURE_DCN =
  'ORGANIZATION_USERS_EDIT_SET_FUTURE_DCN';

export const USER_APPROVAL_AMOUNT_BEGIN = 'USER_APPROVAL_AMOUNT_BEGIN';
export const USER_APPROVAL_AMOUNT_SUCCESS = 'USER_APPROVAL_AMOUNT_SUCCESS';
export const USER_APPROVAL_AMOUNT_FAIL = 'USER_APPROVAL_AMOUNT_FAIL';

export const RESET_USER_DETAILS_STATUS = 'RESET_USER_DETAILS_STATUS';
export const RESET_USER_DETAILS_APPROVAL_AMOUNT_STATUS =
  'RESET_USER_DETAILS_APPROVAL_AMOUNT_STATUS';

export const SEARCH_FORM_SET = 'SEARCH_FORM_SET';

export const SET_USER_APPROVAL_AMOUNT_BEGIN = 'SET_USER_APPROVAL_AMOUNT_BEGIN';
export const SET_USER_APPROVAL_AMOUNT_SUCCESS =
  'SET_USER_APPROVAL_AMOUNT_SUCCESS';
export const SET_USER_APPROVAL_AMOUNT_FAIL = 'SET_USER_APPROVAL_AMOUNT_FAIL';

export const USERS_STATUS_CHANGE_BEGIN = 'USERS_STATUS_CHANGE_BEGIN';
export const USERS_STATUS_CHANGE_SUCCESS = 'USERS_STATUS_CHANGE_SUCCESS';
export const USERS_STATUS_CHANGE_FAIL = 'USERS_STATUS_CHANGE_FAIL';

export const GET_USER_DETAILS_BEGIN = 'GET_USER_DETAILS_BEGIN';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAIL = 'GET_USER_DETAILS_FAIL';
