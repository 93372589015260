import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { isLineItemNotesAvailable } from '../CartItemsTable/utils';
import styles from './cellStyles.module.scss';

const CUSTOMER_SERIAL_NUMBER_ID = 'customerSerialNumber';

const NotesCell = ({ item }) => {
  const {
    orderItemExtendAttribute: { lineItemNotes = {} },
    orderItemId
  } = item;
  const [t] = useTranslation();
  const schema = [
    {
      label: t('PRINT_ASSET_SN'),
      id: 'customerSerialNumber'
    },
    {
      label: t('CAT_ORDER_LINE_ITEM_NO_PLACEHOLDER'),
      id: 'lineItemNo'
    },
    {
      label: t('PRINT_CUST_NO'),
      id: 'customerPartNumber'
    },
    {
      label: t('PRINT_ITEM_NO'),
      id: 'partNote'
    }
  ];
  return (
    isLineItemNotesAvailable(item) && (
      <div className={`${styles['order-items-notes__wrapper']}`}>
        {schema
          .filter(item => lineItemNotes[item.id])
          .map(val => (
            <div
              className={cx('u-color--Black text-sans-serif', {
                [`${styles['order-items-notes__no-text-wrap']}`]:
                  val.id === CUSTOMER_SERIAL_NUMBER_ID
              })}
              key={`${val.id}-${orderItemId}`}
            >
              <span
                className={`m-0 fw-bold text-capitalize ${styles['order-items-notes__notes-label']}`}
              >
                {val.label}
              </span>
              <span className={`${styles['order-items-notes__notes-label']}`}>
                {` ${lineItemNotes[val.id]}`}
              </span>
            </div>
          ))}
      </div>
    )
  );
};

NotesCell.propTypes = {
  item: PropTypes.shape({
    orderItemExtendAttribute: PropTypes.shape({
      lineItemNotes: PropTypes.shape({
        customerSerialNumber: PropTypes.string,
        customerPartNumber: PropTypes.string,
        lineItemNo: PropTypes.string,
        partNote: PropTypes.string
      })
    }),
    orderItemId: PropTypes.string
  })
};

export default NotesCell;
