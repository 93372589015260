import Conditional from '@app/components/common/Conditional';
import { isPDP } from '../../../../utils';
import PDPSkeletonLoader from '../../pdp/PDPSkeletonLoader';

const ProductSkeletonLoader = () => {
  return (
    <>
      <Conditional test={isPDP()}>
        <PDPSkeletonLoader />
      </Conditional>
    </>
  );
};
export default ProductSkeletonLoader;
