import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useQueryParamRoute } from '@app/hooks';
import { MY_ACCOUNT_SEARCH } from '@app/constants/routes';
import { STATUS } from '@app/constants/commonConstants';

const useControlCenterChecker = () => {
  const catalogId = useSelector(state => state.common.catalogId);
  const hasControlCenter = useSelector(
    s => s.featureFlag.PCC_controlCenterFlag
  );
  const langId = useSelector(state => state.common.langId);
  const storeId = useSelector(state => state.common.storeId);
  const getCommonVarsStatus = useSelector(
    state => state.common.getCommonVarsStatus
  );
  const userAffiliation = useSelector(state => state.common.userAffiliation);
  const isCSPCustomer = useSelector(state => state.common.isCSPCustomer);
  const isCatCSR = useSelector(s => s.common?.isCatCSR);
  const allowedUsers = useSelector(
    state =>
      state.storeConfiguration.CONTROL_CENTER_ALLOWED_USER_AFFILIATIONS
        ?.storeConfValue
  );
  const getStoreConfigurationStatus = useSelector(
    state => state.storeConfiguration.storeConfigurationStatus
  );
  const isStoreConfigurationResolved =
    getStoreConfigurationStatus === STATUS.RESOLVED;
  const isControlCenterEnabled = useMemo(() => {
    if (isStoreConfigurationResolved) {
      const allowedUserAffiliations = allowedUsers?.split(',');
      const hasAllowedUser = allowedUserAffiliations?.includes(userAffiliation);
      const isControlCenterVisible =
        hasControlCenter && isCSPCustomer && hasAllowedUser && !isCatCSR;
      return isControlCenterVisible;
    }
  }, [
    hasControlCenter,
    userAffiliation,
    isCSPCustomer,
    allowedUsers,
    isStoreConfigurationResolved,
    isCatCSR
  ]);

  const setRoute = useQueryParamRoute(useHistory())[1];
  const isCommonServiceResolved = getCommonVarsStatus === STATUS.RESOLVED;
  const isCommonServiceLoading = getCommonVarsStatus === STATUS.PENDING;

  const checkControlCenterPermission = useCallback(() => {
    if (isCommonServiceResolved && !isControlCenterEnabled) {
      setRoute(null, MY_ACCOUNT_SEARCH.MY_ACCOUNT_SUMMARY, {
        catalogId,
        langId,
        storeId
      });
    }
  }, [isCommonServiceResolved, isControlCenterEnabled]);

  return {
    isControlCenterEnabled,
    isCommonServiceLoading,
    checkControlCenterPermission
  };
};

export default useControlCenterChecker;
