export const HEADER_ACTIONS_ICON_SIZE = 16;
export const SELECTALL_DEALER = 'selectAllDealer';
export const OFFER_DETAILS_URL =
  'https://www.cat.com/en_US/promotions/finance/cat-card-offers.html';
export const CATFI_NO_THANKS = 'NO_THANKS_PROMO_OPTION';
export const TOP_ESPOT_NAME = 'SUMMARY_PAYMENT_PINSTRIPE_ESPOT';
export const ORDERED_BY_MODAL_TYPE = 'orderedBy';
export const REQUESTED_SHIP_DATE_MODAL_TYPE = 'requestedShipDate';
export const RBD_PARTS_AVAILABILITY_ESPOT = 'RBD_PARTS_AVAILABILITY_ESPOT';
export const CONTACT_DEALER = '[CONTACT_DEALER]';
export const RBD_UPDATE = 'RBDUpdate';
export const PCC_ESPOT_DEALER_TERMS_AND_CONDITIONS_SNP =
  'PCC_ESPOT_DEALER_TERMS_AND_CONDITIONS_SNP';
