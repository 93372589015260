import { STATUS } from '../../constants/commonConstants';
import * as types from './constants';
import { schematicsInitialStore } from './initialStore';

const schematicsReducer = (state = schematicsInitialStore, action = {}) => {
  switch (action.type) {
    case types.SCHEMATICS_GET_BEGIN: {
      return {
        ...state,
        getSchematicsStatus: STATUS.PENDING,
        getSchematicsError: undefined
      };
    }
    case types.SCHEMATICS_GET_FAIL: {
      const { payload } = action;
      return {
        ...state,
        getSchematicsStatus: STATUS.REJECTED,
        getSchematicsError: payload
      };
    }
    case types.SCHEMATICS_GET_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        getSchematicsStatus: STATUS.RESOLVED
      };
    }

    default: {
      return state;
    }
  }
};

export { schematicsInitialStore };
export default schematicsReducer;
