import styles from './SearchBannerWrapper.module.scss';
import cx from 'classnames';

const SearchBannerWrapper = () => {
  return (
    <div
      className={cx(['mx-auto', styles['search-banner--width']])}
      id="search-bar-banner-homepage"
      data-testid="search--banner-homepage"
    />
  );
};

export default SearchBannerWrapper;
