import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import {
  ModalContext,
  Suggestion,
  Suggestions,
  Highlighted
} from 'cat-ecommerce-alloy';
import { replaceTokensInString } from '@app/utils';
import SaveEquipment from './SaveEquipment';
import { useTracking } from 'react-tracking';
import { getFormSubmitEvent } from '../../../../../common/analytics/analyticsUtils';
import styles from '../../../MyEquipment.module.scss';
import { EQUIPMENT_RESULTS_LIMIT } from '../MoreSuggestions/constants';

const EquipmentSuggestions = ({
  results = [],
  limit,
  highlight,
  resetState,
  formikProps,
  skipGAEvent,
  widgetIdentifier,
  otherResults = [],
  showRefiningText
}) => {
  const highlightClassName =
    'text-sans-serif text-md u-color--DarkGray fw-bold';
  const highlightComponentClass = 'text-sans-serif text-md u-color--DarkGray';

  const { t } = useTranslation();
  const item =
    limit > results.length + otherResults.length
      ? results.length + otherResults.length
      : limit;
  const modalCtx = useContext(ModalContext);
  const { trackEvent } = useTracking();
  const [highlightText, setHighlightText] = useState('');
  const getSuggestionText = r =>
    r.equipmentFamily ? r.model + r.equipmentFamily : r.model;
  const openSaveEquipment = currentEquipment => e => {
    e.preventDefault();
    !skipGAEvent &&
      trackEvent(
        getFormSubmitEvent({
          formName: 'Add Equipment',
          formContent: highlight,
          formContent2: getSuggestionText(currentEquipment),
          formStatus: 'success',
          pagePath: document.location.pathname,
          formLocation: widgetIdentifier
        })
      );
    modalCtx.hideOverflow();
    modalCtx.addModal(
      <SaveEquipment
        modalTitle={t('SAVE_MY_EQUIPMENT')}
        currentEquipment={currentEquipment}
        serialNumber={highlight}
        widgetIdentifier={widgetIdentifier}
      />,
      t('SAVE_MY_EQUIPMENT')
    );
    setTimeout(() => {
      resetState(formikProps.setFieldValue);
    }, 1000);

    return false;
  };

  /**
   *
   * @param {string} term Is the user input
   * @returns A string with all the possible combinations that can be highlighted
   *
   */
  const getHighlightText = term => {
    const results = {};
    term.split('').forEach((_, index) => {
      for (let j = 0; j < index; j++) {
        const sub = term
          .substring(j, index + 1)
          .replace(/[.*+\-?^${}()|[\]\\\s/]/g, '');
        results[sub] = sub;
      }
    });
    const termsArray = Object.values(results)
      .filter(n => n.length > 1)
      .sort((a, b) => b.length - a.length);
    return termsArray.join('|');
  };

  useEffect(() => {
    highlight && setHighlightText(getHighlightText(highlight));
  }, [highlight]);

  const getSuggestionOptions = (list, listlimit) => {
    return list.map(
      (suggestion, i) =>
        i < listlimit && (
          <Suggestion
            key={suggestion.productId}
            onClick={openSaveEquipment(suggestion)}
            className="border-start-0 border-end-0 m-0"
          >
            <span className="m-0 text-sans-serif text-md u-color--DarkGray">
              <img src={suggestion.imageURL} alt="" data-testid="machine" />
            </span>
            <span
              className={`${styles['my-equipment__box-width']} text-truncate d-inline-block text-start m-0 text-sans-serif text-md u-color--DarkGray`}
            >
              <span className="m-0 text-sans-serif text-md u-color--DarkGray">
                <Highlighted
                  bypassEscape
                  text={getSuggestionText(suggestion)}
                  highlight={highlightText}
                  highlightClassName={highlightClassName}
                  className={highlightComponentClass}
                />
                <span className="d-block m-0 text-sans-serif text-md u-color--DarkGray">
                  {suggestion?.serialNumber && (
                    <Highlighted
                      bypassEscape
                      text={`(${suggestion.serialNumber} ${t('PREFIX_LABEL')})`}
                      highlight={highlightText}
                      highlightClassName={highlightClassName}
                      className={highlightComponentClass}
                    />
                  )}
                </span>
              </span>
            </span>
          </Suggestion>
        )
    );
  };
  const equipmentOptions = getSuggestionOptions(results, limit);

  const otherEquipmentoptions =
    equipmentOptions.length < limit
      ? getSuggestionOptions(otherResults, limit - equipmentOptions.length)
      : [];

  return (
    <div
      className={cx(`h-auto ${styles['add-equipment__search-suggest']}`, {
        [styles['my-equipment__box-width']]: limit === 8,
        [`mt-3 border-0 shadow-none ${styles['add-equipment__moreSuggestions']}`]:
          limit !== 8
      })}
    >
      {limit > 8 && (
        <span
          className={`m-0 text-univers text-lg ${styles['add-equipment__show-results']}`}
        >
          {replaceTokensInString(
            t('SHOWING'),
            item,
            results.length + otherResults.length || '0'
          )}
        </span>
      )}
      <Suggestions>
        {equipmentOptions.length > 0 ? (
          equipmentOptions
        ) : (
          <span className="u-color--DarkGray p-2 m-0 text-sm">
            {t('MEQ_NO_EXACT_MATCH')}
          </span>
        )}
        {otherEquipmentoptions.length > 0 && (
          <>
            <li className="p-2 bg--ExtraLightGray text-sm u-color--DarkGray m-0">
              {t('MEQ_OTHER_SUGGESTIONS')}
            </li>
            {otherEquipmentoptions}
          </>
        )}
        {results.length + otherResults.length >= EQUIPMENT_RESULTS_LIMIT &&
          showRefiningText && (
            <p
              className={`py-1 ps-2 text-xl u-color--DarkGray fw-bold ${styles['add-equipment__other-suggestion-refining']}`}
            >
              {t('ADD_EQUIP_TRY_REFINING')}
            </p>
          )}
      </Suggestions>
    </div>
  );
};

export default EquipmentSuggestions;

EquipmentSuggestions.propTypes = {
  limit: PropTypes.number.isRequired,
  resetState: PropTypes.func.isRequired,
  formikProps: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired
  }).isRequired,
  highlight: PropTypes.string,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      equipmentFamily: PropTypes.string,
      imageURL: PropTypes.string,
      model: PropTypes.string,
      partNumber: PropTypes.string,
      productId: PropTypes.string
    })
  ),
  otherResults: PropTypes.arrayOf(
    PropTypes.shape({
      equipmentFamily: PropTypes.string,
      imageURL: PropTypes.string,
      model: PropTypes.string,
      partNumber: PropTypes.string,
      productId: PropTypes.string
    })
  ),
  skipGAEvent: PropTypes.bool,
  widgetIdentifier: PropTypes.string,
  showRefiningText: PropTypes.bool
};
