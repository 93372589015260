import { forwardRef } from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';

const DEFAULT_ELEMENT_TYPE = 'div';

const PurifiedHtml = forwardRef(
  (
    {
      as: Component = DEFAULT_ELEMENT_TYPE,
      /** Allows elements like style, script or others to be injected. For more info see https://github.com/cure53/DOMPurify */
      forceBody = false,
      htmlString = '',
      ...props
    },
    ref
  ) => (
    <Component
      {...props}
      ref={ref}
      dangerouslySetInnerHTML={{
        // DOMPurify removes iframe tags when placing the html so in order to prevet that we have added ADD_TAGS: ["iframe"]
        // which will allow iframe tags
        // which will allow target attributes
        __html: DOMPurify.sanitize(htmlString, {
          ADD_TAGS: ['iframe'],
          ADD_ATTR: ['target'],
          FORCE_BODY: forceBody,
          CUSTOM_ELEMENT_HANDLING: {
            tagNameCheck: /^cat-/, // allow all tags starting with "cat-"
            attributeNameCheck: /^/,
            allowCustomizedBuiltInElements: true // customized built-ins are allowed
          }
        })
      }}
    />
  )
);

PurifiedHtml.displayName = 'PurifiedHtml';
PurifiedHtml.propTypes = {
  as: PropTypes.string,
  forceBody: PropTypes.bool,
  htmlString: PropTypes.string.isRequired
};

export default PurifiedHtml;
