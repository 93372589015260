import { v4 } from 'uuid';
import { GENERAL } from '@app/constants/cookieContants';

export const MATCH_ROUTE_DEEPLINK = 'deeplink';
export const TABLE_ITEM_PADDING = '10px 0px 10px 0px';
export const TABLE_ITEM_WIDTH = '33.33%';
export const SUCCESS = 'success';
export const CILG = 'PCC CILG';
export const DEFAULT_ROW_VALUES = {
  quantity: '',
  itemNumber: '',
  assetId: '',
  lineItemNote: '',
  customerPartNumber: '',
  customerItemNumber: '',
  page: GENERAL
};
export const FORMIK_INITAL_VALUES = {
  requestByDate: '',
  items: [
    {
      ...DEFAULT_ROW_VALUES,
      id: v4()
    },
    {
      ...DEFAULT_ROW_VALUES,
      id: v4()
    }
  ],
  equipment: '',
  displayOnScreen: true,
  displayOnPrint: true
};
export const CART_ESPOT_PINSTRIPE = 'CAT_CART_ESPOT_PINSTRIPE_NEW';
export const CART_ESPOT_TOP = 'SHOPPING_PREFERENCES_CART_PAGE_TOP_ESPOT';
export const CART_ESPOT_BOTTOM = 'SHOPPING_PREFERENCES_CART_PAGE_BOTTOM_ESPOT';
export const CART_ESPOT_IE = 'CATSAS_IE_ALERT_ESPOT_SHOPPING_CART';

export const QUICK_ORDER_MODAL_ID = 'SHOPPING_CART_QUICK_ORDER_MODAL';
export const ADD_TO_CART_PHASES = {
  CALLING_SOS_SERVICE: 'CALLING_SOS_SERVICE',
  SOS_MODAL: 'SOS_MODAL',
  CALLING_PA_SERVICE: 'CALLING_PA_SERVICE',
  REPLACEMENT_MODAL: 'REPLACEMENT_MODAL',
  ALTERNATE_MODAL: 'ALTERNATE_MODAL',
  ADD_PARTS_TO_CART: 'ADD_PARTS_TO_CART'
};

export const DEFAULT_SHOPPING_CART_MAX_ITEMS = 180;
export const EQUIPMENT_VALUE = 'equipmentValue';
export const REQUEST_BY_DATE_VALUE = 'requestByDate';

export const ALTERNATIVE = 'alternative';
export const REPLACEMENT = 'replacement';

export const SIS = 'SIS';
export const QUICK_ORDER = 'Quick Order';

export const CHECKOUT_INITIAL_TIME = 'proceedToCheckoutInitialTime';

export const SESSION_STORAGE_CSR_SHARE_CART_REGISTERED_USERS_KEY =
  'CSR_SHARE_CART';
