import { db } from './db';
import { catchDexieErrors, getExpireTime, getStoreInfoKey } from './utils';

export const clearDealerStoreInfo = () =>
  catchDexieErrors(
    db.dealerStoreInfo.where('expireTime').below(new Date().getTime()).delete()
  );

/**
 * @param {string} storeId - string that identifies the store.
 * @param {string} selectedStore - string that identifies the selected Store.
 * @param {string} dcn - string that identifies selectedCustomerNumber.
 * @param {string} endUseCode - string that identifies selectedEndUseCode.
 * @param {string} orderType - string that identifies selectedOrderType.
 */
export async function getDealerStoreInfo({
  userId,
  langId,
  storeId,
  selectedStore,
  dcn,
  endUseCode,
  orderType
}) {
  await clearDealerStoreInfo();

  const storeInfoKey = getStoreInfoKey({
    storeId,
    selectedStore,
    dcn,
    endUseCode,
    orderType
  });

  const key = `${userId}_${langId}_${storeInfoKey}`;

  const dealerStoreInfo = await catchDexieErrors(db.dealerStoreInfo.get(key));

  return dealerStoreInfo?.data ?? {};
}
/**
 * @param {string} storeId - string that identifies the store.
 * @param {string} selectedStore - string that identifies the selected Store.
 * @param {string} dcn - string that identifies selectedCustomerNumber.
 * @param {string} endUseCode - string that identifies selectedEndUseCode.
 * @param {string} orderType - string that identifies selectedOrderType.
 * @param {Object} storeInfo - the data about dealer store info that is received.
 */
export async function setDealerStoreInfo({
  userId,
  langId,
  storeId,
  selectedStore,
  dcn,
  endUseCode,
  orderType,
  storeInfo
}) {
  const expireTime = getExpireTime();
  const storeInfoKey = getStoreInfoKey({
    storeId,
    selectedStore,
    dcn,
    endUseCode,
    orderType
  });

  const key = `${userId}_${langId}_${storeInfoKey}`;

  await catchDexieErrors(
    db.dealerStoreInfo.put({
      key: key,
      expireTime: expireTime,
      data: storeInfo
    })
  );
}
