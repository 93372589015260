import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { replaceTokensInString } from '../../../../../../../utils';
import styles from './cellStyles.module.scss';

const PackageQuantityCell = ({ item }) => {
  const { packageError } = item;
  const [t] = useTranslation();

  return (
    <div className="d-flex">
      <span
        className={`m-0 mt-2 mt-md-0 ${styles['order__items-package-quantity']}`}
        data-testid="package-qty-column"
      >
        {replaceTokensInString(
          t('PACKAGE_MULTIPLIER'),
          packageError?.packageQuantity
        )}
      </span>
    </div>
  );
};

PackageQuantityCell.propTypes = {
  item: PropTypes.shape({
    packageError: PropTypes.shape({
      packageQuantity: PropTypes.number
    })
  })
};

export default PackageQuantityCell;
