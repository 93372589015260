import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Availability from './Availability';
import ModernAvailability from './Common';
import { availability as availabilityPropTypes } from './Availability.proptypes';
import { sourcingDetailsPropTypes } from './AvailabilityBySourceContent/AvailabilityBySourceContent.proptypes';

const CommonAvailability = props => {
  const isStoreAvailabilityEnabled = useSelector(
    state => state.dealer?.isStoreAvailabilityEnabled
  );

  return (
    <>
      {isStoreAvailabilityEnabled ? (
        <ModernAvailability {...props} />
      ) : (
        <Availability {...props} />
      )}
    </>
  );
};

CommonAvailability.propTypes = {
  className: PropTypes.string,
  catEntryId: PropTypes.string,
  hideLabel: PropTypes.bool,
  isLoading: PropTypes.bool,
  availabilityInformation: availabilityPropTypes,
  sourcingDetailsInformation: sourcingDetailsPropTypes
};

export default CommonAvailability;
