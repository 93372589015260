import { STATUS } from '@app/constants/commonConstants';
import * as types from './constants';
import { featureFlagInitialStore } from './initialStore';

const featureFlagReducer = (state = featureFlagInitialStore, action) => {
  switch (action.type) {
    case types.GET_FEATURE_FLAG_BEGIN: {
      return {
        ...state,
        featureFlagStatus: STATUS.PENDING
      };
    }
    case types.GET_FEATURE_FLAG_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        featureFlagStatus: STATUS.RESOLVED
      };
    }
    case types.GET_FEATURE_FLAG_FAIL: {
      return {
        ...state,
        featureFlagStatus: STATUS.REJECTED
      };
    }
    default: {
      return state;
    }
  }
};

export { featureFlagInitialStore };
export default featureFlagReducer;
