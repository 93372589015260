import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TIMEOUT_DEFAULT } from '../constants/commonConstants';

/**
 * Custom hook that will attempt to load a JavaScript file.
 *
 * @param {Object}  props - settings for the hook
 * @param {func}    props.getter - An optional callback function that will returns the script namespace
 * @param {Object}  props.scriptAttributes - an object with keys that should be added as an attribute on a script tag
 *  and values that should be the value for those keys
 * @param {number}  props.timeout - An integer used to define a timeout for the script to load (in ms). Defaults to 5000ms
 * @param {url}     props.url - The url of a javascript file
 * @returns {}
 */
const useScript = ({
  getter,
  scriptAttributes = {},
  timeout = TIMEOUT_DEFAULT,
  url
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    const handleSuccess = () => {
      setError(false);
      setIsLoaded(true);
    };

    const handleError = () => {
      setError(true);
      setIsLoaded(true);
    };

    const getScriptNamespace = (timeoutOccurred, timerId) => {
      if (timeoutOccurred) {
        return;
      }
      // If a getter for the js is provided, then check if it exists
      // If it's not provided, then assume it is loaded
      if (getter) {
        const ns = getter();
        if (ns) {
          handleSuccess();
        } else {
          handleError();
        }
      } else {
        handleSuccess();
      }

      clearTimeout(timerId);
    };

    const attachScript = (timeoutOccurred, timerId) => {
      let head = document.head;
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;

      if (scriptAttributes && typeof scriptAttributes === 'object') {
        Object.entries(scriptAttributes).forEach(([key, val]) =>
          script.setAttribute(key, val)
        );
      }

      script.onreadystatechange = () =>
        getScriptNamespace(timeoutOccurred, timerId);
      script.onload = () => getScriptNamespace(timeoutOccurred, timerId);
      script.onerror = () => handleError(timeoutOccurred, timerId);

      // Fire the loading
      head.appendChild(script);
    };

    if (url) {
      const isExistingScript = document.querySelector(`script[src="${url}"]`);
      let timeoutOccurred = false;
      const timerId = setTimeout(() => {
        handleError();
        timeoutOccurred = true;
      }, timeout);

      if (isExistingScript) {
        getScriptNamespace(timeoutOccurred, timerId);
      } else {
        attachScript(timeoutOccurred, timerId);
      }
    } else {
      setIsLoaded(false);
    }
  }, []);

  return {
    isLoaded,
    error,
    namespace: getter ? getter() : undefined
  };
};

useScript.propTypes = {
  getter: PropTypes.func,
  scriptAttributes: PropTypes.objectOf(PropTypes.string),
  timeout: PropTypes.number,
  url: PropTypes.string.isRequired
};

export default useScript;
