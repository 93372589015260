import { useTranslation } from 'react-i18next';
import { replaceTokensInString } from '@app/utils';
import { formatDateToLocale } from '@app/utils/dateUtils';
import {
  DIFFERENT_MONTHS,
  SAME_MONTHS,
  SINGLE_MONTH
} from '@app/constants/commonConstants';
const defaultConfig = { month: 'short', day: '2-digit' };
const useFormatFulfillmentDate = (fulfillmentDate, config = defaultConfig) => {
  const [t] = useTranslation();
  let availabilityMessage = '';

  const dateFormatKeys = {
    [SAME_MONTHS]: 'AVAILABILITY_COMPONENT_DATE_FORMAT_FROM_TO_SAME_MONTH',
    [DIFFERENT_MONTHS]:
      'AVAILABILITY_COMPONENT_DATE_FORMAT_FROM_TO_DIFFERENT_MONTHS',
    [SINGLE_MONTH]: 'AVAILABILITY_COMPONENT_DATE_FORMAT_BY'
  };

  const getAvailbilityMsg = ({ key, month, month1, day, day1 }) => {
    availabilityMessage = replaceTokensInString(
      t(`${dateFormatKeys[key]}`),
      t(`${month}`),
      day,
      t(`${month1}`) || day1,
      day1
    );
    return { availabilityMessage };
  };
  if (!(fulfillmentDate && fulfillmentDate?.length)) {
    return { availabilityMessage };
  }
  if (fulfillmentDate.length > 1) {
    const { month, day } = formatDateToLocale(fulfillmentDate[0], config);
    const { month: month1 = '', day: day1 = '' } = formatDateToLocale(
      fulfillmentDate[1],
      config
    );
    if (month === month1) {
      return getAvailbilityMsg({ key: SAME_MONTHS, month, day, day1 });
    }
    return getAvailbilityMsg({
      key: DIFFERENT_MONTHS,
      month,
      day,
      month1,
      day1
    });
  }
  const { month, day } = formatDateToLocale(fulfillmentDate[0], config);
  return getAvailbilityMsg({ key: SINGLE_MONTH, month, day });
};
export default useFormatFulfillmentDate;
