import { useEffect, lazy, useRef, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { isHomepage } from '@app/utils/browserUtils';
import { fetchCsrAuditSummary, validateCSRAudits } from './utils';

const CsrActionSummaryModal = lazy(
  () =>
    import(
      /* webpackChunkName: 'csrActionSummaryModal' */ './CsrActionSummaryModal/CsrActionSummaryModal'
    )
);

const CsrActionSummaryNotificationBlocks = () => {
  const userType = useSelector(s => s.common?.userType);
  const storeId = useSelector(s => s.common?.storeId);
  const dealerName = useSelector(s => s.dealer?.dealerName) || null;
  const isCatCorp = useSelector(s => s.common?.isCatCorp) || false;
  const isCatCSR = useSelector(s => s.common?.isCatCSR) || false;
  const isBuyOnBehalf = useSelector(s => s.common?.isBuyOnBehalf) || false;
  const startPage = useSelector(
    s => s.shoppingPreferences?.shoppingPreferencesData?.startPage
  );

  const isHomePage = isHomepage();
  const isRegularCustomer = userType === 'R' && !isCatCorp;
  const isRegisteredUser = userType !== 'G';

  const checkByURL = urlPiece => {
    return window.location.href.includes(urlPiece);
  };

  const visitingPreferredStartPage = useCallback(() => {
    const startPagePreference = startPage?.find(d => d.default)?.value;
    const preferenceSetting = {
      cc: checkByURL('route=ControlCenter'),
      partManuals: checkByURL('PartLookup'),
      shopCart: checkByURL('AjaxOrderItemDisplayView'),
      homePage: isHomepage()
    };

    return preferenceSetting[startPagePreference];
  }, [startPage]);

  const [csrEvents, setCsrEvents] = useState([]);
  const [displayCSRActionSummaryModal, setDisplayCSRActionSummaryModal] =
    useState(false);

  useEffect(() => {
    (async () => {
      if (!isRegisteredUser && !dealerName) {
        return;
      }
      if (isCatCSR || isBuyOnBehalf) {
        return;
      }
      if (!startPage) {
        if (!visitingPreferredStartPage()) {
          return;
        }
      }
      if (!isRegularCustomer && !isHomePage) {
        return;
      }
      const fetchedEvents = await fetchCsrAuditSummary(storeId);
      if (fetchedEvents?.length > 0 && validateCSRAudits(fetchedEvents)) {
        setCsrEvents(fetchedEvents);
        setDisplayCSRActionSummaryModal(true);
      }

      if (!displayCSRActionSummaryModal) {
        return;
      }
    })();
  }, [
    storeId,
    dealerName,
    startPage,
    isRegisteredUser,
    isCatCSR,
    isBuyOnBehalf,
    isRegularCustomer,
    isHomePage,
    displayCSRActionSummaryModal
  ]);

  if (!displayCSRActionSummaryModal) {
    return;
  }

  return <CsrActionSummaryModal csrEvents={csrEvents} />;
};

export default CsrActionSummaryNotificationBlocks;
