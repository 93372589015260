import PropTypes from 'prop-types';

export const error = PropTypes.oneOfType([PropTypes.string, PropTypes.node]);

export const children = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.node)
]);

export const label = PropTypes.oneOfType([PropTypes.string, PropTypes.node]);

export const helpText = PropTypes.oneOfType([PropTypes.string, PropTypes.node]);

export const placeholder = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object
]);

export const type = PropTypes.oneOf([
  'checkbox',
  'date',
  'daterange',
  'dropdown',
  'email',
  'file',
  'inputdropdown',
  'number',
  'radio',
  'switch',
  'tel',
  'text',
  'textarea',
  ''
]);

export const fieldTypes = {
  constrainBytes: PropTypes.number,
  errorAfterSubmit: PropTypes.bool,
  handleOnChange: PropTypes.func,
  label: label,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  skipFormikOnChange: PropTypes.bool,
  type: type,
  validator: PropTypes.func
};
export default {
  children,
  error,
  fieldTypes,
  helpText,
  label,
  placeholder,
  type
};
