import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import {
  FITMENT_ADD_EQUIPMENT,
  FITMENT_SELECT_EQUIPMENT,
  FITMENT_VIEW_EDIT_EQUIPMENT
} from '../../../constants/targets';
import { lazy } from 'react';

const AddEquipmentFitmentCTA = lazy(() =>
  import(
    /* webpackChunkName: 'addEquipmentFitmentCTA' */ './equipmentPanel/AddEquipmentFitmentCTA'
  )
);
const SelectEquipmentFitmentCTA = lazy(() =>
  import(
    /* webpackChunkName: 'selectEquipmentFitmentCTA' */ './equipmentPanel/SelectEquipmentFitmentCTA'
  )
);
const ViewEditEquipmentFitmentCTA = lazy(() =>
  import(
    /* webpackChunkName: 'viewEditEquipmentFitmentCTA' */ './equipmentPanel/ViewEditEquipmentFitmentCTA'
  )
);

const MyEquipmentFitmentWrapper = () => {
  const selectedEquipment = useSelector(
    state => state.myEquipment?.equipments?.selectedEquipment
  );
  const myEquipmentList = useSelector(
    state => state.myEquipment?.equipments?.myEquipmentList
  );
  const storeId = useSelector(state => state.common?.storeId);

  const addEquipmentFitmentTarget = document.getElementById(
    FITMENT_ADD_EQUIPMENT
  );
  const selectEquipmentFitmentTarget = document.getElementById(
    FITMENT_SELECT_EQUIPMENT
  );
  const viewEditEquipmentFitmentTarget = document.getElementById(
    FITMENT_VIEW_EDIT_EQUIPMENT
  );

  if (addEquipmentFitmentTarget) {
    return createPortal(
      <AddEquipmentFitmentCTA storeId={storeId} />,
      addEquipmentFitmentTarget
    );
  }

  if (selectEquipmentFitmentTarget) {
    return createPortal(
      <SelectEquipmentFitmentCTA
        selectedEquipment={selectedEquipment}
        myEquipmentList={myEquipmentList}
      />,
      selectEquipmentFitmentTarget
    );
  }

  if (viewEditEquipmentFitmentTarget) {
    return createPortal(
      <ViewEditEquipmentFitmentCTA
        selectedEquipment={selectedEquipment}
        storeId={storeId}
      />,
      viewEditEquipmentFitmentTarget
    );
  }

  return null;
};

export default MyEquipmentFitmentWrapper;
