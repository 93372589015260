import DOMPurify from 'dompurify';
import { Fragment } from 'react';
/**
 * Escapes the special characters of a regular expression
 * @param {string} string A regexp string to escape
 * @returns {string} scaped regexp
 */
export const escapeRegExp = string =>
  string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

/**
 * runs a regex test on provided string to see if the string is an anchor tag with open and close
 * @param {string} str
 * @returns {boolean}
 * @example stringIsAnAnchorTag(<a>something</a>) returns true
 * @example stringIsAnAnchorTag(something) returns false
 */
export const stringIsAnAnchorTag = str => /^<a.*>.*<\/a>/i.test(str);

/**
 * Replaces tokens in a string
 * @param {string} string - A string with sequential tokens to be replaced. ex: "Hello {0}! It's me {1}!"
 * @param {string[]} replacements - Strings that replace a token {index-1} (the first node in replacements replaces {0}, 2nd -> {1})
 * @param {boolean} encodeReplacement - Option to decode the replacements as URIComponent
 * @returns {string}
 */
export const replaceTokens = (string, replacements, encodeReplacement) =>
  (replacements || []).reduce(
    (accumulator, currentValue, index) =>
      accumulator?.replace(
        new RegExp(escapeRegExp(`{${index}}`), 'g'),
        encodeReplacement ? encodeURIComponent(currentValue) : currentValue
      ),
    string
  );

/**
 * Replaces tokens in a string
 * @param {string} string - A string with sequential tokens to be replaced. ex: "Hello {0}! It's me {1}!"
 * @param {...string} replacements - Strings that replace a token {index-1} (the first node in replacements replaces {0}, 2nd -> {1})
 * @returns {string} - Returns a string with tokens (ex. {0}, {1}) replaced with it's corresponding replacement.
 * @example
 * 		// returns 'Hello world! It's me Keith!'
 * 		replaceTokensInString("Hello {0}! It's me {1}!", 'world', 'Keith');
 * @license - Credit https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
 * 		(Mozilla code samples added on or after August 20, 2010 are in the public domain (CC0). No licensing notice is necessary)
 */
export const replaceTokensInString = (string, ...replacements) =>
  replaceTokens(string, replacements, false);

/**
 * Replaces tokens in a string and return the string URL-encoded
 * @param {string} url - A url string with sequential tokens to be replaced. ex: "/wcs/resources/store/{0}/whatever/{1}"
 * @param {...string} replacements - Strings that replace a token {index-1} (the first node in replacements replaces {0}, 2nd -> {1})
 * @returns {string} - Returns a string with tokens (ex. {0}, {1}) replaced with it's corresponding URI-encoded replacement.
 * @example
 * 		// returns '/wcs/resources/store/test/whatever/%25stuff'
 * 		replaceTokensInUrl("/wcs/resources/store/{0}/whatever/{1}", 'test', '%stuff');
 * @license - Credit https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
 * 		(Mozilla code samples added on or after August 20, 2010 are in the public domain (CC0). No licensing notice is necessary)
 */
export const replaceTokensInUrl = (string, ...replacements) =>
  replaceTokens(string, replacements, true);

/**
 * removes numeric(12345) and alphanumeric(A1A-1A1) zipcode form a string. This is helpful when trying to prevent passing PII in GA events.
 * @param {string} stringWithZip
 * @returns {string} The initially entered string with the zipcode removed
 */
export const removeZipcodeFromString = stringWithZip => {
  if (typeof stringWithZip !== 'string') {
    return '';
  }
  return stringWithZip
    .replace(
      /[A-Za-z]\d[A-Za-z][ -]{0,1}\d[A-Za-z]\d[ ,]|[A-Za-z]\d[A-Za-z][ ,]|\d+[ -]{0,1}\d+[ ,]{0,1}|\d+[ ,]{0,1}?/g,
      ''
    )
    .trim();
};

/**
 * Replaces tokens with component
 * @param {string} string - A string with sequential tokens to be replaced. ex: "Hello {0}! It's me {1}!"
 * @param {node[]} replacements - components that replace a token {index-1} (the first node in replacements replaces {0}, 2nd -> {1})
 * @returns {node[]}
 * We are using index as key as these elements are static
 */
export const replaceTokensWithComponents = (string, ...replacements) => {
  const regEx = /(\{[0-9]+\})/g;
  const substring = string?.split(regEx);
  replacements.forEach((replacement, index) => {
    const replaceTextIndex = substring?.findIndex(
      part => part === `{${index}}`
    );
    substring[replaceTextIndex] = (
      <Fragment key={index}>{replacement}</Fragment>
    );
  });
  return substring;
};

/**
 * runs a regex test on provided string to see if the string has html tag or not
 * @param {string} str
 * @returns {boolean}
 * @example stringHasHTMLTag(testing<a>something</a>) returns true
 * @example stringHasHTMLTag(something) returns false
 */
export const stringHasHTMLTag = str => /<\/?[a-z][\s\S]*>/i.test(str);

/**
 * replaces the number of places specified at the beginning of a string with ceros
 * @param {string} num
 * @param {number} places
 * @returns
 */
export const zeroPad = (num, places) => String(num).padStart(places, '0');
/**
 * removes spaces from String
 * @param {string} str
 * @returns {string} The initially entered string with the spaces removed
 */
export const removeSpacesFromString = str => {
  if (typeof str !== 'string') {
    return '';
  }
  return str.replace(/\s+/g, '');
};

/**
 * Use this function to decode html entities in a HTML string like &reg; or &amp;
 * @param {string} htmlString HTML with entities
 * @returns{string} string with decoded entities like &,>,<,®,~
 */
export function decodeHTML(htmlString) {
  const txt = document.createElement('textarea');
  txt.innerHTML = htmlString;
  return DOMPurify.sanitize(txt.value);
}

/**
 * formats the response from non rest services that come as a comment
 * @param {string} responseData data property from the response
 * @returns
 */
export const formatNonRestResponse = responseData =>
  JSON.parse(responseData.slice(2, -2));

/**
 * Use this function to match custom tags to html tags like <superscript> to <sup>
 * @param {string} content HTML with HTML String
 * @param {Object} tags with actual tags as key and custom tags as value
 * @returns{string} string with replaced html tags
 */
export const replaceCustomTags = (content, tags) => {
  if (stringHasHTMLTag(content)) {
    for (let key in tags) {
      content = content.replaceAll(`${key}>`, `${tags[key]}>`);
    }
  }
  return content;
};

export const capitalize = (str = '') => {
  return str
    ?.toLowerCase()
    ?.replace(/(\b[a-z](?!\s))/g, letter => letter?.toUpperCase());
};

export const capitalizeText = str => {
  return capitalize(str || '');
};

export const replaceFromString = (modal, name) => {
  return name?.replace(modal, '')?.trim() ?? modal;
};
