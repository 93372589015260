import { SEARCHPATH } from './constant';
export const appendUrl = (href, pathname, searchUrlId) => {
  if (pathname === SEARCHPATH && searchUrlId) {
    return `${href}&keyword=${encodeURIComponent(
      searchUrlId
    )}&returnPage=${encodeURIComponent(window.location.href)}`;
  } else {
    return `${href}&returnPage=${encodeURIComponent(window.location.href)}`;
  }
};
