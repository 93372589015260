import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AlloyButton, useModal } from 'cat-ecommerce-alloy';
import { SAVE_TYPE } from '@app/components/pages/checkout/shopping-cart/OrderItems/constants';
import AddSavedListModal from '@app/components/saved-lists/AddSavedListModal';
import CreateSavedListModal from '@app/components/saved-lists/CreateSavedListModal';
import { useSelector } from 'react-redux';
import { USER_TYPE_GUEST } from '@app/constants/commonConstants';
import useSavedLists from '@app/components/saved-lists/useSavedLists';

const SaveToListButton = ({
  itemPartNumber,
  quantity,
  fromPDPPage = false
}) => {
  const [t] = useTranslation();
  const AddSavedListModalProps = useModal();
  const createSavedListModalProps = useModal();
  const savedLists = useSelector(s => s.account?.savedLists);
  const hasSavedLists = savedLists?.length > 0;
  const userType = useSelector(s => s.common?.userType);
  const isGuest = userType === USER_TYPE_GUEST;
  const { saveItemsToList } = useSavedLists();

  const data = {
    items: [
      {
        partNumber: itemPartNumber,
        quantity: quantity
      }
    ],
    saveType: SAVE_TYPE.DEFAULT
  };

  const onClick = () => {
    if (isGuest) {
      saveItemsToList(data);
    } else {
      if (hasSavedLists) {
        AddSavedListModalProps.open();
      } else {
        createSavedListModalProps.open();
      }
    }
  };

  return (
    <>
      <AddSavedListModal
        {...AddSavedListModalProps}
        addSavedListData={data}
        fromPDPPage={fromPDPPage}
      />
      <CreateSavedListModal
        {...createSavedListModalProps}
        createSavedListData={data}
        fromPDPPage={fromPDPPage}
      />
      <AlloyButton
        buttonType="ghost"
        onClick={onClick}
        size="dynamic"
        className="px-0 py-2"
      >
        {t('SAVE_TO_LIST')}
      </AlloyButton>
    </>
  );
};

SaveToListButton.propTypes = {
  itemPartNumber: PropTypes.string,
  quantity: PropTypes.string,
  fromPDPPage: PropTypes.bool
};

export default SaveToListButton;
