/**
 * To Load the Qualtrics only when we needed
 */
export const loadQualtrics = () => {
  window?.QSI?.API?.load();
};

/**
 * To Run the Qualtrics only when we needed
 */
export const runQualtrics = () => {
  window?.QSI?.API?.run();
};
