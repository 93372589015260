export const FIND_MY_SERIAL_DATA = [
  {
    title: 'FMS_SLIDE1_TITLE',
    image: [
      'https://caterpillar.scene7.com/is/image/Caterpillar/CM20220208-8552a-03070',

      'https://caterpillar.scene7.com/is/image/Caterpillar/CM20220208-e9624-c99b5'
    ],
    description: [
      {
        text: 'FMS_SLIDE1_BODY',
        strongText: 'FMS_SLIDE1_BODY2'
      }
    ],
    button: 'FMS_SLIDE1_NEXT_BUTTON',
    id: 0
  },
  {
    title: 'FMS_SLIDE2_TITLE',
    image: [
      'https://caterpillar.scene7.com/is/image/Caterpillar/CM20220208-e3a2c-0b730',

      'https://caterpillar.scene7.com/is/image/Caterpillar/CM20220208-ab41f-ff534'
    ],
    description: [
      {
        text: 'FMS_SLIDE2_BODY'
      },
      {
        text: 'FMS_SLIDE2_BODY2'
      }
    ],
    subDescription: [
      {
        title: 'PREFIX',
        description: 'FMS_SLIDE2_BODY_PREFIX'
      },
      {
        title: 'SEQUENCE_ID',
        description: 'FMS_SLIDE2_BODY_SEQID'
      }
    ],

    button: 'FMS_SLIDE2_NEXT_BUTTON',
    id: 1
  },
  {
    title: 'FMS_SLIDE3_TITLE',
    image: [
      'https://caterpillar.scene7.com/is/image/Caterpillar/CM20220208-a2894-08f88',

      'https://caterpillar.scene7.com/is/image/Caterpillar/CM20220208-37a04-9841a'
    ],
    description: [
      {
        text: 'FMS_SLIDE3_BODY'
      }
    ],
    button: 'FMS_SLIDE3_NEXT_BUTTON',
    id: 2
  },
  {
    title: 'FMS_SLIDE4_TITLE',
    image: [
      'https://caterpillar.scene7.com/is/image/Caterpillar/CM20220208-ad620-d01ab'
    ],
    description: [
      {
        text: 'FMS_SLIDE4_BODY'
      }
    ],
    button: 'FMS_SLIDE4_NEXT_BUTTON',
    contactOption: true,
    id: 3
  }
];
export const FIND_MY_SERIAL_DOTS = [0, 1, 2, 3];
export const FIND_MY_SERIAL_LAST_INDEX = 3;
