import { STATUS } from '@app/constants/commonConstants';

export const checkoutInitialStore = {
  addresses: {},
  addToCartStatus: STATUS.IDLE,
  billingMethodOptions: [],
  cartURL: '',
  currentAddToCartHasAlternate: false,
  currentAddToCartHasReplacement: false,
  currentAddToCartHasSOS: false,
  currentAddToCartModifications: {},
  currentAddToCartPhase: null,
  isCurrentAddToCartAndCheckoutFlow: false,
  deepLink: {},
  isOrderDetailsLoading: true,
  isOrderDetailsLoadSuccessful: false,
  isPLacingOrder: false,
  isPUAndDLoading: false,
  isPUAndDLoadSuccessful: false,
  isSAndPLoading: true,
  isSAndPLoadSuccessful: false,
  isShoppingCartPageLoading: false,
  isRequiredFieldsLoading: true,
  isRequiredFieldsLoadSuccessful: false,
  requiredFields: {},
  setOrderInformationStatus: STATUS.IDLE,
  sosItemDetailsStatus: STATUS.IDLE,
  addToCartSubmitStatus: STATUS.IDLE,
  isPartsRemovedByInvalidSOS: false,
  removedParts: [],
  itemsAddedIntoCartPostProcessing: [],
  isCheckFreightLoading: false,
  isCheckFreightCallSuccessful: false,
  isCheckFreightCallFailed: false,
  taxAndFreight: [],
  catCardPromotionOptionsToggle: false,
  catCardSelectedPromotionOption: '',
  catSalesForceAccountBalanceStatus: STATUS.IDLE,
  catSalesForceAccountBalance: {
    accountId: undefined,
    accountBalance: undefined,
    accountCurrencyCode: undefined
  },
  catCardGlobalOrderTotalResponseStatus: STATUS.IDLE,
  catCardGlobalOrderTotalPayload: {},
  catCardGlobalOrderTotalResponse: {
    accountCurrencyCode: undefined,
    orderTotals: undefined,
    unformattedOrderTotal: undefined,
    accountCurrencyCodeForeignCurrency: undefined,
    orderTotalsForeignCurrency: undefined,
    unformattedOrderTotalForeignCurrency: undefined
  },
  catCreditsDeleteAccount: STATUS.IDLE,
  saveCatCreditsAccountCheckbox: false,
  shareConfirmationStatus: STATUS.IDLE,
  applyCatVantageRewardsToOrderStatus: STATUS.IDLE,
  applyCatCreditsGlobal: {
    wasClickedApplyCreditsBtn: false,
    applyCatCreditsGlobalBalance: {
      amountUsedCatCreditsGlobal: null,
      usedAmountForgeinCurrency: null,
      remainingCatCreditsGlobal: null,
      remainingAmountForeignCurrency: null
    },
    applyCatCreditsGlobalStatus: STATUS.IDLE
  }
};
