import { useSelector } from 'react-redux';

const useUserPreferences = () => {
  const storeId = useSelector(s => s.common?.storeId);
  const contextAttribute = useSelector(
    s => s.user?.byId?.[s.user.current]?.contextAttribute || []
  );
  const getAttributeValue = attributeValue => {
    if (
      attributeValue?.value[0] !== 'true' &&
      attributeValue?.value[0] !== 'false'
    ) {
      return attributeValue?.value[0] ?? '';
    } else {
      return attributeValue?.value[0] === 'true';
    }
  };
  const getInitialValues = baseInitialValues => {
    let initialValues = { ...baseInitialValues };
    Object.keys(initialValues).forEach(key => {
      const attribute = contextAttribute.find(
        attr => attr.attributeName === key
      );
      if (attribute) {
        let attributeValue = attribute.attributeValue.find(
          attrValue => attrValue.storeId === storeId
        );
        attributeValue =
          attributeValue ||
          attribute.attributeValue.find(attrValue => attrValue.storeId === '0');
        initialValues[key] = attributeValue
          ? getAttributeValue(attributeValue)
          : initialValues[key];
      }
    });

    return initialValues;
  };
  const getDefaultValue = (prefix, field) => {
    const prefixAttributeList = contextAttribute.filter(attr => {
      const splitName = attr?.attributeName.split('_');
      return splitName?.includes(prefix);
    });
    if (prefixAttributeList.length > 0) {
      const filterAttr = prefixAttributeList.filter(
        attr => attr.attributeName === field
      );
      const attributeValue = filterAttr[0]?.attributeValue.find(
        attrValue => attrValue.storeId === '0'
      );
      return filterAttr.length === 0
        ? false
        : getAttributeValue(attributeValue);
    } else {
      return true;
    }
  };
  return { getInitialValues, getDefaultValue };
};

export default useUserPreferences;
