import { useSelector, useDispatch } from 'react-redux';
import { loadPreferences } from '@app/store/shoppingPreferences/actions';
import {
  STATUS,
  SUMMARY_AND_PAYMENT_REQUEST_TYPE
} from '@app/constants/commonConstants';

export const useGetShoppingPreferenceDataLoadStatus = (
  enableShoppingPreferencesCall = true,
  isPreferenceCallByJson = false
) => {
  const shoppingPreferenceDataStatus = useSelector(
    s => s.shoppingPreferences.shoppingPreferenceDataStatus
  );
  const defaultDealerId = useSelector(
    s => s.dealerAssociation.defaultAssociation?.value
  );
  const dispatch = useDispatch();

  const shoppingPreferenceDataHasNotBeenCalled =
    shoppingPreferenceDataStatus === STATUS.IDLE;

  if (shoppingPreferenceDataHasNotBeenCalled && enableShoppingPreferencesCall) {
    dispatch(
      loadPreferences({
        storeId: defaultDealerId,
        ...(isPreferenceCallByJson
          ? {}
          : { requestType: SUMMARY_AND_PAYMENT_REQUEST_TYPE })
      })
    );
  }

  const isPreferencesDataLoading =
    shoppingPreferenceDataStatus === STATUS.PENDING;

  const isPreferencesDataLoadCompleted =
    shoppingPreferenceDataStatus === STATUS.REJECTED ||
    shoppingPreferenceDataStatus === STATUS.RESOLVED;

  return { isPreferencesDataLoading, isPreferencesDataLoadCompleted };
};
