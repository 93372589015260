import { useEffect } from 'react';
import { getSessionStorage, removeSessionStorage } from '@app/utils';
import { gaTimeTracker } from '@app/components/common/analytics/analyticsUtils';

const useCalculateGATiming = (startTimeName, gaEventName) => {
  const calculateGATime = initialTime => {
    const startTime = new Date(initialTime);
    const endTime = new Date();
    return Math.floor(endTime - startTime);
  };

  const sendGATiming = initialTime => {
    const totalTime = calculateGATime(initialTime);
    gaTimeTracker(totalTime, gaEventName);
    removeSessionStorage(startTimeName);
  };

  useEffect(() => {
    const initialTime = getSessionStorage(startTimeName);
    if (!initialTime) {
      return;
    }
    sendGATiming(initialTime);
  }, []);
};

export default useCalculateGATiming;
