import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { replaceTokensInUrl } from '../../../utils';
import links from '../../../constants/links';
import Address from '../address/Address';

/**
 * Standardized popup contents for contacting a dealer. Pass the catEntryId to
 * prepopulate the contact form with the part number
 */
const ContactDealerPopup = ({
  catEntryId,
  hideContactUs = false,
  isShowContactUs = true,
  partNumber
}) => {
  const [t] = useTranslation();
  const { storeId, catalogId, langId } = useSelector(state => state.common);
  const { address, city, country, email, locale, phone, state, zipcode } =
    useSelector(state => state.store);
  const hasAddressInfo = [address, city, state, zipcode, country].some(
    val => !!val
  );
  const textClassName = 'text-sm u-color--DarkGray mb-3';

  const getContactDealerLinkAttrs = ({ partNumber, catEntryId }) => {
    let link = links.CONTACT_DEALER_LINK;
    const uniqueId = catEntryId ?? partNumber;
    const attributeId = catEntryId ? 'catalogEntryID' : 'partNumber';
    if (catEntryId || partNumber) {
      link += `&${attributeId}={3}&isContactDealer=Y`;
    }
    return { link, uniqueId };
  };

  const { link, uniqueId } = getContactDealerLinkAttrs({
    partNumber,
    catEntryId
  });

  const contactUsURL = link
    ? replaceTokensInUrl(link, catalogId, langId, storeId, uniqueId)
    : '';
  const contactUsLink = (
    <a className="text-lowercase" href={contactUsURL}>
      {t('CONTACT_FORM')}
    </a>
  );

  const replaceContactUsLink = msg => {
    const split = msg.split('{0}');
    return split.length > 1 ? (
      <>
        <span className={textClassName}>{split[0]}</span>
        {contactUsLink}
        <span className={textClassName}>{split[1]}</span>
      </>
    ) : (
      <>
        {contactUsLink}
        <span className={textClassName}>{split[0]}</span>
      </>
    );
  };

  return (
    <div>
      {isShowContactUs && (
        <p className="text-uppercase text-sm u-color--ExtraDarkGray text-univers--bold mb-2">
          {t('FOOTER_CONTACT_US')}
        </p>
      )}
      {!!phone && (
        <p className={textClassName}>
          <span className={cx('fw-bold', textClassName)}>
            {t('CONTACT_DEALER_CALL')}
          </span>
          <br />
          <a href={`tel:${phone}`}>{phone}</a>
        </p>
      )}
      {!!email && (
        <p className={textClassName}>
          <span className={cx('fw-bold', textClassName)}>{t('EMAIL_US')}</span>
          <br />
          <a href={`mailto:${email}`}>{email}</a>
        </p>
      )}
      {hasAddressInfo && (
        <div className="mb-3">
          <p className={cx('fw-bold text-sm u-color--DarkGray')}>
            {t('CONTACT_DEALER_VISIT')}
          </p>
          <Address
            address1={address}
            city={city}
            className="text-sm u-color--DarkGray"
            country={country}
            locale={locale}
            state={state}
            zipCode={zipcode}
          />
        </div>
      )}
      {!hideContactUs && (
        <p className={textClassName}>
          {replaceContactUsLink(t('CONTACT_DEALER_CONTACT_FORM'))}
        </p>
      )}
    </div>
  );
};

export default ContactDealerPopup;

ContactDealerPopup.propTypes = {
  catEntryId: PropTypes.string,
  hideContactUs: PropTypes.bool,
  isShowContactUs: PropTypes.bool,
  partNumber: PropTypes.string
};
