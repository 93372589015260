import PropTypes from 'prop-types';
import Conditional from '@app/components/common/Conditional';
import { normalizeUrl } from '@app/utils';
import { CatPrimaryNavItem } from 'blocks-react/bedrock/components/PrimaryNavItem/PrimaryNavItem.js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { SEARCHPAGE_ROUTE } from '@app/constants/routes';
import queryString from 'query-string';
import { MAIN_NAV_TRACK } from '@app/constants/analyticsConstants';
import styles from '../Subheader.modules.scss';
import cx from 'classnames';

const SpecialsWrapper = ({ isMobile = false }) => {
  const { t } = useTranslation();
  const match = useRouteMatch(SEARCHPAGE_ROUTE);
  const { search } = useLocation();
  const { fromSpecials } = queryString.parse(search);

  /**It should be true as string because fromSpecials comes from urlParams */
  const isCurrentPage = !!match && fromSpecials === 'true';
  const specialsUrl = useSelector(s => s.header?.globalNavigation?.specials);
  const text = t('SPECIALS');
  return (
    <Conditional test={!!specialsUrl}>
      <CatPrimaryNavItem
        data-testid="specials"
        href={normalizeUrl(specialsUrl)}
        text={text}
        isCurrent={isCurrentPage}
        data-track-event={MAIN_NAV_TRACK.EVENT}
        data-track-attr-nltext={MAIN_NAV_TRACK.SPECIALS_TEXT}
        data-track-attr-context={MAIN_NAV_TRACK.TOP_MENU_CONTEXT}
        className={cx({ [styles['item-border-bottom']]: isMobile })}
      />
    </Conditional>
  );
};

SpecialsWrapper.propTypes = {
  isMobile: PropTypes.bool
};

export default SpecialsWrapper;
