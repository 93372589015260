import { useState } from 'react';
import PropTypes from 'prop-types';
import { OrderStatus } from '@cat-ecom/pcc-components';
import { CONTACT_DEALER_CARDS } from '@app/constants/commonConstants';
import ContactDealerDrawer from '../ContactDealerDrawer';

const Orderstatus = ({
  orderStatus,
  type,
  link,
  orderDisplayLinkText,
  statusKey,
  orderId,
  reviewLink
}) => {
  const [isContactDealerClicked, setContactDealerClicked] = useState(false);
  const handleOpen = () => {
    setContactDealerClicked(true);
  };
  return (
    <>
      <OrderStatus
        orderStatus={orderStatus}
        type={type}
        handleOpen={handleOpen}
        orderDisplayLinkText={orderDisplayLinkText}
        CONTACT_DEALER_CARDS={CONTACT_DEALER_CARDS.includes(statusKey)}
        reviewLink={reviewLink}
        link={link}
      >
        <ContactDealerDrawer
          isContactDealerClicked={isContactDealerClicked}
          setContactDealerClicked={setContactDealerClicked}
          orderStatus={orderStatus}
          orderId={orderId}
          type={type}
          statusKey={statusKey}
        />
      </OrderStatus>
    </>
  );
};

export default Orderstatus;

Orderstatus.propTypes = {
  type: PropTypes.string,
  orderStatus: PropTypes.string,
  link: PropTypes.string,
  orderDisplayLinkText: PropTypes.string,
  reviewLink: PropTypes.string,
  statusKey: PropTypes.string,
  orderId: PropTypes.number
};
