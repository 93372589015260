import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalytics, useBreakpoint } from '@app/hooks';
import { isEmpty } from '@app/utils';
import { CatLoadingIndicator } from 'blocks-react/bedrock/components/LoadingIndicator';
import { useEquipmentSearchSuggestions } from '../AddEquipmentModal/hooks';
import {
  formSubmittedEventPayloads,
  getFormattedSerialNumber,
  saveEquipment
} from '../utils';
import { isServerError, validateSerialNumber } from './utils';
import styles from './AddEquipmentDrawer.module.scss';

import {
  Drawer,
  MyEquipmentSaveFooter,
  MyEquipmentNickname,
  MyEquipmentSearchForm,
  MyEquipmentSerialInput,
  MyEquipmentTab,
  MyEquipmentTabPanel,
  MyEquipmentTabs,
  MyEquipmentForm,
  useDrawer,
  MyEquipmentButton,
  MyEquipmentCatIconSuccess
} from '@cat-ecom/pcc-components';
import {
  GA_FORM_NAME_BY_MODEL,
  GA_FORM_NAME_BY_SERIAL
} from '@app/store/myequipment/constants';

import {
  SEARCH_FILTER,
  SEARCH_LENGTH,
  PAGE_BREAKPOINTS,
  GA_LOCATION_FOR_MEQ,
  BUTTON_VARIANTS,
  HTML_SPECIAL_CHAR_ERROR
} from '@app/constants/commonConstants';
import { TAB_ID } from '../../homepage/EquipmentSection/constants';
import {
  FIND_MODEL_ID,
  FIND_SERIAL_ID,
  FORM_FIELD_MODEL,
  FORM_FIELD_SERIAL,
  FORM_FIELD_NICK_NAME,
  WIDGET_IDENTIFIER
} from '../constant';
import {
  addOrUpdateEquipmentInWishList,
  clearErrors,
  setEquipmentSaveSuccess
} from '@app/store/myequipment/actions';
import ErrorBanner from '../ErrorBanner';
import { useTracking } from 'react-tracking';
import SelectedEquipmentInfo from '../SelectedEquipmentInfo/SelectedEquipmentInfo';
import { replaceTokensInString } from '../../../../utils';
import { capitalizeText } from '@app/utils/stringUtils';
import AddEquipmentFootNote from './AddEquipmentFootNote';
import AddEquipmentDealerNotification from './AddEquipmentDealerNotification';
import AddEquipmentDuplicateBanner from './AddEquipmentDuplicateBanner';
import AddEquipmentInfoDrawer from './AddEquipmentInfoDrawer';
import AddEquipmentMultiSerialSearch from './AddEquipmentMultiSerialSearch';
import SearchResults from './SearchResults';
import AddEquipmentHeader from './AddEquipmentHeader';
import { hasHtmlInjectionTags } from '@app/utils/commonUtils';
import { PCC_LUCID_SOLR_ADDEQUIPMENTCOMPONENT_FLAG } from '@app/constants/featureFlags';

const AddEquipmentDrawer = ({ drawerProps, onDrawerClose, isChild }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedEquipment, setSelectedEquipment] = useState();
  const { trackEvent } = useTracking();
  const { pathname } = useLocation();
  const urlArrayElements = pathname.split('/');
  const isAlpVideoEnable = urlArrayElements.includes(
    'AlpMaintenanceVideosView'
  );
  const isRedirect = urlArrayElements.includes('self-service-options');
  const formMethods = useForm({
    defaultValues: {
      serial: '',
      nickName: '',
      model: ''
    },
    mode: 'all'
  });

  const [t] = useTranslation();
  const isBLOCKSSM = useBreakpoint(PAGE_BREAKPOINTS.BLOCKS_SM + 1);
  const [searchState, setSearchState] = useState({
    query: '',
    results: [],
    otherResults: [],
    success: false
  });

  const [notifyDealer, setNotifyDealer] = useState(false);
  const isCSPCustomer = useSelector(state => state.common.isCSPCustomer);
  const isLucidAddEquipmentFeatFlag = useSelector(
    s => s.featureFlag[PCC_LUCID_SOLR_ADDEQUIPMENTCOMPONENT_FLAG]
  );

  const { equipmentSaveSuccessful } = useSelector(s => s.myEquipment);
  const isLoading = useSelector(state => state.myEquipment.isLoading);
  const {
    callSuggestionsSearchService,
    error,
    errorText,
    setError,
    setErrorText,
    loading
  } = useEquipmentSearchSuggestions();
  const dispatch = useDispatch();
  const { fireFormSubmittedEvent } = useAnalytics();
  const drawerSerialLink = useDrawer({
    id: FIND_SERIAL_ID
  });
  const drawerModelLink = useDrawer({
    id: FIND_MODEL_ID
  });
  const onFindBy = (e, type) => {
    e.stopPropagation();
    if (type === TAB_ID.SERIAL) {
      drawerSerialLink.initiatorProps.onClick();
    } else {
      drawerModelLink.initiatorProps.onClick();
    }
  };

  const isSuccessfulSearch =
    searchState.success &&
    !error &&
    (searchState.results.length > 0 || searchState.otherResults.length > 0);

  const isError = useMemo(() => {
    const isDuplicate = isServerError(formMethods.formState.errors);
    if (errorText) {
      return true;
    } else if (isDuplicate) {
      return false;
    }
    return null;
  }, [errorText, formMethods.formState]);

  const updateSearchState = value => {
    setSearchState(value);
  };

  const resetState = useCallback(() => {
    setError(false);
    setSelectedEquipment();
    setErrorText('');
    updateSearchState({
      query: '',
      results: [],
      otherResults: [],
      success: false
    });
    formMethods.clearErrors();
    dispatch(clearErrors());
  }, [dispatch, formMethods, setError, setErrorText]);
  const disabledAdd = isEmpty(selectedEquipment);

  const onClose = useCallback(
    (_e = null, closeParent = false) => {
      onDrawerClose(closeParent);
    },
    [onDrawerClose]
  );

  const getDuplicateBanner = (serialNumber = '') => {
    return (
      <AddEquipmentDuplicateBanner
        serialNumber={serialNumber}
        onClose={onClose}
        isChild={isChild}
        selectedEquipment={selectedEquipment}
        resetState={resetState}
        formError={formMethods.formState.errors}
        formReset={formMethods.reset}
        setNotifyDealer={setNotifyDealer}
      />
    );
  };
  const onTabChange = e => {
    setActiveIndex(e.detail.activeTabIndex);
    resetState();
    formMethods.reset();
    setNotifyDealer(false);
  };

  const handleSearch = (value, tabName) => {
    resetState();
    if (value.length < 2) {
      return;
    }
    const serialFilter = isLucidAddEquipmentFeatFlag
      ? FORM_FIELD_SERIAL
      : SEARCH_FILTER.SERIAL;
    callSuggestionsSearchService(
      value,
      updateSearchState,
      tabName === FORM_FIELD_SERIAL ? serialFilter : FORM_FIELD_MODEL,
      {
        formLocation: GA_LOCATION_FOR_MEQ,
        formName:
          tabName === FORM_FIELD_SERIAL
            ? GA_FORM_NAME_BY_SERIAL
            : GA_FORM_NAME_BY_MODEL
      }
    );
  };

  const selectListItem = equipment => {
    setSelectedEquipment(equipment);
    if (activeIndex !== 0) {
      formMethods.setValue(
        FORM_FIELD_MODEL,
        `${equipment.model} ${capitalizeText(equipment.equipmentFamily)}`
      );
    }
  };

  const getMultiSerialSearch = (list, searchTerm, serialPrefixLabel = '') => {
    return (
      <AddEquipmentMultiSerialSearch
        list={list}
        searchTerm={searchTerm}
        serialPrefixLabel={serialPrefixLabel}
        selectListItem={selectListItem}
      />
    );
  };

  const setFieldError = (field, message) => {
    if (field === 'serialNumber') {
      const fieldName =
        activeIndex === 0 ? FORM_FIELD_SERIAL : FORM_FIELD_MODEL;
      formMethods.setError(fieldName, {
        type: 'serverError'
      });
    } else if (
      field === 'assetId' &&
      !isServerError(formMethods.formState.errors)
    ) {
      formMethods.setError(FORM_FIELD_NICK_NAME, {
        type: 'serverError',
        message: message
      });
    }
  };

  const handleAddEquipment = formValue => {
    if (!formMethods.formState.isValid || disabledAdd) {
      return;
    }
    const currentEquipment = saveEquipment({
      ...selectedEquipment,
      serialNumber: formValue[FORM_FIELD_SERIAL],
      notifyDealer,
      assetId: formValue[FORM_FIELD_NICK_NAME]
    });
    const { model, equipmentFamily, serialNumber } = currentEquipment;
    const formSubmittedEventPayload = formSubmittedEventPayloads(
      serialNumber,
      currentEquipment,
      isCSPCustomer,
      notifyDealer
    );
    const toastContent = replaceTokensInString(
      t('CURRENTLY_SHOPPING'),
      model,
      capitalizeText(equipmentFamily)
    );

    dispatch(
      addOrUpdateEquipmentInWishList(
        currentEquipment,
        setFieldError,
        trackEvent,
        null,
        false,
        toastContent,
        fireFormSubmittedEvent,
        formSubmittedEventPayload,
        null,
        isAlpVideoEnable,
        isRedirect,
        null,
        isLucidAddEquipmentFeatFlag
      )
    );
  };

  const serialSearch = str => {
    const validateSearch = async str => {
      await formMethods.trigger(FORM_FIELD_SERIAL);
      if (!formMethods.getFieldState(FORM_FIELD_SERIAL).error) {
        handleSearch(str, FORM_FIELD_SERIAL);
      }
    };
    if (str !== formMethods.getValues(FORM_FIELD_SERIAL)) {
      resetState();
      if (str.length === 8) {
        validateSearch(str);
      }
    }
  };

  useEffect(() => {
    if (activeIndex === 0 && searchState.results.length === 1) {
      setSelectedEquipment(searchState.results[0]);
    }
    dispatch(clearErrors());
  }, [activeIndex, dispatch, searchState, selectedEquipment]);

  useEffect(() => {
    return () => dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    if (equipmentSaveSuccessful) {
      dispatch(setEquipmentSaveSuccess(false));
      onClose(null, isChild);
    }
  }, [dispatch, equipmentSaveSuccessful, isChild, onClose]);
  return (
    <div id={styles['add-equipment']}>
      <MyEquipmentForm
        formMethods={formMethods}
        handleSubmit={formMethods.handleSubmit(handleAddEquipment)}
      >
        <Drawer {...drawerProps}>
          <AddEquipmentHeader isChild={isChild} onClose={onClose} />
          <ErrorBanner />
          {isLoading ? (
            <div className={`${styles['loader-indicator']} text-center`}>
              <CatLoadingIndicator />
            </div>
          ) : (
            <div className={`${styles['content-area']}`}>
              <MyEquipmentTabs
                activeIndex={activeIndex}
                onBlTabChange={onTabChange}
              >
                <MyEquipmentTab ariaLabel={t('BY_SERIAL')}>
                  {t('BY_SERIAL')}
                </MyEquipmentTab>
                <MyEquipmentTabPanel>
                  <div className="position-relative">
                    <MyEquipmentSerialInput
                      placeholder={t('ORDER_HISTORY_SERIALNUM_PLACEHOLDER')}
                      name={FORM_FIELD_SERIAL}
                      fieldNote={errorText || null}
                      isError={isError}
                      maxLength={SEARCH_LENGTH.MAX}
                      onChange={value => {
                        serialSearch(value);
                      }}
                      rules={{
                        validate: {
                          validateSerialNumber: fieldVale => {
                            if (fieldVale.length === 0) {
                              return true;
                            }
                            return (
                              validateSerialNumber(fieldVale).isValid ||
                              t('MEQ_SERIAL_INVALID')
                            );
                          }
                        }
                      }}
                      dataCsMask
                    />
                    <MyEquipmentCatIconSuccess
                      showIcon={!isEmpty(searchState.results) && !loading}
                    />
                    {searchState.results.length > 1 &&
                      isEmpty(selectedEquipment) && (
                        <div className={styles['serial-multi-result']}>
                          {getMultiSerialSearch(
                            searchState.results,
                            searchState.results[0].serialNumber,
                            t('PREFIX_LABEL')
                          )}
                        </div>
                      )}
                    {loading && (
                      <CatLoadingIndicator
                        className={`${styles['inline-loader-container']}`}
                      />
                    )}
                  </div>
                  {!isEmpty(selectedEquipment) && (
                    <SelectedEquipmentInfo
                      currentEquipment={selectedEquipment}
                      identifier={WIDGET_IDENTIFIER.ADD}
                    />
                  )}
                  {getDuplicateBanner(
                    getFormattedSerialNumber(
                      formMethods.getValues(FORM_FIELD_SERIAL)
                    )
                  )}
                  <MyEquipmentButton
                    onClick={e => onFindBy(e, TAB_ID.SERIAL)}
                    className={`${styles['find-equipment-guide-text']} p-0 mt-2 mb-3`}
                    ariaLabel={t('FIND_SERIAL_GUIDE_TEXT')}
                    variant={BUTTON_VARIANTS.GHOST}
                  >
                    <span className={`${styles['button-link--padding']}`}>
                      {t('FIND_SERIAL_GUIDE_TEXT')}
                    </span>
                  </MyEquipmentButton>
                  <MyEquipmentNickname
                    placeholder={t('MEQ_NICKNAME')}
                    name={FORM_FIELD_NICK_NAME}
                    hideLabel
                  />
                  <AddEquipmentDealerNotification
                    notifyDealer={notifyDealer}
                    setNotifyDealer={setNotifyDealer}
                    classname="pt-4"
                  />
                </MyEquipmentTabPanel>
                <MyEquipmentTab ariaLabel={t('BY_MODEL')}>
                  {t('BY_MODEL')}
                </MyEquipmentTab>
                <MyEquipmentTabPanel>
                  <MyEquipmentSearchForm
                    onSearch={value => {
                      handleSearch(value);
                    }}
                    name={FORM_FIELD_MODEL}
                    isError={isError}
                    placeholder={t('ADD_EQUIPMENT_MODEL_PLACEHOLDER')}
                    isEmpty={
                      formMethods.getValues(FORM_FIELD_MODEL).length < 2 &&
                      isBLOCKSSM
                    }
                    dataCsMask
                  >
                    <SearchResults
                      loading={loading}
                      searchState={searchState}
                      isBLOCKSSM={isBLOCKSSM}
                      searchTerm={formMethods.getValues(FORM_FIELD_MODEL)}
                      isSuccessfulSearch={isSuccessfulSearch}
                      getMultiSerialSearch={getMultiSerialSearch}
                      errorText={errorText}
                    />
                  </MyEquipmentSearchForm>
                  {getDuplicateBanner()}
                  <MyEquipmentButton
                    onClick={e => onFindBy(e, TAB_ID.MODEL)}
                    className={`${styles['find-equipment-guide-text']} p-0 mt-2 mb-3`}
                    ariaLabel={t('FIND_MODEL_GUIDE_TEXT')}
                    variant={BUTTON_VARIANTS.GHOST}
                  >
                    <span className={`${styles['button-link--padding']}`}>
                      {t('FIND_MODEL_GUIDE_TEXT')}
                    </span>
                  </MyEquipmentButton>
                  <MyEquipmentNickname
                    placeholder={t('MEQ_NICKNAME')}
                    name={FORM_FIELD_NICK_NAME}
                    rules={{
                      validate: {
                        validateNickName: value => {
                          return hasHtmlInjectionTags(
                            value,
                            replaceTokensInString(
                              t('ADD_INV_CHAR_NEW_FIELD_WARNING'),
                              '',
                              HTML_SPECIAL_CHAR_ERROR
                            )
                          );
                        }
                      }
                    }}
                    hideLabel
                  />
                </MyEquipmentTabPanel>
              </MyEquipmentTabs>
              <AddEquipmentFootNote />
              <MyEquipmentSaveFooter
                disableSave={!formMethods.formState.isValid || disabledAdd}
                saveLabel={t('ADD')}
                cancelClass={'ps-3'}
                cancelLabel={t('CANCEL')}
                onCancelHandler={onClose}
              />
            </div>
          )}
        </Drawer>
      </MyEquipmentForm>
      <AddEquipmentInfoDrawer
        drawerSerialLink={drawerSerialLink}
        drawerModelLink={drawerModelLink}
        isChild={true}
      />
    </div>
  );
};

AddEquipmentDrawer.propTypes = {
  drawerProps: PropTypes.object,
  onDrawerClose: PropTypes.func,
  isChild: PropTypes.bool
};

export default AddEquipmentDrawer;
