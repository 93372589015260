import { useFormikContext } from 'formik';
import { SystemFeedback } from 'cat-ecommerce-alloy';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { FEEDBACK_TYPES } from '@app/constants/errorConstants';
import Conditional from '@app/components/common/Conditional';
import { useEffect, useState } from 'react';
import { getSessionStorageCartSharingData } from '@app/components/pages/checkout/shopping-cart/ShoppingCartPage/utils';
import {
  CSR_SHARE_CART_LINK_STOREID_PARAM,
  CSR_SHARE_CART_LINK_STORE_LOCATION_ID_PARAM
} from '@app/components/pages/checkout/shopping-cart/OrderItems/constants';
import {
  dealerAssociationsPropTypes,
  storesLocationPropTypes
} from '@app/components/associated-dealer/declarations.propTypes';

const AssociatedDealerFormPrepopulateCartShare = ({
  dealers = [],
  storeLocations = []
}) => {
  const { setFieldValue, values } = useFormikContext();
  const [t] = useTranslation();
  const [showWarningCartShare, setShowWarningCartShare] = useState(false);
  const CSR_SHARE_CART_DATA = getSessionStorageCartSharingData();

  const prepopulateStoreLocationSelectedInCartShare = () => {
    if (
      CSR_SHARE_CART_DATA &&
      CSR_SHARE_CART_DATA[CSR_SHARE_CART_LINK_STORE_LOCATION_ID_PARAM] &&
      values?.dealer?.value ===
        CSR_SHARE_CART_DATA[CSR_SHARE_CART_LINK_STOREID_PARAM]
    ) {
      const findStoreLocationItem = storeLocations.find(
        storeLocationItem =>
          storeLocationItem.value ===
          CSR_SHARE_CART_DATA[CSR_SHARE_CART_LINK_STORE_LOCATION_ID_PARAM]
      );
      if (findStoreLocationItem) {
        setFieldValue('storeLocation', { ...findStoreLocationItem });
      }
    } else {
      const findStoreLocationItem = storeLocations.find(
        storeLocationItem => storeLocationItem.default
      );
      if (findStoreLocationItem) {
        setFieldValue('storeLocation', { ...findStoreLocationItem });
      }
    }
  };
  const prepopulateDealerSelectedInCartShare = () => {
    if (
      dealers.length > 0 &&
      CSR_SHARE_CART_DATA &&
      CSR_SHARE_CART_DATA[CSR_SHARE_CART_LINK_STOREID_PARAM]
    ) {
      const findDealerFromShareCartLink = dealers.find(
        dealerD =>
          dealerD.value ===
          CSR_SHARE_CART_DATA[CSR_SHARE_CART_LINK_STOREID_PARAM]
      );
      if (findDealerFromShareCartLink) {
        setFieldValue('dealer', { ...findDealerFromShareCartLink });
        if (findDealerFromShareCartLink?.customerNumber?.length > 0) {
          setFieldValue(
            'customerNumber',
            findDealerFromShareCartLink.customerNumber[0]
          );
        }
      }
    }
  };

  useEffect(() => {
    prepopulateStoreLocationSelectedInCartShare();
  }, [values?.dealer?.value, storeLocations]);

  useEffect(() => {
    setShowWarningCartShare(false);
    if (CSR_SHARE_CART_DATA) {
      const findDealerFromShareCartLink = dealers.find(
        dealerD =>
          dealerD.value ===
          CSR_SHARE_CART_DATA[CSR_SHARE_CART_LINK_STOREID_PARAM]
      );
      if (
        findDealerFromShareCartLink &&
        values?.dealer?.value !== findDealerFromShareCartLink.value
      ) {
        setShowWarningCartShare(true);
      }
    }
  }, [values?.dealer?.value]);

  return (
    <Conditional test={showWarningCartShare && CSR_SHARE_CART_DATA}>
      <SystemFeedback
        className={cx(['system-feedback--has-title', 'mb-3'])}
        type={FEEDBACK_TYPES.WARNING}
        title={t('LOSS_CART_ITEMS_WARNING')}
        actions={[
          {
            label: t('SHIP_GO_BACK'),
            type: 'secondary',
            onClick: () => prepopulateDealerSelectedInCartShare()
          }
        ]}
        message={t('LOSS_CART_ITEMS_WARNING_VERBIAGE')}
      />
    </Conditional>
  );
};

AssociatedDealerFormPrepopulateCartShare.propTypes = {
  dealers: dealerAssociationsPropTypes,
  storeLocations: storesLocationPropTypes
};

export default AssociatedDealerFormPrepopulateCartShare;
