import { UOM, STATUS } from '@app/constants/commonConstants';

export const searchUIInitialStore = {
  activeFacets: [],
  unitOfMeasure: UOM.us,
  searchUIDetailsStatus: STATUS.IDLE,
  searchUIDetailsError: null,
  products: [],
  subCategories: [],
  spellingSuggestions: [],
  lowestLoadedPage: 1,
  productCardExpandedArr: [],
  pageSize: 16,
  showSpecificationsToggle: false,
  equipmentFitmentStatus: STATUS.IDLE,
  queryParams: {
    catalogId: '',
    facets: '',
    langId: '',
    model: '',
    offset: '',
    searchTerm: '',
    storeId: '',
    sortBy: '',
    categoryId: ''
  },
  facetsFilteredByUOM: []
};
