import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import * as types from '@app/store/notificationHeader/constants';

import { orderAccountFilterHelper, trimLeadingZero } from '../utils';

const useFilterNotification = () => {
  const [filterResult, setFilterResult] = useState([]);

  const dispatch = useDispatch();

  const handleFilter = useCallback((originalArray, filterData) => {
    try {
      if (Object.keys(filterData).length > 0) {
        dispatch({
          type: types.NOTIFICATION_FILTER_BEGIN
        });
        const filteredDataByOrderStatus =
          filterData?.orderStatus?.length > 0
            ? orderAccountFilterHelper(originalArray, filterData?.orderStatus)
            : originalArray;

        const filterResults = _.filter(filteredDataByOrderStatus, o => {
          const referenceOrderNumber =
            filterData?.['orderNumberOrReference']?.toLowerCase();
          const account = filterData?.account?.label;
          const isDCN = filterData?.account?.label === o?.dcn;
          const purchaseOrderNumber = filterData?.['poNumber'];

          let documentNumber = filterData?.['orderNumberOrReference'];
          if (referenceOrderNumber) {
            documentNumber =
              trimLeadingZero(referenceOrderNumber).toLowerCase();
          }
          const orderNumber = trimLeadingZero(
            o.orderNumber?.toString()?.toLowerCase()
          );

          const referenceId = o.referenceId?.toString()?.toLowerCase();
          const isOrderNumber =
            _.startsWith(orderNumber, documentNumber) ||
            _.startsWith(referenceId, referenceOrderNumber);

          const isPoNumber = () => {
            if (!!o?.poNumber) {
              return _.startsWith(
                trimLeadingZero(o?.poNumber?.toString()?.toLowerCase()),
                trimLeadingZero(purchaseOrderNumber?.toLowerCase())
              );
            }
          };

          if (account && referenceOrderNumber && purchaseOrderNumber) {
            return isDCN && isOrderNumber && isPoNumber();
          } else if (account && referenceOrderNumber && !purchaseOrderNumber) {
            return isDCN && isOrderNumber;
          } else if (account && !referenceOrderNumber && purchaseOrderNumber) {
            return isDCN && isPoNumber();
          } else if (account && !referenceOrderNumber && !purchaseOrderNumber) {
            return isDCN;
          } else if (!account && referenceOrderNumber && purchaseOrderNumber) {
            return isOrderNumber && isPoNumber();
          } else if (!account && referenceOrderNumber && !purchaseOrderNumber) {
            return isOrderNumber;
          } else if (!account && !referenceOrderNumber && purchaseOrderNumber) {
            return isPoNumber();
          } else if (
            !account &&
            !referenceOrderNumber &&
            !purchaseOrderNumber
          ) {
            return true;
          }
          return false;
        });

        setFilterResult(filterResults);
        dispatch({
          type: types.NOTIFICATION_FILTER_SUCCESS
        });
      } else {
        setFilterResult(originalArray);
        dispatch({
          type: types.NOTIFICATION_FILTER_SUCCESS
        });
      }
    } catch (e) {
      dispatch({
        type: types.NOTIFICATION_FILTER_ERROR
      });
    }
  }, []);

  return {
    filterResult,
    handleFilter
  };
};

export default useFilterNotification;
