import { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  AlloyButton,
  ModalContext,
  ComponentLoader
} from 'cat-ecommerce-alloy';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SaveEquipment from '@app/components/pages/my-equipment/AddEquipmentModal/SearchSuggestions/EquipmentSuggestions/SaveEquipment';
import useEquipmentDrawer from '@app/hooks/useEquipmentDrawer';

const MyEquipmentCTA = ({ type, buttonText }) => {
  const [t] = useTranslation();
  const {
    openAddDrawer,
    openEditDrawer,
    openSelectDrawer,
    getDrawerComponent
  } = useEquipmentDrawer();
  let modalType = '';
  const selectedEquipment = useSelector(
    s => s.myEquipment.equipments?.selectedEquipment
  );
  const myEquipmentList = useSelector(
    s => s.myEquipment.equipments?.myEquipmentList
  );
  const { isQRCodeTempEquipment } = selectedEquipment;

  const defaultProps = {
    modalTitle: t('SAVE_MY_EQUIPMENT'),
    currentEquipment: selectedEquipment
  };

  const initialLoading = useSelector(s => s.myEquipment?.initialLoading);
  const isLoading = useSelector(s => s.myEquipment?.isLoading);
  const modalCtx = useContext(ModalContext);
  let equipmentWidgetCallback;
  /** case 1: blank status while loading equipment data or page loading */
  if (isLoading || initialLoading) {
    equipmentWidgetCallback = () => modalCtx.addModal(<ComponentLoader />);
  } else if (!selectedEquipment?.model) {
    /**case 2: Add Equipment : when there is no selected equipment
     * 2.a:  no equipment in the list -- Add Equipment
     * 2.b:  otherwise -- selected equipment   */
    if (myEquipmentList.length === 0) {
      modalType = 'AddEquipmentModal';
      equipmentWidgetCallback = () => openAddDrawer();
    } else {
      modalType = 'SelectModal';
      equipmentWidgetCallback = () => openSelectDrawer();
    }
  } else if (!selectedEquipment.serialNumber || isQRCodeTempEquipment) {
    /**case 3: Save Equipment and Edit Modal*/
    modalType = 'SaveEquipment';
    equipmentWidgetCallback = () =>
      modalCtx.addModal(
        <SaveEquipment {...defaultProps} isTempEquipmentMessage />
      );
  } else {
    modalType = 'EditModal';
    equipmentWidgetCallback = () => openEditDrawer();
  }

  return (
    <>
      <AlloyButton
        buttonType={type}
        size="small"
        type="submit"
        data-testid="My_Equipment_CTA"
        data-component={modalType}
        onClick={() => equipmentWidgetCallback()}
      >
        {buttonText ? buttonText : t('MEQ_ADD_EQUIP')}
      </AlloyButton>
      {getDrawerComponent({
        currentEquipment: selectedEquipment,
        myEquipmentList: myEquipmentList
      })}
    </>
  );
};

MyEquipmentCTA.propTypes = {
  type: PropTypes.string,
  buttonText: PropTypes.string
};

export default MyEquipmentCTA;
