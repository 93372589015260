import { useTranslation } from 'react-i18next';
import { Input } from 'cat-ecommerce-alloy';
import Field from '../../../../common/form/Field';

const AddressInput = () => {
  const [t] = useTranslation();

  return (
    <Field
      as={Input}
      className={`w-100 mb-0`}
      hideErrorMessage={true}
      id="dealer-user_address"
      label={t('BCT_ADDRESS')}
      name="address"
      showOptional={true}
      type="text"
    />
  );
};

export default AddressInput;
