import PropTypes from 'prop-types';
import { CatPrimaryNavItem } from 'blocks-react/bedrock/components/PrimaryNavItem/PrimaryNavItem.js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { normalizeUrl, replaceTokensInString } from '@app/utils';
import endpoints from '@app/constants/endpoints';
import { useRouteMatch } from 'react-router-dom';
import { PARTS_MANUAL_ROUTE } from '@app/constants/routes';
import { MAIN_NAV_TRACK } from '@app/constants/analyticsConstants';
import styles from '../Subheader.modules.scss';
import cx from 'classnames';

const PartDiagramsWrapper = ({ isMobile = false }) => {
  const { t } = useTranslation();

  const esiteURL = useSelector(s => s.common.esiteURL);
  const isCurrentPage = useRouteMatch(PARTS_MANUAL_ROUTE);
  const { FOOTER_LINK_PART_MANUAL } = endpoints;

  // build the link and normalize it
  const getLinkUrl = url => {
    const linkEndpoint = replaceTokensInString(url, esiteURL);
    return normalizeUrl(linkEndpoint);
  };
  const text = t('CAT_PARTS_DIAGRAM');
  return (
    <CatPrimaryNavItem
      data-testid="partDiagramsNavItem"
      href={getLinkUrl(FOOTER_LINK_PART_MANUAL)}
      text={text}
      isCurrent={!!isCurrentPage}
      data-track-event={MAIN_NAV_TRACK.EVENT}
      data-track-attr-nltext={MAIN_NAV_TRACK.PARTS_DIAGRAM_TEXT}
      data-track-attr-context={MAIN_NAV_TRACK.TOP_MENU_CONTEXT}
      className={cx({ [styles['item-border-bottom']]: isMobile })}
    />
  );
};

PartDiagramsWrapper.propTypes = {
  isMobile: PropTypes.bool
};

export default PartDiagramsWrapper;
