// This is the format WCS is expecting to receive
export const ADDRESS_TYPES = {
  shippingAndBilling: 'SB',
  billing: 'B',
  shipping: 'S'
};

// This is the format WCS sends in the address list
export const ADDRESS_TYPE_LABELS = {
  shippingAndBilling: 'ShippingAndBilling',
  billing: 'Billing',
  shipping: 'Shipping'
};

//constants for AVI service
export const SELECTED_ADDRESS = {
  ORIGINAL: 'original',
  SUGGESTED: 'suggested'
};
export const AVI_REQUEST_TYPE_CREATE = 'create';

export const INVALID_EMAIL_DOMAINS = [
  '@cat.com',
  '@perkins.com',
  '@solarturbines.com',
  '@progressrail.com',
  '@mwm.net',
  '@fgwilson.com',
  '@kempervalve.com',
  '@anchorcoupling.com'
];

export const TAX_CREDIT_OPTIONS = {
  YES: 'YES',
  NO: 'NO'
};

export const COMPANY_LENGTH = 14;
export const REGEX_NUMERIC = /^[0-9]+$/;
export const INDIVIDUAL_FIELD_FORMAT = /(\d{3}).(\d{3}).(\d{3})-(\d{2})/g;
export const REGEX_ALPHA_NUMERIC = /[^A-Za-z0-9]/g;
export const INDIVIDUAL = 'Individual';
export const COMPANY = 'Company';
