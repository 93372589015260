import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTracking } from 'react-tracking';
import useHttp from '../../../../hooks/useHttp';
import { replaceTokensInUrl, removeZipcodeFromString } from '../../../../utils';
import { processDealerStores } from '../../DealerLocatorModal/utils';
import { getFormSubmitEvent } from '../../../common/analytics/analyticsUtils';
import endpoints from '../../../../constants/endpoints';

const useFindDealers = () => {
  const { trackEvent } = useTracking();
  const isCSPCustomer = useSelector(state => state?.common?.isCSPCustomer);
  const storeId = useSelector(state => state?.common?.storeId);
  const langId = useSelector(state => state?.common?.langId);
  const dealerAssociations =
    useSelector(state => state?.dealerAssociation?.dealerAssociations) ?? [];
  const associatedStoreString = Array.isArray(dealerAssociations)
    ? dealerAssociations.map(assoc => assoc.value).join(',')
    : '';
  const processData = useCallback(
    (data = {}) => {
      const associatedStores = associatedStoreString.split(',');
      return processDealerStores(data, associatedStores, isCSPCustomer);
    },
    [associatedStoreString, isCSPCustomer]
  );
  const { invoke, data, error, loading, numInvocations, reset } = useHttp({
    processData
  });

  const findDealers = useCallback(
    (
      suggestion,
      isSuggestion,
      skipGaFormSubmission = false,
      extendedList = false
    ) => {
      const url = replaceTokensInUrl(
        endpoints.LOCATION_DEALER_INFORMATION,
        storeId,
        langId,
        suggestion,
        extendedList
      );
      const gaLocation = isSuggestion
        ? removeZipcodeFromString(suggestion)
        : '';
      const gaEvent = {
        ...getFormSubmitEvent({
          formName: 'Dealer Locator',
          formContent: gaLocation
        }),
        formFieldCausingError: ''
      };
      const errorEvent = {
        ...gaEvent,
        formStatus: 'fail'
      };
      const noDataEvent = {
        ...errorEvent,
        formFieldCausingError: 'location invalid'
      };
      invoke({ method: 'get', rethrowError: true, url })
        .then((data = {}) => {
          if (!skipGaFormSubmission) {
            if (
              !Array.isArray(data.dealerStores) ||
              data.dealerStores.length === 0
            ) {
              trackEvent(noDataEvent);
            } else {
              trackEvent(gaEvent);
            }
          }
        })
        .catch(() => {
          if (!skipGaFormSubmission) {
            trackEvent(errorEvent);
          }
        });
    },
    [invoke, storeId, trackEvent]
  );
  return { invoke: findDealers, data, error, loading, numInvocations, reset };
};

export default useFindDealers;
