import AddEquipmentFindSerialDrawer from '../AddEquipmentFindSerialDrawer';
import AddEquipmentFindModelDrawer from '../AddEquipmentFindModelDrawer';
import { PropTypes } from 'prop-types';

const AddEquipmentInfoDrawer = ({
  drawerSerialLink,
  drawerModelLink,
  isChild,
  showBackButton
}) => {
  return (
    <>
      <AddEquipmentFindSerialDrawer
        drawerSerialLink={drawerSerialLink}
        isChild={isChild}
        showBackButton={showBackButton}
      />
      <AddEquipmentFindModelDrawer
        drawerModelLink={drawerModelLink}
        isChild={isChild}
        showBackButton={showBackButton}
      />
    </>
  );
};

AddEquipmentInfoDrawer.propTypes = {
  drawerSerialLink: PropTypes.string,
  drawerModelLink: PropTypes.string,
  isChild: PropTypes.bool,
  showBackButton: PropTypes.bool
};
export default AddEquipmentInfoDrawer;
