import { useSelector } from 'react-redux';
import { PCC_HEADLESS_SHOPPING_CART_FLAG } from '@app/constants/featureFlags';
import { headlessLinks } from '@app/constants/links';
import { replaceTokensInString } from '@app/utils';
import links from '@app/constants/links';

const useShoppingCartFeatureFlag = ({ customURL = '' } = {}) => {
  const storeId = useSelector(s => s.common?.storeId);
  const langId = useSelector(s => s.common?.langId);
  const catalogId = useSelector(s => s.common?.catalogId);
  const esiteURL = useSelector(s => s.common?.esiteURL);
  const shoppingCartHeadlessFlagValue = useSelector(
    s => s.featureFlag?.[PCC_HEADLESS_SHOPPING_CART_FLAG]
  );
  const { SHOPPING_CART, SHOPPING_CART_URL_PARAMETER } = links;
  const shoppingCartUrlParameter = encodeURI(
    replaceTokensInString(SHOPPING_CART_URL_PARAMETER, storeId)
  );

  const commerceShopingCartURL =
    customURL ||
    replaceTokensInString(
      SHOPPING_CART,
      catalogId,
      langId,
      shoppingCartUrlParameter
    );
  const shoppingCartUrl = shoppingCartHeadlessFlagValue
    ? replaceTokensInString(headlessLinks.HEADLESS_SHOPPING_CART_LINK, esiteURL)
    : commerceShopingCartURL;

  return shoppingCartUrl;
};

export default useShoppingCartFeatureFlag;
