import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useHeaderAnalytics from '../hooks/useHeaderAnalytics';
import CartDrawer from './CartDrawer/CartDrawer';
import { HeaderCart, Portal, useDrawer } from '@cat-ecom/pcc-components';
import { DRAWERS_ROOT } from '@app/constants/targets';
import { getCurrentOrderItemCount } from './utils';
import { returnFocusToNavItem } from '@app/utils/DOMUtils';
import Conditional from '@app/components/common/Conditional';
import useShoppingCartFeatureFlag from '@app/hooks/useShoppingCartFeatureFlag';

const HeaderCartWrapper = () => {
  const { t } = useTranslation();
  const { productImpressionEvent } = useHeaderAnalytics();

  const currentOrderId = useSelector(s => s?.orders?.current);
  const shoppingCartUrl = useShoppingCartFeatureFlag();
  const currentOrderItems =
    useSelector(s => s?.orders?.byId[currentOrderId]?.ascendingOrderItems) ||
    [];
  const isCartEmpty = currentOrderItems.length === 0;

  const numItems = getCurrentOrderItemCount(currentOrderItems);
  const { closeDrawer, initiatorProps, drawerProps, isDrawerOpen } = useDrawer({
    onClose: () => returnFocusToNavItem('#headerCart'),
    onOpen: () => {
      if (!isCartEmpty) {
        productImpressionEvent(currentOrderItems);
      }
    }
  });
  const cartDrawerProps = {
    closeDrawer,
    ...drawerProps
  };
  return (
    <>
      <HeaderCart
        title={t('ENCLOSED_SHOPPING_INFO')}
        cartItems={numItems}
        handleDrawerProps={initiatorProps}
        id="headerCart"
        isCurrent={isDrawerOpen}
        redirectionLink={shoppingCartUrl}
      />
      <Conditional test={false}>
        <Portal id={DRAWERS_ROOT}>
          <CartDrawer {...cartDrawerProps} />
        </Portal>
      </Conditional>
    </>
  );
};

export default HeaderCartWrapper;
