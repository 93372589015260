import { STATUS } from '@app/constants/commonConstants';

export const visionLinkInitialStore = {
  vlAsset: {
    data: {},
    status: STATUS.IDLE
  },
  vlAssetSummary: {
    data: {},
    status: STATUS.IDLE
  }
};
