import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ERROR_DOMAIN, ERROR_PATH } from '../../../../constants/errorConstants';
import useSystemFeedback from '@app/hooks/useSystemFeedback';
import { errorPropTypes } from '../../declarations.proptypes';
import PropTypes from 'prop-types';
import { CSR_TEXTS } from '@app/components/pages/csr-landing/texts';

const DealerLocationListErrorEffect = ({
  error,
  isBlankResult,
  isBlankFilteredDealers,
  bobRedirect
}) => {
  const [t] = useTranslation();
  const { setError, clearError } = useSystemFeedback();

  useEffect(() => {
    const noLocationsMessage = t('FYD_BLOCKED_COUNTRY_LOCATION');
    const blankFilteredDealersMessage = `${t('FYD_NO_RESULTS')} ${t(
      'FYD_ADJUST_SEARCH'
    )}`;
    if (error) {
      const returnedError = bobRedirect
        ? {
            message: CSR_TEXTS.ADP_MODAL_ERROR_MESSAGE,
            severity: 'error'
          }
        : error;
      setError(
        ERROR_DOMAIN.DEALER_LOCATOR,
        ERROR_PATH.LOCATIONS,
        returnedError
      );
    } else if (isBlankResult) {
      const blankError = {
        message: noLocationsMessage
      };
      setError(ERROR_DOMAIN.DEALER_LOCATOR, ERROR_PATH.LOCATIONS, blankError);
    } else if (isBlankFilteredDealers) {
      const blankFilteredDealersError = {
        message: blankFilteredDealersMessage
      };
      setError(
        ERROR_DOMAIN.DEALER_LOCATOR,
        ERROR_PATH.LOCATIONS,
        blankFilteredDealersError
      );
    } else {
      clearError(ERROR_DOMAIN.DEALER_LOCATOR, ERROR_PATH.LOCATIONS);
    }
    // clear errors when modal unmount
    return () => clearError(ERROR_DOMAIN.DEALER_LOCATOR, ERROR_PATH.LOCATIONS);
  }, [clearError, error, isBlankFilteredDealers, isBlankResult, setError, t]);

  return null;
};

DealerLocationListErrorEffect.propTypes = {
  error: errorPropTypes,
  isBlankResult: PropTypes.bool,
  isBlankFilteredDealers: PropTypes.bool
};

export default DealerLocationListErrorEffect;
