import { MODALS_ROOT } from '@app/constants/targets';
import { Portal } from '@cat-ecom/pcc-components';
import AddEquipmentFindModelDrawer from './AddEquipmentFindModelDrawer';
const Container = props => {
  return (
    <Portal id={MODALS_ROOT}>
      <AddEquipmentFindModelDrawer {...props} />
    </Portal>
  );
};

export default Container;
