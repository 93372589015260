import { createPortal } from 'react-dom';
import { HEADER as headerTarget } from '@app/constants/targets.js';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';

const HeaderLoading = () => {
  const isDesktop = useBreakpoint(PAGE_BREAKPOINTS.BLOCKS_LG);
  const headerRootElement = document.getElementById(headerTarget);
  const loaderHeight = isDesktop ? '137px' : '185px';
  return headerRootElement
    ? createPortal(
        <>
          <div
            className="w-100 bg-black"
            style={{ height: loaderHeight }}
          ></div>
        </>,
        headerRootElement
      )
    : null;
};

export default HeaderLoading;
